const colors = {
  RED: opacity =>
    opacity ? `rgba(218, 47, 40, ${opacity})` : "rgb(218, 47, 40)",
  DARK_BLUE: opacity =>
    opacity ? `rgba(16, 39, 65, ${opacity})` : "rgb(16, 39, 65)",
  LIGHT_BLUE: opacity =>
    opacity ? `rgba(0, 140, 255, ${opacity})` : "rgb(0, 140, 255)",
  GREY: opacity =>
    opacity ? `rgba(183, 183, 183, ${opacity})` : "rgb(183, 183, 183)",
  PINK: opacity =>
    opacity ? `rgba(237, 130, 142, ${opacity})` : "rgb(237, 130, 142,)"
};
export default colors;
