/***
 * Onboard new features utilizing react joyride
 * @patr
 */
import React from "react";

// NPM Modules
import Joyride from "react-joyride";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ACTIONS, EVENTS } from "react-joyride";

// Redux
import { JoyrideActions } from "../../redux/joyride";
import { ModalActions } from "../../redux/modals";

class FeatureOnboard extends React.Component {
  state = {
    scroll: 0
  };

  callback = data => {
    const { action, index, type } = data;
    let { joyride, joyrideActions, modalActions } = this.props;

    if (type === EVENTS.TOUR_END && joyride.run) {
      joyrideActions.setRun({ run: false, inProgress: false });
      joyrideActions.setSteps({});
      joyrideActions.setStepIndex(0);
      modalActions.openManualInputModal(false);
      modalActions.openAddProductModal(false);
      modalActions.openAddConsignmentModal(false);
      modalActions.openItemManagementModal(false);
      modalActions.openApproveConsignmentModal(false);

      this.props.history.push("/tutorials");
    } else if (type === EVENTS.STEP_AFTER) {
      let nextIndex = action === ACTIONS.PREV ? index - 1 : index + 1;
      joyrideActions.setStepIndex(nextIndex);
    }

    // ADD SOLESTAGE PRODUCT FEATURE
    if (type === EVENTS.STEP_AFTER) {
      if (joyride.feature === "list_consignment") {
        if (action === ACTIONS.PREV) {
          if (index === 1) {
            modalActions.openApproveConsignmentModal(false);
          }
        } else {
          if (index === 1) {
            joyrideActions.setRun({ run: false, inProgress: true });
            document.getElementById("clickRow").click();
            setTimeout(() => {
              joyrideActions.setRun({ run: true, inProgress: true });
            }, 325);
          } else if (index === 2) {
            joyrideActions.setRun({ run: false, inProgress: true });
            document.getElementById("approve").click();
            setTimeout(() => {
              joyrideActions.setRun({ run: true, inProgress: true });
            }, 50);
          }
        }
      }

      if (joyride.feature === "approve_consignment") {
        if (action === ACTIONS.PREV) {
          if (index === 1) {
            modalActions.openApproveConsignmentModal(false);
          }
        } else {
          if (index === 0) {
            joyrideActions.setRun({ run: false, inProgress: true });
            document.getElementById("clickRow").click();
            setTimeout(() => {
              joyrideActions.setRun({ run: true, inProgress: true });
            }, 325);
          } else if (index === 3) {
            joyrideActions.setRun({ run: false, inProgress: true });
            document.getElementById("approve").click();
            setTimeout(() => {
              joyrideActions.setRun({ run: true, inProgress: true });
            }, 50);
          }
        }
      }

      if (joyride.feature === "change_inventory_price") {
        if (action === ACTIONS.PREV) {
          if (index === 1) {
            modalActions.openItemManagementModal(false);
          }
        } else {
          if (index === 0) {
            joyrideActions.setRun({ run: false, inProgress: true });
            document.getElementById("manageButton").click();
            setTimeout(() => {
              joyrideActions.setRun({ run: true, inProgress: true });
            }, 325);
          }
        }
      }
      if (joyride.feature === "add_consignment_inventory") {
        if (action === ACTIONS.PREV) {
        } else {
          if (index === 0) {
            joyrideActions.setRun({ run: false, inProgress: true });
            modalActions.openAddConsignmentModal(true);
            setTimeout(() => {
              joyrideActions.setRun({ run: true, inProgress: true });
            }, 325);
          } else if (index === 1) {
            joyrideActions.setRun({ run: false, inProgress: true });
          } else if (index === 3) {
            joyrideActions.setRun({ run: false, inProgress: true });
          }
        }
      }
      if (joyride.feature === "add_solestage_inventory") {
        // If we go back, then need to reverse course
        if (action === ACTIONS.PREV) {
          if (index === 1) {
            modalActions.openAddProductModal(false);
          } else if (index === 2) {
            modalActions.openManualInputModal(false);
          }
        } else {
          if (index === 0) {
            joyrideActions.setRun({ run: false, inProgress: true });
            modalActions.openAddProductModal(true);
            setTimeout(() => {
              joyrideActions.setRun({ run: true, inProgress: true });
            }, 325);
          }

          if (index === 2) {
            joyrideActions.setRun({ run: false, inProgress: true });
            modalActions.openManualInputModal(true);
            setTimeout(() => {
              joyrideActions.setRun({ run: true, inProgress: true });
            }, 225);
          }
        }
      }
    }
  };

  render() {
    let { joyride } = this.props;
    return (
      <Joyride
        steps={joyride.steps}
        run={joyride.run}
        callback={this.callback}
        stepIndex={joyride.stepIndex}
        showProgress={true}
        continuous={true}
        scrollOffset={49.5 + this.state.scroll}
      />
    );
  }
}

const mapStateToProps = state => ({
  joyride: state.joyride,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  joyrideActions: bindActionCreators(JoyrideActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FeatureOnboard));
