/***
 * Paypal oauth page to save paypal info
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import qs from "qs";
import { ClipLoader } from "react-spinners";
import { injectIntl } from "react-intl";

// Redux
import { AuthActions } from "../../redux/auth";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";

class StripeOnboarding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linked: false,
      error: false
    };
  }

  componentDidMount = () => {
    let qstring = qs.parse(this.props.location.search.substring(1));
    this.getOnboardingLink(qstring).then(link => {
      // window.location = link;
    });
    // this.saveStripeInfo(qstring);
    // window.location = link;
  };

  getOnboardingLink = async query => {
    let token = query.state;

    const location = query.location;
    let config = API.POST_CONFIG({ location });
    config.headers.Authorization = `Token ${token}`;
    const url = API.STRIPE_ONBOARDING;
    return fetch(url, config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(data => {
        window.location = data.link.url;
      })
      .catch(error => {
        alert(JSON.stringify(error.message.error));
        this.setState({
          error: true
        });
      });
  };

  render() {
    let { intl } = this.props;

    return (
      <div className={css(styles.container)}>
        {this.state.error ? (
          <div className={css(styles.paypalConnected)}>
            {intl.formatMessage({
              id: "paypal_error",
              defaultMessage:
                "An error occurred during Stripe connection, please try again!"
            })}
          </div>
        ) : this.state.linked ? (
          <div className={css(styles.paypalConnected)}>
            {intl.formatMessage({
              id: "paypal_connect",
              defaultMessage:
                "Stripe connected! Close this browser to go back to the app."
            })}
          </div>
        ) : (
          <ClipLoader color={"#111"} size={24} />
        )}
      </div>
    );
  }
}
var styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
    boxSizing: "border-box"
  },
  paypalConnected: {
    fontSize: 24,
    letterSpacing: 0.7,
    textAlign: "center"
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StripeOnboarding)
);
