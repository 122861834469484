import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, injectIntl } from "react-intl";
import ReactTable from "react-table";
import Toggle from "react-toggle";

// Component
import BaseModal from "../BaseModal";
// import MoveScreen from './MoveScreen';
// import RequestScreen from './RequestScreen';
import Button from "../../Button";
import BarcodeModal from "../AddProduct/BarcodeModal";
import CurrencyDisplay from "../../CurrencyDisplay";

// Actions
import { ModalActions } from "../../../redux/modals";
import { MessageActions } from "../../../redux/message";

// Config
import API from "../../../config/api";
import Helpers from "../../../redux/helpers";

// Stylesheets
import "react-table/react-table.css";
import "../../../screens/inventory/stylesheets/Table.css";
class ItemManagementModal extends React.Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      printBarcode: false,
      numPages: 1,
      page: 1,
      pageSize: 20,
      totalItems: 0,
      chosenColumns: [
        "size",
        "barcode",
        "resync",
        "quantity",
        "price",
        // "online price",
        /*'default_price',
        'shopify_price',
        'goat_price',
        'stockx_price',*/
        "lowest consignment price",
        "locations"
      ],
      data: [],
      doubleClicked: {},
      listOnToggle: {},
      showInput: false,
      selectedBarcode: {},
      hidePrice: true
    };

    this.state = {
      ...this.defaultState
    };
  }
  /***
   * closes the modal
   */
  closeModal = () => {
    let { modalActions } = this.props;
    this.setState(this.defaultState);
    modalActions.openItemManagementModal(false);
  };

  /***
   * Sets price for all inventory items
   */
  setPrice = ({ instore_price, online_price, sku }) => {
    let priceConfig = {
      inventory_ids: "__all__"
    };

    //updates all fields to an uniform price
    let price = {
      instore_price: instore_price ? instore_price : online_price,
      default_price: instore_price ? instore_price : online_price,
      goat_price: instore_price ? instore_price : online_price,
      magento_price: instore_price ? instore_price : online_price,
      solestage_price: instore_price ? instore_price : online_price,
      shine_price: instore_price ? instore_price : online_price
    };

    priceConfig.price = price;
    let { messageActions, intl } = this.props;
    messageActions.setMessage("");
    messageActions.showMessage({ show: true, load: true });
    const location = Number(this.props.location);
    return fetch(
      API.INVENTORY({
        id: 1,
        consignment__isnull: "True",
        location
      }) + `sku=${sku}`,
      API.PUT_CONFIG(priceConfig)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage(
          intl.formatMessage({
            id: "price_updated",
            defaultMessage: "Price Updated!"
          })
        );
        messageActions.showMessage({ show: true });
      })
      .catch(error => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage(
          intl.formatMessage({
            id: "something_wrong",
            defaultMessage: "Something went wrong!"
          })
        );
        messageActions.showMessage({ show: true, error: true });
      });
  };

  /***
   * Set list on
   */
  setListOn = ({ listOnParam, sku }) => {
    let listOnConfig = {
      inventory_ids: "__all__",
      ...listOnParam
    };
    let { messageActions, intl } = this.props;
    messageActions.setMessage("");
    messageActions.showMessage({ show: true, load: true });
    const location = Number(this.props.location);
    return fetch(
      API.INVENTORY({
        id: 1,
        consignment__isnull: "True",
        location
      }) + `sku=${sku}`,
      API.PUT_CONFIG(listOnConfig)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(_ => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage(
          intl.formatMessage({
            id: "list_on_complete",
            defaultMessage: "List on complete!"
          })
        );
        messageActions.showMessage({ show: true });
      })
      .catch(error => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage(
          intl.formatMessage({
            id: "something_wrong",
            defaultMessage: "Something went wrong!"
          })
        );
        messageActions.showMessage({ show: true, error: true });
      });
  };

  /***
   * callback after barcode unmounts
   */
  unmountBarcode = () => {
    this.setState({
      printBarcode: false,
      selectedBarcode: {},
      hidePrice: true
    });
  };

  getQuantityBySize = () => {
    this.setState({
      loading: true
    });
    const location = Number(this.props.location);
    return fetch(
      API.TRUE_STYLE({
        style: this.props.items.style,
        location
      }) + "&only_available=True",
      API.GET_CONFIG(true)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        return res;
      });
  };

  /**
   * After opening the modal
   */
  onAfterOpen = async () => {
    // this.getData();
  };

  /**
   * sorting sfor skus by size
   * @param  {String} a -- size of sku as a string
   * @param  {String} b -- size of sku as a string
   */
  sortSKUSize = (a, b) => {
    let float_a = +a;
    let float_b = +b;
    if (!float_a || !float_b) {
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      }
    } else {
      if (float_a < float_b) {
        return -1;
      } else if (float_a > float_b) {
        return 1;
      }
    }
    return 0;
  };

  onKeyPress = e => {
    if (e.key === "Enter") {
      this.input.blur();
    }
  };

  /**
   * Gets bulk SKU Data
   */
  getBulkSkuData = () => {
    const location = Number(this.props.location);
    let url = API.GET_BULK_STOCK_QUANTITY({
      location,
      style: this.props.items.style
    });
    return fetch(url, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = [];
        let sizes = Object.keys(res.count_by_sku);
        sizes.sort(this.sortSKUSize);
        for (let i = 0; i < sizes.length; i++) {
          let size = sizes[i];
          let dataPoint = {
            "lowest consignment price": res.consignment_price_by_sku[size],
            price: res.price_by_sku[size],
            "online price": res.online_price_by_sku[size],
            barcode: this.props.items.extraInfo.barcode_by_us_size[size],
            maxQuantity: res.count_by_sku[size],
            quantity: res.count_by_sku[size],
            size,
            sku: res.skus[size],
            locations: []
          };
          data.push(dataPoint);
        }
        this.setState({
          data
        });
      });
  };

  /**
   * Gets the data of the product
   * @params int page -- the page we want to get from backend (index start from 1)
   */
  getData = async () => {
    let { messageActions } = this.props;
    messageActions.showMessage({ show: true, load: true });
    let quantityBySize = await this.getQuantityBySize();
    await this.getBulkSkuData(quantityBySize);
    messageActions.showMessage({ show: false, load: false });
    this.setState({
      searching: false
    });
  };

  onChangeListOn = (key, sku, props) => {
    let data = [...this.state.data];
    let listOn = { ...this.state.listOnToggle };
    listOn[key] = { listOn: props.index };
    if (key !== "price") {
      let listOnParam = {};
      if (key === "online price") {
        listOnParam["list_on_shine"] = true;
        listOnParam["list_on_magento"] = true;
        data[props.index].item["list_on_shine"] = true;
        data[props.index].item["list_on_magento"] = true;
      }
      this.setListOn({ listOnParam, sku });
    }
    this.setState({
      doubleClicked: {
        header: props.column.Header,
        rowIndex: props.index,
        columnId: props.column.id,
        value: 0
      },
      listOnToggle: listOn,
      showInput: true
    });
  };

  onInputBlur = () => {
    let { data, doubleClicked } = this.state;

    let row = data[doubleClicked.rowIndex];

    if (doubleClicked.header.includes("price")) {
      if (doubleClicked.header === "price") {
        this.setPrice({
          instore_price: data[doubleClicked.rowIndex][doubleClicked.columnId],
          sku: row.sku
        });
      } else if (doubleClicked.header === "online price") {
        this.setPrice({
          online_price: data[doubleClicked.rowIndex][doubleClicked.columnId],
          sku: row.sku
        });
      }
    } else if (doubleClicked.header === "quantity") {
      this.decreaseQuantity();
    } else {
      let sku = row.sku;
      let update = {};
      update[doubleClicked.columnId] =
        data[doubleClicked.rowIndex][doubleClicked.columnId];
      this.saveSKUChanges({ sku, update });
    }

    this.setState({
      showInput: false,
      doubleClicked: {}
    });
  };

  /**
   * Handles double clicking on a cell
   * Saves row, column, and value information for clicked cell
   * Triggers input to show, allowing user to change values
   * @param  {Object} props -- object containing the properties of a cell
   */
  handleDblClick = props => {
    // TODO: Connect to the backend
    if (props.column.Header) {
      if (
        props.column.Header !== "barcode" &&
        props.column.Header !== "color" &&
        props.column.Header !== "style" &&
        props.column.Header !== "lowest consignment price"
      ) {
        let value = props.value;
        if (props.column.Header.includes("price")) {
          value = value / 100;
        }
        this.setState({
          doubleClicked: {
            header: props.column.Header,
            rowIndex: props.row._index,
            columnId: props.column.id,
            value
          },
          showInput: true
        });
      }
    }
  };

  /**
   * Save changes on frontend when cell information is changed
   * @param  {Event} e -- change event that triggers when a cell is changed
   */
  onCellChange = e => {
    clearTimeout(this.onCellChangeTimeout);
    let doubleClicked = { ...this.state.doubleClicked };
    let data = [...this.state.data];
    doubleClicked.value = e.target.value;
    if (doubleClicked.header.includes("price")) {
      data[doubleClicked.rowIndex][doubleClicked.columnId] =
        e.target.value * 100;
    } else if (doubleClicked.header === "quantity") {
      if (e.target.value > data[doubleClicked.rowIndex]["maxQuantity"]) {
        data[doubleClicked.rowIndex][doubleClicked.columnId] =
          data[doubleClicked.rowIndex]["maxQuantity"];
        doubleClicked.value = data[doubleClicked.rowIndex]["maxQuantity"];
      } else if (e.target.value < 0) {
        data[doubleClicked.rowIndex][doubleClicked.columnId] = 0;
        doubleClicked.value = 0;
      } else {
        data[doubleClicked.rowIndex][doubleClicked.columnId] = e.target.value;
      }
    } else {
      data[doubleClicked.rowIndex][doubleClicked.columnId] = e.target.value;
    }
    this.setState({
      doubleClicked: doubleClicked,
      data: data
    });
  };

  /**
   * save changes to items
   * @param  {Object} sku -- sku information
   * @param  {Object} update -- update for sku
   */
  saveSKUChanges = ({ sku, update }) => {
    let { messageActions, intl } = this.props;
    messageActions.setMessage("");
    messageActions.showMessage({ show: true, load: true });

    return fetch(API.SAVE_SKU(sku), API.PUT_CONFIG(update))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage(
          intl.formatMessage({
            id: "sku_updated",
            defaultMessage: "Sku Updated!"
          })
        );
        messageActions.showMessage({ show: true });
      })
      .catch(error => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage(
          intl.formatMessage({
            id: "something_wrong",
            defaultMessage: "Something went wrong!"
          })
        );
        messageActions.showMessage({ show: true, error: true });
      });
  };

  /**
   * select whole input when input focused
   * @param  {EventObject} e -- event object for input focus
   */
  handleFocus = e => {
    e.target.select();
  };

  /**
   * decrease stock quantity
   */
  decreaseQuantity = () => {
    let { messageActions, intl } = this.props;
    let { doubleClicked } = this.state;
    let data = [...this.state.data];
    let decreaseQuantity =
      data[doubleClicked.rowIndex]["maxQuantity"] -
      data[doubleClicked.rowIndex]["quantity"];
    if (decreaseQuantity > 0) {
      data[doubleClicked.rowIndex]["maxQuantity"] =
        data[doubleClicked.rowIndex]["quantity"];

      let size = data[this.state.doubleClicked.rowIndex].size;
      messageActions.setMessage("");
      messageActions.showMessage({ show: true, load: true });

      let note = prompt("Decrease Stock Reason");
      let params = {
        decreaseQuantity,
        note: note || ""
      };

      const location = Number(this.props.location);
      return fetch(
        API.DECREASE_STOCK_QUANTITY({
          location,
          style: this.props.items.style,
          size
        }),
        API.POST_CONFIG(params)
      )
        .then(Helpers.checkStatus)
        .then(res => {
          messageActions.setMessage(
            intl.formatMessage({
              id: "stock_decreased",
              defaultMessage: "Stock decreased!"
            })
          );
          messageActions.showMessage({ show: true });
          this.props.quantityUpdate(
            this.props.items["table-id"] - 1,
            this.props.items.stock_quantity - decreaseQuantity
          );
          this.decreaseStockTimeout = setTimeout(() => {
            messageActions.showMessage({ show: false });
          }, 1000);

          this.setState({
            data
          });
        });
    }
  };

  /**
   * set printBarcode to true to print barcode
   * @param {Object} row -- row object of selected barcode
   */
  printBarcode = row => {
    let size = row.size;
    let selectedBarcode = {
      barcode: this.props.items.extraInfo.barcode_by_us_size[size],
      key: row.barcode,
      unmount: this.unmountBarcode,
      name: this.props.items.extraInfo.product_name_us,
      size: size,
      price: row.price / 100,
      color: this.props.items.extraInfo.color,
      style: this.props.items.style
    };

    this.setState({
      printBarcode: true,
      selectedBarcode
    });
  };

  /**
   * Resync's the item with clover
   * @param {Object} row -- row object of selected barcode
   */
  resync = row => {
    let sku = row.sku;
    let path = {
      path: "resync"
    };

    let config = {
      sku
    };

    let { messageActions } = this.props;

    messageActions.showMessage({ show: true, load: true });
    return fetch(API.SKU(path), API.POST_CONFIG(config))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        messageActions.showMessage({ show: false });
        if (res === 200) {
          messageActions.setMessage("Item Synced!");
          messageActions.showMessage({ show: true, load: false });
        } else {
          messageActions.setMessage("Something went wrong!");
          messageActions.showMessage({ show: true, error: true, load: false });
        }
      })
      .catch(error => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage(error.message);
        messageActions.showMessage({ show: true, error: true });
      });
  };

  hidePriceChange = e => {
    this.setState({ hidePrice: !e.target.checked });
  };

  render() {
    const { productId, search, location } = this.props;
    const url = search
      ? `/product/${location}/${productId}/${search}`
      : `/product/${location}/${productId}`;
    // const locationId = this.props.location ? this.props.location : "all";
    let { modals, intl } = this.props;
    let chosenColumns = this.state.chosenColumns;
    let columns = chosenColumns.map((column, index) => ({
      Header: column
        ? column !== "table-id" && column !== "manage"
          ? column
          : null
        : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => {
        return (
          <div
            className={css(styles.valueContainer)}
            onDoubleClick={() => this.handleDblClick(props)}
          >
            {props.column.Header === "sizes" ? (
              <button
                className={css(styles.manageItems)}
                onClick={() => this.openSizeModal(props)}
              >
                <FormattedMessage
                  id="check_sizes_button"
                  defaultMessage="Check Sizes"
                />
              </button>
            ) : props.row._index === this.state.doubleClicked.rowIndex &&
              props.column.id === this.state.doubleClicked.columnId ? (
              <input
                className={css(
                  styles.input,
                  props.column.Header === "quantity" && styles.numInput
                )}
                value={this.state.doubleClicked.value}
                onChange={this.onCellChange}
                onFocus={this.handleFocus}
                ref={ref => {
                  this.input = ref;
                }}
                onBlur={this.onInputBlur}
                type={props.column.Header === "quantity" ? "number" : "text"}
                min={0}
                max={props.original.maxQuantity}
                onKeyPress={this.onKeyPress}
                autoFocus
              />
            ) : props.column.Header.includes("price") ? (
              <div
                className={css(
                  styles.cellValue,
                  props.column.Header === null && styles.rowNumber
                )}
              >
                {!props.value || props.value < 0 ? (
                  "-"
                ) : (props.value > 0 &&
                    props.column.Header === "online price") ||
                  props.column.Header === "price" ||
                  (props.column.Header.includes("consignment") &&
                    props.value > 0) ? (
                  <CurrencyDisplay price={props.value / 100} />
                ) : !this.state.listOnToggle[props.column.Header] ? (
                  <Toggle
                    checked={
                      this.state.listOnToggle[props.column.Header]
                        ? this.state.listOnToggle[props.column.Header]
                            .listOn === props.index
                        : false
                    }
                    onChange={() =>
                      this.onChangeListOn(
                        props.column.Header,
                        props.original.sku,
                        props
                      )
                    }
                  />
                ) : (
                  this.state.listOnToggle[props.column.Header].listOn ===
                    props.index &&
                  this.state.showInput && (
                    <input
                      className={css(styles.input)}
                      value={this.state.doubleClicked.value}
                      onChange={this.onCellChange}
                      onFocus={this.handleFocus}
                      ref={ref => {
                        this.input = ref;
                      }}
                      onBlur={this.onInputBlur}
                      onKeyPress={this.onKeyPress}
                      autoFocus
                    />
                  )
                )}
              </div>
            ) : props.column.Header === "barcode" ? (
              <>
                <Button
                  className={styles.printBarcode}
                  id={"barcode_print"}
                  onClick={() => this.printBarcode(props.original)}
                  text={intl.formatMessage({
                    id: "export",
                    defaultMessage: "Print Barcode"
                  })}
                />
                <div>
                  <input type="checkbox" onChange={this.hidePriceChange} />
                  <span>Show Price</span>
                </div>
              </>
            ) : props.column.Header === "resync" ? (
              <Button
                className={[styles.printBarcode, styles.sync]}
                onClick={() => this.resync(props.original)}
                text={intl.formatMessage({
                  id: "sync",
                  defaultMessage: "Sync"
                })}
              />
            ) : props.column.Header === "locations" ? (
              <div className={css(styles.locations)}>
                {props.value.map((location, index) => {
                  return (
                    <span className={css(styles.locationName)}>
                      {location}
                      {index !== props.value.length - 1 && ", "}
                    </span>
                  );
                })}
              </div>
            ) : (
              <div
                className={css(
                  styles.cellValue,
                  props.column.Header === null && styles.rowNumber,
                  props.column.Header === "size" && styles.size
                )}
              >
                {props.row[index] ? props.row[index].value : props.value}
              </div>
            )}
          </div>
        );
      }
    }));

    return (
      <BaseModal
        isOpen={modals.openItemManagementModal}
        onAfterOpen={this.onAfterOpen}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        contentLabel="ItemManagementModal"
        bodyContainer={styles.bodyContainer}
      >
        <div className={css(styles.itemManagementContainer)}>
          <FontAwesomeIcon
            icon={["far", "times"]}
            className={css(styles.closeIcon)}
            onClick={this.closeModal}
          />
          <div className={css(styles.item)}>
            <img
              src={this.props.items.image}
              className={css(styles.image)}
              alt={"Product"}
            />
            <div className={css(styles.itemInfoContainer)}>
              <div className={css(styles.itemInfo, styles.productName)}>
                {this.props.items.extraInfo.product_name_us}
              </div>
              <div className={css(styles.itemInfo, styles.sku)}>
                {this.props.items.style}
              </div>
              <a href={url}>Product ID: {this.props.productId}</a>
            </div>
          </div>
        </div>
        <ReactTable
          className={css(styles.reactTable)}
          data={this.state.data}
          pages={this.state.numPages}
          columns={columns}
          footerClassName={css(styles.tableFooter)}
          defaultPageSize={this.state.pageSize}
          page={this.state.page - 1}
          onPageSizeChange={pageSize => {
            this.setState({
              pageSize,
              page: 1,
              numPages: Math.ceil(this.state.totalItems / pageSize)
            });
          }}
          onPageChange={pageIndex => {
            this.setState({
              page: pageIndex + 1
            });
          }}
          minRows={0}
          onFetchData={(state, instance) => {
            // if (window.location.search.includes('guide=true')) {
            //   return null;
            // }
            if (!window.location.search.includes("guide=true")) {
              this.getData();
            } else {
              return true;
            }
          }}
          nextText={intl.formatMessage({
            id: "next",
            defaultMessage: "Next"
          })}
          previousText={intl.formatMessage({
            id: "previous",
            defaultMessage: "Previous"
          })}
          noDataText={intl.formatMessage({
            id: "no_items_found",
            defaultMessage: "No items found"
          })}
          showPagination={false}
          manual
        />
        {this.state.printBarcode && (
          <BarcodeModal
            barcode={this.state.selectedBarcode.barcode}
            key={this.state.selectedBarcode.key}
            unmount={this.state.selectedBarcode.unmountBarcode}
            name={this.state.selectedBarcode.name}
            size={this.state.selectedBarcode.size}
            price={this.state.selectedBarcode.price}
            color={this.state.selectedBarcode.color}
            style={this.state.selectedBarcode.style}
            hidePrice={this.state.hidePrice}
          />
        )}
      </BaseModal>
    );
  }
}

var styles = StyleSheet.create({
  itemManagementContainer: {
    width: "100%",
    display: "flex",
    marginBottom: 20
  },
  bodyContainer: {
    maxHeight: "100%"
  },
  modal: {
    maxWidth: "100%",
    width: "100%",
    height: "100%",
    padding: 20
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 10,
    // fontVariant: 'small-caps',
    letterSpacing: 0.7
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 2
  },
  productName: {
    fontSize: 20,
    fontWeight: "bold"
  },
  itemInfoContainer: {
    marginLeft: 16
  },
  image: {
    border: "2px solid silver",
    width: 125,
    height: 125,
    borderRadius: 4
  },
  reactTable: {
    marginTop: 20,
    width: "100%",
    overflow: "auto",
    maxHeight: 600
  },
  cell: {
    padding: 0,
    borderColor: "#eee",
    borderBottom: "solid 1px #eee",
    minHeight: 100
  },
  sync: {
    background: "rgb(101, 193, 116)",
    borderColor: "rgb(101, 193, 116)",

    ":hover": {
      background: "rgba(101, 193, 116, .7)"
    }
  },
  cellValue: {
    textAlign: "center",
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  link: {
    textDecoration: "none",
    color: "#000"
  },
  input: {
    width: "100%",
    height: "100%",
    border: "none",
    textAlign: "center",
    fontSize: 16,
    padding: "auto"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 8
  },
  size: {
    fontSize: 22,
    fontWeight: 700
  },
  closeIcon: {
    position: "absolute",
    right: 50,
    top: 50,
    fontSize: "2.5em",
    cursor: "pointer"
  },
  numInput: {
    "-webkit-appearance": "textfield",
    "-moz-appearance": "textfield",
    appearance: "textfield"
  }
});

const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth,
  localization: state.localization
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ItemManagementModal)
);
