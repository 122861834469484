/***
 * The dropdown location component
 * @craiglu
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { getConsignerIds } from "../../config/api";

class Dropdown extends React.Component {
  render() {
    let consignerIds = getConsignerIds();
    let { inventory, intl, consigner } = this.props;

    let fetchedLocations = [...inventory.locations];
    let all = { id: 0, name: "All" };
    if (!this.props.removeAll) {
      fetchedLocations = [all, ...inventory.locations];
    }

    let defaultLocation = fetchedLocations.find(
      location => this.props.default && this.props.default === location.id
    );

    let locations = consigner
      ? fetchedLocations.map((location, index) => {
          if (location.consigner) {
            let consignerId;
            if (location.id === 4) {
              consignerId = consignerIds.warehouseId;
            } else if (location.id === 1) {
              consignerId = consignerIds.fairfaxId;
            }

            return (
              <option
                value={consignerId}
                key={location.id}
                selected={
                  this.props.default && this.props.default === location.id
                }
              >
                {location.id === 4 && consignerIds.warehouseEmail}
                {location.id === 1 && consignerIds.fairfaxEmail}
              </option>
            );
          }
          return null;
        })
      : fetchedLocations.map((location, index) => {
          return (
            <option
              value={location.id}
              key={location.id}
              selected={
                this.props.default && this.props.default === location.id
              }
            >
              {location.name}
            </option>
          );
        });
    return (
      <select
        className={css(styles.input, this.props.className)}
        onChange={this.props.onChange}
        readOnly={this.props.readOnly}
      >
        {(this.props.removeAll ||
          (defaultLocation && !defaultLocation.consigner)) && (
          <option disabled selected value>
            {this.props.consigner
              ? intl.formatMessage({
                  id: "choose_consigner",
                  defaultMessage: "Choose Consigner"
                })
              : intl.formatMessage({
                  id: "choose_location",
                  defaultMessage: "Choose Location"
                })}
          </option>
        )}
        {locations}
      </select>
    );
  }
}

var styles = StyleSheet.create({
  input: {
    width: "100%",
    maxWidth: 320,
    height: 38,
    borderRadius: 4,
    outline: "none",
    fontSize: 16,
    border: "1px solid #ddd",
    background: "#fff",
    cursor: "pointer"
  }
});

const mapStateToProps = state => ({
  // auth: state.auth,
  inventory: state.inventory
});

const mapDispatchToProps = dispatch => ({
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Dropdown)
);
