/***
 * Clover oauth redirect URL
 * @patr
 */

import React from "react";

// NPM Modules
import { connect } from "react-redux";
import qs from "qs";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";

class Clover extends React.Component {
  /***
   * Sends the code back to the backend
   */
  sendCodeToBackend = (code, client_id, merchant_id) => {
    return fetch(
      API.CLOVER_CODE,
      API.POST_CONFIG({ code, client_id, merchant_id })
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.props.history.push("/dashboard");
      });
  };

  componentDidMount = () => {
    let query = qs.parse(this.props.history.location.search);
    let code = query.code;
    let client_id = query.client_id;
    let merchant_id = query["?merchant_id"];

    this.sendCodeToBackend(code, client_id, merchant_id);
  };

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  // auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Clover);
