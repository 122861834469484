export const defaultInventoryHeaders = date_text => {
  return [
    {
      header: "SS ID",
      field: "id",
      filterable: true
    },
    {
      header: "Image",
      field: "pictures__url",
      filterable: false
    },
    {
      header: "Product Name",
      field: "sku__product__product_name_us",
      filterable: true
    },
    {
      header: "Size",
      field: "sku__us_size",
      filterable: true
    },
    {
      header: "Price",
      field: "consignment__price__default_price",
      filterable: true
    },
    {
      header: "Style",
      field: "sku__product__style",
      filterable: true
    },
    {
      header: "Used",
      field: "consignment__used",
      filterable: true
    },
    {
      header: "Consignment Status",
      field: "consignment__status",
      filterable: true
    },
    {
      header: "Listed Date",
      field: "consignment__listed_at",
      filterable: true
    },
    {
      header: date_text,
      field: "",
      filterable: false
    },
    {
      header: "Consigner",
      field: "consigner__first_name",
      filterable: true
    },
    {
      header: "Consignment Order",
      field: "consignment__order",
      filterable: true
    }
  ];
};

export const setOrderingAttribute = (state, params) => {
  let ordering = `-consignment__${state.activeTab}_at`;
  let sorted = state.sorted;

  if (state.activeTab === "sold") {
    ordering = "-sold_at";
  }

  if (sorted && sorted[0]) {
    const foundInventoryHeader = defaultInventoryHeaders("").find(
      element => element.header === sorted[0].id
    );
    if (foundInventoryHeader && foundInventoryHeader.filterable) {
      const complement = foundInventoryHeader
        ? foundInventoryHeader.field
        : sorted[0].id;
      params["ordering"] = (sorted[0].desc ? "" : "-") + complement;
    }
  } else {
    params["ordering"] = ordering;
  }
};

export const setIsUsedAttribute = (state, params) => {
  if (state.isUsed != null) {
    params["isUsed"] = state.isUsed;
  }
};

export const setDeletedAttribute = (state, params) => {
  if (state.activeTab === "deleted") {
    params["deleted"] = true;
  } else {
    params["deleted"] = false;
  }
};
