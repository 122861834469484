/**
 * Auth page for login and signup
 * @craiglu
 */

import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";

// Components
import LoginForm from "../../components/auth/LoginForm";
import SignupForm from "../../components/auth/SignupForm";
import Input from "../../components/Input";
import Button from "../../components/Button";

// Redux
import { AuthActions } from "../../redux/auth";
import { MessageActions } from "../../redux/message";

// Configs
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Lib
import queryString from "qs";

class Auth extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forgotPassword: false,
      email: "",
      passwordResetSent: false
    };
  }

  componentDidMount() {
    let { authActions } = this.props;
    if (!this.props.login) {
      var query = queryString.parse(
        this.props.history.location.search.replace("?", "")
      );
      let email = query["email"];
      let token = query["token"];
      authActions
        .authenticateToken(token, email)
        .then(authenticated => {
          if (!authenticated) {
            this.props.history.push("/404");
          }
        })
        .catch(error => {
          this.props.history.push("/404");
        });
    }
  }

  /***
   * Hits devise's endpoint to trigger a reset password email
   */
  resetPassword = e => {
    e.preventDefault();
    let { messageActions } = this.props;
    fetch(
      API.PASSWORD_RESET,
      API.POST_CONFIG({
        email: this.state.email,
        redirect_url: "/password/reset"
      })
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(_ => {
        this.setState({
          passwordResetSent: true
        });
        messageActions.setMessage("Password Reset Email Sent");
        messageActions.showMessage({ show: true });
      })
      .catch(error => {
        if (error.response) {
          error.response.json().then(errMsg => {
            let message = errMsg.errors.full_messages
              ? errMsg.errors.full_messages[0]
              : errMsg.errors[0];
            messageActions.setMessage(message);
            messageActions.showMessage({ show: true, error: true });
          });
        }
      });
  };

  updateSignupInfo = e => {
    let { authActions } = this.props;
    let name = "";
    let value = "";
    if (e.target) {
      name = e.target.name;
      value = e.target.value;
      if (name === "address_state") {
        value = value.toUpperCase();
      } else if (name === "email") {
        value = value.toLowerCase();
      }
    } else {
      name = "brand";
      value = e.value;
    }
    authActions.updateSignupInfo(name, value);
  };

  render() {
    let { auth, authActions, routing, intl } = this.props;
    return (
      <div className={css(styles.authContainer)}>
        {auth.fetchingAuthenticationToken ? null : (
          <div className={css(styles.form)}>
            <h2 className={css(styles.title)}>
              {intl.formatMessage({ id: "login", defaultMessage: "Login" })}
            </h2>
            <p className={css(styles.tagline)}>
              {" "}
              SoleStage Management Dashboard{" "}
            </p>
            {this.state.forgotPassword ? (
              <form
                className={css(styles.forgotPassword)}
                onSubmit={this.resetPassword}
              >
                <div className={css(styles.forgotPasswordText)}>
                  {intl.formatMessage({
                    id: "enter_email",
                    defaultMessage:
                      "Enter the email address associated with your account, and we’ll email you a link to reset your password."
                  })}
                </div>
                <Input
                  className={styles.input}
                  placeholder={intl.formatMessage({
                    id: "email_address",
                    defaultMessage: "Email Address"
                  })}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <br />
                <Button
                  button_color={styles.buttonColor}
                  text={
                    !this.state.passwordResetSent
                      ? intl.formatMessage({
                          id: "reset_password",
                          defaultMessage: "Reset Password"
                        })
                      : intl.formatMessage({
                          id: "password_reset_sent",
                          defaultMessage: "Password Reset Sent!"
                        })
                  }
                  disabled={this.state.passwordResetSent}
                />
              </form>
            ) : this.props.login ? (
              <LoginForm
                auth={auth}
                login={authActions.login}
                getProfile={authActions.getUser}
                history={this.props.history}
                routing={routing}
              />
            ) : (
              <SignupForm
                auth={auth}
                register={authActions.register}
                inputChange={this.updateSignupInfo}
                history={this.props.history}
              />
            )}
            <div className={css(styles.authSwitch)}>
              {this.props.login ? null : (
                <div className={css(styles.login)}>
                  {intl.formatMessage({
                    id: "reset_password",
                    defaultMessage: "Already have an account?"
                  })}{" "}
                  <Link className={css(styles.link)} to="/login">
                    {intl.formatMessage({
                      id: "login_here",
                      defaultMessage: "Login here"
                    })}
                  </Link>
                </div>
              )}
              {this.props.login ? (
                this.state.forgotPassword ? (
                  <div className={css(styles.login)}>
                    <span
                      className={css(styles.link)}
                      onClick={() => this.setState({ forgotPassword: false })}
                    >
                      {intl.formatMessage({
                        id: "back_login",
                        defaultMessage: "Back to Login"
                      })}
                    </span>
                  </div>
                ) : (
                  <div className={css(styles.login)}>
                    <span
                      className={css(styles.link)}
                      onClick={() => this.setState({ forgotPassword: true })}
                    >
                      {intl.formatMessage({
                        id: "forgot_password",
                        defaultMessage: "Forgot your password?"
                      })}
                    </span>
                  </div>
                )
              ) : null}
            </div>
          </div>
        )}
      </div>
    );
  }
}

var styles = StyleSheet.create({
  authContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    boxSizing: "border-box",
    padding: "20px",
    background: "rgb(246, 249, 252)"
  },
  rexLogo: {
    width: "100%",
    marginBottom: "20px",

    "@media only screen and (min-width: 320px)": {
      width: "80%"
    },

    "@media only screen and (min-width: 768px)": {
      width: "280px"
    }
  },
  input: {
    padding: 15
  },
  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 28,
    background: "#fff",
    flexDirection: "column",
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  title: {
    margin: "12px 0px",
    letterSpacing: 1,
    fontWeight: 400,
    fontSize: 40
  },
  tagline: {
    letterSpacing: 0.7,
    opacity: 0.8
  },
  authSwitch: {
    // marginBottom: '30px',
  },
  login: {
    opacity: 0.7,
    marginTop: 16
  },
  forgotPassword: {
    color: "#fff",
    "@media only screen and (min-width: 320px)": {
      width: "100%"
    },

    "@media only screen and (min-width: 768px)": {
      width: "440px"
    }
  },
  forgotPasswordText: {
    marginBottom: 16,
    textAlign: "center"
  },
  buttonColor: {
    background: `${colors.RED(1)}`,
    marginTop: 16
  },
  link: {
    color: `${colors.RED(1)}`,
    textDecoration: "none",
    cursor: "pointer"
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  routing: state.routing
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Auth)
);
