/***
 * Action / Reducer file for authentication / user handling
 * @patr + @craiglu
 */

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

import API from "../config/api";
import Helpers from "./helpers";

export const AuthConstants = {
  LOGIN: "@@auth/LOGIN",
  REGISTER: "@@auth/REGISTER",
  FETCHING_REGISTER: "@@auth/FETCHING_REGISTER",
  FETCHING_LOGIN: "@@auth/FETCHING_LOGIN",
  FETCHING_USER: "@@auth/FETCHING_USER",
  GOT_USER: "@@auth/GOT_USER",
  ERROR: "@@auth/ERROR",
  GENERATING_API_KEY: "@@auth/GENERATING_API_KEY",
  GENERATED_API_KEY: "@@auth/GENERATED_API_KEY",
  UPDATE_USER_PROFILE: "@@auth/UPDATE_USER_PROFILE",
  SAVING_PROFILE_CHANGES: "@@auth/SAVING_PROFILE_CHANGES",
  SAVED_PROFILE_CHANGES: "@@auth/SAVED_PROFILE_CHANGES",
  CANCEL_SUBSCRIPTION: "@@auth/CANCEL_SUBSCRIPTION",
  AUTHENTICATE_TOKEN: "@@auth/AUTHENTICATE_TOKEN",
  UPDATE_SIGNUP_INFO: "@@auth/UPDATE_SIGNUP_INFO"
};

function saveToLocalStorage(key, value) {
  var storage = window.localStorage;
  storage.setItem(key, value);
  return;
}

const TOKEN = "solestage.management.token";

let getUserHelper = (dispatch, dispatchFetching) => {
  if (!dispatchFetching) {
    dispatch({ type: AuthConstants.FETCHING_USER, isFetchingUser: true });
  }
  var config = API.GET_CONFIG();
  return fetch(API.USER, config)
    .then(Helpers.checkStatus)
    .then(Helpers.parseJSON)
    .then(json => {
      if (json.results.length > 0) {
        return dispatch({
          type: AuthConstants.GOT_USER,
          isFetchingUser: false,
          isLoggedIn: true,
          authChecked: true,
          userProfile: json.results[0]
        });
      }

      return dispatch({
        type: AuthConstants.GOT_USER,
        isFetchingUser: false,
        isLoggedIn: false,
        authChecked: true
      });
    })
    .catch(error => {
      dispatch({
        type: AuthConstants.GOT_USER,
        isFetchingUser: false,
        authChecked: true
      });
      return error;
    });
};

export const AuthActions = {
  /***
   * Login with django-rest-auth
   * @params {params} -- a dict of str username/email, str password
   */
  login: params => {
    return dispatch => {
      var postConfig = API.POST_CONFIG(params);
      delete postConfig["headers"]["Authorization"];
      dispatch({ type: AuthConstants.FETCHING_LOGIN, isFetchingLogin: true });
      return fetch(API.LOGIN, postConfig)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(json => {
          saveToLocalStorage(TOKEN, json.token);
          return dispatch({
            type: AuthConstants.LOGIN,
            isLoggedIn: true,
            isFetchingLogin: false,
            error: null
          });
        })
        .catch(error => {
          if (error.message) {
            return dispatch({
              type: AuthConstants.ERROR,
              error: error.message
            });
          } else {
            return dispatch({
              type: AuthConstants.ERROR,
              error: error
            });
          }
        });
    };
  },

  /**
   * Authenticates signup token
   * @param  {Object} token -- token to send to backend to authenticate
   */
  authenticateToken: (token, email) => {
    return dispatch => {
      dispatch({
        type: AuthConstants.AUTHENTICATE_TOKEN,
        fetchingAuthenticationToken: true
      });
      return fetch(API.AUTHENTICATE_TOKEN(token, email), API.GET_CONFIG())
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(json => {
          if (json.status === 403) {
            return false;
          } else {
            return dispatch({
              type: AuthConstants.AUTHENTICATE_TOKEN,
              fetchingAuthenticationToken: false,
              signupInfo: {
                first_name: "",
                last_name: "",
                email: json.email,
                password: ""
              }
            });
          }
        });
    };
  },

  /***
   * Login with Facebook
   * @params {params} -- a dict of str username/email, str password
   */
  facebookLogin: params => {
    return dispatch => {
      let postConfig = API.POST_CONFIG(params);
      delete postConfig["headers"]["Authorization"];
      return fetch(API.FACEBOOK_LOGIN, postConfig)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(json => {
          saveToLocalStorage(TOKEN, json.key);
          return dispatch({
            type: AuthConstants.LOGIN,
            isLoggedIn: true,
            isFetchingLogin: false
          });
        })
        .catch(error => {
          // TODO: what to do with errors?
          if (error.response.status === 401) {
            console.log(error.response);
            return error.response;
          } else {
            console.log(error);
          }
        });
    };
  },

  /***
   * Register with django-rest-auth
   * @params {params} -- a dict of str username, str email, str password
   */
  register: params => {
    params["password2"] = params.password;
    params["password1"] = params.password;
    params["employee"] = true;
    var postConfig = API.POST_CONFIG(params);
    delete postConfig["headers"]["Authorization"];
    return dispatch => {
      dispatch({
        type: AuthConstants.FETCHING_REGISTER,
        isFetchingRegister: true
      });
      return fetch(API.REGISTER, postConfig)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(json => {
          saveToLocalStorage(TOKEN, json.key);

          return dispatch({
            type: AuthConstants.REGISTER,
            isLoggedIn: true,
            isFetchingRegister: false,
            error: null
          });
        })
        .catch(error => {
          if (error.message) {
            return dispatch({
              type: AuthConstants.ERROR,
              error: error.message
            });
          } else {
            return dispatch({
              type: AuthConstants.ERROR,
              error: error
            });
          }
        });
    };
  },

  /***
   * Signs a user out
   */
  signout: () => {
    return dispatch => {
      return fetch(API.SIGNOUT, API.POST_CONFIG())
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(json => {
          window.localStorage.removeItem(TOKEN);
          window.location.replace("/");
        });
    };
  },

  /***
   * Gets a user profile back from the backend
   */
  getUser: (dispatchFetching = false) => {
    return dispatch => {
      return getUserHelper(dispatch, dispatchFetching);
    };
  },

  /**
   * update signup info
   * @param  {String} id -- String id of info needed to be updated
   * @param  {String} value -- String value
   */
  updateSignupInfo: (id, value) => {
    return dispatch => {
      var param = { signupInfo: {} };
      param.signupInfo[`${id}`] = value;
      return dispatch({
        type: AuthConstants.UPDATE_SIGNUP_INFO,
        signupInfo: param.signupInfo
      });
    };
  },

  /**
   * update the profile on frontend
   */
  updateUserProfile: params => {
    return dispatch => {
      return dispatch({
        type: AuthConstants.UPDATE_USER_PROFILE,
        userProfile: params
      });
    };
  },

  /***
   * Save changes to user profile
   */
  saveProfileChanges: () => {
    return (dispatch, getState) => {
      let userProfile = getState().auth.userProfile;
      let putConfig = API.PUT_CONFIG(userProfile);
      dispatch({
        type: AuthConstants.SAVING_PROFILE_CHANGES,
        savingProfileChanges: true
      });
      return fetch(API.USER + `${userProfile.id}/`, putConfig)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(json => {
          return dispatch({
            type: AuthConstants.SAVED_PROFILE_CHANGES,
            savingProfileChanges: false
          });
        });
    };
  },

  /***
   * cancel active subscription at end of billing cycle
   */
  cancelSubscription: () => {
    return (dispatch, getState) => {
      let userProfile = getState().auth.userProfile;
      let putConfig = API.PUT_CONFIG({ id: userProfile.subscription.id });
      return fetch(API.STRIPE, putConfig)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(json => {
          return dispatch({
            type: AuthConstants.CANCEL_SUBSCRIPTION
          });
        });
    };
  },

  /**
   * update the user profile with subscription information
   * @param  {Object} userProfile   -- user profile from backend
   */
  updateUserSubscription: userProfile => {
    return dispatch => {
      return dispatch({
        type: AuthConstants.UPDATE_USER_PROFILE,
        userProfile: userProfile
      });
    };
  }
};

/**********************************
 *        REDUCER SECTION         *
 **********************************/

const defaultAuthState = {
  isLoggedIn: false,
  isFetchingLogin: false,
  generatingAPIKey: false,
  authChecked: false,
  userProfile: {
    subscription: {}
  },
  error: null
};

const AuthReducer = (state = defaultAuthState, action) => {
  switch (action.type) {
    case AuthConstants.LOGIN:
    case AuthConstants.REGISTER:
    case AuthConstants.FETCHING_REGISTER:
    case AuthConstants.FETCHING_LOGIN:
    case AuthConstants.FETCHING_USER:
    case AuthConstants.GOT_USER:
    case AuthConstants.GENERATING_API_KEY:
    case AuthConstants.ERROR:
    case AuthConstants.AUTHENTICATE_TOKEN:
      return {
        ...state,
        ...action
      };
    case AuthConstants.UPDATE_SIGNUP_INFO:
      return {
        ...state,
        ...action,
        signupInfo: { ...state.signupInfo, ...action.signupInfo }
      };
    case AuthConstants.GENERATED_API_KEY:
      return {
        ...state,
        ...action,
        userProfile: { ...state.userProfile, api_key: action.api_key }
      };
    case AuthConstants.UPDATE_USER_PROFILE:
      return {
        ...state,
        ...action,
        userProfile: { ...state.userProfile, ...action.userProfile }
      };
    default:
      return state;
  }
};

export default AuthReducer;
