/***
 * Barcode component to print
 * @patr
 */
import React from "react";
import ReactBarcode from "react-barcode";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import CurrencyDisplay from "../CurrencyDisplay";

export default class Barcode extends React.Component {
  render() {
    return (
      <div className={css(styles.barcode)}>
        <div className={css(styles.name, styles.info)}>{this.props.name}</div>
        {this.props.conditions && this.props.conditions.length > 0 && (
          <div className={css(styles.conditions, styles.info)}>
            {this.props.conditions.join(", ")}
          </div>
        )}
        <div className={css(styles.inline)}>
          <div className={css(styles.size, styles.info)}>
            sz. {this.props.size},
          </div>
          {!this.props.hidePrice && (
            <div className={css(styles.price, styles.info)}>
              <CurrencyDisplay price={this.props.price} />
            </div>
          )}
          {this.props.style && !this.props.style.includes(this.props.name) && (
            <div className={css(styles.info)}>style: {this.props.style}</div>
          )}
        </div>
        <div className={css(styles.color, styles.info)}>
          color: {this.props.color}
        </div>
        <div className={css(styles.reactbarcode)}>
          <ReactBarcode value={this.props.barcode} height={45} fontSize={12} />
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  barcode: {
    width: 250,
    pageBreakAfter: "always"
    // maxHeight: 125,
  },
  reactbarcode: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  info: {
    textAlign: "center",
    marginTop: 4,
    fontSize: 12,
    marginRight: 8
  },
  inline: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  size: {
    marginRight: 16
  },
  consigner: {
    fontSize: 12
  },
  price: {
    fontSize: 16,
    marginRight: 8,
    fontWeight: "bold"
  },
  conditions: {
    fontSize: 12
  },
  color: {
    fontSize: 8
  }
});
