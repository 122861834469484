import React from "react";
// NPM Modules
import { StyleSheet, css } from "aphrodite";
// import { FormattedMessage } from 'react-intl';

import { injectIntl } from "react-intl";

class RoleDropdown extends React.Component {
  constructor(props) {
    super(props);
    let { intl } = this.props;
    this.roles = [
      {
        id: "Admin",
        name: intl.formatMessage({ id: "admin", defaultMessage: "Admin" })
      },
      {
        id: "Store Manager",
        name: intl.formatMessage({
          id: "store_manager",
          defaultMessage: "Store Manager"
        })
      },
      {
        id: "Accountant",
        name: intl.formatMessage({
          id: "accountant",
          defaultMessage: "Accountant"
        })
      },
      {
        id: "Warehouse Manager",
        name: intl.formatMessage({
          id: "warehouse_manager",
          defaultMessage: "Warehouse Manager"
        })
      },
      {
        id: "Warehouse Employee",
        name: intl.formatMessage({
          id: "warehouse_employee",
          defaultMessage: "Warehouse Employee"
        })
      },
      {
        id: "Store Employee",
        name: intl.formatMessage({
          id: "store_employee",
          defaultMessage: "Store Employee"
        })
      },
      {
        id: "Retail Floor",
        name: intl.formatMessage({
          id: "retail_floor",
          defaultMessage: "Retail Floor iPad"
        })
      }
    ];
  }
  render() {
    let { intl } = this.props;
    let roles = this.roles.map((location, index) => {
      return (
        <option value={location.id} key={location.id}>
          {location.name}
        </option>
      );
    });
    return (
      <select
        className={css(styles.input, this.props.className)}
        onChange={this.props.onChange}
        readOnly={this.props.readOnly}
      >
        <option disabled selected value className={css(styles.choose)}>
          {intl.formatMessage({
            id: "choose_role",
            defaultMessage: "Choose Role"
          })}
        </option>
        {roles}
      </select>
    );
  }
}
var styles = StyleSheet.create({
  input: {
    width: 200,
    height: 38,
    borderRadius: 4,
    outline: "none",
    fontSize: 16,
    border: "1px solid #ddd",
    background: "#fff",
    cursor: "pointer"
  },
  choose: {
    color: "rgba(0, 0, 0, .7)"
  }
});

export default injectIntl(RoleDropdown);
