/***
 * Consignment screen for Solestage
 * @patr -- patrick@quantfive.org
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { FormattedMessage, injectIntl } from "react-intl";
import TimeAgo from "javascript-time-ago";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
// Components
import Button from "../../components/Button";
import Searchbar from "../../components/inventory/Searchbar";
import Dropdown from "../../components/inventory/Dropdown";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Redux
import { ModalActions } from "../../redux/modals";
import { JoyrideActions } from "../../redux/joyride";
import { InventoryActions } from "../../redux/inventory";

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// Create relative date/time formatter.
// const timeAgo = new TimeAgo("en-US");

class InventoryReconciliation extends React.Component {
  constructor(props) {
    super(props);

    let { joyride, intl, locationId } = this.props;

    this.tabs = [
      {
        id: "pending",
        label: intl.formatMessage({
          id: "pending",
          defaultMessage: "Pending"
        })
      },
      {
        id: "completed",
        label: intl.formatMessage({
          id: "completed",
          defaultMessage: "Completed"
        })
      },
      {
        id: "all",
        label: intl.formatMessage({
          id: "all_request",
          defaultMessage: "All"
        })
      }
    ];

    this.guideData = [
      {
        Consigner: "Johnny Champ",
        Consignment: "#76",
        Date: "a month ago",
        Price: "$900",
        Quantity: 1,
        Status:
          joyride.feature === "list_consignment" ? "completed" : "pending",
        items: [
          {
            active: true,
            clover_id: null,
            condition: "New",
            consignment_order: 76,
            cost: null,
            created_at: "2018-11-03T12:53:38.336007-07:00",
            id: 148660,
            image_link:
              "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg",
            in_transit: false,
            labelled: false,
            listable: false,
            listed: false,
            location: null,
            price: null,
            shelf: "",
            sold: false,
            sold_at: null,
            consigner: {
              billing_address: null,
              consignment_approved: true,
              consignment_rate: 15,
              created_at: "2018-09-24T17:55:32.379915-07:00",
              email: "cu@quantfive.org",
              first_name: "Johnny",
              id: 15,
              last_name: "Champ",
              phone_number: "8502944844",
              return_address: null,
              shipping_address: {
                id: 14,
                state: "CA",
                country: "USA",
                city: "Los Angeles",
                zip_code: "94709"
              },
              updated_at: "2018-11-07T00:00:04.717238-08:00",
              user: 21,
              verified_consigner: true
            },
            consignment: {
              apparel_package_condition: "",
              barcode_printed: false,
              box_condition: "Good Condition",
              clover_item_id: "",
              created_at: "2018-11-03T12:58:53.342228-07:00",
              defects: false,
              discolored: false,
              id: 206,
              missing_insoles: false,
              missing_tags: false,
              order: 77,
              payout: null,
              pending_price: null,
              pictures: [],
              price: {
                id: 201,
                default_price: 90000,
                default_currency: "usd",
                solestage_price: 90000,
                instore_price: 90000
              },
              scuff_marks: false,
              size: "10",
              status: "approved",
              tears: false,
              updated_at: "2018-11-03T13:11:19.096066-07:00",
              yellowing: false
            },
            pictures: [
              {
                id: 20504,
                sku: 20549,
                url:
                  "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg"
              }
            ],
            updated_at: "2018-11-03T12:55:28.734470-07:00",
            sku: {
              active: true,
              barcode: "12345678999",
              brand: "Adidas",
              color: "Beluga",
              created_at: "2018-11-09T08:44:04.436342-08:00",
              default_location: null,
              eu_size: null,
              gender: "M",
              id: 20549,
              item_type: "shoe",
              product_name: "Adidas Yeezy Boost",
              product_name_us: "Adidas Yeezy Boost",
              sku: null,
              style: "508214-660",
              updated_at: "2018-11-09T08:44:04.781280-08:00",
              us_size: "10"
            }
          }
        ],
        request_id: 76
      }
    ];

    this.state = {
      data: [],
      numPages: 1,
      page: 1,
      pageSize: 20,
      hoveredRow: null,
      activeTab:
        joyride.feature === "list_consignment" ? "completed" : "pending",
      count: null,
      barcodes: [],
      search: "",
      location: locationId
    };
  }

  /***
   * Searches the backend for SKU, Barcode, Product Name
   */
  search = async e => {
    if (e.key === "Enter") {
      this.setState({
        page: 1,
        searching: true
      });
      if (this.state.activeTab === "pending" && !this.state.search) {
        await this.getReconciliations(
          1,
          this.state.pageSize,
          "pending",
          this.state.location
        );
      } else if (this.state.activeTab === "completed" && !this.state.search) {
        await this.getReconciliations(
          1,
          this.state.pageSize,
          "completed",
          this.state.location
        );
      } else {
        await this.getReconciliations();
      }
      this.setState({
        searching: false
      });
    }
  };

  /***
   * Gets all price change requests
   */
  getReconciliations = (page, pageSize, status, location) => {
    // let { auth } = this.props;
    let params = {
      page,
      pageSize,
      status,
      location
    };

    if (this.state.search) {
      params["search"] = this.state.search;
    }

    this.setState({
      searching: true
    });

    return fetch(API.INVENTORY_RECONCILIATION(params), API.GET_CONFIG(false))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = [];
        this.setState({
          searching: false
        });
        if (res.results) {
          data = res.results.map((inventoryReconciliation, index) => {
            return {
              ID: `#${inventoryReconciliation.id}`,
              reconciliation_id: inventoryReconciliation.id,
              "Start Date": inventoryReconciliation.start_date
                ? moment(inventoryReconciliation.start_date).format(
                    "MM/DD/YYYY"
                  )
                : null,
              "Submission Date": inventoryReconciliation.submit_date
                ? new Date(
                    inventoryReconciliation.submit_date
                  ).toLocaleDateString("en-US")
                : null,
              Status: inventoryReconciliation.status,
              Name: inventoryReconciliation.name,
              Notes: inventoryReconciliation.notes,
              Sessions: inventoryReconciliation.sessions.length,
              "Total Scanned": inventoryReconciliation.total_scanned,
              "Total Inventory":
                inventoryReconciliation.snapshot_item_ids.length,
              Missing: inventoryReconciliation.missing_inventory_stringified_ids
                ? inventoryReconciliation.missing_inventory_stringified_ids.split(
                    ","
                  ).length
                : inventoryReconciliation.snapshot_item_ids.length
            };
          });
        }
        this.setState({
          numPages: Math.ceil(res.count / this.state.pageSize),
          count: res.count,
          data
        });
      });
  };

  /***
   * Sets the search value
   */
  searchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    this.setState(
      {
        activeTab: tab,
        page: 1
      },
      () => {
        let status = null;
        if (tab === "pending") {
          status = "pending";
        } else if (tab === "completed") {
          status = "completed";
        }
        this.getReconciliations(
          1,
          this.state.pageSize,
          status,
          this.state.location
        );
      }
    );
  };

  renderCell = (header, props, index) => {
    switch (header) {
      case "Status":
        let completed = props.value === "completed";
        if (completed) {
          return (
            <div
              className={css(styles.cellValue, styles.status, styles.fulfilled)}
            >
              {props.value}
            </div>
          );
        } else {
          return (
            <div
              className={css(
                styles.cellValue,
                styles.status,
                styles.unfulfilled
              )}
            >
              {props.value}
            </div>
          );
        }
      case "Missing":
        return <div className={css(styles.cellValue)}>{props.value}</div>;
      default:
        return <div className={css(styles.cellValue)}>{props.value}</div>;
    }
  };

  reconciliationClicked = id => {
    let { history } = this.props;
    history.push(`/reconciliation/${id}`);
  };

  componentDidMount = () => {
    if (this.props.joyride.forceJoy) {
      let { joyrideActions } = this.props;
      joyrideActions.setRun({ run: true, inProgress: true });
    }
  };

  onLocationChange = e => {
    let value = e.target.value;
    if (e.target.value === "0") {
      value = null;
    }

    const { pageSize, activeTab } = this.state;
    this.setState(
      {
        location: value,
        page: 1,
        firstLoad: true
      },
      () => {
        this.getReconciliations(1, pageSize, activeTab, value);
        this.props.setLocation(parseInt(value));
      }
    );
  };

  createInventoryReconciliation = () => {
    const { location } = this.state;
    let name = prompt("Reconciliation Name");
    if (!name) {
      alert("Please enter reconciliation name.");
      return;
    }
    const params = {
      location: parseInt(location),
      name
    };

    return fetch(API.INVENTORY_RECONCILIATION({}), API.POST_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.props.history.push(`/reconciliation/${res.id}`);
      });
  };

  render() {
    let { intl } = this.props;
    let columns = [
      intl.formatMessage({
        id: "id",
        defaultMessage: "ID"
      }),
      intl.formatMessage({ id: "start_date", defaultMessage: "Start Date" }),
      intl.formatMessage({ id: "name", defaultMessage: "Name" }),
      intl.formatMessage({ id: "notes", defaultMessage: "Notes" }),
      intl.formatMessage({
        id: "submission_date",
        defaultMessage: "Submission Date"
      }),
      intl.formatMessage({ id: "status", defaultMessage: "Status" }),
      intl.formatMessage({ id: "sessions", defaultMessage: "Sessions" }),
      intl.formatMessage({
        id: "total_scanned",
        defaultMessage: "Total Scanned"
      }),
      intl.formatMessage({
        id: "total_inventory",
        defaultMessage: "Total Inventory"
      }),
      intl.formatMessage({ id: "missing", defaultMessage: "Missing" })
    ].map((column, index) => ({
      Header: column
        ? column !== "table-id"
          ? column !== "manage"
            ? column
            : null
          : null
        : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      filterable: false,
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => (
        <div
          className={css(
            styles.valueContainer,
            props.row._index === this.state.hoveredRow && styles.hoveredRow
          )}
          id={
            window.location.search.includes("guide=true") &&
            props.row._index === 0 &&
            "clickRow"
          }
          onClick={() =>
            this.reconciliationClicked(props.original["ID"].replace("#", ""))
          }
          onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
        >
          {this.renderCell(props.column.Header, props, index)}
        </div>
      )
    }));

    let tabs = this.tabs.map((tab, index) => {
      return (
        <div
          className={css(
            styles.tab,
            this.state.activeTab === tab.id && styles.activeTab
          )}
          onClick={() => this.setActiveTab(tab.id)}
          key={`tab_${tab.id}_${index}`}
          // id={tab.id === "completed" && "completedTab"}
        >
          {tab.label}
        </div>
      );
    });

    return (
      <div className={css(styles.orders)}>
        <h2 className={css(styles.dashboardTitle)}>
          <FormattedMessage
            id="reconciliation_tab"
            defaultMessage="Inventory Reconciliation"
          />
        </h2>
        <div className={css(styles.tabs)}>{tabs}</div>
        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <div className={css(styles.search)} id="search">
            <Searchbar
              search={this.search}
              searchChange={this.searchChange}
              searching={this.state.searching}
            />
            <Dropdown
              onChange={this.onLocationChange}
              default={this.state.location}
              removeAll
            />
            <Button
              className={styles.createButton}
              text={
                this.state.fetching ? (
                  <FontAwesomeIcon icon={["far", "spinner-third"]} spin />
                ) : (
                  intl.formatMessage({
                    id: "start_new",
                    defaultMessage: "+ Start New"
                  })
                )
              }
              onClick={this.createInventoryReconciliation}
            />
          </div>
          <div id="approveConsignment">
            <ReactTable
              className={css(styles.reactTable) + " order-table"}
              id="approveConsignment"
              data={
                window.location.search.includes("guide=true")
                  ? this.guideData
                  : this.state.data
              }
              filterable={true}
              pages={this.state.numPages}
              columns={columns}
              footerClassName={css(styles.tableFooter)}
              defaultPageSize={this.state.pageSize}
              page={this.state.page - 1}
              onPageSizeChange={pageSize => {
                this.setState({
                  pageSize,
                  numPages: Math.ceil(this.state.totalItems / pageSize)
                });
              }}
              onPageChange={pageIndex => {
                this.setState({
                  page: pageIndex + 1
                });
              }}
              minRows={0}
              onFetchData={(state, instance) => {
                if (window.location.search.includes("guide=true")) {
                  return null;
                }
                this.getReconciliations(
                  state.page + 1,
                  state.pageSize,
                  this.state.activeTab,
                  this.state.location
                );
              }}
              nextText={intl.formatMessage({
                id: "next",
                defaultMessage: "Next"
              })}
              previousText={intl.formatMessage({
                id: "previous",
                defaultMessage: "Previous"
              })}
              noDataText={intl.formatMessage({
                id: "no_items_found",
                defaultMessage: "No items found"
              })}
              manual
            />
          </div>
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  search: {
    display: "flex"
  },
  orders: {
    textAlign: "center",
    padding: 20,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  status: {
    textTransform: "capitalize"
  },
  table: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
    color: "#212b36",
    borderBottom: "1px solid #ddd"
  },
  productColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    whiteSpace: "pre-wrap"
  },
  itemPhoto: {
    width: 75,
    height: 75,
    paddingBottom: 8
  },
  orderNumber: {
    color: colors.LIGHT_BLUE(1)
  },
  hoveredRow: {
    background: "#eee"
  },
  link: {
    textDecoration: "none"
  },
  unfulfilled: {
    background: "#ffea8a",
    padding: "8px 8px",
    borderRadius: 24
  },
  rejected: {
    padding: "8px 8px",
    borderRadius: 24,
    color: "red"
  },
  fulfilled: {
    padding: "8px 8px",
    borderRadius: 24,
    background: "#dfe3e8"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 16,
    position: "relative"
  },
  tab: {
    marginLeft: 7,
    marginRight: 7,
    opacity: ".3",
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  },
  button: {
    width: 200,
    position: "absolute",
    right: 0,
    bottom: 5
  },
  createButton: {
    width: 200,
    height: 38,
    marginLeft: 20,
    fontSize: 16,
    padding: 10
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  joyride: state.joyride
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  joyrideActions: bindActionCreators(JoyrideActions, dispatch),
  setLocation: InventoryActions.setLocation
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InventoryReconciliation));
