import API from "../../config/api";
import Helpers from "../helpers";

/***
 * Action / Reducer for Inventory Management
 */

/**********************************
 *        CONSTANTS SECTION       *
 **********************************/

const InventoryConstants = {
  ADD_INVENTORY: "@@inventory/ADD_INVENTORY",
  GET_LOCATIONS: "@@inventory/GET_LOCATIONS",
  SET_LOCATION: "@@inventory/SET_LOCATION"
};

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

export const InventoryActions = {
  getLocations: () => {
    return dispatch => {
      return fetch(API.LOAD_LOCATIONS(), API.GET_CONFIG())
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          // let all = {id: 0, name: 'All'}
          let locations = [...res.results];

          return dispatch({
            type: InventoryConstants.GET_LOCATIONS,
            locations
          });
        });
    };
  },
  setLocation: locationId => {
    return dispatch => {
      return dispatch({
        type: InventoryConstants.SET_LOCATION,
        locationId
      });
    };
  },
  addInventory: file => {
    return dispatch => {
      fetch(
        API.IMPORT_EXCEL,
        API.POST_FILE_CONFIG("file", file, "sheet_name", null)
      )
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          return dispatch({
            type: InventoryConstants.ADD_INVENTORY
          });
        });
    };
  }
};

/*********************************
 *        REDUCER SECTION         *
 **********************************/

const initialState = {
  locations: []
};

const inventoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case InventoryConstants.ADD_INVENTORY:
    case InventoryConstants.GET_LOCATIONS:
      return {
        ...state,
        ...action
      };
    case InventoryConstants.SET_LOCATION:
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

export default inventoryReducer;
