/***
 * Consignment screen for Solestage
 * @patr -- patrick@quantfive.org
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { FormattedMessage, injectIntl } from "react-intl";
import TimeAgo from "javascript-time-ago";
import numeral from "numeral";

// Components
import ApproveConsignmentModal from "../../components/modals/ApproveConsignmentModal";
import Button from "../../components/Button";
import AddConsignmentModal from "../../components/modals/AddConsignment/AddConsignmentModal";
import BarcodeModal from "../../components/modals/AddProduct/BarcodeModal";
import Searchbar from "../../components/inventory/Searchbar";
import Dropdown from "../../components/inventory/Dropdown";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Redux
import { ModalActions } from "../../redux/modals";
import { JoyrideActions } from "../../redux/joyride";
import Actions from "../../redux/inventory/actions";

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// Create relative date/time formatter.
// const timeAgo = new TimeAgo("en-US");

class Consignment extends React.Component {
  constructor(props) {
    super(props);

    let { joyride, intl } = this.props;
    let tab = joyride.feature === "list_consignment" ? "approved" : "pending";
    let activeTab = this.getParams(window.location.href).tab
      ? this.getParams(window.location.href).tab
      : tab;

    this.tabs = [
      {
        id: "pending",
        label: intl.formatMessage({ id: "pending", defaultMessage: "Pending" })
      },
      {
        id: "approved",
        label: intl.formatMessage({
          id: "approved",
          defaultMessage: "Approved"
        })
      },
      {
        id: "listed",
        label: intl.formatMessage({ id: "listed", defaultMessage: "Listed" })
      },
      {
        id: "rejected",
        label: intl.formatMessage({
          id: "rejected",
          defaultMessage: "Rejected"
        })
      },
      {
        id: "all",
        label: intl.formatMessage({
          id: "all_consignment",
          defaultMessage: "All Consignment"
        })
      }
    ];

    this.guideData = [
      {
        Consigner: "Johnny Champ",
        Consignment: "#76",
        Created_date: "a month ago",
        Updated_date: "a month ago",
        Price: "$900",
        Quantity: 1,
        Status: joyride.feature === "list_consignment" ? "approved" : "pending",
        items: [
          {
            active: true,
            clover_id: null,
            condition: "New",
            consignment_order: 76,
            cost: null,
            created_at: "2018-11-03T12:53:38.336007-07:00",
            id: 148660,
            image_link:
              "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg",
            in_transit: false,
            labelled: false,
            listable: false,
            listed: false,
            location: null,
            price: null,
            shelf: "",
            sold: false,
            sold_at: null,
            consigner: {
              billing_address: null,
              consignment_approved: true,
              consignment_rate: 15,
              created_at: "2018-09-24T17:55:32.379915-07:00",
              email: "cu@quantfive.org",
              first_name: "Johnny",
              id: 15,
              last_name: "Champ",
              phone_number: "8502944844",
              return_address: null,
              shipping_address: {
                id: 14,
                state: "CA",
                country: "USA",
                city: "Los Angeles",
                zip_code: "94709"
              },
              updated_at: "2018-11-07T00:00:04.717238-08:00",
              user: 21,
              verified_consigner: true
            },
            consignment: {
              apparel_package_condition: "",
              barcode_printed: false,
              box_condition: "Good Condition",
              clover_item_id: "",
              created_at: "2018-11-03T12:58:53.342228-07:00",
              defects: false,
              discolored: false,
              id: 206,
              missing_insoles: false,
              missing_tags: false,
              order: 77,
              payout: null,
              pending_price: null,
              pictures: [],
              price: {
                id: 201,
                default_price: 90000,
                default_currency: "usd",
                solestage_price: 90000,
                instore_price: 90000
              },
              scuff_marks: false,
              size: "10",
              status: "approved",
              tears: false,
              updated_at: "2018-11-03T13:11:19.096066-07:00",
              yellowing: false
            },
            pictures: [
              {
                id: 20504,
                sku: 20549,
                url:
                  "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg"
              }
            ],
            updated_at: "2018-11-03T12:55:28.734470-07:00",
            sku: {
              active: true,
              barcode: "12345678999",
              brand: "Adidas",
              color: "Beluga",
              created_at: "2018-11-09T08:44:04.436342-08:00",
              default_location: null,
              eu_size: null,
              gender: "M",
              id: 20549,
              item_type: "shoe",
              product_name: "Adidas Yeezy Boost",
              product_name_us: "Adidas Yeezy Boost",
              sku: null,
              style: "508214-660",
              updated_at: "2018-11-09T08:44:04.781280-08:00",
              us_size: "10"
            }
          }
        ],
        consignment_id: 76
      }
    ];

    this.state = {
      created_date: [],
      updated_date: [],
      numPages: 1,
      page: 1,
      pageSize: 20,
      hoveredRow: null,
      activeTab,
      count: null,
      barcodes: [],
      search: "",
      dropoff_location: null
    };
  }

  getParams = url => {
    let params = {};
    let parser = document.createElement("a");
    parser.href = url;
    let query = parser.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };

  /***
   * Searches the backend for SKU, Barcode, Product Name
   */
  search = async e => {
    if (e.key === "Enter") {
      this.setState({
        page: 1,
        searching: true
      });
      if (this.state.activeTab === "pending" && !this.state.search) {
        await this.getConsignment(1, this.state.pageSize, "pending");
      } else if (this.state.activeTab === "approved" && !this.state.search) {
        await this.getConsignment(1, this.state.pageSize, "approved");
      } else if (this.state.activeTab === "listed" && !this.state.search) {
        await this.getConsignment(1, this.state.pageSize, "listed");
      } else if (this.state.activeTab === "rejected" && !this.state.search) {
        await this.getConsignment(1, this.state.pageSize, "rejected");
      } else {
        await this.getConsignment();
      }
      this.setState({
        searching: false
      });
    }
  };

  /***
   * Gets all consignment items
   */
  getConsignment = (page, size, status) => {
    let { queryConsignmentOrder } = this.props;
    let params = {
      page,
      size,
      status
    };
    // if (!["Admin", "Warehouse Manager"].includes(auth.userProfile.role)) {
    //   params["dropoff_location"] = auth.userProfile.location.id;
    // }

    if (status === "all" || this.state.activeTab === "all") {
      delete params.status;
    } else if (this.state.activeTab === "approved") {
    } else if (this.state.activeTab === "listed") {
    } else if (this.state.activeTab === "rejected") {
    } else {
      // params.consignment__status__in = "pending,approved,listed";
    }

    if (this.state.search && this.state.search.includes("co-")) {
      params["id"] =
        this.state.search.includes("co-") &&
        this.state.search.replace("co-", "");
    } else if (this.state.search) {
      params["search"] = this.state.search;
    }

    if (this.state.dropoff_location) {
      params["dropoff_location"] = this.state.dropoff_location;
    }

    const onStart = () => {
      this.setState({
        searching: true,
        data: []
      });
    };

    const onSuccess = res => {
      let data = [];
      this.setState({
        searching: false
      });
      if (res.results) {
        data = res.results.map((consignmentOrder, index) => {
          // TODO: get the consigner user returning from backend,
          let quantity = consignmentOrder.consignment_items.length;
          let total_price = 0;
          for (let i = 0; i < quantity; i++) {
            // Transition from cents to dollars
            let price = consignmentOrder.consignment_items[i].consignment.price;
            total_price += price ? price.default_price / 100 : null;
          }
          return {
            Consignment: `#${consignmentOrder.id}`,
            consignment_id: consignmentOrder.id,
            items: consignmentOrder.consignment_items,
            Created_date: new Date(
              consignmentOrder.created_at
            ).toLocaleDateString("en-US"),
            Updated_date: new Date(
              consignmentOrder.updated_at
            ).toLocaleDateString("en-US"),
            Consigner: consignmentOrder.consigner
              ? consignmentOrder.consigner.first_name +
                " " +
                consignmentOrder.consigner.last_name
              : null,
            Status: consignmentOrder.status,
            Quantity: quantity,
            Price: numeral(total_price).format("$0,0"),
            Location: (consignmentOrder.dropoff_location || {}).name,
            Note: consignmentOrder.note
          };
        });
      } else {
        let quantity = res.consignment_items.length;
        let total_price = 0;
        for (let i = 0; i < quantity; i++) {
          // Transition from cents to dollars
          let price = res.consignment_items[i].consignment.price;
          total_price += price ? price.default_price / 100 : null;
        }
        data = [
          {
            Consignment: `#${res.id}`,
            consignment_id: res.id,
            items: res.consignment_items,
            Created_date: new Date(res.created_at).toLocaleDateString("en-US"),
            Updated_date: new Date(res.updated_at).toLocaleDateString("en-US"),
            Consigner: res.consigner ? res.consigner.email : null,
            Status: res.status,
            Quantity: quantity,
            Price: numeral(total_price).format("$0,0"),
            Location: ""
          }
        ];
      }
      this.setState(
        {
          numPages: Math.ceil(res.count / this.state.pageSize),
          count: res.count,
          data
        },
        () => this.appendToUrl(this.state.activeTab)
      );
    };

    const onFailure = error => {
      this.setState({
        searching: false
      });
    };

    queryConsignmentOrder({
      params,
      onStart,
      onSuccess,
      onFailure
    });
  };

  /***
   * Sets the search value
   */
  searchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    this.setState(
      {
        activeTab: tab,
        page: 1
      },
      () => {
        let status = null;
        if (tab === "pending") {
          status = "pending";
        } else if (tab === "approved") {
          status = "approved";
        } else if (tab === "listed") {
          status = "listed";
        } else if (tab === "rejected") {
          status = "rejected";
        }
        this.appendToUrl(tab);
        this.getConsignment(1, this.state.pageSize, status);
      }
    );
  };

  appendToUrl = tab => {
    window.history.replaceState(null, null, `?tab=${tab}`);
  };

  printBarcode = (barcode, item) => {
    let key = Math.random()
      .toString(36)
      .substring(7);
    this.setState({
      barcodes: [...this.state.barcodes, { barcode, item, key }]
    });
  };

  unmount = key => {
    let barcodes = [...this.state.barcodes];
    let index = 0;
    for (let i = 0; i < barcodes.length; i++) {
      if (barcodes[i].key === key) {
        index = i;
        break;
      }
    }

    barcodes.splice(index, 1);

    this.setState({
      barcodes
    });
  };

  /***
   * Get items in this consignment order that were fully approved
   */
  getConsignmentItems = consignmentId => {
    return fetch(
      API.CONSIGNMENT_ORDERS_BARCODE({ id: consignmentId }),
      API.GET_CONFIG()
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let items = res;
        for (let i = 0; i < items.length; i++) {
          this.printBarcode(`ss-${items[i].id}`, items[i]);
        }
      });
  };

  /**
   *
   *
   */
  onNoteChange = () => {
    this.getConsignment(1, this.state.pageSize, this.state.activeTab);
  };

  /**
   * Processing to show the proper data on approval
   * @param { boolean } list -- whether we've listed the item or not (if not, don't print barcode)
   */
  onApproveOrReject = (consignmentId, list) => {
    let data = [...this.state.data];
    if (this.state.activeTab === "pending") {
      for (let i = 0; i < data.length; i++) {
        if (data[i].consignment_id === consignmentId) {
          data.splice(i, 1);
          break;
        }
      }
    }

    if (list) {
      this.getConsignmentItems(consignmentId);
    }

    this.setState({
      count: this.state.count - 1
    });

    this.setState({
      data
    });
  };

  /**
   * on change of location selector
   * @param  {Event} e -- on change event for selector
   */
  onLocationChange = e => {
    let value = e.target.value;
    if (e.target.value === "0") {
      value = null;
    }

    this.setState(
      {
        dropoff_location: value,
        page: 1,
        firstLoad: true
      },
      () => {
        this.getConsignment(1, this.state.pageSize, this.state.activeTab);
      }
    );
  };

  renderCell = (header, props, index) => {
    switch (header) {
      case "Status":
        let fulfilled = props.value === "approved" || props.value === "listed";
        let rejected = props.value === "rejected";
        if (fulfilled) {
          return (
            <div
              className={css(styles.cellValue, styles.status, styles.fulfilled)}
            >
              {props.value}
            </div>
          );
        } else if (rejected) {
          return (
            <div
              className={css(styles.cellValue, styles.status, styles.rejected)}
            >
              {props.value}
            </div>
          );
        } else {
          return (
            <div
              className={css(
                styles.cellValue,
                styles.status,
                styles.unfulfilled
              )}
            >
              {props.value}
            </div>
          );
        }
      default:
        return (
          <div
            className={css(
              styles.cellValue,
              header === "Order" && styles.orderNumber
            )}
          >
            {props.row[index] ? props.row[index].value : props.value}
          </div>
        );
    }
  };

  consignmentClicked = (item, status, note) => {
    let { modalActions } = this.props;
    modalActions.openApproveConsignmentModal(true, item, status, note);
  };

  addConsignmentClicked = () => {
    let { modalActions } = this.props;
    modalActions.openAddConsignmentModal(true);
  };

  componentDidMount = () => {
    if (this.props.joyride.forceJoy) {
      let { joyrideActions } = this.props;
      joyrideActions.setRun({ run: true, inProgress: true });
    }
  };

  render() {
    let { intl } = this.props;
    let columns = [
      intl.formatMessage({
        id: "consignment_tab",
        defaultMessage: "Consignment"
      }),
      intl.formatMessage({
        id: "dropoff_location",
        defaultMessage: "Location"
      }),
      intl.formatMessage({
        id: "created_date",
        defaultMessage: "Created_date"
      }),
      intl.formatMessage({
        id: "updated_date",
        defaultMessage: "Updated_date"
      }),
      intl.formatMessage({ id: "consigner", defaultMessage: "Consigner" }),
      intl.formatMessage({ id: "status", defaultMessage: "Status" }),
      intl.formatMessage({ id: "price", defaultMessage: "Price" }),
      intl.formatMessage({ id: "quantity", defaultMessage: "Quantity" })
    ].map((column, index) => ({
      Header: column
        ? column !== "table-id"
          ? column !== "manage"
            ? column
            : null
          : null
        : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      filterable: false,
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => (
        <div
          className={css(
            styles.valueContainer,
            props.row._index === this.state.hoveredRow && styles.hoveredRow
          )}
          id={
            window.location.search.includes("guide=true") &&
            props.row._index === 0
              ? "clickRow"
              : undefined
          }
          onClick={() => {
            this.consignmentClicked(
              props.original.items,
              props.original.Status,
              props.original.Note
            );
          }}
          onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
        >
          {this.renderCell(props.column.Header, props, index)}
        </div>
      )
    }));

    let tabs = this.tabs.map((tab, index) => {
      return (
        <div
          className={css(
            styles.tab,
            this.state.activeTab === tab.id && styles.activeTab
          )}
          onClick={() => this.setActiveTab(tab.id)}
          key={`tab_${tab.id}_${index}`}
          id={tab.id === "approved" ? "approveTab" : undefined}
        >
          {tab.label}
        </div>
      );
    });

    let barcodes = this.state.barcodes.map((dict, index) => {
      if (index > 0) {
        return null;
      }
      return (
        <BarcodeModal
          barcode={dict.barcode}
          key={`${dict.key}_${index}`}
          unmount={this.unmount}
          barcodeKey={dict.key}
          name={dict.item.sku.product_name_us}
          size={dict.item.sku.us_size}
          color={dict.item.sku.color}
          price={dict.item.consignment.price.default_price / 100}
        />
      );
    });

    return (
      <div className={css(styles.orders)}>
        <h2 className={css(styles.dashboardTitle)}>
          <FormattedMessage id="consignment_tab" defaultMessage="Consignment" />{" "}
          {this.state.count && `(${this.state.count})`}
        </h2>
        <Dropdown
          onChange={this.onLocationChange}
          default={this.state.location}
        />
        <div className={css(styles.tabs)}>
          {tabs}
          <Button
            className={styles.button}
            text={
              <FormattedMessage
                id="add_consignment"
                defaultMessage="Add Consignment"
              />
            }
            onClick={this.addConsignmentClicked}
            id={"addConsignmentButton"}
          />
        </div>
        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <div className={css(styles.search)} id="search">
            <Searchbar
              search={this.search}
              searchChange={this.searchChange}
              searching={this.state.searching}
            />
          </div>
          <div id="approveConsignment">
            <ReactTable
              className={css(styles.reactTable) + " order-table"}
              id="approveConsignment"
              data={
                window.location.search.includes("guide=true")
                  ? this.guideData
                  : this.state.data
              }
              filterable={true}
              pages={this.state.numPages}
              columns={columns}
              footerClassName={css(styles.tableFooter)}
              defaultPageSize={this.state.pageSize}
              page={this.state.page - 1}
              onPageSizeChange={pageSize => {
                this.setState({
                  pageSize,
                  numPages: Math.ceil(this.state.totalItems / pageSize)
                });
              }}
              onPageChange={pageIndex => {
                this.setState({
                  page: pageIndex + 1
                });
              }}
              minRows={0}
              onFetchData={(state, instance) => {
                if (window.location.search.includes("guide=true")) {
                  return null;
                }
                this.getConsignment(
                  state.page + 1,
                  state.pageSize,
                  this.state.activeTab
                );
              }}
              nextText={intl.formatMessage({
                id: "next",
                defaultMessage: "Next"
              })}
              previousText={intl.formatMessage({
                id: "previous",
                defaultMessage: "Previous"
              })}
              noDataText={intl.formatMessage({
                id: "no_items_found",
                defaultMessage: "No items found"
              })}
              manual
            />
          </div>
        </div>
        <ApproveConsignmentModal
          onApproveOrReject={this.onApproveOrReject}
          onNoteChange={this.onNoteChange}
        />
        <AddConsignmentModal />
        {barcodes}
      </div>
    );
  }
}

var styles = StyleSheet.create({
  orders: {
    textAlign: "center",
    padding: 20,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  status: {
    textTransform: "capitalize"
  },
  table: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
    color: "#212b36",
    borderBottom: "1px solid #ddd"
  },
  productColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    whiteSpace: "pre-wrap"
  },
  itemPhoto: {
    width: 75,
    height: 75,
    paddingBottom: 8
  },
  orderNumber: {
    color: colors.LIGHT_BLUE(1)
  },
  hoveredRow: {
    background: "#eee"
  },
  link: {
    textDecoration: "none"
  },
  unfulfilled: {
    background: "#ffea8a",
    padding: "8px 8px",
    borderRadius: 24
  },
  rejected: {
    padding: "8px 8px",
    borderRadius: 24,
    color: "red"
  },
  fulfilled: {
    padding: "8px 8px",
    borderRadius: 24,
    background: "#dfe3e8"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 16,
    position: "relative"
  },
  tab: {
    marginLeft: 7,
    marginRight: 7,
    opacity: ".3",
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  },
  button: {
    width: 200,
    position: "absolute",
    right: 0,
    bottom: 5
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  joyride: state.joyride
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  joyrideActions: bindActionCreators(JoyrideActions, dispatch),
  queryConsignmentOrder: payload =>
    dispatch(Actions.inventory.queryConsignmentOrder(payload))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Consignment));
