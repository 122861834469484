/***
 * Manual Input modal for adding new products
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isAfterDay from "react-dates/lib/utils/isAfterDay";
import moment from "moment";
import numeral from "numeral";

// Components
import BaseModal from "./BaseModal";
import Button from "../Button";

// Actions
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import IMAGECOMING from "../../assets/imagecoming.jpg";

// Stylesheets
import "react-dates/lib/css/_datepicker.css";
import "./stylesheets/react_dates.css";
import Input from "../Input";
import CurrencyDisplay from "../CurrencyDisplay";

class PayoutHistoryModal extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      items: [],
      message: null,
      fetchingData: false,
      postingData: false,
      currentPayoutAmount: 0,
      itemClicked: null,
      payout_value: 0,
      payoutCheck: false
    };

    this.state = {
      ...this.initialState
    };
  }

  closeModal = () => {
    let { modalActions } = this.props;
    this.setState({
      ...this.initialState
    });
    modalActions.openPayoutHistoryModal(false, { profile: {}, location: {} });
  };

  isOutsideRange = day => {
    let dayIsBlocked = false;

    if (isAfterDay(day, moment().subtract(0, "day"))) {
      dayIsBlocked = true;
    }

    return dayIsBlocked;
  };

  /**
   * MARKS THE PAYOUT AS PAID
   * THIS ENDPOINT DOESN'T ACTUALLY MAKE THE PAYOUT, THIS.PAYOUT() WILL MAKE THE FULL PAYOUT
   */
  markPayout = () => {
    let { modals, messageActions, intl } = this.props;
    let startDate = moment(this.state.startDate).format("L");
    let endDate = moment(this.state.endDate).format("L");
    let endpoint =
      API.MARK_PAYOUT +
      `?sold_at__date__gte=${startDate}&sold_at__date__lte=${endDate}&consigner=${
        modals.payout.user
      }&sold=True`;
    this.setState({
      postingData: true
    });

    let params = {
      selected_user_id: modals.payout.user
    };

    return fetch(endpoint, API.POST_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(_ => {
        let items = [...this.state.items];

        items.forEach(item => {
          item.consignment.is_paid = "success";
        });

        messageActions.setMessage(
          intl.formatMessage({
            id: "payout_recorded",
            defaultMessage: "Payout Recorded"
          })
        );
        messageActions.showMessage({ show: true });
        this.setState({
          items,
          payout_value: 0,
          postingData: false
        });
      })
      .catch(error => {
        this.setState({
          postingData: false
        });

        messageActions.setMessage(
          intl.formatMessage({
            id: "already_paid",
            defaultMessage: "These payouts have already been made"
          })
        );
        messageActions.showMessage({ show: true, error: true });
      });
  };

  /**
   * Gets the report specified by start date and end date
   */
  getReport = () => {
    let { modals, payout } = this.props;
    let endpoint = "";

    if (!payout) {
      endpoint =
        API.CHECK_PAYOUT + `?consigner=${modals.payout.user}&sold=True`;
    } else {
      endpoint =
        API.CHECK_PAYOUT + `?consigner=${modals.payout.receiver}&sold=True`;
    }

    if (modals.payout.location) {
      endpoint += `&location=${modals.payout.location.id}`;
    }

    if (payout) {
      endpoint += `&consignment__payout__payout_group=${modals.payout.id}`;
      if (!this.props.payoutHistory) {
        endpoint += `&consignment__payout__state=requested,failed,pending`;
      }
    }

    endpoint += `&history=true`;

    this.setState({
      fetchingData: true
    });

    return fetch(endpoint, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          ...res,
          fetchingData: false
        });
      });
  };

  /**
   * After the modal is opened, if we're on the payout, fetch the payouts
   */
  onAfterOpen = () => {
    this.getReport();

    if (this.props.payoutCheck) {
      this.setState({
        payoutCheck: true
      });
    }
  };

  /**
   * ENDPOINT TO ACTUALLY MAKE THE PAYOUT WITH $$
   */
  payout = () => {
    let { items, postingData } = this.state;

    if (postingData) {
      // Guard against double payout
      return null;
    }

    let { payoutId, messageActions, intl, modals } = this.props;
    let inventory_ids = items.map((item, index) => {
      return item.id;
    });

    let payout_values = items.map((item, index) => {
      return item.payout_value;
    });

    let params = {
      inventory_ids,
      payout_values,
      location: modals.payout.location.id
    };

    this.setState({
      postingData: true
    });

    return fetch(
      API.REQUEST_PAYOUT + `${payoutId}/pay/`,
      API.PUT_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        if (res.success) {
          messageActions.setMessage(
            intl.formatMessage({
              id: "payout_successful",
              defaultMessage: "Payout successful!"
            })
          );
          messageActions.showMessage({ show: true });
          this.setState(
            {
              postingData: false
            },
            () => {
              this.props.success();
              this.closeModal();
            }
          );
        } else {
          this.setState({
            items: []
          });
          this.getReport();
          this.setState({
            postingData: false
          });
          let message = res.message.join(",");
          for (let i = 0; i < res.failed_payouts.length; i++) {
            if (res.message[i] === "INSUFFICIENT_FUNDS") {
              message += `${intl.formatMessage({
                id: "insufficient_funds",
                defaultMessage: "Insufficient Funds in Paypal for"
              })} ${res.failed_payouts[i].location.name}\n\n`;
            }
          }

          if (!message) {
            message = `${intl.formatMessage({
              id: "something_went_wrong",
              defaultMessage: "Something went wrong!"
            })}`;
          }

          alert(message);
        }
      })
      .catch(error => {
        messageActions.setMessage(
          intl.formatMessage({
            id: "something_went_wrong",
            defaultMessage: "Something went wrong!"
          })
        );
        messageActions.showMessage({ show: true, error: true });
        this.setState({
          postingData: false
        });
      });
  };

  /**
   * When we change a payout value, we want to change the value in state
   * @param { Event } e -- the event for onClick
   * @param { Integer } index -- the index of the item we clicked on
   */
  onPayoutChange = (e, index) => {
    let value = e.target.value;
    this.setState({
      currentPayoutAmount: value
    });
  };

  /**
   * Saving the payout rate
   */
  onPayoutSave = (e, index) => {
    let { items, currentPayoutAmount } = this.state;
    let value = currentPayoutAmount;
    let newItems = JSON.parse(JSON.stringify(items));

    newItems[index].payout_value = value * 100;
    this.setState({
      items: newItems,
      itemClicked: null,
      currentPayoutAmount: null
    });
  };

  /**
   * When we click the payout amount, allow the user to edit the payout amount
   * @param { Event } e -- the event for onClick
   * @param { Integer } index -- the index of the item we clicked on
   */
  onClickAmount = (e, index) => {
    e.stopPropagation();
    this.setState({
      itemClicked: index,
      currentPayoutAmount: this.state.items[index].payout_value / 100
    });
  };

  /**
   * Getting inventory for a specific consigner at a specific location
   */
  getInventory = () => {
    let { modals, messageActions } = this.props;
    let consigner = modals.payout;
    let params = {
      route: "download_consigner_report",
      consigner: consigner.user
    };
    messageActions.showMessage({ show: true, load: true });
    return fetch(API.INVENTORY_CSV(params), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        this.setState({
          message: null,
          exportingInventory: false
        });
        let link = document.createElementNS(
          "http://www.w3.org/1999/xhtml",
          "a"
        );
        link.href = URL.createObjectURL(res);
        link.download = `${consigner.first_name}_${
          consigner.last_name
        }_inventory.csv`;
        link.click();
        messageActions.showMessage({ show: false });
      });
  };

  payoutButton = payoutValue => {
    let { modals, payout, intl } = this.props;
    let isDisabled =
      payoutValue === null ||
      !payoutValue > 0 ||
      modals.payout.state === "paid";
    let className = isDisabled ? styles.disabled : styles.actionButton;

    let onClick = payout ? this.payout : this.markPayout;

    let title = intl.formatMessage({
      id: "mark_as_paid",
      defaultMessage: "Mark as Paid"
    });

    if (payout) {
      if (this.props.payoutCheck) {
        title = intl.formatMessage({
          id: "payout_by_check",
          defaultMessage: "Payout By Check"
        });
      } else {
        title = intl.formatMessage({
          id: "payout",
          defaultMessage: "Payout"
        });
      }
    }

    if (modals.payout.state === "paid") {
      title = intl.formatMessage({
        id: "paid_payout",
        defaultMessage: "Payout already paid"
      });
    }

    return (
      <Button
        className={className}
        text={
          this.state.postingData ? (
            <FontAwesomeIcon icon={["far", "spinner-third"]} spin />
          ) : (
            title
          )
        }
        onClick={onClick}
        disabled={isDisabled}
      />
    );
  };

  getConsignmentStatus = item => {
    let { intl } = this.props;
    let itemStatus = item.consignment.is_paid;

    if (itemStatus === "success") {
      return intl.formatMessage({ id: "yes", defaultMessage: "Yes" });
    } else if (itemStatus === "initiated") {
      return intl.formatMessage({
        id: "initiated",
        defaultMessage: "Initiated"
      });
    } else if (itemStatus === "transferred") {
      return intl.formatMessage({
        id: "transferred",
        defaultMessage: "Transferred"
      });
    } else if (itemStatus === "failed") {
      return intl.formatMessage({
        id: "failed",
        defaultMessage: "Failed"
      });
    } else {
      return intl.formatMessage({ id: "no", defaultMessage: "No" });
    }
  };

  render() {
    let { modals, payout, intl, auth } = this.props;
    let items = this.state.items.map((item, index) => {
      item.picture_url = null;
      if (item.pictures && item.pictures.length > 0) {
        item.picture_url = item.pictures[0].url;
      }
      let payoutAmount = item.payout_value / 100;
      return (
        <div className={css(styles.soldConsignment)} key={`payout_${item.id}`}>
          <div className={css(styles.column)}>ss-{item.id}</div>
          <div className={css(styles.column)}>
            <img
              alt={"picture_url"}
              className={css(styles.productImg)}
              src={item.picture_url ? item.picture_url : IMAGECOMING}
            />
          </div>
          <div className={css(styles.column)}>{item.sku.product_name_us}</div>
          <div className={css(styles.column)}>
            {item.purchased_price ? (
              <CurrencyDisplay price={item.purchased_price / 100} />
            ) : (
              intl.formatMessage({
                id: "marked_sold",
                defaultMessage: "MARKED SOLD, NO PURCHASE PRICE"
              })
            )}
          </div>
          {this.state.itemClicked === index && payout ? (
            <div
              className={css(styles.column, styles.rateEdit)}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Input
                className={styles.rateInput}
                value={this.state.currentPayoutAmount}
                onChange={e => this.onPayoutChange(e, index)}
                autoFocus
              />
              <Button
                className={styles.rateButton}
                text={intl.formatMessage({
                  id: "save",
                  defaultMessage: "Save"
                })}
                onClick={e => this.onPayoutSave(e, index)}
              />
            </div>
          ) : (
            <div
              className={css(styles.column)}
              onClick={e => this.onClickAmount(e, index)}
            >
              {payoutAmount ? <CurrencyDisplay price={payoutAmount} /> : null}
            </div>
          )}
          <div className={css(styles.column)}>
            {moment(item.sold_at).format("L")}
          </div>
          <div className={css(styles.column)}>
            {item.location && item.location.name}
          </div>
          <div className={css(styles.column)}>
            {this.getConsignmentStatus(item)}
          </div>
        </div>
      );
    });

    let payoutValue = 0;

    this.state.items.forEach(item => {
      payoutValue += item.payout_value;
    });

    let consigner = modals.payout.profile;
    if (!this.props.payout) {
      consigner = modals.payout;
    }

    return (
      <BaseModal
        isOpen={modals.openPayoutHistoryModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        onAfterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="PayoutHistoryModal"
      >
        <div className={css(styles.payoutHistoryModaContainer)}>
          <div className={css(styles.reportContainer)}>
            <div className={css(styles.header)}>
              <div className={css(styles.instructionsContainer)}>
                <h1 className={css(styles.title)}>
                  {intl.formatMessage({
                    id: "consigner_payout_report",
                    defaultMessage: `Payout 
                                        Report`
                  })}
                </h1>
              </div>
              <div className={css(styles.consignerInfo)}>
                <div className={css(styles.labelColumn)}>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "consigner",
                      defaultMessage: "Consigner"
                    })}:`}
                  </div>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "email_address",
                      defaultMessage: "Email"
                    })}:`}
                  </div>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "number",
                      defaultMessage: "Number"
                    })}:`}
                  </div>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "shoe_consignment_rate",
                      defaultMessage: "Shoe Consignment Rate"
                    })}:`}
                  </div>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "apparel_consignment_rate",
                      defaultMessage: "Apparel Consignment Rate"
                    })}:`}
                  </div>
                  {this.props.payout && (
                    <div className={css(styles.label)}>
                      {`${intl.formatMessage({
                        id: "payout.id",
                        defaultMessage: "Payout ID"
                      })}:`}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.label)}>
                      {`${intl.formatMessage({
                        id: "payout.method",
                        defaultMessage: "Payout Method"
                      })}:`}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.label)}>
                      {`${intl.formatMessage({
                        id: "payout.platform_id",
                        defaultMessage: "Stripe Payout ID"
                      })}:`}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.label)}>
                      {`${intl.formatMessage({
                        id: "payout.platform_payout_status",
                        defaultMessage: "Stripe Status"
                      })}:`}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.label)}>
                      {`${intl.formatMessage({
                        id: "payout.status",
                        defaultMessage: "Payout status"
                      })}:`}
                    </div>
                  )}
                </div>

                <div className={css(styles.infoColumn)}>
                  <div className={css(styles.info)}>
                    {`${consigner.first_name} ${consigner.last_name}`}
                  </div>
                  <div className={css(styles.info)}>{consigner.email}</div>
                  <div className={css(styles.info)}>
                    {consigner.phone_number}
                  </div>
                  <div className={css(styles.info)}>
                    {consigner.shoe_consignment_rate}%
                  </div>
                  <div className={css(styles.info)}>
                    {consigner.non_shoe_consignment_rate}%
                  </div>
                  {this.props.payout && (
                    <div className={css(styles.info)}>#{modals.payout.id}</div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.info)}>
                      {modals.payout.payout_type}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.info)}>
                      #{modals.payout.platform_id}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.info)}>
                      #{modals.payout.platform_payout_status}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.info)}>
                      {modals.payout.state}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {!this.props.payout && (
              <Button
                className={[styles.actionButton, styles.closeRequest]}
                text={intl.formatMessage({
                  id: "get_inventory",
                  defaultMessage: "Export Inventory"
                })}
                onClick={this.getInventory}
              />
            )}
          </div>

          <div className={css(styles.tableContainer)}>
            <div className={css(styles.soldTitle)}>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "product_id",
                  defaultMessage: "Product ID"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "sku_product_image",
                  defaultMessage: "Image"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "sku_product_name",
                  defaultMessage: "Product Name"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({ id: "price", defaultMessage: "Price" })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "payout_amount",
                  defaultMessage: "Payout Amount"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "date_sold",
                  defaultMessage: "Date Sold"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "inventory_location",
                  defaultMessage: "Location"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({ id: "paid", defaultMessage: "Paid" })}
              </div>
            </div>

            <hr />

            <div className={css(styles.itemsContainer)}>
              {this.state.items.length > 0 ? (
                items
              ) : (
                <div className={css(styles.noItems)}>
                  {this.state.fetchingData ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      size={`3x`}
                      spin
                    />
                  ) : (
                    <div>
                      {intl.formatMessage({
                        id: "no_sales_found",
                        defaultMessage:
                          "No sales found for specified date range."
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={css(styles.payout)}>
            {`${intl.formatMessage({
              id: "total_payout_for_date",
              defaultMessage: "Total Payout Amount For Date Range"
            })}: ${
              intl.locale === "en"
                ? numeral(payoutValue / 100).format("$0,0.00")
                : new Intl.NumberFormat("zh-CN", {
                    style: "currency",
                    currency: "CNY"
                  }).format(payoutValue / 100)
            }`}

            {["admin", "accountant"].includes(
              auth.userProfile.role.toLowerCase()
            ) && this.payoutButton(payoutValue)}
          </div>
          <br />
          <br />
        </div>
      </BaseModal>
    );
  }
}

var styles = StyleSheet.create({
  modal: {
    maxWidth: 1200,
    width: "90%",
    position: "absolute",
    zIndex: 5,
    height: "90%"
  },
  bodyContainer: {
    height: "100%",
    maxHeight: "unset",
    alignItems: "unset",
    justifyContent: "unset",
    padding: 0
  },
  payoutHistoryModaContainer: {
    padding: 20,
    minHeight: 600,
    height: "100%"
  },
  reportContainer: {
    padding: 20,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column"
  },
  datePickerContainer: {
    display: "flex",
    paddingBottom: 25
  },
  tableContainer: {
    border: "1px solid #ccc",
    margin: "1em 0em 2em 0em",
    padding: "1em 0em 0em 0em"
  },
  actionButton: {
    width: 200,
    height: 48,
    marginLeft: 20,
    fontSize: 18,
    padding: 10
  },
  closeRequest: {
    background: colors.DARK_BLUE(1),
    borderColor: colors.DARK_BLUE(1),
    ":hover": {
      background: colors.DARK_BLUE(0.8)
    }
  },
  checkRequested: {
    textAlign: "center"
  },
  message: {
    marginBottom: 10,
    color: colors.RED(0.7)
  },
  instructions: {
    width: 300,
    opacity: ".7",
    marginBottom: 20
  },
  title: {
    marginBottom: 10
  },
  soldConsignment: {
    width: "100%",
    display: "flex",
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: "1px solid #ccc",
    ":nth-child(even)": {
      backgroundColor: "#f5f5f5"
    }
  },
  column: {
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  soldTitle: {
    fontSize: 18,
    fontWeight: "bold",
    width: "100%",
    display: "flex"
  },
  noItems: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    fontSize: 20
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  consignerInfo: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    marginLeft: 20
  },
  labelColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  label: {
    fontWeight: "bold",
    marginRight: 10
  },
  infoColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  info: {
    width: 300,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  instructionsContainer: {
    width: 300
  },
  toggle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 16
  },
  disabled: {
    background: "#aaa",
    border: "1px solid #aaa",
    width: 200,
    height: 48,
    marginLeft: 20,
    fontSize: 18,
    padding: 10,

    ":hover": {
      background: "#aaa"
    }
  },
  payout: {
    fontSize: 25,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "auto"
  },
  itemsContainer: {
    overflow: "auto",
    flex: 1
  },
  productImg: {
    width: 80
  }
});

const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PayoutHistoryModal)
);
