import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import InputMask from "react-input-mask";

// Config
import colors from "../../../../config/colors";

const SKUFormInput = props => {
  let {
    id,
    label,
    size,
    type,
    mask,
    placeholder,
    value,
    readOnly,
    incompleteField,
    handleInputChange,
    uppercase,
    required,
    intl
  } = props;

  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "left"
    },
    label: {
      paddingLeft: 10,
      fontWeight: 300,
      margin: "10px 0px 10px 0px"
    },
    inputWrapper: {
      paddingLeft: 10
    },
    input: {
      height: 50,
      width: size ? size : "",
      borderRadius: 3,
      outline: "none",
      fontSize: 14,
      fontWeight: 200,
      cursor: "text",
      border: `1px solid ${incompleteField ? colors.RED(1) : "#ddd"}`,
      transition: "all 0.30s ease-in-out",
      paddingLeft: 10,
      background: "rgb(245, 245, 245)",
      ":focus": {
        border: `1px solid ${
          incompleteField ? colors.RED(1) : colors.LIGHT_BLUE(1)
        }`,
        boxShadow: `0px 0px 5px ${
          incompleteField ? colors.RED(1) : colors.LIGHT_BLUE(1)
        }`,
        background: "white"
      }
    }
  });

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.label)}>{label}</div>
      <div className={css(styles.inputWrapper)}>
        {mask ? (
          <InputMask
            className={css(styles.input)}
            value={value}
            id={id}
            required={required}
            mask={mask}
            onChange={e =>
              handleInputChange ? handleInputChange(e, uppercase) : null
            }
            placeholder={
              incompleteField
                ? intl.formatMessage({
                    id: "required",
                    defaultMessage: "Required"
                  })
                : placeholder
            }
          />
        ) : (
          <input
            className={css(styles.input)}
            id={id}
            key={id}
            type={type}
            required={required}
            placeholder={
              incompleteField
                ? intl.formatMessage({
                    id: "required",
                    defaultMessage: "Required"
                  })
                : placeholder
            }
            value={value}
            onChange={e =>
              handleInputChange ? handleInputChange(e, uppercase) : null
            }
            readOnly={readOnly ? true : false}
          />
        )}
      </div>
    </div>
  );
};

export default injectIntl(SKUFormInput);
