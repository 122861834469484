import React, { Component } from "react";

// NPM Modules
import { Route, Redirect } from "react-router-dom";

export default class ProtectedRoute extends Component {
  checkAuth = Component => {
    if (this.props.allow) {
      return <Component {...this.props} />;
    } else {
      return (
        <Redirect to={this.props.redirect ? this.props.redirect : "/404"} />
      );
    }
  };

  render() {
    const { component: Component, ...props } = this.props;

    return <Route {...props} render={() => this.checkAuth(Component)} />;
  }
}
