/***
 * Manual Input modal for adding new products
 * @patr
 */
import React from "react";
// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropzone from "react-dropzone";

// Components
import BaseModal from "./BaseModal";
import Button from "../Button";
import Input from "../Input";

// Actions
import { ModalActions } from "../../redux/modals";
import { AuthActions } from "../../redux/auth";
import { MessageActions } from "../../redux/message";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

class AddPictureModal extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      uploadComplete: false,
      uploadError: false,
      uploading: false,
      image_url: null
    };
    this.state = {
      ...this.initialState
    };
  }

  closeModal = () => {
    let { modalActions } = this.props;
    this.setState({
      ...this.initialState
    });
    modalActions.openAddPictureModal(false, {});
  };

  urlChange = e => {
    this.setState({
      image_url: e.target.value
    });
  };

  updateImageURL = e => {
    let { modals } = this.props;
    e.preventDefault();
    this.setState({
      updatingURL: true
    });
    fetch(
      API.UPDATE_PICTURES,
      API.POST_CONFIG({
        style: modals.addPictureModalRow.style,
        image_url: this.state.image_url
      })
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          updatingURL: false
        });
        this.props.updatePicture(modals.addPictureModalRow.index, res.url);
        this.closeModal();
      });
  };

  /***
   * Excel file upload to the backend. Currently uploads the inventory file.
   */
  onDrop = (acceptedFiles, rejectedFiles) => {
    let { intl, modals } = this.props;
    this.setState({
      uploading: true,
      uploadError: ""
    });
    // let body = {'sheet_name': 0}
    if (acceptedFiles.length === 0) {
      if (rejectedFiles[0].size > 1000000) {
        this.setState({
          uploadError: intl.formatMessage({
            id: "image_too_big_error",
            defaultMessage: "Please upload a photo smaller than 1MB"
          })
        });
      } else {
        this.setState({
          uploadError: intl.formatMessage({
            id: "excel_upload_error",
            defaultMessage: "There was an error uploading your file!"
          })
        });
      }
      return null;
    }
    fetch(
      API.UPDATE_PICTURES,
      API.POST_FILE_CONFIG(
        "image",
        acceptedFiles[0],
        "style",
        modals.addPictureModalRow.style
      )
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.props.updatePicture(modals.addPictureModalRow.index, res.url);
        this.setState({
          uploading: false,
          uploadComplete: true
        });
        this.closeModal();
      });
  };

  render() {
    let { modals, intl } = this.props;
    return (
      <BaseModal
        isOpen={modals.openAddPictureModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddPictureModal"
      >
        <div className={css(styles.addPictureContainer)}>
          <div className={css(styles.title)}>
            {intl.formatMessage({
              id: "update_image",
              defaultMessage: "Update Image"
            })}
          </div>
          <div className={css(styles.instructions)}>
            {intl.formatMessage({
              id: "update_image_message",
              defaultMessage:
                "Update image by entering url to image or uploading an image"
            })}
          </div>
          <form
            className={css(styles.inputContainer)}
            onSubmit={this.updateImageURL}
          >
            <Input
              value={this.state.image_url}
              onChange={this.urlChange}
              placeholder={intl.formatMessage({
                id: "image_url",
                defaultMessage: "Image Url"
              })}
            />
            <Button
              className={styles.button}
              text={
                this.state.updatingURL ? (
                  <FontAwesomeIcon
                    icon={["far", "spinner-third"]}
                    spin
                    className={css(styles.searchIcon, styles.searcher)}
                  />
                ) : (
                  <FormattedMessage
                    id="submit_url"
                    defaultMessage="Submit Url"
                  />
                )
              }
              onClick={this.updateImageURL}
            />
          </form>
          <div className={css(styles.divider)}>Or</div>
          <Dropzone
            className={css(
              styles.dropzone,
              this.state.uploadComplete && styles.dropzoneComplete
            )}
            onDrop={this.onDrop}
            accept={["image/jpeg", "image/png"]}
            maxSize={2000000}
          >
            {this.state.uploadError ? (
              this.state.uploadError
            ) : this.state.uploadComplete ? (
              <FontAwesomeIcon
                icon={["far", "check-circle"]}
                className={css(styles.spinner)}
                color={"#fff"}
              />
            ) : this.state.uploading ? (
              <FontAwesomeIcon
                icon={["far", "spinner-third"]}
                className={css(styles.spinner)}
                spin
              />
            ) : (
              <FormattedMessage
                id="drag_and_drop"
                defaultMessage="Drag & Drop or Click to Upload"
              />
            )}
          </Dropzone>
        </div>
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  modal: {
    maxWidth: 900,
    position: "absolute",
    zIndex: 5
  },
  bodyContainer: {
    height: "100%",
    maxHeight: "unset",
    alignItems: "unset",
    justifyContent: "unset",
    padding: 0
  },
  addPictureContainer: {
    padding: 50
  },
  title: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 20
  },
  dropzone: {
    width: "100%",
    height: 300,
    border: "2px dashed #000",
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 30,
    cursor: "pointer",
    position: "relative",
    transition: ".5s ease-in-out",

    ":hover": {
      background: "rgba(0, 0, 0, .2)"
    }
  },
  dropzoneComplete: {
    border: `2px solid ${colors.LIGHT_BLUE(1)}`,
    height: 100,
    background: colors.LIGHT_BLUE(1),

    ":hover": {
      background: colors.LIGHT_BLUE(0.8)
    }
  },
  divider: {
    textAlign: "center",
    padding: 20
  },
  instructions: {
    marginBottom: 20,
    textAlign: "center"
  },
  inputContainer: {
    display: "flex"
  },
  button: {
    width: 200,
    marginLeft: 10
  }
});
const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddPictureModal)
);
