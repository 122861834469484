import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

class PaymentMethodDropdown extends React.Component {
  render() {
    return (
      <select
        className={css(styles.input, this.props.className)}
        onChange={this.props.onChange}
        readOnly={this.props.readOnly}
      >
        <option value="All">All</option>
        <option value="check">Check</option>
        <option value="stripe">Stripe</option>
        <option value="manual">Manual</option>
      </select>
    );
  }
}

var styles = StyleSheet.create({
  input: {
    display: "flex",
    width: "100%",
    maxWidth: 320,
    height: 38,
    borderRadius: 4,
    outline: "none",
    fontSize: 16,
    border: "1px solid #ddd",
    background: "#fff",
    cursor: "pointer"
  }
});

const mapStateToProps = state => ({
  inventory: state.inventory
});

const mapDispatchToProps = dispatch => ({});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentMethodDropdown)
);
