import React from "react";

// NPM Modules
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bindActionCreators } from "redux";
import _ from "lodash";
import {
  Modal,
  Form,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Button as BootstrapButton
} from "react-bootstrap";

// Components
import Barcode from "../../components/inventory/Barcode";
import BarcodeModal from "../../components/modals/AddProduct/BarcodeModal";

//Redux
import { MessageActions } from "../../redux/message";

// Config
import API from "../../config/api";
import colors from "../../config/colors";
import Helpers from "../../redux/helpers";
import ConsignmentFields from "../../components/consignment/ConsignmentFields";
import ProductInfo from "../../components/consignment/ProductInfo";

//Style
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../components/modals/stylesheets/ImageCarousel.css";

class InventoryItem extends React.Component {
  constructor(props) {
    super(props);

    let { intl } = this.props;

    this.state = {
      product: null,
      printBarcode: false,
      hidePrice: true,
      syncingClover: false,
      form: {
        consignment: {},
        sku: {},
        changeConsignment: {},
        changeSKU: {},
        create_new: false,
        pictures: []
      },
      upcCodes: {},
      loading: false,
      activeTab: "consignment_info",
      showDeleteModal: false,
      showDeletedReasonFreeFormText: true,
      deleteReasons: [
        "Consignor took back",
        "Listing mistake",
        "Duplicate listing",
        "Item sold on stockX",
        "Item sold on ebay",
        "Item transfered"
      ],
      unrequiredDeleteReasons: ["Consignor took back wcv"],
      selectedDeleteReason: null,
      deleteReasonText: null,
      deleteReason: null,
      missingReasons: [
        "Post Reconciliation",
        "Item stolen",
        "Cannot find item"
      ],
      showMissingModal: false,
      selectedMissingReason: null,
      showRelistModal: false,
      relistReason: ""
    };

    this.tabs = [
      {
        id: "consignment_info",
        label: intl.formatMessage({
          id: "consignment_info",
          defaultMessage: "Consignment Info"
        })
      },
      {
        id: "consignment_purchase_order",
        label: intl.formatMessage({
          id: "consignment_purchase_order",
          defaultMessage: "Consignment/Purchase Order"
        })
      },
      {
        id: "product_info",
        label: intl.formatMessage({
          id: "product_info",
          defaultMessage: "Product Info"
        })
      },
      {
        id: "scanned_session",
        label: intl.formatMessage({
          id: "scanned_session",
          defaultMessage: "Scanned Session"
        })
      }
    ];
  }

  getSkuBarcodeMapping = product_id => {
    let { messageActions } = this.props;
    return fetch(API.SKU({ product_id: product_id }), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let barcode = {};
        if (res.results.length) {
          res.results.forEach(sku => {
            barcode[sku.us_size] = sku.barcode;
          });
        }
        this.setState(
          {
            upcCodes: barcode,
            loading: false
          },
          () => {
            messageActions.showMessage({ show: false });
          }
        );
      });
  };

  getProduct = () => {
    let inventoryID = this.props.match.params.id;
    let { messageActions } = this.props;
    messageActions.showMessage({ show: true, load: true });
    this.setState({ loading: true });
    return fetch(API.INVENTORY({ id: inventoryID }), API.GET_CONFIG(true))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(item => {
        let consignment = item.consignment;
        if (!consignment.size) {
          consignment.size = item.sku.us_size;
        }
        console.log("item", item);
        this.getSkuBarcodeMapping(item.sku.product.id);
        this.handleCloseModal();
        // messageActions.showMessage({ show: false });
        this.setState({
          form: {
            ...this.state.form,
            consignment: consignment,
            changeConsignment: {},
            changeSKU: {},
            sku: item.sku,
            price: item.consignment.price.default_price / 100,
            cost: item.cost !== null ? item.cost / 100 : null,
            list_on_shopify: item.list_on_shopify,
            pictures: item.consignment.pictures
              ? item.consignment.pictures
              : [],
            list_on_ebay: item.list_on_ebay
          },
          listOnShopify: item.list_on_shopify,
          listOnEbay: item.list_on_ebay,
          locationId: item.location.id,
          product: item,
          originPics: item.consignment.pictures
            ? _.cloneDeep(item.consignment.pictures)
            : []
        });
      })
      .catch(error => {
        messageActions.showMessage({ show: false });
        this.setState({ loading: false });
        console.log(error);
      });
  };

  openDeleteModal = () => {
    this.setState({
      showDeleteModal: true,
      deleteReason: null
    });
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      selectedDeleteReason: null,
      deleteReasonText: null,
      deleteReason: null
    });
  };

  deleteConsignmentButton = () => {
    const { intl } = this.props;
    return (
      <div className={css(styles.delete)}>
        <button
          className={css(styles.button, styles.deleteConsignment)}
          onClick={this.openDeleteModal}
        >
          {this.state.deletingConsignment ? (
            <FontAwesomeIcon
              icon={["far", "spinner-third"]}
              className={css(styles.iconSpinner)}
              spin
            />
          ) : (
            intl.formatMessage({
              id: "delete_consignment",
              defaultMessage: "Delete Consignment"
            })
          )}
        </button>
        {this.loadDeleteModal()}
      </div>
    );
  };

  /**
   * input change for product information
   * @param  {EventObject} e  -- event object
   * @param  {String} id -- id string of input
   */
  onFormInputChange = (e, id) => {
    let form = { ...this.state.form };

    if (id === "price") {
      form.price = e.target.value;
      form.changeConsignment[id] = {
        id: form.consignment.price.id,
        default_price: e.target.value * 100
      };
    } else if (id === "cost") {
      let cost = e.target.value !== "" ? e.target.value : null;

      form.cost = cost;
      form.changeConsignment[id] = cost ? cost * 100 : cost;
    } else if (id === "listOnShopify") {
      const list_on_shopify = e.target.checked;
      form.list_on_shopify = list_on_shopify;
      form.changeConsignment["list_on_shopify"] = list_on_shopify;
      this.setState({
        item: { ...this.state.item, list_on_shopify },
        listOnShopify: list_on_shopify
      });
    } else if (id === "listOnEbay") {
      const list_on_ebay = e.target.checked;
      form.list_on_ebay = list_on_ebay;
      form.changeConsignment["list_on_ebay"] = list_on_ebay;
      this.setState({
        item: { ...this.state.item, list_on_ebay },
        listOnEbay: list_on_ebay
      });
    } else {
      if (id !== "style") {
        form.create_new = true;
      }
      form.sku[id] = e.target.value;
      form.changeSKU[id] = e.target.value;
    }

    this.setState({ form });
  };

  onSizeChange = size => {
    let form = { ...this.state.form };
    form.consignment.size = size;
    form.changeConsignment.size = size;
    form.changeSKU.us_size = size;
    form.sku.us_size = size;
    form.consignment.waist = null;
    form.consignment.length = null;

    this.setState({ form });
  };

  /**
   * On type change
   * @param  {String} typeId -- id of type
   */
  onTypeChange = typeId => {
    let form = { ...this.state.form };
    form.sku.item_type = typeId;
    form.changeSKU.item_type = typeId;
    this.setState({ form });
  };

  /**
   * On defect selected
   * @param  {String} defect --
   * @return {[type]}        [description]
   */
  onDefectClick = defect => {
    let form = { ...this.state.form };
    let isDefect = this.state.form.consignment[defect] ? false : true;
    form.consignment[defect] = isDefect;
    form.changeConsignment[defect] = isDefect;
    this.setState({ form });
  };

  onPackageClick = (packaging, packageCondition) => {
    let form = { ...this.state.form };
    form.consignment[packaging] = packageCondition;
    form.changeConsignment[packaging] = packageCondition;
    this.setState({ form });
  };
  onPantsChange = (type, size) => {
    let form = { ...this.state.form };
    form.consignment.size = null;
    if (type === "waist") {
      form.consignment.waist = size;
      form.changeConsignment.waist = size;
    } else {
      form.consignment.length = size;
      form.changeConsignment.length = size;
    }
    this.setState({ form });
  };

  onPicturesChange = pictures => {
    let form = { ...this.state.form };
    form.pictures = pictures;
    this.setState({
      form: form
    });
  };

  printBarcode = () => {
    this.setState({
      printBarcode: true
    });
  };

  syncClover = e => {
    let { messageActions, intl } = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      syncingClover: true
    });

    return fetch(
      API.SYNC_WITH_CLOVER({ id: this.state.product.id }),
      API.POST_CONFIG()
    )
      .then(Helpers.checkStatus)
      .then(_ => {
        messageActions.setMessage(
          intl.formatMessage({
            id: "clover_synced",
            defaultMessage: "Synced to Clover"
          })
        );
        messageActions.showMessage({ show: true });
        this.setState({
          syncingClover: false
        });
      });
  };

  updateConsignment = e => {
    e.preventDefault();
    let form = { ...this.state.form };

    if (
      this.state.form.changeConsignment.waist &&
      this.state.form.changeConsignment.length
    ) {
      form.changeConsignment.size = `${
        this.state.form.changeConsignment.waist
      }x${this.state.form.changeConsignment.length}`;
      form.consignment.size = `${this.state.form.changeConsignment.waist}x${
        this.state.form.changeConsignment.length
      }`;
      form.sku.us_size = `${this.state.form.changeConsignment.waist}x${
        this.state.form.changeConsignment.length
      }`;
      form.changeSKU.us_size = `${this.state.form.changeConsignment.waist}x${
        this.state.form.changeConsignment.length
      }`;
      form.changeConsignment.waist = null;
      form.changeConsignment.length = null;
      form.consignment.waist = null;
      form.consignment.length = null;
    }

    let consignment_params = {
      consignment: form.changeConsignment
    };
    delete form.sku.quantity;
    let sku_params = {
      sku: form.sku,
      changeSKU: form.changeSKU,
      create_new: form.create_new
    };

    delete sku_params.changeSKU.barcode;

    // let item = {
    //   sku: form.sku,
    //   list_on_shopify: form.list_on_shopify,
    //   list_on_ebay: form.list_on_ebay,
    //   consignment: { ...form.consignment, ...form.changeConsignment }
    // };

    this.setState({
      updatingConsignment: true
    });

    let finishUpdatingConsignment = () => {
      this.setState({
        updatingConsignment: false
      });
      //   this.props.updateConsignment(item, this.props.index);
      this.goBack();
    };

    let consignmentDone = false;
    if (Object.keys(this.state.form.changeConsignment).length > 0) {
      consignmentDone = this.updateConsignmentItem(consignment_params);
    }

    let skuDone = false;
    if (Object.keys(this.state.form.changeSKU).length > 0) {
      skuDone = this.updateSKU(sku_params);
    }

    let pictureDone = false;
    pictureDone = this.updatePicture(
      form.pictures,
      this.state.product.sku.product_name_us,
      form.consignment.id
    );

    Promise.all([consignmentDone, skuDone, pictureDone]).then(vals => {
      finishUpdatingConsignment();
    });
  };

  /**
   * Updates the consignment item
   */
  updateConsignmentItem = consignment_params => {
    return fetch(
      API.CONSIGNMENT({ id: this.state.product.consignment.id }),
      API.PUT_CONFIG(consignment_params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        return res;
      });
  };

  /**
   * Updates the SKU first and then updates the inventory item
   */
  updateSKU = sku_params => {
    return fetch(
      API.SKU_FIND_OR_CREATE({ id: this.state.product.sku.id }),
      API.POST_CONFIG(sku_params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        return fetch(
          API.INVENTORY({ id: this.state.product.id }),
          API.PUT_CONFIG({ sku_id: res.id })
        )
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON)
          .then(res => {
            return res;
          });
      });
  };

  updatePicture = (pictures, product_name_us, consignment_id) => {
    let originPics = this.state.originPics;
    let fetches = [];

    //remove pic
    originPics.forEach(pic => {
      if (pictures.findIndex(p => p.id === pic.id) === -1) {
        fetches.push(
          fetch(
            API.CONSIGNMENT_PICTURE({ id: pic.id }),
            API.DELETE_CONFIG()
          ).then(Helpers.checkStatus)
        );
      }
    });
    //upload pic

    let upload_pictures = [];
    pictures.forEach(pic => {
      if (!("id" in pic)) {
        upload_pictures.push(pic);
      }
    });

    if (upload_pictures.length !== 0) {
      let formData = new FormData();
      for (let i = 0; i < upload_pictures.length; i++) {
        formData.append("pictures[]", upload_pictures[i]);
      }
      formData.append("product_name_us", product_name_us);

      fetches.push(
        fetch(
          API.CONSIGNMENT_PICTURE({
            id: consignment_id,
            upload_photos: true
          }),
          API.POST_FORM_DATA_CONFIG(formData)
        )
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON)
      );
    }

    return Promise.all(fetches);
  };

  hidePriceChange = e => {
    this.setState({ hidePrice: !e.target.checked });
  };

  /***
   * On unmount of barcode modal
   */
  printFinished = () => {
    this.setState({
      printBarcode: false
    });

    let params = {
      barcode_printed: true
    };

    return fetch(
      API.CONSIGNMENT({ id: this.state.product.consignment.id }),
      API.PUT_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON);
  };

  selectDeletionReason = event => {
    const selectedDeleteReason = this.state.deleteReasons[event];
    let showDeletedReasonFreeFormText = true;
    if (selectedDeleteReason === "Consignor took back wcv") {
      showDeletedReasonFreeFormText = false;
    }

    this.setState({
      selectedDeleteReason,
      showDeletedReasonFreeFormText,
      deleteReasonText: null
    });
  };

  handleInputReasonTextChange = e => {
    this.setState({
      deleteReasonText: e.target.value
    });
  };

  cancelDeleteConsignment = () => {
    this.setState({
      showDeleteModal: false,
      deleteReason: null
    });
  };

  handleDeleteConfirmation = () => {
    let deleteReason = "";

    if (this.state.selectedDeleteReason) {
      deleteReason = this.state.selectedDeleteReason;
    }

    if (this.state.deleteReasonText) {
      deleteReason = deleteReason + " " + this.state.deleteReasonText;
    }

    this.setState({
      deletingConsignment: true,
      showDeleteModal: false
    });

    return fetch(
      API.INVENTORY({ id: this.state.product.id }),
      API.PUT_CONFIG({ active: false, delete_reason: deleteReason })
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.goBack();
        this.setState({
          deletingConsignment: false,
          loading: false
        });
      });
  };

  disableDeleteConfirmation = () => {
    if (this.state.selectedDeleteReason === "Consignor took back wcv") {
      return false;
    } else if (
      this.state.selectedDeleteReason !== null &&
      this.state.selectedDeleteReason !== "Consignor took back wcv" &&
      this.state.deleteReasonText
    ) {
      return false;
    }

    return true;
  };

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    this.setState({
      activeTab: tab
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleCloseModal = () => {
    this.setState({
      showMissingModal: false,
      form: {
        consignment: {},
        sku: {},
        changeConsignment: {},
        changeSKU: {},
        create_new: false,
        pictures: []
      },
      sold_at: null
    });
  };

  handleCloseRelistModal = () => {
    this.setState({
      showRelistModal: false,
      form: {
        consignment: {},
        sku: {},
        changeConsignment: {},
        changeSKU: {},
        create_new: false,
        pictures: []
      },
      sold_at: null
    });
  };

  selectMissingReason = event => {
    const selectedMissingReason = this.state.missingReasons[event];
    this.setState({
      selectedMissingReason
    });
  };

  mark_as_missing = () => {
    this.setState({
      showMissingModal: true
    });
  };

  /**
   * Relist Item
   *
   */

  relist = () => {
    this.setState({
      showRelistModal: true
    });
  };

  handleItemRelist = async () => {
    let { messageActions } = this.props;

    let params = {
      reason: this.state.relistReason
    };

    return fetch(
      API.REMARK_AS_LISTED({ id: this.state.product.id }),
      API.PUT_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(() => {
        console.log("Finish Relist");
        this.setState({ showRelistModal: false }, () => {
          this.getProduct();
        });
      })
      .catch(error => {
        if (error.message === "Forbidden") {
          messageActions.showMessage({ show: false });
          messageActions.setMessage("Permission Denied");
          messageActions.showMessage({ show: true, error: true });
        }
      });
  };

  handleItemMissing = async () => {
    let { messageActions } = this.props;

    let params = {
      reason: this.state.selectedMissingReason
    };

    return fetch(
      API.MARK_AS_MISSING({ id: this.state.product.id }),
      API.PUT_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(() => {
        console.log("Finish Marking as Missing");
        this.getProduct();
        // this.props.removeConsignment(this.props.id);
      })
      .catch(error => {
        if (error.message === "Forbidden") {
          messageActions.showMessage({ show: false });
          messageActions.setMessage("Permission Denied");
          messageActions.showMessage({ show: true, error: true });
        }
      });
  };

  componentDidMount() {
    this.getProduct();
  }

  loadDeleteModal = () => {
    return (
      <Modal
        show={this.state.showDeleteModal}
        onHide={this.closeDeleteModal}
        centered={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete consignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="Delete reasons">
              <Row>
                <Col>
                  <DropdownButton
                    variant="danger"
                    id="dropdown-item-button"
                    title={`Why you are deleting it ?`}
                  >
                    {this.state.deleteReasons.map((reason, index) => {
                      return (
                        <Dropdown.Item
                          onSelect={this.selectDeletionReason}
                          eventKey={index}
                        >
                          {reason}
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                </Col>
                {this.state.selectedDeleteReason && (
                  <Col className={css(styles.deleteReasonContainer)}>
                    <span className={css(styles.deleteReason)}>
                      {this.state.selectedDeleteReason}
                    </span>
                  </Col>
                )}
              </Row>
            </Form.Group>
            {this.state.showDeletedReasonFreeFormText && (
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Please give a reason for deletion </Form.Label>
                <Form.Control
                  value={this.state.deleteReasonText}
                  onChange={e => this.handleInputReasonTextChange(e)}
                  size="small"
                  type="text"
                  placeholder="Enter a reason"
                />
              </Form.Group>
            )}
            <Modal.Footer>
              <BootstrapButton
                variant="secondary"
                onClick={this.closeDeleteModal}
              >
                Cancel
              </BootstrapButton>

              <BootstrapButton
                variant="danger"
                disabled={this.disableDeleteConfirmation()}
                onClick={this.handleDeleteConfirmation}
              >
                OK
              </BootstrapButton>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  loadMissingModal = () => (
    <Modal
      show={this.state.showMissingModal}
      onHide={this.handleCloseModal}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Mark as Missing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="missing">
            <Row>
              <Col>
                <DropdownButton
                  variant="danger"
                  id="dropdown-item-button"
                  title="Why is this item missing?"
                >
                  {this.state.missingReasons.map((reason, index) => {
                    return (
                      <Dropdown.Item
                        onSelect={this.selectMissingReason}
                        eventKey={index}
                      >
                        {reason}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
              </Col>
              {this.state.selectedMissingReason && (
                <Col className={css(styles.rejectionReasonContainer)}>
                  <span className={css(styles.rejectionReason)}>
                    {this.state.selectedMissingReason}
                  </span>
                </Col>
              )}
            </Row>
          </Form.Group>
          <Modal.Footer>
            <BootstrapButton
              variant="secondary"
              onClick={this.handleCloseModal}
            >
              Cancel
            </BootstrapButton>

            <BootstrapButton
              variant="danger"
              disabled={!this.state.selectedMissingReason}
              onClick={this.handleItemMissing}
            >
              OK
            </BootstrapButton>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );

  loadRelistModal = () => (
    <Modal
      show={this.state.showRelistModal}
      onHide={this.handleCloseRelistModal}
      centered={true}
    >
      <Modal.Header closeButton>
        <Modal.Title>Remark as Listed</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="missing">
            <Form.Label>Please give a reason for remark as listed </Form.Label>
            <Form.Control
              value={this.state.relistReason}
              onChange={e => {
                e.preventDefault();
                this.setState({ relistReason: e.target.value });
              }}
              size="small"
              type="text"
              placeholder="Enter a reason"
            />
          </Form.Group>
          <Modal.Footer>
            <BootstrapButton
              variant="secondary"
              onClick={this.handleCloseRelistModal}
            >
              Cancel
            </BootstrapButton>

            <BootstrapButton
              variant="danger"
              disabled={!this.state.relistReason}
              onClick={this.handleItemRelist}
            >
              OK
            </BootstrapButton>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );

  render() {
    let { intl } = this.props;
    let tabs = this.tabs.map((tab, index) => {
      return (
        <div
          className={css(
            styles.tab,
            this.state.activeTab === tab.id && styles.activeTab
          )}
          onClick={() => this.setActiveTab(tab.id)}
          key={`tab_${tab.id}_${index}`}
          id={tab.id === "sold" && "consignmentsSold"}
        >
          {tab.label}
        </div>
      );
    });
    let conditions = [];
    let price = 0;
    let barcode = "";
    let final_conditions = [];
    if (this.state.product) {
      if (this.state.product.consignment) {
        let consignment = this.state.product.consignment;
        conditions = [
          consignment.yellowing && "yellowing",
          consignment.defects && "defects",
          consignment.missing_insoles && "missing insoles",
          consignment.scuff_marks && "scuff_marks",
          consignment.used && "used"
        ];

        for (let i = 0; i < conditions.length; i++) {
          if (conditions[i] !== false) {
            final_conditions.push(conditions[i]);
          }
        }

        price = consignment.price.default_price;
        barcode = `ss-${this.state.product.id}`;
      } else {
        price = this.state.product.price.instore_price;
        barcode = this.state.product.sku.barcode;
      }
    }

    let picture_url = null;
    if (this.state.product) {
      if (
        this.state.product.pictures &&
        this.state.product.pictures.length > 0
      ) {
        picture_url = this.state.product.pictures[0].url;
      }
    }

    // let consignment_pics = [];
    // if (this.state.product) {
    //   consignment_pics = this.state.product.consignment.pictures.map(
    //     (picture, index) => {
    //       return picture.url;
    //     }
    //   );
    // }

    return (
      <div className={css(styles.inventoryItem)}>
        {this.state.loading ? (
          <></>
        ) : this.state.product ? (
          <>
            <div className={css(styles.buttonWrapper)}>
              <button className={css(styles.button)} onClick={this.goBack}>
                Back
              </button>
              <div className={css(styles.rightButtonWrapper)}>
                {this.state.product.consignment.status !== "sold" &&
                  this.state.product.consignment.status !== "deleted" &&
                  this.state.product.consignment.status !== "missing" && (
                    <div className={css(styles.showPriceContainer)}>
                      <input type="checkbox" onChange={this.hidePriceChange} />
                      <span style={{ marginRight: 10 }}>Show Price</span>
                      <button
                        className={css(styles.button)}
                        onClick={this.printBarcode}
                      >
                        {intl.formatMessage({
                          id: "print_label",
                          defaultMessage: "Print Label"
                        })}
                      </button>
                    </div>
                  )}

                {this.state.product.consignment.status === "listed" && (
                  <button
                    className={css(styles.button, styles.cloverSync)}
                    onClick={this.syncClover}
                  >
                    {this.state.syncingClover ? (
                      <FontAwesomeIcon
                        spin
                        icon={["far", "spinner-third"]}
                        className={css(styles.icon)}
                      />
                    ) : (
                      <FormattedMessage
                        id="sync_clover"
                        defaultMessage={`Sync with Clover`}
                      />
                    )}
                  </button>
                )}

                {this.state.product.consignment.status !== "deleted" &&
                  this.deleteConsignmentButton()}
              </div>
            </div>
            <div className={css(styles.title)}>
              <div className={css(styles.productInfo)}>
                <img
                  alt="product"
                  src={picture_url}
                  className={css(styles.productImg)}
                />
                <div className={css(styles.titleInfo)}>
                  <h2>ID #{this.state.product.id}</h2>
                  <h1>{this.state.product.sku.product_name_us}</h1>
                </div>
              </div>
            </div>
            <div className={css(styles.tabContainer)}>{tabs}</div>
            <div className={css(styles.contentWrapper)}>
              {this.state.activeTab === "consignment_info" && (
                <>
                  <div
                    style={{
                      padding: 10,
                      backgroundColor: "#eee"
                    }}
                  >
                    {this.state.form.consignment.pending_at && (
                      <div>
                        Pending at:{" "}
                        {new Date(
                          this.state.form.consignment.pending_at
                        ).toLocaleString("en-US")}
                      </div>
                    )}
                    {this.state.form.consignment.approved_at && (
                      <div>
                        Approved at:{" "}
                        {new Date(
                          this.state.form.consignment.approved_at
                        ).toLocaleString("en-US")}
                      </div>
                    )}
                    {this.state.form.consignment.listed_at && (
                      <div>
                        Listed at:{" "}
                        {new Date(
                          this.state.form.consignment.listed_at
                        ).toLocaleString("en-US")}
                      </div>
                    )}
                    {this.state.product.sold_at && (
                      <div>
                        Sold at:{" "}
                        {new Date(this.state.product.sold_at).toLocaleString(
                          "en-US"
                        )}
                      </div>
                    )}
                  </div>
                  <ConsignmentFields
                    form={this.state.form}
                    noQuantity={true}
                    upcCodes={this.state.upcCodes}
                    instructions={"Edit consignment information as needed"}
                    buttonText={
                      this.state.updatingConsignment ? (
                        <FontAwesomeIcon
                          icon={["far", "spinner-third"]}
                          className={css(styles.iconSpinner)}
                          spin
                        />
                      ) : (
                        intl.formatMessage({
                          id: "update_consignment",
                          defaultMessage: "Update Consignment"
                        })
                      )
                    }
                    onFormInputChange={this.onFormInputChange}
                    onSizeChange={this.onSizeChange}
                    onTypeChange={this.onTypeChange}
                    onDefectClick={this.onDefectClick}
                    onPackageClick={this.onPackageClick}
                    onPantsChange={this.onPantsChange}
                    onPicturesChange={this.onPicturesChange}
                    onButtonClick={this.updateConsignment}
                    listOnShopify={this.state.listOnShopify}
                    listOnEbay={this.state.listOnEbay}
                    hideCost={this.props.hideCost}
                    changeable={
                      this.state.product.consignment.status !== "sold" &&
                      this.state.product.consignment.status !== "deleted" &&
                      this.state.product.consignment.status !== "missing"
                    }
                    locationId={this.state.locationId}
                    onlyShowConsignment
                    manageItem
                  />
                </>
              )}
              {this.state.activeTab === "product_info" && (
                <ProductInfo form={this.state.form} />
              )}
              {this.state.activeTab === "scanned_session" && (
                <div>
                  {(this.state.product.consignment.status === "listed" ||
                    this.state.product.consignment.status === "ordered") && (
                    <button
                      className={css(styles.button, styles.deleteConsignment)}
                      onClick={this.mark_as_missing}
                    >
                      {intl.formatMessage({
                        id: "mark_as_missing",
                        defaultMessage: "Mark As Missing"
                      })}
                    </button>
                  )}
                  {this.loadMissingModal()}
                  {this.state.product.consignment.status === "missing" && (
                    <>
                      <button
                        className={css(styles.button, styles.deleteConsignment)}
                        onClick={this.relist}
                      >
                        {intl.formatMessage({
                          id: "relist",
                          defaultMessage: "Remark As Listed"
                        })}
                      </button>
                      <div
                        style={{
                          padding: 10,
                          backgroundColor: "#eee"
                        }}
                      >
                        <div>
                          Missing At:{" "}
                          {new Date(
                            this.state.product.consignment.missing_at
                          ).toLocaleString("en-US")}
                        </div>
                        <div>
                          Missing Reason:
                          {this.state.product.consignment.note}
                        </div>
                      </div>
                    </>
                  )}
                  {this.loadRelistModal()}

                  <h3 style={{ marginTop: 10 }}>
                    {intl.formatMessage({
                      id: "last_scanned_session",
                      defaultMessage: "Scanned Sessions"
                    })}
                  </h3>

                  {this.state.product.scanned_sessions &&
                    this.state.product.scanned_sessions
                      .split(",")
                      .map(session => <p>{session}</p>)}
                </div>
              )}
              {this.state.activeTab === "consignment_purchase_order" && (
                <>
                  <h4>
                    Consignment Order #{this.state.product.consignment_order}{" "}
                  </h4>
                  <div
                    style={{
                      color: "rgb(180,180,180)",
                      fontSize: "1.2em"
                    }}
                  >
                    Consigner: {this.state.product.consigner.first_name}{" "}
                    {this.state.product.consigner.last_name}
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <h1>Consignment Not Found</h1>
        )}
        {this.state.product && this.state.printBarcode && (
          <BarcodeModal
            unmount={() => this.setState({ printBarcode: false })}
            component={
              <Barcode
                name={this.state.product.sku.product_name_us}
                conditions={final_conditions}
                price={price / 100}
                barcode={barcode}
                color={this.state.product.sku.color}
                size={this.state.product.sku.us_size}
                style={this.state.product.sku.style}
                hidePrice={this.state.hidePrice}
              />
            }
          />
        )}
      </div>
    );
  }
}

var styles = StyleSheet.create({
  inventoryItem: {
    padding: 50
  },
  title: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 50,
    height: "25vh"
  },
  tab: {
    marginRight: 20,
    opacity: ".3",
    fontSize: 20,
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  },
  tabContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: 10,
    position: "relative"
  },
  contentWrapper: {
    padding: 10
  },
  productInfo: {
    marginRight: 50,
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  consignmentContainer: {
    position: "absolute",
    top: 25,
    width: "100%",
    zIndex: 2
  },
  manageContainer: {
    padding: 20
  },
  print: {
    position: "absolute",
    left: 20
  },
  button: {
    backgroundColor: colors.DARK_BLUE(1),
    borderRadius: 4,
    border: 0,
    padding: 16,
    color: "#fff",
    fontSize: 18,
    letterSpacing: 0.7,
    cursor: "pointer",
    outline: "none",
    marginRight: 15
  },
  iconSpinner: {
    color: "#fff"
  },
  deleteConsignment: {
    backgroundColor: colors.RED(1)
  },
  cancelDelete: {
    backgroundColor: colors.GREY(1),
    marginRight: 16
  },
  productImg: {
    height: "100%"
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 40
  },
  rightButtonWrapper: {
    display: "flex"
  },
  cloverSync: {
    background: "#65c174",
    marginLeft: 16
  },
  photos: {
    width: 300
  },
  deleteReasonContainer: {
    display: "flex"
  },
  deleteReason: {
    paddingTop: "0.5em"
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InventoryItem)
);
