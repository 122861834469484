import React, { Fragment } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import Select from "react-select";
import { FormattedMessage, injectIntl } from "react-intl";

// Config
import colors from "../../config/colors";

// const FILTER_TIMEOUT = 600;

class Filter extends React.Component {
  constructor(props) {
    super(props);

    let { intl } = this.props;

    this.selectOptions = [
      {
        value: "contains",
        label: intl.formatMessage({
          id: "filter_contains",
          defaultMessage: "Contains"
        })
      },
      {
        value: "gt",
        label: intl.formatMessage({
          id: "filter_gt",
          defaultMessage: "Greater Than"
        })
      },
      {
        value: "lt",
        label: intl.formatMessage({
          id: "filter_lt",
          defaultMessage: "Less Than"
        })
      }
    ];

    this.state = {
      filterType: this.selectOptions[0],
      filterValue: "",
      filterChosen: false
    };
  }

  /***
   * Changing the filter type
   * @params  { option } -- a dict of {value: '', label: ''}
   */
  changeFilterType = option => {
    clearTimeout(this.filterValueTimeout);
    clearTimeout(this.filterTypeTimeout);
    // Update local state
    this.setState({
      filterType: option
    });
  };

  /***
   * Changing the filter type
   * @params event -- the event object that grabs the input
   */
  changeFilterValue = event => {
    clearTimeout(this.filterValueTimeout);
    clearTimeout(this.filterTypeTimeout);
    let filterValue = event.target.value;
    // Update local state
    this.setState({
      filterValue
    });
  };

  filterClicked = () => {
    if (this.state.filterChosen) {
      this.props.onChange("", "");
    }
    this.setState({
      filterChosen: !this.state.filterChosen,
      filterValue: "",
      filterType: this.selectOptions[0]
    });
  };

  /**
   * Filters the backend
   */
  submitFilter = e => {
    if (e.key === "Enter") {
      this.props.onChange &&
        this.props.onChange(
          this.state.filterValue,
          this.state.filterType.value
        );
    }
  };

  render() {
    return (
      <div className={css(styles.filter)}>
        <button className={css(styles.addFilter)} onClick={this.filterClicked}>
          {!this.state.filterChosen ? (
            <FormattedMessage
              id="filter_label_filter"
              defaultMessage="Filter"
            />
          ) : (
            <FormattedMessage
              id="filter_label_cancel"
              defaultMessage="Cancel"
            />
          )}
        </button>
        {this.state.filterChosen ? (
          <Fragment>
            <Select
              options={this.selectOptions}
              onChange={this.changeFilterType}
              value={this.state.filterType}
              className={css(styles.select)}
              menuContainerStyle={{ zIndex: 5 }}
            />
            <input
              value={this.state.filterValue}
              onChange={this.changeFilterValue}
              className={css(styles.input)}
              onKeyPress={this.submitFilter}
              placeholder={this.state.filterType.label + "..."}
            />
          </Fragment>
        ) : null}
      </div>
    );
  }
}

let styles = StyleSheet.create({
  selectField: {
    ":after": {
      content: ""
    }
  },
  addFilter: {
    width: "100%",
    height: "100%",
    fontVariant: "small-caps",
    fontSize: 18,
    background: colors.RED(1),
    borderRadius: 4,
    color: "#fff",
    cursor: "pointer",
    letterSpacing: 0.7,
    fontWeight: 400,
    border: `1px solid ${colors.RED(1)}`,
    outline: "none",
    marginBottom: 12
  },
  select: {
    zIndex: 2
  },
  input: {
    marginTop: 12,
    width: "100%",
    padding: 8,
    // border: 'none',
    // borderBottom: '1px solid #eee',
    boxSizing: "border-box"
  },
  filter: {
    width: "100%",
    zIndex: 5,
    position: "relative"
  }
});
export default injectIntl(Filter);
