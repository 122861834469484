/***
 * Manual Input modal for adding new products
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isAfterDay from "react-dates/lib/utils/isAfterDay";
import moment from "moment";
import numeral from "numeral";

// Components
import BaseModal from "./BaseModal";
import Button from "../Button";

// Actions
import { ModalActions } from "../../redux/modals";
import { AuthActions } from "../../redux/auth";
import { MessageActions } from "../../redux/message";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Stylesheets
import "react-dates/lib/css/_datepicker.css";
import "./stylesheets/react_dates.css";
import Input from "../Input";
import CurrencyDisplay from "../CurrencyDisplay";

class ConsignerPayoutsModal extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      items: [],
      startDate: moment()
        .subtract(1, "weeks")
        .startOf("week"),
      endDate: moment()
        .subtract(1, "weeks")
        .endOf("week"),
      message: null,
      fetchingData: false,
      postingData: false,
      currentPayoutAmount: 0,
      itemClicked: null,
      payout_value: 0,
      payoutCheck: false,
      location: 1,
      locationOptions: {},
      balanceByLocation: {},
      amount: 0
    };
    this.state = {
      ...this.initialState
    };
  }

  closeModal = () => {
    let { modalActions } = this.props;
    this.setState({
      ...this.initialState
    });
    modalActions.openConsignerPayoutsModal(false, {
      profile: {},
      location: {}
    });
  };

  salesReportDatesChange = ({ startDate, endDate }) => {
    this.setState({
      startDate,
      endDate
    });
  };

  isOutsideRange = day => {
    let dayIsBlocked = false;

    if (isAfterDay(day, moment().subtract(0, "day"))) {
      dayIsBlocked = true;
    }

    return dayIsBlocked;
  };

  /**
   *
   * Get balance inventory
   */

  /**
   *
   * Get Balance
   */
  getBalance = () => {
    let { modals } = this.props;

    let consigner = modals.payouts.profile;
    if (!this.props.payout) {
      consigner = modals.payouts;
    }

    fetch(API.PAYOUT_AMOUNT({ user_id: consigner.user }), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState(
          {
            locationOptions: res.locations,
            balanceByLocation: res.amount_by_location,
            amount: res.amount
          },
          () => {}
        );
      });
  };

  /**
   * Gets the report specified by start date and end date
   */
  // getReportByDataRange = () => {
  // 	let { modals, payout, intl } = this.props;
  // 	let { startDate, endDate } = this.state;
  // 	let endpoint = "";
  // 	let consigner = null;

  // 	if (!payout) {
  // 		consigner = modals.payouts;
  // 		endpoint =
  // 			API.CHECK_PAYOUT + `?consigner=${modals.payouts.user}&sold=True`;
  // 	} else {
  // 		consigner = modals.payouts.profile;
  // 		endpoint =
  // 			API.CHECK_PAYOUT +
  // 			`?consigner=${modals.payouts.receiver}&sold=True`;
  // 	}

  // 	if (modals.payouts.location) {
  // 		endpoint += `&location=${modals.payouts.location.id}`;
  // 	}
  // 	if (!payout && !startDate && !endDate) {
  // 		this.setState({
  // 			message: intl.formatMessage({
  // 				id: "please_select_valid_date",
  // 				defaultMessage:
  // 					"Please select a valid date range before continuing",
  // 			}),
  // 		});
  // 		return;
  // 	} else if (payout) {
  // 		endpoint += `&consignment__payout__payout_group=${
  // 			this.props.payoutId
  // 		}`;
  // 		if (!this.props.payoutHistory) {
  // 			endpoint += `&consignment__payout__status=requested,failed,pending`;
  // 		}
  // 	}

  // 	if (startDate) {
  // 		startDate = startDate.format("L");
  // 		endpoint += `&sold_at__date__gte=${startDate}`;
  // 	}

  // 	if (this.props.payoutHistory) {
  // 		endpoint += `&history=true`;
  // 	}

  // 	if (endDate) {
  // 		endDate = endDate.format("L");
  // 		endpoint += `&sold_at__date__lte=${endDate}`;
  // 	}

  // 	this.setState({
  // 		fetchingData: true,
  // 	});
  // 	return fetch(endpoint, API.GET_CONFIG())
  // 		.then(Helpers.checkStatus)
  // 		.then(Helpers.parseJSON)
  // 		.then((res) => {
  // 			for (let i = 0; i < res.items.length; i++) {
  // 				let currentItem = res.items[i];
  // 				let consignmentRate =
  // 					consigner.non_shoe_consignment_rate / 100;
  // 				if (
  // 					currentItem.sku.product &&
  // 					currentItem.sku.product.item_type &&
  // 					currentItem.sku.product.item_type.includes("shoe")
  // 				) {
  // 					consignmentRate = consigner.shoe_consignment_rate / 100;
  // 				}
  // 			}
  // 			this.setState({
  // 				...res,
  // 				fetchingData: false,
  // 			});
  // 		});
  // };
  getReport = () => {
    let { modals } = this.props;

    let consigner = modals.payouts.profile;
    if (!this.props.payout) {
      consigner = modals.payouts;
    }

    this.setState({
      fetchingData: true
    });
    return fetch(
      API.CURRENT_BALANCE({ user_id: consigner.user }),
      API.GET_CONFIG()
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        for (let i = 0; i < res.items.length; i++) {
          let currentItem = res.items[i];
          // let consignmentRate = consigner.non_shoe_consignment_rate / 100;
          if (
            currentItem.sku.product &&
            currentItem.sku.product.item_type &&
            currentItem.sku.product.item_type.includes("shoe")
          ) {
            // consignmentRate = consigner.shoe_consignment_rate / 100;
          }
        }
        this.setState({
          ...res,
          fetchingData: false
        });
      });
  };

  /**
   * After the modal is opened, if we're on the payout, fetch the payouts
   */
  onAfterOpen = () => {
    const { payoutHistory } = this.props;
    if (payoutHistory) {
      this.getReport();
    } else {
      this.getReport();
    }
    this.getBalance();

    if (this.props.payoutCheck) {
      this.setState({
        payoutCheck: true
      });
      alert("The consigner requested a check for this payout");
    }
  };

  /**
   * ENDPOINT TO CREATE REQUESTED PAYOUT
   */
  request_payouts = async (locationId, payoutAmount) => {
    let { modals, messageActions } = this.props;

    let params = {
      user_id: modals.payouts.user,
      amount_value: payoutAmount,
      status: "open",
      location: locationId,
      payout_check: true,
      payout_type: "check"
    };

    messageActions.showMessage({ show: true, loader: true });
    return fetch(API.REQUESTED_PAYOUT, API.POST_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(async _ => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage("Payout Requested!");
        messageActions.showMessage({ show: true });
        this.getReport();
        this.getBalance();
      })
      .catch(error => {
        console.log({ error });
        if (error.response) {
          try {
            console.log({ errorJson: error.response });
            messageActions.showMessage({ show: false });
            messageActions.setMessage(error.message.error);
            messageActions.showMessage({
              show: true,
              error: true,
              timeout: 5000
            });
          } catch (e) {
            console.log({ e });
            messageActions.showMessage({ show: false });
            messageActions.setMessage("Something is Wrong");
            messageActions.showMessage({ show: true, error: true });
          }

          // error.response
          //   .json()
          //   .then(errorJson => {
          //     console.log({errorJson})
          //     messageActions.showMessage({ show: false });
          //     messageActions.setMessage(errorJson.error);
          //     messageActions.showMessage({ show: true, error: true });
          //   })
          //   .catch(e => {
          //     console.log({e})
          //     messageActions.showMessage({ show: false });
          //     messageActions.setMessage(intl.formatMessage({ id: 'something_wrong', defaultMessage: 'Something went wrong!' }));
          //     messageActions.showMessage({ show: true, error: true });
          //   });
        } else {
          messageActions.showMessage({ show: false });
          messageActions.setMessage("Something went wrong!");
          messageActions.showMessage({ show: true, error: true });
        }
      });
  };

  /**
   * When we change a payout value, we want to change the value in state
   * @param { Event } e -- the event for onClick
   * @param { Integer } index -- the index of the item we clicked on
   */
  onPayoutChange = (e, index) => {
    let value = e.target.value;
    this.setState({
      currentPayoutAmount: value
    });
  };

  /**
   * Saving the payout rate
   */
  onPayoutSave = (e, index) => {
    let { items, currentPayoutAmount } = this.state;
    let value = currentPayoutAmount;
    let newItems = JSON.parse(JSON.stringify(items));

    newItems[index].payout_value = value * 100;
    this.setState({
      items: newItems,
      itemClicked: null,
      currentPayoutAmount: null
    });
  };

  /**
   * When we click the payout amount, allow the user to edit the payout amount
   * @param { Event } e -- the event for onClick
   * @param { Integer } index -- the index of the item we clicked on
   */
  onClickAmount = (e, index) => {
    e.stopPropagation();
    this.setState({
      itemClicked: index,
      currentPayoutAmount: this.state.items[index].payout_value / 100
    });
  };

  /**
   * Changes the payout type from check --> ACH and vice versa
   */
  togglePayoutType = e => {
    let ach = e.target.checked;
    this.setState({
      payoutCheck: !ach
    });

    let { modals, messageActions } = this.props;

    let rest = {
      route: "switch_payout_request",
      id: modals.payouts.id
    };

    let data = {
      payout_check: !ach
    };
    messageActions.showMessage({ show: true, load: true });
    return fetch(API.REQUEST_PAYOUT_2({ rest }), API.POST_CONFIG(data))
      .then(Helpers.checkStatus)
      .then(_ => {
        messageActions.showMessage({ show: false });
        this.props.updatePayout({
          index: this.props.payoutIndex,
          payoutCheck: !ach
        });
      })
      .catch(e => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage(e.message);
        messageActions.showMessage({ show: true, error: true });
      });
  };

  /**
   * Getting inventory for a specific consigner at a specific location
   */
  getInventory = () => {
    let { modals, messageActions } = this.props;
    let consigner = modals.payouts;
    let params = {
      route: "download_consigner_report",
      consigner: consigner.user
    };
    messageActions.showMessage({ show: true, load: true });
    return fetch(API.INVENTORY_CSV(params), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        this.setState({
          message: null,
          exportingInventory: false
        });
        let link = document.createElementNS(
          "http://www.w3.org/1999/xhtml",
          "a"
        );
        link.href = URL.createObjectURL(res);
        link.download = `${consigner.first_name}_${
          consigner.last_name
        }_inventory.csv`;
        link.click();
        messageActions.showMessage({ show: false });
      });
  };

  render() {
    let { modals, payout, intl, auth } = this.props;
    let items = this.state.items.map((item, index) => {
      let payoutAmount = item.payout_value / 100;
      return (
        <div className={css(styles.soldConsignment)} key={`payout_${item.id}`}>
          <div className={css(styles.column)}>ss-{item.id}</div>
          <div className={css(styles.column)}>{item.sku.product_name_us}</div>
          <div className={css(styles.column)}>
            {item.purchased_price ? (
              <CurrencyDisplay price={item.purchased_price / 100} />
            ) : (
              intl.formatMessage({
                id: "marked_sold",
                defaultMessage: "MARKED SOLD, NO PURCHASE PRICE"
              })
            )}
          </div>
          {this.state.itemClicked === index && payout ? (
            <div
              className={css(styles.column, styles.rateEdit)}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <Input
                className={styles.rateInput}
                value={this.state.currentPayoutAmount}
                onChange={e => this.onPayoutChange(e, index)}
                autoFocus
              />
              <Button
                className={styles.rateButton}
                text={intl.formatMessage({
                  id: "save",
                  defaultMessage: "Save"
                })}
                onClick={e => this.onPayoutSave(e, index)}
              />
            </div>
          ) : (
            <div
              className={css(styles.column)}
              onClick={e => this.onClickAmount(e, index)}
            >
              {payoutAmount ? <CurrencyDisplay price={payoutAmount} /> : null}
            </div>
          )}
          <div className={css(styles.column)}>
            {moment(item.sold_at).format("L")}
          </div>
          <div className={css(styles.column)}>
            {item.location && item.location.name}
          </div>
          <div className={css(styles.column)}>
            {item.consignment.is_paid === "success"
              ? intl.formatMessage({
                  id: "yes",
                  defaultMessage: "Yes"
                })
              : item.consignment.is_paid === "initiated"
              ? intl.formatMessage({
                  id: "initiated",
                  defaultMessage: "Initiated"
                })
              : item.consignment.is_paid === "transferred"
              ? intl.formatMessage({
                  id: "transferred",
                  defaultMessage: "Transferred"
                })
              : item.consignment.is_paid === "failed"
              ? intl.formatMessage({
                  id: "failed",
                  defaultMessage: "Failed"
                })
              : intl.formatMessage({
                  id: "no",
                  defaultMessage: "No"
                })}
          </div>
        </div>
      );
    });

    let payoutValue = 0;
    for (let i = 0; i < this.state.items.length; i++) {
      payoutValue += this.state.items[i].payout_value;
    }

    let consigner = modals.payouts.profile;
    if (!this.props.payout) {
      consigner = modals.payouts;
    }

    return (
      <BaseModal
        isOpen={modals.openConsignerPayoutsModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        onAfterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="ConsignerPayoutsModal"
      >
        <div className={css(styles.consignerPayoutsModalContainer)}>
          <div className={css(styles.reportContainer)}>
            <div className={css(styles.header)}>
              <div className={css(styles.instructionsContainer)}>
                <h1 className={css(styles.title)}>
                  {intl.formatMessage({
                    id: "consigner_report",
                    defaultMessage: "Consigner Report"
                  })}
                </h1>
                {this.state.message && (
                  <div className={css(styles.message)}>
                    {this.state.message}
                  </div>
                )}
              </div>
              <div className={css(styles.consignerInfo)}>
                <div className={css(styles.labelColumn)}>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "consigner",
                      defaultMessage: "Consigner"
                    })}:`}
                  </div>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "email_address",
                      defaultMessage: "Email"
                    })}:`}
                  </div>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "number",
                      defaultMessage: "Number"
                    })}:`}
                  </div>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "shoe_consignment_rate",
                      defaultMessage: "Shoe Consignment Rate"
                    })}:`}
                  </div>
                  <div className={css(styles.label)}>
                    {`${intl.formatMessage({
                      id: "apparel_consignment_rate",
                      defaultMessage: "Apparel Consignment Rate"
                    })}:`}
                  </div>
                  {this.props.payout && (
                    <div className={css(styles.label)}>
                      {`${intl.formatMessage({
                        id: "payout.id",
                        defaultMessage: "Payout ID"
                      })}:`}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.label)}>
                      {`${intl.formatMessage({
                        id: "payout.platform_id",
                        defaultMessage: "Stripe Payout ID"
                      })}:`}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.label)}>
                      {`${intl.formatMessage({
                        id: "payout.platform_payout_status",
                        defaultMessage: "Stripe Status"
                      })}:`}
                    </div>
                  )}
                </div>
                <div className={css(styles.infoColumn)}>
                  <div className={css(styles.info)}>
                    {`${consigner.first_name} ${consigner.last_name}`}
                  </div>
                  <div className={css(styles.info)}>{consigner.email}</div>
                  <div className={css(styles.info)}>
                    {consigner.phone_number}
                  </div>
                  <div className={css(styles.info)}>
                    {consigner.shoe_consignment_rate}%
                  </div>
                  <div className={css(styles.info)}>
                    {consigner.non_shoe_consignment_rate}%
                  </div>
                  {this.props.payout && (
                    <div className={css(styles.info)}>#{modals.payouts.id}</div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.info)}>
                      #{modals.payouts.platform_id}
                    </div>
                  )}
                  {this.props.payout && (
                    <div className={css(styles.info)}>
                      #{modals.payouts.platform_payout_status}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={css(styles.soldTitle)}>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "product_id",
                  defaultMessage: "Product ID"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "sku_product_name",
                  defaultMessage: "Product Name"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "price",
                  defaultMessage: "Price"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "payout_amount",
                  defaultMessage: "Payout Amount"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "date_sold",
                  defaultMessage: "Date Sold"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "inventory_location",
                  defaultMessage: "Location"
                })}
              </div>
              <div className={css(styles.column)}>
                {intl.formatMessage({
                  id: "paid",
                  defaultMessage: "Paid"
                })}
              </div>
            </div>
            <div className={css(styles.itemsContainer)}>
              {this.state.items.length > 0 ? (
                items
              ) : (
                <div className={css(styles.noItems)}>
                  {this.state.fetchingData ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      size={`3x`}
                      spin
                    />
                  ) : (
                    <div>
                      {intl.formatMessage({
                        id: "no_sales_found",
                        defaultMessage:
                          "No balance found for specified date range."
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={css(styles.payout)}>
              {`${intl.formatMessage({
                id: "total_payout_for_date",
                defaultMessage: "Total Payout Amount"
              })}: ${
                intl.locale === "en"
                  ? numeral(this.state.amount / 100).format("$0,0.00")
                  : new Intl.NumberFormat("zh-CN", {
                      style: "currency",
                      currency: "CNY"
                    }).format(this.state.amount / 100)
              }`}
            </div>
            <div className={css(styles.requestButtonGroup)}>
              {["admin", "accountant"].includes(
                auth.userProfile.role.toLowerCase()
              ) &&
                this.state.balanceByLocation &&
                Object.keys(this.state.balanceByLocation).map((key, index) => (
                  <Button
                    className={
                      this.state.balanceByLocation[key] === null ||
                      !this.state.balanceByLocation[key] > 0
                        ? styles.disabled
                        : styles.actionButton
                    }
                    text={`${intl.formatMessage({
                      id: "request_payouts",
                      defaultMessage: `Request for ${key}`
                    })}: ${
                      intl.locale === "en"
                        ? numeral(
                            this.state.balanceByLocation[key] / 100
                          ).format("$0,0.00")
                        : new Intl.NumberFormat("zh-CN", {
                            style: "currency",
                            currency: "CNY"
                          }).format(this.state.balanceByLocation[key] / 100)
                    }`}
                    onClick={e => {
                      // e.preventDefault();
                      this.request_payouts(
                        this.state.locationOptions[key],
                        this.state.balanceByLocation[key]
                      );
                    }}
                    disabled={payoutValue === null || !payoutValue > 0}
                  />
                ))}
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  modal: {
    maxWidth: 1200,
    width: "90%",
    position: "absolute",
    zIndex: 5,
    height: "90%"
  },
  bodyContainer: {
    height: "100%",
    maxHeight: "unset",
    alignItems: "unset",
    justifyContent: "unset",
    padding: 0
  },
  consignerPayoutsModalContainer: {
    padding: 20,
    minHeight: 600,
    height: "100%"
  },
  reportContainer: {
    //borderBottom: '1px solid #ddd',
    padding: 20,
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column"
  },
  datePickerContainer: {
    display: "flex",
    paddingBottom: 25
  },

  actionButton: {
    width: 300,
    height: 48,
    marginLeft: 20,
    fontSize: 18,
    padding: 10
  },
  requestButtonGroup: {
    width: "100%",
    marginTop: 20,
    display: "flex",
    justifyContent: "center"
  },
  closeRequest: {
    background: colors.DARK_BLUE(1),
    borderColor: colors.DARK_BLUE(1),

    ":hover": {
      background: colors.DARK_BLUE(0.8)
    }
  },
  checkRequested: {
    textAlign: "center"
  },
  message: {
    marginBottom: 10,
    color: colors.RED(0.7)
  },
  instructions: {
    width: 300,
    opacity: ".7",
    marginBottom: 20
  },
  title: {
    marginBottom: 10
  },
  soldConsignment: {
    width: "100%",
    display: "flex",
    paddingTop: 15,
    paddingBottom: 15,
    borderBottom: "1px solid #aaa"
  },
  column: {
    width: 200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  soldTitle: {
    fontSize: 18,
    fontWeight: "bold",
    width: "100%",
    display: "flex"
  },
  noItems: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 20,
    fontSize: 20
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  consignerInfo: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 30,
    marginLeft: 20
  },
  labelColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  label: {
    fontWeight: "bold",
    marginRight: 10
  },
  infoColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  info: {
    width: 300,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  instructionsContainer: {
    width: 300
  },
  toggle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 16
  },
  disabled: {
    background: "#aaa",
    border: "1px solid #aaa",
    //opacity: .5,
    width: 200,
    height: 48,
    marginLeft: 20,
    fontSize: 18,
    padding: 10,

    ":hover": {
      background: "#aaa"
    }
  },
  payout: {
    fontSize: 25,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "auto"
  },
  itemsContainer: {
    // height: 400,
    overflow: "auto",
    marginBottom: 20,
    flex: 1
  }
});
const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConsignerPayoutsModal)
);
