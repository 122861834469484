/**
 * Auth page for login and signup
 * @craiglu
 */

import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router";

// Components
import Input from "../../components/Input";
import Button from "../../components/Button";

// Redux
import { AuthActions } from "../../redux/auth";
import { MessageActions } from "../../redux/message";

// Configs
import API from "../../config/api";
import Helpers from "../../redux/helpers";

class FirstLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      password: ""
    };
  }

  componentDidMount() {
    let { auth } = this.props;
    if (!auth.userProfile.first_login) {
      this.props.history.push("/dashboard");
    }
  }

  /**
   * handles the change of inputs
   * @param  {Event} e -- event object when input value changes
   */
  handleInputChange = e => {
    let { authActions } = this.props;
    let params = {};
    params[e.target.id] = e.target.value;
    authActions.updateUserProfile(params);
  };

  changePassword = e => {
    this.setState({
      password: e.target.value
    });
  };

  submit = e => {
    let { auth } = this.props;
    e.preventDefault();

    if (this.state.page === 0) {
      this.setState({
        page: 1
      });
    } else {
      let params = {
        user_profile: { ...auth.userProfile, first_login: false }
      };
      params.password = this.state.password;
      fetch(API.USER + `${auth.userProfile.id}/`, API.PUT_CONFIG(params))
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(json => {
          this.props.history.push("/dashboard");
        });
    }
  };
  render() {
    let { auth, intl } = this.props;

    return (
      <div className={css(styles.authContainer)}>
        {this.state.page === 0 ? (
          <div className={css(styles.confirmContainer)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "confirm_info",
                defaultMessage: "Confirm your info"
              })}
            </div>
            <form className={css(styles.inputs)}>
              <div className={css(styles.inputContainer)}>
                <div className={css(styles.label)}>
                  {intl.formatMessage({
                    id: "profile_first_name",
                    defaultMessage: "First Name"
                  })}
                </div>
                <div className={css(styles.input)}>
                  <FontAwesomeIcon
                    icon={["far", "user"]}
                    className={css(styles.inputIcon)}
                    size="1x"
                    color="#fff"
                  />
                  <Input
                    className={styles.userInput}
                    id="first_name"
                    type="text"
                    value={auth.userProfile.first_name || ""}
                    onChange={this.handleInputChange}
                    placeholder={intl.formatMessage({
                      id: "profile_first_name",
                      defaultMessage: "First Name"
                    })}
                  />
                </div>
              </div>
              <div className={css(styles.inputContainer)}>
                <div className={css(styles.label)}>
                  {intl.formatMessage({
                    id: "profile_last_name",
                    defaultMessage: "Last Name"
                  })}
                </div>
                <div className={css(styles.input)}>
                  <FontAwesomeIcon
                    icon={["far", "user"]}
                    className={css(styles.inputIcon)}
                    size="1x"
                    color="#fff"
                  />
                  <Input
                    className={styles.userInput}
                    id="last_name"
                    type="text"
                    value={auth.userProfile.last_name || ""}
                    onChange={this.handleInputChange}
                    placeholder={intl.formatMessage({
                      id: "profile_last_name",
                      defaultMessage: "Last Name"
                    })}
                  />
                </div>
              </div>
              <div className={css(styles.inputContainer)}>
                <div className={css(styles.label)}>
                  {intl.formatMessage({
                    id: "profile_email",
                    defaultMessage: "Email Address"
                  })}
                </div>
                <div className={css(styles.input)}>
                  <FontAwesomeIcon
                    icon={["far", "envelope"]}
                    className={css(styles.inputIcon)}
                    size="1x"
                    color="#fff"
                  />
                  <Input
                    className={styles.userInput}
                    id="email"
                    type="email"
                    value={auth.userProfile.email || ""}
                    onChange={this.handleInputChange}
                    placeholder={intl.formatMessage({
                      id: "profile_email",
                      defaultMessage: "Email Address"
                    })}
                  />
                </div>
              </div>
              <div className={css(styles.inputContainer, styles.lastInput)}>
                <div className={css(styles.label)}>
                  {intl.formatMessage({
                    id: "profile_phone_number",
                    defaultMessage: "Phone Number"
                  })}
                </div>
                <div className={css(styles.input)}>
                  <FontAwesomeIcon
                    icon={["far", "phone"]}
                    className={css(styles.inputIcon)}
                    size="1x"
                    color="#fff"
                  />
                  <Input
                    className={styles.userInput}
                    id="phone_number"
                    type="tel"
                    value={auth.userProfile.phone_number || ""}
                    onChange={this.handleInputChange}
                    placeholder={intl.formatMessage({
                      id: "profile_phone_number",
                      defaultMessage: "Phone Number"
                    })}
                  />
                </div>
              </div>
              <Button text={"Confirm"} onClick={this.submit} />
            </form>
          </div>
        ) : (
          <div className={css(styles.confirmContainer)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "change_password",
                defaultMessage: "Change your password"
              })}
            </div>
            <form className={css(styles.inputs)}>
              <div className={css(styles.inputContainer, styles.lastInput)}>
                <div className={css(styles.input)}>
                  <FontAwesomeIcon
                    icon={["far", "key"]}
                    className={css(styles.inputIcon)}
                    size="1x"
                    color="#fff"
                  />
                  <Input
                    className={styles.userInput}
                    id="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.changePassword}
                    placeholder={intl.formatMessage({
                      id: "profile_password",
                      defaultMessage: "Password"
                    })}
                  />
                </div>
              </div>
              <Button text={"Change password"} onClick={this.submit} />
            </form>
          </div>
        )}
      </div>
    );
  }
}

var styles = StyleSheet.create({
  authContainer: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  inputs: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    marginBottom: 15
  },
  userInput: {
    padding: "15px 40px",
    // border: 'none',
    // borderBottom: '1px solid #eee',
    background: "#fff",
    width: 300,
    borderWidth: "2px",
    boxSizing: "border-box",
    color: "#828282",
    fontSize: 16,

    "::placeholder": {
      opacity: ".5"
    }
  },
  label: {
    marginBottom: 5
  },
  inputContainer: {
    position: "relative",
    height: "100%",
    marginBottom: 10
  },
  input: {
    position: "relative"
  },
  inputIcon: {
    position: "absolute",
    color: "#828282",
    left: "16px",
    top: "50%",
    transform: "translateY(-50%)"
  },
  lastInput: {
    marginBottom: 20
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  routing: state.routing
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default withRouter(
  injectIntl(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(FirstLogin)
  )
);
