/***
 * Shipping screen for Solestage
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { FormattedMessage, injectIntl } from "react-intl";
import TimeAgo from "javascript-time-ago";

// Components
import Input from "../../components/Input";
import Button from "../../components/Button";
import SpecificOrder from "../orders/SpecificOrder";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Redux
import { MessageActions } from "../../redux/message";
import { ModalActions } from "../../redux/modals";

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// Create relative date/time formatter.
// const timeAgo = new TimeAgo("en-US");

class Shipping extends React.Component {
  constructor(props) {
    super(props);
    let { intl } = this.props;

    this.tabs = [
      {
        id: "not_shipped",
        name: intl.formatMessage({
          id: "not_shipped",
          defaultMessage: "Not Shipped"
        })
      },
      {
        id: "shipped",
        name: intl.formatMessage({
          id: "shipped",
          defaultMessage: "Shipped"
        })
      }
    ];

    this.state = {
      data: [],
      numPages: 1,
      page: 1,
      pageSize: 20,
      hoveredRow: null,
      trackingNumberInput: "",
      activeTab: "not_shipped"
    };
  }

  /**
   * get fulfillments
   * @param  {String} activeTab -- active tab to filter fulfillments by
   */
  getFulfillments = activeTab => {
    let { messageActions, auth } = this.props;
    messageActions.showMessage({ show: true, load: true });
    let status = "awaiting_shipment";
    let shipped = false;
    if (activeTab === "shipped") {
      shipped = true;
    }
    return fetch(
      API.FULFILLMENT({
        page: this.state.page,
        pageSize: this.state.pageSize,
        status: shipped ? null : status,
        shipped: shipped,
        from_location: auth.userProfile.location.id
      }),
      API.GET_CONFIG(false)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = res.results.map((fulfillment, index) => {
          return {
            Order: `#${fulfillment.items[0].id}`,
            order_id: fulfillment.items[0].id,
            fulfillment_id: fulfillment.id,
            "Tracking Service": fulfillment.tracking_service,
            "Tracking Number": fulfillment.tracking_number,
            Updated: new Date(fulfillment.updated_at).toLocaleDateString(
              "en-US"
            ),
            status: fulfillment.status,
            shipped: fulfillment.shipped
          };
        });
        messageActions.showMessage({ show: false });
        this.setState({
          data
        });
      });
  };

  /**
   * mark package as shipped
   */
  markAsShipped = () => {
    let { messageActions } = this.props;
    messageActions.showMessage({ show: true, load: true });
    let trackingNumber = this.state.trackingNumberInput;
    let params = {
      trackingNumber: trackingNumber
    };
    return fetch(API.MARK_AS_SHIPPED, API.POST_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = [...this.state.data];
        let shipped_index = 0;
        for (let i = 0; i < this.state.data.length; i++) {
          if (this.state.data["Tracking Number"] === trackingNumber) {
            shipped_index = i;
          }
        }
        data.splice(shipped_index, 1);
        messageActions.showMessage({ show: false });
        messageActions.setMessage("Shipment marked as shipped!");
        messageActions.showMessage({ show: true });
        setTimeout(() => {
          messageActions.showMessage({ show: false });
        }, 1000);
        this.setState({
          data,
          trackingNumberInput: ""
        });
      });
  };

  renderCell = (header, props, index) => {
    switch (header) {
      default:
        return (
          <div
            className={css(
              styles.cellValue,
              header === "Order" && styles.orderNumber
            )}
          >
            {props.row[index] ? props.row[index].value : props.value}
          </div>
        );
    }
  };

  /**
   * When enter is pressed on input focus
   * @param  {EventObject} e -- evetn object when enter is pressed
   */
  onEnterPress = e => {
    if (e.key === "Enter") {
      this.markAsShipped();
    }
  };

  /**
   * on input change
   * @param  {EventObject} e -- event when input changes
   */
  onInputChange = e => {
    this.setState({
      trackingNumberInput: e.target.value
    });
  };

  /**
   * set active tab state
   * @param {String} activeTab -- active tab id to switch to
   */
  setActiveTab(activeTab) {
    this.getFulfillments(activeTab);
    this.setState({
      activeTab
    });
  }

  /**
   * open specifc order modal
   */
  openSpecificOrderModal = orderId => {
    let { modalActions } = this.props;

    modalActions.openSpecificOrderModal(true);
    this.setState({
      selectedOrder: orderId
    });
  };

  render() {
    let { intl } = this.props;
    let columns = [
      "Order",
      "Tracking Number",
      "Tracking Service",
      "Updated"
    ].map((column, index) => ({
      Header: column
        ? column !== "table-id"
          ? column !== "manage"
            ? column
            : null
          : null
        : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      filterable: false,
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => (
        <div
          className={css(
            styles.valueContainer,
            props.row._index === this.state.hoveredRow && styles.hoveredRow
          )}
          onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
          onClick={() => this.openSpecificOrderModal(props.original.order_id)}
        >
          {this.renderCell(props.column.Header, props, index)}
        </div>
      )
    }));

    let tabs = this.tabs.map((tab, index) => {
      return (
        <div
          className={css(
            styles.tab,
            this.state.activeTab === tab.id && styles.activeTab
          )}
          onClick={() => this.setActiveTab(tab.id)}
          key={`tab_${tab.id}_${index}`}
        >
          {tab.name}
        </div>
      );
    });
    return (
      <div className={css(styles.shipping)}>
        <h2 className={css(styles.dashboardTitle)}>
          <FormattedMessage id="shipping_title" defaultMessage="Shipping" />
        </h2>
        <div className={css(styles.tabContainer)}>{tabs}</div>
        <div className={css(styles.inputLine)}>
          <Input
            className={styles.trackingInput}
            placeholder={"Enter or scan tracking number..."}
            onKeyPress={this.onEnterPress}
            onChange={this.onInputChange}
            autoFocus
          />
          <Button
            text={
              <FormattedMessage
                id="mark_as_shipped"
                defaultMessage="Mark Package as Shipped"
              />
            }
            className={styles.submitButton}
            onClick={this.markAsShipped}
          />
        </div>
        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <ReactTable
            className={css(styles.reactTable) + " order-table"}
            data={this.state.data}
            filterable={true}
            pages={this.state.numPages}
            columns={columns}
            footerClassName={css(styles.tableFooter)}
            defaultPageSize={this.state.pageSize}
            page={this.state.page - 1}
            onPageSizeChange={pageSize => {
              this.setState({
                pageSize,
                numPages: Math.ceil(this.state.totalItems / pageSize)
              });
            }}
            onPageChange={pageIndex => {
              this.setState({
                page: pageIndex + 1
              });
            }}
            minRows={0}
            onFetchData={(state, instance) => {
              this.getFulfillments(this.state.activeTab);
            }}
            nextText={intl.formatMessage({
              id: "next",
              defaultMessage: "Next"
            })}
            previousText={intl.formatMessage({
              id: "previous",
              defaultMessage: "Previous"
            })}
            noDataText={intl.formatMessage({
              id: "no_items_found",
              defaultMessage: "No items found"
            })}
            manual
          />
        </div>
        <SpecificOrder orderId={this.state.selectedOrder} />
      </div>
    );
  }
}

var styles = StyleSheet.create({
  shipping: {
    textAlign: "center",
    padding: 20,
    background: "rgb(249, 249, 249)",
    height: "100%"
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
  },
  table: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
    color: "#212b36"
  },
  orderNumber: {
    color: colors.LIGHT_BLUE(1)
  },
  hoveredRow: {
    background: "#eee"
  },
  link: {
    textDecoration: "none"
  },
  unfulfilled: {
    background: "#ffea8a",
    padding: "8px 8px",
    borderRadius: 24
  },
  fulfilled: {
    padding: "8px 8px",
    borderRadius: 24,
    background: "#dfe3e8"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  inputLine: {
    display: "flex",
    padding: "20px 200px"
  },
  submitButton: {
    width: 350,
    marginLeft: 20
  },
  trackingInput: {
    background: "#fff"
  },
  tabContainer: {
    display: "flex",
    marginBottom: 10,
    justifyContent: "center"
  },
  tab: {
    marginLeft: 7,
    marginRight: 7,
    opacity: ".3",
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  messageActions: bindActionCreators(MessageActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Shipping));
