/**
 * Redux reducer index file
 * @patr -- patrick@quantfive.org
 */

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import localizationReducer from "./localization";
import inventoryReducer from "./inventory";
import modalReducer from "./modals";
import authReducer from "./auth";
import messageReducer from "./message";
import joyrideReducer from "./joyride";

export default combineReducers({
  routing: routerReducer,
  localization: localizationReducer,
  inventory: inventoryReducer,
  modals: modalReducer,
  auth: authReducer,
  message: messageReducer,
  joyride: joyrideReducer
});
