import React, { Component } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
// import { bindActionCreators } from 'redux';
import numeral from "numeral";
import DefaultTooltipContent from "recharts/lib/component/DefaultTooltipContent";
import Dropdown from "react-dropdown";
import { DateRangePicker } from "react-dates";
import isAfterDay from "react-dates/lib/utils/isAfterDay";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import ProductCard from "../../components/sales/ProductCard";
import ChartCard from "../../components/sales/ChartCard";
import Button from "../../components/Button";
import SalesDataCard from "../../components/sales/SalesDataCard";
import SoldInventoryList from "../../components/sales/SoldInventoryList";
import ReturnedInventoryList from "../../components/sales/ReturnedInventoryList";
import Locations from "../../components/inventory/Dropdown";

// Stylesheets
import "./stylesheets/legend.css";
import "react-dropdown/style.css";
import "react-dates/lib/css/_datepicker.css";
import "./stylesheets/react_dates.css";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import colors from "../../config/colors";
import { MessageActions } from "../../redux/message";
import { InventoryActions } from "../../redux/inventory";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    let { locationId, intl } = this.props;

    this.dropdownOptions = [
      {
        value: "today",
        label: intl.formatMessage({ id: "today", defaultMessage: "Today" })
      },
      {
        value: "this_week",
        label: intl.formatMessage({
          id: "this_week",
          defaultMessage: "This Week"
        })
      },
      {
        value: "1_month",
        label: intl.formatMessage({
          id: "1_month",
          defaultMessage: "This Month"
        })
      },
      {
        value: "3_month",
        label: intl.formatMessage({
          id: "3_month",
          defaultMessage: "Last 3 Months"
        })
      },
      {
        value: "6_month",
        label: intl.formatMessage({
          id: "6_month",
          defaultMessage: "Last 6 Months"
        })
      }
    ];
    this.state = {
      graph: [],
      popular_items: [],
      this_week_sales: 0,
      last_week_sales: 0,
      dropdownValue: {
        value: "today",
        label: intl.formatMessage({ id: "today", defaultMessage: "Today" })
      },
      startDate: null,
      endDate: null,
      focusedInput: null,
      message: null,
      salesData: {},
      inventory_sold: [],
      returnsData: {},
      inventory_returned: [],
      fetchingData: false,
      location: locationId,
      allowedUsers: false
    };
  }

  /**
   * Format chart labels on y axis
   * @param  {Integer} tick -- default label
   * @return {String}      -- label returned after formatting
   */
  tickFormatter = tick => {
    return numeral(tick).format("$0,0a");
  };

  customTooltip = props => {
    if (props.payload && props.payload.length > 0) {
      for (let i = 0; i < props.payload.length; i++) {
        let dataKey = props.payload[i].dataKey;
        props.payload[i].value = numeral(
          props.payload[i].payload[dataKey]
        ).format("$0,0");
      }
      const newPayload = [...props.payload];
      return <DefaultTooltipContent {...props} payload={newPayload} />;
    } else {
      return <DefaultTooltipContent {...props} />;
    }
  };

  /**
   * get data from backend for the sales report
   * @param {String} option -- value from dropdown selector
   */
  getData = option => {
    let { showMessage, setMessage, intl } = this.props;
    showMessage({ show: true, load: true });
    if (option === "today") {
      let today = moment().format("YYYY-MM-DD");
      this.setState({
        fetchingData: true
      });

      let startDate = null;
      let endDate = null;
      if (this.state.startDate) {
        startDate = this.state.startDate.format("YYYY-MM-DD");
      }

      if (this.state.endDate) {
        endDate = this.state.endDate.format("YYYY-MM-DD");
      }

      if (endDate && endDate === startDate) {
        endDate = moment(endDate).format("YYYY-MM-DD");
      }
      let location = this.state.location;
      if (parseInt(location, 10) === 0) {
        location = "";
      }

      return fetch(
        API.DAILY_SALES_REPORT +
          `?location=${location}&sold_at__date__gte=${startDate ||
            today}&sold_at__date__lte=${endDate || today}&sold=True`,
        API.GET_CONFIG()
      )
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          showMessage({ show: false });
          this.setState({
            fetchingData: false,
            ...res
          });
        })
        .catch(error => {
          showMessage({ show: false });
          setMessage(
            intl.formatMessage({
              id: "something_wrong",
              defaultMessage: "Something went wrong!"
            })
          );
          showMessage({ show: true, error: true });
        });
    } else {
      return fetch(API.DASHBOARD(option, this.state.location), API.GET_CONFIG())
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          showMessage({ show: false });
          this.setState({
            ...res
          });
        })
        .catch(error => {
          showMessage({ show: false });
          setMessage(
            intl.formatMessage({
              id: "something_wrong",
              defaultMessage: "Something went wrong!"
            })
          );
          showMessage({ show: true, error: true });
        });
    }
  };

  onSelect = e => {
    this.getData(e.value);
    this.setState({
      dropdownValue: e
    });
  };

  isOutsideRange = day => {
    let dayIsBlocked = false;

    if (isAfterDay(day, moment())) {
      dayIsBlocked = true;
    }

    return dayIsBlocked;
  };

  onChangeLocation = e => {
    const location = parseInt(e.target.value);
    this.setState({ location }, () => {
      this.getData(this.state.dropdownValue.value);
      this.props.setLocation(location);
    });
  };

  downloadReport = () => {
    let { intl } = this.props;
    clearTimeout(this.salesReportTimeout);
    if (!this.state.startDate || !this.state.endDate) {
      this.setState({
        message: intl.formatMessage({
          id: "select_date",
          defaultMessage: "Please select a date range before downloading."
        })
      });
      return;
    }

    this.setState({
      fetching: true
    });

    let startDate = this.state.startDate.format("YYYY-MM-DD");
    let endDate = this.state.endDate.format("YYYY-MM-DD");
    if (endDate === startDate) {
      endDate = this.state.endDate.add(1, "days").format("YYYY-MM-DD");
    }

    this.salesReportTimeout = setTimeout(() => {
      let location = this.state.location;
      if (location === "0") {
        location = "";
      }
      fetch(
        API.SALES_REPORT +
          `?location=${location}&sold_at__date__gte=${startDate}&sold_at__date__lte=${endDate}&sold=True`,
        API.GET_CONFIG()
      )
        .then(Helpers.checkStatus)
        .then(res => {
          return res.blob();
        })
        .then(res => {
          this.setState({
            message: null,
            fetching: false
          });
          let link = document.createElementNS(
            "http://www.w3.org/1999/xhtml",
            "a"
          );
          link.href = URL.createObjectURL(res);
          link.download = `${startDate}_to_${endDate}_sales_report.csv`;
          link.click();
        });
    }, 300);
  };

  componentDidMount() {
    let { auth } = this.props;
    this.getData(this.state.dropdownValue.value);

    let allowUsers = ["admin", "accountant"].includes(
      auth.userProfile.role.toLowerCase()
    );

    this.setState({
      allowedUsers: allowUsers
    });
  }

  salesReportDatesChange = ({ startDate, endDate }) => {
    this.setState(
      {
        startDate,
        endDate
      },
      () => {
        if (this.state.startDate && this.state.endDate) {
          this.getData("today");
        }
      }
    );
  };

  render() {
    let { intl } = this.props;
    let products = this.state.popular_items.map((product, index) => {
      return (
        <div className={css(styles.product)}>
          <ProductCard item={product.item} count={product.count} />
        </div>
      );
    });

    return (
      <div className={css(styles.dashboardPage)}>
        <div className={css(styles.reportContainer)}>
          <h1 className={css(styles.title)}>
            {intl.formatMessage({
              id: "sales_report",
              defaultMessage: "Sales Report"
            })}
          </h1>
          {this.state.allowedUsers && (
            <h4 className={css(styles.subTitle)}>
              {intl.formatMessage({
                id: "inventory_location",
                defaultMessage: "Location"
              })}
            </h4>
          )}
          <Locations
            className={styles.locations}
            onChange={this.onChangeLocation}
            default={this.state.location}
          />
          {this.state.message && (
            <div className={css(styles.message)}>{this.state.message}</div>
          )}
          <div className={css(styles.datePickerContainer)}>
            <DateRangePicker
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={this.salesReportDatesChange} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              showClearDates={true}
              isOutsideRange={this.isOutsideRange}
              minimumNights={0}
            />
            <Button
              className={styles.downloadButton}
              text={
                this.state.fetching ? (
                  <FontAwesomeIcon icon={["far", "spinner-third"]} spin />
                ) : (
                  intl.formatMessage({
                    id: "download_report",
                    defaultMessage: "Download Report"
                  })
                )
              }
              onClick={this.downloadReport}
            />
          </div>
        </div>
        <Dropdown
          className={css(styles.dropdown)}
          options={this.dropdownOptions}
          onChange={this.onSelect}
          value={this.state.dropdownValue}
        />
        {this.state.dropdownValue.value === "today" ? (
          <div
            className={css(
              styles.dashboardContainer,
              styles.dashboardContainerToday
            )}
          >
            <div className={css(styles.section, styles.sectionToday)}>
              <h1 className={css(styles.title)}>
                {`${intl.formatMessage({
                  id: "sales_for",
                  defaultMessage: "Sales for"
                })} ${
                  this.state.startDate && this.state.endDate
                    ? "Custom Period"
                    : this.state.dropdownValue.label
                }`}
              </h1>
              <SalesDataCard
                salesData={this.state.salesData}
                returnsData={this.state.returnsData}
              />
            </div>
            <div className={css(styles.section, styles.sectionToday)}>
              <h1 className={css(styles.title)}>
                {intl.formatMessage({
                  id: "inventory_sold",
                  defaultMessage: "Inventory Sold"
                })}
              </h1>
              {this.state.inventory_sold.length > 0 ? (
                <div className={css(styles.topProducts)}>
                  <SoldInventoryList items={this.state.inventory_sold} />
                </div>
              ) : this.state.fetchingData ? null : (
                <div className={css(styles.topProducts)}>
                  {intl.formatMessage({
                    id: "no_inventory_sold",
                    defaultMessage: "No Inventory Sold Yet"
                  })}
                </div>
              )}
            </div>
            <div className={css(styles.section, styles.sectionToday)}>
              <h1 className={css(styles.title)}>
                {intl.formatMessage({
                  id: "inventory_returned",
                  defaultMessage: "Inventory Returned"
                })}
              </h1>
              {this.state.inventory_returned.length > 0 ? (
                <div className={css(styles.topProducts)}>
                  <ReturnedInventoryList
                    items={this.state.inventory_returned}
                  />
                </div>
              ) : this.state.fetchingData ? null : (
                <div className={css(styles.topProducts)}>
                  {intl.formatMessage({
                    id: "no_inventory_returned",
                    defaultMessage: "No Inventory Returned Yet"
                  })}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={css(styles.dashboardContainer)}>
            <div className={css(styles.section)}>
              <h1 className={css(styles.title)}>
                {`${intl.formatMessage({
                  id: "sales_for",
                  defaultMessage: "Sales for"
                })} ${this.state.dropdownValue.label}`}
              </h1>
              <ChartCard
                totalTitle={intl.formatMessage({
                  id: "total_revenue",
                  defaultMessage: "Total Revenue"
                })}
                total={numeral(this.state.this_week_sales).format("$0,0.00")}
                graph={this.state.graph}
                tickFormatter={this.tickFormatter}
                customTooltip={this.customTooltip}
                current_name={intl.formatMessage({
                  id: "current_revenue",
                  defaultMessage: "Current Revenue"
                })}
                prev_name={intl.formatMessage({
                  id: "prev_revenue",
                  defaultMessage: "Previous Revenue"
                })}
                current_dataKey={"current_revenue"}
                prev_dataKey={"prev_revenue"}
              />
              <ChartCard
                totalTitle={intl.formatMessage({
                  id: "total_sold",
                  defaultMessage: "Total Sold"
                })}
                total={this.state.this_week_sold}
                graph={this.state.graph}
                current_name={intl.formatMessage({
                  id: "current_sold",
                  defaultMessage: "Current Sold"
                })}
                prev_name={intl.formatMessage({
                  id: "prev_sold",
                  defaultMessage: "Previous Sold"
                })}
                current_dataKey={"current_sold"}
                prev_dataKey={"prev_sold"}
              />
            </div>
            <div className={css(styles.section)}>
              <h1 className={css(styles.title)}>
                {`${intl.formatMessage({
                  id: "top_products",
                  defaultMessage: "Top Products for"
                })} ${this.state.dropdownValue.label}`}
              </h1>
              {products.length > 0 ? (
                <div className={css(styles.topProducts)}>{products}</div>
              ) : (
                <div className={css(styles.topProducts)}>
                  {intl.formatMessage({
                    id: "no_products_sold",
                    defaultMessage: "No Products Sold"
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  dashboardPage: {
    width: "100%",
    minHeight: "100vh",
    paddingTop: 30,
    paddingBottom: 30,
    boxSizing: "border-box"
  },
  dashboardContainer: {
    "@media only screen and (min-width: 1024px)": {
      display: "flex",
      justifyContent: "center"
    }
  },
  section: {
    paddingRight: 30,
    paddingLeft: 30,
    width: "70%"
  },
  sectionToday: {
    width: "100%"
  },
  topProducts: {
    display: "flex",
    flexWrap: "wrap"
  },
  product: {
    margin: 10
  },
  chartContainer: {
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    paddingTop: 20,
    paddingBottom: 30,
    marginBottom: 20
  },
  chartTitle: {
    textAlign: "center",
    marginTop: 0
  },
  legend: {
    position: "relative"
  },
  dropdown: {
    width: 200,
    marginLeft: 30
  },
  reportContainer: {
    borderBottom: "1px solid #ddd",
    margin: 30,
    marginTop: 0,
    paddingBottom: 20
  },
  datePickerContainer: {
    display: "flex"
  },
  downloadButton: {
    width: 200,
    height: 48,
    marginLeft: 20,
    fontSize: 18,
    padding: 10
  },
  message: {
    marginBottom: 10,
    color: colors.RED(0.7)
  },
  dashboardContainerToday: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    "@media only screen and (min-width: 1024px)": {
      justifyContent: "flex-start",
      alignItems: "flex-start"
    }
  },
  locations: {
    width: 314,
    marginBottom: 10
  },
  subTitle: {
    marginTop: 5,
    marginBottom: 5
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = {
  showMessage: MessageActions.showMessage,
  setMessage: MessageActions.setMessage,
  setLocation: InventoryActions.setLocation
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Dashboard));
