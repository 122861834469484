/***
 * Paypal oauth page to save paypal info
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

// Redux
import { AuthActions } from "../../redux/auth";

class StripeOnboardingReturn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linked: false,
      error: false
    };
  }

  render() {
    let { intl } = this.props;

    return (
      <div className={css(styles.paypalConnected)}>
        {intl.formatMessage({
          id: "paypal_connect",
          defaultMessage: "Close this browser to go back to the app."
        })}
      </div>
    );
  }
}
var styles = StyleSheet.create({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
    boxSizing: "border-box"
  },
  paypalConnected: {
    fontSize: 24,
    letterSpacing: 0.7,
    textAlign: "center"
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StripeOnboardingReturn)
);
