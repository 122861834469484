/***
 * The size filter dropdown component
 * @rlieu002
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

class SizeDropdown extends React.Component {
  // getSizeArray = () => {
  //   const sizes = [];
  //   for (let i = 4; i <= 19; i += 0.5) {
  //     sizes.push(i);
  //   }
  //   return [...sizes, "S", "M", "L", "XL"];
  // };

  renderSizeOptions = () => {
    const sizes = this.props.sizes;

    return sizes
      .sort((a, b) => parseFloat(a) - parseFloat(b))
      .map((size, index) => {
        return (
          size && (
            <option
              value={size}
              key={index}
              selected={this.props.default && this.props.default === size}
            >
              {size}
            </option>
          )
        );
      });
  };

  render() {
    return (
      <select
        className={css(styles.input, this.props.className)}
        onChange={this.props.onChange}
        readOnly={this.props.readOnly}
      >
        <option value="All">All</option>
        {this.renderSizeOptions()}
      </select>
    );
  }
}

var styles = StyleSheet.create({
  input: {
    width: "100%",
    maxWidth: 320,
    height: 38,
    borderRadius: 4,
    outline: "none",
    fontSize: 16,
    border: "1px solid #ddd",
    background: "#fff",
    cursor: "pointer"
  }
});

const mapStateToProps = state => ({
  // auth: state.auth,
  inventory: state.inventory
});

const mapDispatchToProps = dispatch => ({
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SizeDropdown)
);
