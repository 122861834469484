import React, { Component } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";

// Redux
import { AuthActions } from "../../redux/auth";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Assets
import Logo from "./assets/logo.png";

// Components
import LanguageChooser from "../language/LanguageChooser";

class Sidebar extends Component {
  constructor(props) {
    super(props);

    let { intl, authActions, auth } = this.props;

    this.tabs = [
      {
        id: "dashboard",
        to: "/dashboard",
        name: intl.formatMessage({
          id: "dashboard_tab",
          defaultMessage: "Home"
        }),
        icon: ["far", "home"]
      },
      {
        id: "consignmentOrder",
        to: "/consignment/order",
        name: intl.formatMessage({
          id: "consignment_order_tab",
          defaultMessage: "Consignment Order"
        }),
        icon: ["far", "tags"]
      },
      {
        id: "consignmentInventory",
        to: "/inventory/inventory",
        name: intl.formatMessage({
          id: "consignment_inventory_tab",
          defaultMessage: "Consignment Inventory"
        }),
        icon: ["far", "barcode-read"]
      },
      {
        id: "sku",
        to: "/sku",
        name: intl.formatMessage({ id: "sku_tab", defaultMessage: "Product" }),
        icon: ["fal", "table"]
      },
      {
        id: "orders",
        to: "/orders/unfulfilled",
        name: intl.formatMessage({
          id: "orders_tab",
          defaultMessage: "Orders"
        }),
        icon: ["far", "chart-line"]
      },
      {
        id: "shipping",
        to: "/shipping",
        name: intl.formatMessage({
          id: "shipping_tab",
          defaultMessage: "Shipping"
        }),
        icon: ["far", "truck"]
      },
      {
        id: "payouts",
        to: "/payouts",
        name: intl.formatMessage({
          id: "payouts_tab",
          defaultMessage: "Payouts"
        }),
        icon: ["far", "money-bill-wave"]
      },
      {
        id: "consigners",
        to: "/consigners",
        name: intl.formatMessage({
          id: "consigners_tab",
          defaultMessage: "Consigners"
        }),
        icon: ["far", "users"]
      },
      {
        id: "price",
        to: "/price",
        name: intl.formatMessage({
          id: "price_tab",
          defaultMessage: "Price Change Requests"
        }),
        icon: ["far", "tags"]
      },
      {
        id: "help",
        to: "/tutorials",
        name: intl.formatMessage({ id: "help_tab", defaultMessage: "Help" }),
        icon: ["far", "question-circle"]
      },

      {
        id: "reconciliation",
        to: "/reconciliation",
        name: intl.formatMessage({
          id: "reconciliation_tab",
          defaultMessage: "Reconciliation"
        }),
        icon: ["far", "check"]
      },
      {
        id: "users",
        to: "/users",
        name: intl.formatMessage({ id: "users_tab", defaultMessage: "Users" }),
        icon: ["far", "users"]
      },
      {
        id: "signout",
        onClick: authActions.signout,
        name: intl.formatMessage({ id: "signout", defaultMessage: "Sign Out" }),
        icon: ["far", "sign-out"]
      }
    ];

    if (
      auth.userProfile.role &&
      ["admin", "accountant", "warehouse manager"].includes(
        auth.userProfile.role.toLowerCase()
      )
    ) {
      this.tabs.splice(-2, -1, {
        id: "auditTrail",
        to: "/audit",
        name: intl.formatMessage({
          id: "audit_trail",
          defaultMessage: "Audit Trail"
        }),
        icon: ["far", "history"]
      });
    } else if (
      auth.userProfile.role &&
      auth.userProfile.role.toLowerCase() === "retail floor"
    ) {
      this.tabs = this.tabs.filter(tab =>
        ["consignmentInventory", "signout"].includes(tab.id)
      );
    }

    this.state = {
      activeTab: this.tabs[0].id,
      orderCounts: {}
    };
  }

  setActiveTab = tabId => {
    this.setState({
      actibeTab: tabId
    });
  };

  onTabClick = tab => {
    this.setState({
      activeTab: tab.id
    });

    tab.onClick();
  };

  componentDidMount = () => {
    let { routing } = this.props;
    for (let i = 0; i < this.tabs.length; i++) {
      if (routing.location.pathname === this.tabs[i].to) {
        this.setState({
          activeTab: this.tabs[i].id
        });
      } else if (
        routing.location.pathname.includes("/inventory/inventory") ||
        routing.location.pathname.includes("/inventory/products")
      ) {
        this.setState({
          activeTab: "consignmentInventory"
        });
      }
    }

    this.pollOrders();
    this.orderCountInterval = setInterval(this.pollOrders, 15000);
  };

  componentWillUnmount() {
    clearInterval(this.orderCountInterval);
  }

  pollOrders = () => {
    let { auth } = this.props;
    if (!auth.userProfile.location) {
      return;
    }
    return fetch(
      API.ORDER_PAGE_COUNTS({ location: auth.userProfile.location.id }),
      API.GET_CONFIG(false)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          orderCounts: res
        });
      });
  };

  render() {
    let { auth } = this.props;
    let tabs = this.tabs.map((tab, index) => {
      if (tab.id === "payouts") {
        if (
          auth.userProfile.role &&
          !["admin", "accountant", "store supermanager"].includes(
            auth.userProfile.role.toLowerCase()
          )
        ) {
          return null;
        }
      }

      if (tab.id === "consigners") {
        if (
          auth.userProfile.role &&
          ![
            "admin",
            "accountant",
            "warehouse manager",
            "store manager",
            "store supermanager"
          ].includes(auth.userProfile.role.toLowerCase())
        ) {
          return null;
        }
      }

      if (tab.id === "users") {
        if (!["Admin", "Warehouse Manager"].includes(auth.userProfile.role)) {
          return null;
        }
      }

      if (tab.id === "consignmentBarcodes") {
        if (!["Admin"].includes(auth.userProfile.role)) {
          return (
            <Link
              key={`sidebar_tab_${tab.id}`}
              to={"/consignment/inventory"}
              className={css(styles.link)}
              onClick={() => this.setState({ activeTab: tab.id })}
            >
              <div
                className={css(
                  styles.tab,
                  tab.id === this.state.activeTab && styles.activeTab
                )}
              >
                <FontAwesomeIcon
                  icon={tab.icon}
                  className={css(styles.tabIcon)}
                />
                <FormattedMessage
                  id="consignment_barcodes"
                  defaultMessage={`Consignment Barcodes`}
                />
              </div>
            </Link>
          );
        }
      }

      if (
        auth.userProfile.role &&
        auth.userProfile.role.toLowerCase() === "retail floor"
      ) {
        if (!["consignmentInventory", "signout"].includes(tab.id)) {
          return null;
        }
      }

      if (tab.onClick) {
        return (
          <div
            key={`sidebar_tab_${tab.id}`}
            className={css(
              styles.tab,
              tab.id === this.state.activeTab && styles.activeTab
            )}
            onClick={() => this.onTabClick(tab)}
          >
            <FontAwesomeIcon icon={tab.icon} className={css(styles.tabIcon)} />
            {tab.name}
          </div>
        );
      }
      return (
        <Link
          key={`sidebar_tab_${tab.id}`}
          to={tab.to}
          className={css(styles.link)}
          onClick={() => this.setState({ activeTab: tab.id })}
        >
          <div
            className={css(
              styles.tab,
              tab.id === this.state.activeTab && styles.activeTab
            )}
          >
            <FontAwesomeIcon icon={tab.icon} className={css(styles.tabIcon)} />
            {tab.name}
            {tab.id === "orders" &&
            this.state.orderCounts.unfulfilled &&
            this.state.orderCounts.unfulfilled > 0 ? (
              <span className={css(styles.notificationBubble)}>
                {this.state.orderCounts.unfulfilled}
              </span>
            ) : null}
          </div>
        </Link>
      );
    });
    return (
      <div className={css(styles.sidebarContainer)}>
        <div className={css(styles.logoContainer)}>
          <img className={css(styles.logo)} src={Logo} alt={"Logo"} />
          {/* <div className={css(styles.logoText)}>Solestage</div>
          <div className={css(styles.logoTagline)}>
            <FormattedMessage id="management" defaultMessage={`Management`} />
          </div> */}
        </div>

        <div className={css(styles.tabSection)}>
          <div className={css(styles.language)}>
            <LanguageChooser />
          </div>
          {tabs}
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  sidebarContainer: {
    width: 200,
    height: "100%",
    backgroundColor: "black",
    position: "fixed",
    overflow: "auto",
    padding: 8,
    boxSizing: "border-box"
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16
  },
  logo: {
    objectFit: "cover",
    width: 160
  },
  tabSection: {
    marginTop: 16
  },
  logoText: {
    color: "white",
    fontFamily: "'Archivo Black', sans-serif",
    textAlign: "center",
    letterSpacing: 1,
    marginTop: 8,
    lineHeight: 1.4,
    fontSize: 18
  },
  logoTagline: {
    fontFamily: "font-family: 'Raleway', sans-serif",
    color: colors.GREY(1),
    letterSpacing: 1
  },
  language: {
    width: "100%"
  },
  notificationBubble: {
    background: colors.RED(1),
    color: "#fff",
    padding: "5px 8px",
    marginLeft: 16,
    borderRadius: "50%"
  },
  link: {
    textDecoration: "none"
  },
  tab: {
    color: "#fff",
    opacity: ".5",
    margin: "24px 0px",
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 10,
    boxSizing: "border-box",
    width: "100%",
    cursor: "pointer",
    position: "relative"
  },
  activeTab: {
    opacity: "1"
  },
  tabIcon: {
    marginRight: 12
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  routing: state.routing
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Sidebar));
