/***
 * Screen to invite a user and manage all of your users
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTable from "react-table";

// Components
import Button from "../../components/Button";
import Input from "../../components/Input";
import ConsignerPayoutsModal from "../../components/modals/ConsignerPayoutsModal";
import { Button as BootstrapButton } from "react-bootstrap";

// Redux
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

// API
import API from "../../config/api";
import Helpers from "../../redux/helpers";

const CONSTANTS = {
  CONSIGNMENT_TYPES: {
    USED_SHOE: "used_shoe_consignment_rate",
    SHOE: "shoe_consignment_rate",
    NON_SHOE: "non_shoe_consignment_rate"
  },
  SHOE: "Shoe",
  NON_SHOE: "Non Shoe",
  USED_SHOE: "Used Shoe"
};

class Consigners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      page: 1,
      consigners: [],
      total_consigners: null,
      fetchingData: false,
      selectedCell: { id: null, index: null, type: null },
      pageSize: 20,
      numPages: 0,
      rate: null,
      submitting: false,
      exportingPayouts: false
    };
  }

  exportPayouts = async () => {
    const { auth } = this.props;
    const url = API.REQUEST_PAYOUT_2({
      rest: { route: "download_consignment_payables_report" },
      querystring: { by_consigner: true }
    });

    fetch(url, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        alert(
          `Requested payouts report will be sent to ${auth.userProfile.email}`
        );
      })
      .catch(async error => {
        const { detail } = await Helpers.parseJSON(error.response);
        alert(detail);
        alert("There was an error requesting the export");
      })
      .finally(() => {
        this.setState({ exportingPayouts: false });
      });
  };

  searchForConsigner = search => {
    this.setState(
      {
        page: 1,
        data: [],
        search: search,
        consigners: []
      },
      () => {
        this.onTableFetchData(this.state);
      }
    );
  };

  onChangeSearch = e => {
    this.setState({
      search: e.target.value
    });
  };

  onRateChange = e => {
    this.setState({ rate: e.target.value }, () => {});
  };

  onRateSave = (e, consignerIndex, rateType) => {
    const { intl, messageActions } = this.props;
    const { selectedCell, data, rate } = this.state;

    if (isNaN(rate)) {
      messageActions.setMessage("Invalid rate");
      messageActions.showMessage({ show: true, load: false, error: true });
      return;
    }

    let params = {
      shoe_consignment_rate: rate
    };

    if (rateType === CONSTANTS.CONSIGNMENT_TYPES.NON_SHOE) {
      params = {
        non_shoe_consignment_rate: rate
      };
    } else if (rateType === CONSTANTS.CONSIGNMENT_TYPES.USED_SHOE) {
      params = {
        used_shoe_consignment_rate: rate
      };
    }

    let consigner_id = this.state.consigners[consignerIndex].id;
    this.setState({ submitting: true });

    fetch(
      API.USER_ACTIONS + `${consigner_id}/update_consignment_rate/`,
      API.POST_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        data[selectedCell.index][selectedCell.id] = `${Number(rate).toFixed(
          2
        )}%`;
      })
      .catch(e => {
        console.log(e);
        messageActions.setMessage(
          intl.formatMessage({
            id: "something_went_wrong",
            defaultMessage: "Something went wrong!"
          })
        );
        messageActions.showMessage({ show: true, load: false, error: true });
      })
      .finally(() => {
        this.setState({
          submitting: false,
          data,
          selectedCell: { id: null, index: null }
        });
      });
  };

  saveButtonLabel = rateType => {
    const { intl } = this.props;
    if (!this.state.submiting) {
      return intl.formatMessage({
        id: "save",
        defaultMessage: "Save"
      });
    } else {
      return (
        <FontAwesomeIcon
          icon={["far", "spinner-third"]}
          className={css(styles.spinner)}
          spin
        />
      );
    }
  };

  isSubmitting = rateType => {
    const submitting = `${rateType}_submitting`;
    return this.state[submitting];
  };

  getColumnWidth = column => {
    if (column === "#") {
      return 50;
    } else if (column === "Email Address") {
      return 250;
    } else {
      return 200;
    }
  };

  getColumns = () => {
    let columns = [
      "#",
      "Name",
      "Email Address",
      "Phone Number",
      "Shoe",
      "Non Shoe",
      "Used Shoe"
    ].map((column, index) => ({
      Header: column,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(styles.cellHeader),
      filterable: false,
      minWidth: this.getColumnWidth(column),
      Cell: props => (
        <div
          className={css(
            styles.valueContainer,
            props.row._index === this.state.hoveredRow && styles.hoveredRow
          )}
          onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
        >
          <div className={css(styles.cellValue)}>{props.value}</div>
        </div>
      )
    }));

    columns.push({
      expander: false,
      Header: "",
      style: {
        textOverflow: "ellipsis !important",
        display: "flex",
        alignItems: "left",
        justifyContent: "left",
        fontSize: 25,
        padding: "0",
        userSelect: "none"
      }
    });
    return columns;
  };

  getOrderingParams = (sorted, queryParams) => {
    let orderField,
      prefix = null;
    const baseFields = {
      Name: "name",
      "Email Address": "email",
      "Phone Number": "phone_number",
      Shoe: "shoe_consignment_rate",
      "Non Shoe": "non_shoe_consignment_rate",
      "Used Shoe": "used_shoe_consignment_rate"
    };

    if (sorted && sorted[0]) {
      prefix = sorted[0].desc ? "" : "-";
      orderField = baseFields[sorted[0].id];
    }

    if (orderField) {
      queryParams = queryParams + `&ordering=` + prefix + orderField;
    }
    return queryParams;
  };

  onTableFetchData = state => {
    let queryParams =
      `${API.USER_ACTIONS}?` +
      `search=${this.state.search}` +
      `&page=${this.state.page}` +
      `&size=${this.state.pageSize}`;

    queryParams = this.getOrderingParams(state.sorted, queryParams);

    const { messageActions } = this.props;
    let consigners = null;

    messageActions.showMessage({ show: true, load: true });

    fetch(queryParams, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        consigners = res.results;
        this.setState(
          { numPages: Math.ceil(res.count / this.state.pageSize) },
          () => {}
        );
      })
      .catch(e => {
        console.log(e);
        consigners = [];
      })
      .finally(() => {
        this.formatConsignerData(consigners);
      });
  };

  formatConsignerData = consigners => {
    const { messageActions, intl } = this.props;

    if (!consigners) {
      messageActions.setMessage(
        intl.formatMessage({
          id: "something_went_wrong",
          defaultMessage: "Something went wrong!"
        })
      );
      messageActions.showMessage({ show: true, load: false });
      console.log("No conisgners returned.");
      return {};
    }

    const data = consigners.map((consigner, index) => {
      return {
        "#": index,
        Name: `${consigner.first_name} ${consigner.last_name}`,
        "Email Address": consigner.email ? `${consigner.email}` : "-",
        "Phone Number": consigner.phone_number
          ? `${consigner.phone_number}`
          : "-",
        Shoe: `${consigner.shoe_consignment_rate}%`,
        "Non Shoe": `${consigner.non_shoe_consignment_rate}%`,
        "Used Shoe": `${consigner.used_shoe_consignment_rate}%`
      };
    });

    this.setState(
      {
        data: data,
        consigners,
        selectedCell: { id: null, index: null },
        total_consigners: data.length,
        loading: false
      },
      () => messageActions.showMessage({ show: false, load: false })
    );
  };

  onCellClick = (state, rowInfo, column, instance) => {
    let { messageActions, modalActions } = this.props;

    return {
      onClick: e => {
        if (
          column.id === CONSTANTS.SHOE ||
          column.id === CONSTANTS.NON_SHOE ||
          column.id === CONSTANTS.USED_SHOE
        ) {
          if (
            this.state.selectedCell.index !== rowInfo.index ||
            this.state.selectedCell.id !== column.id
          ) {
            const updatedState = this.selectConsignmentRate(column.id, rowInfo);

            this.setState({ ...updatedState }, () => {});
          }
        } else {
          const consigner = this.state.consigners[rowInfo.index];
          if (!consigner) {
            messageActions.setMessage("Consigner not load correctly");
            messageActions.showMessage({ show: true, load: false });
          } else {
            modalActions.openConsignerPayoutsModal(true, consigner);
          }
        }
      }
    };
  };

  selectConsignmentRate = (consignmentRateName, rowInfo) => {
    let { messageActions } = this.props;

    const data = this.state.data;
    const consignmentRateParsedName = `${consignmentRateName}`
      .split(" ")
      .join("_")
      .toUpperCase();
    const consignmentRateType =
      CONSTANTS.CONSIGNMENT_TYPES[consignmentRateParsedName];

    if (!consignmentRateType) {
      messageActions.setMessage("Consigner not load correctly");
      messageActions.showMessage({ show: true, load: false });
      return {};
    }

    const htmlElement = (
      <div
        className={css(styles.column, styles.rateEdit)}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Input
          className={styles.rateInput}
          value={null}
          onChange={e => this.onRateChange(e)}
          autoFocus
        />
        <Button
          className={styles.rateButton}
          text={this.saveButtonLabel(consignmentRateType)}
          onClick={e => this.onRateSave(e, rowInfo.index, consignmentRateType)}
          disabled={this.state.submitting}
        />
      </div>
    );

    if (consignmentRateType === CONSTANTS.CONSIGNMENT_TYPES.SHOE) {
      data[rowInfo.index][CONSTANTS.SHOE] = htmlElement;
    } else if (consignmentRateType === CONSTANTS.CONSIGNMENT_TYPES.NON_SHOE) {
      data[rowInfo.index][CONSTANTS.NON_SHOE] = htmlElement;
    } else if (consignmentRateType === CONSTANTS.CONSIGNMENT_TYPES.USED_SHOE) {
      data[rowInfo.index][CONSTANTS.USED_SHOE] = htmlElement;
    }

    // Dont allow two cells to be selected at the same time.
    if (this.state.selectedCell.id != null) {
      const { selectedCell } = this.state;
      const value = this.state.consigners[selectedCell.index][
        consignmentRateType
      ];
      data[selectedCell.index][selectedCell.id] = `${value}%`;
    }

    return {
      selectedCell: {
        id: consignmentRateName,
        index: rowInfo.index
      },
      data
    };
  };

  handlePageSizeChange = pageSize => {
    this.setState(
      {
        page: 1,
        data: [],
        consigners: [],
        pageSize,
        numPages: Math.ceil(this.state.total_consigners / pageSize)
      },
      () => this.onTableFetchData(this.state)
    );
  };

  handlePageChange = pageIndex => {
    this.setState(
      {
        page: pageIndex + 1,
        data: [],
        consigners: []
      },
      () => this.onTableFetchData(this.state)
    );
  };

  render() {
    let { intl } = this.props;
    let columns = this.getColumns();

    return (
      <div className={css(styles.consignersPage)}>
        <div className={css(styles.header)}>
          <div className={css(styles.title)}>
            {intl.formatMessage({
              id: "consigners_list",
              defaultMessage: "Consigners List"
            })}
          </div>
          <div className={css(styles.description)}>
            {intl.formatMessage({
              id: "search_consigners",
              defaultMessage:
                "Search up any consigner by name or by email. You can set specific consigner rates for individuals and manage a consigner's payout."
            })}
          </div>
          <form
            className={css(styles.searchContainer)}
            onSubmit={e => {
              e.preventDefault();
              this.searchForConsigner(this.state.search);
            }}
          >
            <Input
              containerClassName={styles.userSearchInputContainer}
              placeholder={intl.formatMessage({
                id: "search_name_email",
                defaultMessage: "Search by name or email"
              })}
              value={this.state.search}
              onChange={this.onChangeSearch}
            />
            <Button
              className={styles.userSearchButton}
              text={
                this.state.fetchingData ? (
                  <FontAwesomeIcon icon={["far", "spinner-third"]} spin />
                ) : (
                  intl.formatMessage({
                    id: "search",
                    defaultMessage: "Search"
                  })
                )
              }
            />
          </form>
        </div>

        <div className={css(styles.exportContainer)}>
          {["admin", "accountant"].includes(
            this.props.auth.userProfile.role.toLowerCase()
          ) && (
            <BootstrapButton
              size="sm"
              variant="outline-secondary"
              onClick={this.exportPayouts}
            >
              {(
                <FontAwesomeIcon
                  icon={["fas", "file-export"]}
                  className={css(styles.fileIcon)}
                />
              ) &&
                (this.state.exportingPayouts ? (
                  <FontAwesomeIcon
                    icon={["far", "spinner-third"]}
                    className={css(styles.fileIcon)}
                    spin
                  />
                ) : (
                  <FormattedMessage
                    id="export_payouts"
                    defaultMessage="Export Consigner Balances"
                  />
                ))}
            </BootstrapButton>
          )}
        </div>

        <br />
        <hr />
        <br />
        <br />

        <div className={css(styles.userListContainer)}>
          <ReactTable
            className={
              css(styles.reactTable) +
              " " +
              css(styles.consignersTable) +
              " consigners-table -striped -highlight"
            }
            expanded={false}
            footerClassName={css(styles.tableFooter)}
            minRows={0}
            columns={columns}
            data={this.state.data}
            page={this.state.page - 1}
            pages={this.state.numPages}
            pageSize={this.state.pageSize}
            defaultPageSize={this.state.pageSize}
            onPageSizeChange={this.handlePageSizeChange}
            onPageChange={this.handlePageChange}
            nextText={intl.formatMessage({
              id: "next",
              defaultMessage: "Next"
            })}
            previousText={intl.formatMessage({
              id: "previous",
              defaultMessage: "Previous"
            })}
            noDataText={intl.formatMessage({
              id: "no_items_found",
              defaultMessage: "No items found"
            })}
            onFetchData={this.onTableFetchData}
            getTdProps={this.onCellClick}
            manual
          />

          <br />
          <br />
          <br />
        </div>
        <ConsignerPayoutsModal payout={false} />
      </div>
    );
  }
}

var styles = StyleSheet.create({
  consignersPage: {
    padding: 30
  },
  searchContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  userSearchInputContainer: {
    width: 500,
    marginRight: 10
  },
  userSearchInput: {
    width: 500,
    marginRight: 10
  },
  userSearchButton: {
    width: 200,
    fontSize: 20
  },
  exportContainer: {
    display: "flex",
    justifyContent: "center"
  },
  export: {
    margin: "0 1em",
    width: 300,
    fontSize: 20
  },
  title: {
    fontSize: 40,
    textAlign: "center",
    letterSpacing: 1,
    marginBottom: 10
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20
  },
  description: {
    fontSize: 18,
    textAlign: "center",
    width: 500,
    overflow: "wrap",
    opacity: 0.7,
    marginBottom: 20
  },
  userListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  user: {
    display: "flex",
    fontSize: 18,
    cursor: "pointer",

    ":hover": {
      background: "#ddd"
    }
  },
  column: {
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: "center",
    width: 180,
    height: 40,
    paddingTop: 20,
    paddingBottom: 20,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  userTitle: {
    fontSize: 20,
    fontWeight: "bold",
    display: "flex",
    paddingLeft: 40,
    paddingBottom: 10,
    borderBottom: "1px solid"
  },
  numbering: {
    width: 30,
    height: 40,
    paddingLeft: 10,
    paddingTop: 20,
    paddingBottom: 20
  },
  rateEdit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: "0!important"
  },
  rateInput: {
    height: 40,
    width: 100,
    padding: 10,
    marginRight: 10
  },
  rateButton: {
    width: 70,
    height: 40,
    padding: 10
  },
  rate: {
    cursor: "initial"
  },
  consignersTable: {
    borderBottom: "1px solid #ccc",
    minHeight: "300px",
    width: "100%"
  },

  valueContainer: {
    height: 60,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: "8 16",
    color: "#212b36"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "6px 6px",
    outline: "none",
    textAlign: "left",
    fontWeight: "bold",
    overflow: "visible"
  },
  cell: {
    padding: 0
  },
  cellValue: {
    width: "100%",
    whiteSpace: "normal",
    paddingLeft: 10,
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Consigners));
