//NPM
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

//Component
import VariantTags from "./VariantTags";
import VariantEntry from "./VariantEntry";

class SKUVariant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDown: false,
      type: ""
    };
  }

  /***
   * clears cache and tags when product type is changed
   */
  componentDidUpdate() {
    if (this.props.type !== this.state.type) {
      this.setState({ type: this.props.type });
    }
  }

  /***
   * toggles drop-down interface that allows users to add size variants
   * @param { Object } e - event object from onClick event
   */
  toggleDropDown = e => {
    this.setState({ dropDown: !this.state.dropDown });
  };

  render() {
    let {
      type,
      tags,
      skus,
      handleAddition,
      handleDelete,
      handleBarcode,
      intl
    } = this.props;

    return (
      <div className={css(styles.variant)}>
        <div className={css(styles.variantHeader)}>
          <h2 className={css(styles.title)}>
            {intl.formatMessage({ id: "variant", defaultMessage: "Variant" })}
          </h2>
          {/* <div className={css(styles.addBtn)} onClick={this.toggleDropDown}>
            {dropDown
              ? intl.formatMessage({ id: "cancel", defaultMessage: "Cancel" })
              : intl.formatMessage({
                  id: "add_sku",
                  defaultMessage: "Add SKU"
                })}
          </div> */}
        </div>
        <div className={css(styles.variantLabel)}>
          {intl.formatMessage({
            id: "variant_des",
            defaultMessage:
              "Add variants if this product comes in multiple sizes."
          })}
        </div>

        <div className={css(styles.variantDropDown)}>
          <div className={css(styles.row)}>
            <div className={css(styles.container)}>
              <div className={css(styles.label)}>
                {type
                  ? `${type[0]
                      .toUpperCase()
                      .concat(type.slice(1))} ${intl.formatMessage({
                      id: "size",
                      defaultMessage: "Size"
                    })}`
                  : intl.formatMessage({
                      id: "product_type",
                      defaultMessage: "Product Type"
                    })}
              </div>
              <div className={css(styles.inputWrapper)}>
                <VariantTags
                  toggleVariantTable={this.toggleVariantTable}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  type={type}
                  tags={tags}
                />
              </div>
            </div>
          </div>
          {tags !== undefined && tags.length > 0 && (
            <div>
              <div className={`${css(styles.labels)} ${css(styles.row)}`}>
                <div className={`${css(styles.column)} ${css(styles.label)}`}>
                  {intl.formatMessage({
                    id: "variant",
                    defaultMessage: "Variant"
                  })}
                </div>
                <div className={`${css(styles.barCode)} ${css(styles.label)}`}>
                  {intl.formatMessage({
                    id: "sku_barcode",
                    defaultMessage: "Barcode"
                  })}
                </div>
              </div>
              {tags.map((variant, index) => {
                return (
                  <VariantEntry
                    variant={variant}
                    key={variant.id}
                    index={index}
                    skus={skus}
                    handleDelete={handleDelete}
                    handleBarcode={handleBarcode}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

let styles = StyleSheet.create({
  variant: {
    width: 840,
    padding: 15,
    background: "white",
    minHeight: 400
  },
  variantHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  variantLabel: {
    paddingLeft: 10,
    fontWeight: 100,
    margin: "10px 0px 10px 0px",
    fontFamily: "system-ui"
  },
  variantDropDown: {
    marginTop: 25,
    background: "rgb(245, 245, 245)",
    boxShadow: "rgba(129,148,167,0.39) 0px 1px 3px 0px"
  },
  title: {
    paddingLeft: 10,
    fontWeight: "bold"
  },
  addBtn: {
    paddingTop: 5,
    paddingRight: 10,
    fontWeight: 300,
    cursor: "pointer",
    color: "#007ace",
    fontFamily: "system-ui",
    touchAction: "manipulation",
    ":hover": {
      textDecoration: "underline",
      color: "#212b36"
    }
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingBottom: 20
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "left",
    marginLeft: 20
  },
  labels: {
    paddingBottom: 0
  },
  column: {
    paddingLeft: 63,
    paddingRight: 20,
    marginRight: 10
  },
  barCode: {
    marginLeft: 50
  },
  label: {
    paddingLeft: 10,
    fontWeight: 300,
    margin: "10px 0px 10px 0px"
  },
  inputWrapper: {
    paddingLeft: 10
  }
});

export default injectIntl(SKUVariant);
