//NPM
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

//CONFIG
import colors from "../../../../config/colors";

class SKUProductDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { intl } = this.props;
    return (
      <div className={css(styles.description)}>
        <label className={css(styles.descriptionLabel)}>Description</label>
        <textarea
          className={css(styles.descriptionInput)}
          id="description"
          name="description"
          rows="8"
          cols="33"
          placeholder={intl.formatMessage({
            id: "write_description",
            defaultMessage: "Write a description..."
          })}
          value={this.props.description}
          onChange={this.props.handleInputChange}
        />
      </div>
    );
  }
}

let styles = StyleSheet.create({
  description: {
    display: "flex",
    flexDirection: "column"
  },
  descriptionLabel: {
    paddingLeft: 10,
    margin: "20px 0px 10px 0px",
    fontWeight: 300
  },
  descriptionInput: {
    resize: "none",
    height: 150,
    lineHeight: 1.4,
    fontFamily: "system-ui",
    fontSize: 14,
    fontWeight: 200,
    padding: 10,
    cursor: "text",
    border: "1px solid #ddd",
    borderRadius: 3,
    margin: "0 10px 0px 10px",
    outline: "none",
    transition: "all 0.30s ease-in-out",
    background: "rgb(245, 245, 245)",
    ":focus": {
      border: `1px solid ${colors.LIGHT_BLUE(1)}`,
      boxShadow: `0px 0px 5px  ${colors.LIGHT_BLUE(1)}`,
      background: "white"
    }
  }
});

export default injectIntl(SKUProductDescription);
