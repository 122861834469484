import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import Creatable from "react-select/creatable";

// Config
import colors from "../../../../config/colors";

const SKUFormDropdown = props => {
  let {
    label,
    size,
    incompleteField,
    placeholder,
    options,
    value,
    handleDropdown,
    onCreateOption
  } = props;

  const styles = StyleSheet.create({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "left"
    },
    label: {
      paddingLeft: 10,
      fontWeight: 300,
      margin: "10px 0px 10px 0px"
    },
    inputWrapper: {
      paddingLeft: 10
    },
    input: {
      height: 50,
      width: size ? size : "",
      borderRadius: 3,
      outline: "none",
      fontSize: 14,
      fontWeight: 200,
      cursor: "text",
      transition: "all 0.30s ease-in-out",
      ":focus": {
        border: `1px solid ${
          incompleteField ? colors.RED(1) : colors.LIGHT_BLUE(1)
        }`,
        boxShadow: `0px 0px 5px ${
          incompleteField ? colors.RED(1) : colors.LIGHT_BLUE(1)
        }`,
        background: "white"
      }
    }
  });

  const customStyles = {
    control: provided => ({
      ...provided,
      height: 50
    })
  };

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.label)}>{label}</div>
      <div className={css(styles.inputWrapper)}>
        <Creatable
          styles={customStyles}
          options={options.sort((a, b) => (a.label > b.label ? 1 : -1))}
          className={css(styles.input)}
          value={value}
          onChange={opt => handleDropdown(opt.value)}
          placeholder={placeholder}
          onCreateOption={optionName => onCreateOption(optionName)}
        />
      </div>
    </div>
  );
};

export default injectIntl(SKUFormDropdown);
