/***
 * Consignment screen for Solestage
 * @patr -- patrick@quantfive.org
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import TimeAgo from "javascript-time-ago";

// Components
import Button from "../../components/Button";
import Input from "../../components/Input";
import ConsignmentOrder from "../../components/consignment/ConsignmentOrder";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Redux
import { ModalActions } from "../../redux/modals";
import { JoyrideActions } from "../../redux/joyride";

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// Create relative date/time formatter.
// const timeAgo = new TimeAgo("en-US");

class ScannedItems extends React.Component {
  constructor(props) {
    super(props);

    let { joyride, intl } = this.props;

    this.tabs = [
      {
        id: "pending",
        label: intl.formatMessage({
          id: "pending",
          defaultMessage: "Pending"
        })
      },
      {
        id: "completed",
        label: intl.formatMessage({
          id: "completed",
          defaultMessage: "Completed"
        })
      },
      {
        id: "all",
        label: intl.formatMessage({
          id: "all_request",
          defaultMessage: "All"
        })
      }
    ];

    this.guideData = [
      {
        Consigner: "Johnny Champ",
        Consignment: "#76",
        Date: "a month ago",
        Price: "$900",
        Quantity: 1,
        Status:
          joyride.feature === "list_consignment" ? "completed" : "pending",
        items: [
          {
            active: true,
            clover_id: null,
            condition: "New",
            consignment_order: 76,
            cost: null,
            created_at: "2018-11-03T12:53:38.336007-07:00",
            id: 148660,
            image_link:
              "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg",
            in_transit: false,
            labelled: false,
            listable: false,
            listed: false,
            location: null,
            price: null,
            shelf: "",
            sold: false,
            sold_at: null,
            consigner: {
              billing_address: null,
              consignment_approved: true,
              consignment_rate: 15,
              created_at: "2018-09-24T17:55:32.379915-07:00",
              email: "cu@quantfive.org",
              first_name: "Johnny",
              id: 15,
              last_name: "Champ",
              phone_number: "8502944844",
              return_address: null,
              shipping_address: {
                id: 14,
                state: "CA",
                country: "USA",
                city: "Los Angeles",
                zip_code: "94709"
              },
              updated_at: "2018-11-07T00:00:04.717238-08:00",
              user: 21,
              verified_consigner: true
            },
            consignment: {
              apparel_package_condition: "",
              barcode_printed: false,
              box_condition: "Good Condition",
              clover_item_id: "",
              created_at: "2018-11-03T12:58:53.342228-07:00",
              defects: false,
              discolored: false,
              id: 206,
              missing_insoles: false,
              missing_tags: false,
              order: 77,
              payout: null,
              pending_price: null,
              pictures: [],
              price: {
                id: 201,
                default_price: 90000,
                default_currency: "usd",
                solestage_price: 90000,
                instore_price: 90000
              },
              scuff_marks: false,
              size: "10",
              status: "approved",
              tears: false,
              updated_at: "2018-11-03T13:11:19.096066-07:00",
              yellowing: false
            },
            pictures: [
              {
                id: 20504,
                sku: 20549,
                url:
                  "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg"
              }
            ],
            updated_at: "2018-11-03T12:55:28.734470-07:00",
            sku: {
              active: true,
              barcode: "12345678999",
              brand: "Adidas",
              color: "Beluga",
              created_at: "2018-11-09T08:44:04.436342-08:00",
              default_location: null,
              eu_size: null,
              gender: "M",
              id: 20549,
              item_type: "shoe",
              product_name: "Adidas Yeezy Boost",
              product_name_us: "Adidas Yeezy Boost",
              sku: null,
              style: "508214-660",
              updated_at: "2018-11-09T08:44:04.781280-08:00",
              us_size: "10"
            }
          }
        ],
        request_id: 76
      }
    ];

    let { sessionId, reconciliationId } = this.props.match.params;
    this.state = {
      sessionId,
      reconciliationId,
      data: [],
      numPages: 1,
      page: 1,
      pageSize: 20,
      hoveredRow: null,
      activeTab:
        joyride.feature === "list_consignment" ? "completed" : "pending",
      count: null,
      barcodes: [],
      search: "",
      scannedItemId: "",
      itemIds: [],
      missingIds: [],
      extraIds: [],
      soldIds: [],
      initNotes: "",
      notes: "",
      submitted: false,
      start_date: "",
      submit_date: "",
      status: "",
      itemData: null,
      previewItem: true,
      currentItem: null
    };
  }

  // search = async e => {
  //   if (e.key === "Enter") {
  //     this.setState({
  //       page: 1,
  //       searching: true
  //     });
  //     if (this.state.activeTab === "pending" && !this.state.search) {
  //       await this.getSessions(1, this.state.pageSize, "pending");
  //     } else if (this.state.activeTab === "completed" && !this.state.search) {
  //       await this.getSessions(1, this.state.pageSize, "completed");
  //     } else {
  //       await this.getSessions();
  //     }
  //     this.setState({
  //       searching: false
  //     });
  //   }
  // };

  /***
   * Gets all sessions for the inventory reconciliation
   */
  getSessionDetails = () => {
    let { reconciliationId, sessionId } = this.state;
    return fetch(
      API.INVENTORY_RECONCILIATION({
        reconciliationId,
        sessionId,
        type: "get_session_details"
      }),
      API.GET_CONFIG(false)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let { data, missing, extra, sold } = res;
        this.setState({
          submitted: !!data.submit_date,
          itemIds: data.scanned_item_ids.reverse(),
          missingIds: missing,
          extraIds: extra,
          soldIds: sold,
          notes: data.notes,
          initNotes: data.notes,
          start_date: data.start_date,
          submit_date: data.submit_date,
          status: data.status
        });
      });
  };

  togglePreview = e => {
    this.setState({ previewItem: e.target.checked });
  };

  renderItems = itemIds => {
    // <ReactList
    //   itemRenderer={(i, k) => this.renderItem(i, k, itemIds)}
    //   length={itemIds.length}
    //   className={css(styles.idsContainer)}
    // />
    return itemIds.map(
      itemId =>
        itemId && <div className={css(styles.idContainer)}> {itemId}</div>
    );
  };

  renderItem = (index, key, itemIds) => {
    const itemId = itemIds[index];
    return (
      <div key={key} className={css(styles.idContainer)}>
        {" "}
        {itemId}
      </div>
    );
  };

  _handleKeyDown = e => {
    if (e.key === "Enter") {
      if (this.state.scannedItemId.slice(0, 3) !== "ss-") {
        window.alert("Barcode Error: " + this.state.scannedItemId);
        this.setState({
          scannedItemId: ""
        });
        return;
      }
      let itemId = this.state.scannedItemId.slice(3);

      const params = {
        reconciliationId: this.state.reconciliationId,
        sessionId: this.state.sessionId,
        itemId,
        type: "scan_item",
        preview: this.state.previewItem
      };

      this.setState({
        scannedItemId: ""
      });

      return fetch(API.INVENTORY_RECONCILIATION(params), API.POST_CONFIG())
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          let { scanned, missing, extra, sold, status, item } = res;

          this.setState(
            {
              itemIds: scanned.reverse(),
              missingIds: missing,
              extraIds: extra,
              soldIds: sold,
              itemData: item,
              itemStatus: status
            },
            () => {
              if (!status.includes("valid")) {
                setTimeout(() => alert(status));
              }
            }
          );
        });
    }
  };

  scanItem = e => {
    const scannedId = e.target.value;
    this.setState({
      scannedItemId: scannedId
    });
  };

  /**
   * Update order note
   *
   */
  saveNote = () => {
    let { sessionId, notes } = this.state;
    let params = {
      type: "update_session_notes",
      sessionId,
      notes
    };

    return fetch(API.INVENTORY_RECONCILIATION(params), API.PUT_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState(prev => {
          return { initNotes: prev.notes };
        });
      });
  };

  /***
   * Sets the search value
   */
  searchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    this.setState(
      {
        activeTab: tab,
        page: 1
      },
      () => {
        // let status = null;
        // if (tab === "pending") {
        //   status = "pending";
        // } else if (tab === "completed") {
        //   status = "completed";
        // }
        this.getSessions();
      }
    );
  };

  renderCell = (header, props, index) => {
    switch (header) {
      case "Status":
        let fulfilled = props.value === "completed";
        let rejected = props.value === "rejected";
        if (fulfilled) {
          return (
            <div
              className={css(styles.cellValue, styles.status, styles.fulfilled)}
            >
              {props.value}
            </div>
          );
        } else if (rejected) {
          return (
            <div
              className={css(styles.cellValue, styles.status, styles.rejected)}
            >
              {props.value}
            </div>
          );
        } else {
          return (
            <div
              className={css(
                styles.cellValue,
                styles.status,
                styles.unfulfilled
              )}
            >
              {props.value}
            </div>
          );
        }
      default:
        return (
          <div
            className={css(
              styles.cellValue,
              header === "Order" && styles.orderNumber
            )}
          >
            {props.row[index] ? props.row[index].value : props.value}
          </div>
        );
    }
  };

  componentDidMount = () => {
    this.getSessionDetails();
    if (this.props.joyride.forceJoy) {
      let { joyrideActions } = this.props;
      joyrideActions.setRun({ run: true, inProgress: true });
    }
  };

  onClickBack = () => {
    const { history } = this.props;
    const { reconciliationId } = this.state;

    history.push(`/reconciliation/${reconciliationId}`);
  };

  onClickSubmit = () => {
    let answer = window.confirm(
      "You will not be able to edit this session after submitting. Continue?"
    );
    if (!answer) {
      return;
    }
    const { sessionId, itemIds } = this.state;
    const params = {
      sessionId,
      type: "submit_session"
    };

    if (itemIds.length > 0) {
      return fetch(API.INVENTORY_RECONCILIATION(params), API.PUT_CONFIG())
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          this.setState({ submitted: true });
        });
    } else {
      alert("You have not scanned any items.");
    }
  };

  render() {
    let { auth, intl } = this.props;
    // let columns = [
    //   intl.formatMessage({
    //     id: "id",
    //     defaultMessage: "ID"
    //   })
    //   // intl.formatMessage({ id: "start_date", defaultMessage: "Start Date" }),
    //   // intl.formatMessage({
    //   //   id: "submission_date",
    //   //   defaultMessage: "Submission Date"
    //   // }),
    //   // intl.formatMessage({
    //   //   id: "total_scanned",
    //   //   defaultMessage: "Total Scanned"
    //   // }),
    //   // intl.formatMessage({ id: "status", defaultMessage: "Status" })
    // ].map((column, index) => ({
    //   Header: column
    //     ? column !== "table-id"
    //       ? column !== "manage"
    //         ? column
    //         : null
    //       : null
    //     : null,
    //   accessor: column,
    //   className: css(styles.cell),
    //   headerClassName: css(
    //     styles.cellHeader,
    //     column === "table-id" && styles.headerRowNumber
    //   ),
    //   filterable: false,
    //   minWidth: column === "table-id" ? 50 : 165,
    //   Cell: props => (
    //     <div
    //       className={css(
    //         styles.valueContainer,
    //         props.row._index === this.state.hoveredRow && styles.hoveredRow
    //       )}
    //       id={
    //         window.location.search.includes("guide=true") &&
    //         props.row._index === 0 &&
    //         "clickRow"
    //       }
    //       // onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
    //     >
    //       {this.renderCell(props.column.Header, props, index)}
    //     </div>
    //   )
    // }));

    // let tabs = this.tabs.map((tab, index) => {
    //   return (
    //     <div
    //       className={css(
    //         styles.tab,
    //         this.state.activeTab === tab.id && styles.activeTab
    //       )}
    //       onClick={() => this.setActiveTab(tab.id)}
    //       key={`tab_${tab.id}_${index}`}
    //       // id={tab.id === "completed" && "completedTab"}
    //     >
    //       {tab.label}
    //     </div>
    //   );
    // });

    let final_conditions = [];

    let consignment =
      this.state.itemData && this.state.itemData.consignment
        ? this.state.itemData.consignment
        : {};
    let conditions = [
      consignment.yellowing && "yellowing",
      consignment.defects && "defects",
      consignment.missing_insoles && "missing insoles",
      consignment.scuff_marks && "scuff_marks",
      consignment.used && "used"
    ];

    for (let i = 0; i < conditions.length; i++) {
      if (conditions[i] !== false) {
        final_conditions.push(conditions[i]);
      }
    }

    return (
      <div className={css(styles.orders)}>
        <h2 className={css(styles.dashboardTitle)}>
          <FormattedMessage
            id="reconciliation_sessions"
            defaultMessage={`Session ${this.state.sessionId} (Reconciliation ${
              this.state.reconciliationId
            })`}
          />
        </h2>
        {/* <div className={css(styles.tabs)}>{tabs}</div> */}
        <div className={css(styles.infoWrapper)}>
          <div className={css(styles.infoDetailWrapper)}>
            <div>
              {intl.formatMessage({
                id: "start_date",
                defaultMessage: "Start Date: "
              })}
              {this.state.start_date}
            </div>
            {this.state.submit_date && (
              <div>
                {intl.formatMessage({
                  id: "submit_date",
                  defaultMessage: "Submit Date: "
                })}
                {this.state.submit_date}
              </div>
            )}
            <div>
              {intl.formatMessage({
                id: "status",
                defaultMessage: "Status: "
              })}
              {this.state.status}
            </div>
          </div>
          <div className={css(styles.reconciliationNoteWrapper)}>
            <textarea
              name={"note"}
              placeholder={"Add notes for this session"}
              value={this.state.notes}
              className={css(styles.reconciliationNote)}
              onBlur={!this.state.submitted && this.saveNote}
              onChange={e => this.setState({ notes: e.target.value })}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <h3>Total Scanned:</h3>
            <p style={{ fontSize: 60, margin: 0 }}>
              {this.state.itemIds.length}
            </p>
          </div>
        </div>

        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <div className={css(styles.header)}>
            <Button
              className={styles.button}
              text={intl.formatMessage({
                id: "back",
                defaultMessage: "<< Back"
              })}
              onClick={this.onClickBack}
            />
            <div className={css(styles.search)}>
              <Input
                value={this.state.scannedItemId}
                placeholder={intl.formatMessage({
                  id: "search_consigner",
                  defaultMessage: "Scan Item"
                })}
                onChange={this.scanItem}
                id={"searchConsignment"}
                disabled={this.state.submitted}
                onKeyDown={this._handleKeyDown}
              />
              <div>
                <input
                  type="checkbox"
                  onChange={this.togglePreview}
                  checked={this.state.previewItem}
                />
                <span>Display Preview</span>
              </div>
            </div>
            <Button
              className={styles.button}
              text={intl.formatMessage({
                id: "submit",
                defaultMessage: "Submit"
              })}
              onClick={this.onClickSubmit}
              disabled={this.state.submitted}
            />
          </div>
          <div className={css(styles.currentItem)}>
            <h3>Current Scanned Item</h3>
            <p>Status: {this.state.itemStatus}</p>
            {this.state.previewItem &&
              this.state.itemData &&
              this.state.itemData.id && (
                <div className={css(styles.preview)}>
                  {this.state.itemData && (
                    <div className={css(styles.consignmentOrder)}>
                      <ConsignmentOrder
                        conditions={final_conditions}
                        id={this.state.itemData.id}
                        status={this.state.itemData.consignment.status}
                        pictures={this.state.itemData.pictures}
                        color={
                          this.state.itemData.sku &&
                          this.state.itemData.sku.color
                        }
                        consignment_id={this.state.itemData.consignment.id}
                        product_name={
                          this.state.itemData.sku &&
                          this.state.itemData.sku.product_name_us
                        }
                        size={
                          this.state.itemData.sku &&
                          this.state.itemData.sku.us_size
                        }
                        price={
                          this.state.itemData.consignment.price
                            ? this.state.itemData.consignment.price
                                .default_price
                            : 0
                        }
                        cost={this.state.itemData.cost}
                        consigner={this.state.itemData.consigner}
                        updated_at={this.state.itemData.consignment.updated_at}
                        sold_at={this.state.itemData.sold_at}
                        print={!this.state.itemData.sold}
                        // removeConsignment={this.removeConsignment}
                        barcode={`ss-${this.state.itemData.id}`}
                        sold={this.state.itemData.sold}
                        style={
                          this.state.itemData.sku &&
                          this.state.itemData.sku.style
                        }
                        deletedReason={this.state.itemData.delete_reason}
                        deleted={!this.state.itemData.active}
                        hidePriceChange={this.hidePriceChange}
                        hidePrice={this.state.hidePrice}
                        listed_at={this.state.itemData.consignment.listed_at}
                        pending_at={this.state.itemData.consignment.pending_at}
                        approved_at={
                          this.state.itemData.consignment.approved_at
                        }
                        display
                      />
                    </div>
                  )}
                </div>
              )}
          </div>
          <h3>Scanned IDs (this session):</h3>
          <div className={css(styles.scannedItems)}>
            {this.renderItems(this.state.itemIds)}
            <br />
          </div>
          <h3>Extra IDs (all sessions):</h3>
          <div className={css(styles.scannedItems)}>
            {this.renderItems(this.state.extraIds)}
            <br />
          </div>
          <h3>Sold IDs (all sessions):</h3>
          <div className={css(styles.scannedItems)}>
            {this.renderItems(this.state.soldIds)}
            <br />
          </div>
          {["admin", "accountant"].includes(
            auth.userProfile.role.toLowerCase()
          ) && (
            <>
              <h3>Missing IDs (all sessions):</h3>
              <div className={css(styles.scannedItems)}>
                {this.renderItems(this.state.missingIds)}
                <br />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  orders: {
    textAlign: "center",
    padding: 20,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  status: {
    textTransform: "capitalize"
  },
  table: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
    color: "#212b36",
    borderBottom: "1px solid #ddd"
  },
  productColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    whiteSpace: "pre-wrap"
  },
  itemPhoto: {
    width: 75,
    height: 75,
    paddingBottom: 8
  },
  orderNumber: {
    color: colors.LIGHT_BLUE(1)
  },
  hoveredRow: {
    background: "#eee"
  },
  link: {
    textDecoration: "none"
  },
  unfulfilled: {
    background: "#ffea8a",
    padding: "8px 8px",
    borderRadius: 24
  },
  rejected: {
    padding: "8px 8px",
    borderRadius: 24,
    color: "red"
  },
  fulfilled: {
    padding: "8px 8px",
    borderRadius: 24,
    background: "#dfe3e8"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 16,
    position: "relative"
  },
  tab: {
    marginLeft: 7,
    marginRight: 7,
    opacity: ".3",
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  },
  button: {
    width: 100,
    height: 38,
    fontSize: 16,
    padding: 10
  },
  startSessionButton: {
    width: 180,
    height: 38,
    marginLeft: 20,
    fontSize: 16,
    padding: 10
  },
  scannedItems: {
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    width: "100%"
  },
  idsContainer: {
    display: "flex"
  },
  idContainer: {
    padding: 5,
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: "rgb(244,244,244)",
    marginRight: 10,
    marginBottom: 10
  },

  infoWrapper: {
    display: "flex",
    marginBottom: 20,
    width: "100%",
    justifyContent: "center",
    height: 150
  },
  infoDetailWrapper: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    width: "20%",
    boxShadow: " 0px 0px 10px rgba(0,0,0,0.1)",
    marginRight: "2%"
  },
  reconciliationNoteWrapper: {
    marginRight: 100,
    width: "40%",
    backgroundColor: "rgb(250,250,250)",
    boxShadow: " 0px 0px 10px rgba(0,0,0,0.1)",
    padding: 10
  },
  reconciliationNote: {
    boxSizing: "border-box",
    resize: "none",
    width: "100%",
    fontSize: "1.3em",
    height: "100%",
    padding: 10,
    overflowY: "scroll",
    border: "none",
    backgroundColor: "rgba(0,0,0,0)"
  },
  noteEdit: {
    backgroundColor: "rgb(255,255,255)",
    border: "none"
  },
  noteDisplay: {
    border: "none"
  },

  editButtonWrapper: {
    marginTop: 10,
    display: "flex",
    width: "100%"
  },
  editButton: {
    padding: "1em 2em",
    border: "none",
    cursor: "pointer",
    letterSpacing: 0.7,
    outline: "none",
    marginRight: 10
  },
  hideButton: {
    display: "none"
  },
  preview: {
    textAlign: "center"
  },
  consignmentOrder: {
    width: 500,
    margin: "0 auto",
    marginBottom: 16,
    padding: 16,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    backgroundColor: "white",
    position: "relative"
    // margin: '0 auto',
  },
  currentItem: {
    padding: 10,
    width: "max-content",
    margin: "0 auto",
    marginTop: 10,
    backgroundColor: "rgb(250,250,250)"
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  joyride: state.joyride
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  joyrideActions: bindActionCreators(JoyrideActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ScannedItems));
