import React from "react";

// NPM Modules
import { default as SpinKit } from "react-spinkit";
import { StyleSheet, css } from "aphrodite";

const LoadingIndicator = ({
  classes,
  className,
  children,
  fadeIn,
  full,
  id,
  loading,
  name,
  showing,
  text,
  textClassName,
  outerClass
}) => (
  <div id={id} className={css(styles.loadingIndicator, outerClass)}>
    {text && <h1 className={css(styles.text, textClassName)}>{text}</h1>}
    <SpinKit
      className={css(styles.loader, className)}
      fadeIn={fadeIn}
      name={name}
    />
  </div>
);

LoadingIndicator.defaultProps = {
  name: "ball-beat",
  loading: false
};

const styles = StyleSheet.create({
  loadingIndicator: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.6)"
  }
});

export default LoadingIndicator;
