/***
 * Screen to invite a user and manage all of your users
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { BulletList } from "react-content-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTable from "react-table";
import { get } from "lodash";
import { withRouter } from "react-router-dom";

// Components
import { Button as BootstrapButton } from "react-bootstrap";
import Button from "../../components/Button";
import InviteUsers from "../../components/modals/InviteUsers";

// Redux
import { ModalActions } from "../../redux/modals";

// API
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import colors from "../../config/colors";

class Users extends React.Component {
  constructor(props) {
    super(props);

    this._tabs = [
      { id: "active", label: "Users" },
      { id: "deleted", label: "Disabled Users" }
    ];

    this.state = {
      employees: [],
      activeTab: this._tabs[0].id,
      fetchingEmployees: false,
      fetchedEmployees: false,
      page: 1,
      pageSize: 25,
      totalItems: 0,
      numPages: 1
    };
  }

  componentDidMount = () => {
    this.getAllEmployees();
  };

  /***
   * Opens the invite users modal
   */
  openInviteModal = () => {
    let { modalActions } = this.props;
    modalActions.openInviteUsersModal(true);
  };

  onPermissionsClick = user => {
    this.props.history.push(`/permissions/${user.id}`);
  };

  /***
   * Gets all the users registered in the backend
   */
  getAllEmployees = () => {
    this.setState({
      fetchingEmployees: true
    });
    const showActive = this.state.activeTab === "active";
    const pageSize = this.state.pageSize;
    const page = this.state.page || 1;
    let url = API.EMPLOYEE + "?active=" + (showActive ? "True" : "False");
    url = `${url}&page=${page}&page_size=${pageSize}`;
    return fetch(url, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        if (res.count > 0) {
          this.setState({
            employees: res.results
          });
        }

        this.setState({
          fetchingEmployees: false,
          fetchedEmployees: true,
          totalItems: res.count,
          numPages: Math.ceil(res.count / this.state.pageSize)
        });
      });
  };

  setTab = activeTab => {
    this.setState({ activeTab }, () => this.getAllEmployees());
  };

  showConfirmDelete = user => {
    this.setState({ confirmingDelete: user.id });
  };

  confirmingDelete = user => {
    return this.state.confirmingDelete === user.id;
  };

  deleteUser = user => {
    fetch(`${API.EMPLOYEE}${user.id}/`, API.DELETE_CONFIG())
      .then(Helpers.checkStatus)
      .then(() => {
        this.setState({ confirmingDelete: null });
        this.getAllEmployees();
      });
  };

  get columns() {
    const {
      state: { activeTab },
      props: { intl }
    } = this;
    const currentRole = get(this.props, "auth.userProfile.role");
    const isAdmin = currentRole === "Admin";

    let columns = [
      {
        id: "employeeInfo",
        Header: "Employee",
        accessor: user => user,
        className: css(styles.employeeInfo),
        headerClassName: css(styles.employeeInfo),
        Cell: ({ original: user }) => (
          <>
            <div className={css(styles.image)}>
              {user.image ? (
                <img src={user.img} alt="woop" />
              ) : (
                <div className={css(styles.img)}>
                  <FontAwesomeIcon
                    icon={["fas", "user"]}
                    className={css(styles.user)}
                  />
                </div>
              )}
            </div>

            <div className={css(styles.name)}>
              <div className={css(styles.fullName)}>
                {user.first_name ? user.first_name + " " + user.last_name : ""}
              </div>
              <div className={css(styles.email)}>{user.email}</div>
              {user.location && (
                <div className={css(styles.location)}>
                  {user.location.name}
                  <br />
                  {user.location.address.address}, {user.location.address.city}{" "}
                  {user.location.address.state}
                </div>
              )}
            </div>
          </>
        )
      },
      {
        id: "role",
        Header: "Role",
        accessor: user => user,
        className: css(styles.employeeRole),
        headerClassName: css(styles.employeeRole),
        Cell: ({ original: user }) => (
          <>
            <div className={css(styles.employeeRole)}>{user.role}</div>
            {isAdmin && (
              <div className={css(styles.permissionsLink)}>
                <BootstrapButton onClick={() => this.onPermissionsClick(user)}>
                  permissions
                </BootstrapButton>
              </div>
            )}
          </>
        )
      }
    ];

    if (activeTab === "active") {
      columns.push({
        id: "delete",
        Header: "",
        accessor: user => user,
        className: css(styles.deleteEmployee),
        headerClassName: css(styles.deleteEmployee),
        Cell: ({ original: user }) => (
          <div className={css(styles.deleteEmployeeWrapper)}>
            {this.confirmingDelete(user) ? (
              <Button
                text={intl.formatMessage({
                  id: "confirm_delete",
                  defaultMessage: "Confirm Delete"
                })}
                onClick={() => this.deleteUser(user)}
              />
            ) : (
              <Button
                text={intl.formatMessage({
                  id: "delete_user",
                  defaultMessage: "Delete User"
                })}
                onClick={() => this.showConfirmDelete(user)}
              />
            )}
          </div>
        )
      });
    }

    return columns;
  }

  get tabs() {
    return this._tabs.map((tab, index) => (
      <div
        onClick={() => this.setTab(tab.id)}
        key={`tab_${tab.id}`}
        className={css(
          styles.tab,
          index !== 0 && styles.marginLeft,
          this.state.activeTab === tab.id && styles.activeTab
        )}
      >
        {tab.label}
      </div>
    ));
  }

  changePageSize = pageSize => {
    this.setState(
      {
        pageSize,
        page: 1,
        numPages: Math.ceil(this.state.totalItems / pageSize)
      },
      () => this.getAllEmployees()
    );
  };

  changePage = page => {
    this.setState({ page }, () => this.getAllEmployees());
  };

  render() {
    const { employees } = this.state;
    const columns = this.columns;
    const tabs = this.tabs;
    let { intl } = this.props;

    return (
      <div className={css(styles.users)}>
        <div className={css(styles.userBar)}>
          <div className={css(styles.tabs)}>{tabs}</div>
          <div className={css(styles.invite)}>
            <Button
              text={intl.formatMessage({
                id: "invite_users",
                defaultMessage: "Add Employee"
              })}
              onClick={this.openInviteModal}
            />
          </div>
        </div>

        <div className={css(styles.userList)}>
          {!this.state.fetchedEmployees ? (
            <div>
              <BulletList style={{ height: "250px" }} />
              <BulletList style={{ height: "250px" }} />
            </div>
          ) : (
            <ReactTable
              headerClassName={css(styles.userLabels)}
              columns={columns}
              data={employees}
              defaultPageSize={25}
              pages={this.state.numPages}
              page={this.state.page - 1}
              onPageSizeChange={this.changePageSize}
              onPageChange={pageIndex => this.changePage(pageIndex + 1)}
              onFetchData={() => {}}
              nextText={intl.formatMessage({
                id: "next",
                defaultMessage: "Next"
              })}
              previousText={intl.formatMessage({
                id: "previous",
                defaultMessage: "Previous"
              })}
              noDataText={intl.formatMessage({
                id: "no_items_found",
                defaultMessage: "No users found"
              })}
              manual
            />
          )}
        </div>
        <InviteUsers />
      </div>
    );
  }
}

var styles = StyleSheet.create({
  userBar: {
    padding: 16,
    background: "#fff",
    borderBottom: "1px solid #eee",
    display: "flex",
    alignItems: "center"
  },
  tabs: {
    display: "flex",
    alignItems: "center"
  },
  marginLeft: {
    marginLeft: 16
  },
  activeTab: {
    opacity: 1
  },
  tab: {
    opacity: 0.5,
    cursor: "pointer",
    fontSize: 18,
    letterSpacing: 0.7
  },
  invite: {
    width: 250,
    marginLeft: "auto"
  },
  userLabels: {
    display: "flex",
    alignItems: "center",
    padding: 24,
    background: "#eee"
  },
  label: {
    fontWeight: "bold",
    letterSpacing: 0.7
  },
  employeeInfo: {
    flex: 1,
    display: "flex",
    alignItems: "center"
    // justifyContent: 'center',
  },
  name: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 24,
    justifyContent: "center",
    fontSize: 18,
    letterSpacing: 0.7
  },
  employeeRole: {
    flex: 1,
    fontSize: 18,
    letterSpacing: 0.7
  },
  permissionsLink: {
    fontSize: 16,
    color: colors.LIGHT_BLUE(1)
  },
  deleteEmployee: {
    flex: 1,
    alignItems: "center"
  },
  deleteEmployeeWrapper: {
    width: 200,
    marginLeft: "auto"
  },
  fullName: {
    letterSpacing: 0.7,
    color: colors.LIGHT_BLUE(1)
  },
  placeholder: {
    width: "100%"
  },
  userList: {
    padding: 24
  },
  email: {
    fontSize: 16
  },
  location: {
    fontSize: 15,
    marginTop: 4
  },
  userComponent: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24
  },
  img: {
    background: "#eee",
    width: 80,
    height: 80,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  user: {
    // color: '#fff',
    opacity: 0.7,
    fontSize: "1.5em"
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch)
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(Users)));
