/***
 * Modal to invite users to management app
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import BaseModal from "./BaseModal";
import Input from "../Input";
import Button from "../Button";
import Dropdown from "../../components/inventory/Dropdown";
import RoleDropdown from "../../components/RoleDropdown";

// Actions
import { ModalActions } from "../../redux/modals";

// Config
import Helpers from "../../redux/helpers";
import API from "../../config/api";

class InviteUsersModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      first_names: [],
      last_names: [],
      phone_numbers: [],
      locations: [],
      roles: [],
      size: 1,
      addingUsers: false
    };
  }
  /***
   * closes the modal
   */
  closeModal = () => {
    let { modalActions } = this.props;
    modalActions.openInviteUsersModal(false);
  };

  /***
   * Submits the form to the backend
   */
  submitForm = e => {
    e.preventDefault();
  };

  /**
   * handles the change of inputs
   * @param  {String} email -- string email
   */
  handleInputChange = (e, index) => {
    let first_names = [...this.state.first_names];
    let last_names = [...this.state.last_names];
    let phone_numbers = [...this.state.phone_numbers];
    let emails = [...this.state.emails];
    let size = this.state.size;

    if (e.target.id === "first_name") {
      if (first_names.length - 1 > index && size === index + 1) {
        first_names.push(e.target.value);
      } else {
        first_names[index] = e.target.value;
      }
    } else if (e.target.id === "last_name") {
      if (last_names.length - 1 > index && size === index + 1) {
        last_names.push(e.target.value);
      } else {
        last_names[index] = e.target.value;
      }
    } else if (e.target.id === "phone_number") {
      if (phone_numbers.length - 1 > index && size === index + 1) {
        phone_numbers.push(e.target.value);
      } else {
        phone_numbers[index] = e.target.value;
      }
    } else if (e.target.id === "email") {
      if (
        size === index + 1 &&
        e.target.value.charAt(e.target.value.length - 1) === "@"
      ) {
        size++;
      }

      if (emails.length - 1 > index && size === index + 1) {
        emails.push(e.target.value);
      } else {
        emails[index] = e.target.value;
      }
    }

    this.setState({
      first_names: first_names,
      last_names: last_names,
      phone_numbers: phone_numbers,
      emails: emails,
      size: size
    });
  };

  /**
   * removes email from the emails array
   * @param  {Integer} index -- index of the input being deleted
   */
  deleteEmail = index => {
    let emails = [...this.state.emails];
    let roles = [...this.state.roles];
    let locations = [...this.state.locations];

    if (this.state.emails.length > 0) {
      emails.splice(index, 1);
      roles.splice(index, 1);
      locations.splice(index, 1);

      let size = this.state.size;
      if (size !== index + 1) {
        size--;
      }
      this.setState({
        emails: emails,
        size: size
      });
    }
  };

  /**
   * on change of location selector
   * @params {Event} e -- on change event for selector
   * @params int index -- the index of the location
   */
  onLocationChange = (e, index) => {
    // let value = e.target.value
    // if (e.target.value === "0") {
    //   value = null
    // }

    let locations = [...this.state.locations];

    if (index > locations.length) {
      locations.push(e.target.value);
    } else {
      locations[index] = e.target.value;
    }

    this.setState({
      locations
    });
  };

  /**
   * on change of location selector
   * @params {Event} e -- on change event for selector
   * @params int index -- the index of the location
   */
  onRoleChange = (e, index) => {
    // let value = e.target.value
    // if (e.target.value === "0") {
    //   value = null
    // }

    let roles = [...this.state.roles];

    if (index > roles.length) {
      roles.push(e.target.value);
    } else {
      roles[index] = e.target.value;
    }

    this.setState({
      roles
    });
  };

  /***
   * Adds users to invite table
   */
  addUsers = () => {
    let employees = [];
    for (let i = 0; i < this.state.emails.length; i++) {
      let employee = {
        role: this.state.roles[i],
        profile: {
          email: this.state.emails[i],
          first_name: this.state.first_names[i],
          last_name: this.state.last_names[i],
          phone_number: this.state.phone_numbers[i],
          location: this.state.locations[i],
          role: this.state.roles[i]
        }
      };
      employees.push(employee);
    }
    return fetch(API.EMPLOYEE_INVITES, API.POST_CONFIG(employees))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        if (res.success) {
          this.closeModal();
        }
      });
  };

  render() {
    let { modals, intl } = this.props;

    let inputs = [];

    for (let i = 0; i < this.state.size; i++) {
      inputs.push(
        <div className={css(styles.inputs)} key={`add_user_inputs_${i}`}>
          <div className={css(styles.inputRow, styles.firstRow)}>
            <div className={css(styles.inputContainer, styles.firstContainer)}>
              <div className={css(styles.label)}>
                {intl.formatMessage({
                  id: "profile_first_name",
                  defaultMessage: "First Name"
                })}
              </div>
              <div className={css(styles.email)}>
                <FontAwesomeIcon
                  icon={["far", "user"]}
                  className={css(styles.envelope)}
                  size="1x"
                  color="#fff"
                />
                <Input
                  className={styles.userInput}
                  id="first_name"
                  type="text"
                  value={this.state.first_names[i] || ""}
                  onChange={e => {
                    this.handleInputChange(e, i);
                  }}
                  placeholder={intl.formatMessage({
                    id: "profile_first_name",
                    defaultMessage: "First Name"
                  })}
                />
              </div>
            </div>
            <div className={css(styles.inputContainer)}>
              <div className={css(styles.label)}>
                {intl.formatMessage({
                  id: "profile_last_name",
                  defaultMessage: "Last Name"
                })}
              </div>
              <div className={css(styles.email)}>
                <FontAwesomeIcon
                  icon={["far", "user"]}
                  className={css(styles.envelope)}
                  size="1x"
                  color="#fff"
                />
                <Input
                  className={styles.userInput}
                  id="last_name"
                  type="text"
                  value={this.state.last_names[i] || ""}
                  onChange={e => {
                    this.handleInputChange(e, i);
                  }}
                  placeholder={intl.formatMessage({
                    id: "profile_last_name",
                    defaultMessage: "Last Name"
                  })}
                />
              </div>
            </div>
            <div className={css(styles.inputContainer)}>
              <div className={css(styles.label)}>
                {intl.formatMessage({
                  id: "profile_phone_number",
                  defaultMessage: "Phone Number"
                })}
              </div>
              <div className={css(styles.email)}>
                <FontAwesomeIcon
                  icon={["far", "phone"]}
                  className={css(styles.envelope)}
                  size="1x"
                  color="#fff"
                />
                <Input
                  className={styles.userInput}
                  id="phone_number"
                  type="tel"
                  value={this.state.phone_numbers[i] || ""}
                  onChange={e => {
                    this.handleInputChange(e, i);
                  }}
                  placeholder={intl.formatMessage({
                    id: "profile_phone_number",
                    defaultMessage: "Phone Number"
                  })}
                />
              </div>
            </div>
          </div>
          <div className={css(styles.inputRow)}>
            <div className={css(styles.inputContainer, styles.firstContainer)}>
              <div className={css(styles.label)}>
                {intl.formatMessage({
                  id: "profile_email",
                  defaultMessage: "Email Address"
                })}
              </div>
              <div className={css(styles.email)}>
                <FontAwesomeIcon
                  icon={["far", "envelope"]}
                  className={css(styles.envelope)}
                  size="1x"
                  color="#fff"
                />
                <Input
                  className={styles.userInput}
                  id="email"
                  type="email"
                  value={this.state.emails[i] || ""}
                  onChange={e => {
                    this.handleInputChange(e, i);
                  }}
                  placeholder={intl.formatMessage({
                    id: "profile_email",
                    defaultMessage: "Email Address"
                  })}
                />
              </div>
            </div>
            <div className={css(styles.inputContainer)}>
              <div className={css(styles.label)}>
                {intl.formatMessage({
                  id: "inventory_location",
                  defaultMessage: "Location"
                })}
              </div>
              <Dropdown
                className={styles.dropdown}
                removeAll={true}
                onChange={e => this.onLocationChange(e, i)}
              />
            </div>
            <div className={css(styles.inputContainer)}>
              <div className={css(styles.label)}>
                {intl.formatMessage({ id: "role", defaultMessage: "Role" })}
              </div>
              <RoleDropdown
                className={styles.roleDropdown}
                onChange={e => this.onRoleChange(e, i)}
              />
            </div>
          </div>
          <FontAwesomeIcon
            icon={["far", "times"]}
            className={css(styles.inputFA)}
            size="1x"
            color="#fff"
            onClick={() => this.deleteEmail(i)}
          />
        </div>
      );
    }

    return (
      <BaseModal
        isOpen={modals.openInviteUsersModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <div className={css(styles.invite)}>
          <div className={css(styles.inputsContainer)}>{inputs}</div>
          <div className={css(styles.addRow)}>
            <div className={css(styles.addButton)}>
              <Button
                onClick={this.addUsers}
                text={
                  this.state.addingUsers ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      spin
                      color="#fff"
                    />
                  ) : (
                    intl.formatMessage({ id: "add", defaultMessage: "Add" })
                  )
                }
              />
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  bodyContainer: {
    padding: 0,
    paddingTop: 30
  },
  modal: {
    // height: '80%',
    // padding: 0,
    maxWidth: 900,
    minWidth: 870
  },
  invite: {
    width: "100%",
    maxHeight: "100vh",
    overflow: "auto"
  },
  addRow: {
    display: "flex",
    borderTop: "1px solid #e5e5e5",
    padding: 16
  },
  // label: {
  //   color: '#4c4c4c',
  //   letterSpacing: .7,
  //   marginLeft: 300,
  // },
  addButton: {
    width: 300,
    marginLeft: "auto"
  },
  labelBar: {
    background: "#f9f9f9",
    padding: 16,
    display: "flex",
    borderBottom: "1px solid #e5e5e5"
  },
  inputRow: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  firstRow: {
    marginBottom: 20
  },
  dropdown: {
    height: 50,
    width: 150
  },
  roleDropdown: {
    height: 50
  },
  userInput: {
    padding: "15px 40px",
    // border: 'none',
    // borderBottom: '1px solid #eee',
    background: "#fff",
    width: 250,
    borderWidth: "2px",
    boxSizing: "border-box",
    color: "#828282",
    fontSize: 16,

    "::placeholder": {
      opacity: ".5"
    }
  },
  firstLabel: {
    marginLeft: 30
  },
  lastLabel: {
    marginLeft: 170
  },
  inputFA: {
    position: "absolute",
    color: "#828282",
    right: "16px",
    top: 20
  },
  firstContainer: {
    marginLeft: 30
  },
  inputContainer: {
    position: "relative",
    marginLeft: 30,
    height: "100%"
  },
  envelope: {
    position: "absolute",
    color: "#828282",
    left: "16px",
    top: "50%",
    transform: "translateY(-50%)"
  },
  label: {
    marginBottom: 5
  },
  email: {
    position: "relative"
  },
  inputs: {
    padding: 16,
    borderBottom: "1px solid #e5e5e5",
    position: "relative"
  }
});
const mapStateToProps = state => ({
  modals: state.modals
  // auth: state.auth,
});
const mapDispatchToProps = dispatch => ({
  // authActions: bindActionCreators(AuthActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch)
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InviteUsersModal)
);
