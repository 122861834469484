/**
 * Place where we create the store and combine it with the reducers (Redux stuff)
 * author: @patr
 */
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import { createLogger } from "redux-logger";
import rootReducer from "../redux";
import createHistory from "history/createBrowserHistory";
import createSagaMiddleware from "redux-saga";
import SagaManager from "../redux/SagaManager";

export const history = createHistory();

// Create middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [thunk, routerMiddleware(history), sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  // Configure the logger middleware
  const logger = createLogger({
    level: "debug",
    collapsed: true
  });
  middleware.push(logger);
}

const createStoreWithMiddleware = compose(
  applyMiddleware(...middleware)(createStore)
);

export function configure(initialState) {
  // Create the redux store and add middleware to it
  var configStore = createStoreWithMiddleware(rootReducer, initialState);

  // Start sagas
  SagaManager.startSagas(sagaMiddleware);

  // Snippet to allow hot reload to work with reducers
  if (module.hot) {
    module.hot.accept(function _() {
      configStore.replaceReducer(rootReducer);
    });

    const sagaCallback = () => {
      SagaManager.cancelSagas(configStore);
      require("../redux/SagaManager").default.startSagas(sagaMiddleware);
    };

    module.hot.accept("../redux/SagaManager", sagaCallback);
    module.hot.acceptCallback = sagaCallback;
  }

  return configStore;
}
