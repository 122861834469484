/***
 * Specific order modal
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import BaseModal from "../../components/modals/BaseModal";

// Redux
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import Button from "../../components/Button";
import ShippingLabelComponent from "../../components/orders/ShippingLabelComponent";

class ChooseShippingLabel extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      shippingLabels: [],
      rates: [],
      weight: 3.0,
      confirmation: false,
      rateSelectedIndex: undefined,
      createLabelError: undefined,
      dLength: 14.0,
      dWidth: 10.0,
      dHeight: 6.0,
      disableChooseShippingOption: true,
      addInsurance: false,
      insuredValue: 0,
      billToThirdParty: false,
      billToAccount: "",
      totalValue: 0
      // billToPostalCode: '',
      // billToCountryCode: '',
    };
    this.state = {
      ...this.initialState
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.weight !== prevState.weight) {
      clearTimeout(this.weightGetRates);

      this.weightGetRates = setTimeout(() => {
        this.getRates();
      }, 700);
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.weightGetRates);
  };

  /**
   * Get shipping label rates
   */
  getRates = () => {
    let { messageActions } = this.props;
    let dimensions = {
      length: this.state.dLength,
      width: this.state.dWidth,
      height: this.state.dHeight
    };

    let params = {
      weight: this.state.weight,
      confirmation: this.requireSignature.checked ? "adult_signature" : "none",
      fulfillmentId: this.props.fulfillment.id,
      dimensions
    };

    let config = API.GET_CONFIG(false);
    messageActions.showMessage({ show: true, load: true });

    return fetch(API.GET_RATES(params), config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(resp => this.handleRatesResponse(resp))
      .catch(error => {
        console.log(error.message);
      });
  };

  handleRatesResponse = resp => {
    let { intl, messageActions } = this.props;

    this.setState({
      rates: resp.rates
    });

    if (resp.rates && !resp.rates.length) {
      messageActions.setMessage(
        intl.formatMessage({
          id: "empty_shipping_rates",
          defaultMessage: "Could no get shipping rates"
        })
      );
      messageActions.showMessage({ show: true, error: true });
      setTimeout(() => {
        this.closeModal();
      }, 1500);
    } else {
      messageActions.showMessage({ show: false, load: false });
    }
  };

  /**
   * create shipping label for fulfillment
   * @param  {Integer} fulfillmentId -- id of fulfillment
   */
  createShippingLabel = fulfillmentId => {
    const {
      rates,
      rateSelectedIndex,
      weight,
      addInsurance,
      insuredValue,
      billToThirdParty,
      billToAccount
    } = this.state;
    if (addInsurance && !insuredValue)
      alert("You've chosen to add insurance. Please input insured value.");
    if (billToThirdParty && !billToAccount)
      alert(
        "You've chosen 'bill to third party'. Please input account number."
      );

    let { messageActions } = this.props;
    let rate = rates[rateSelectedIndex];

    let params = {
      confirmation: this.requireSignature.checked ? "adult_signature" : "none",
      weight: weight,
      codes: !billToThirdParty
        ? {
            serviceCode: rate.serviceCode,
            carrierCode: rate.carrierCode,
            packageCode: rate.packageCode
          }
        : {
            serviceCode: "ups_ground",
            carrierCode: "ups",
            packageCode: "package"
          },
      insuranceOptions: addInsurance
        ? {
            provider: "shipsurance",
            insureShipment: true,
            insuredValue: insuredValue
          }
        : null,
      advancedOptions: billToThirdParty
        ? {
            billToParty: "third_party",
            billToAccount: billToAccount
            // billToPostalCode: this.state.billToPostalCode,
            // billToCountryCode: this.state.billToCountryCode,
          }
        : null
    };

    let config = API.POST_CONFIG(params);
    messageActions.showMessage({ show: true, load: true });

    return fetch(
      API.CHOOSE_SHIPPING_LABEL({ fulfillment_id: fulfillmentId }),
      config
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(resp => {
        this.props.setFulfillments(resp);
        this.setState({
          fulfillmentStatus: true
        });
        this.props.markAsFulfilled();
        this.props.printShippingLabel(resp);
        messageActions.showMessage({ show: false, load: false });
        this.closeModal();
      })
      .catch(resp => {
        let message = "There was an error creating the label";
        try {
          if (resp.message) {
            message = resp.message.detail;
          }
        } catch (err) {
          console.log({ resp });
          console.log("Failed to parse error details");
        }
        this.setState({ createLabelError: message });
        messageActions.showMessage({ show: false, load: false });
      });
  };

  onAfterOpen = () => {
    let weight = 0.0;
    let totalValue = 0;

    this.props.items.forEach(item => {
      let currentWeight = item.inventory.sku.product.weight;
      if (isNaN(currentWeight)) {
        weight += parseFloat(currentWeight);
      }

      totalValue += item.total;
    });

    if (weight === 0.0) {
      weight = 3.0;
    }

    this.setState({ weight, totalValue }, () => {
      this.getRates();
    });
  };

  handleChooseRate = () => {
    this.setState(
      {
        disableChooseShippingOption: true,
        createLabelError: undefined
      },
      () => {
        this.createShippingLabel(this.props.fulfillment.id).finally(() => {
          this.setState({ disableChooseShippingOption: false });
        });
      }
    );
  };

  closeModal = () => {
    let { modalActions } = this.props;
    modalActions.chooseShippingModal(false);
    this.setState({
      ...this.initialState
    });
  };

  onChangeDimension = (value, type) => {
    this.setState({ [type]: value });
  };

  toggleInsurance = () => {
    // TODO set default insuredValue?
    this.setState({ addInsurance: !this.state.addInsurance });
  };

  toggleBillTo = () => {
    // TODO set default billTo?
    this.setState({ billToThirdParty: !this.state.billToThirdParty });
  };

  render() {
    let { modals } = this.props;
    let rates = this.state.rates.map((rate, index) => {
      return (
        <div
          className={css(styles.shippingContainer)}
          key={`rate_${index}`}
          onClick={() => {
            this.setState({
              disableChooseShippingOption: false,
              createLabelError: undefined,
              rateSelectedIndex: index
            });
          }}
        >
          <ShippingLabelComponent
            name={rate.serviceName}
            price={rate.cost}
            logo={
              rate.carrierCode === "ups" ? (
                <FontAwesomeIcon name="ups" icon={["fab", "ups"]} size={"2x"} />
              ) : (
                <FontAwesomeIcon
                  icon={["fab", "usps"]}
                  size={"2x"}
                  color={"blue"}
                />
              )
            }
            selected={index === this.state.rateSelectedIndex}
          />
        </div>
      );
    });

    return (
      <BaseModal
        isOpen={modals.chooseShippingModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <div className={css(styles.chooseShippingLabels)}>
          <h1 className={css(styles.title)}>Choose Shipping Service</h1>
          <div className={css(styles.shippingInfoWrapper)}>
            <span className={css(styles.shippingInfo)}>
              Shopify Order: #{this.props.shopify_orderId}{" "}
            </span>
            <span className={css(styles.shippingInfo)}>
              Order Total: ${this.state.totalValue}{" "}
            </span>
            <span className={css(styles.shippingInfo)}>
              Shipping Amount: ${this.props.amount}
            </span>
            <span className={css(styles.shippingInfo)}>
              Selected Shipping Method: {this.props.method}
            </span>
          </div>
          <div>
            <div className={css(styles.settingContainer)}>
              <label className={css(styles.label)}>
                {" "}
                Require Adult Signature
                <input
                  className={css(styles.checkbox)}
                  type="checkbox"
                  ref={ref => (this.requireSignature = ref)}
                  onChange={this.getRates}
                />
              </label>
            </div>
            <div className={css(styles.settingContainer)}>
              <label className={css(styles.label)}>
                {" "}
                Weight
                <input
                  className={css(styles.weightBox)}
                  value={this.state.weight}
                  onChange={e => {
                    this.setState({
                      weight: e.target.value
                    });
                  }}
                />{" "}
                lbs
              </label>
            </div>
            <div className={css(styles.settingContainer)}>
              <label className={css(styles.label)}>Dimensions (in):</label>
              <label className={css(styles.label)}>
                {" "}
                Length
                <input
                  className={css(styles.weightBox)}
                  value={this.state.dLength}
                  onChange={e =>
                    this.onChangeDimension(e.target.value, "dLength")
                  }
                />{" "}
              </label>
              <label className={css(styles.label)}>
                {" "}
                Width
                <input
                  className={css(styles.weightBox)}
                  value={this.state.dWidth}
                  onChange={e =>
                    this.onChangeDimension(e.target.value, "dWidth")
                  }
                />{" "}
              </label>
              <label className={css(styles.label)}>
                {" "}
                Height
                <input
                  className={css(styles.weightBox)}
                  value={this.state.dHeight}
                  onChange={e =>
                    this.onChangeDimension(e.target.value, "dHeight")
                  }
                />{" "}
              </label>
            </div>
            {["admin", "warehouse manager"].includes(
              this.props.auth.userProfile.role.toLowerCase()
            ) && (
              <>
                <div className={css(styles.billTo)}>
                  <label className={css(styles.label)}>
                    {" "}
                    Bill to Third Party
                    <input
                      className={css(styles.checkbox)}
                      type="checkbox"
                      ref={ref => (this.billToThirdParty = ref)}
                      onChange={this.toggleBillTo}
                    />
                  </label>
                </div>
                {this.state.billToThirdParty && (
                  <div className={css(styles.settingContainer)}>
                    <label className={css(styles.label)}>
                      {" "}
                      Bill To Account Number
                      <input
                        className={css(styles.textInput)}
                        value={this.state.billToAccount}
                        onChange={e => {
                          this.setState({
                            billToAccount: e.target.value
                          });
                        }}
                      />{" "}
                    </label>
                  </div>
                )}
                <div className={css(styles.settingContainer)}>
                  <label className={css(styles.label)}>
                    {" "}
                    Add Insurance
                    <input
                      className={css(styles.checkbox)}
                      type="checkbox"
                      ref={ref => (this.addInsurance = ref)}
                      onChange={this.toggleInsurance}
                    />
                  </label>
                </div>
                {this.state.addInsurance && (
                  <div className={css(styles.settingContainer)}>
                    <label className={css(styles.label)}>
                      {" "}
                      Insured Value ($)
                      <input
                        className={css(styles.weightBox)}
                        value={this.state.insuredValue}
                        onChange={e => {
                          this.setState({
                            insuredValue: e.target.value
                          });
                        }}
                      />{" "}
                    </label>
                  </div>
                )}
              </>
            )}
          </div>
          {this.state.createLabelError && (
            <div className={css(styles.error)}>
              {this.state.createLabelError}
            </div>
          )}
          {!this.state.billToThirdParty && (
            <div className={css(styles.rates)}>{rates}</div>
          )}
          <Button
            text={
              this.state.rateSelectedIndex !== undefined ||
              this.state.billToAccount.length >= 6
                ? "Continue"
                : "Please Choose Shipping Option"
            }
            onClick={this.handleChooseRate}
            disabled={
              this.state.disableChooseShippingOption &&
              this.state.billToAccount.length < 6
            }
          />
        </div>
      </BaseModal>
    );
  }
}

var styles = StyleSheet.create({
  modal: {
    maxWidth: "unset",
    maxHeight: "unset",
    width: "70%",
    zIndex: 999999,
    background: "rgb(249, 249, 249)"
  },
  close: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  title: {
    textAlign: "center"
  },
  checkbox: {
    margin: 0,
    marginLeft: 4,
    height: 20,
    width: 20
  },
  rates: {
    marginTop: 16,
    overflow: "auto",
    width: "100%",
    height: 200
  },
  label: {
    fontSize: 14
  },
  shippingContainer: {
    marginBottom: 16
  },
  chooseShippingLabels: {
    "@media only screen and (min-width: 768px)": {
      height: 600,
      overflow: "auto",
      width: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column"
    }
  },
  weightBox: {
    height: "100%",
    width: 35,
    textAlign: "center",
    margin: "0px 7px"
  },
  textInput: {
    height: "100%",
    width: 100,
    textAlign: "center",
    margin: "0px 7px"
  },
  billTo: {
    marginTop: 16,
    display: "flex",
    flexDirection: "column"
  },
  settingContainer: {
    marginTop: 10
  },
  error: {
    marginTop: 16,
    fontSize: 16,
    color: colors.RED(1)
  },
  shippingInfoWrapper: {
    padding: 20,
    background: "white",
    marginBottom: 30
  },
  shippingInfo: {
    marginLeft: 8,
    marginRight: 8
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  modals: state.modals
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ChooseShippingLabel));
