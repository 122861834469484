import React from "react";
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default ({ error, errorStyles, iconStyles, iconSize = "2x" }) => (
  <div className={css(styles.errorContainer)}>
    {error && (
      <div className={css(styles.error, errorStyles)}>
        <FontAwesomeIcon
          icon={["fas", "exclamation"]}
          size={iconSize}
          className={css(styles.exclamation, iconStyles)}
        />
        {error}
      </div>
    )}
  </div>
);

const styles = StyleSheet.create({
  errorContainer: {
    borderRadius: "4px",
    width: "100%"
  },
  error: {
    background: "#f5e1de",
    padding: "10px",
    "overflow-wrap": "break-word",
    wordBreak: "break-word",
    color: "#676870",
    display: "flex",
    alignItems: "center",
    borderRadius: "4px"
  },
  exclamation: {
    color: "#fff",
    background: "#E7665C",
    padding: "5px 15px",
    borderRadius: "4px",
    marginRight: "10px"
  }
});
