/***
 * Consignment screen for Solestage
 * @patr -- patrick@quantfive.org
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { FormattedMessage, injectIntl } from "react-intl";
import TimeAgo from "javascript-time-ago";
import numeral from "numeral";

// Components
import ApprovePriceChangeModal from "../../components/modals/ApprovePriceChangeModal";
import Searchbar from "../../components/inventory/Searchbar";
import Dropdown from "../../components/inventory/Dropdown";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Redux
import { ModalActions } from "../../redux/modals";
import { JoyrideActions } from "../../redux/joyride";
import { InventoryActions } from "../../redux/inventory";

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// Create relative date/time formatter.
// const timeAgo = new TimeAgo("en-US");

class PriceChange extends React.Component {
  constructor(props) {
    super(props);

    let { joyride, intl, locationId } = this.props;

    this.tabs = [
      {
        id: "pending",
        label: intl.formatMessage({
          id: "pending",
          defaultMessage: "Pending"
        })
      },
      {
        id: "approved",
        label: intl.formatMessage({
          id: "approved",
          defaultMessage: "Approved"
        })
      },
      {
        id: "all",
        label: intl.formatMessage({
          id: "all_request",
          defaultMessage: "All Requests"
        })
      }
    ];

    this.guideData = [
      {
        Consigner: "Johnny Champ",
        "Requested By": "Johnny Champ",
        Consignment: "#76",
        Date: "a month ago",
        Location: "Fairfax",
        Price: "$900",
        Quantity: 1,
        Status: joyride.feature === "list_consignment" ? "approved" : "pending",
        items: [
          {
            active: true,
            clover_id: null,
            condition: "New",
            consignment_order: 76,
            cost: null,
            created_at: "2018-11-03T12:53:38.336007-07:00",
            id: 148660,
            image_link:
              "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg",
            in_transit: false,
            labelled: false,
            listable: false,
            listed: false,
            location: null,
            price: null,
            shelf: "",
            sold: false,
            sold_at: null,
            consigner: {
              billing_address: null,
              consignment_approved: true,
              consignment_rate: 15,
              created_at: "2018-09-24T17:55:32.379915-07:00",
              email: "cu@quantfive.org",
              first_name: "Johnny",
              id: 15,
              last_name: "Champ",
              phone_number: "8502944844",
              return_address: null,
              shipping_address: {
                id: 14,
                state: "CA",
                country: "USA",
                city: "Los Angeles",
                zip_code: "94709"
              },
              updated_at: "2018-11-07T00:00:04.717238-08:00",
              user: 21,
              verified_consigner: true
            },
            requested_by: {
              email: "cu@quantfive.org",
              first_name: "Johnny",
              last_name: "Champ",
              user: 21
            },
            consignment: {
              apparel_package_condition: "",
              barcode_printed: false,
              box_condition: "Good Condition",
              clover_item_id: "",
              created_at: "2018-11-03T12:58:53.342228-07:00",
              defects: false,
              discolored: false,
              id: 206,
              missing_insoles: false,
              missing_tags: false,
              order: 77,
              payout: null,
              pending_price: null,
              pictures: [],
              price: {
                id: 201,
                default_price: 90000,
                default_currency: "usd",
                solestage_price: 90000,
                instore_price: 90000
              },
              scuff_marks: false,
              size: "10",
              status: "approved",
              tears: false,
              updated_at: "2018-11-03T13:11:19.096066-07:00",
              yellowing: false
            },
            pictures: [
              {
                id: 20504,
                sku: 20549,
                url:
                  "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg"
              }
            ],
            updated_at: "2018-11-03T12:55:28.734470-07:00",
            sku: {
              active: true,
              barcode: "12345678999",
              brand: "Adidas",
              color: "Beluga",
              created_at: "2018-11-09T08:44:04.436342-08:00",
              default_location: null,
              eu_size: null,
              gender: "M",
              id: 20549,
              item_type: "shoe",
              product_name: "Adidas Yeezy Boost",
              product_name_us: "Adidas Yeezy Boost",
              sku: null,
              style: "508214-660",
              updated_at: "2018-11-09T08:44:04.781280-08:00",
              us_size: "10"
            }
          }
        ],
        request_id: 76
      }
    ];

    this.state = {
      data: [],
      numPages: 1,
      page: 1,
      pageSize: 20,
      hoveredRow: null,
      activeTab:
        joyride.feature === "list_consignment" ? "approved" : "pending",
      count: null,
      barcodes: [],
      search: "",
      location: locationId
    };
  }

  /***
   * Searches the backend for SKU, Barcode, Product Name
   */
  search = async e => {
    if (e.key === "Enter") {
      this.setState({
        page: 1,
        searching: true
      });
      if (this.state.activeTab === "pending" && !this.state.search) {
        await this.getPriceChangeRequest(1, this.state.pageSize, "pending");
      } else if (this.state.activeTab === "approved" && !this.state.search) {
        await this.getPriceChangeRequest(1, this.state.pageSize, "approved");
      } else {
        await this.getPriceChangeRequest();
      }
      this.setState({
        searching: false
      });
    }
  };

  /***
   * Gets all price change requests
   */
  getPriceChangeRequest = (page, pageSize, status) => {
    // let { auth } = this.props;
    let orderParams = {
      page,
      pageSize,
      status
    };
    // if (!["Admin", "Warehouse Manager"].includes(auth.userProfile.role)) {
    //   orderParams["dropoff_location"] = auth.userProfile.location.id;
    // }

    // if (status === "all" || this.state.activeTab === "all") {
    //   delete orderParams.status;
    // } else if (this.state.activeTab === "approved") {
    //   orderParams.status = "approved";
    // } else {
    //   orderParams.status = "pending";
    // }

    if (this.state.search) {
      orderParams["search"] = this.state.search;
    }

    if (this.state.location) {
      orderParams["location"] = this.state.location;
    }

    this.setState({
      searching: true
    });

    return fetch(API.CONSIGNMENT_PRICE(orderParams), API.GET_CONFIG(false))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = [];
        this.setState({
          searching: false
        });
        if (res.results) {
          data = res.results.map((priceChangeRequest, index) => {
            // TODO: get the consigner user returning from backend,
            // let quantity = consignmentOrder.consignment_items.length;
            // let total_price = 0;
            // for (let i = 0; i < quantity; i++) {
            //   // Transition from cents to dollars
            //   let price =
            //     consignmentOrder.consignment_items[i].consignment.price;
            //   total_price += price ? price.default_price / 100 : null;
            // }
            return {
              Request: `#${priceChangeRequest.id}`,
              request_id: priceChangeRequest.id,
              consignment: priceChangeRequest.consignment,
              Date: new Date(priceChangeRequest.updated_at).toLocaleDateString(
                "en-US"
              ),
              Location:
                priceChangeRequest.location && priceChangeRequest.location.name,
              Consigner: priceChangeRequest.consigner
                ? priceChangeRequest.consigner.first_name +
                  " " +
                  priceChangeRequest.consigner.last_name
                : null,
              "Requested By": priceChangeRequest.requested_by
                ? priceChangeRequest.requested_by.first_name +
                  " " +
                  priceChangeRequest.requested_by.last_name
                : null,
              Status: priceChangeRequest.status,
              // Quantity: quantity,
              "New Price": numeral(
                priceChangeRequest.price.default_price / 100
              ).format("$0,0"),
              "Current Price": numeral(
                priceChangeRequest.consignment.price.default_price / 100
              ).format("$0,0"),
              "Price When Requested": priceChangeRequest.old_price
                ? numeral(
                    priceChangeRequest.old_price.default_price / 100
                  ).format("$0,0")
                : "Not available"
            };
          });
        } else {
          // let quantity = res.consignment_items.length;
          // let total_price = 0;
          // for (let i = 0; i < quantity; i++) {
          //   // Transition from cents to dollars
          //   let price = res.consignment_items[i].consignment.price;
          //   total_price += price ? price.default_price / 100 : null;
          // }
          data = [
            {
              Request: `#${res.id}`,
              request_id: res.id,
              consignment: res.consignment,
              Date: new Date(res.updated_at).toLocaleDateString("en-US"),
              Consigner: res.consigner ? res.consigner.email : null,
              "Requested By": res.requested_by ? res.requested_by.email : null,
              Status: res.status,
              // Quantity: quantity,
              "New Price": numeral(res.price.default_price / 100).format(
                "$0,0"
              ),
              "Current Price": numeral(
                res.consignment.price.default_price / 100
              ).format("$0,0"),
              "Price When Requested": res.old_price
                ? numeral(res.old_price.default_price / 100).format("$0,0")
                : "Not available"
            }
          ];
        }
        this.setState({
          numPages: Math.ceil(res.count / this.state.pageSize),
          count: res.count,
          data
        });
      });
  };

  onLocationChange = e => {
    let value = e.target.value;
    if (value === "0") {
      value = null;
    }
    this.setState(
      {
        location: value,
        page: 1
      },
      () => {
        this.getPriceChangeRequest(
          1,
          this.state.pageSize,
          this.state.activeTab
        );
        this.props.setLocation(parseInt(value));
      }
    );
  };

  /***
   * Sets the search value
   */
  searchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    this.setState(
      {
        activeTab: tab,
        page: 1
      },
      () => {
        let status = null;
        if (tab === "pending") {
          status = "pending";
        } else if (tab === "approved") {
          status = "approved";
        }
        this.getPriceChangeRequest(1, this.state.pageSize, status);
      }
    );
  };

  printBarcode = (barcode, item) => {
    let key = Math.random()
      .toString(36)
      .substring(7);
    this.setState({
      barcodes: [...this.state.barcodes, { barcode, item, key }]
    });
  };

  unmount = key => {
    let barcodes = [...this.state.barcodes];
    let index = 0;
    for (let i = 0; i < barcodes.length; i++) {
      if (barcodes[i].key === key) {
        index = i;
        break;
      }
    }

    barcodes.splice(index, 1);

    this.setState({
      barcodes
    });
  };

  /**
   * Processing to show the proper data on approval
   */
  onApproveOrReject = requestId => {
    let data = [...this.state.data];
    if (this.state.activeTab === "pending") {
      for (let i = 0; i < data.length; i++) {
        if (data[i].request_id === requestId) {
          data.splice(i, 1);
          break;
        }
      }
    }

    this.setState({
      count: this.state.count - 1
    });

    this.setState({
      data
    });
  };

  renderCell = (header, props, index) => {
    switch (header) {
      case "Status":
        let fulfilled = props.value === "approved";
        let rejected = props.value === "rejected";
        if (fulfilled) {
          return (
            <div
              className={css(styles.cellValue, styles.status, styles.fulfilled)}
            >
              {props.value}
            </div>
          );
        } else if (rejected) {
          return (
            <div
              className={css(styles.cellValue, styles.status, styles.rejected)}
            >
              {props.value}
            </div>
          );
        } else {
          return (
            <div
              className={css(
                styles.cellValue,
                styles.status,
                styles.unfulfilled
              )}
            >
              {props.value}
            </div>
          );
        }
      default:
        return (
          <div
            className={css(
              styles.cellValue,
              header === "Order" && styles.orderNumber
            )}
          >
            {props.row[index] ? props.row[index].value : props.value}
          </div>
        );
    }
  };

  consignmentClicked = request => {
    let { modalActions } = this.props;
    modalActions.openApprovePriceChangeModal(true, request);
  };

  componentDidMount = () => {
    if (this.props.joyride.forceJoy) {
      let { joyrideActions } = this.props;
      joyrideActions.setRun({ run: true, inProgress: true });
    }
  };

  render() {
    let { intl } = this.props;
    let columns = [
      intl.formatMessage({
        id: "request_tab",
        defaultMessage: "Request"
      }),
      intl.formatMessage({ id: "date", defaultMessage: "Date" }),
      intl.formatMessage({ id: "location", defaultMessage: "Location" }),
      intl.formatMessage({
        id: "consigner",
        defaultMessage: "Consigner"
      }),
      intl.formatMessage({
        id: "requested_by",
        defaultMessage: "Requested By"
      }),
      intl.formatMessage({ id: "status", defaultMessage: "Status" }),
      intl.formatMessage({
        id: "new_price",
        defaultMessage: "New Price"
      }),
      intl.formatMessage({
        id: "current_price",
        defaultMessage: "Current Price"
      }),
      intl.formatMessage({
        id: "old_price",
        defaultMessage: "Price When Requested"
      })
      // intl.formatMessage({ id: "quantity", defaultMessage: "Quantity" })
    ].map((column, index) => ({
      Header: column
        ? column !== "table-id"
          ? column !== "manage"
            ? column
            : null
          : null
        : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      filterable: false,
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => (
        <div
          className={css(
            styles.valueContainer,
            props.row._index === this.state.hoveredRow && styles.hoveredRow
          )}
          id={
            window.location.search.includes("guide=true") &&
            props.row._index === 0 &&
            "clickRow"
          }
          onClick={() => this.consignmentClicked(props.original)}
          onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
        >
          {this.renderCell(props.column.Header, props, index)}
        </div>
      )
    }));

    let tabs = this.tabs.map((tab, index) => {
      return (
        <div
          className={css(
            styles.tab,
            this.state.activeTab === tab.id && styles.activeTab
          )}
          onClick={() => this.setActiveTab(tab.id)}
          key={`tab_${tab.id}_${index}`}
          id={tab.id === "approved" && "approveTab"}
        >
          {tab.label}
        </div>
      );
    });

    // let barcodes = this.state.barcodes.map((dict, index) => {
    //   if (index > 0) {
    //     return null;
    //   }
    //   return (
    //     <BarcodeModal
    //       barcode={dict.barcode}
    //       key={`${dict.key}_${index}`}
    //       unmount={this.unmount}
    //       barcodeKey={dict.key}
    //       name={dict.item.sku.product_name_us}
    //       size={dict.item.sku.us_size}
    //       color={dict.item.sku.color}
    //       price={dict.item.consignment.price.default_price / 100}
    //     />
    //   );
    // });

    return (
      <div className={css(styles.orders)}>
        <h2 className={css(styles.dashboardTitle)}>
          <FormattedMessage
            id="price_tab"
            defaultMessage="Price Change Requests"
          />{" "}
          {this.state.count && `(${this.state.count})`}
        </h2>
        <Dropdown
          onChange={this.onLocationChange}
          default={this.state.location || "0"}
        />
        <div className={css(styles.tabs)}>{tabs}</div>
        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <div className={css(styles.search)} id="search">
            <Searchbar
              search={this.search}
              searchChange={this.searchChange}
              searching={this.state.searching}
            />
          </div>
          <div id="approveConsignment">
            <ReactTable
              className={css(styles.reactTable) + " order-table"}
              id="approveConsignment"
              data={
                window.location.search.includes("guide=true")
                  ? this.guideData
                  : this.state.data
              }
              filterable={true}
              pages={this.state.numPages}
              columns={columns}
              footerClassName={css(styles.tableFooter)}
              defaultPageSize={this.state.pageSize}
              page={this.state.page - 1}
              onPageSizeChange={pageSize => {
                this.setState({
                  pageSize,
                  numPages: Math.ceil(this.state.totalItems / pageSize)
                });
              }}
              onPageChange={pageIndex => {
                this.setState({
                  page: pageIndex + 1
                });
              }}
              minRows={0}
              onFetchData={(state, instance) => {
                if (window.location.search.includes("guide=true")) {
                  return null;
                }
                this.getPriceChangeRequest(
                  state.page + 1,
                  state.pageSize,
                  this.state.activeTab !== "all" && this.state.activeTab
                );
              }}
              nextText={intl.formatMessage({
                id: "next",
                defaultMessage: "Next"
              })}
              previousText={intl.formatMessage({
                id: "previous",
                defaultMessage: "Previous"
              })}
              noDataText={intl.formatMessage({
                id: "no_items_found",
                defaultMessage: "No items found"
              })}
              manual
            />
          </div>
        </div>
        <ApprovePriceChangeModal onApproveOrReject={this.onApproveOrReject} />
        {/* {barcodes} */}
      </div>
    );
  }
}

var styles = StyleSheet.create({
  orders: {
    textAlign: "center",
    padding: 20,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  status: {
    textTransform: "capitalize"
  },
  table: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
    color: "#212b36",
    borderBottom: "1px solid #ddd"
  },
  productColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    whiteSpace: "pre-wrap"
  },
  itemPhoto: {
    width: 75,
    height: 75,
    paddingBottom: 8
  },
  orderNumber: {
    color: colors.LIGHT_BLUE(1)
  },
  hoveredRow: {
    background: "#eee"
  },
  link: {
    textDecoration: "none"
  },
  unfulfilled: {
    background: "#ffea8a",
    padding: "8px 8px",
    borderRadius: 24
  },
  rejected: {
    padding: "8px 8px",
    borderRadius: 24,
    color: "red"
  },
  fulfilled: {
    padding: "8px 8px",
    borderRadius: 24,
    background: "#dfe3e8"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 16,
    position: "relative"
  },
  tab: {
    marginLeft: 7,
    marginRight: 7,
    opacity: ".3",
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  },
  button: {
    width: 200,
    position: "absolute",
    right: 0,
    bottom: 5
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  joyride: state.joyride
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  joyrideActions: bindActionCreators(JoyrideActions, dispatch),
  setLocation: InventoryActions.setLocation
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(PriceChange));
