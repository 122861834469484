/***
 *
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

// Redux
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

// Config
import colors from "../../config/colors";

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      receivedOrder: false,
      order: [],
      fulfillmentStatus: "Unfulfilled",
      shippingLabelUrl: "",
      printShippingLabel: false,
      check: {},
      scanItem: {}
    };
  }

  render() {
    let barcodeStr = "";
    for (let i = 0; i < this.props.order.inventory.sku.barcodes.length; i++) {
      barcodeStr += this.props.order.inventory.sku.barcodes[i].code + " / ";
    }

    return (
      <div
        className={css(
          styles.itemLine,
          this.props.orderIndex + 1 === this.props.orderList.length &&
            styles.noBorderBottom
        )}
      >
        <img
          src={
            this.props.order.inventory.pictures[0] &&
            this.props.order.inventory.pictures[0].url
          }
          className={css(styles.photo, styles.orderLine)}
          alt={this.props.order.inventory.product_name_us}
        />
        <div className={css(styles.nameInfo, styles.orderLine)}>
          <div className={css(styles.productName)}>
            {this.props.order.inventory.sku.product_name_us}
          </div>
          <div className={css(styles.id, styles.nameLine)}>
            ID: {this.props.order.inventory.id}
          </div>
          <div className={css(styles.id, styles.nameLine)}>
            Shopify Order ID: {this.props.order.order.shopify_order_id}
          </div>
          <div className={css(styles.size, styles.nameLine)}>
            Size: {this.props.order.inventory.sku.us_size}
          </div>
          <div className={css(styles.sku, styles.nameLine)}>
            Style: {this.props.order.inventory.sku.product.style}
          </div>
          <div className={css(styles.sku, styles.nameLine)}>
            Barcode:{" "}
            {this.props.order.inventory.consigner
              ? this.props.order.inventory.consigner.email !==
                  "lane+shine@solestage.com" &&
                `ss-${this.props.order.inventory.id}`
              : this.props.order.inventory.sku.barcodes.length > 1
              ? barcodeStr
              : this.props.order.inventory.sku.barcodes.length === 1
              ? `${this.props.order.inventory.sku.barcodes[0].code}`
              : "N/A"}
          </div>
          {this.props.order.inventory.shelf && (
            <div className={css(styles.sku, styles.nameLine)}>
              Shelf: {this.props.order.inventory.shelf}
            </div>
          )}
          {this.props.order.inventory.consigner && (
            <div className={css(styles.sku, styles.nameLine)}>
              Consigner:{" "}
              {this.props.order.inventory.consigner.first_name +
                " " +
                this.props.order.inventory.consigner.last_name}{" "}
              / {this.props.order.inventory.consigner.email}
            </div>
          )}
        </div>
        <div className={css(styles.price)}>
          ${this.props.order.purchased_price / 100}
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  orderTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  orderId: {
    fontSize: 30
  },
  timeTitle: {
    marginLeft: 8,
    marginRight: 8
  },
  OrderDetails: {
    padding: 50,
    background: "rgb(249, 249, 249)",
    height: "100%",
    width: "100%",
    color: "#212b36"
  },
  listPlaceholder: {
    height: 200
  },
  orderBox: {
    borderRadius: 4,
    background: "#fff",
    maxWidth: 600,
    marginBottom: 24,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  placeholderBox: {
    padding: 24
  },
  itemsSection: {
    padding: 24
  },
  price: {
    fontSize: 18
  },
  itemLine: {
    display: "flex",
    alignItems: "center",
    padding: 16,
    borderBottom: "1px solid #eee"
  },
  photo: {
    width: 75,
    height: 75,
    objectFit: "contain"
  },
  nameLine: {
    marginTop: 8,
    opacity: 0.7,
    fontSize: 14
  },
  orderLine: {
    marginRight: 80
  },
  noBorderBottom: {
    borderBottom: 0
  },
  actionBar: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 16
  },
  button: {
    width: 200
  },
  markAsFulfilled: {
    marginRight: 8,
    background: "#fff",
    color: colors.RED(1),
    ":hover": {
      background: "#fff"
    }
  },
  orderInformation: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  goBackLink: {
    color: "#000",
    textDecoration: "none"
  },
  modal: {
    maxWidth: "unset",
    maxHeight: "unset",
    height: "90%",
    width: "70%",
    zIndex: 999999,
    background: "rgb(249, 249, 249)"
  },
  close: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  bodyContainer: {
    padding: 0,
    background: "rgb(249, 249, 249)"
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  modals: state.modals
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrderDetails));
