import React from "react";
import { connect } from "react-redux";

// NPM Modules
import NewWindow from "react-new-window";
import { StyleSheet, css } from "aphrodite";

// Actions
// import { ModalActions } from "../../../redux/modals";

// import "./pageStyle.css";

class PackingSlipModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalPrice: 0,
      totalQuantity: 0,
      items: null
    };
  }

  componentDidMount = () => {
    if (this.props.items) {
      let totalPrice = 0;
      let totalQuantity = 0;
      console.log("items", this.props.items);
      let inventory = this.props.items.map(
        (item, index) => item.fulfillment.status !== "refund" && item.inventory
      );
      // this.props.items.map((item, index) => {
      // 	if (this.props.status[index] == "approved") {
      // 		totalPrice +=
      // 			(item.consignment.price
      // 				? item.consignment.price.default_price
      // 				: 0) / 100;
      // 		totalQuantity++;
      // 	}
      // });
      this.setState(
        {
          totalPrice,
          totalQuantity,
          items: inventory
        },
        () => {
          this.loadPrint = setTimeout(() => {
            let userAgent = navigator.userAgent.toLowerCase();
            if (userAgent.indexOf(" electron/") > -1) {
              // Electron-specific code
              // printContentsSilently();
            } else {
              this.manifest.window && this.manifest.window.print();
            }
            this.props.closePackingSlipModal();
          }, 200);
        }
      );
    }
  };

  render() {
    let shippingAddress = this.props.address;
    return (
      <NewWindow
        onBlock={this.onBlock}
        ref={ref => (this.manifest = ref)}
        onUnload={this.props.closePackingSlipModal}
      >
        <div className={css(styles.manifestWrapper)}>
          <div className={css(styles.title, styles.orderId)}>
            Shopify Order #{this.props.shopify_order_id}
          </div>
          <div className={css(styles.orderInfoWrapper)}>
            <div className={css(styles.orderInfoFirst)}>
              <div className={css(styles.customer)}>
                <div className={css(styles.infoTitle)}>SHIP TO</div>
                <div className={css(styles.name)}>
                  {shippingAddress.first_name + " " + shippingAddress.last_name}
                </div>
                <div className={css(styles.name)}>
                  {shippingAddress.address_one} {shippingAddress.address_two}
                </div>
                <div className={css(styles.name)}>
                  {[
                    shippingAddress.city +
                      " " +
                      (shippingAddress.state ? shippingAddress.state : ""),
                    shippingAddress.country,
                    shippingAddress.zip_code
                  ]
                    .filter(el => el && el.trim() !== "")
                    .join(", ")}
                </div>
              </div>
              <div>
                <div className={css(styles.infoTitle)}>SSM ORDER ID</div>
                <div>#{this.props.order_id}</div>
              </div>
            </div>
          </div>
          <div className={css(styles.packingListWrapper)}>
            <div className={css(styles.packingListInfoWrapper)}>
              <div className={css(styles.title, styles.packingTitle)}>
                Packing List
              </div>
              {/* <div className={css(styles.info)}>
								Total Price: ${this.state.totalPrice}
							</div>
							<div className={css(styles.info)}>
								Total Quantity: {this.state.totalQuantity}
							</div> */}
            </div>
            <div>
              <table className={css(styles.tableWrapper)}>
                <tr>
                  <th className={css(styles.tableCell)}>ID</th>
                  <th className={css(styles.tableCell)}>Name</th>
                  <th className={css(styles.tableCell)}>Style</th>
                  <th className={css(styles.tableCell)}>Size</th>
                  <th className={css(styles.tableCell)}>Color</th>
                  <th className={css(styles.tableCell)}>Price</th>
                </tr>
                {this.state.items &&
                  this.state.items.map((item, index) => {
                    return (
                      <tr
                        style={{
                          pageBreakInside: "avoid",
                          pageBreakAfter: "auto"
                        }}
                      >
                        <td className={css(styles.tableCell)}>#{item.id}</td>
                        <td className={css(styles.tableCell)}>
                          {item.sku.product_name_us}
                        </td>
                        <td className={css(styles.tableCell)}>
                          {item.sku.product.style}
                        </td>
                        <td className={css(styles.tableCell)}>
                          {item.sku.us_size}
                        </td>
                        <td className={css(styles.tableCell)}>
                          {item.sku.color}
                        </td>
                        <td className={css(styles.tableCell)}>
                          $
                          {(item.consignment.price
                            ? item.consignment.price.default_price
                            : 0) / 100}
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>

            <div className={css(styles.incInfo)}>
              <hr />
              <div
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  marginBottom: 5
                }}
              >
                Solestage
              </div>
              <div className={css(styles.incInfoText)}>
                17651 Railroad St, City of Industry, CA, 91748, United States
              </div>
              <div className={css(styles.incInfoText)}>info@solestage.com</div>
              <div className={css(styles.incInfoText)}>www.solestage.com</div>
            </div>
          </div>
        </div>
      </NewWindow>
    );
  }
}

var styles = StyleSheet.create({
  manifestWrapper: {
    padding: 30
  },
  title: {
    fontWeight: "bold",
    fontSize: 18
  },
  infoTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 3
  },
  orderInfoWrapper: {
    marginTop: 20,
    fontSize: 13
  },
  orderInfoFirst: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10
  },
  packingListWrapper: {
    marginTop: 30
  },
  packingListInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  packingTitle: {
    fontSize: 15,
    marginRight: 20
  },
  info: {
    fontSize: 12
  },
  tableWrapper: {
    fontSize: 12,
    width: "100%",
    textAlign: "left",
    marginTop: 10,
    borderCollapse: "collapse",
    pageBreakInside: "auto"
  },
  tableCell: {
    border: "1px solid black",
    padding: 3
  },
  incInfo: {
    marginTop: 20
  },
  incInfoText: {
    fontSize: 12,
    marginTop: 3
  }
});

const mapStateToProps = state => ({
  modals: state.modals
});

const mapDispatchToProps = dispatch => ({
  // modalActions: bindActionCreators(ModalActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackingSlipModal);
