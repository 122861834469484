//NPM
import React from "react";
import { StyleSheet, css } from "aphrodite";

const SKUTypes = ({ type, listType, highlight, handleTypeClick }) => {
  let { id, label } = type;
  let style = listType === "product" ? styles.productType : styles.genderType;

  let h_style = listType === "product" ? styles.highlight : styles.highlight2;

  let bool = highlight === id;

  return (
    <div
      className={bool ? css(style, h_style) : css(style)}
      id={style}
      onClick={() => handleTypeClick(id)}
    >
      <p className={css(styles.productTypeText)}>{label}</p>
    </div>
  );
};

let styles = StyleSheet.create({
  genderType: {
    height: 35,
    width: 120,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 5px 0px 5px",
    background: "white",
    border: "1px solid #ddd",
    borderRadius: 3,
    margin: "0px 5px 10px 0px",
    userSelect: "none",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      border: `1px solid rgb(218, 47, 40)`,
      boxShadow: `0px 0px 5px rgb(218, 47, 40)`
    }
  },
  productType: {
    height: 35,
    width: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px 5px 0px 5px",
    background: "white",
    border: "1px solid #ddd",
    borderRadius: 3,
    margin: "0px 5px 10px 0px",
    userSelect: "none",
    cursor: "pointer",
    transition: "all 0.2s ease-in-out",
    ":hover": {
      border: `1px solid rgb(218, 47, 40)`,
      boxShadow: `0px 0px 5px rgb(218, 47, 40)`
    }
  },
  highlight: {
    color: "white",
    border: "1px solid rgb(218, 47, 40)",
    boxShadow: "0px 0px 3px rgb(218, 47, 40)",
    backgroundColor: "rgba(218, 47, 40, 0.8)"
  },
  highlight2: {
    color: "white",
    border: "1px solid rgb(218, 47, 40)",
    boxShadow: "0px 0px 3px rgb(218, 47, 40)",
    backgroundColor: "rgba(218, 47, 40, 0.8)"
  },
  productTypeText: {
    fontFamily: " system-ui",
    fontSize: 14,
    margin: "auto",
    fontWeight: 100
  }
});

export default SKUTypes;
