import React, { Component } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import ReactTable from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import Toggle from "react-toggle";
import Dropzone from "react-dropzone";

// Components
import Filter from "../../components/spreadsheet/Filter";
import ItemManagementModal from "../../components/modals/ItemManagement/ItemManagementModal";
import Searchbar from "../../components/inventory/Searchbar";
import AddProductModal from "../../components/modals/AddProduct/AddProductModal";
import SizeModal from "../../components/modals/ItemManagement/SizeModal";
import AddPictureModal from "../../components/modals/AddPictureModal";
import LoadingIndicator from "../../components/LoadingIndicator";
import SKUModal from "../../components/modals/AddSKU/SKUModal/SKUModal";

// Redux
import { InventoryActions } from "../../redux/inventory";
import { ModalActions } from "../../redux/modals";

// Config
import colors from "../../config/colors";
import API, { getShopifyUrl } from "../../config/api";
import Helpers from "../../redux/helpers";

// Stylesheets
import "react-table/react-table.css";
import "./stylesheets/Table.css";
import "./stylesheets/Alert.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-toggle/style.css"; // for ES6 modules

// Assets
import logo from "../../logo.svg";
import { MessageActions } from "../../redux/message";

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        {
          img: logo,
          sku: "d12390dj030",
          price: "9000",
          units: "10",
          location: "here",
          wow: "yes"
        },
        {
          img: logo,
          sku: "d12390dj030",
          price: "9000",
          units: "10",
          location: "here",
          wow: "yes"
        }
      ],
      numPages: 1,
      uploadError: "",
      uploadingExcel: false,
      uploadComplete: false,
      chosenColumns: [],
      locations: [],
      doubleClicked: {},
      selectedInput: false,
      uploadExcel: false,
      activeTab: "style",
      manageItems: null,
      searching: true,
      totalItems: 0,
      pageSize: 20,
      page: 1,
      search: null,
      imageHover: false,
      saving: false,
      noPhotos: false,
      exportingProducts: false,
      exportingSkus: false
    };
  }

  exportProducts = () => {
    this.setState({
      exportingProducts: true
    });

    fetch(API.PRODUCT_REPORT, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        this.setState({
          message: null,
          exportingProducts: false
        });
        alert(
          `Product report will be sent to ${this.props.auth.userProfile.email}`
        );
        // let link = document.createElementNS(
        //   "http://www.w3.org/1999/xhtml",
        //   "a"
        // );
        // link.href = URL.createObjectURL(res);
        // link.download = `consignments.csv`;
        // link.click();
      });
  };

  exportSkus = () => {
    this.setState({
      exportingSkus: true
    });

    fetch(API.SKU_REPORT, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        this.setState({
          message: null,
          exportingSkus: false
        });
        alert(
          `Sku report will be sent to ${this.props.auth.userProfile.email}`
        );
        // let link = document.createElementNS(
        //   "http://www.w3.org/1999/xhtml",
        //   "a"
        // );
        // link.href = URL.createObjectURL(res);
        // link.download = `consignments.csv`;
        // link.click();
      });
  };

  /**
   * Gets the data depending on which filter we're using
   * @param { int } page -- the page we want to get from backend (index start from 1)
   */
  getData = async ({ page, pageSize, filtered, sorted }) => {
    this.setState({
      searching: true
    });

    let { messageActions } = this.props;
    let noPhotos = this.state.noPhotos;

    messageActions.showMessage({ show: true, load: true });

    if (this.state.locations.length > 0) {
      if (!filtered) {
        filtered = [
          {
            id: "skus",
            value: {
              value: this.state.locations[0],
              type: "inventory__location"
            }
          }
        ];
      } else {
        let extraFilters = [
          {
            id: "skus",
            value: {
              value: this.state.locations[0],
              type: "inventory__location"
            }
          }
        ];
        filtered = [...filtered, ...extraFilters];
      }
    }

    await this.getProductData({
      page,
      search: this.state.search,
      pageSize,
      sorted,
      filtered,
      noPhotos
    });

    messageActions.showMessage({ show: false });

    this.setState({
      searching: false
    });
  };

  /**
   * Sets up the product data by adding a manage button and also a count #
   */
  setupProductData = (row, index, page) => {
    let item = {
      "table-id": (page - 1) * this.state.pageSize + index + 1,
      manage: null
    };

    let extraInfo = {
      ...row
    };

    let picture_url = null;
    // Unfurl the photo link into the row
    if (row.pictures && row.pictures.length > 0) {
      // row.pictures is currently an array with one object if it exists
      picture_url = row.pictures[0].url;
    }

    delete row.goat_extra_info;
    delete row.pictures;
    delete row.product_name;
    delete row.skus;

    return {
      ...item,
      image: picture_url,
      ...row,
      extraInfo
    };
  };

  /**
   * save changes to items
   * @param  {Object} row -- row information being changed
   */
  saveSKUChanges = (row, columnId) => {
    return fetch(
      API.UPDATE_SKUS,
      API.POST_CONFIG({
        sku: row,
        change: columnId,
        oldStyle: columnId === "style" ? this.state.oldStyle : null
      })
    )
      .then(Helpers.checkStatus)
      .then(res => {
        console.log(res);
      });
  };

  /**
   * Renders the product info for the merge SKU
   * @param { Object } product -- the product we want to display
   */
  renderProductInfo = product => {
    return (
      <div className={css(styles.productInfo)}>
        {product.pictures && product.pictures[0] && (
          <div className={css(styles.image)}>
            <img
              alt="product"
              src={product.pictures[0].url}
              className={css(styles.productImg)}
            />
          </div>
        )}
        <div className={css(styles.productContainerInfo)}>
          <div className={css(styles.productName)}>
            {product.product_name_us}
          </div>
          <div className={css(styles.color)}>{product.color}</div>
          <div className={css(styles.brand)}>{product.brand.name}</div>
        </div>
      </div>
    );
  };

  /**
   * Merges two styles together
   * @param { Integer } oldProductId -- the ID of the old style we want to get rid of
   * @param { string } newStyle -- the new style we want this old product to have
   */
  mergeStyles = (oldProductId, newStyle) => {
    let params = {
      style: newStyle
    };
    let { messageActions, intl } = this.props;
    messageActions.showMessage({ show: true, load: true });
    return fetch(
      API.PRODUCT({ merge: true, id: oldProductId }),
      API.DELETE_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(_ => {
        let msg = intl.formatMessage({
          id: "product_merge_success",
          defaultMessage: "Product Successfully Merged!"
        });
        this.refresh();
        messageActions.showMessage({ show: false });
        messageActions.setMessage(msg);
        messageActions.showMessage({ show: true });
      })
      .catch(_ => {
        let msg = intl.formatMessage({
          id: "something_wrong_try_again",
          defaultMessage: "Something went wrong, try again later."
        });
        messageActions.showMessage({ show: false });
        messageActions.setMessage(msg);
        messageActions.showMessage({ show: true, error: true });
      });
  };

  /**
   * Toggles featured on shine true / false
   * @param { Event } e -- the event
   * @param { Object } row -- the row which we want to feature on Shine
   */
  featuredOnShine = (e, row) => {
    let checked = e.target.checked;
    row.featured_on_shine = checked;
    this.saveProductChanges(row);
  };

  /**
   * Excel file upload to the backend. Currently uploads the inventory file.
   */
  onDrop = (acceptedFiles, rejectedFiles) => {
    let { intl } = this.props;
    this.setState({
      uploadingExcel: true,
      uploadError: ""
    });
    if (acceptedFiles.length === 0) {
      this.setState({
        uploadError: intl.formatMessage({
          id: "excel_upload_error",
          defaultMessage: "There was an error uploading your file!"
        })
      });
      return null;
    }

    let formData = new FormData();
    formData.append("file", acceptedFiles[0]);
    return fetch(API.IMPORT_SKU_EXCEL, API.POST_FORM_DATA_CONFIG(formData))
      .then(Helpers.checkStatus)
      .then(Helpers.checkJSON)
      .then(json => {
        this.setState({
          uploadingExcel: false,
          uploadComplete: true
        });

        // eslint-disable-next-line
        let uploadTimeout = setTimeout(() => {
          this.setState({
            uploadComplete: true
          });
        }, 2000);
      })
      .catch(error => {
        let { messageActions } = this.props;
        this.setState({
          uploadingExcel: false
        });

        let message = "Something went wrong!";
        if (error.message) {
          message = error.message;

          if (error.message.detail) {
            message = error.message.detail;
          }
        }
        messageActions.setMessage(message);
        messageActions.showMessage({ show: true, error: true });
      });
  };

  /**
   * Save changes to products
   * @param  {Object} row -- row information being changed
   */
  saveProductChanges = row => {
    let params = { ...row };
    return fetch(API.PRODUCT({ id: row.id }), API.PATCH_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(_ => {})
      .catch(error => {
        // if (
        //   error.message &&
        //   error.message.style.includes(
        //     "product with this style already exists."
        //   )
        // ) {
        fetch(API.PRODUCT({ style: row.style }), API.GET_CONFIG())
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON)
          .then(res => {
            // intl.formatMessage({
            //   id: 'product_style_exists_msg',
            //   defaultMessage: 'Another Product Exists With This Style',
            // });

            let product = res.results[0];
            confirmAlert({
              title: `${row.style} Already Exists`,
              message: "Are you sure you want to merge the styles?",
              childrenElement: () => {
                row.pictures = row.extraInfo.pictures;
                return (
                  <div className={css(styles.confirmAlert)}>
                    {this.renderProductInfo(row)}
                    <FontAwesomeIcon
                      icon={["far", "long-arrow-alt-down"]}
                      size={"3x"}
                    />
                    {this.renderProductInfo(product)}
                  </div>
                );
              },
              buttons: [
                {
                  label: "Yes",
                  onClick: () => {
                    this.mergeStyles &&
                      this.mergeStyles(row.extraInfo.id, row.style);
                  }
                },
                {
                  label: "No",
                  onClick: () => {
                    return null;
                  }
                }
              ]
            });
          })
          .catch(error => {});
        // }
      });
  };

  /**
   * Gets products, unique by style number
   */
  getProductData = ({
    page,
    search,
    pageSize,
    filtered,
    route,
    sorted,
    noPhotos
  }) => {
    let params = {
      page,
      size: pageSize,
      filtered,
      route,
      ordering: "-id"
    };

    if (search && search.includes("sk-")) {
      params["id"] = search.substring(3, search.length);
    } else {
      params["search"] = search;
    }

    if (sorted && sorted[0]) {
      params.ordering = (sorted[0].desc ? "" : "-") + sorted[0].id;
    }

    if (noPhotos) {
      if (params.filtered) {
        params.filtered.push({
          id: "pictures",
          value: { value: true, type: "isnull" }
        });
      } else {
        params.filtered = [
          { id: "pictures", value: { value: true, type: "isnull" } }
        ];
      }
    }

    return fetch(API.PRODUCT(params), API.GET_CONFIG(false))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = res.results;
        data = data.map((row, index) => {
          return this.setupProductData(row, index, page);
        });
        let chosenColumns = [];

        if (data.length > 0) {
          let tempColumns = Object.keys(data[0]);
          let index = tempColumns.indexOf("id");
          if (index !== -1) {
            tempColumns.splice(index, 1);
          }
          index = tempColumns.indexOf("extraInfo");
          if (index !== -1) {
            tempColumns.splice(index, 1);
          }

          let featuredOnShineIndex = tempColumns.indexOf("featured_on_shine");
          tempColumns[featuredOnShineIndex] = tempColumns[7];
          tempColumns[7] = "featured_on_shine";

          chosenColumns = tempColumns;
        }

        this.setState({
          data: data,
          totalItems: res.count,
          numPages: Math.ceil(res.count / this.state.pageSize),
          chosenColumns
        });
      });
  };

  componentDidUpdate() {
    if (this.input && this.state.selectedInput) {
      this.input.select();
      this.setState({
        selectedInput: false
      });
    }
  }

  /**
   * Handles double clicking on a cell
   * Saves row, column, and value information for clicked cell
   * Triggers input to show, allowing user to change values
   * @param  {Object} props -- object containing the properties of a cell
   */
  handleDblClick = props => {
    let { modalActions } = this.props;
    if (props.column.Header) {
      if (props.column.id === "image") {
        let row = {
          index: props.row._index,
          style: this.state.data[props.row._index].style
        };
        modalActions.openAddPictureModal(true, row);
      }
      this.setState({
        doubleClicked: {
          rowIndex: props.row._index,
          columnId: props.column.id,
          value: props.value
        },
        oldStyle: props.column.id === "style" ? props.value : null,
        selectedInput: true
      });
    }
  };

  /***
   * Handles image uploading
   */
  imageUpload = props => {
    let { modalActions } = this.props;
    if (props.column.id === "image") {
      let row = {
        index: props.row._index,
        style: this.state.data[props.row._index].style
      };
      modalActions.openAddPictureModal(true, row);
    }
  };

  /**
   * Save changes on frontend when cell information is changed
   * @param  {Event} e -- change event that triggers when a cell is changed
   */
  onCellChange = e => {
    clearTimeout(this.onCellChangeTimeout);
    let doubleClicked = { ...this.state.doubleClicked };
    let data = [...this.state.data];
    doubleClicked.value = e.target.value;
    data[doubleClicked.rowIndex][doubleClicked.columnId] = e.target.value;
    this.onCellChangeTimeout = setTimeout(async () => {
      let { messageActions } = this.props;
      messageActions.setMessage("");
      messageActions.showMessage({ show: true, load: true });
      await this.saveProductChanges(
        data[doubleClicked.rowIndex],
        doubleClicked.columnId
      );
      messageActions.showMessage({ show: false });
    }, 600);
    this.setState({
      doubleClicked: doubleClicked,
      data: data
    });
  };

  /**
   * Handle single click on table, clicking outside input box makes it dissapear
   * @param  {Object} props -- clicked cell information
   */
  handleOnClick = event => {
    if (this.input !== document.activeElement) {
      this.setState({
        doubleClicked: {}
      });
    }
  };

  /***
   * Opens the add product modal
   */
  addSkuModal = () => {
    let { modalActions } = this.props;
    modalActions.openAddSKUModal(true);
  };

  /***
   * Sets the search value
   */
  searchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  /***
   * Searches the backend for SKU, Barcode, Product Name
   */
  search = async e => {
    if (e.key === "Enter") {
      this.setState({
        page: 1,
        searching: true
      });
      await this.getData({
        page: 1,
        pageSize: this.state.pageSize,
        filtered: this.state.filtered,
        sorted: this.state.sorted
      });
      this.setState({
        searching: false
      });
    }
  };

  /***
   * Closes the upload container
   */
  closeDropzone = e => {
    e.stopPropagation();
    this.setState({
      uploadExcel: false,
      uploadError: "",
      uploadComplete: false,
      uploadingExcel: false
    });
  };

  /**
   * open item management modal and save clicked row information to state
   * @param  {Object} props -- object with clicked cell information
   */
  // openModal = props => {
  //   let { modalActions } = this.props;
  //   modalActions.openItemManagementModal(true);
  //   let row = { ...props.original };
  //   this.setState({
  //     manageItems: row,
  //     doubleClicked: {}
  //   });
  // };

  openModal = props => {
    let { modalActions } = this.props;
    let row = { ...props.original };
    row.edit = true;
    modalActions.openAddSKUModal(true, false, row);
  };

  /***
   * Opens the size modal
   * @param { props } -- the object with clicked cell information
   */
  openSizeModal = props => {
    let { modalActions } = this.props;
    let us_sizes = [...props.original.extraInfo.us_sizes];
    modalActions.openSizeModal(
      true,
      us_sizes,
      props.original.extraInfo.item_type,
      props.original.style
    );
  };

  /***
   * Change item price in state on price update
   * @params int price -- the price in cents
   * @params int tableId -- the id in the table that we need to update
   * @params string size -- the size of the shoe price we're updating
   */
  priceUpdate = (price, tableId, size) => {
    let data = [...this.state.data];
    data[tableId - 1].extraInfo.price_by_us_size[size] = price;
    this.setState({
      data
    });
  };

  /**
   * When the filter changes, update the table
   * @param { String } filterValue -- the value of the filter
   * @param { String } filterType -- the value of the type
   * @param { Func } onChange the callback on change
   */
  filterOnChange = (filterValue, filterType, onChange) => {
    let value = { value: filterValue, type: filterType };

    onChange(value);
  };

  updatePicture = (index, url) => {
    let data = [...this.state.data];
    data[index].image = url;
    this.setState({
      data: data
    });
  };

  onPhotoEnter = props => {
    if (props.column.Header) {
      if (
        props.column.id === "image" &&
        props.row.image !== "" &&
        props.row.image !== null
      ) {
        this.setState({
          imageHover: { row: props.row._index }
        });
      }
    }
  };

  /**
   * Refreshes the data
   */
  refresh = async () => {
    this.setState({
      saving: true
    });
    await this.getData({
      page: this.state.page,
      search: this.state.search,
      pageSize: this.state.pageSize,
      filtered: this.state.filtered,
      sorted: this.state.sorted
    });
    this.setState({
      saving: false
    });
  };

  onPhotoLeave = props => {
    if (props.column.Header) {
      if (props.column.id === "image") {
        this.setState({
          imageHover: {}
        });
      }
    }
  };

  deleteImage = (e, props) => {
    e.stopPropagation();
    let data = [...this.state.data];
    data[props.row._index].image = "";
    fetch(API.DEACTIVATE_PICTURES, API.POST_CONFIG({ style: props.row.style }))
      .then(Helpers.checkStatus)
      .then(res => {
        this.setState({
          data: data,
          imageHover: {}
        });
      });
  };

  /**
   * Get products with no photos
   */
  noPhotos = e => {
    let checked = e.target.checked;
    this.setState(
      {
        noPhotos: checked
      },
      () => {
        this.getData({ page: 1 });
      }
    );
  };

  toggleLocation = (e, locationId) => {
    let checked = e.target.checked;
    let locations = [];
    if (checked) {
      locations = [...locations, locationId];
    } else {
      locations.splice(locations.indexOf(locationId), 1);
    }

    this.setState(
      {
        locations
      },
      () => {
        this.getData({
          page: 1,
          pageSize: this.state.pageSize,
          filtered: this.state.filtered,
          sorted: this.state.sorted
        });
      }
    );
  };

  render() {
    let { intl, auth } = this.props;
    let chosenColumns = this.state.chosenColumns;
    let columns = chosenColumns.map((column, index) => ({
      Header: column
        ? column !== "table-id"
          ? column !== "manage"
            ? column
            : null
          : null
        : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      Filter: ({ filter, onChange }) => (
        <Filter
          onChange={(type, value) => this.filterOnChange(type, value, onChange)}
          key={this.state.activeFolderName + index}
        />
      ),
      filterable: index === 0 || index === 1 ? false : true,
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => (
        <div
          className={css(styles.valueContainer)}
          onClick={() => this.imageUpload(props)}
          onDoubleClick={() => this.handleDblClick(props)}
          onMouseEnter={() => this.onPhotoEnter(props)}
          onMouseLeave={() => this.onPhotoLeave(props)}
        >
          {this.state.imageHover.row === props.row._index &&
            props.column.Header === "image" && (
              <FontAwesomeIcon
                icon={["far", "times"]}
                className={css(styles.timesIcon)}
                onClick={e => this.deleteImage(e, props)}
              />
            )}
          {props.column.Header === "sizes" ? (
            <button
              className={css(styles.manageItems)}
              onClick={() => this.openSizeModal(props)}
            >
              <FormattedMessage
                id="check_sizes_button"
                defaultMessage="Check Sizes"
              />
            </button>
          ) : props.column.Header === "featured_on_shine" ? (
            <div>
              <Toggle
                defaultChecked={props.value}
                onChange={e => this.featuredOnShine(e, props.original)}
              />
            </div>
          ) : props.column.Header === "price" ? (
            <div
              className={css(
                styles.cellValue,
                props.column.Header === null && styles.rowNumber
              )}
            />
          ) : props.column.Header === "image" ? (
            props.value ? (
              <img
                className={css(styles.img)}
                src={props.value}
                alt={props.value}
              />
            ) : (
              <FormattedMessage id="no_image_found" defaultMessage="No Image" />
            )
          ) : props.column.Header === "shopify_product_id" ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${getShopifyUrl()}/admin/products/${props.value}`}
            >
              {props.value}
            </a>
          ) : props.column.Header === "brand" ? (
            <div
              className={css(
                styles.cellValue,
                props.column.Header === null && styles.rowNumber
              )}
            >
              {props.value ? props.value.name : null}
            </div>
          ) : props.column.Header === null && props.value === null ? (
            <button
              className={css(styles.manageItems)}
              onClick={() => this.openModal(props)}
            >
              <FormattedMessage
                id="table_manage_button"
                defaultMessage="Manage"
              />
            </button>
          ) : props.row._index === this.state.doubleClicked.rowIndex &&
            props.column.id === this.state.doubleClicked.columnId ? (
            <input
              className={css(styles.input)}
              value={this.state.doubleClicked.value}
              onChange={this.onCellChange}
              ref={ref => {
                this.input = ref;
              }}
              autoFocus
            />
          ) : (
            <div
              className={css(
                styles.cellValue,
                props.column.Header === null && styles.rowNumber
              )}
            >
              {props.row[index] ? props.row[index].value : props.value}
            </div>
          )}
        </div>
      )
    }));

    return (
      <div className={css(styles.dashboard)}>
        <div className={css(styles.header)}>
          <h2 className={css(styles.dashboardTitle)}>
            <FormattedMessage
              id="sku_management"
              defaultMessage="Product Management"
            />
            {["admin", "accountant", "warehouse manager"].includes(
              auth.userProfile.role.toLowerCase()
            ) && (
              <>
                <button
                  id="export"
                  className={css(styles.export)}
                  onClick={() => this.exportProducts()}
                >
                  <FontAwesomeIcon
                    icon={["fas", "file-export"]}
                    className={css(styles.fileIcon)}
                  />
                  {this.state.exportingProducts ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.fileIcon)}
                      spin
                    />
                  ) : (
                    <FormattedMessage
                      id="export_products"
                      defaultMessage="Export Products"
                    />
                  )}
                </button>
                <button
                  id="export"
                  className={css(styles.export)}
                  onClick={() => this.exportSkus()}
                >
                  <FontAwesomeIcon
                    icon={["fas", "file-export"]}
                    className={css(styles.fileIcon)}
                  />
                  {this.state.exportingSkus ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.fileIcon)}
                      spin
                    />
                  ) : (
                    <FormattedMessage
                      id="export_products"
                      defaultMessage="Export Skus"
                    />
                  )}
                </button>
              </>
            )}
          </h2>
          <div className={css(styles.actionContainer)}>
            {this.state.uploadExcel && (
              <Dropzone
                className={css(
                  styles.dropzone,
                  this.state.uploadComplete && styles.dropzoneComplete
                )}
                onDrop={this.onDrop}
                accept={[
                  "text/plain",
                  "application/vnd.ms-excel",
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                  ".xls",
                  ".xlsx",
                  ".csv"
                ]}
              >
                {this.state.uploadError ? (
                  this.state.uploadError
                ) : this.state.uploadComplete ? (
                  <FontAwesomeIcon
                    icon={["far", "check-circle"]}
                    className={css(styles.spinner)}
                    color={"#fff"}
                  />
                ) : this.state.uploadingExcel ? (
                  <FontAwesomeIcon
                    icon={["far", "spinner-third"]}
                    className={css(styles.spinner)}
                    spin
                  />
                ) : (
                  <FormattedMessage
                    id="drag_and_drop"
                    defaultMessage="Drag & Drop or Click to Upload"
                  />
                )}
                <FontAwesomeIcon
                  icon={["far", "times"]}
                  className={css(styles.times)}
                  onClick={this.closeDropzone}
                />
              </Dropzone>
            )}
          </div>
        </div>
        <div className={css(styles.table)}>
          <div className={css(styles.body)}>
            <div className={css(styles.topBar)}>
              <div className={css(styles.search)}>
                <Searchbar
                  search={this.search}
                  searchChange={this.searchChange}
                />
              </div>
              <div className={css(styles.utilities)}>
                <label className={css(styles.shineProducts)}>
                  <Toggle defaultChecked={false} onChange={this.noPhotos} />
                  <span>
                    <FormattedMessage
                      id="no_photos"
                      defaultMessage="No Photos"
                    />
                  </span>
                </label>

                <button
                  className={css(styles.export, styles.addProduct, styles.save)}
                  onClick={this.refresh}
                >
                  <FontAwesomeIcon
                    icon={["fas", "sync"]}
                    className={css(styles.fileIcon)}
                  />
                  <FormattedMessage id="refresh" defaultMessage="Refresh" />
                </button>
                <button
                  className={css(styles.export, styles.addProduct)}
                  onClick={this.addSkuModal}
                >
                  <FontAwesomeIcon
                    icon={["far", "plus"]}
                    className={css(styles.fileIcon)}
                  />
                  <FormattedMessage id="add_product" defaultMessage="Add Sku" />
                </button>
              </div>
            </div>
            <div className={css(styles.lowerBar)}>
              <label className={css(styles.shineProducts, styles.toggle)}>
                <Toggle
                  checked={this.state.locations.includes(1)}
                  defaultChecked={false}
                  onChange={e => this.toggleLocation(e, 1)}
                />
                <span>Fairfax</span>
              </label>
              <label className={css(styles.shineProducts, styles.toggle)}>
                <Toggle
                  checked={this.state.locations.includes(2)}
                  defaultChecked={false}
                  onChange={e => this.toggleLocation(e, 2)}
                />
                <span>Irvine</span>
              </label>
              <label className={css(styles.shineProducts, styles.toggle)}>
                <Toggle
                  checked={this.state.locations.includes(5)}
                  defaultChecked={false}
                  onChange={e => this.toggleLocation(e, 5)}
                />
                <span>NYC</span>
              </label>
              <label className={css(styles.shineProducts, styles.toggle)}>
                <Toggle
                  checked={this.state.locations.includes(4)}
                  defaultChecked={false}
                  onChange={e => this.toggleLocation(e, 4)}
                />
                <span>Warehouse</span>
              </label>
            </div>

            <ReactTable
              className={css(styles.reactTable)}
              data={this.state.data}
              filterable={true}
              pages={this.state.numPages}
              columns={columns}
              footerClassName={css(styles.tableFooter)}
              defaultPageSize={this.state.pageSize}
              page={this.state.page - 1}
              onPageSizeChange={pageSize => {
                this.setState({
                  pageSize,
                  numPages: Math.ceil(this.state.totalItems / pageSize)
                });
              }}
              onPageChange={pageIndex => {
                this.setState({
                  page: pageIndex + 1
                });
              }}
              minRows={0}
              onFetchData={(state, instance) => {
                this.setState({
                  page: state.page + 1,
                  pageSize: state.pageSize,
                  filtered: state.filtered,
                  sorted: state.sorted
                });
                this.getData({
                  page: state.page + 1,
                  pageSize: state.pageSize,
                  filtered: state.filtered,
                  sorted: state.sorted
                });
              }}
              nextText={intl.formatMessage({
                id: "next",
                defaultMessage: "Next"
              })}
              previousText={intl.formatMessage({
                id: "previous",
                defaultMessage: "Previous"
              })}
              noDataText={intl.formatMessage({
                id: "no_items_found",
                defaultMessage: "No items found"
              })}
              manual
            />
          </div>
        </div>
        {this.state.manageItems && (
          <ItemManagementModal
            items={this.state.manageItems}
            priceUpdate={this.priceUpdate}
          />
        )}
        <AddProductModal
          inventoryPushCallback={() =>
            this.getData({
              page: this.state.page,
              pageSize: this.state.pageSize,
              filtered: this.state.filtered,
              sorted: this.state.sorted
            })
          }
        />
        <SKUModal refresh={this.refresh} />
        <SizeModal />
        <AddPictureModal
          data={this.state.data}
          row={this.state.doubleClicked}
          updatePicture={this.updatePicture}
        />
        {this.state.saving && (
          <LoadingIndicator
            fadeIn="quarter"
            showing={true}
            text="Refreshing..."
            outerClass={styles.loading}
            full
          />
        )}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  dashboard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "rgb(249, 249, 249)",
    minHeight: "100vh",
    padding: "30px 30px",
    boxSizing: "border-box"
    // height: 'calc(100% - 180px)',
  },
  loading: {
    position: "fixed",
    top: 0,
    left: 0
  },
  header: {
    // background: '#fff',
    // boxShadow: 'rgba(129, 148, 167, 0.39) 0px 3px 10px 0px',
    marginBottom: 24
  },
  cell: {
    padding: 0,
    borderColor: "#eee",
    borderBottom: "solid 1px #eee",
    maxHeight: 100
  },
  confirmAlert: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  cellValue: {
    textAlign: "center",
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  link: {
    textDecoration: "none",
    color: "#000"
  },
  shineProducts: {
    marginRight: 12,
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  toggle: {
    marginRight: 16
  },
  input: {
    width: "100%",
    height: "100%",
    border: "none",
    textAlign: "center",
    fontSize: 16,
    padding: "auto"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    position: "relative"
  },
  img: {
    width: 80,
    height: 80,
    objectFit: "contain",
    cursor: "pointer"
  },
  lowerBar: {
    display: "flex",
    marginLeft: "auto",
    marginTop: 16,
    marginBottom: 16
  },
  uploadButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    background: colors.RED(1),
    color: "#fff",
    fontSize: 18,
    cursor: "pointer",
    borderRadius: 4,
    border: `1px solid ${colors.RED(1)}`,
    outline: "none",
    width: 150,

    ":hover": {
      background: "#fff",
      border: `1px solid ${colors.RED(1)}`,
      color: colors.RED(1)
    }
  },
  dropzone: {
    width: "100%",
    height: 300,
    border: "2px dashed #000",
    zIndex: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 30,
    cursor: "pointer",
    position: "relative",
    transition: ".5s ease-in-out",

    ":hover": {
      background: "rgba(0, 0, 0, .2)"
    }
  },
  productInfo: {
    display: "flex",
    alignItems: "center",
    margin: 16,
    justifyContent: "center",
    padding: 16,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    borderRadius: 8
  },
  productImg: {
    height: 100,
    marginRight: 32
  },
  dropzoneComplete: {
    border: `2px solid ${colors.LIGHT_BLUE(1)}`,
    height: 100,
    background: colors.LIGHT_BLUE(1),

    ":hover": {
      background: colors.LIGHT_BLUE(0.8)
    }
  },
  times: {
    position: "absolute",
    top: 0,
    right: 0,
    padding: 15,
    transition: ".3s ease-in-out",
    ":hover": {
      transform: "scale(1.3)"
    }
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16
  },
  utilities: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    justifyContent: "flex-end"
  },
  search: {
    width: "50%"
    // boxShadow: 'rgba(129, 148, 167, 0.39) 0px 3px 10px 0px',
  },
  export: {
    marginLeft: 8,
    display: "flex",
    alignItems: "center",
    // padding: 8,
    height: 36,
    borderRadius: 4,
    padding: 8,
    border: "1px solid #ddd",
    background: "rgb(245, 245, 245)",
    cursor: "pointer",
    outline: "none",
    fontSize: 14,
    letterSpacing: 0.7,

    ":hover": {
      transform: "scale(1.05)",
      "-webkit-transition": ".5s",
      transition: ".5s"
    }
  },
  addProduct: {
    background: colors.RED(1),
    color: "#fff",
    borderColor: colors.RED(1),
    marginLeft: 12
  },
  save: {
    background: colors.LIGHT_BLUE(1),
    color: "#fff",
    borderColor: colors.LIGHT_BLUE(1)
  },
  moveProduct: {
    marginLeft: 12,
    background: colors.LIGHT_BLUE(1),
    color: "#fff",
    borderColor: colors.LIGHT_BLUE(1)
  },
  fileIcon: {
    marginRight: 8
  },
  actionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 10
  },
  tabContainer: {
    display: "flex",
    marginBottom: 10,
    justifyContent: "center"
  },
  dashboardTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tab: {
    marginLeft: 7,
    marginRight: 7,
    opacity: ".3",
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  body: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  table: {},
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  },
  actionIcon: {
    fontSize: 18,
    paddingTop: 10,
    marginLeft: 12,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 4,
    background: "#fff",
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    cursor: "pointer",

    ":hover": {
      transform: "translateY(-5px)",
      "-webkit-transition": ".5s",
      transition: ".5s"
    }
  },
  manageItems: {
    width: 120,
    height: 40,
    fontSize: 16,
    background: "none",
    cursor: "pointer",
    borderRadius: 4,
    outline: 0,
    border: "1px solid #bbb",

    ":hover": {
      background: colors.RED(1),
      color: "#fff",
      borderColor: colors.RED(1)
    }
  },
  dropdown: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
    alignItems: "center"
  },
  location: {
    fontSize: 20,
    fontWeight: "bold",
    marginRight: 5
  },
  timesIcon: {
    position: "absolute",
    top: 10,
    right: 10,
    fontSize: 20,
    opacity: 0.5,
    cursor: "pointer"
  }
});

const mapStateToProps = state => ({
  inventory: state.inventory,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  inventoryActions: bindActionCreators(InventoryActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Product));
