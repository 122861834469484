/***
 * Action / Reducer file to open and close modals
 * @craiglu
 */

// eslint-disable-next-line
import API from "../config/api";
// eslint-disable-next-line
import Helpers from "./helpers";

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

const ModalConstants = {
  ITEM_MANAGEMENT_MODAL_TOGGLE: "@@modal/ITEM_MANAGEMENT_MODAL_TOGGLE",
  ADD_PRODUCT_MODAL_TOGGLE: "@@modal/ADD_PRODUCT_MODAL_TOGGLE",
  ADD_SKU_MODAL_TOGGLE: "@@modal/ADD_SKU_MODAL_TOGGLE",
  BARCODE_MODAL_TOGGLE: "@@modal/BARCODE_MODAL_TOGGLE",
  INVITE_USERS_TOGGLE: "@@modal/INVITE_USERS_TOGGLE",
  APPROVE_CONSIGNMENT_TOGGLE: "@@modal/APPROVE_CONSIGNMENT_TOGGLE",
  APPROVE_PRICE_TOGGLE: "@@modal/APPROVE_PRICE_TOGGLE",
  MANUAL_INPUT_MODAL_TOGGLE: "@@modal/MANUAL_INPUT_MODAL_TOGGLE",
  SIZE_MODAL_TOGGLE: "@@modal/SIZE_MODAL_TOGGLE",
  OPEN_MODAL: "@@modal/OPEN_MODAL",
  LOCKED_INVENTORY_MODAL_TOGGLE: "@@modal/LOCKED_INVENTORY_MODAL_TOGGLE",
  ADD_CONSIGNMENT_MODAL_TOGGLE: "@@modal/ADD_CONSIGNMENT_MODAL_TOGGLE",
  CONSIGNMENT_MANAGEMENT_MODAL_TOGGLE:
    "@@modal/CONSIGNMENT_MANAGEMENT_MODAL_TOGGLE",
  ADD_PICTURE_MODAL_TOGGLE: "@@modal/ADD_PICTURE_MODAL_TOGGLE",
  BARCODE_SCAN_TO_PRINT_MODAL_TOGGLE:
    "@@modal/BARCODE_SCAN_TO_PRINT_MODAL_TOGGLE",
  CONSIGNER_PAYOUT_MODAL_TOGGLE: "@@modal/CONSIGNER_PAYOUT_MODAL_TOGGLE",
  CONSIGNER_PAYOUTS_MODAL_TOGGLE: "@@modal/CONSIGNER_PAYOUTS_MODAL_TOGGLE",
  CONSIGNER_PAYOUT_MODAL_HISTORY_TOGGLE:
    "@@modal/CONSIGNER_PAYOUT_MODAL_HISTORY_TOGGLE",
  VERIFY_INVENTORY_MODAL_TOGGLE: "@@modal/VERIFY_INVENTORY_MODAL_TOGGLE",
  MISSING_ITEMS_MODAL_TOGGLE: "@@modal/MISSING_ITEMS_MODAL_TOGGLE",
  LIST_PRODUCT_ONLINE_TOGGLE: "@@modal/LIST_PRODUCT_ONLINE_TOGGLE",
  RETURN_MODAL_TOGGLE: "@@modal/RETURN_MODAL_TOGGLE",
  SHINE_ALREADY_SOLD_TOGGLE: "@@modal/SHINE_ALREADY_SOLD_TOGGLE",
  SPECIFIC_ORDER_MODAL_TOGGLE: "@@modal/SPECIFIC_ORDER_MODAL_TOGGLE",
  CHECK_ITEM_MODAL_TOGGLE: "@@modal/CHECK_ITEM_MODAL_TOGGLE",
  CHOOSE_SHIPPING_MODAL_TOGGLE: "@@modal/CHOOSE_SHIPPING_MODAL_TOGGLE"
};

export const ModalActions = {
  /***
   * Opens/closes the modal for item management modal
   * @param: boolean -- true opens modal false closes modal
   */
  openItemManagementModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.ITEM_MANAGEMENT_MODAL_TOGGLE,
        openItemManagementModal: openModal
      });
    };
  },
  /***
   * Opens/closes the modal for listing a product online
   * @param: boolean -- true opens modal false closes modal
   */
  openListProductOnlineModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.LIST_PRODUCT_ONLINE_TOGGLE,
        openListProductOnlineModal: openModal
      });
    };
  },

  /***
   * Opens/closes the modal for adding a product
   * @param: boolean -- true opens modal false closes modal
   */
  openAddProductModal: (openModal, move = false, globalView = false) => {
    return dispatch => {
      dispatch({
        type: ModalConstants.ADD_PRODUCT_MODAL_TOGGLE,
        openAddProductModal: openModal,
        addProductModal: {
          move,
          globalView
        }
      });
    };
  },

  /***
   * Opens/closes the modal for missing items
   * @param: boolean -- true opens modal false closes modal
   */
  openMissingItemsModal: (openModal, params) => {
    return dispatch => {
      return dispatch({
        type: ModalConstants.MISSING_ITEMS_MODAL_TOGGLE,
        openMissingItemsModal: openModal,
        missingItemsModal: {
          missingItems: params.missingItems
        }
      });
    };
  },

  /***
   * Opens/closes the modal for approving consignment
   * @param: boolean -- true opens modal false closes modal
   */
  openApproveConsignmentModal: (openModal, items, status, note) => {
    return dispatch => {
      dispatch({
        type: ModalConstants.APPROVE_CONSIGNMENT_TOGGLE,
        openApproveConsignmentModal: openModal,
        approveConsignmentModal: {
          items,
          status,
          note
        }
      });
    };
  },

  /***
   * Opens/closes the modal for approving price change requests
   * @param: boolean -- true opens modal false closes modal
   */
  openApprovePriceChangeModal: (openModal, request) => {
    return dispatch => {
      dispatch({
        type: ModalConstants.APPROVE_PRICE_TOGGLE,
        openApprovePriceChangeModal: openModal,
        approvePriceChangeModal: {
          request
        }
      });
    };
  },

  /***
   * Opens/closes the modal for adding a product
   * @param: boolean -- true opens modal false closes modal
   */
  openAddSKUModal: (openModal, error, params) => {
    return dispatch => {
      let skuModal = {
        error
      };
      if (params) {
        skuModal = {
          ...skuModal,
          ...params
        };
      }
      dispatch({
        type: ModalConstants.ADD_SKU_MODAL_TOGGLE,
        openAddSKUModal: openModal,
        skuModal
      });
    };
  },

  /***
   * Opens/closes the modal to print a barcode
   * @param: boolean -- true opens modal false closes modal
   */
  openBarcodeModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.BARCODE_MODAL_TOGGLE,
        openBarcodeModal: openModal
      });
    };
  },

  /***
   * Opens/closes the modal to scan a barcode to print
   * @param: boolean -- true opens modal false closes modal
   */
  openScanToPrintBarcodeModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.BARCODE_SCAN_TO_PRINT_MODAL_TOGGLE,
        openScanToPrintBarcodeModal: openModal
      });
    };
  },

  /***
   * Opens/closes the product size modal
   * @params boolean -- true opens modal false closes modal
   * @params [sizes] -- a list of sizes that are in stock
   * @params string item_type -- the type of item, a shoe or other
   */
  openSizeModal: (openModal, sizes, item_type, style) => {
    return dispatch => {
      dispatch({
        type: ModalConstants.SIZE_MODAL_TOGGLE,
        openSizeModal: openModal,
        sizeModal: {
          sizes,
          item_type,
          style
        }
      });
    };
  },

  /***
   * Opens/closes the modal for inviting users to the management app
   * @param: boolean -- true opens modal false closes modal
   */
  openInviteUsersModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.INVITE_USERS_TOGGLE,
        openInviteUsersModal: openModal
      });
    };
  },

  /***
   * Opens/closes the modal for manual input of product adding
   * @param: boolean -- true opens modal false closes modal
   */
  openManualInputModal: (openModal, error, params) => {
    return dispatch => {
      let manualInputModal = {
        error
      };
      if (params) {
        manualInputModal = {
          ...manualInputModal,
          ...params
        };
      }
      return dispatch({
        type: ModalConstants.MANUAL_INPUT_MODAL_TOGGLE,
        openManualInputModal: openModal,
        manualInputModal
      });
    };
  },

  /***
   * Opens/closes the modal for manual adding consignments
   * @param: boolean -- true opens modal false closes modal
   */
  openAddConsignmentModal: (openModal, error, params) => {
    return dispatch => {
      let AddConsignmentModal = {
        error
      };
      if (params) {
        AddConsignmentModal = {
          ...AddConsignmentModal,
          ...params
        };
      }
      return dispatch({
        type: ModalConstants.ADD_CONSIGNMENT_MODAL_TOGGLE,
        openAddConsignmentModal: openModal,
        AddConsignmentModal
      });
    };
  },

  /***
   * Opens/closes the modal for consignment management
   * @param: boolean -- true opens modal false closes modal
   */
  openConsignmentManagementModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.CONSIGNMENT_MANAGEMENT_MODAL_TOGGLE,
        openConsignmentManagementModal: openModal
      });
    };
  },

  /***
   * Opens/closes the modal for adding picture to sku/style
   * @param: boolean -- true opens modal false closes modal
   */
  openAddPictureModal: (openModal, row) => {
    return dispatch => {
      dispatch({
        type: ModalConstants.ADD_PICTURE_MODAL_TOGGLE,
        openAddPictureModal: openModal,
        addPictureModalRow: row
      });
    };
  },

  /**
   * Opens/closes the modal for adding picture to sku/style
   * @param { boolean } -- true opens modal false closes modal
   */
  openConsignerPayoutModal: (openModal, payout) => {
    return dispatch => {
      dispatch({
        type: ModalConstants.CONSIGNER_PAYOUT_MODAL_TOGGLE,
        openConsignerPayoutModal: openModal,
        payout
      });
    };
  },

  /**
   * Opens/closes the modal for consignment payout history screen
   * @param { boolean } -- true opens modal false closes modal
   */
  openPayoutHistoryModal: (openModal, payout) => {
    return dispatch => {
      dispatch({
        type: ModalConstants.CONSIGNER_PAYOUT_MODAL_HISTORY_TOGGLE,
        openPayoutHistoryModal: openModal,
        payout
      });
    };
  },

  /**
   * Opens/closes the modal for adding picture to sku/style
   * @param { boolean } -- true opens modal false closes modal
   */
  openConsignerPayoutsModal: (openModal, payouts) => {
    return dispatch => {
      dispatch({
        type: ModalConstants.CONSIGNER_PAYOUTS_MODAL_TOGGLE,
        openConsignerPayoutsModal: openModal,
        payouts
      });
    };
  },

  /***
   * Opens/closes the modal for verifying inventory
   * @param: boolean -- true opens modal false closes modal
   */
  openVerifyInventoryModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.VERIFY_INVENTORY_MODAL_TOGGLE,
        openVerifyInventoryModal: openModal
      });
    };
  },

  /***
   * Opens/closes the modal for returns
   * @param: boolean -- true opens modal false closes modal
   */
  openReturnModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.RETURN_MODAL_TOGGLE,
        openReturnModal: openModal
      });
    };
  },

  /***
   * Opens/closes the modal for shine item already sold while scanning item into inventory
   * @param: boolean -- true opens modal false closes modal
   */
  openShineAlreadySoldModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.SHINE_ALREADY_SOLD_TOGGLE,
        openShineAlreadySoldModal: openModal
      });
    };
  },

  /***
   * Opens/closes the modal for specific orders
   * @param: boolean -- true opens modal false closes modal
   */
  openSpecificOrderModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.SPECIFIC_ORDER_MODAL_TOGGLE,
        openSpecificOrderModal: openModal
      });
    };
  },
  /***
   * Opens/closes the modal to lock inventory
   * @param: boolean -- true opens modal false closes modal
   */
  openLockedInventoryModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.LOCKED_INVENTORY_MODAL_TOGGLE,
        openLockedInventoryModal: openModal
      });
    };
  },

  /***
   * Opens/closes the modal to check if current item matches the item in the system
   * @param: boolean -- true opens modal false closes modal
   */
  openCheckItemModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.CHECK_ITEM_MODAL_TOGGLE,
        openCheckItemModal: openModal
      });
    };
  },

  /**
   * Opens/closes the modal to check if current item matches the item in the system
   * @param { Boolean } openModal -- true opens modal false closes modal
   */
  chooseShippingModal: openModal => {
    return dispatch => {
      dispatch({
        type: ModalConstants.CHOOSE_SHIPPING_MODAL_TOGGLE,
        chooseShippingModal: openModal
      });
    };
  }
};

/**********************************
 *        REDUCER SECTION         *
 **********************************/

const defaultState = {
  openItemManagementModal: false,
  openAddProductModal: false,
  openAddSKUModal: false,
  openBarcodeModal: false,
  openInviteUsersModal: false,
  openApproveConsignmentModal: false,
  openSizeModal: false,
  openScanToPrintBarcodeModal: false,
  sizeModal: {
    sizes: []
  },
  approveConsignmentModal: {
    items: []
  },
  addProductModal: {
    move: false
  },
  skuModal: {},
  manualInputModal: {},
  openManualInputModal: false,
  openAddConsignmentModal: false,
  openConsignmentManagementModal: false,
  openAddPictureModal: false,
  addPictureModalRow: {},
  openConsignerPayoutModal: false,
  payouts: {
    profile: {},
    location: {}
  },
  openPayoutHistoryModal: false,
  openConsignerPayoutsModal: false,
  payout: {
    profile: {},
    location: {}
  },
  missingItemsModal: {
    missingItems: []
  },
  openVerifyInventoryModal: false,
  openListProductOnlineModal: false,
  openReturnModal: false,
  openShineAlreadySoldModal: false,
  openSpecificOrderModal: false,
  openCheckItemModal: false,
  chooseShippingModal: false
};

const ModalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ModalConstants.OPEN_MODAL:
    case ModalConstants.ITEM_MANAGEMENT_MODAL_TOGGLE:
    case ModalConstants.ADD_PRODUCT_MODAL_TOGGLE:
    case ModalConstants.ADD_SKU_MODAL_TOGGLE:
    case ModalConstants.BARCODE_MODAL_TOGGLE:
    case ModalConstants.INVITE_USERS_TOGGLE:
    case ModalConstants.APPROVE_CONSIGNMENT_TOGGLE:
    case ModalConstants.APPROVE_PRICE_TOGGLE:
    case ModalConstants.MANUAL_INPUT_MODAL_TOGGLE:
    case ModalConstants.SIZE_MODAL_TOGGLE:
    case ModalConstants.ADD_CONSIGNMENT_MODAL_TOGGLE:
    case ModalConstants.CONSIGNMENT_MANAGEMENT_MODAL_TOGGLE:
    case ModalConstants.ADD_PICTURE_MODAL_TOGGLE:
    case ModalConstants.BARCODE_SCAN_TO_PRINT_MODAL_TOGGLE:
    case ModalConstants.CONSIGNER_PAYOUT_MODAL_TOGGLE:
    case ModalConstants.CONSIGNER_PAYOUT_MODAL_HISTORY_TOGGLE:
    case ModalConstants.CONSIGNER_PAYOUTS_MODAL_TOGGLE:
    case ModalConstants.VERIFY_INVENTORY_MODAL_TOGGLE:
    case ModalConstants.MISSING_ITEMS_MODAL_TOGGLE:
    case ModalConstants.LIST_PRODUCT_ONLINE_TOGGLE:
    case ModalConstants.RETURN_MODAL_TOGGLE:
    case ModalConstants.SHINE_ALREADY_SOLD_TOGGLE:
    case ModalConstants.SPECIFIC_ORDER_MODAL_TOGGLE:
    case ModalConstants.CHECK_ITEM_MODAL_TOGGLE:
    case ModalConstants.LOCKED_INVENTORY_MODAL_TOGGLE:
    case ModalConstants.CHOOSE_SHIPPING_MODAL_TOGGLE:
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

export default ModalReducer;
