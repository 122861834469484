/***
 * Modal to show that a shine item is already bought when scanning in
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import BaseModal from "./BaseModal";
import Button from "../Button";
import Input from "../Input";
import OrderDetails from "../orders/OrderDetails";

// Actions
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

class CheckItemModal extends React.Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      scannedItemBarcode: "",
      itemMatches: false,
      message: "Please Scan the barcode for the item below"
    };

    this.state = {
      ...this.defaultState
    };
  }

  /***
   * closes the modal
   */
  closeModal = () => {
    let { modalActions } = this.props;
    this.setState(
      {
        ...this.defaultState,
        scannedItemBarcode: ""
      },
      () => {
        modalActions.openCheckItemModal(false);
      }
    );
  };

  onEnterPress = e => {
    if (e.key === "Enter") {
      this.checkScannedItem();
    }
  };

  checkScannedItem = () => {
    let { intl, scanItem } = this.props;
    let scannedItemBarcode = `${this.state.scannedItemBarcode}`;
    let code = scannedItemBarcode.slice(0, 3);

    let barcodeStr = "";
    for (let i = 0; i < scanItem.inventory.sku.barcodes.length; i++) {
      barcodeStr += scanItem.inventory.sku.barcodes[i].code + " / ";
    }

    if (
      code === "ss-" &&
      scannedItemBarcode === `ss-${scanItem.inventory.id}`
    ) {
      this.props.callback();
      this.closeModal();
    } else if (barcodeStr.includes(scannedItemBarcode)) {
      this.props.callback();
      this.closeModal();
    } else {
      this.setState({
        message: intl.formatMessage({
          id: "scan_item_wrong",
          defaultMessage:
            "Scanned item does not match the item in the system. To continue, make sure the item you are trying to scan matches."
        })
      });
    }
  };

  render() {
    let { modals, intl } = this.props;
    return (
      <BaseModal
        isOpen={modals.openCheckItemModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <FontAwesomeIcon
          icon={["far", "times"]}
          className={css(styles.times)}
          onClick={this.closeModal}
        />
        {this.state.message && (
          <div className={css(styles.message)}>{this.state.message}</div>
        )}
        <div className={css(styles.orderDetails)}>
          <OrderDetails
            orderIndex={0}
            orderList={[]}
            order={this.props.scanItem}
          />
        </div>

        <div className={css(styles.inputSection)}>
          <Input
            containerClassName={styles.inputContainer}
            onKeyPress={this.onEnterPress}
            onChange={e => {
              this.setState({
                scannedItemBarcode: e.target.value
              });
            }}
            value={this.state.scannedItemBarcode}
            autoFocus={true}
          />
          <Button
            text={intl.formatMessage({
              id: "scan_item",
              defaultMessage: "Scan Item"
            })}
            onClick={this.checkScannedItem}
          />
        </div>
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  modal: {
    maxWidth: "unset",
    maxHeight: "unset",
    width: "70%",
    height: 600,
    zIndex: 999999
  },
  message: {
    fontSize: 26,
    marginBottom: 24,
    fontWeight: 600,
    textAlign: "center",
    whiteSpace: "pre-wrap"
  },
  times: {
    position: "absolute",
    right: 20,
    top: 15,
    fontSize: 20
  },
  bodyContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    boxSizing: "border-box"
  },
  soldItem: {
    padding: 10,
    border: "1px solid #000",
    margin: 10,
    width: 200
  },
  orderDetails: {
    width: 600,
    marginBottom: 24,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  inputSection: {
    width: 600
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 20
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    marginBottom: 10
  },
  description: {
    width: 400,
    textAlign: "center"
  },
  inputContainer: {
    marginBottom: 10
  }
});
const mapStateToProps = state => ({
  modals: state.modals
});
const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheckItemModal)
);
