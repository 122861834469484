/***
 * The searchbar component for the dashboard
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";

// import { bindActionCreators } from 'redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { injectIntl } from "react-intl";

// Config
import colors from "../../config/colors";

class Searchbar extends React.Component {
  render() {
    let { intl, searchChange, search, value } = this.props;
    return (
      <div className={css(styles.searchBar)}>
        <div className={css(styles.search)}>
          <input
            className={css(styles.input)}
            value={value}
            onChange={searchChange}
            onKeyPress={search}
            placeholder={intl.formatMessage({
              id: "search_message",
              defaultMessage: "Search Here..."
            })}
          />
          {this.props.searching ? (
            <FontAwesomeIcon
              icon={["far", "spinner-third"]}
              spin
              className={css(styles.searchIcon, styles.searcher)}
            />
          ) : (
            <FontAwesomeIcon
              icon={["far", "search"]}
              className={css(styles.searchIcon)}
            />
          )}
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  searchBar: {
    width: "100%",
    background: "#fff",
    // padding: 16,
    boxSizing: "border-box"
  },
  search: {
    position: "relative"
  },
  searcher: {
    top: "40%"
  },
  input: {
    width: "100%",
    borderRadius: 4,
    outline: "none",
    padding: 8,
    fontSize: 16,
    boxSizing: "border-box",
    border: "1px solid #ddd",
    background: "rgb(245, 245, 245)",

    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.LIGHT_BLUE(1)}`
    }
  },
  searchIcon: {
    position: "absolute",
    right: 16,
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer"
  }
});

const mapStateToProps = state => ({
  // auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Searchbar)
);
