import { createActions } from "redux-actions";

const Actions = createActions({
  INVENTORY: {
    QUERY_INVENTORY: undefined,
    QUERY_SKU: undefined,
    QUERY_CONSIGNMENT_ORDER: undefined
  }
});

export default Actions;
