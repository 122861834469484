/***
 * Link to all accepted consignment orders for this location
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import _ from "lodash";
import ReactTable from "react-table";
import queryString from "query-string";

// Components
import Input from "../../components/Input";
import Dropdown from "../../components/inventory/Dropdown";

// Redux
import { ModalActions } from "../../redux/modals";
import { JoyrideActions } from "../../redux/joyride";
import { MessageActions } from "../../redux/message";
import Actions from "../../redux/inventory/actions";

// Config
import API from "../../config/api";
import IMAGECOMING from "../../assets/imagecoming.jpg";
import Helpers from "../../redux/helpers";
import colors from "../../config/colors";

import "react-placeholder/lib/reactPlaceholder.css";

class InventoryStyle extends React.Component {
  constructor(props) {
    super(props);

    let { auth, intl } = this.props;

    this.tabs = [
      {
        id: "listed",
        label: intl.formatMessage({
          id: "listed_consignment_inventory",
          defaultMessage: "Listed"
        })
      },
      // {
      //   id: "needToPrint",
      //   label: intl.formatMessage({
      //     id: "unprinted_consignment",
      //     defaultMessage: "Unprinted Consignment Inventory"
      //   })
      // },
      {
        id: "sold",
        label: intl.formatMessage({
          id: "consignment_sold",
          defaultMessage: "Sold"
        })
      }
    ];

    if (
      auth.userProfile.role &&
      ["admin", "accountant", "warehouse manager"].includes(
        auth.userProfile.role.toLowerCase()
      )
    ) {
      this.tabs.splice(1, 0, {
        id: "pending",
        label: intl.formatMessage({
          id: "consignment_pending",
          defaultMessage: "Pending"
        })
      });
    }

    if (
      auth.userProfile.role &&
      ["admin", "accountant"].includes(auth.userProfile.role.toLowerCase())
    ) {
      this.tabs.push({
        id: "deleted",
        label: intl.formatMessage({
          id: "deleted",
          defaultMessage: "Deleted"
        })
      });
    }

    this.state = {
      styleInfo: {},
      initData: [],
      data: [],
      activeTab: "listed",
      loading: false,
      page: 1,
      firstLoad: true,
      error: null,
      selectedIndex: 0,
      selectedConditions: [],
      location: auth.userProfile.location.id,
      exportingConsignments: false,
      startDate: null,
      endDate: null,
      focusedInput: null,
      sizes: [],
      selectedSize: null,
      selectedConsigner: null,
      users: [],
      selectedUser: {},
      emailSearch: null,
      openDropdown: false,
      isEditing: [],
      editRow: []
    };
  }

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    this.setState(
      {
        activeTab: tab,
        page: 1,
        firstLoad: true,
        items: []
      },
      () => {
        this.loadSkus({});
      }
    );
  };

  /**
   * on change of location selector
   * @param  {Event} e -- on change event for selector
   */
  onLocationChange = e => {
    let value = e.target.value;
    if (e.target.value === "0") {
      value = null;
    }

    this.setState(
      {
        location: value,
        page: 1,
        firstLoad: true
      },
      () => {
        this.loadSkus({});
      }
    );
  };

  onSizeFilterChange = e => {
    let value = e.target.value;
    if (e.target.value === "All") {
      value = null;
    }

    this.setState(
      {
        selectedSize: value && value,
        page: 1,
        firstLoad: true
      },
      () => {
        this.loadSkus({});
      }
    );
  };

  /***
   * Loads skus
   */
  loadSkus = ({ id, page, pageSize }) => {
    let style = this.props.match.params.styleId;

    if (!page) {
      page = this.state.page;
    }

    if (!pageSize) {
      pageSize = this.state.pageSize;
    }

    let { querySku, showMessage } = this.props;

    showMessage({ show: true, load: true });

    let params = {
      size: pageSize,
      style,
      location: this.state.location,
      consigner: this.state.selectedConsigner,
      us_size: this.state.selectedSize,
      consignment_status:
        this.state.activeTab === "pending"
          ? "pending,approved"
          : this.state.activeTab === "deleted"
          ? "pending,listed,approved,sold"
          : this.state.activeTab,
      deleted: this.state.activeTab === "deleted"
    };

    const onStart = () => {
      this.setState({ loading: true });
    };

    const onSuccess = res => {
      showMessage({ show: false });
      if (this.state.firstLoad) {
        this.setState({
          firstLoad: false
        });
      }
      let data = [];
      Object.keys(res.results).forEach(size => {
        let size_data = [];
        if (Object.keys(res.results[size]).length === 0) {
          size_data = [
            {
              Size: size,
              Price: 0,
              Quantity: 0
            }
          ];
        } else {
          size_data = Object.keys(res.results[size]).map((key, index) => {
            return {
              Size: size,
              Price: parseInt(key) / 100,
              Quantity: res.results[size][key]
            };
          });
        }
        data = [...data, ...size_data];
      });
      let sizeOrder = ["O/S", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "N/A"];
      let sortedData =
        res.style_info.type === "shoe"
          ? data.sort((a, b) => parseFloat(a.Size) - parseFloat(b.Size))
          : data.sort(
              (a, b) => sizeOrder.indexOf(a.Size) - sizeOrder.indexOf(b.Size)
            );
      this.setState(
        {
          data: sortedData,
          initData: _.cloneDeep(data),
          styleInfo: res.style_info,
          loading: false,
          editRow: data.map(() => {
            return {};
          }),
          isEditing: data.map(() => false)
        },
        () => {
          let params = {
            location: this.state.location,
            page: this.state.page,
            tab: this.state.activeTab,
            size: this.state.selectedSize,
            consigner: this.state.selectedConsigner
          };
          this.props.history.replace({
            search: queryString.stringify(params),
            ...this.props.history
          });
        }
      );
    };

    const onFailure = error => {
      if (this.state.firstLoad) {
        this.setState({
          firstLoad: false
        });
      }

      this.setState({
        loading: false
      });
    };

    querySku({
      params,
      onStart,
      onSuccess,
      onFailure
    });
  };

  onPriceChange = (e, index) => {
    let new_value = e.target.value;
    let data = [...this.state.data];
    data[index]["Price"] = new_value;
    let editRow = [...this.state.editRow];
    editRow[index]["newPrice"] = new_value;
    if (new_value === this.state.initData[index]["Price"]) {
      delete editRow[index]["newPrice"];
    }
    let isEditing = [...this.state.isEditing];
    isEditing[index] = true;
    this.setState({
      data,
      editRow,
      isEditing
    });
  };

  onQuantityChange = (e, index) => {
    let new_value = e.target.value;
    let data = [...this.state.data];
    data[index]["Quantity"] = new_value;
    let editRow = [...this.state.editRow];
    editRow[index]["newQuantity"] = new_value;
    if (new_value === this.state.initData[index]["Quantity"]) {
      delete editRow[index]["newQuantity"];
    }
    let isEditing = [...this.state.isEditing];
    isEditing[index] = true;
    this.setState({
      data,
      editRow,
      isEditing
    });
  };

  saveUpdate = e => {
    let changeList = `${this.props.match.params.styleId} Changes: \n`;
    this.state.editRow.forEach((row, index) => {
      let change = "";
      if (Object.keys(row).length !== 0) {
        change = `${this.state.data[index].Size}: `;
      }
      if ("newPrice" in row) {
        change += `Price change: ${this.state.initData[index].Price} to ${
          row["newPrice"]
        }`;
      }
      if ("newQuantity" in row) {
        change += ` | Quantity change: ${
          this.state.initData[index].Quantity
        } to ${row["newQuantity"]} \n`;
      }

      changeList += `${change}`;
    });
    let confirm = window.confirm(changeList);

    if (!confirm) {
      return;
    }

    let { showMessage, setMessage } = this.props;

    showMessage({ show: true, load: true });

    let fetches = [];
    let errorMessage = "";

    this.state.editRow.forEach((row, index) => {
      //price change
      if ("newPrice" in row) {
        let params = {
          type: "update_price",
          style: this.props.match.params.styleId,
          size: this.state.data[index].Size,
          location: this.state.location,
          consigner: this.state.selectedConsigner,
          consignment_status: this.state.activeTab,
          price: row["newPrice"] * 100,
          currentPrice: this.state.initData[index]["Price"] * 100
        };
        fetches.push(
          fetch(API.UPDATE_ITEMS_BY_SKU(params), API.POST_CONFIG({}))
            .then(Helpers.checkStatus)
            .then(Helpers.parseJSON)
            .then(res => {
              let isEditing = _.cloneDeep(this.state.isEditing);
              isEditing[index] = false;
              this.setState({
                isEditing
              });
            })
            .catch(error => {
              if (error.message === "Forbidden") {
                errorMessage = "Permission Denied";
              }
            })
        );
      }

      //quantity change
      if ("newQuantity" in row) {
        let reason = prompt(
          `Please enter the reason of changing quantity for Size ${
            this.state.data[index].Size
          }`,
          ""
        );
        if (reason === null) {
          reason = "";
        }
        let params = {
          type: "update_quantity",
          style: this.props.match.params.styleId,
          size: this.state.data[index].Size,
          location: this.state.location,
          consigner: this.state.selectedConsigner,
          consignment_status: this.state.activeTab,
          quantity: row["newQuantity"],
          reason,
          currentPrice: this.state.initData[index]["Price"] * 100
        };
        fetches.push(
          fetch(API.UPDATE_ITEMS_BY_SKU(params), API.POST_CONFIG({}))
            .then(Helpers.checkStatus)
            .then(Helpers.parseJSON)
            .then(res => {
              let isEditing = _.cloneDeep(this.state.isEditing);
              isEditing[index] = false;
              this.setState({
                isEditing
              });
            })
        );
      }
    });

    Promise.all(fetches).then(() => {
      showMessage({ show: false });
      setMessage(errorMessage);
      showMessage({ show: true, error: true });
      if (errorMessage) return;
      this.loadSkus({});
    });
  };

  discardUpdate = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    let data = _.cloneDeep(this.state.data);
    data[index] = _.cloneDeep(this.state.initData[index]);
    let isEditing = _.cloneDeep(this.state.isEditing);
    isEditing[index] = false;
    let editRow = _.cloneDeep(this.state.editRow);
    editRow[index] = {};
    this.setState({
      data,
      editRow,
      isEditing
    });
  };

  discardAllUpdate = e => {
    e.preventDefault();
    e.stopPropagation();
    let data = _.cloneDeep(this.state.initData);
    let isEditing = _.cloneDeep(this.state.isEditing);
    isEditing = isEditing.map(i => {
      return false;
    });
    let editRow = _.cloneDeep(this.state.editRow);
    editRow = editRow.map(edit => {
      return {};
    });
    this.setState({
      data,
      editRow,
      isEditing
    });
  };

  /***
   * Removes consignment item from list after printing
   */
  removeConsignment = inventory_id => {
    if (this.state.activeTab === "listed") {
      return null;
    }
    let items = [...this.state.items];
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === inventory_id) {
        items.splice(i, 1);
        break;
      }
    }

    this.setState({
      items
    });
  };

  componentDidMount = () => {
    if (this.props.location.search && this.props.location.search !== "") {
      let params = queryString.parse(this.props.location.search);
      if (params.consigner) {
        fetch(
          API.USER_ACTIONS + `?id=${params.consigner}&page=1&size=5`,
          API.GET_CONFIG()
        )
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON)
          .then(res => {
            this.setState({ selectedUser: res.results[0] });
          });
      }

      this.setState(
        {
          page: params.page,
          location: parseInt(params.location),
          activeTab: params.tab,
          selectedSize: params.size,
          selectedConsigner: params.consigner
        },
        () => {
          this.loadSkus({
            page: this.state.page
          });
        }
      );
    } else {
      this.loadSkus({});
    }

    if (!this.joyride && this.props.joyride.forceJoy) {
      let { joyrideActions } = this.props;
      this.joyride = true;
      joyrideActions.setRun({ run: true, inProgress: true });
    }
  };

  renderCell = (header, props, index) => {
    switch (header) {
      case "Others":
        return (
          <div>
            <button onClick={e => this.viewSkuItems(e, props)}>See All</button>
          </div>
        );
      case " ":
        return (
          this.state.isEditing[props.row._index] && (
            <button
              className={css(styles.updateButton)}
              onClick={e => this.discardUpdate(e, props.row._index)}
            >
              Restore
            </button>
          )
        );
      case "Price":
        return (
          <input
            value={props.value}
            className={css(styles.inputField)}
            onChange={e => this.onPriceChange(e, props.row._index)}
            type="number"
            min={0}
            disabled={
              !this.state.selectedConsigner || this.state.activeTab !== "listed"
            }
          />
        );
      case "Quantity":
        return (
          <input
            value={props.value}
            className={css(styles.inputField)}
            onChange={e => this.onQuantityChange(e, props.row._index)}
            type="number"
            min={0}
            disabled={
              !this.state.selectedConsigner || this.state.activeTab !== "listed"
            }
          />
        );
      default:
        return <div className={css(styles.cellValue)}>{props.value}</div>;
    }
  };

  setConsigner = selectedConsigner => {
    this.setState({ selectedConsigner }, () => this.loadSkus({}));
  };

  onSelectEmail = user => {
    this.setState(
      {
        selectedUser: user,
        openDropdown: false,
        selectedConsigner: user.user
      },
      () => this.loadSkus({})
    );
  };

  searchForEmails = email => {
    if (email === null || email === "") {
      this.setState(
        {
          users: [],
          selectedUser: { email: email },
          selectedConsigner: null
        },
        () => {
          this.loadSkus({});
        }
      );
    } else {
      let config = API.GET_CONFIG();
      fetch(API.USER_ACTIONS + `?search=${email}&page=1&size=5`, config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          let users = [...res.results];
          this.setState({
            users: users,
            openDropdown: true
          });
        });
    }
    this.setState({
      selectedUser: { email: email }
    });
  };

  viewSkuItems = (e, props) => {
    this.props.history.push(
      `/inventory/inventory?location=${this.state.location}&page=1&search=${
        this.props.match.params.styleId
      }&size=${props.original.Size}&tab=${this.state.activeTab}&consigner=${
        this.state.selectedConsigner
      }`
    );
  };

  render() {
    let { intl } = this.props;

    let columns = ["Size", "Price", "Quantity", "Others", " "].map(
      (column, index) => ({
        Header: column,
        accessor: column,
        className: css(styles.cell),
        headerClassName: css(styles.cellHeader),
        filterable: false,
        minWidth: column === "Product Name" ? 400 : column === " " ? 50 : 100,
        Cell: props => (
          <div className={css(styles.valueContainer)}>
            {this.renderCell(props.column.Header, props, index)}
          </div>
        )
      })
    );

    let tabs = this.tabs.map((tab, index) => {
      return (
        <div
          className={css(
            styles.tab,
            this.state.activeTab === tab.id && styles.activeTab
          )}
          onClick={() => this.setActiveTab(tab.id)}
          key={`tab_${tab.id}_${index}`}
          id={tab.id === "sold" && "consignmentsSold"}
        >
          {tab.label}
        </div>
      );
    });

    let users = this.state.users.map((user, index) => {
      return (
        <div
          className={css(styles.dropdownItem)}
          onClick={() => this.onSelectEmail(user)}
        >
          {user.email}
        </div>
      );
    });

    return (
      <div className={css(styles.allBarcodes)}>
        <h2 className={css(styles.inventoryTitle)}>
          <FormattedMessage id="inventory_title" defaultMessage="Inventory" />
        </h2>
        <div className={css(styles.filterWrapper)}>
          <div className={css(styles.filterRow)}>
            <div className={css(styles.dropdown)}>
              <span className={css(styles.location)}>{`${intl.formatMessage({
                id: "inventory_location",
                defaultMessage: "Location"
              })}:`}</span>
              <Dropdown
                onChange={this.onLocationChange}
                default={this.state.location}
                removeAll
              />
            </div>
            <div className={css(styles.dropdown)}>
              <span className={css(styles.location)}>{`${intl.formatMessage({
                id: "consigner",
                defaultMessage: "Consigner"
              })}:`}</span>
              <div className={css(styles.emailInputContainer)}>
                <Input
                  className={[styles.userInputs, styles.emailInput]}
                  value={this.state.selectedUser.email}
                  onChange={e => this.searchForEmails(e.target.value)}
                  placeholder={intl.formatMessage({
                    id: "profile_email",
                    defaultMessage: "Email"
                  })}
                  required
                  autoComplete="no"
                />
                {this.state.users &&
                this.state.selectedUser.email &&
                this.state.openDropdown ? (
                  <div className={css(styles.userDropdown)}>{users}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={css(styles.tabContainer)}>{tabs}</div>
        </div>
        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <div className={css(styles.styleInfo)}>
            <img
              alt="product"
              src={
                this.state.styleInfo.picture === ""
                  ? IMAGECOMING
                  : this.state.styleInfo.picture
              }
              className={css(styles.productImg)}
            />
            <div className={css(styles.productInfo)}>
              <h3>{this.props.match.params.styleId}</h3>
              <h2>{this.state.styleInfo.product_name}</h2>
            </div>
          </div>
          <ReactTable
            className={css(styles.reactTable) + "inventory-table"}
            data={this.state.data}
            filterable={false}
            pages={this.state.numPages}
            columns={columns}
            showPagination={false}
            footerClassName={css(styles.tableFooter)}
            defaultPageSize={this.state.pageSize}
            minRows={0}
            // onFetchData={(state, instance) => {
            //   console.log("table fetch");
            //   this.loadSkus({});
            // }}
            noDataText={intl.formatMessage({
              id: "no_items_found",
              defaultMessage: "No items found"
            })}
            manual
          />

          <div className={css(styles.updateButtonWrapper)}>
            <button
              onClick={this.saveUpdate}
              disabled={this.state.isEditing.every(item => item === false)}
              className={css(styles.updateButton)}
            >
              Save
            </button>
            <button
              onClick={this.discardAllUpdate}
              className={css(styles.updateButton)}
            >
              Discard All
            </button>
          </div>

          {/* {orders.length === 0 ? (
            <div className={css(styles.info)}>
              {this.state.error
                ? this.state.error
                : this.state.activeTab === "needToPrint"
                ? intl.formatMessage({
                    id: "no_barcodes",
                    defaultMessage: "No barcodes to print."
                  })
                : intl.formatMessage({
                    id: "no_consignments",
                    defaultMessage: "No consignments found."
                  })}
            </div>
          ) : (
            <InfiniteScroll
              className={css(styles.barcodeGroup)}
              pageStart={0}
              loadMore={() =>
                this.loadSkus(this.state.search, "", false, true)
              }
              hasMore={
                this.state.items.length < this.state.itemCount &&
                !this.state.loading
              }
              loader={
                <div className="loader" key={0}>
                  {`${intl.formatMessage({
                    id: "loading",
                    defaultMessage: "Loading"
                  })} ...`}
                </div>
              }
            >
              {orders}
            </InfiniteScroll>
          )} */}
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  inventoryTitle: {
    fontSize: 40
  },
  allBarcodes: {
    padding: 30,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  filterWrapper: {
    background: "#fff",
    padding: "20px 50px",
    marginTop: 20,
    marginBottom: 40,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    textAlign: "left"
  },
  filterRow: {
    width: "100%",
    maxWidth: 900,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  },
  barcodeGroup: {
    padding: 16,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  tab: {
    color: "#111",
    fontSize: 16,
    padding: 16,
    textDecoration: "none",
    cursor: "pointer",
    borderRight: "1px solid #ddd"
  },
  info: {
    textAlign: "center",
    fontSize: 36,
    marginTop: 36
  },
  styleInfo: {
    display: "flex",
    alignItems: "center",
    padding: 50
  },
  dropdown: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "16px 10px"
  },
  input: {
    width: "max-content",
    borderRadius: 4,
    padding: 16,
    fontSize: 16,
    marginRight: 10,
    boxSizing: "border-box",
    border: "2px solid #ddd",
    background: "#fff",
    cursor: "pointer"
  },
  location: {
    marginRight: 8
  },
  activeTab: {
    background: colors.RED(1),
    color: "#fff"
  },
  tabContainer: {
    marginBottom: 16,
    background: "#fff",
    display: "inline-flex",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  consignmentOrder: {
    width: 500,
    margin: 16,
    padding: 16,
    borderRadius: 4,
    cursor: "pointer",
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    position: "relative"
    // margin: '0 auto',
  },
  soldBanner: {
    opacity: 0.8,
    zIndex: 4
  },
  soldOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#ddd",
    opacity: 0,
    zIndex: 3,
    top: 0,
    left: 0
  },
  export: {
    display: "flex",
    alignItems: "center",
    marginLeft: 8,
    height: 36,
    borderRadius: 4,
    padding: 8,
    border: "1px solid #ddd",
    background: "rgb(245, 245, 245)",
    cursor: "pointer",
    outline: "none",
    fontSize: 14,
    letterSpacing: 0.7,

    ":hover": {
      transform: "scale(1.05)",
      "-webkit-transition": ".5s",
      transition: ".5s"
    }
  },
  emailInputContainer: {
    width: 320
  },
  emailInput: {
    marginBottom: 0
  },
  dropdownItem: {
    padding: 10,
    paddingLeft: 20,
    cursor: "pointer",

    ":hover": {
      background: "rgb(204, 204, 204)"
    }
  },
  userInputs: {
    marginBottom: 20,
    height: 38,
    fontSize: 16,
    background: "#fff",
    border: "1px solid rgb(204, 204, 204)"
  },
  userDropdown: {
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: 4,
    zIndex: 2,
    position: "absolute",
    background: "#fff",
    width: "max-content"
  },
  productImg: {
    width: 200,
    marginRight: 32
  },
  productInfo: {
    textAlign: "left"
  },
  table: {
    background: "#fff",
    padding: 20,
    marginTop: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: 70,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8 16",
    color: "#212b36"
  },
  hoveredRow: {
    background: "#eee"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontWeight: "bold",
    overflow: "visible"
  },
  cell: {
    padding: 0
  },
  productName: {
    width: "100%",
    whiteSpace: "normal"
  },
  updateButtonWrapper: {
    margin: "0 auto",
    width: 200,
    display: "flex",
    justifyContent: "space-between",
    padding: 50,
    zIndex: "3"
  },
  updateButton: {
    color: "#fff",
    background: colors.RED(1),
    border: `1px solid ${colors.RED(1)}`,
    borderRadius: 4,
    fontSize: 15,
    padding: "10px 8px",
    cursor: "pointer",
    letterSpacing: 0.7,
    outline: "none",
    marginRight: 10,

    ":hover": {
      background: colors.RED(0.8)
    },

    ":disabled": {
      background: colors.GREY(0.8),
      border: `1px solid ${colors.GREY(1)}`
    }
  },
  inputField: {
    padding: 5,
    width: "50%"
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  joyride: state.joyride
});

const mapDispatchToProps = {
  openConsignmentManagementModal: ModalActions.openConsignmentManagementModal,
  joyrideActions: JoyrideActions,
  setMessage: MessageActions.setMessage,
  showMessage: MessageActions.showMessage,
  querySku: payload => dispatch => dispatch(Actions.inventory.querySku(payload))
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(InventoryStyle)
);
