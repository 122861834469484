/***
 * Action / Reducer file for handling events
 * @craiglu
 */

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

const Constants = {
  UPDATE_STEPS: "@@joyride/UPDATE_STEPS",
  STEP_INDEX: "@@joyride/STEP_INDEX",
  RUN: "@@joyride/RUN"
};

export const JoyrideActions = {
  /**
   * Updates joyride's steps
   * @params [steps] -- The steps for joyride to run
   * @params string feature -- which feature we're onboarding
   */
  setSteps: ({ feature, forceJoy }) => {
    return (dispatch, getState) => {
      let steps = [];
      switch (feature) {
        case "list_consignment":
          steps = [
            {
              target: "#approveTab",
              title: "List Consignment",
              content:
                "When a consigner brings his items into the store, first click the Approved tab",
              disableBeacon: true
            },
            {
              target: "#search",
              title: "Search for the consignment order",
              content:
                "The consigner should have a code for his order, starting with co-. Search that in the search, something like co-76 and press enter.",
              disableBeacon: true
            },
            {
              target: "#approve",
              title: "Approve the items again",
              content:
                "If his items are what he says they are, approve the items again",
              disableBeacon: true
            },
            {
              target: "#haveConsignerItemApprove",
              title: "Click YES",
              content:
                "This time, you should have the consigners items with you. Click YES here and a barcode will print. Congrats, the consigner now has his item listed!",
              disableBeacon: true
            }
          ];
          break;
        case "approve_consignment":
          steps = [
            {
              target: "#approveConsignment",
              title: "Pending Consignments",
              content:
                "When a consigner consigns an item through the app, their items will show up here.",
              disableBeacon: true
            },
            {
              target: "#consignmentInfo",
              title: "Consignment Information",
              content: "Here's all the information about the consignment",
              disableBeacon: true
            },
            {
              target: "#approve",
              title: "Approve",
              content:
                "If you think the price is right and the item is good, approve the consignment",
              disableBeacon: true
            },
            {
              target: "#reject",
              title: "Reject",
              content:
                "Otherwise, reject the consignment. If you reject, you can also give a reason",
              disableBeacon: true
            },
            {
              target: "#haveConsignerItem",
              title: "Do you have the consigner's item?",
              content:
                "Sometimes consigners leave their items in the store with you. When a consigner brings the item in, you can reapprove them here to get their item listed and to print the barcode.",
              disableBeacon: true
            }
          ];
          break;
        case "change_inventory_price":
          steps = [
            {
              target: "#manageButton",
              title: "Manage Inventory",
              content:
                "Manage Inventory like quantity and changing price by clicking Manage",
              disableBeacon: true
            },
            {
              target: "#chooseSize",
              title: "Choose a Size",
              content: "First choose a size.",
              disableBeacon: true
            },
            {
              target: "#setPrice",
              title: "Set a Price",
              content: "Then set a price",
              disableBeacon: true
            },
            {
              target: "#decreaseQuantity",
              title: "Decrease Quantity",
              content:
                "From here you can also Decrease quantity. If you want to add quantity, you must add a product",
              disableBeacon: true
            },
            {
              target: "#printBarcode",
              title: "Print Barcode",
              content:
                "If you want, you can reprint or print a barcode for this product",
              disableBeacon: true
            }
          ];
          break;
        case "search_consignment_inventory":
          steps = [
            {
              target: "#searchConsignment",
              title: "Search Consignment",
              content:
                "Search the inventory here either by consigner, item, or scan the barcode",
              disableBeacon: true
            },
            {
              target: "#consignmentsSold",
              title: "Sold Consignments",
              content: "See all sold consignments here",
              disableBeacon: true
            }
          ];
          break;
        case "add_consignment_inventory":
          steps = [
            {
              target: "#addConsignmentButton",
              title: "Add Consignment (already at store)",
              content:
                "If you already have the item in the store, you can add the consignment here",
              disableBeacon: true
            },
            {
              target: "#consignerInformation",
              title: "Add Consigner Information",
              content:
                "Fill out the consigner's information here. The consigner MUST be linked to an email.",
              disableBeacon: true
            },
            {
              target: "#searchExisting",
              title: "Search for Product",
              content:
                "Search for the product by product name, SKU, or barcode.",
              disableBeacon: true
            },
            {
              target: "#addNewStyle",
              title: "Add New Style",
              content: "If you can't find the product, add a new style.",
              disableBeacon: true
            },
            {
              target: "#consignmentInformation",
              title: "Fill consignment information",
              content:
                "For this product, fill out as much consignment information as you can.",
              disableBeacon: true
            }
          ];
          break;
        case "search_solestage_inventory":
          steps = [
            {
              target: "#searchInventory",
              title: "Search for Products",
              content:
                "Here you can search for products by scanning the printed barcode, product name, product style, or brand",
              disableBeacon: true
            }
          ];
          break;
        case "add_solestage_inventory":
          steps = [
            {
              target: "#addProductButton",
              title: "Add Product",
              content:
                "Click here to add a Solestage inventory product. A Solestage inventory product is one that is owned by Solestage and not from a consigner.",
              disableBeacon: true
            },
            {
              target: "#scanBarcode",
              title: "Scan UPC Barcode",
              content: "Scan the UPC Barcode from the item.",
              disableBeacon: true,
              placement: "bottom"
            },
            {
              target: "#manualInput",
              title: "Manual Input",
              content:
                "If the item does not have a UPC Barcode, manually enter the item",
              disableBeacon: true,
              placement: "bottom"
            },
            {
              target: "#searchByProductName",
              title: "Search Product Name or Style",
              content:
                "Search for the product you're entering by name or style code",
              disableBeacon: true
            },
            {
              target: "#addNewStyle",
              title: "Add new style",
              content:
                "If you can't find the product by searching, add a new style.",
              disableBeacon: true
            }
          ];
          break;
        default:
          steps = [];
          break;
      }
      return dispatch({
        type: Constants.UPDATE_STEPS,
        steps,
        feature,
        forceJoy
      });
    };
  },

  /***
   * Sets joyride to run or not
   * @params boolean run -- run true or false
   */
  setRun: ({ run, inProgress }) => {
    return dispatch => {
      dispatch({
        type: Constants.RUN,
        run,
        inProgress
      });
    };
  },

  /***
   * Sets the step index of the joyride
   * @params int stepIndex -- the index of the step we're on
   */
  setStepIndex: stepIndex => {
    return dispatch => {
      dispatch({
        type: Constants.STEP_INDEX,
        stepIndex
      });
    };
  }
};

/**********************************
 *        REDUCER SECTION         *
 **********************************/

const defaultState = {
  run: false,
  stepIndex: 0,
  steps: [],
  feature: "",
  forceJoy: false,
  inProgress: false
};

const ResultsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case Constants.UPDATE_STEPS:
    case Constants.STEP_INDEX:
    case Constants.RUN:
      return {
        ...state,
        ...action
      };
    default:
      return state;
  }
};

export default ResultsReducer;
