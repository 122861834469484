import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// NPM Modules
import NewWindow from "react-new-window";

// Component
// import BaseModal from '../BaseModal';
import Barcode from "../../inventory/Barcode";

// Actions
import { ModalActions } from "../../../redux/modals";

class BarcodeModal extends React.Component {
  componentDidMount = () => {
    this.printTimeout = setTimeout(() => {
      let userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf(" electron/") > -1) {
        // Electron-specific code
        // printContentsSilently();
      } else {
        this.barcode.window && this.barcode.window.print();
      }
      this.props.unmount && this.props.unmount();
    }, 500);
  };

  render() {
    console.log("in-barcodes", this.props.barcodes);
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      return (
        <NewWindow onBlock={this.onBlock} ref={ref => (this.barcode = ref)}>
          {this.props.component
            ? this.props.component
            : this.props.barcodes.map(
                (barcode, index) =>
                  barcode.item && (
                    <Barcode
                      key={`${barcode.key}_${index}`}
                      barcode={barcode.barcode}
                      name={barcode.item.sku.product_name_us}
                      size={barcode.item.sku.us_size}
                      price={barcode.item.consignment.price.default_price / 100}
                      conditions={barcode.item.conditions}
                      color={barcode.item.sku.color}
                      style={barcode.item.sku.style}
                      hidePrice={barcode.item.hidePrice}
                    />
                  )
              )}
        </NewWindow>
      );
    } else {
      return (
        <NewWindow onBlock={this.onBlock} ref={ref => (this.barcode = ref)}>
          {this.props.component
            ? this.props.component
            : this.props.barcodes.map(
                (barcode, index) =>
                  barcode.item && (
                    <Barcode
                      key={`${barcode.key}_${index}`}
                      barcode={barcode.barcode}
                      name={barcode.item.sku.product_name_us}
                      size={barcode.item.sku.us_size}
                      price={barcode.item.consignment.price.default_price / 100}
                      conditions={barcode.item.conditions}
                      color={barcode.item.sku.color}
                      style={barcode.item.sku.style}
                      hidePrice={barcode.item.hidePrice}
                    />
                  )
              )}
        </NewWindow>
      );
    }
  }
}

const mapStateToProps = state => ({
  modals: state.modals
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BarcodeModal);
