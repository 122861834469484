/***
 *
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";

export default class SizeBlock extends React.Component {
  render() {
    return (
      <div
        className={css(
          styles.sizeBlock,
          !this.props.inStock && styles.outOfStock,
          this.props.activeSize && styles.activeSize
        )}
      >
        {this.props.size}
      </div>
    );
  }
}

var styles = StyleSheet.create({
  sizeBlock: {
    border: "1px solid #ddd",
    borderRadius: 4,
    height: 50,
    width: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  outOfStock: {
    color: "#ddd",
    backgroundColor: "#f7f7f7",
    cursor: "not-allowed",
    opacity: 0.4,
    boxShadow: "none"
  },
  activeSize: {
    background: "linear-gradient(to right, #cb2d3e, #ef473a)",
    color: "#fff"
  }
});
