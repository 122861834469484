import React, { Component } from "react";

// NPM Modules
import { css, StyleSheet } from "aphrodite";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import colors from "../../config/colors";

// Redux
import { AuthActions } from "../../redux/auth";
import { MessageActions } from "../../redux/message";

class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: null,
      detail: false,
      loading: false
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onPasswordChange = e => {
    this.setState({
      password: e.target.value
    });
  };

  onButtonClick = () => {
    const { showMessage, setMessage } = this.props.messageActions;
    showMessage({ show: true, load: true });

    const config = this.configureRequest();
    if (config.headers.Authorization) {
      delete config.headers.Authorization;
    }
    return fetch(API.PASSWORD_RESET_CONFIRM, config)
      .then(Helpers.parseJSON)
      .then(res => {
        this.mounted &&
          this.setState(
            {
              ...res
            },
            () => {
              this.mounted && showMessage({ show: false, load: false });
            }
          );
      })
      .catch(error => {
        showMessage({ show: false });
        setMessage(error.message.token);
        showMessage({ show: true, error: true });
      });
  };

  configureRequest = () => {
    const params = {
      uid: this.props.match.params.uid,
      token: this.props.match.params.token,
      new_password1: this.state.password,
      new_password2: this.state.password
    };
    const config = API.POST_CONFIG(params);

    return config;
  };

  render() {
    return (
      <div className={css(styles.page)}>
        {this.state.detail ? (
          <div className={css(styles.resetPasswordContainer)}>
            <div className={css(styles.passwordResetConfirmation)}>
              Password reset successful!
            </div>
            <span
              className={css(styles.link)}
              onClick={() => this.props.history.push("/login")}
            >
              {this.props.intl.formatMessage({
                id: "back_login",
                defaultMessage: "Back to Login"
              })}
            </span>
          </div>
        ) : (
          <div className={css(styles.resetPasswordContainer)}>
            <div className={css(styles.header)}>
              <div className={css(styles.title)}>Password Reset</div>
              <div className={css(styles.instructions)}>
                A password reset was requested. Enter and submit your new
                password to continue.
              </div>
              {this.state.new_password2 ? (
                <div className={css(styles.error)}>
                  {this.state.new_password2[0]}
                </div>
              ) : null}
            </div>
            <input
              type={"password"}
              className={css(styles.input)}
              value={this.state.password}
              onChange={this.onPasswordChange}
              placeholder={"New Password"}
            />
            <button className={css(styles.button)} onClick={this.onButtonClick}>
              {this.props.intl.formatMessage({
                id: "change_password",
                defaultMessage: "Change Password"
              })}
            </button>
          </div>
        )}
      </div>
    );
  }
}

let styles = StyleSheet.create({
  page: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // background: "linear-gradient(to right, #fff, #fff)",
    background: "rgb(246, 249, 252)"
  },
  input: {
    borderRadius: 4,
    border: "1px solid #ddd",
    padding: 10,
    fontSize: 18,
    marginBottom: 10,
    width: "100%",
    boxSizing: "border-box"
  },
  button: {
    color: "#fff",
    background: colors.RED(1),
    border: `1px solid ${colors.RED(1)}`,
    width: "100%",
    borderRadius: 4,
    fontSize: 16,
    padding: "16px 8px",
    cursor: "pointer",
    letterSpacing: 0.7,
    outline: "none",

    ":hover": {
      background: colors.RED(0.8)
    }
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 10
  },
  header: {
    marginBottom: 20,
    // color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  background: {
    background: "linear-gradient(to right, #cb2d3e, #ef473a)",
    position: "fixed",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1
  },
  resetPasswordContainer: {
    // background: "rgba(0, 0, 0, .75)",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    width: "90%",
    boxSizing: "border-box",
    maxWidth: 500,

    "@media only screen and (min-width: 768px)": {
      padding: 40
    }
  },
  error: {
    // width: 300,
    padding: 10,
    color: "#ffb7b7"
  },
  passwordResetConfirmation: {
    // color: "#fff",
    fontSize: 25,
    fontWeight: "bold"
  },
  link: {
    color: `${colors.RED(1)}`,
    textDecoration: "none",
    cursor: "pointer"
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  routing: state.routing
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PasswordReset)
);
