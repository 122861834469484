import React, { Component } from "react";

// NPM Modules
import { css, StyleSheet } from "aphrodite";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import colors from "../../config/colors";

// Redux
import { AuthActions } from "../../redux/auth";
import { MessageActions } from "../../redux/message";

class ConfirmEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "Something went wrong. Please try again later.",
      loading: true
    };
  }

  componentDidMount() {
    this.mounted = true;
    const { uid, token } = this.props.match.params;

    if (uid && token) {
      this.verifyEmail({ uid, token });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  verifyEmail = params => {
    const { showMessage, setMessage } = this.props.messageActions;
    showMessage({ show: true, load: true });

    // const config = API.POST_CONFIG(params);
    const config = API.GET_CONFIG();

    // return fetch(API.CONFIRM_EMAIL, config)
    return fetch(`${API.CONFIRM_EMAIL}${params.uid}/${params.token}`, config)
      .then(Helpers.parseJSON)
      .then(res => {
        this.mounted &&
          this.setState(
            {
              loading: false,
              ...res
            },
            () => {
              this.mounted && showMessage({ show: false, load: false });
            }
          );
      })
      .catch(error => {
        this.setState({ loading: false });
        showMessage({ show: false });
        setMessage(error.message.token);
        showMessage({ show: true, error: true });
      });
  };

  render() {
    if (this.state.loading) return null;

    return (
      <div className={css(styles.page)}>
        <div className={css(styles.resetPasswordContainer)}>
          <div className={css(styles.passwordResetConfirmation)}>
            {this.state.message}
          </div>
        </div>
      </div>
    );
  }
}

let styles = StyleSheet.create({
  page: {
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // background: "linear-gradient(to right, #fff, #fff)",
    background: "rgb(246, 249, 252)"
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    marginBottom: 10
  },
  header: {
    marginBottom: 20,
    // color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  background: {
    background: "linear-gradient(to right, #cb2d3e, #ef473a)",
    position: "fixed",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: -1
  },
  resetPasswordContainer: {
    // background: "rgba(0, 0, 0, .75)",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 16,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    width: "90%",
    boxSizing: "border-box",
    maxWidth: 500,

    "@media only screen and (min-width: 768px)": {
      padding: 40
    }
  },
  error: {
    // width: 300,
    padding: 10,
    color: "#ffb7b7"
  },
  passwordResetConfirmation: {
    // color: "#fff",
    fontSize: 25,
    fontWeight: "bold"
  },
  link: {
    color: `${colors.RED(1)}`,
    textDecoration: "none",
    cursor: "pointer"
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  routing: state.routing
});

const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConfirmEmail)
);
