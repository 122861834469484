/***
 * Modal to show that a shine item is already bought when scanning in
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import BaseModal from "./BaseModal";
import Button from "../Button";
import ShippingLabelModal from "./ShippingLabelModal";

// Actions
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";

class ShineAlreadySoldModal extends React.Component {
  constructor(props) {
    super(props);
    this.defaultState = {
      printShippingLabel: false,
      shippingLabelUrl: ""
    };

    this.state = {
      ...this.defaultState
    };
  }

  /***
   * closes the modal
   */
  closeModal = () => {
    let { modalActions } = this.props;
    modalActions.openShineAlreadySoldModal(false);
    this.setState({
      ...this.defaultState
    });
  };

  /**
   * create shipping label for fulfillment
   * @param  {Integer} fulfillmentId -- id of fulfillment
   * @param  {Integer} index         -- index of fulfillment
   */
  createShippingLabel = (fulfillmentId, country, index) => {
    let { messageActions } = this.props;
    let config = API.POST_CONFIG();
    messageActions.showMessage({ show: true, load: true });
    return fetch(
      API.CREATE_SHIPPING_LABEL({ fulfillment_id: fulfillmentId }),
      config
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(resp => {
        let soldItems = { ...this.props.soldItems };
        soldItems[country][index].order_information[0].fulfillment = resp;
        this.props.updateProduct(soldItems);
        this.printShippingLabel(resp);
        messageActions.showMessage({ show: false, load: false });
      });
  };

  /**
   * print shipping label
   * @param  {Object} fulfillment -- fulfillment object
   */
  printShippingLabel = fulfillment => {
    this.setState({
      printShippingLabel: true,
      shippingLabelUrl: fulfillment.label_link
    });
  };

  /**
   * close shipping modal after new window closes
   */
  closeShippingLabelModal = () => {
    this.setState({
      printShippingLabel: false
    });
  };

  renderProduct = ({ item, index }) => {
    return (
      <div className={css(styles.soldItem)}>
        <div className={css(styles.productInfo)}>
          <div className={css(styles.infoLine)}>{item.product_name_us}</div>
          <div className={css(styles.imageContainer)}>
            <img
              className={css(styles.img)}
              src={item.image}
              alt={item.product_name_us}
            />
          </div>
          <div className={css(styles.infoLine)}>
            <FormattedMessage id={"sku_tab"} defaultMessage={"sku"} />:{" "}
            {item.sku}
          </div>
          <div className={css(styles.infoLine)}>
            <FormattedMessage id={"size"} defaultMessage={"Size"} />:{" "}
            {item.us_size}
          </div>
          <div className={css(styles.infoLine)}>
            <FormattedMessage id={"sku_barcode"} defaultMessage={"Barcode"} />:{" "}
            {`ss-${item.id}`}
          </div>
        </div>
        {item.order_ship_to_address.country.toLowerCase() === "usa" ? (
          <Button
            text={
              item.order_information[0].fulfillment.tracking_number ? (
                <FormattedMessage
                  id={"print_shipping_label"}
                  defaultMessage={"Print Shipping Label"}
                />
              ) : (
                <FormattedMessage
                  id={"create_shipping_label"}
                  defaultMessage={"Create Shipping Label"}
                />
              )
            }
            onClick={
              item.order_information[0].fulfillment.tracking_number
                ? () =>
                    this.printShippingLabel(
                      item.order_information[0].fulfillment
                    )
                : () =>
                    this.createShippingLabel(
                      item.order_information[0].fulfillment.id,
                      index
                    )
            }
          />
        ) : (
          <div className={css(styles.addressContainer)}>
            <div className={css(styles.shipTo)}>
              <FormattedMessage id={"ship.to"} defaultMessage={"Ship To"} />:
            </div>
            <div className={css(styles.address)}>
              {item.order_ship_to_address.address_one}
            </div>
            <div className={css(styles.address)}>{`${
              item.order_ship_to_address.city
            }, ${item.order_ship_to_address.zip_code}, ${
              item.order_ship_to_address.country
            }`}</div>
          </div>
        )}
      </div>
    );
  };
  render() {
    let { modals } = this.props;
    let usa_items = this.props.soldItems.usa.map((item, index) => {
      return this.renderProduct({ item, index });
    });
    let china_items = this.props.soldItems.china.map((item, index) => {
      return this.renderProduct({ item, index });
    });
    return (
      <BaseModal
        isOpen={modals.openShineAlreadySoldModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <FontAwesomeIcon
          icon={["far", "times"]}
          className={css(styles.times)}
          onClick={this.closeModal}
        />
        {usa_items.length > 0 && (
          <div className={css(styles.sectionContainer)}>
            <div className={css(styles.header)}>
              <div className={css(styles.title)}>
                <FormattedMessage
                  id={"already.sold.modal.title.usa"}
                  defaultMessage={"Items Sold to USA"}
                />
              </div>
              <div className={css(styles.description)}>
                <FormattedMessage
                  id={"already.sold.modal.description.usa"}
                  defaultMessage={
                    "These scanned items have already been sold in the USA. Generate and print the shipping label. Package and prepare item for shipping"
                  }
                />
              </div>
            </div>
            <div className={css(styles.body)}>{usa_items}</div>
          </div>
        )}
        {china_items.length > 0 && (
          <div className={css(styles.sectionContainer)}>
            <div className={css(styles.header)}>
              <div className={css(styles.title)}>
                <FormattedMessage
                  id={"already.sold.modal.title.china"}
                  defaultMessage={"Items Sold to China"}
                />
              </div>
              <div className={css(styles.description)}>
                <FormattedMessage
                  id={"already.sold.modal.description.china"}
                  defaultMessage={
                    "These scanned items have already been sold to China. Send to China warehouse to handle."
                  }
                />
              </div>
            </div>
            <div className={css(styles.body)}>{china_items}</div>
          </div>
        )}
        {this.state.printShippingLabel && (
          <ShippingLabelModal
            url={this.state.shippingLabelUrl}
            closeShippingLabelModal={this.closeShippingLabelModal}
          />
        )}
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  modal: {
    maxWidth: "unset",
    maxHeight: "unset",
    width: "70%",
    zIndex: 999999
  },
  times: {
    position: "absolute",
    right: 20,
    top: 15,
    fontSize: 20
  },
  body: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  soldItem: {
    padding: 10,
    border: "1px solid #000",
    margin: 10,
    width: 200
  },
  infoLine: {
    width: "100%",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: 10
  },
  img: {
    width: 80,
    height: 80,
    objectFit: "contain"
  },
  imageContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  header: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom: 20
  },
  title: {
    fontSize: 25,
    fontWeight: 500,
    marginBottom: 10
  },
  description: {
    width: 400,
    textAlign: "center"
  },
  sectionContainer: {
    flex: 1
  },
  address: {
    textAlign: "center",
    fontWeight: "bold"
  },
  addressContainer: {
    width: "100%",
    fontSize: 18
  },
  shipTo: {
    fontWeight: "bold"
  }
});
const mapStateToProps = state => ({
  modals: state.modals
});
const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ShineAlreadySoldModal)
);
