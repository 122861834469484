import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// NPM Modules
import NewWindow from "react-new-window";

// Actions
import { ModalActions } from "../../redux/modals";

class ShippingLabelModal extends React.Component {
  render() {
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      return (
        <NewWindow
          url={this.props.url}
          onBlock={this.onBlock}
          ref={ref => (this.shipping_label = ref)}
          onUnload={this.props.closeShippingLabelModal}
        />
      );
    } else {
      return (
        <NewWindow
          url={this.props.url}
          onBlock={this.onBlock}
          ref={ref => (this.shipping_label = ref)}
          onUnload={this.props.closeShippingLabelModal}
        />
      );
    }
  }
}

const mapStateToProps = state => ({
  modals: state.modals
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingLabelModal);
