// NPM Modules
import React from "react";
import numeral from "numeral";
import { injectIntl } from "react-intl";

const CurrencyDisplay = props => {
  let { intl, format } = props;
  let price = parseFloat(props.price, 10);
  return (
    <div>
      {intl.locale === "en"
        ? numeral(price).format(format ? format : "($0,0[.]00)")
        : new Intl.NumberFormat("zh-CN", {
            style: "currency",
            currency: "CNY"
          }).format(price)}
    </div>
  );
};

export default injectIntl(CurrencyDisplay);
