import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// NPM Modules
import NewWindow from "react-new-window";
import { StyleSheet, css } from "aphrodite";

// Actions
import { ModalActions } from "../../../redux/modals";

import "./pageStyle.css";

class ManifestModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalPrice: 0,
      totalQuantity: 0,
      items: null
    };
  }
  componentDidMount = () => {
    if (this.props.items) {
      let totalPrice = 0;
      let totalQuantity = 0;
      this.props.items.forEach((item, index) => {
        if (this.props.status[index] === "approved") {
          totalPrice +=
            (item.consignment.price
              ? item.consignment.price.default_price
              : 0) / 100;
          totalQuantity++;
        }
      });
      this.setState({ totalPrice, totalQuantity, items: this.props.items });
    }
    this.loadPrint = setTimeout(() => {
      let userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf(" electron/") > -1) {
        // Electron-specific code
        // printContentsSilently();
      } else {
        this.manifest.window && this.manifest.window.print();
      }
      this.props.closeManifestModal();
    }, 200);
  };

  render() {
    return (
      <NewWindow onBlock={this.onBlock} ref={ref => (this.manifest = ref)}>
        <div className={css(styles.manifestWrapper)}>
          <div className={css(styles.title, styles.orderId)}>
            Consignment Order #{this.props.consignment_order_id}
          </div>
          <div className={css(styles.orderInfoWrapper)}>
            <div className={css(styles.orderInfoFirst)}>
              <div>To: {this.props.location}</div>
              <div>
                Date:{" "}
                {new Date(this.props.created_at).toLocaleDateString("en-US")}
              </div>
            </div>
            Consigner: {this.props.consigner}
          </div>
          <div className={css(styles.packingListWrapper)}>
            <div className={css(styles.packingListInfoWrapper)}>
              <div className={css(styles.title, styles.packingTitle)}>
                Packing List
              </div>
              <div className={css(styles.info)}>
                Total Price: ${this.state.totalPrice}
              </div>
              <div className={css(styles.info)}>
                Total Quantity: {this.state.totalQuantity}
              </div>
            </div>
            <div>
              <table className={css(styles.tableWrapper)}>
                <tr>
                  <th className={css(styles.tableCell)}>ID</th>
                  <th className={css(styles.tableCell)}>Name</th>
                  <th className={css(styles.tableCell)}>Style</th>
                  <th className={css(styles.tableCell)}>Size</th>
                  <th className={css(styles.tableCell)}>Color</th>
                  <th className={css(styles.tableCell)}>Price</th>
                </tr>
                {this.state.items &&
                  this.state.items.map((item, index) => {
                    if (this.props.status[index] === "approved") {
                      return (
                        <tr
                          style={{
                            pageBreakInside: "avoid",
                            pageBreakAfter: "auto"
                          }}
                        >
                          <td className={css(styles.tableCell)}>#{item.id}</td>
                          <td className={css(styles.tableCell)}>
                            {item.sku.product_name_us}
                          </td>
                          <td className={css(styles.tableCell)}>
                            {item.sku.product.style}
                          </td>
                          <td className={css(styles.tableCell)}>
                            {item.sku.us_size}
                          </td>
                          <td className={css(styles.tableCell)}>
                            {item.sku.color}
                          </td>
                          <td className={css(styles.tableCell)}>
                            $
                            {(item.consignment.price
                              ? item.consignment.price.default_price
                              : 0) / 100}
                          </td>
                        </tr>
                      );
                    }
                    return null;
                  })}
              </table>
            </div>
            <div className={css(styles.signature)}>
              <div>Signature: </div>
              <div className={css(styles.signatureField)} />
            </div>
          </div>
        </div>
      </NewWindow>
    );
  }
}

var styles = StyleSheet.create({
  manifestWrapper: {
    padding: 30
  },
  title: {
    fontWeight: "bold",
    fontSize: 18
  },
  orderInfoWrapper: {
    marginTop: 20,
    fontSize: 13
  },
  orderInfoFirst: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10
  },
  packingListWrapper: {
    marginTop: 30
  },
  packingListInfoWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  packingTitle: {
    fontSize: 15,
    marginRight: 20
  },
  info: {
    fontSize: 12
  },
  tableWrapper: {
    fontSize: 12,
    width: "100%",
    textAlign: "left",
    marginTop: 10,
    borderCollapse: "collapse",
    pageBreakInside: "auto"
  },
  tableCell: {
    border: "1px solid black",
    padding: 3
  },
  signature: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    fontSize: 13,
    width: "100%",
    height: 20
  },
  signatureField: {
    borderBottom: "1px solid black",
    minWidth: 300,
    height: "100%",
    marginLeft: 10
  }
});

const mapStateToProps = state => ({
  modals: state.modals
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManifestModal);
