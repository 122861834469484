//NPM
import React from "react";
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";

//COMPONENT
import SKUTypes from "./SKUTypes";

class SKUTypeList extends React.Component {
  constructor(props) {
    super(props);

    this.gender = [
      {
        id: "Unisex",
        label: this.props.intl.formatMessage({
          id: "unisex",
          defaultMessage: "Unisex"
        })
      },
      {
        id: "Men",
        label: this.props.intl.formatMessage({
          id: "men",
          defaultMessage: "Men"
        })
      },
      {
        id: "Women",
        label: this.props.intl.formatMessage({
          id: "women",
          defaultMessage: "Women"
        })
      },
      {
        id: "Grade School",
        label: this.props.intl.formatMessage({
          id: "grade_school",
          defaultMessage: "Grade School"
        })
      },
      {
        id: "Pre School",
        label: this.props.intl.formatMessage({
          id: "pre_school",
          defaultMessage: "Pre School"
        })
      },
      {
        id: "Toddler",
        label: this.props.intl.formatMessage({
          id: "toddler",
          defaultMessage: "Toddler"
        })
      },
      {
        id: "Infant",
        label: this.props.intl.formatMessage({
          id: "infant",
          defaultMessage: "Infant"
        })
      }
    ];

    this.types = [
      {
        id: "shoe",
        label: this.props.intl.formatMessage({
          id: "shoe",
          defaultMessage: "Shoes"
        })
      },
      {
        id: "headwear",
        label: this.props.intl.formatMessage({
          id: "headwear",
          defaultMessage: "Headwear"
        })
      },
      {
        id: "jacket",
        label: this.props.intl.formatMessage({
          id: "jacket",
          defaultMessage: "Jacket"
        })
      },
      {
        id: "shirt",
        label: this.props.intl.formatMessage({
          id: "shirt",
          defaultMessage: "Shirt"
        })
      },
      {
        id: "t-shirt",
        label: this.props.intl.formatMessage({
          id: "t-shirt",
          defaultMessage: "T-Shirt"
        })
      },
      {
        id: "top/sweatshirt",
        label: this.props.intl.formatMessage({
          id: "top/sweatshirt",
          defaultMessage: "Tops/Sweatshirts"
        })
      },
      {
        id: "bags",
        label: this.props.intl.formatMessage({
          id: "bags",
          defaultMessage: "Bags"
        })
      },
      {
        id: "pants",
        label: this.props.intl.formatMessage({
          id: "pants",
          defaultMessage: "Pants"
        })
      },
      {
        id: "accessory",
        label: this.props.intl.formatMessage({
          id: "accessory",
          defaultMessage: "Accessory"
        })
      }
    ];
  }

  /**
   * highlights the div that the user clicks, and sends the information to parent to be saved in state
   * @param { String } type_id - the value of the div clicked by user
   */
  handleTypeClick = type_id => {
    let { listType } = this.props;
    this.props.handleListType(type_id, listType);
  };

  render() {
    let { label, listType, gender, item_type } = this.props;
    let list = listType === "gender" ? this.gender : this.types;

    return (
      <div className={css(styles.productTypeWrapper)}>
        <div className={css(styles.productTypeLabel)}>{label}</div>
        <div className={css(styles.productTypeList)}>
          {list.map((type, index) => {
            return (
              <SKUTypes
                key={type.id}
                listType={listType}
                type={type}
                index={index}
                highlight={listType === "gender" ? gender : item_type}
                handleTypeClick={this.handleTypeClick}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

let styles = StyleSheet.create({
  productTypeWrapper: {
    display: "flex",
    flexDirection: "column"
  },
  productTypeLabel: {
    paddingLeft: 10,
    fontWeight: 300,
    margin: "10px 0px 10px 0px"
  },
  productTypeList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "intial",
    minWidth: 825,
    maxWidth: 825,
    padding: "0px 10px 0px 10px"
  }
});

export default injectIntl(SKUTypeList);
