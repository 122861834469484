import React, { Component, Fragment } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { addLocaleData, IntlProvider } from "react-intl";
import "react-dates/initialize";
import * as Sentry from "@sentry/browser";
import { get } from "lodash";

// Supported Data Locales
import en from "react-intl/locale-data/en";
import zh from "react-intl/locale-data/zh";

// Screens
import Dashboard from "../dashboard/Dashboard";
import Clover from "../clover/Clover";
import Stockx from "../stockx/Stockx";
import Auth from "../auth/Auth";
import PasswordReset from "../auth/PasswordReset";
import ConfirmEmail from "../auth/ConfirmEmail";
import {
  StripeAuth,
  StripeOnboarding,
  StripeOnboardingReturn
} from "../stripe";
import Users from "../user/Users";
import Orders from "../orders/Orders";
import SpecificOrder from "../orders/SpecificOrder";
import Terms from "../terms/Terms";
import Eula from "../terms/Eula";
import Privacy from "../terms/Privacy";
import FirstLogin from "../auth/FirstLogin";
import Consignment from "../consignment/Consignment";
import PaypalAuth from "../auth/PaypalAuth";
import Sku from "../sku/Sku";
import Consigners from "../user/Consigners";
import Tutorials from "../tutorials/Tutorials";
import FeatureOnboard from "../tutorials/FeatureOnboard";
import Payouts from "../user/Payouts";
import Shipping from "../shipping/Shipping";
import ItemManagement from "../../components/modals/ItemManagement/ItemManagement";
import AuditTrail from "../audit/AuditTrail";
import PriceChange from "../price/PriceChange";
import InventoryReconciliation from "../reconciliation/InventoryReconciliation";
import InventoryReconciliationSessions from "../reconciliation/InventoryReconciliationSessions";
import ScannedItems from "../reconciliation/ScannedItems";
import MissingItems from "../reconciliation/MissingItems";
import InventoryItem from "../consignment/InventoryItem";
import Inventory from "../consignment/Inventory";
import InventoryStyle from "../consignment/InventoryStyle";
import Permissions from "../user/Permissions";

// Components
import "../../components/FontAwesome";
import Sidebar from "../../components/navigation/Sidebar";
import ProtectedRoute from "../../components/routing/ProtectedRoute";
import Message from "../../components/Message";
// import PollConsignmentPrint from '../../components/consignment/PollConsignmentPrint';

// Redux
import { LocalizationActions } from "../../redux/localization";
import { AuthActions } from "../../redux/auth";
import { InventoryActions } from "../../redux/inventory";

// Stylesheets
import "./stylesheets/PlaceholderAnimation.css";

// Add in data locales
addLocaleData([...en, ...zh]);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5ba0567785804f2886a5be007afdefb2@sentry.io/1765164",
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment:
      process.env.REACT_APP_ENV === "staging" ? "staging" : "production"
  });
}

class App extends Component {
  componentDidMount() {
    let { localizationActions } = this.props;
    localizationActions.initializeLocale();

    let { auth, authActions } = this.props;
    if (
      !auth.isLoggedIn &&
      !["/eula", "/terms", "/privacy", "/paypal"].includes(
        this.props.routing.location.pathname
      )
    ) {
      authActions.getUser().then(auth => {
        let that = this;

        if (auth.isLoggedIn) {
          let { inventoryActions } = this.props;
          inventoryActions.getLocations();
        }

        if (auth.isLoggedIn && auth.userProfile.first_login) {
          this.props.history.push("/login/first");
        } else if (auth.isLoggedIn && !auth.userProfile.first_login) {
          if (
            that.props.routing.location.pathname &&
            !["/login", "/signup", "/"].includes(
              that.props.routing.location.pathname
            )
          ) {
            this.props.history.push(that.props.routing.location.pathname);
          } else {
            this.props.history.push("/dashboard");
          }
        } else if (
          this.props.history.location.pathname !== "/signup" &&
          !this.props.history.location.pathname.includes("/password") &&
          !this.props.history.location.pathname.includes("/confirm-email") &&
          !this.props.history.location.pathname.includes("/stripe")
        ) {
          this.props.history.push({
            pathname: "/login",
            state: {
              path: this.props.history.location.pathname
            }
          });
        }
      });
    }
  }

  componentWillReceiveProps = (nextProps, nextState) => {
    if (nextProps.auth.isLoggedIn && !this.props.auth.isLoggedIn) {
      let { inventoryActions } = this.props;
      inventoryActions.getLocations();
    }

    if (
      nextProps.isLoggedIn &&
      !get(this.props, "auth.userProfile.role") &&
      get(nextProps, "auth.userProfile.role")
    ) {
      if (nextProps.auth.userProfile.role.toLowerCase() === "retail floor") {
        this.props.history.push("/consignment/inventory");
      }
    }
  };

  render() {
    let { localization, auth, routing } = this.props;

    let allowUsers = ["Admin", "Warehouse Manager"].includes(
      auth.userProfile.role
    ); //auth.userProfile.permissions && ['auth.change_group'].every((val) => (auth.userProfile.permissions.indexOf(val) >= 0));

    let auditUsers =
      auth.userProfile.role &&
      ["admin", "warehouse manager", "accountant"].includes(
        auth.userProfile.role.toLowerCase()
      );

    let accountantUsers =
      auth.userProfile.role &&
      ["admin", "accountant"].includes(auth.userProfile.role.toLowerCase());

    let consignerUsers =
      auth.userProfile.role &&
      [
        "admin",
        "accountant",
        "store manager",
        "store supermanager",
        "warehouse manager"
      ].includes(auth.userProfile.role.toLowerCase());

    let floorUsers =
      auth.userProfile.role &&
      ["retail floor"].includes(auth.userProfile.role.toLowerCase());

    const dashboardUsers = !floorUsers;
    /*
     * We need to force re-mount all our components when there is a language change
     * And we also need it to happen after the user cookie/session for language is actually
     * updated so we can get the proper result set
     * */

    // if (localization.updatingSessionLanguage) {
    //   return this.renderLanguageChange();
    // }

    let showSidebar =
      !["/eula", "/terms", "/privacy", "/paypal"].includes(
        routing.location.pathname
      ) && !routing.location.pathname.match(/\/stripe/);

    const LocationRoute = ({ component: Component, ...rest }) => {
      const locationId =
        this.props.auth.userProfile.location &&
        this.props.auth.userProfile.location.id;
      return (
        <Route
          {...rest}
          render={props => (
            <Component
              locationId={this.props.locationId || locationId}
              {...props}
            />
          )}
        />
      );
    };

    return (
      <IntlProvider
        key={localization.language}
        locale={localization.language}
        messages={localization.messages}
      >
        <div className={css(styles.app)}>
          {auth.authChecked ||
          ["/eula", "/terms", "/privacy", "/paypal"].includes(
            this.props.routing.location.pathname
          ) ||
          routing.location.pathname.match(/\/stripe/) ? (
            <Fragment>
              {auth.isLoggedIn && showSidebar && <Sidebar />}
              <div
                className={css(styles.body, auth.isLoggedIn && styles.loggedIn)}
              >
                <Switch>
                  <Route path="/clover" component={Clover} />
                  <Route path="/login/first" component={FirstLogin} />
                  <Route
                    path="/password/reset/:uid/:token"
                    render={props => <PasswordReset {...props} />}
                  />
                  <Route
                    path="/login"
                    render={props => <Auth login={true} {...props} />}
                  />
                  <Route
                    path="/signup"
                    render={props => <Auth login={false} {...props} />}
                  />
                  <Route
                    path="/stripe_connect/:location"
                    render={props => <StripeAuth {...props} />}
                  />
                  <Route
                    path="/stripe_onboarding"
                    render={props => <StripeOnboarding {...props} />}
                  />
                  <Route
                    path="/stripe_onboarding_return"
                    render={props => <StripeOnboardingReturn {...props} />}
                  />
                  <ProtectedRoute
                    path="/payouts/history"
                    component={props => (
                      <Payouts payoutHistory={true} {...props} />
                    )}
                    allow={accountantUsers}
                  />
                  <ProtectedRoute
                    path="/payouts"
                    component={Payouts}
                    allow={accountantUsers}
                  />
                  <ProtectedRoute
                    path="/users"
                    component={Users}
                    allow={allowUsers}
                  />
                  <Route
                    path="/inventory/inventory/:id"
                    component={InventoryItem}
                  />
                  <Route
                    path="/inventory/products/:styleId"
                    component={InventoryStyle}
                  />
                  <Route path="/inventory/:type" component={Inventory} />

                  {/* <Route
                    path="/consignment/inventory"
                    component={AllBarcodes}
                  />
                  <LocationRoute
                    path="/consignment/print"
                    component={AllBarcodes}
                  /> */}
                  <LocationRoute
                    path="/orders/:orderStatus"
                    component={Orders}
                  />
                  <Route path="/consignment" component={Consignment} />
                  <Route path="/order/:order_id" component={SpecificOrder} />
                  <Route
                    exact
                    path="/product/:locationId/:productId"
                    component={ItemManagement}
                  />
                  <Route
                    path="/product/:locationId/:productId/:search"
                    component={ItemManagement}
                  />
                  <LocationRoute
                    exact
                    path="/dashboard"
                    component={Dashboard}
                    allow={dashboardUsers}
                    redirect="/"
                  />
                  <Route path="/terms" component={Terms} />
                  <Route path="/eula" component={Eula} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/paypal" component={PaypalAuth} />
                  <Route path="/shipping" component={Shipping} />
                  <ProtectedRoute
                    path="/consigners"
                    component={Consigners}
                    allow={consignerUsers}
                  />
                  <Route
                    path="/sku"
                    render={props => (
                      <Sku {...this.props} localization={localization} />
                    )}
                  />
                  <Route path="/tutorials" component={Tutorials} />
                  <ProtectedRoute
                    path="/audit"
                    component={AuditTrail}
                    allow={auditUsers}
                  />
                  <LocationRoute path="/price" component={PriceChange} />
                  <LocationRoute
                    exact
                    path="/reconciliation"
                    component={InventoryReconciliation}
                  />
                  <Route
                    exact
                    path="/reconciliation/:reconciliationId"
                    component={InventoryReconciliationSessions}
                  />
                  <Route
                    exact
                    path="/reconciliation/:reconciliationId/missing"
                    component={MissingItems}
                  />
                  <Route
                    path="/reconciliation/:reconciliationId/:sessionId"
                    component={ScannedItems}
                  />
                  <Route path="/permissions/:userId" component={Permissions} />
                  <Route
                    path="/api/auth/confirm-email/:uid/:token"
                    component={ConfirmEmail}
                  />
                  <Route path="/stockx" component={Stockx} />
                </Switch>
              </div>
              <Message />
              <FeatureOnboard />
              {/*<PollConsignmentPrint />*/}
            </Fragment>
          ) : null}
        </div>
      </IntlProvider>
    );
  }
}

const styles = StyleSheet.create({
  app: {
    display: "flex",
    minHeight: "100vh",
    position: "relative",
    fontFamily: "Helvetica, sans-serif"
  },
  body: {
    flex: 1,
    boxSizing: "border-box",
    position: "relative"
  },
  loggedIn: {
    maxWidth: "calc(100% - 200px)",
    marginLeft: "auto",
    "@media only screen and (max-width: 600px)": {
      maxWidth: "none",
      marginLeft: "200px"
    }
  }
});

const mapStateToProps = state => ({
  localization: state.localization,
  auth: state.auth,
  routing: state.routing,
  locationId: state.inventory.locationId
});

const mapDispatchToProps = dispatch => ({
  localizationActions: bindActionCreators(LocalizationActions, dispatch),
  authActions: bindActionCreators(AuthActions, dispatch),
  inventoryActions: bindActionCreators(InventoryActions, dispatch)
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
