import React, { Component } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  LineChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Line,
  Legend
} from "recharts";

class ChartCard extends Component {
  tickFormatter(tick) {
    if (this.props.tickFormatter) {
      return this.props.tickFormatter(tick);
    } else {
      return tick;
    }
  }

  render() {
    return (
      <div className={css(styles.chartCard)}>
        <div className={css(styles.header)}>
          <div className={css(styles.totalContainer)}>
            <div className={css(styles.totalTitle)}>
              {this.props.totalTitle}
            </div>
            <div className={css(styles.total)}>{this.props.total}</div>
          </div>
        </div>
        <LineChart
          width={650}
          height={320}
          data={this.props.graph}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis dataKey="name" />
          <YAxis
            tickFormatter={tick => this.tickFormatter(tick)}
            padding={{ bottom: 30 }}
          />
          <Tooltip content={this.props.customTooltip} />
          <Legend className={css(styles.legend)} />
          <CartesianGrid strokeDasharray="3 3" />
          <Line
            name={this.props.current_name}
            type="monotone"
            dataKey={this.props.current_dataKey}
            stroke="#bca810"
            activeDot={{ r: 6 }}
          />
          <Line
            name={this.props.prev_name}
            type="monotone"
            dataKey={this.props.prev_dataKey}
            stroke="#387908"
            activeDot={{ r: 6 }}
          />
        </LineChart>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  chartCard: {
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    paddingTop: 20,
    paddingBottom: 30,
    marginBottom: 20,
    width: 680
  },
  totalContainer: {
    width: "100%",
    paddingLeft: 30,
    marginBottom: 20
  },
  totalTitle: {
    fontSize: 16
  },
  total: {
    fontSize: 25
  }
});

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ChartCard));
