import { take, fork, call, actionChannel } from "redux-saga/effects";
import { buffers } from "redux-saga";
import Actions from "./actions";
import Helpers from "../helpers";
import API from "../../config/api";

const {
  inventory: { queryInventory, querySku, queryConsignmentOrder }
} = Actions;

class InventorySaga {
  *init() {
    yield fork(saga.searchQueue);
  }

  *searchQueue() {
    const channel = yield actionChannel(
      [queryInventory, querySku, queryConsignmentOrder],
      buffers.sliding(1)
    );
    while (true) {
      const action = yield take(channel);
      if (action) {
        switch (action.type) {
          case queryInventory.toString():
            yield saga.queryInventory(action);
            break;
          case querySku.toString():
            yield saga.querySku(action);
            break;
          case queryConsignmentOrder.toString():
            yield saga.queryConsignmentOrder(action);
            break;
          default:
            break;
        }
      }
    }
  }

  *queryInventory({
    payload: { params, type, onStart, onSuccess, onFailure }
  }) {
    let url = "";
    if (type === "item") {
      url = API.INVENTORY(params);
    } else if (type === "style") {
      url = API.TRUE_STYLE(params);
    }

    let config = API.GET_CONFIG(false);
    try {
      onStart && onStart();

      // search for ss number first when searching for products
      if (type === "style" && params["search"] && !isNaN(params["search"])) {
        const preInventory = yield fetch(
          API.INVENTORY({ id: params["search"] }),
          API.GET_CONFIG(false)
        )
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON);
        params["search"] = preInventory.sku.style;
        url = API.TRUE_STYLE(params);
      }

      const result = yield fetch(url, config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON);
      onSuccess && (yield call(onSuccess, result));
    } catch (error) {
      onFailure && (yield call(onFailure, error));
    }
  }

  *querySku({ payload: { params, onStart, onSuccess, onFailure } }) {
    let url = API.TRUE_STYLE(params);

    let config = API.GET_CONFIG(false);
    try {
      onStart && onStart();
      const result = yield fetch(url, config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON);
      onSuccess && (yield call(onSuccess, result));
    } catch (error) {
      onFailure && (yield call(onFailure, error));
    }
  }

  *queryConsignmentOrder({
    payload: { params, onStart, onSuccess, onFailure }
  }) {
    let url = API.CONSIGNMENT_ORDERS(params);
    let config = API.GET_CONFIG(false);
    try {
      onStart && onStart();
      const result = yield fetch(url, config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON);
      onSuccess && (yield call(onSuccess, result));
    } catch (error) {
      onFailure && (yield call(onFailure, error));
    }
  }
}

const saga = new InventorySaga();

export default saga;
