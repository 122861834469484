import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";

// Config
import colors from "../../../../config/colors";

const VariantEntry = ({
  variant,
  index,
  handleBarcode,
  skus,
  handleDelete
}) => {
  return (
    <div className={css(styles.variantEntry)}>
      <input
        className={css(styles.checkBox)}
        type="checkbox"
        onChange={() => handleDelete(index)}
        checked
      />
      <div className={css(styles.sizeWrapper)}>
        <p className={css(styles.size)}>{variant.id}</p>
      </div>
      <input
        className={css(styles.barcode)}
        type="text"
        value={skus[variant.id].barcode}
        onChange={e => handleBarcode(variant.id, e.target.value)}
      />
    </div>
  );
};

let styles = StyleSheet.create({
  variantEntry: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: 10,
    height: 35,
    width: 820,
    borderTop: "1px solid #ddd"
  },
  checkBox: {
    height: 20,
    width: 20
  },
  sizeWrapper: {
    width: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  size: {
    fontSize: 14,
    fontFamily: " system-ui"
  },
  barcode: {
    height: 35,
    borderRadius: 3,
    outline: "none",
    fontFamily: " system-ui",
    fontSize: 14,
    fontWeight: 200,
    cursor: "text",
    border: "1px solid #ddd",
    transition: "all 0.30s ease-in-out",
    paddingLeft: 10,
    marginLeft: 20,
    ":focus": {
      border: `1px solid ${colors.LIGHT_BLUE(1)}`,
      boxShadow: `0px 0px 5px  ${colors.LIGHT_BLUE(1)}`
    }
  }
});

export default VariantEntry;
