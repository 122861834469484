/***
 * Screen to invite a user and manage all of your users
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

// Redux
import { ModalActions } from "../../redux/modals";
import { JoyrideActions } from "../../redux/joyride";

class Tutorials extends React.Component {
  constructor(props) {
    super(props);
    let { intl } = this.props;
    this.solestageGuides = [
      {
        id: "add_inventory",
        label: intl.formatMessage({
          id: "add_inventory",
          defaultMessage: "Add Solestage Inventory"
        }),
        onClick: this.addSolestageInventory
      },
      {
        id: "search_inventory",
        label: intl.formatMessage({
          id: "search_inventory",
          defaultMessage: "Search Solestage Inventory"
        }),
        onClick: this.searchSolestageInventory
      },
      {
        id: "change_price",
        label: intl.formatMessage({
          id: "change_price",
          defaultMessage: "Change Price & Decrease Stock Quantity"
        }),
        onClick: this.changeInventoryPrice
      }
    ];

    this.consignmentGuides = [
      {
        id: "add_consignment",
        label: intl.formatMessage({
          id: "add_consignment_already",
          defaultMessage: "Add Consignment Item (item already in store)"
        }),
        onClick: this.addConsignment
      },
      {
        id: "search_consignment_inventory",
        label: intl.formatMessage({
          id: "search_consignment",
          defaultMessage: "Search Consignment Inventory"
        }),
        onClick: this.searchConsignmentInventory
      },
      {
        id: "approve_consignment",
        label: intl.formatMessage({
          id: "approve_consignment",
          defaultMessage: "Approve Consignment (First Approval)"
        }),
        onClick: this.approveConsignment
      },
      {
        id: "list_consignment",
        label: intl.formatMessage({
          id: "list_consignment",
          defaultMessage:
            "List Consignment (When Customer Brings item to Store)"
        }),
        onClick: this.listApproval
      }
    ];
  }

  listApproval = () => {
    let { joyrideActions } = this.props;
    joyrideActions.setStepIndex(0);
    joyrideActions.setSteps({ feature: "list_consignment", forceJoy: true });
    this.props.history.push("/consignment?guide=true");
  };

  approveConsignment = () => {
    let { joyrideActions } = this.props;
    joyrideActions.setStepIndex(0);
    joyrideActions.setSteps({ feature: "approve_consignment", forceJoy: true });
    this.props.history.push("/consignment?guide=true");
  };

  changeInventoryPrice = () => {
    let { joyrideActions } = this.props;
    joyrideActions.setStepIndex(0);
    joyrideActions.setSteps({
      feature: "change_inventory_price",
      forceJoy: true
    });
    this.props.history.push("/inventory?guide=true");
  };

  searchSolestageInventory = () => {
    let { joyrideActions } = this.props;
    joyrideActions.setStepIndex(0);
    joyrideActions.setSteps({
      feature: "search_solestage_inventory",
      forceJoy: true
    });
    this.props.history.push("/inventory?guide=true");
  };

  addConsignment = () => {
    let { joyrideActions } = this.props;
    joyrideActions.setStepIndex(0);
    joyrideActions.setSteps({
      feature: "add_consignment_inventory",
      forceJoy: true
    });
    this.props.history.push("/consignment?guide=true");
  };

  searchConsignmentInventory = () => {
    let { joyrideActions } = this.props;
    joyrideActions.setStepIndex(0);
    joyrideActions.setSteps({
      feature: "search_consignment_inventory",
      forceJoy: true
    });
    this.props.history.push("/consignment/inventory?guide=true");
  };

  addSolestageInventory = () => {
    let { joyrideActions } = this.props;
    joyrideActions.setStepIndex(0);
    joyrideActions.setSteps({
      feature: "add_solestage_inventory",
      forceJoy: true
    });
    this.props.history.push("/inventory?guide=true");
  };

  render() {
    let { intl } = this.props;
    let solestage = this.solestageGuides.map((guide, index) => {
      return (
        <div className={css(styles.guide)} onClick={guide.onClick}>
          {guide.label}
        </div>
      );
    });

    let consignment = this.consignmentGuides.map((guide, index) => {
      return (
        <div className={css(styles.guide)} onClick={guide.onClick}>
          {guide.label}
        </div>
      );
    });

    return (
      <div className={css(styles.guides)}>
        <div className={css(styles.top)}>
          <h2>{` Solestage ${intl.formatMessage({
            id: "inventory_guides",
            defaultMessage: "Inventory Guides"
          })}`}</h2>
          {solestage}
        </div>
        <div className={css(styles.bottom)}>
          <h2>
            {" "}
            {`${intl.formatMessage({
              id: "consignment_tab",
              defaultMessage: "Consignment"
            })} ${intl.formatMessage({
              id: "inventory_guides",
              defaultMessage: "Inventory Guides"
            })}`}{" "}
          </h2>
          {consignment}
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  guides: {
    padding: 16,
    paddingLeft: 48
    // display: 'flex',
    // alignItems: 'center',
  },
  bottom: {
    marginTop: 48
  },
  guide: {
    padding: 16,
    display: "flex",
    letterSpacing: 0.7,
    fontSize: 18,
    cursor: "pointer",
    alignItems: "center",
    width: 500,
    border: "1px solid #ddd",
    borderRadius: 4,
    margin: 16,
    boxShadow: "rgba(129,148,167,0.39) 0px 3px 10px 0px"
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  joyrideActions: bindActionCreators(JoyrideActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Tutorials));
