import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import ReactModal from "react-modal";

// Stylesheets
import "./stylesheets/BaseModal.css";

class BaseModal extends React.Component {
  render() {
    let combinedOverlayStyles = {
      ...overlayStyles
    };

    if (this.props.overlayStyles) {
      combinedOverlayStyles = {
        overlay: {
          ...overlayStyles.overlay,
          ...this.props.overlayStyles.overlay
        }
      };
    }

    return (
      <ReactModal
        className={css(styles.modal, this.props.modalClassName)}
        isOpen={this.props.isOpen}
        contentLabel={this.props.contentLabel}
        onAfterOpen={this.props.afterOpen || this.props.onAfterOpen}
        onRequestClose={this.props.closeModal}
        ref={ref => this.props.setModalRef && this.props.setModalRef(ref)}
        closeTimeoutMS={300}
        appElement={document.getElementById("root")}
        style={combinedOverlayStyles}
      >
        <div className={css(styles.bodyContainer, this.props.bodyContainer)}>
          {this.props.children}
        </div>
      </ReactModal>
    );
  }
}

const overlayStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.2)",
    zIndex: "11"
  }
};

var styles = StyleSheet.create({
  modal: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
    background: "#fff",
    outline: "none",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    overflow: "scroll",
    boxShadow: "0 0 50px rgba(0,0,0,0.1)",
    width: "80%",
    fontFamily: "Helvetica, sans-serif",
    maxWidth: 600,
    minWidth: 300
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 25,
    maxHeight: 700
  }
});

export default BaseModal;
