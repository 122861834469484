/***
 * Screen to invite a user and manage all of your users
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import PaymentMethodDropdown from "../../components/PaymentMethodDropdown";
import moment from "moment";
import { DateRangePicker } from "react-dates";
import isAfterDay from "react-dates/lib/utils/isAfterDay";

// Components
import ConsignerPayoutModal from "../../components/modals/ConsignerPayoutModal";
import PayoutHistoryModal from "../../components/modals/PayoutHistoryModal";
import PayoutStatus from "../../components/PayoutStatus";
import CurrencyDisplay from "../../components/CurrencyDisplay";

// Redux
import { ModalActions } from "../../redux/modals";

// API
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import { MessageActions } from "../../redux/message";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button as BootstrapButton } from "react-bootstrap";

class Payouts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      page: 1,
      payouts: [],
      shoeRateClicked: null,
      nonShoeRateClicked: null,
      payoutsTotal: 0,
      shoe_consignment_rate: 15,
      non_shoe_consignment_rate: 20,
      fetchingData: false,
      payoutCheck: false,
      paymentType: null,
      startDate: null,
      endDate: null,
      focusedInput: null
    };
  }

  componentWillMount() {
    this.loadMore(this.state.page, this.state.search);
  }

  /**
   * Loads more payouts
   */
  loadMore = page => {
    if (
      this.state.payouts.length < this.state.payoutsTotal ||
      !this.state.payoutsTotal
    ) {
      this.getPayouts(page);
    }
  };

  /**
   * Gets all payouts
   */
  getPayouts = (page, e) => {
    e && e.preventDefault();
    let config = API.GET_CONFIG();
    let { messageActions } = this.props;
    messageActions.showMessage({ show: true, load: true });
    let url = API.REQUEST_PAYOUT + `?page=${page}`;
    if (this.props.payoutHistory) {
      url += "&state__ne=open,closed&ordering=-success_at";
    } else {
      url += "&state=open&ordering=requested_at";
    }

    let { search, paymentType } = this.state;

    if (search) {
      url += `&search=${search}`;
    }

    if (paymentType && paymentType !== "All") {
      url += `&payout_type=${paymentType}`;
    }

    let startDate = null;
    let endDate = null;
    if (this.state.startDate) {
      startDate = this.state.startDate.format("YYYY-MM-DD");
      url += `&created_at__date__gte=${startDate}`;
    }

    if (this.state.endDate) {
      endDate = this.state.endDate.format("YYYY-MM-DD");
      if (endDate === startDate) {
        endDate = moment(endDate).format("YYYY-MM-DD");
      }
      url += `&created_at__date__lte=${endDate}`;
    }

    return fetch(url, config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        messageActions.showMessage({ show: false });
        this.setState({
          payouts:
            page > 1 ? [...this.state.payouts, ...res.results] : res.results,
          payoutsTotal: res.count,
          page: page
        });
      })
      .catch(error => {
        messageActions.showMessage({ show: false });
      });
  };

  exportPayouts = async () => {
    const { payoutHistory, auth } = this.props;
    const querystring = payoutHistory ? { history: true } : {};
    const url = API.REQUEST_PAYOUT_2({
      rest: { route: "download_payout_report" },
      querystring
    });
    const config = API.GET_CONFIG();
    try {
      await fetch(url, config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          alert(
            `${
              payoutHistory ? "Payout history" : "Requested payouts"
            } report will be sent to ${auth.userProfile.email}`
          );
        })
        .catch(async error => {
          const { detail } = await Helpers.parseJSON(error.response);
          alert(detail);
        });
    } catch (error) {
      alert("There was an error requesting the export");
    } finally {
      this.setState({ exportingPayouts: false });
    }
  };

  exportPayoutsSummary = async () => {
    const { auth } = this.props;

    const url = API.REQUEST_PAYOUT_2({
      rest: { route: "download_summary_payout_report" }
    });
    const config = API.GET_CONFIG();
    try {
      await fetch(url, config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          alert(
            `Requested payouts summary report will be sent to ${
              auth.userProfile.email
            }`
          );
        })
        .catch(async error => {
          const { detail } = await Helpers.parseJSON(error.response);
          alert(detail);
        });
    } catch (error) {
      alert("There was an error requesting the export");
    } finally {
      this.setState({ exportingPayouts: false });
    }
  };

  /**
   * On successful payout, reset payouts
   */
  payoutSuccess = () => {
    this.setState(
      {
        payouts: [],
        payoutsTotal: 0,
        page: 1
      },
      () => {
        this.getPayouts(1);
      }
    );
  };

  /**
   * Updates the payout
   */
  updatePayout = ({ index, payoutCheck }) => {
    let payouts = [...this.state.payouts];
    payouts[index].payout_check = payoutCheck;
    this.setState({
      payouts
    });
  };

  onChangeSearch = e => {
    this.setState({
      search: e.target.value
    });
  };

  openModal = (payout, index) => {
    let { modalActions } = this.props;

    this.setState(
      {
        payoutId: payout.id,
        payoutCheck: payout.payout_check,
        payoutIndex: index
      },
      () => {
        if (this.props.payoutHistory) {
          modalActions.openPayoutHistoryModal(true, payout);
        } else {
          modalActions.openConsignerPayoutModal(true, payout);
        }
      }
    );
  };

  onPaymentChange = e => {
    let paymentType = e.target.value;
    if (e.target.value === "All") {
      paymentType = null;
    }

    this.setState(
      {
        paymentType,
        payouts: [],
        payoutsTotal: 0,
        page: 1
      },
      () => {
        this.getPayouts(this.state.page);
      }
    );
  };

  renderPayoutStatus = payout => {
    let payoutStatuses = {
      closed: {
        icon: "times-circle",
        color: "red"
      },
      reversed: {
        icon: "sync",
        color: "darkgreen"
      },
      paid: {
        icon: "check-circle",
        color: "green"
      },
      partially_paid: {
        icon: "check-circle",
        color: "#fea301"
      },
      open: {
        icon: "sync",
        color: "#fea301"
      },
      initiated: {
        icon: "sync",
        color: "#fea301"
      }
    };

    let status = payoutStatuses[payout.state];
    return (
      <div className={css(styles.statusColumn)}>
        <FontAwesomeIcon icon={status.icon} color={status.color} />
        <span className={css(styles.statusText)}>{payout.status}</span>
      </div>
    );
  };

  isOutsideRange = day => {
    let dayIsBlocked = false;

    if (isAfterDay(day, moment())) {
      dayIsBlocked = true;
    }

    return dayIsBlocked;
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.setState(
      {
        startDate,
        endDate
      },
      () => {
        if (this.state.startDate && this.state.endDate) {
          this.getPayouts(1);
        }
      }
    );
  };

  render() {
    let { intl } = this.props;
    let payouts = this.state.payouts.map((payout, index) => {
      return (
        <div
          className={css(styles.user)}
          onClick={() => this.openModal(payout, index)}
          key={`payout_${payout.id}`}
        >
          <div className={css(styles.column)}>{payout.id}</div>
          <div className={css(styles.column)}>
            {`${payout.profile.first_name} ${payout.profile.last_name}`}
          </div>
          <div className={css(styles.column)}>{payout.profile.email}</div>
          <div className={css(styles.column)}>
            {payout.profile.phone_number}
          </div>
          <div className={css(styles.column)}>
            {payout.location && payout.location.name}{" "}
          </div>
          <div className={css(styles.column)}>
            <CurrencyDisplay
              price={payout.amount_value / 100}
              format={"($0,0[.]00)"}
            />
          </div>
          <div className={css(styles.column)}>
            {!this.props.payoutHistory
              ? payout.requested_at
                ? moment(payout.requested_at).format("MM/DD/YY")
                : moment(payout.created_at).format("MM/DD/YY")
              : payout.success_at
              ? moment(payout.success_at).format("MM/DD/YY")
              : "Not Paid"}
          </div>
          <div className={css(styles.column)}>
            {payout && <PayoutStatus payout={payout} />}
          </div>
          <div className={css(styles.column)}>{payout.payout_type}</div>
        </div>
      );
    });

    return (
      <div className={css(styles.consignersPage)}>
        <div className={css(styles.header)}>
          <div className={css(styles.title)}>
            {!this.props.payoutHistory
              ? intl.formatMessage({
                  id: "requested_payouts",
                  defaultMessage: "Requested Payouts"
                })
              : intl.formatMessage({
                  id: "payout_history",
                  defaultMessage: "Payout History"
                })}
          </div>
          <div className={css(styles.description)}>
            {intl.formatMessage({
              id: "requested_payouts_list",
              defaultMessage:
                "Here is a list of requested payouts. In the payout screen you can change the payout amount."
            })}
          </div>
        </div>
        <div className={css(styles.payoutType)}>
          <Link
            to={this.props.payoutHistory ? "/payouts" : "/payouts/history"}
            className={css(styles.payoutLink)}
          >
            {this.props.payoutHistory
              ? intl.formatMessage({
                  id: "requested_payouts",
                  defaultMessage: "Requested Payouts"
                })
              : intl.formatMessage({
                  id: "payout_history",
                  defaultMessage: "Payout History"
                })}
          </Link>
        </div>
        <div className={css(styles.filterWrapper)}>
          <form
            className={css(styles.searchContainer)}
            onSubmit={e => this.getPayouts(1, e)}
          >
            <Input
              containerClassName={styles.userSearchInputContainer}
              placeholder={intl.formatMessage({
                id: "search_name_email",
                defaultMessage: "Search by name or email"
              })}
              value={this.state.search}
              onChange={this.onChangeSearch}
            />
            <Button
              className={styles.userSearchButton}
              text={
                this.state.fetchingData ? (
                  <FontAwesomeIcon icon={["far", "spinner-third"]} spin />
                ) : (
                  intl.formatMessage({
                    id: "search",
                    defaultMessage: "Search"
                  })
                )
              }
            />
          </form>
          <br />

          <div className={css(styles.filterRow)}>
            <div className={css(styles.dropdown)}>
              <span className={css(styles.location)}>{`${intl.formatMessage({
                id: "inventory_payout_method",
                defaultMessage: "Payment method"
              })}:`}</span>
              <PaymentMethodDropdown onChange={this.onPaymentChange} />
            </div>

            <DateRangePicker
              startDate={this.state.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={this.state.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={this.onDatesChange} // PropTypes.func.isRequired,
              focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
              showClearDates={true}
              isOutsideRange={this.isOutsideRange}
              minimumNights={0}
            />
          </div>
        </div>

        <div className={css(styles.exportContainer)}>
          {["admin", "accountant"].includes(
            this.props.auth.userProfile.role.toLowerCase()
          ) && (
            <>
              <BootstrapButton
                size="sm"
                variant="outline-secondary"
                onClick={this.exportPayouts}
                style={{ margin: "5px" }}
              >
                {(
                  <FontAwesomeIcon
                    icon={["fas", "file-export"]}
                    className={css(styles.fileIcon)}
                  />
                ) &&
                  (this.state.exportingPayouts ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.fileIcon)}
                      spin
                    />
                  ) : (
                    <FormattedMessage
                      id="export_payouts"
                      defaultMessage={
                        this.props.payoutHistory
                          ? "Export Payout History"
                          : "Export Requested Payouts"
                      }
                    />
                  ))}
              </BootstrapButton>
              <BootstrapButton
                size="sm"
                variant="outline-secondary"
                onClick={this.exportPayoutsSummary}
                style={{ margin: "5px" }}
              >
                {(
                  <FontAwesomeIcon
                    icon={["fas", "file-export"]}
                    className={css(styles.fileIcon)}
                  />
                ) &&
                  (this.state.exportingPayouts ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.fileIcon)}
                      spin
                    />
                  ) : (
                    <FormattedMessage
                      id="export_payouts"
                      defaultMessage="Export Payout Summary"
                    />
                  ))}
              </BootstrapButton>
            </>
          )}
        </div>
        <br />
        <hr />
        <br />
        <div className={css(styles.userListContainer)}>
          <div className={css(styles.userTitle)}>
            <div className={css(styles.column)}>
              {intl.formatMessage({
                id: "ID",
                defaultMessage: "ID"
              })}
            </div>
            <div className={css(styles.column)}>
              {intl.formatMessage({
                id: "name",
                defaultMessage: "Name"
              })}
            </div>
            <div className={css(styles.column)}>
              {intl.formatMessage({
                id: "email_address",
                defaultMessage: "Email"
              })}
            </div>
            <div className={css(styles.column)}>
              {intl.formatMessage({
                id: "phone",
                defaultMessage: "Phone"
              })}
            </div>
            <div className={css(styles.column)}>
              {intl.formatMessage({
                id: "location",
                defaultMessage: "Location"
              })}
            </div>
            <div className={css(styles.column)}>
              {intl.formatMessage({
                id: "payout_value",
                defaultMessage: "Payout Value"
              })}
            </div>
            <div className={css(styles.column)}>
              {!this.props.payoutHistory
                ? intl.formatMessage({
                    id: "date_requested",
                    defaultMessage: "Date Requested"
                  })
                : intl.formatMessage({
                    id: "date_paid",
                    defaultMessage: "Date Paid"
                  })}
            </div>
            <div className={css(styles.column)}>
              {intl.formatMessage({
                id: "status",
                defaultMessage: "Status"
              })}
            </div>
            <div className={css(styles.column)}>
              {intl.formatMessage({
                id: "payment_method",
                defaultMessage: "Payment Method"
              })}
            </div>
          </div>
          <InfiniteScroll
            pageStart={0}
            loadMore={() =>
              this.loadMore(this.state.page + 1, this.state.search)
            }
            hasMore={this.state.payouts.length < this.state.payoutsTotal}
            loader={
              <div className="loader" key={0}>
                {`${intl.formatMessage({
                  id: "loading",
                  defaultMessage: "Loading"
                })} ...`}
              </div>
            }
          >
            {payouts}
          </InfiniteScroll>
        </div>
        <ConsignerPayoutModal
          payout={true}
          payoutId={this.state.payoutId}
          success={this.payoutSuccess}
          payoutHistory={this.props.payoutHistory}
          payoutCheck={this.state.payoutCheck}
          getPayouts={this.getPayouts}
          updatePayout={this.updatePayout}
          payoutIndex={this.state.payoutIndex}
        />
        {this.props.openConsignerPayoutModal && (
          <ConsignerPayoutModal
            payout={true}
            payoutId={this.state.payoutId}
            success={this.payoutSuccess}
            payoutHistory={this.props.payoutHistory}
            payoutCheck={this.state.payoutCheck}
            getPayouts={this.getPayouts}
            updatePayout={this.updatePayout}
            payoutIndex={this.state.payoutIndex}
          />
        )}
        <PayoutHistoryModal
          payout={true}
          payoutId={this.state.payoutId}
          success={this.payoutSuccess}
          payoutHistory={this.props.payoutHistory}
          payoutCheck={this.state.payoutCheck}
          getPayouts={this.getPayouts}
          updatePayout={this.updatePayout}
          payoutIndex={this.state.payoutIndex}
        />
      </div>
    );
  }
}

var styles = StyleSheet.create({
  consignersPage: {
    padding: 30
  },
  searchContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  userSearchInputContainer: {
    width: "100%",
    marginRight: 10
  },
  userSearchInput: {
    width: 500,
    marginRight: 10
  },
  userSearchButton: {
    width: 200,
    fontSize: 20
  },
  title: {
    fontSize: 40,
    textAlign: "center",
    letterSpacing: 1,
    marginBottom: 10
  },
  header: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20
  },
  payoutType: {
    textAlign: "center",
    marginBottom: 40
  },
  payoutLink: {
    color: "#111",
    fontSize: 25
  },
  description: {
    fontSize: 18,
    textAlign: "center",
    width: 500,
    overflow: "wrap",
    opacity: 0.7,
    marginBottom: 20
  },
  userListContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "scroll"
  },
  user: {
    width: "100vw",
    marginLeft: "200px",
    display: "flex",
    fontSize: 18,
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    ":nth-child(even)": {
      backgroundColor: "#f5f5f5"
    },
    ":hover": {
      background: "#ddd"
    }
  },
  column: {
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: "center",
    width: "100%",
    maxWidth: 160,
    paddingTop: 20,
    paddingBottom: 20,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  userTitle: {
    fontSize: 20,
    width: "100vw",
    marginLeft: "200px",
    fontWeight: "bold",
    display: "flex",
    paddingLeft: 40,
    paddingBottom: 10,
    borderBottom: "1px solid"
  },
  numbering: {
    width: 30,
    height: 40,
    paddingLeft: 10,
    paddingTop: 20,
    paddingBottom: 20
  },
  rateEdit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  rateInput: {
    height: 40,
    width: 100,
    padding: 10,
    marginRight: 10
  },
  rateButton: {
    width: 70,
    height: 40,
    padding: 10
  },
  rate: {
    cursor: "initial"
  },
  statusText: {
    margin: "0em 0.5em",
    textTransform: "capitalize"
  },
  exportContainer: {
    display: "flex",
    justifyContent: "center"
  },
  dropdown: {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "13px 10px"
  },
  filterWrapper: {
    background: "#fff",
    padding: 20,
    marginTop: 20,
    marginBottom: 40,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  filterRow: {
    width: "100%",
    maxWidth: 900,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Payouts));
