/***
 * Consignment modal to approve / reject
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import BaseModal from "./BaseModal";
import Button from "../Button";

// Actions
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Stylesheets
import "react-image-carousel/lib/css/main.min.css";
import "./stylesheets/ImageCarousel.css";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";

class ApprovePriceChangeModal extends React.Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      rejectLoading: false,
      approveLoading: false,
      noLoading: false,
      request: null,
      item: null
    };

    this.state = {
      ...this.defaultState
    };
  }

  /***
   * closes the modal
   */
  closeModal = () => {
    let { modalActions } = this.props;
    modalActions.openApprovePriceChangeModal(false, null);
    this.setState({
      ...this.defaultState,
      locations: this.state.locations
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.modals.openApprovePriceChangeModal) {
      let request = nextProps.modals.approvePriceChangeModal.request;
      if (request) {
        fetch(
          API.INVENTORY({ id: request.consignment.inventory_id }),
          API.GET_CONFIG()
        )
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON)
          .then(res => {
            this.setState({
              item: res
            });
          });
      }
    }
  }

  /**
   * Rejects price change request
   */
  rejectRequest = async () => {
    let { modals, intl, messageActions } = this.props;
    let rejectionReason = "";
    let userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf(" electron/") > -1) {
      // Electron-specific code
      rejectionReason = await window.electronPrompt({
        title: intl.formatMessage({
          id: "reject_reason",
          defaultMessage: "Rejection Reason"
        }),
        label: intl.formatMessage({
          id: "reject_reason_message",
          defaultMessage: "Why are you rejecting this request?"
        }),
        inputAttrs: {
          type: "text",
          required: true
        }
      });
    } else {
      rejectionReason = prompt(
        intl.formatMessage({
          id: "please_give_reject_reason",
          defaultMessage: "Please give a reason for rejection: "
        })
      );
    }

    if (rejectionReason === null) {
      return null;
    }

    this.setState({
      rejectLoading: true
    });

    let id = modals.approvePriceChangeModal.request.request_id;
    let params = {
      id,
      reason: rejectionReason
    };

    return fetch(
      API.CONSIGNMENT_PRICE({ id, type: "reject" }),
      API.PUT_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(res => {
        this.setState({
          rejectLoading: false
        });
        messageActions.setMessage(
          intl.formatMessage({
            id: "price_change_rejected",
            defaultMessage: "Price Change Rejected"
          })
        );
        messageActions.showMessage({ show: true, error: true });
        this.props.onApproveOrReject(id);
        this.closeModal();
      });
  };

  approveRequest = () => {
    let { modals, messageActions, intl } = this.props;
    this.setState({
      approveLoading: true
    });
    let id = modals.approvePriceChangeModal.request.request_id;
    return fetch(
      API.CONSIGNMENT_PRICE({ id, type: "approve" }),
      API.PUT_CONFIG({ id })
    )
      .then(Helpers.checkStatus)
      .then(res => {
        this.setState({
          approveLoading: false
        });
        messageActions.setMessage(
          intl.formatMessage({
            id: "price_change_approved",
            defaultMessage: "Price Change Approved"
          })
        );
        messageActions.showMessage({ show: true });
        this.props.onApproveOrReject(id);
        this.closeModal();
      });
  };

  renderItem = () => {
    let { intl } = this.props;
    let { item, request } = this.state;

    return (
      <div className={css(styles.information)}>
        {item && (
          <div className={css(styles.sectionContainer)}>
            <div id="consignmentInfo">
              <div className={css(styles.title)}>
                {item && item.sku.product_name_us}
              </div>
              <div className={css(styles.section, styles.center)}>
                <img
                  className={css(styles.productImg)}
                  src={item.pictures[0] && item.pictures[0].url}
                  alt={item && item.sku.product_name_us}
                />
                <div className={css(styles.info)}>
                  <div className={css(styles.price)}>
                    Current Price: $
                    {request.consignment.price.default_price / 100}
                    <br />
                    New Price: {request["New Price"]}
                  </div>
                  {request.consignment.size && (
                    <div className={css(styles.size)}>
                      {`${intl.formatMessage({
                        id: "size",
                        defaultMessage: "Size"
                      })} ${request.consignment.size}`}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {request["Status"] === "pending" && (
              <div className={css(styles.actionGroup)}>
                <Button
                  text={
                    this.state.rejectLoading ? (
                      <FontAwesomeIcon
                        icon={["far", "spinner-third"]}
                        spin
                        color={"#fff"}
                      />
                    ) : (
                      <FormattedMessage id="reject" defaultMessage="Reject" />
                    )
                  }
                  id="reject"
                  onClick={this.rejectRequest}
                />
                <Button
                  text={
                    this.state.approveLoading ? (
                      <FontAwesomeIcon
                        icon={["far", "spinner-third"]}
                        spin
                        color={"#fff"}
                      />
                    ) : (
                      <FormattedMessage
                        id="approved"
                        defaultMessage="Approve"
                      />
                    )
                  }
                  id="approve"
                  className={styles.approveButton}
                  onClick={this.approveRequest}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  onAfterOpen = () => {
    let { modals } = this.props;
    this.setState({
      request: modals.approvePriceChangeModal.request
    });
  };

  render() {
    let { modals } = this.props;

    return (
      <BaseModal
        isOpen={modals.openApprovePriceChangeModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <FontAwesomeIcon
          icon={["far", "times"]}
          className={css(styles.times)}
          onClick={this.closeModal}
        />
        <div className={css(styles.grouping)}>
          <div className={css(styles.approveConsignment)}>
            <div className={css(styles.right)}>{this.renderItem()}</div>
          </div>
        </div>
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  modal: {
    height: "100%",
    maxWidth: "unset",
    width: "100%"
  },
  title: {
    textAlign: "center",
    letterSpacing: 0.7,
    fontSize: 24
  },
  grouping: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  approveConsignment: {
    height: "100%",
    display: "flex",
    width: "100%"
  },
  bodyContainer: {
    height: "100%",
    maxHeight: "unset"
  },
  section: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
    fontSize: 18,
    overflow: "auto",
    flexWrap: "wrap"
  },
  steps: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 16,
    width: "100%",
    flexWrap: "wrap"
  },
  checkContainer: {
    width: "50%",
    marginTop: 4
  },
  times: {
    position: "absolute",
    right: 32,
    top: 32,
    fontSize: "2.5em",
    cursor: "pointer"
  },
  productImg: {
    width: 100,
    height: 100
  },
  photos: {
    flex: 1
  },
  sectionContainer: {
    padding: 8
  },
  middleContainer: {
    background: "rgb(249, 249, 249)"
  },
  picker: {
    overflow: "scroll",
    height: "calc(100%)",
    paddingTop: 32,
    paddingBottom: 32
  },
  inline: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: 0
  },
  information: {
    flex: 1,
    marginLeft: 32,
    display: "flex",
    height: "100%",
    flexDirection: "column"
  },
  column: {
    flexDirection: "column",
    alignItems: "center"
  },
  info: {
    // display: 'flex',
    marginLeft: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: 18
  },
  actionGroup: {
    display: "flex",
    margin: "auto",
    maxWidth: 500
  },
  price: {
    fontSize: 24,
    letterSpacing: 0.7
    // marginLeft: 16,
  },
  question: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    margin: "16px 0px"
  },
  productImgSteps: {
    width: 50,
    height: 50,
    cursor: "pointer",
    margin: "0 auto"
  },
  approveButton: {
    marginLeft: 16
  },
  locations: {
    flex: 1,
    marginLeft: 16
  },
  right: {
    width: "100%",
    flex: 1
  },
  center: {
    justifyContent: "center"
  },
  rejectionTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: 20,
    fontWeight: "bold"
  },
  messageContainer: {
    paddingLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  numbering: {
    width: 30,
    textAlign: "center",
    marginRight: 10
  },
  timeCreated: {
    opacity: ".5",
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 10
  },
  rejectionContainer: {
    overflow: "scroll",
    width: "100%",
    flex: 1,
    marginBottom: 20
  }
});
const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApprovePriceChangeModal)
);
