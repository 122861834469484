/***
 * Manual Input modal for adding new products
 * @patr
 */
import React from "react";
// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";

// Components
import BaseModal from "../BaseModal";
import Input from "../../Input";
import Button from "../../Button";
import ErrorBlock from "../../ErrorBlock";
import SearchPlaceholder from "./ManualInputModalSearchPlaceholder";

// Actions
import { ModalActions } from "../../../redux/modals";
import { MessageActions } from "../../../redux/message";

// Config
import colors from "../../../config/colors";
import API from "../../../config/api";
import Helpers from "../../../redux/helpers";

const SEARCH_TIMEOUT = 700;

class ManualInputModal extends React.Component {
  constructor(props) {
    super(props);

    let { intl } = this.props;

    this.youthSizes = [];
    for (let i = 1; i < 13; i += 0.5) {
      this.youthSizes.push(i + "Y");
    }

    this.childSizes = [];
    for (let i = 1; i < 14; i += 0.5) {
      this.childSizes.push(i + "C");
    }

    this.adidasChildSizes = [];
    for (let i = 1; i < 14; i += 0.5) {
      this.adidasChildSizes.push(i + "K");
    }

    this.shoeSizes = [];
    for (let i = 3.5; i < 18; i += 0.5) {
      this.shoeSizes.push(i);
    }

    this.apparelSizes = [
      "N/A",
      "XS",
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "XXXL",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56"
    ];

    this.currencies = [{ value: "usd", label: "USD" }];

    this.types = [
      {
        id: "shoe",
        label: intl.formatMessage({ id: "shoe", defaultMessage: "Shoes" })
      },
      {
        id: "apparel",
        label: intl.formatMessage({ id: "apparel", defaultMessage: "Apparel" })
      },
      {
        id: "accessory",
        label: intl.formatMessage({
          id: "accessory",
          defaultMessage: "Accessory"
        })
      }
    ];

    this.fields = [
      {
        id: "product_name_us",
        label: intl.formatMessage({
          id: "sku_product_name",
          defaultMessage: "Product Name"
        })
      },
      {
        id: "brand",
        label: intl.formatMessage({ id: "sku_brand", defaultMessage: "Brand" })
      },
      {
        id: "color",
        label: intl.formatMessage({ id: "sku_color", defaultMessage: "Color" })
      },
      {
        id: "style",
        label: intl.formatMessage({
          id: "sku_style",
          defaultMessage: "Style Number"
        })
      },
      {
        id: "barcode",
        label: intl.formatMessage({
          id: "sku_barcode",
          defaultMessage: "Barcode"
        })
      }
    ];

    this.initialState = {
      skus: [],
      selectedStyle: {},
      page: 1,
      form: {},
      searching: false,
      search: ""
    };

    this.state = {
      ...this.initialState
    };
  }

  closeModal = () => {
    let { modalActions } = this.props;
    modalActions.openManualInputModal(false);

    this.setState({
      ...this.initialState
    });
  };

  /***
   * When search changes, set a small timeout to wait for to search
   */
  onSearchChange = e => {
    clearTimeout(this.searchTimeout);
    let searchValue = e.target.value;
    this.setState({
      searching: true,
      search: searchValue
    });

    this.searchTimeout = setTimeout(async () => {
      await this.search(searchValue);
      this.setState({
        searching: false
      });
    }, SEARCH_TIMEOUT);
  };

  search = searchValue => {
    return fetch(API.PRODUCT({ search: searchValue }), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          skus: res.results
        });
      });
  };

  onStyleClick = style => {
    let { modals } = this.props;
    this.setState({
      selectedStyle: style,
      page: 2,
      form: {
        product_name_us: style.product_name_us,
        brand: style.brand,
        color: style.color,
        style: style.style,
        barcode:
          (modals.manualInputModal.form &&
            modals.manualInputModal.form.barcode) ||
          style.barcode,
        type: style.item_type,
        price:
          (modals.manualInputModal.form &&
            modals.manualInputModal.form.price) ||
          this.props.styleToPrice[
            style.style || `${style.product_name_us}-${style.color}`
          ],
        cost:
          (modals.manualInputModal.form && modals.manualInputModal.form.cost) ||
          this.props.styleToCost[
            style.style || `${style.product_name_us}-${style.color}`
          ]
      }
    });
  };

  onFormInputChange = (e, id) => {
    let form = { ...this.state.form };
    if (id === "currency") {
      form[id] = e;
    } else {
      form[id] = e.target.value;
    }

    this.setState({
      form: form
    });
  };

  onSizeChange = size => {
    this.setState({
      form: { ...this.state.form, size: size }
    });
  };

  onTypeChange = typeId => {
    this.setState({
      form: { ...this.state.form, type: typeId }
    });
  };

  addNewStyle = () => {
    this.setState({
      page: 2
    });
  };

  /**
   * Creates an inventory item
   */
  onCreateInventory = async e => {
    e.preventDefault();
    this.setState({
      creatingInventory: true
    });
    let location = parseInt(this.props.locationId, 10);
    let consigner = parseInt(this.props.consignerId, 10);
    let add_inventory_order =
      this.props.addInventoryOrder &&
      (await this.props.addInventoryOrder({ location }));
    let price = {
      default_price: parseFloat(this.state.form.price, 10) * 100,
      instore_price: parseFloat(this.state.form.price, 10) * 100,
      goat_price: parseFloat(this.state.form.price, 10) * 100,
      magento_price: parseFloat(this.state.form.price, 10) * 100,
      solestage_price: parseFloat(this.state.form.price, 10) * 100,
      shine_price: parseFloat(this.state.form.price, 10) * 100
    };

    let sku = {
      brand: this.state.form.brand,
      color: this.state.form.color,
      product_name_us: this.state.form.product_name_us,
      us_size: this.state.form.size,
      style: this.state.form.style,
      barcode: this.state.form.barcode,
      item_type: this.state.form.type
    };

    let inventory = {
      price: price,
      sku: sku,
      location,
      consigner,
      add_inventory_order_id: add_inventory_order.id,
      cost: parseFloat(this.state.form.cost, 10) * 100
    };

    this.props.updatePriceByStyle(
      this.state.form.price,
      this.state.form.style ||
        `${this.state.form.product_name_us}-${this.state.form.color}`
    );
    this.props.updateCostByStyle(
      this.state.form.cost,
      this.state.form.style ||
        `${this.state.form.product_name_us}-${this.state.form.color}`
    );

    let inventoryArray = [];
    let { modals } = this.props;
    let quantity =
      (modals.manualInputModal.form && modals.manualInputModal.form.quantity) ||
      this.props.quantity;
    for (let i = 0; i < quantity; i++) {
      inventoryArray.push(inventory);
    }
    return fetch(API.INVENTORY({}), API.POST_CONFIG(inventoryArray))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          creatingInventory: false
        });
        this.props.inventoryCreatedCallback(res, this.state.tableId, quantity);
        this.closeModal();
      })
      .catch(error => {
        let { messageActions } = this.props;
        let message = "Something went wrong!";
        if (error.message) {
          message = error.message;
          if (error.message.detail) {
            message = error.message.detail;
          }
        }
        messageActions.setMessage(message);
        messageActions.showMessage({ show: true, error: true });
        this.setState({
          creatingInventory: false
        });
      });
  };

  onAfterOpen = () => {
    let { modals } = this.props;
    this.setState({
      ...modals.manualInputModal
    });
  };

  render() {
    let { modals, intl, localization } = this.props;

    let productStyles = this.state.skus.map((sku, index) => {
      return (
        <div
          className={css(styles.productStyle)}
          onClick={() => this.onStyleClick(sku)}
          key={`style_${index}`}
        >
          <div className={css(styles.column)}>
            {sku.pictures && sku.pictures.length > 0 ? (
              <img
                className={css(styles.picture)}
                src={sku.pictures[0].url}
                alt={sku.product_name_us}
              />
            ) : (
              <div>
                {intl.formatMessage({
                  id: "no_image_found",
                  defaultMessage: "No image found"
                })}
              </div>
            )}
          </div>
          <div className={css(styles.column)}>{sku.product_name_us}</div>
          <div className={css(styles.column)}>{sku.style}</div>
        </div>
      );
    });

    let fields = this.fields.map((field, index) => {
      return (
        <div className={css(styles.formInputContainer)} key={`field_${index}`}>
          <label className={css(styles.label)}> {field.label} </label>
          <Input
            value={this.state.form[field.id]}
            onChange={e => this.onFormInputChange(e, field.id)}
            placeholder={field.label}
            required={!(field.id === "style" || field.id === "barcode")}
          />
        </div>
      );
    });

    let childSizes = this.childSizes.map((size, index) => {
      return (
        <div
          className={css(
            styles.size,
            this.state.form.size === size && styles.selectedSize
          )}
          onClick={() => this.onSizeChange(size)}
          key={`size_${size}`}
        >
          <span>{size}</span>
        </div>
      );
    });

    let adidasChildSizes = this.adidasChildSizes.map((size, index) => {
      return (
        <div
          className={css(
            styles.size,
            this.state.form.size === size && styles.selectedSize
          )}
          onClick={() => this.onSizeChange(size)}
          key={`size_${size}`}
        >
          <span>{size}</span>
        </div>
      );
    });

    let youthSizes = this.youthSizes.map((size, index) => {
      return (
        <div
          className={css(
            styles.size,
            this.state.form.size === size && styles.selectedSize
          )}
          onClick={() => this.onSizeChange(size)}
          key={`size_${size}`}
        >
          <span>{size}</span>
        </div>
      );
    });

    let sizes =
      this.state.form.type === "shoe" ? (
        this.shoeSizes.map((size, index) => {
          return (
            <div
              className={css(
                styles.size,
                this.state.form.size === size && styles.selectedSize
              )}
              onClick={() => this.onSizeChange(size)}
              key={`size_${size}`}
            >
              <span>{size}</span>
            </div>
          );
        })
      ) : this.state.form.type === "apparel" ||
        this.state.form.type === "accessory" ? (
        this.apparelSizes.map((size, index) => {
          return (
            <div
              className={css(
                styles.size,
                this.state.form.size === size && styles.selectedSize
              )}
              onClick={() => this.onSizeChange(size)}
              key={`size_${size}`}
            >
              <span>{size}</span>
            </div>
          );
        })
      ) : (
        <div className={css(styles.message)}>
          {intl.formatMessage({
            id: "select_product_type",
            defaultMessage: "Please select a product type to continue"
          })}
        </div>
      );
    let types = this.types.map((type, index) => {
      return (
        <div
          className={css(
            styles.type,
            this.state.form.type === type.id && styles.selectedType
          )}
          key={`type_${type.id}`}
          onClick={() => this.onTypeChange(type.id)}
        >
          <span>{type.label}</span>
        </div>
      );
    });

    let overlayStyles = {
      overlay: {
        backgroundColor: "rgba(0,0,0,0.9)"
      }
    };

    return (
      <BaseModal
        isOpen={modals.openManualInputModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        overlayStyles={overlayStyles}
        bodyContainer={styles.bodyContainer}
        contentLabel="ManualInputModal"
      >
        {this.state.page === 1 ? (
          <div className={css(styles.container)}>
            {modals.manualInputModal.error && (
              <ErrorBlock
                error={modals.manualInputModal.error}
                errorStyles={styles.errorBlock}
              />
            )}
            <div className={css(styles.search)}>
              <div className={css(styles.instructions)}>
                <span className={css(styles.title)}>
                  {intl.formatMessage({
                    id: "existing_products_search",
                    defaultMessage: "Search Existing Products"
                  })}
                </span>
                <div className={css(styles.description)}>
                  {intl.formatMessage({
                    id: "existing_products_message",
                    defaultMessage:
                      "Search for existing products in inventory and associate the product you're adding with the correct style or if it can't be found contact the Warehouse."
                  })}
                </div>
              </div>
              <div
                className={css(styles.inputContainer)}
                id="searchByProductName"
              >
                <Input
                  className={styles.input}
                  placeholder={intl.formatMessage({
                    id: "existing_products_input",
                    defaultMessage: "Search by product name or style code"
                  })}
                  onChange={this.onSearchChange}
                  autoFocus
                />
              </div>
              <div className={css(styles.labels)}>
                <span className={css(styles.column)}>
                  {intl.formatMessage({
                    id: "picture",
                    defaultMessage: "Picture"
                  })}
                </span>
                <span className={css(styles.column)}>
                  {intl.formatMessage({
                    id: "product_name",
                    defaultMessage: "Product Name"
                  })}
                </span>
                <span className={css(styles.column)}>
                  {intl.formatMessage({
                    id: "style_code",
                    defaultMessage: "Style Code"
                  })}
                </span>
              </div>
              <div className={css(styles.productStyles)}>
                <ReactPlaceholder
                  ready={!this.state.searching}
                  customPlaceholder={<SearchPlaceholder />}
                  showLoadingAnimation={true}
                >
                  {productStyles.length > 0
                    ? productStyles
                    : this.state.search &&
                      !this.state.searching && (
                        <div className={css(styles.noProductsFound)}>
                          {intl.formatMessage({
                            id: "no_product_found",
                            defaultMessage:
                              "No Products Found, please contact Warehouse"
                          })}
                        </div>
                      )}
                </ReactPlaceholder>
              </div>
            </div>
            {/* {["Admin", "Warehouse Manager", "Store Supermanager"].includes(
              auth.userProfile.role
            ) && (
              <Button
                className={styles.button}
                text={intl.formatMessage({
                  id: "add_new_style",
                  defaultMessage: "Add new style"
                })}
                id="addNewStyle"
                onClick={this.addNewStyle}
              />
            )} */}
          </div>
        ) : (
          <div className={css(styles.formContainer)}>
            {modals.manualInputModal.error && (
              <ErrorBlock
                error={modals.manualInputModal.error}
                errorStyles={styles.errorBlock}
              />
            )}
            <div className={css(styles.instructions)}>
              <span className={css(styles.title)}>
                {intl.formatMessage({
                  id: "product_info",
                  defaultMessage: "Product Info"
                })}
              </span>
              <div className={css(styles.description)}>
                {intl.formatMessage({
                  id: "consignment_instruction",
                  defaultMessage:
                    "Enter in as much product information as possible."
                })}
              </div>
            </div>
            <form
              className={css(
                styles.formBody,
                modals.manualInputModal.error && styles.formBodyLesser
              )}
              onSubmit={this.onCreateInventory}
            >
              <div className={css(styles.formInner)}>
                <div className={css(styles.title)}>
                  {intl.formatMessage({
                    id: "product_type",
                    defaultMessage: "Product Type"
                  })}
                </div>
                <div className={css(styles.productTypes)}>{types}</div>
                <div className={css(styles.title)}>
                  {intl.formatMessage({ id: "size", defaultMessage: "Size" })}
                </div>
                {this.state.form.type === "shoe" && (
                  <div className={css(styles.sizes)}>{youthSizes}</div>
                )}
                {this.state.form.type === "shoe" && (
                  <div className={css(styles.sizes)}>{childSizes}</div>
                )}
                {this.state.form.type === "shoe" && (
                  <div className={css(styles.sizes)}>{adidasChildSizes}</div>
                )}
                <div className={css(styles.sizes)}>{sizes}</div>
                <div className={css(styles.title)}>
                  {intl.formatMessage({ id: "price", defaultMessage: "Price" })}
                </div>
                <div className={css(styles.priceContainer)}>
                  <FontAwesomeIcon
                    icon={[
                      "far",
                      localization.locale === "zh-cn"
                        ? "yen-sign"
                        : "dollar-sign"
                    ]}
                    className={css(styles.dollarSign)}
                  />
                  <Input
                    className={styles.priceInput}
                    onChange={e => this.onFormInputChange(e, "price")}
                    value={this.state.form.price}
                    defaultValue={
                      this.props.styleToPrice[
                        this.state.form.style ||
                          `${this.state.form.product_name_us}-${
                            this.state.form.color
                          }`
                      ]
                    }
                    type="number"
                    required={true}
                    placeholder={intl.formatMessage({
                      id: "enter_price",
                      defaultMessage: "Enter price in $"
                    })}
                  />
                </div>
                <div className={css(styles.title)}>Cost</div>
                <div className={css(styles.priceContainer)}>
                  <FontAwesomeIcon
                    icon={[
                      "far",
                      localization.locale === "zh-cn"
                        ? "yen-sign"
                        : "dollar-sign"
                    ]}
                    className={css(styles.dollarSign)}
                  />
                  <Input
                    className={styles.priceInput}
                    onChange={e => this.onFormInputChange(e, "cost")}
                    value={this.state.form.cost}
                    type="number"
                    defaultValue={
                      this.props.styleToCost[
                        this.state.form.style ||
                          `${this.state.form.product_name_us}-${
                            this.state.form.color
                          }`
                      ]
                    }
                    required={true}
                    placeholder={intl.formatMessage({
                      id: "enter_cost",
                      defaultMessage: "Enter cost in $"
                    })}
                  />
                </div>
                <div className={css(styles.title)}>
                  {intl.formatMessage({
                    id: "basic_product_information",
                    defaultMessage: "Basic Product information"
                  })}
                </div>
                <div className={css(styles.form)}>{fields}</div>
              </div>
              <Button
                className={styles.button}
                text={
                  this.state.creatingInventory ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      spin
                      color="#fff"
                      className={css(styles.spinnerThird)}
                    />
                  ) : (
                    intl.formatMessage({
                      id: "create_inventory_item",
                      defaultMessage: "Create Inventory Item"
                    })
                  )
                }
              />
            </form>
          </div>
        )}
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  modal: {
    height: "90%",
    maxWidth: 900,
    position: "absolute"
  },
  bodyContainer: {
    height: "100%",
    maxHeight: "unset",
    alignItems: "unset",
    justifyContent: "unset",
    boxSizing: "border-box"
  },
  formContainer: {
    width: "100%",
    overflow: "scroll"
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #aaa"
  },
  input: {
    marginTop: 16,
    marginBottom: 16,
    height: 50,

    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.RED(1)}`
    }
  },
  search: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  instructions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    textAlign: "center",
    paddingTop: 0,
    borderBottom: "1px solid #aaa"
  },
  title: {
    fontSize: 22
  },
  description: {
    marginTop: 8,
    fontSize: 14,
    width: "80%",
    opacity: 0.7
  },
  picture: {
    width: 100,
    height: 100
  },
  productStyle: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
    height: 100,
    ":hover": {
      background: "#eee"
    }
  },
  error: {
    textAlign: "center"
  },
  errorBlock: {
    marginBottom: 12
  },
  noProductsFound: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  productStyles: {
    width: "100%",
    overflow: "scroll",
    border: "1px solid #ddd",
    borderRadius: 4,
    flex: 1,
    marginBottom: 40
  },
  button: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    borderRadius: 0
  },
  labels: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 20
  },
  column: {
    width: "32%",
    display: "flex",
    justifyContent: "center"
  },
  label: {
    fontWeight: "bold",
    letterSpacing: 0.7,
    marginBottom: 8
  },
  formBody: {
    marginTop: 16,
    height: "calc(100% - 124px)"
  },
  formBodyLesser: {
    height: "calc(100% - 186px)"
  },
  formInner: {
    height: "100%",
    overflow: "auto"
  },
  formInputContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 16
  },
  dropdownMenu: {
    border: "1px solid #ddd",
    borderTop: "none"
  },
  menuItem: {
    padding: 10
  },
  size: {
    border: "1px solid #000",
    width: 50,
    height: 50,
    marginRight: 10,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#000",

    ":hover": {
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`,
      background: colors.RED(1)
    }
  },
  sizes: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: 20,
    boxSizing: "border-box"
  },
  selectedSize: {
    color: "#fff",
    border: `1px solid ${colors.RED(1)}`,
    background: colors.RED(1)
  },
  type: {
    width: 100,
    height: 40,
    border: "1px solid #000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    marginBottom: 10,
    cursor: "pointer",

    ":hover": {
      opacity: 0.6
    }
  },
  productTypes: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  selectedType: {
    color: "#fff",
    backgroundColor: colors.RED(1),
    border: `1px solid ${colors.RED(1)}`
  },
  message: {
    opacity: 0.7,
    textAlign: "center",
    fontSize: 20,
    letterSpacing: 1,
    padding: 20,
    width: "100%"
  },
  priceInput: {
    width: "100%",
    marginRight: 16,
    paddingLeft: 38,
    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.RED(1)}`
    }
  },
  currencySelector: {
    width: "100%"
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    padding: "20px 28px",
    position: "relative"
  },
  dollarSign: {
    position: "absolute",
    left: 48,
    zIndex: 2,
    color: "gray"
  },
  placeholderContainer: {
    width: 500,
    height: 500
  },
  rowPlaceholder: {
    width: 100,
    height: 100,
    display: "flex",
    justifyContent: "space-between"
  },
  imagePlaceholder: {
    width: 100,
    height: 100
  },
  infoPlaceholder: {
    width: 100
  }
});
const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth,
  localization: state.localization
});
const mapDispatchToProps = dispatch => ({
  // authActions: bindActionCreators(AuthActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManualInputModal)
);
