import React, { Component } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import CurrencyDisplay from "../CurrencyDisplay";

class SoldProductsList extends Component {
  render() {
    let { intl } = this.props;
    let items = this.props.items.map((item, index) => {
      return (
        <div className={css(styles.item)}>
          <div className={css(styles.numbering)}>{index + 1}.</div>
          <div className={css(styles.column, styles.productName)}>
            {item.product_name_us}
          </div>
          <div className={css(styles.column, styles.style)}>{item.style}</div>
          <div className={css(styles.column)}>{item.us_size}</div>
          <div className={css(styles.column)}>{item.id}</div>
          <div className={css(styles.column)}>{item.sales_channel}</div>
          <div className={css(styles.column)}>
            <CurrencyDisplay price={item.net_price} />
          </div>
          <div className={css(styles.column)}>
            <CurrencyDisplay price={item.price / 100} />
          </div>
          <div className={css(styles.column)}>{item.consigner_id}</div>
          <div className={css(styles.column)}>{item.consigner_name}</div>
          <div className={css(styles.column)}>{item.consigner_email}</div>
        </div>
      );
    });
    return (
      <div className={css(styles.topProductsList)}>
        <div className={css(styles.headings)}>
          <div className={css(styles.numbering)} />
          <div className={css(styles.column, styles.productName)}>
            {intl.formatMessage({
              id: "product_name",
              defaultMessage: "Product Name"
            })}
          </div>
          <div className={css(styles.column, styles.style)}>
            {intl.formatMessage({ id: "style", defaultMessage: "Style" })}
          </div>
          <div className={css(styles.column)}>
            {intl.formatMessage({ id: "size", defaultMessage: "Size" })}
          </div>
          <div className={css(styles.column)}>
            {intl.formatMessage({
              id: "inventory_id",
              defaultMessage: "Item ID"
            })}
          </div>
          <div className={css(styles.column)}>
            {intl.formatMessage({
              id: "sales_channel",
              defaultMessage: "Platform"
            })}
          </div>
          <div className={css(styles.column)}>
            {intl.formatMessage({
              id: "net_price",
              defaultMessage: "Sold Price"
            })}
          </div>
          <div className={css(styles.column)}>
            {intl.formatMessage({
              id: "price",
              defaultMessage: "Price"
            })}
          </div>
          <div className={css(styles.column)}>
            {intl.formatMessage({
              id: "consigner_id",
              defaultMessage: "Consigner"
            })}
          </div>
          <div className={css(styles.column)}>
            {intl.formatMessage({
              id: "consigner_name",
              defaultMessage: "Consigner Name"
            })}
          </div>
          <div className={css(styles.column)}>
            {intl.formatMessage({
              id: "consigner_email",
              defaultMessage: "Consigner Email"
            })}
          </div>
        </div>
        {items}
      </div>
    );
  }
}

const styles = StyleSheet.create({
  topProductsList: {
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    padding: 20,
    marginBottom: 20,
    width: "100%",
    display: "table",
    boxSizing: "border-box"
  },
  item: {
    display: "table-row",
    marginBottom: 16,
    alignItems: "center",
    boxSizing: "border-box"
  },
  column: {
    wordBreak: "break-word",
    display: "table-cell",
    marginRight: 10,
    minWidth: "5%"
  },
  style: {
    width: "15%"
  },
  numbering: {
    display: "table-cell",
    alignItems: "center",
    justifyContent: "center"
  },
  headings: {
    display: "table-row",
    fontSize: 18,
    fontWeight: "bold"
  },
  productName: {
    width: "25%",
    paddingRight: 10
  }
});

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SoldProductsList));
