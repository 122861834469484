import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";

// Config
import colors from "../config/colors";

export default class Button extends React.Component {
  render() {
    return (
      <button
        className={css(styles.button, this.props.className)}
        disabled={this.props.disabled}
        type={this.props.type}
        id={this.props.id}
        onClick={this.props.onClick}
      >
        {this.props.text}
      </button>
    );
  }
}

var styles = StyleSheet.create({
  button: {
    color: "#fff",
    background: colors.RED(1),
    border: `1px solid ${colors.RED(1)}`,
    width: "100%",
    borderRadius: 4,
    fontSize: 16,
    padding: "16px 8px",
    cursor: "pointer",
    letterSpacing: 0.7,
    outline: "none",

    ":hover": {
      background: colors.RED(0.8)
    },

    ":disabled": {
      background: colors.GREY(0.8),
      border: `1px solid ${colors.GREY(1)}`
    }
  }
});
