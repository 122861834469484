/***
 * Manual Input modal for adding new products
 * @patr
 */
import React, { Fragment } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
// eslint-disable-next-line
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageUpload from "react-image-upload-grid";

// Components
import Input from "../Input";
import Button from "../Button";
import ErrorBlock from "../ErrorBlock";

// Config
import colors from "../../config/colors";
import { checkPermissions } from "../../utils";

class ConsignmentFields extends React.Component {
  constructor(props) {
    super(props);

    let { intl } = this.props;

    this.youthSizes = [];
    for (let i = 1; i < 13; i += 0.5) {
      this.youthSizes.push(i + "Y");
    }

    this.childSizes = [];
    for (let i = 1; i < 14; i += 0.5) {
      this.childSizes.push(i + "C");
    }

    this.adidasChildSizes = [];
    for (let i = 1; i < 14; i += 0.5) {
      this.adidasChildSizes.push(i + "K");
    }

    this.shoeSizes = [];
    for (let i = 1; i < 18; i += 0.5) {
      this.shoeSizes.push(i.toString());
    }

    this.apparelSizes = [
      "N/A",
      "XXS",
      "XS",
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "XXXL",
      "46",
      "48",
      "50",
      "52",
      "54",
      "56"
    ];

    this.accessorySizes = [
      "N/A",
      "O/S",
      "XS",
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "XXXL"
    ];

    this.headwearSizes = [
      ...this.apparelSizes,
      "6 7/8",
      "7",
      "7 1/8",
      "7 1/4",
      "7 3/8",
      "7 1/2",
      "7 5/8",
      "7 3/4"
    ];

    this.pantsSizes = ["XXS", "XS", "S", "M", "L", "XL", "XXL", "XXXL"];

    this.pantsWaist = [];
    for (let i = 28; i < 37; i++) {
      this.pantsWaist.push(i.toString());
    }

    this.pantsLength = [];
    for (let i = 28; i < 37; i++) {
      this.pantsLength.push(i.toString());
    }

    this.shoeQuestions = [
      {
        id: "yellowing",
        label: intl.formatMessage({
          id: "yellowing",
          defaultMessage: "Yellowing"
        }),
        text: intl.formatMessage({
          id: "yellowing_text",
          defaultMessage: "Is there yellowing of the shoe?"
        })
      },
      {
        id: "scuff_marks",
        label: intl.formatMessage({
          id: "scuff_marks",
          defaultMessage: "Scuff Marks"
        }),
        text: intl.formatMessage({
          id: "scuff_marks_message",
          defaultMessage: "Are there scuff marks on the shoe?"
        })
      },
      {
        id: "defects",
        label: intl.formatMessage({ id: "defects", defaultMessage: "Defects" }),
        text: intl.formatMessage({
          id: "defects_message",
          defaultMessage:
            "Are there any defects including manufacturer defects on the item?"
        })
      },
      {
        id: "missing_insoles",
        label: intl.formatMessage({
          id: "missing_insoles",
          defaultMessage: "Missing Insoles"
        }),
        text: intl.formatMessage({
          id: "missing_insoles_message",
          defaultMessage: "Are the insoles replaced or missing?"
        })
      },
      {
        id: "used",
        label: intl.formatMessage({
          id: "used",
          defaultMessage: "Used"
        }),
        text: intl.formatMessage({
          id: "used_message",
          defaultMessage: "Is the item used?"
        })
      }
    ];

    this.apparelQuestions = [
      {
        id: "discolored",
        label: intl.formatMessage({
          id: "discolored",
          defaultMessage: "Discolored/Stained"
        }),
        text: intl.formatMessage({
          id: "discolored_message",
          defaultMessage: "Is there any discoloring or stains?"
        })
      },
      {
        id: "tears",
        label: intl.formatMessage({ id: "tears", defaultMessage: "Tears" }),
        text: intl.formatMessage({
          id: "tears_message",
          defaultMessage: "Are there tears/rips/holes in the apparel item?"
        })
      },
      {
        id: "defects",
        label: intl.formatMessage({ id: "defects", defaultMessage: "Defects" }),
        text: intl.formatMessage({
          id: "defects_message",
          defaultMessage:
            "Are there any defects including manufacturer defects on the item?"
        })
      },
      {
        id: "missing_tags",
        label: intl.formatMessage({
          id: "missing_tags",
          defaultMessage: "Missing Tags"
        }),
        text: intl.formatMessage({
          id: "missing_tags_message",
          defaultMessage:
            "Are any tags missing (original product tags and item care tags?"
        })
      },
      {
        id: "used",
        label: intl.formatMessage({
          id: "used",
          defaultMessage: "Used"
        }),
        text: intl.formatMessage({
          id: "used_message",
          defaultMessage: "Is the item used?"
        })
      }
    ];

    this.boxConditions = [
      {
        id: "good",
        label: intl.formatMessage({
          id: "good_condition",
          defaultMessage: "Good Condition"
        }),
        value: intl.formatMessage({
          id: "good_condition",
          defaultMessage: "Good Condition"
        })
      },
      {
        id: "missing_lid",
        label: intl.formatMessage({
          id: "missing_lid",
          defaultMessage: "Missing Lid"
        })
      },
      {
        id: "damaged",
        label: intl.formatMessage({ id: "damaged", defaultMessage: "Damaged" })
      },
      {
        id: "no_original",
        label: intl.formatMessage({
          id: "no_original",
          defaultMessage: "No Original Box / Bag"
        })
      }
    ];

    this.apparelPackageConditions = [
      {
        id: "good",
        label: intl.formatMessage({
          id: "good_condition",
          defaultMessage: "Good Condition"
        }),
        value: intl.formatMessage({
          id: "good_condition",
          defaultMessage: "Good Condition"
        })
      },
      {
        id: "damaged",
        label: intl.formatMessage({ id: "damaged", defaultMessage: "Damaged" })
      },
      {
        id: "no_original",
        label: intl.formatMessage({
          id: "no_packaging",
          defaultMessage: "No Original Packaging"
        })
      },
      {
        id: "N/A",
        label: intl.formatMessage({
          id: "n/a",
          defaultMessage: "Does Not Apply"
        })
      }
    ];

    this.currencies = [{ value: "usd", label: "USD" }];

    this.types = [
      {
        id: "shoe",
        label: intl.formatMessage({ id: "shoe", defaultMessage: "Shoes" })
      },
      {
        id: "headwear",
        label: intl.formatMessage({
          id: "headwear",
          defaultMessage: "Headwear"
        })
      },
      {
        id: "jacket",
        label: intl.formatMessage({ id: "jacket", defaultMessage: "Jacket" })
      },
      {
        id: "shirt",
        label: intl.formatMessage({ id: "shirt", defaultMessage: "Shirt" })
      },
      {
        id: "t-shirt",
        label: intl.formatMessage({ id: "t-shirt", defaultMessage: "T-Shirt" })
      },
      {
        id: "top/sweatshirt",
        label: intl.formatMessage({
          id: "top/sweatshirt",
          defaultMessage: "Tops/Sweatshirts"
        })
      },
      {
        id: "bags",
        label: intl.formatMessage({ id: "bags", defaultMessage: "Bags" })
      },
      {
        id: "pants",
        label: intl.formatMessage({ id: "pants", defaultMessage: "Pants" })
      },
      {
        id: "accessory",
        label: intl.formatMessage({
          id: "accessory",
          defaultMessage: "Accessory"
        })
      }
    ];

    this.typesToSizes = {
      shoe: [
        ...this.youthSizes,
        ...this.childSizes,
        ...this.adidasChildSizes,
        ...this.shoeSizes
      ],
      headwear: this.accessorySizes,
      jacket: this.apparelSizes,
      shirt: this.apparelSizes,
      "t-shirt": this.apparelSizes,
      "top/sweatshirt": this.apparelSizes,
      bags: this.accessorySizes,
      pants: this.pantsSizes,
      accessory: this.accessorySizes
    };

    this.fields = [
      {
        id: "item_type",
        label: intl.formatMessage({
          id: "product_type",
          defaultMessage: "Product Type"
        })
      },
      {
        id: "product_name_us",
        label: intl.formatMessage({
          id: "sku_product_name",
          defaultMessage: "Product Name"
        })
      },
      {
        id: "brand",
        label: intl.formatMessage({ id: "sku_brand", defaultMessage: "Brand" })
      },
      {
        id: "color",
        label: intl.formatMessage({ id: "sku_color", defaultMessage: "Color" })
      },
      {
        id: "style",
        label: intl.formatMessage({
          id: "sku_style",
          defaultMessage: "Style Number"
        })
      },
      {
        id: "barcode",
        label: intl.formatMessage({
          id: "sku_barcode",
          defaultMessage: "Barcode"
        })
      }
    ];

    if (this.props.onlyShowConsignment) {
      this.fields = [
        {
          id: "barcode",
          label: intl.formatMessage({
            id: "sku_barcode",
            defaultMessage: "Barcode"
          })
        }
      ];
    }

    this.initialState = {
      form: {}
    };

    this.state = {
      ...this.initialState,
      listOnShopify: props.listOnShopify,
      listOnEbay: props.listOnEbay
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { listOnShopify, listOnEbay } = this.props;

    if (listOnShopify !== prevProps.listOnShopify) {
      this.setState({ listOnShopify });
    }
    if (listOnEbay !== prevProps.listOnEbay) {
      this.setState({ listOnEbay });
    }
  };

  /**
   * input change for product information
   * @param  {EventObject} e  -- event object
   * @param  {String} id -- id string of input
   */
  onFormInputChange = (e, id) => {
    this.props.onFormInputChange(e, id);
  };

  /**
   * On size change
   * @param  {Float} size -- size
   */
  onSizeChange = size => {
    if (this.props.upcCodes) {
      let eBarcode = {
        target: {}
      };
      eBarcode.target.value = !(size in this.props.upcCodes)
        ? ""
        : this.props.upcCodes[size]
        ? this.props.upcCodes[size]
        : "";
      this.props.onFormInputChange(eBarcode, "barcode");
    }

    this.props.onSizeChange && this.props.onSizeChange(size);
  };

  /**
   * On type change
   * @param  {String} typeId -- id of type
   */
  onTypeChange = typeId => {
    this.props.onTypeChange(typeId, this.typesToSizes[typeId]);
  };

  /**
   * On defect selected
   * @param  {String} defect --
   * @return {[type]}        [description]
   */
  onDefectClick = defect => {
    this.props.onDefectClick(defect);
  };

  onPackageClick = (packaging, packageCondition) => {
    this.props.onPackageClick(packaging, packageCondition);
  };

  onPantsChange = (type, size) => {
    this.props.onPantsChange(type, size);
  };

  addPicture = files => {
    let pictures = [...this.props.form.pictures, ...files];
    this.setState(
      {
        pictures
      },
      () => {
        this.props.onPicturesChange(this.state.pictures);
      }
    );
  };

  removePicture = index => {
    let pictures = [...this.props.form.pictures];
    // let removed_id = pictures[index].id;
    pictures.splice(index, 1);
    this.setState(
      {
        pictures
      },
      () => {
        this.props.onPicturesChange(this.state.pictures);
      }
    );
  };

  renderSizes = (availableSizes, size) => {
    const { manageItem, auth, locationId, addItem } = this.props;
    const hasPermission = checkPermissions(
      "change_inventory_size",
      auth.userProfile,
      locationId
    );

    if ((manageItem && hasPermission) || addItem) {
      return <div className={css(styles.sizes)}>{availableSizes}</div>;
    }

    return (
      <div className={css(styles.size)}>
        <span>{size}</span>
      </div>
    );
  };

  render = () => {
    let { modals, intl, localization } = this.props;

    let fields = this.fields.map((field, index) => {
      // let required = true;
      // if (field.id === "style" || field.id === "barcode") {
      //   required = false;
      // }
      return (
        <div className={css(styles.formInputContainer)} key={`field_${index}`}>
          <label className={css(styles.label)}> {field.label} </label>
          <Input
            value={
              this.props.form && field.id === "brand"
                ? this.props.form.sku.product["brand"].name
                : this.props.form.sku[field.id]
            }
            onChange={e => this.onFormInputChange(e, field.id)}
            placeholder={field.label}
            // required={required}
            disabled={true}
          />
        </div>
      );
    });

    let sizeOrder = ["O/S", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "N/A"];

    let sorted_available_sizes =
      this.props.form.sku.item_type === "shoe"
        ? this.props.form.sku.product.available_sizes.sort(
            (a, b) => parseFloat(a) - parseFloat(b)
          )
        : this.props.form.sku.product.available_sizes.sort(
            (a, b) => sizeOrder.indexOf(a) - sizeOrder.indexOf(b)
          );

    let availableSizes = sorted_available_sizes.map((size, index) => {
      return (
        <div
          className={css(
            styles.size,
            this.props.form.consignment.size === size && styles.selected
          )}
          onClick={() => this.props.changeable && this.onSizeChange(size)}
          key={`size_${size}`}
        >
          <span>{size}</span>
        </div>
      );
    });

    let sizes = null;
    switch (this.props.form.sku.item_type) {
      case null:
        sizes = (
          <div className={css(styles.message)}>
            {" "}
            This product doesn't have a product type{" "}
          </div>
        );
        break;
      case "shoe":
        sizes = this.shoeSizes.map((size, index) => {
          return (
            <div
              className={css(
                styles.size,
                this.props.form.consignment.size === size && styles.selected
              )}
              onClick={() => this.props.changeable && this.onSizeChange(size)}
              key={`size_${size}`}
            >
              <span>{size}</span>
            </div>
          );
        });
        break;
      case "accessory":
        sizes = this.accessorySizes.map((size, index) => {
          return (
            <div
              className={css(
                styles.size,
                this.props.form.consignment.size === size && styles.selected
              )}
              onClick={() => this.props.changeable && this.onSizeChange(size)}
              key={`size_${size}`}
            >
              <span>{size}</span>
            </div>
          );
        });
        break;
      case "pants":
        sizes = this.pantsSizes.map((size, index) => {
          return (
            <div
              className={css(
                styles.size,
                this.props.form.consignment.size === size && styles.selected
              )}
              onClick={() => this.props.changeable && this.onSizeChange(size)}
              key={`size_${size}`}
            >
              <span>{size}</span>
            </div>
          );
        });
        break;
      case "headwear":
        sizes = this.headwearSizes.map((size, index) => {
          return (
            <div
              className={css(
                styles.size,
                this.props.form.consignment.size === size && styles.selected
              )}
              onClick={() => this.props.changeable && this.onSizeChange(size)}
              key={`size_${size}`}
            >
              <span>{size}</span>
            </div>
          );
        });
        break;
      default:
        sizes = this.apparelSizes.map((size, index) => {
          return (
            <div
              className={css(
                styles.size,
                this.props.form.consignment.size === size && styles.selected
              )}
              onClick={() => this.props.changeable && this.onSizeChange(size)}
              key={`size_${size}`}
            >
              <span>{size}</span>
            </div>
          );
        });
    }

    if (!sizes) {
      sizes = (
        <div className={css(styles.message)}>
          {intl.formatMessage({
            id: "select_product_type",
            defaultMessage: "Please select a product type to continue"
          })}
        </div>
      );
    }

    // let types = this.types.map((type, index) => {
    //   return (
    //     <div
    //       className={css(
    //         styles.type,
    //         this.props.form.sku.item_type === type.id && styles.selected
    //       )}
    //       key={`type_${type.id}`}
    //       onClick={() => this.props.changeable && this.onTypeChange(type.id)}
    //     >
    //       <span>{type.label}</span>
    //     </div>
    //   );
    // });

    let consignmentConditions =
      this.props.form.sku.item_type === "shoe"
        ? this.shoeQuestions.map((condition, index) => {
            return (
              <div
                className={css(
                  styles.consignmentCondition,
                  this.props.form.consignment[condition.id] && styles.selected
                )}
                onClick={() =>
                  this.props.changeable && this.onDefectClick(condition.id)
                }
              >
                <div className={css(styles.conditionTitle)}>
                  {condition.label}
                </div>
                <div className={css(styles.conditionText)}>
                  {condition.text}
                </div>
              </div>
            );
          })
        : this.apparelQuestions.map((condition, index) => {
            return (
              <div
                className={css(
                  styles.consignmentCondition,
                  this.props.form.consignment[condition.id] && styles.selected
                )}
                onClick={() =>
                  this.props.changeable && this.onDefectClick(condition.id)
                }
              >
                <div className={css(styles.conditionTitle)}>
                  {condition.label}
                </div>
                <div className={css(styles.conditionText)}>
                  {condition.text}
                </div>
              </div>
            );
          });

    let packageConditions =
      this.props.form.sku.item_type === "shoe"
        ? this.boxConditions.map((condition, index) => {
            return (
              <div
                className={css(
                  styles.packageCondition,
                  this.props.form.consignment["box_condition"] ===
                    condition.label && styles.selected
                )}
                onClick={() =>
                  this.props.changeable &&
                  this.onPackageClick("box_condition", condition.label)
                }
              >
                <div className={css(styles.conditionTitle)}>
                  {condition.label}
                </div>
              </div>
            );
          })
        : this.apparelPackageConditions.map((condition, index) => {
            return (
              <div
                className={css(
                  styles.packageCondition,
                  this.props.form.consignment["apparel_package_condition"] ===
                    condition.label && styles.selected
                )}
                onClick={() =>
                  this.onPackageClick(
                    "apparel_package_condition",
                    condition.label
                  )
                }
              >
                <div className={css(styles.conditionTitle)}>
                  {condition.label}
                </div>
              </div>
            );
          });

    const { isEbay } = this.props;

    return (
      <div className={css(styles.formContainer)} id={this.props.id}>
        {modals.manualInputModal.error && (
          <ErrorBlock
            error={modals.manualInputModal.error}
            errorStyles={styles.errorBlock}
          />
        )}
        {!this.props.onlyShowConsignment && (
          <div className={css(styles.instructions)}>
            <span className={css(styles.title)}>
              {this.props.instructionsTitle ? (
                this.props.instructionsTitle
              ) : (
                <FormattedMessage
                  id="consignment_tab"
                  defaultMessage={`Consignment Info`}
                />
              )}
            </span>
            <div className={css(styles.description)}>
              {this.props.instructions}
            </div>
          </div>
        )}
        <form
          className={css(styles.formBody)}
          onSubmit={this.props.onButtonClick}
        >
          {this.props.confirmingDelete && (
            <Fragment>
              <div className={css(styles.title)}>
                <FormattedMessage
                  id="delete_consignment_reason"
                  defaultMessage="Reason for deletion"
                />
              </div>
              <div className={css(styles.deleteReasonContainer)}>
                <Input
                  className={styles.deleteReasonInput}
                  onChange={e => this.onFormInputChange(e, "delete_reason")}
                  value={this.props.form.delete_reason}
                  type="textarea"
                  required={false}
                />
              </div>
            </Fragment>
          )}
          {/* <div className={css(styles.title)}>
            <FormattedMessage
              id="product_type"
              defaultMessage={`Product Type`}
            />
          </div>
          <div className={css(styles.productTypes)}>{types}</div> */}
          {!this.props.noSizes && (
            <div className={css(styles.sizeContainer)}>
              <div className={css(styles.title)}>
                <FormattedMessage id="size" defaultMessage={`Size`} />
              </div>
              {availableSizes.length === 0 ? (
                <div className={css(styles.message)}>
                  {intl.formatMessage({
                    id: "no_size_available",
                    defaultMessage: "There's no sizes attached to this product"
                  })}
                </div>
              ) : (
                this.renderSizes(
                  availableSizes,
                  this.props.form.consignment.size
                )
              )}

              {/* {this.props.form.sku.item_type === "shoe" && (
                <div className={css(styles.sizes)}>{youthSizes}</div>
              )}
              {this.props.form.sku.item_type === "shoe" && (
                <div className={css(styles.sizes)}>{childSizes}</div>
              )}
              {this.props.form.sku.item_type === "shoe" && (
                <div className={css(styles.sizes)}>{adidasChildSizes}</div>
              )}
              <div className={css(styles.sizes)}>{sizes}</div>
              {this.props.form.sku.item_type === "pants" && (
                <div className={css(styles.separator)}>
                  <FormattedMessage
                    id="select_pants_size"
                    defaultMessage={`Or select waist/length size combination`}
                  />
                </div>
              )}
              {this.props.form.sku.item_type === "pants" && (
                <div className={css(styles.pantsSize)}>
                  <div className={css(styles.pantsTitle)}>
                    <FormattedMessage id="waist" defaultMessage={`Waist`} />
                  </div>
                  <div className={css(styles.sizes)}>{pantsWaist}</div>
                </div>
              )}
              {this.props.form.sku.item_type === "pants" && (
                <div className={css(styles.pantsSize)}>
                  <div className={css(styles.pantsTitle)}>
                    <FormattedMessage id="length" defaultMessage="Length" />
                  </div>
                  <div className={css(styles.sizes)}>{pantsLength}</div>
                </div>
              )} */}
            </div>
          )}
          {!this.props.noPrice && (
            <Fragment>
              <div className={css(styles.title)}>
                <FormattedMessage id="price" defaultMessage="Price" />
              </div>
              <div className={css(styles.priceContainer)}>
                <FontAwesomeIcon
                  icon={[
                    "far",
                    localization.locale === "zh-cn" ? "yen-sign" : "dollar-sign"
                  ]}
                  className={css(styles.dollarSign)}
                />
                <Input
                  className={styles.priceInput}
                  onChange={e => this.onFormInputChange(e, "price")}
                  value={this.props.form.price}
                  type="number"
                  required
                  placeholder={intl.formatMessage({
                    id: "enter_price",
                    defaultMessage: "Enter price in $"
                  })}
                  disabled={!this.props.changeable}
                />
              </div>
              {!this.props.hideCost && (
                <>
                  <div className={css(styles.title)}>
                    <FormattedMessage id="cost" defaultMessage="Cost" />
                  </div>
                  <div className={css(styles.priceContainer)}>
                    <FontAwesomeIcon
                      icon={[
                        "far",
                        localization.locale === "zh-cn"
                          ? "yen-sign"
                          : "dollar-sign"
                      ]}
                      className={css(styles.dollarSign)}
                    />
                    <Input
                      className={styles.priceInput}
                      onChange={e => this.onFormInputChange(e, "cost")}
                      value={this.props.form.cost}
                      type="number"
                      placeholder={intl.formatMessage({
                        id: "enter_price",
                        defaultMessage: "Enter cost in $"
                      })}
                      disabled={!this.props.changeable}
                    />
                  </div>
                </>
              )}
            </Fragment>
          )}

          {!this.props.noQuantity && (
            <Fragment>
              <div className={css(styles.title)}>
                <FormattedMessage id="quantity" defaultMessage="Quantity" />
              </div>
              <div className={css(styles.priceContainer)}>
                <Input
                  className={styles.priceInput}
                  onChange={e => this.onFormInputChange(e, "quantity")}
                  value={this.props.form.quantity}
                  type="number"
                  required={true}
                  placeholder={intl.formatMessage({
                    id: "quantity",
                    defaultMessage: "Quantity"
                  })}
                  disabled={!this.props.changeable}
                />
              </div>
            </Fragment>
          )}

          <div className={css(styles.title)}>
            <FormattedMessage
              id="basic_product_information"
              defaultMessage="Basic Product Information"
            />
          </div>
          <div className={css(styles.form)}>{fields}</div>
          {!this.props.noConsignment && (
            <Fragment>
              <div className={css(styles.title)}>
                <FormattedMessage
                  id="consignment_condition"
                  defaultMessage="Consignment Conditions"
                />
              </div>
              <div className={css(styles.consignmentConditions)}>
                {consignmentConditions}
              </div>
              <div className={css(styles.title)}>
                <FormattedMessage
                  id="original_packaging_condition"
                  defaultMessage="Original Packaging Conditions"
                />
              </div>
              <div className={css(styles.packageConditions)}>
                {packageConditions}
              </div>
            </Fragment>
          )}
          {this.props.addConsignment && (
            <Fragment>
              <div className={css(styles.title)}>
                <FormattedMessage
                  id="print_option"
                  defaultMessage="Print Option"
                />
              </div>
              <div className={css(styles.showPriceContainer)}>
                <input
                  type="checkbox"
                  checked={this.props.form.showPrice}
                  onChange={e => this.onFormInputChange(e, "showPrice")}
                  disabled={!this.props.changeable}
                />
                <span> &nbsp; Show Price </span>
              </div>
            </Fragment>
          )}
          <div className={css(styles.title)}>
            <FormattedMessage id="listOnShopify" defaultMessage="List Option" />
          </div>
          <div className={css(styles.showPriceContainer)}>
            <input
              type="checkbox"
              checked={this.state.listOnShopify}
              onChange={e => this.onFormInputChange(e, "listOnShopify")}
              disabled={!this.props.changeable}
            />
            <span> &nbsp; Shopify </span>
          </div>
          <div className={css(styles.showPriceContainer)}>
            <input
              type="checkbox"
              checked={this.state.listOnEbay}
              onChange={e => this.onFormInputChange(e, "listOnEbay")}
              disabled={!this.props.changeable || isEbay}
            />
            <span> &nbsp; Ebay </span>
            {isEbay && <span> &nbsp; (location is set to Ebay)</span>}
          </div>
          {!this.props.addConsignment && (
            <div className={css(styles.imagesPage)}>
              <h2 className={css(styles.pageTitle)}>
                {intl.formatMessage({
                  id: "images",
                  defaultMessage: "Images"
                })}
              </h2>
              <ImageUpload
                imageContainerClassName={css(styles.imageContainerClass)}
                imageAddedCallback={this.addPicture}
                removeImageCallback={this.removePicture}
                images={this.props.form.pictures}
                onSortEnd={this.onSortEnd}
              />
            </div>
          )}
          <Button
            className={styles.button}
            text={this.props.buttonText}
            disabled={!this.props.changeable}
          />
        </form>
      </div>
    );
  };
}
var styles = StyleSheet.create({
  formContainer: {
    width: "100%",
    overflow: "scroll"
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #aaa"
  },
  input: {
    margin: 16,
    height: 50,

    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.RED(1)}`
    }
  },
  search: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  instructions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    textAlign: "center",
    paddingTop: 0,
    borderBottom: "1px solid #aaa"
    // marginBottom: 20,
  },
  title: {
    fontSize: 22
  },
  description: {
    marginTop: 8,
    fontSize: 14,
    width: "80%",
    opacity: 0.7
  },
  picture: {
    width: 100,
    height: 100
  },
  productStyle: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
    height: 100,
    ":hover": {
      background: "#eee"
    }
  },
  error: {
    textAlign: "center"
  },
  errorBlock: {
    marginBottom: 12
  },
  noProductsFound: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  productStyles: {
    width: "100%",
    overflow: "scroll",
    border: "1px solid #ddd",
    borderRadius: 4,
    flex: 1,
    marginBottom: 20
  },
  button: {
    width: "100%",
    marginBottom: 20
  },
  labels: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 20
  },
  column: {
    width: "32%",
    display: "flex",
    justifyContent: "center"
  },
  label: {
    fontWeight: "bold",
    letterSpacing: 0.7,
    marginBottom: 8
  },
  formBody: {
    marginTop: 16
  },
  formInputContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 16
  },
  size: {
    border: "1px solid #000",
    width: 50,
    height: 50,
    marginRight: 10,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#000",

    ":hover": {
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`,
      background: colors.RED(1)
    }
  },
  sizes: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: 20,
    boxSizing: "border-box"
  },
  selected: {
    color: "#fff",
    border: `1px solid ${colors.RED(1)}`,
    background: colors.RED(1)
  },
  type: {
    // width: 100,
    height: 40,
    border: "1px solid #000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    marginBottom: 10,
    cursor: "pointer",
    padding: "0px 16px",

    ":hover": {
      opacity: 0.6
    }
  },
  productTypes: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  message: {
    opacity: 0.7,
    textAlign: "center",
    fontSize: 20,
    letterSpacing: 1,
    padding: 20,
    width: "100%"
  },
  priceInput: {
    width: "100%",
    marginRight: 16,
    paddingLeft: 38,
    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.RED(1)}`
    }
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    padding: "20px 28px",
    position: "relative"
  },
  showPriceContainer: {
    display: "flex",
    alignItems: "center",
    padding: "20px 28px",
    position: "relative"
  },
  dollarSign: {
    position: "absolute",
    left: 48
  },
  consignmentConditions: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  consignmentCondition: {
    padding: 10,
    border: "1px solid #ddd",
    margin: 8,
    width: 220,
    height: 85,
    cursor: "pointer",

    ":hover": {
      background: colors.RED(1),
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`
    }
  },
  conditionTitle: {
    fontSize: 20,
    fontWeight: "bold"
  },
  conditionText: {
    fontSize: 14
  },
  packageConditions: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  packageCondition: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ddd",
    margin: 8,
    width: 220,
    height: 50,
    cursor: "pointer",

    ":hover": {
      background: colors.RED(1),
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`
    }
  },
  pantsTitle: {
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 20
  },
  separator: {
    width: "100%",
    fontWeight: "bold",
    padding: 20,
    paddingBottom: 40
  }
});
const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth,
  localization: state.localization
});
const mapDispatchToProps = dispatch => ({});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConsignmentFields)
);
