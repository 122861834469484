/***
 *
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

// Components
import Barcode from "../inventory/Barcode";
import BarcodeModal from "../modals/AddProduct/BarcodeModal";

// Redux
import { MessageActions } from "../../redux/message";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

import { bindActionCreators } from "redux";
import IMAGECOMING from "../../assets/imagecoming.jpg";

class ConsignmentPrint extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      printBarcode: false,
      item: this.props.item,
      hidePrice: true
    };
  }

  /***
   * On unmount of barcode modal
   */
  printFinished = () => {
    this.setState({
      printBarcode: false
    });

    let params = {
      barcode_printed: true
    };

    return fetch(
      API.CONSIGNMENT({ id: this.state.item.consignment.id, simple: true }),
      API.PUT_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(() => {
        this.props.printFinish(this.props.index);
      });
  };

  onHidePriceChange = e => {
    this.setState({ hidePrice: e.target.checked });
  };

  print = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      printBarcode: true
    });
  };

  render() {
    let item = this.state.item;
    return (
      <div className={css(styles.summaryInfo)} key={item}>
        <div
          className={css(styles.productSummay)}
          onClick={this.props.clickConsignment}
        >
          <img
            className={css(styles.productImgSteps)}
            alt={item.sku.product_name_us}
            src={
              item && item.pictures[0] && item.pictures[0].url
                ? item.pictures[0].url
                : IMAGECOMING
            }
          />
          <p>{item.sku.product_name_us}</p>
        </div>
        {item && (
          <button
            className={css(
              styles.printButton,
              item.consignment.status === "rejected" ||
                item.consignment.status === "sold"
                ? styles.disabledButton
                : "",
              item.consignment.barcode_printed ? styles.printedButton : ""
            )}
            onClick={this.print}
            disabled={
              item.consignment.status === "rejected" ||
              item.consignment.status === "sold"
            }
          >
            <FormattedMessage
              id="print_label"
              defaultMessage={
                item.consignment.barcode_printed
                  ? `Printed ✔`
                  : item.consignment.status === "rejected"
                  ? `Rejected`
                  : item.consignment.status === "sold"
                  ? `Sold`
                  : `Print Label`
              }
            />
          </button>
        )}
        <div className={css(styles.hidePriceContainer)}>
          <input
            type="checkbox"
            checked={this.state.hidePrice}
            onChange={e => this.onHidePriceChange(e)}
          />
          <span> &nbsp; Hide Price </span>
        </div>
        {item && this.state.printBarcode && (
          <BarcodeModal
            unmount={this.printFinished}
            component={
              <Barcode
                hidePrice={this.state.hidePrice}
                name={item.sku && item.sku.product_name_us}
                conditions={item.consignment.used && ["used"]}
                price={
                  (item.consignment.price
                    ? item.consignment.price.default_price
                    : 0) / 100
                }
                barcode={`ss-${item.id}`}
                size={item.sku && item.sku.us_size}
                color={item.sku && item.sku.color}
                style={item.sku && item.sku.style}
                consigner={
                  item.consigner.first_name + " " + item.consigner.last_name
                }
              />
            }
          />
        )}
      </div>
    );
  }
}
var styles = StyleSheet.create({
  summaryInfo: {
    width: "max-content",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    height: "max-content",
    marginBottom: 10,
    backgroundColor: "rgb(250,250,250)"
  },
  productSummay: {
    display: "flex",
    alignItems: "center",
    padding: 20,
    backgroundColor: "rgb(244,244,244)",
    height: 100,
    width: 300,
    cursor: "pointer"
  },
  productImgSteps: {
    width: 50,
    height: 50
  },
  printButton: {
    backgroundColor: `${colors.RED(1)}`,
    borderRadius: 4,
    border: 0,
    padding: 16,
    color: "#fff",
    fontSize: 14,
    minWidth: 120,
    width: "40%",
    letterSpacing: 0.7,
    cursor: "pointer",
    outline: "none",
    marginLeft: 10
  },
  disabledButton: {
    backgroundColor: "rgb(200,200,200)",
    ":hover": {
      backgroundColor: "rgb(200,200,200)"
    }
  },
  printedButton: {
    backgroundColor: "rgb(0,0,0)",
    ":hover": {
      backgroundColor: "rgb(0,0,0)"
    }
  },
  hidePriceContainer: {
    width: 200,
    marginLeft: 20
  }
});

const mapStateToProps = state => ({
  // auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  messageActions: bindActionCreators(MessageActions, dispatch)
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ConsignmentPrint));
