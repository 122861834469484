import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faTimes,
  faChartLine,
  faSpinnerThird,
  faCheckCircle,
  faSearch,
  faPlus,
  faExchange,
  faArrowLeft,
  faLongArrowRight,
  faUsers,
  faEnvelope,
  faHome,
  faPhone,
  faUser as faUserRegular,
  faCheck as faCheckRegular,
  faKey,
  faTags,
  faSignOut,
  faDollarSign,
  faBarcodeRead,
  faQuestionCircle,
  faMoneyBillWave,
  faYenSign,
  faLongArrowAltDown,
  faTruck,
  faChevronUp,
  faChevronDown,
  faLock,
  faHistory
} from "@fortawesome/pro-regular-svg-icons";

import {
  faTable,
  faExclamationTriangle,
  faTimesCircle,
  faCheckCircle as faCheckCircleLight
} from "@fortawesome/pro-light-svg-icons";

import {
  faFileUpload,
  faFileExport,
  faShoppingCart,
  faPersonDolly,
  faCheck,
  faUser,
  faCheckCircle as faCheckCircleSolid,
  faExclamation,
  faWifi,
  faSync,
  faRedoAlt,
  faTimesCircle as faTimesCircleSolid,
  faPencil,
  faExclamationCircle as faExclamationCircleSolid
} from "@fortawesome/pro-solid-svg-icons";

import { faUps, faUsps } from "@fortawesome/free-brands-svg-icons";

library.add(
  faTimesCircleSolid,
  faCheckCircleLight,
  faTimesCircle,
  faExclamationCircleSolid,
  faUps,
  faUsps,
  faPencil,
  faLock,
  faYenSign,
  faSync,
  faLongArrowAltDown,
  faMoneyBillWave,
  faWifi,
  faQuestionCircle,
  faEnvelope,
  faUser,
  faUsers,
  faTimes,
  faTable,
  faCheck,
  faChartLine,
  faFileUpload,
  faFileExport,
  faSpinnerThird,
  faCheckCircle,
  faSearch,
  faPlus,
  faPersonDolly,
  faExchange,
  faShoppingCart,
  faArrowLeft,
  faLongArrowRight,
  faExclamationTriangle,
  faHome,
  faPhone,
  faUserRegular,
  faCheckRegular,
  faHome,
  faCheckCircleSolid,
  faKey,
  faTags,
  faSignOut,
  faDollarSign,
  faExclamation,
  faBarcodeRead,
  faRedoAlt,
  faTruck,
  faChevronDown,
  faChevronUp,
  faHistory
);
