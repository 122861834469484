/***
 * Modal to add products to inventory
 * Includes scanning items into input fields
 * @patr
 */
import React, { Fragment } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTable from "react-table";

// Components
import BaseModal from "../BaseModal";
import Button from "../../Button";
import Dropdown from "../../inventory/Dropdown";
import BarcodeModal from "./BarcodeModal";
import ManualInputModal from "../AddManual/ManualInputModal";
import ShineAlreadySoldModal from "../ShineAlreadySoldModal";
import Toggle from "react-toggle";

// Actions
import { ModalActions } from "../../../redux/modals";
import { MessageActions } from "../../../redux/message";

// Config
import API, { getConsignerIds } from "../../../config/api";
import colors from "../../../config/colors";
import Helpers from "../../../redux/helpers";
import {
  INTERNAL_BARCODE_PREFIX,
  INTERNAL_INVENTORY_ITEM_PREFIX,
  INTERNAL_CONSIGNMENT_ORDER_PREFIX
} from "../../../config/settings";

class AddProductModal extends React.Component {
  constructor(props) {
    super(props);

    let { auth, intl } = this.props;
    this.defaultState = {
      page: 0,
      activeTab: "",
      activeItem: "",
      // TODO: get the chinese names here
      columns: [
        intl.formatMessage({ id: "table-id", defaultMessage: "table-id" }),
        intl.formatMessage({
          id: "list_online",
          defaultMessage: "list online"
        }),
        intl.formatMessage({ id: "image", defaultMessage: "image" }),
        intl.formatMessage({ id: "color", defaultMessage: "color" }),
        intl.formatMessage({ id: "price", defaultMessage: "price" }),
        intl.formatMessage({ id: "cost", defaultMessage: "cost" }),
        intl.formatMessage({ id: "us_size", defaultMessage: "us_size" }),
        intl.formatMessage({
          id: "product_name_us",
          defaultMessage: "product_name_us"
        }),
        intl.formatMessage({ id: "brand", defaultMessage: "brand" }),
        intl.formatMessage({ id: "style", defaultMessage: "style" }),
        intl.formatMessage({ id: "shelf_name", defaultMessage: "shelf_name" }),
        intl.formatMessage({ id: "barcode", defaultMessage: "barcode" }),
        intl.formatMessage({ id: "eu_size", defaultMessage: "eu_size" })
      ],
      data: [],
      doubleClicked: {},
      rowHovered: {},
      quantity: 1,
      currentProductIds: {},
      barcodes: [],
      skuToPrice: {},
      skuToCost: {},
      styleToPrice: {},
      styleToCost: {},
      pushingInventory: false,
      inventoryPushed: false,
      locationId: null,
      currentLocationId: auth.userProfile.location.id,
      currentLocationText: auth.userProfile.location.name,
      locationText: "",
      soldItems: {
        china: [],
        usa: []
      },
      toggle: true,
      disableToggle: false,
      consignerId: null
    };

    this.state = {
      ...this.defaultState
    };
  }

  /***
   * closes the modal
   */
  closeModal = () => {
    let { modalActions, modals } = this.props;
    this.setState({
      ...this.defaultState
    });
    if (modals.openAddProductModal) {
      modalActions.openAddProductModal(false);
    } else {
      modalActions.openReturnModal(false);
    }
  };

  componentWillReceiveProps(nextProps) {
    // if (nextProps.modals.skuModal !== this.props.modals.skuModal && nextProps.modals.skuModal.id) {
    //   this.getSKUData(nextProps.modals.skuModal.barcode, this.state.data.length, this.state.quantity)
    // }
  }

  /**
   * Handles hovering of rows
   * Allows you to remove an item scanned
   * Triggers input to show, allowing user to change values
   * @param  {Object} props -- object containing the properties of a cell
   */
  handleRowHover = (props, rowHovered) => {
    // TODO: Connect to the backend
    if (rowHovered) {
      this.setState({
        rowHovered: {
          rowIndex: props.row._index,
          columnId: props.column.id,
          value: props.value
        }
      });
    } else {
      this.setState({
        rowHovered: {}
      });
    }
  };

  /**
   * Sets active tab with passed tab id
   * @param  int page -- the page active
   * @param string activeTab -- the tab which is active
   */
  setActivePage = (page, activeTab) => {
    this.setState({
      page: page,
      activeTab: activeTab
    });
  };

  /***
   * Remove data from the table
   */
  removeData = props => {
    let { modals } = this.props;
    let index = props.index;
    let newData = [...this.state.data];
    let spliced = newData.splice(index, 1);
    for (let i = 0; i < newData.length; i++) {
      newData[i]["table-id"] = i + 1;
    }

    this.setState({
      data: newData
    });

    let receive = this.state.activeItem.includes(INTERNAL_BARCODE_PREFIX);
    if (modals.addProductModal.move || receive) {
      return null;
    }

    return fetch(
      API.INVENTORY({ id: spliced[0].createdInventoryId }),
      API.PUT_CONFIG({ active: false })
    )
      .then(Helpers.checkStatus)
      .then(res => {
        return res;
      })
      .catch(error => {
        console.log(error);
      });
  };

  /***
   * Gets items aggregated by SKU
   * @params string barcode -- the barcode we want to get the item for
   * @params int tabelId -- the index of where the item is in the table so we can replace it with updated SKU info
   * @params int quantity -- the number of items we're adding
   */
  getSKUData = (barcode, tableId, quantity = 1) => {
    let skuConfigs = {
      barcode: barcode || this.state.search
    };
    let { intl } = this.props;
    return fetch(API.SKU(skuConfigs), API.GET_CONFIG(false))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(async res => {
        let skuData = res.results[0];
        let userAgent = navigator.userAgent.toLowerCase();
        let price = "";
        let cost = "";
        if (this.state.skuToPrice[barcode]) {
          price = this.state.skuToPrice[barcode];
        } else {
          if (userAgent.indexOf(" electron/") > -1) {
            // Electron-specific code
            price = await window.electronPrompt({
              title: intl.formatMessage({
                id: "set_price",
                defaultMessage: "Set Price"
              }),
              label: intl.formatMessage({
                id: "price_in_dollar",
                defaultMessage: "Price in $: "
              }),
              inputAttrs: {
                type: "text",
                required: true
              }
            });
          } else {
            price = prompt(
              intl.formatMessage({
                id: "enter_price",
                defaultMessage: "Please enter a price in $"
              })
            );
            cost = prompt(
              intl.formatMessage({
                id: "enter_cost",
                defaultMessage: "Enter the cost in $"
              })
            );
          }

          let skuToPrice = { ...this.state.skuToPrice };
          skuToPrice[barcode] = price;
          this.setState({
            skuToPrice
          });
        }

        if (this.state.skuToCost[barcode]) {
          cost = this.state.skuToCost[barcode];
        } else {
          let skuToCost = { ...this.state.skuToCost };
          skuToCost[barcode] = cost;
          this.setState({
            skuToCost
          });
        }

        let createdInventory =
          res.results.length > 0
            ? await this.addToInventory(skuData.id, quantity, price, cost)
            : res.results;
        if (createdInventory.length > 0) {
          this.setupSKUData(
            res.results,
            barcode,
            tableId,
            quantity,
            createdInventory[0]
          );
        } else {
          let { modalActions, intl } = this.props;
          let { quantity } = this.state;
          modalActions.openManualInputModal(
            true,
            intl.formatMessage({
              id: "sku_error",
              defaultMessage:
                "No style number associated with this barcode. Do you want to add it below?"
            }),
            { form: { barcode, price, cost, quantity }, page: 1, tableId }
          );
        }
      });
  };

  /***
   * Callback for manual input for created inventory
   */
  inventoryCreatedCallback = (createdInventory, previousTableId, quantity) => {
    let barcode = createdInventory[0].sku.id
      ? `sk-${createdInventory[0].sku.id}`
      : `ss-${createdInventory[0].id}`;
    // this.printBarcode(barcode, createdInventory[0]);
    let tableId = this.state.data.length + 1;
    if (previousTableId) {
      tableId = previousTableId;
    }
    this.setupSKUData(
      [createdInventory[0].sku],
      barcode,
      tableId,
      quantity,
      createdInventory[0]
    );
  };

  /***
   * Gets data of each individual inventory item from the backend to fill the table
   * @params string id -- the id of the individual item we want to receive
   */
  getIndividualItemData = (
    id,
    skuId,
    consignment__isnull,
    price__isnull,
    in_transit
  ) => {
    let { intl, modalActions, modals } = this.props;
    let location = null;
    if (skuId) {
      location = this.state.locationId;
    }
    return fetch(
      API.INVENTORY({
        id,
        sku: skuId,
        consignment__isnull,
        price__isnull,
        location
      }),
      API.GET_CONFIG(false)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = [res];
        let id = null;
        let soldItems = {
          china: [],
          usa: []
        };
        if (skuId) {
          for (let i = 0; i < res.results.length; i++) {
            if (!this.state.currentProductIds[res.results[i].id]) {
              id = res.results[i].id;
              data = [res.results[i]];
              break;
            }
          }

          if (!id) {
            let { messageActions } = this.props;
            messageActions.setMessage(
              intl.formatMessage({
                id: "no_transit_products",
                defaultMessage: "No more in transit products!"
              })
            );
            messageActions.showMessage({ show: true, error: true });
            return null;
          }
        }

        let tableId = this.state.data.length;

        data = data.map((row, index) => {
          let item = {
            "table-id": tableId,
            manage: null
          };

          // Unfurl SKU into the row
          let sku = { ...row.sku };
          delete sku.pictures;
          delete row.sku;

          let price = {};
          if (row.price) {
            price = { ...row.price };
            delete row.price;
          }

          let picture_url = null;

          // Unfurl the photo link into the row
          if (row.pictures) {
            // row.pictures is currently an array with one object if it exists
            picture_url = row.pictures[0]
              ? row.pictures[0].url
              : row.pictures[0];
          }
          delete row.pictures;

          let priceNum = price.default_price / 100;
          if (!priceNum) {
            priceNum = row.consignment.price.default_price / 100;
          }

          if (row.sold && !modals.openReturnModal) {
            let inv_item = {
              ...item,
              image: picture_url,
              ...sku,
              ...row,
              price: priceNum
            };

            if (row.order_ship_to_address.country.toLowerCase() === "china") {
              soldItems.china.push(inv_item);
            } else {
              soldItems.usa.push(inv_item);
            }
            let fulfillment = row.order_information
              ? row.order_information[0].fulfillment
              : null;
            if (fulfillment) {
              this.markAsFulfilled(fulfillment);
            }
          }
          return {
            ...item,
            image: picture_url,
            ...sku,
            ...row,
            price: priceNum
          };
        });

        let prevData = [...this.state.data];
        prevData[tableId - 1] = data[0];

        let currentProductIds = { ...this.state.currentProductIds };
        currentProductIds[id] = true;

        if (soldItems.china.length > 0 || soldItems.usa.length > 0) {
          modalActions.openShineAlreadySoldModal(true);
        }

        this.setState({
          data: prevData,
          currentProductIds,
          soldItems
        });

        return data[0];
      });
  };

  /**
   * Get consignment order from consignment order id.
   * Format for table
   * @param  {String} id -- id of consignment order
   */
  getConsignmentOrder = id => {
    let { modalActions } = this.props;
    let params = API.GET_CONFIG();
    fetch(API.CONSIGNMENT_ORDERS({ id }), params)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(resp => {
        let currentProductIds = { ...this.state.currentProductIds };
        let tableId = this.state.data.length;
        let soldItems = {
          china: [],
          usa: []
        };
        let data = resp.consignment_items.map((row, index) => {
          let item = {
            "table-id": tableId,
            manage: null
          };

          // Unfurl SKU into the row
          let sku = { ...row.sku };
          delete sku.pictures;
          delete row.sku;

          let price = {};
          if (row.price) {
            price = { ...row.price };
            delete row.price;
          }

          let picture_url = null;

          // Unfurl the photo link into the row
          if (row.pictures) {
            // row.pictures is currently an array with one object if it exists
            picture_url = row.pictures[0]
              ? row.pictures[0].url
              : row.pictures[0];
          }
          delete row.pictures;

          let priceNum = price.default_price / 100;
          if (!priceNum) {
            priceNum = row.consignment.price.default_price / 100;
          }
          currentProductIds[row.id] = true;
          if (row.sold) {
            let inv_item = {
              ...item,
              image: picture_url,
              ...sku,
              ...row,
              price: priceNum
            };
            if (row.order_ship_to_address.country.toLowerCase() === "china") {
              soldItems.china.push(inv_item);
            } else {
              soldItems.usa.push(inv_item);
            }
            let fulfillment = row.order_information[0].fulfillment;
            this.markAsFulfilled(fulfillment);
          }
          tableId++;
          return {
            ...item,
            image: picture_url,
            ...sku,
            ...row,
            price: priceNum
          };
        });
        let filteredData = data.filter(function(val) {
          return val !== undefined && val !== null;
        });

        let prevData = [...this.state.data];
        prevData.splice(prevData.length - 1, 1);
        prevData = prevData.concat(filteredData);

        if (soldItems.china.length > 0 || soldItems.usa.length > 0) {
          modalActions.openShineAlreadySoldModal(true);
        }
        this.setState({
          data: prevData,
          currentProductIds,
          soldItems
        });
      });
  };

  /**
   * Sets up the SKU Data
   * @params [data] -- the data in a list of sku's
   * @params string barcode -- the barcode we want to get the item for
   * @params int tabelId -- the index of where the item is in the table so we can replace it with updated SKU info
   * @params int quantity -- the number of items we're adding
   * @params [{inventory}] -- a list of the created inventory objects
   */
  setupSKUData = (data, barcode, tableId, quantity, createdInventory) => {
    if (data.length <= 0) {
      let { modalActions, intl } = this.props;
      modalActions.openAddSKUModal(
        true,
        intl.formatMessage({
          id: "sku_error",
          defaultMessage:
            "No SKU associated with this barcode. Do you want to add it below?"
        }),
        { barcode: barcode }
      );
      return null;
    } else {
      // Transform the data to fit react-table
      data = data.map((row, index) => {
        let item = {
          "table-id": tableId
        };

        let picture_url = null;
        // Unfurl the photo link into the row
        if (row.pictures) {
          // row.pictures is currently an array with one object if it exists
          picture_url = row.pictures[0] ? row.pictures[0].url : row.pictures[0];
        }
        delete row.pictures;

        let price = createdInventory.price.default_price / 100;
        return {
          ...item,
          image: picture_url,
          cost: createdInventory.cost / 100,
          ...row,
          price: price
        };
      });

      let prevData = [...this.state.data];
      for (let i = 0; i < quantity; i++) {
        let newData = {
          ...data[0],
          id: createdInventory.id
        };

        newData["table-id"] = tableId + i;
        prevData[tableId - 1 + i] = newData;
        newData["createdInventoryId"] = createdInventory.id;
        newData["skuId"] = createdInventory.sku.id;
        newData["item"] = createdInventory;
      }

      this.setState({
        data: prevData
      });
    }
  };

  updateProduct = soldItems => {
    this.setState({
      soldItems
    });
  };

  printBarcode = (barcode, item) => {
    let { modals } = this.props;

    if (!modals.addProductModal.move) {
      let key = Math.random()
        .toString(36)
        .substring(7);
      this.setState({
        barcodes: [...this.state.barcodes, { barcode, item, key }]
      });
    }

    this.barcodeInput && this.barcodeInput.focus();
  };

  unmount = key => {
    let barcodes = [...this.state.barcodes];
    let index = 0;
    for (let i = 0; i < barcodes.length; i++) {
      if (barcodes[i].key === key) {
        index = i;
        break;
      }
    }

    barcodes.splice(index, 1);

    this.setState({
      barcodes
    });
  };

  /**
   * Change the quantity of product to add
   */
  quantityChange = e => {
    this.setState({
      quantity: parseInt(e.target.value, 10)
    });
  };

  /**
   * Checks if enter was pressed for the input to add to inventory
   */
  keyPress = e => {
    if (e.key === "Enter") {
      this.addProduct();
    }
  };

  /**
   * Transforms the single item inventory to an aggregated inventory view
   */
  aggregateInventory = () => {
    let columns = [...this.state.columns];
    columns.splice(3, 0, "quantity");

    let items = {};
    for (let i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].barcode in items) {
        items[this.state.data[i].barcode].quantity += 1;
      } else {
        items[this.state.data[i].barcode] = this.state.data[i];
        items[this.state.data[i].barcode].quantity = 1;
      }
    }

    let newData = Object.values(items);

    this.setState({
      columns,
      data: newData
    });
  };

  /**
   * Adds shelf to inventory
   * @param { String } shelf -- the shelf we're adding for inventory
   */
  addToShelf = shelf => {
    let data = [...this.state.data];
    for (let i = 0; i < data.length; i++) {
      if (!data[i].shelf_name || data[i].shelf_name === "") {
        data[i].shelf_name = shelf;
      }
    }
    this.setState({
      data
    });
    // fetch(API.SHELF({name: shelf}), API.GET_CONFIG())
    // .then(Helpers.checkStatus)
    // .then(res => {
    //   for (let i = 0; i < data.length; i++) {
    //     if (!data[i].shelf) {
    //       data[i].shelf_name = res.name;
    //       data[i].shelf_id = res.id;
    //     }
    //   }

    // })
  };

  /**
   * Push all items to inventory
   */
  pushToInventory = async () => {
    let { modals, messageActions, intl } = this.props;

    // let receive = this.state.activeItem.includes(INTERNAL_BARCODE_PREFIX);

    this.setState({
      pushingInventory: true
    });

    if (modals.addProductModal.move) {
      if (!this.state.locationId || !this.state.currentLocationId) {
        messageActions.setMessage(
          intl.formatMessage({
            id: "please_set_location",
            defaultMessage: "Please set the locations to move the product"
          })
        );
        messageActions.showMessage({ show: true, error: true, clickoff: true });
        this.setState({
          pushingInventory: false
        });
        return;
      }
      let moveIds = [];
      for (let i = 0; i < this.state.data.length; i++) {
        moveIds.push(this.state.data[i].id);
      }

      let data = {
        ship_to: parseInt(this.state.locationId, 10),
        ship_from: parseInt(this.state.currentLocationId, 10),
        items: moveIds
      };
      this.aggregateInventory();
      return fetch(API.SHIPMENT(), API.POST_CONFIG(data))
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          this.props.inventoryPushCallback();
          this.setState({
            inventoryPushed: true
          });
        });
    } else {
      let add_inventory_order = await this.addInventoryOrder({
        location: this.state.locationId
      });
      let received = [];
      for (let i = 0; i < this.state.data.length; i++) {
        received.push({ id: this.state.data[i].id, in_transit: false });
      }
      let { modals } = this.props;
      let update = this.state.data.map((item, index) => {
        // delete item.consignment.pictures;
        return {
          // consignment: item.consignment,
          id: item.id,
          shelf: item.shelf_name,
          add_inventory_order_id: add_inventory_order.id,
          location: parseInt(this.state.locationId, 10),
          is_return: modals.openReturnModal
        };
      });
      this.aggregateInventory();
      return fetch(API.INVENTORY({ id: 1 }), API.PUT_CONFIG(update))
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          this.props.inventoryPushCallback();
          this.setState({
            inventoryPushed: true
          });
        });
    }
  };

  /**
   * Creates an inventory order for inventory item to be associated with
   */
  addInventoryOrder = params => {
    return fetch(API.ADD_INVENTORY_ORDER, API.POST_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        return res;
      });
  };

  openManualInputModal = () => {
    let { modalActions } = this.props;
    modalActions.openManualInputModal(true);
  };

  /**
   * PUT Request to update Items in inventory
   * @param { Int } inventoryID -- the ID of the inventory item we want to update
   * @param { Object } update -- the update we want to make to the inventory item
   */
  updateInventory = (inventoryID, update) => {
    return fetch(API.INVENTORY({ id: inventoryID }), API.PUT_CONFIG(update))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let { messageActions, intl } = this.props;
        messageActions.setMessage(
          intl.formatMessage({
            id: "product_updated",
            defaultMessage: "Product Updated"
          })
        );
        messageActions.showMessage({ show: true });
        return res;
      })
      .catch(error => {
        console.log(error);
      });
  };

  /***
   * POST Request to Add Items to inventory
   * @int skuId -- the ID of the SKU we want to create inventory for
   * @int quantity -- the amount of inventory we want to create
   * @string price -- the amount in dollars of the price of the item
   * @string cost -- the amount in dollars of the cost of the item
   */
  addToInventory = (skuId, quantity, price, cost) => {
    let priceInCents = parseFloat(price, 2) * 100;
    let costInCents = parseFloat(cost, 2) * 100;
    let data = {
      sku: skuId,
      location: parseInt(this.state.locationId, 10),
      cost: costInCents,
      price: {
        default_price: priceInCents,
        instore_price: priceInCents,
        goat_price: priceInCents,
        magento_price: priceInCents,
        solestage_price: priceInCents,
        shine_price: priceInCents
      },
      consigner: parseInt(this.state.consignerId),
      list_on_magento: true
    };

    let allData = Array(quantity).fill(data);
    return fetch(API.INVENTORY({}), API.POST_CONFIG(allData))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        return res;
      })
      .catch(error => {
        console.log(error);
      });
  };

  /***
   * Current location for moving products
   */
  currentLocationChange = e => {
    let locationId = e.target.value;
    let selectedOption = e.target.options.selectedIndex;
    this.setState({
      currentLocationId: locationId,
      currentLocationText: e.target.options[selectedOption].text
    });
    this.barcodeInput && this.barcodeInput.focus();
  };

  /***
   * Choose to add more to inventory
   */
  addMoreInventory = () => {
    this.setState({
      ...this.defaultState,
      locationId: this.state.locationId
    });
  };

  /**
   * save changes to items
   * @param  {Object} row -- row information being changed
   */
  saveSKUChanges = row => {
    let { messageActions, intl } = this.props;
    let config = { ...row };

    if ("pictures" in config) {
      delete config["pictures"];
    }

    if (this.state.doubleClicked.header === "price") {
      let priceObject = {
        price: {
          default_price: config["price"] * 100, // bring price back to cents
          instore_price: config["price"] * 100, // bring price back to cents
          goat_price: config["price"] * 100,
          magento_price: config["price"] * 100,
          solestage_price: config["price"] * 100,
          shine_price: config["price"] * 100
        }
      };
      return fetch(
        API.INVENTORY({ id: config["createdInventoryId"] }),
        API.PUT_CONFIG(priceObject)
      )
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          messageActions.setMessage(
            intl.formatMessage({
              id: "product_updated",
              defaultMessage: "Product Updated"
            })
          );
          messageActions.showMessage({ show: true });

          let newData = [...this.state.data];

          for (let i = 0; i < newData.length; i++) {
            if (newData[i].createdInventoryId === res.id) {
              newData[i]["price"] = res.price.default_price;
              newData[i].item.price = res.price;
            }
          }

          this.setState({
            data: newData
          });
        });
    }

    if ("price" in config) {
      delete config["price"];
    }

    fetch(API.SAVE_SKU(row.skuId), API.PUT_CONFIG(config))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        messageActions.setMessage(
          intl.formatMessage({
            id: "product_updated",
            defaultMessage: "Product Updated"
          })
        );
        messageActions.showMessage({ show: true });
        let newData = [...this.state.data];

        for (let i = 0; i < newData.length; i++) {
          if (newData[i].skuId === res.id) {
            let newStuff = { ...res };
            delete newStuff["id"];
            newData[i] = { ...newData[i], ...newStuff };
            newData[i].item.sku = res;
          }
        }

        this.setState({
          data: newData
        });
      });
  };

  /**
   * Save changes on frontend when cell information is changed
   * @param  {Event} e -- change event that triggers when a cell is changed
   */
  onCellChange = e => {
    clearTimeout(this.onCellChangeTimeout);
    let doubleClicked = { ...this.state.doubleClicked };
    let data = [...this.state.data];
    doubleClicked.value = e.target.value;
    data[doubleClicked.rowIndex][doubleClicked.columnId] = e.target.value;

    this.onCellChangeTimeout = setTimeout(() => {
      if (doubleClicked.columnId === "cost") {
        let costInDollars =
          data[doubleClicked.rowIndex][doubleClicked.columnId];
        let costInCents = parseFloat(costInDollars, 10) * 100;
        this.updateInventory(data[doubleClicked.rowIndex].id, {
          cost: costInCents
        });
      } else {
        this.saveSKUChanges(data[doubleClicked.rowIndex]);
      }
    }, 700);
    this.setState({
      doubleClicked: doubleClicked,
      data: data
    });
  };

  /***
   * Adds product to memory and the backend
   */
  addProduct = async () => {
    let { modals } = this.props;
    if (!this.state.activeItem || this.state.activeItem === "") {
      return null;
    }

    let data = [...this.state.data];
    if (
      this.state.activeItem.slice(0, 3) !== INTERNAL_BARCODE_PREFIX &&
      this.state.activeItem.slice(0, 3) !== INTERNAL_INVENTORY_ITEM_PREFIX &&
      this.state.activeItem.slice(0, 3) !== INTERNAL_CONSIGNMENT_ORDER_PREFIX &&
      this.state.activeItem.includes("SHELF")
    ) {
      this.addToShelf(this.state.activeItem);
      return null;
    }
    // First check and make sure the product isn't already added
    for (let i = 0; i < this.state.data.length; i++) {
      if (
        this.state.data[i].id ===
        parseInt(this.state.activeItem.replace(INTERNAL_BARCODE_PREFIX, ""), 10)
      ) {
        this.setState({
          activeItem: "",
          quantity: 1
        });
        return null;
      }
    }
    // Add a table id to the data
    for (let i = 0; i < this.state.quantity; i++) {
      data.push({
        barcode: this.state.activeItem,
        "table-id": this.state.data.length + i
      });
    }

    this.setState(
      {
        data
      },
      async () => {
        let receiveConsignment = this.state.activeItem.includes(
          INTERNAL_BARCODE_PREFIX
        );
        let receiveProduct = this.state.activeItem.includes(
          INTERNAL_INVENTORY_ITEM_PREFIX
        );
        let receiveConsignmentOrder = this.state.activeItem.includes(
          INTERNAL_CONSIGNMENT_ORDER_PREFIX
        );
        if (receiveConsignment) {
          // eslint-disable-next-line
          let item = await this.getIndividualItemData(
            this.state.activeItem.replace(INTERNAL_BARCODE_PREFIX, "")
          );
          // this.updateInventory(item.id, {in_transit: false});
        } else if (receiveProduct) {
          // eslint-disable-next-line
          let item = await this.getIndividualItemData(
            null,
            this.state.activeItem.replace(INTERNAL_INVENTORY_ITEM_PREFIX, ""),
            true,
            false,
            true
          );
        } else if (receiveConsignmentOrder) {
          await this.getConsignmentOrder(
            this.state.activeItem.replace(INTERNAL_CONSIGNMENT_ORDER_PREFIX, "")
          );
        } else if (modals.addProductModal.move) {
          if (this.state.activeItem.includes("sk-")) {
            await this.getIndividualItemData(
              null,
              this.state.activeItem.replace(INTERNAL_INVENTORY_ITEM_PREFIX, ""),
              true,
              false,
              false
            );
          } else {
            await this.getIndividualItemData(
              this.state.activeItem.replace(INTERNAL_BARCODE_PREFIX, "")
            );
          }
        } else {
          await this.getSKUData(
            this.state.activeItem,
            this.state.data.length - (this.state.quantity - 1),
            this.state.quantity
          );
        }

        this.barcodeInput && this.barcodeInput.focus();

        this.setState({
          activeItem: "",
          quantity: 1
        });
      }
    );
  };

  /**
   * Handles double clicking on a cell
   * Saves row, column, and value information for clicked cell
   * Triggers input to show, allowing user to change values
   * @param  {Object} props -- object containing the properties of a cell
   */
  handleDblClick = props => {
    // TODO: Connect to the backend
    if (props.column.Header) {
      this.setState({
        doubleClicked: {
          rowIndex: props.row._index,
          columnId: props.column.id,
          value: props.value,
          header: props.column.Header
        },
        selectedInput: true
      });
    }
  };

  /***
   * Sends products to specific location
   */
  locationChange = e => {
    let locationId = e.target.value;
    let selectedOption = e.target.options.selectedIndex;
    this.setState({
      locationId: locationId,
      locationText: e.target.options[selectedOption].text
    });

    this.barcodeInput && this.barcodeInput.focus();
  };

  /***
   * After the modal is opened, we need to check if it's from a global look or not
   */
  onAfterOpen = () => {
    let { modals, auth } = this.props;
    if (modals.addProductModal.globalView) {
      this.setState({
        page: -1
      });
    } else {
      let locationId = auth.userProfile.location.id;
      let consignerId = this.getConsignerId(locationId);

      this.setState({
        locationId,
        consignerId
      });
    }
  };

  getConsignerId = locationId => {
    let consignerIds = getConsignerIds();

    if (locationId === 4) {
      return consignerIds.warehouseId;
    } else if (locationId === 1) {
      return consignerIds.fairfaxId;
    }
    return null;
  };

  checkInInventory = ({ id }) => {
    this.setState({
      page: 0,
      locationId: id,
      consignerId: this.getConsignerId(id)
    });
  };

  /***
   * Button to print all barcodes scanned in
   */
  printAllBarcodes = () => {
    let barcodes = [];
    for (let i = 0; i < this.state.data.length; i++) {
      let barcode = `sk-${this.state.data[i].skuId}`;
      let item = this.state.data[i].item;
      let key = Math.random()
        .toString(36)
        .substring(7);
      barcodes.push({ barcode, item, key });
    }

    let { modals } = this.props;

    if (!modals.addProductModal.move) {
      this.setState({
        barcodes: [...this.state.barcodes, ...barcodes]
      });
    }

    this.barcodeInput && this.barcodeInput.focus();
  };

  /**
   * Function to list/delist item on magento
   * @param { Object } props - the item being updated
   */
  listOnMagento = async props => {
    let { data } = this.state;
    let index = props.index;
    let toggleState = data[index].item.list_on_magento;
    let config = await API.PUT_CONFIG({ list_on_magento: !toggleState }); // toggle to opposite (if true, then false. etc.)
    return fetch(API.INVENTORY({ id: props.original.item.id }), config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let newData = [...data];
        newData[index].item.list_on_magento = res.list_on_magento;
        this.setState({ data: newData });
      })
      .catch(error => {
        console.log(error);
      });
  };

  /**
   * Function to determine and to display whether a toggle updatee is successful or unsuccessful
   * @param { Boolean } toggleState - true if toggle
   */
  listProductMessage = () => {};

  markAsFulfilled = fulfillment => {
    let config = API.PUT_CONFIG({ status: "Fulfilled" });
    return fetch(API.FULFILLMENT({ fulfillment_id: fulfillment.id }), config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(resp => {
        return resp;
      });
  };

  setConsigner = consignerId => {
    this.setState({ consignerId });
  };

  render() {
    let { modals, intl, auth, inventory, modalActions } = this.props;

    let columns = this.state.columns.map((column, index) => ({
      Header: column ? (column !== "table-id" ? column : null) : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => {
        if (props.column.Header !== "list online") {
          return (
            <div
              className={css(styles.valueContainer)}
              onDoubleClick={() => this.handleDblClick(props)}
              onMouseEnter={() => this.handleRowHover(props, true)}
              onMouseLeave={() => this.handleRowHover(props, false)}
            >
              {props.column.Header === "image" ? (
                <div>
                  {props.row._index === this.state.rowHovered.rowIndex ? (
                    <button
                      className={css(styles.reprint)}
                      onClick={() =>
                        this.printBarcode(
                          `sk-${props.original.skuId}`,
                          props.original.item
                        )
                      }
                    >
                      Reprint Barcode
                    </button>
                  ) : props.value ? (
                    <img
                      className={css(styles.img)}
                      src={props.value}
                      alt={props.value}
                    />
                  ) : (
                    intl.formatMessage({
                      id: "no_image_found",
                      defaultMessage: "No image found"
                    })
                  )}
                </div>
              ) : props.column.id === "table-id" ? (
                <div
                  className={css(
                    styles.cellValue,
                    props.column.Header === null && styles.rowNumber
                  )}
                >
                  {this.state.inventoryPushed ? (
                    <FontAwesomeIcon
                      icon={["fas", "check"]}
                      className={css(styles.timesRow, styles.check)}
                    />
                  ) : props.row._index === this.state.rowHovered.rowIndex ? (
                    <FontAwesomeIcon
                      icon={["far", "times"]}
                      className={css(styles.timesRow)}
                      onClick={() => this.removeData(props)}
                    />
                  ) : (
                    props.value
                  )}
                </div>
              ) : props.row._index === this.state.doubleClicked.rowIndex &&
                props.column.id === this.state.doubleClicked.columnId ? (
                <input
                  className={css(styles.doubleClickInput)}
                  value={this.state.doubleClicked.value}
                  onChange={this.onCellChange}
                  ref={ref => {
                    this.input = ref;
                  }}
                  autoFocus
                />
              ) : (
                <div
                  className={css(
                    styles.cellValue,
                    props.column.Header === null && styles.rowNumber
                  )}
                >
                  {props.row[index] ? props.row[index].value : props.value}
                </div>
              )}
            </div>
          );
        } else {
          return (
            <div
              className={css(styles.valueContainer)}
              onDoubleClick={e => this.handleDblClick(props)}
              onMouseEnter={e => this.handleRowHover(props, true)}
              onMouseLeave={e => this.handleRowHover(props, false)}
            >
              <Toggle
                checked={
                  this.state.data && props.original.item
                    ? this.state.data[props.index].item.list_on_magento
                    : false
                }
                onChange={() => this.listOnMagento(props)}
              />
            </div>
          );
        }
      }
    }));

    let locations = inventory.locations.map((location, index) => {
      return (
        <div
          className={css(styles.location, index % 2 === 1 && styles.separate)}
          key={`location_${location.id}_addproductmodal`}
          onClick={() => this.checkInInventory(location)}
        >
          {location.name}
        </div>
      );
    });
    let barcodes = this.state.barcodes.map((dict, index) => {
      if (index > 0) {
        return null;
      }
      return (
        <BarcodeModal
          barcode={dict.barcode}
          key={`${dict.key}_${index}`}
          unmount={this.unmount}
          barcodeKey={dict.key}
          name={dict.item.sku && dict.item.sku.product_name_us}
          size={dict.item.sku && dict.item.sku.us_size}
          color={dict.item.sku && dict.item.sku.color}
          style={dict.item.sku && dict.item.sku.style}
          price={dict.item.price.default_price / 100}
        />
      );
    });

    return (
      <BaseModal
        isOpen={modals.openAddProductModal || modals.openReturnModal}
        closeModal={this.closeModal}
        modalClassName={[styles.modal]}
        afterOpen={this.onAfterOpen}
        animate={true}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <div className={css(styles.productModal)}>
          <FontAwesomeIcon
            icon={["far", "times"]}
            className={css(styles.times)}
            onClick={this.closeModal}
          />
          <div className={css(styles.header)}>
            {modals.addProductModal.move ? (
              <div>
                <h2 className={css(styles.moveTitle)}>
                  <FormattedMessage
                    id="management_modal_move"
                    defaultMessage="Move Product"
                  />
                </h2>
                <h2 className={css(styles.title)}>
                  <div className={css(styles.locationSelector)}>
                    <Dropdown
                      onChange={this.currentLocationChange}
                      removeAll={true}
                      default={auth.userProfile.location.id}
                    />
                  </div>
                  <FontAwesomeIcon
                    icon={["far", "long-arrow-right"]}
                    size={"2x"}
                    className={css(styles.longArrow)}
                  />
                  <div className={css(styles.locationSelector)}>
                    <Dropdown onChange={this.locationChange} removeAll={true} />
                  </div>
                </h2>
              </div>
            ) : this.state.page === -1 ? null : (
              !this.state.inventoryPushed && (
                <h2 className={css(styles.title)}>
                  {modals.openReturnModal ? (
                    <FormattedMessage
                      id="return_product"
                      defaultMessage="Return Products"
                    />
                  ) : (
                    <FormattedMessage
                      id="add_product"
                      defaultMessage="Add Products"
                    />
                  )}{" "}
                  {this.state.data.length > 0 && `(${this.state.data.length})`}
                  <FontAwesomeIcon
                    icon={["far", "long-arrow-right"]}
                    size={"2x"}
                    className={css(styles.longArrow)}
                  />
                  <div className={css(styles.locationSelector)}>
                    <Dropdown
                      onChange={this.locationChange}
                      removeAll={true}
                      default={this.state.locationId}
                    />
                  </div>
                </h2>
              )
            )}
          </div>
          {this.state.page === -1 ? (
            <div className={css(styles.chooseStoreBody)}>
              <h2> Which Location are these products going to? </h2>
              <div className={css(styles.allLocations)}>{locations}</div>
            </div>
          ) : !this.state.inventoryPushed ? (
            <Fragment>
              <div className={css(styles.body)}>
                <div className={css(styles.inputContainer)}>
                  <input
                    className={css(styles.input)}
                    onKeyPress={this.keyPress}
                    value={this.state.activeItem}
                    id="scanBarcode"
                    placeholder={intl.formatMessage({
                      id: "scan_barcode",
                      defaultMessage: "Scan or enter barcode"
                    })}
                    onChange={e =>
                      this.setState({ activeItem: e.target.value })
                    }
                    ref={ref => (this.barcodeInput = ref)}
                    autoFocus
                  />
                  <input
                    className={css(
                      styles.input,
                      styles.quantityInput,
                      styles.marginLeft
                    )}
                    value={this.state.quantity}
                    onKeyPress={this.keyPress}
                    onChange={this.quantityChange}
                    type="number"
                    min={1}
                    placeholder={intl.formatMessage({
                      id: "quantity",
                      defaultMessage: "Quantity"
                    })}
                  />
                  <div
                    className={css(styles.buttonContainer, styles.marginLeft)}
                  >
                    <Button
                      onClick={this.addProduct}
                      className={
                        modals.addProductModal.move && styles.moveColor
                      }
                      text={
                        modals.openReturnModal
                          ? intl.formatMessage({
                              id: "return_product",
                              defaultMessage: "Return Product"
                            })
                          : intl.formatMessage({
                              id: "add_product",
                              defaultMessage: "Add Product"
                            })
                      }
                    />
                  </div>
                  {!modals.openReturnModal && (
                    <>
                      <div
                        className={css(
                          styles.buttonContainer,
                          styles.marginLeft,
                          styles.manualContainer
                        )}
                        id="manualInput"
                      >
                        <Button
                          onClick={this.openManualInputModal}
                          className={styles.manual}
                          text={intl.formatMessage({
                            id: "manual_input",
                            defaultMessage: "Manual Input"
                          })}
                        />
                      </div>
                      <div className={css(styles.locationSelector)}>
                        <Dropdown
                          consigner
                          onChange={e =>
                            this.setConsigner(parseInt(e.target.value))
                          }
                          default={this.state.locationId}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {!modals.openReturnModal && this.state.data.length > 0 && (
                <div
                  className={css(
                    styles.buttonContainer,
                    styles.marginLeft,
                    styles.printAllBarcodes,
                    styles.printButtonContainer
                  )}
                >
                  <Button
                    onClick={this.printAllBarcodes}
                    className={styles.moveColor}
                    text={intl.formatMessage({
                      id: "print_all_barcodes",
                      defaultMessage: "Print all Barcodes"
                    })}
                  />
                  {(this.state.soldItems.china.length > 0 ||
                    this.state.soldItems.usa.length > 0) && (
                    <Button
                      onClick={() =>
                        modalActions.openShineAlreadySoldModal(true)
                      }
                      className={styles.shineSoldColor}
                      text={intl.formatMessage({
                        id: "manage_shine_sold",
                        defaultMessage: "Manage Items Already Sold on Shine"
                      })}
                    />
                  )}
                </div>
              )}
              <div className={css(styles.tableContainer)}>
                <ReactTable
                  className={"addProductTable"}
                  data={this.state.data}
                  filterable={false}
                  pages={this.state.numPages}
                  columns={columns}
                  footerClassName={css(styles.tableFooter)}
                  defaultPageSize={1000000}
                  minRows={0}
                />
              </div>
            </Fragment>
          ) : (
            <div className={css(styles.inventoryPushed)}>
              <h2 className={css(styles.inventoryMoved)}>
                {modals.addProductModal.move ? (
                  <FormattedMessage
                    id="inventory_moved"
                    defaultMessage={`Inventory status is now in transit from ${
                      this.state.currentLocationText
                    } to ${this.state.locationText}`}
                    values={{
                      currentLocation: this.state.currentLocationText,
                      locationText: this.state.locationText
                    }}
                  />
                ) : modals.openReturnModal ? (
                  <FormattedMessage
                    id="inventory_returned"
                    defaultMessage="Return successfully processed."
                  />
                ) : (
                  <FormattedMessage
                    id="inventory_added"
                    defaultMessage="Inventory successfully added."
                  />
                )}
              </h2>
              <div className={css(styles.inventoryBody)}>
                <div className={css(styles.buttonGroup)}>
                  <Button
                    text={intl.formatMessage({
                      id: "done",
                      defaultMessage: "Done"
                    })}
                    onClick={this.closeModal}
                    className={[styles.doneButton]}
                  />
                  {modals.openReturnModal ? (
                    <Button
                      text={intl.formatMessage({
                        id: "return_more",
                        defaultMessage: "Return more to inventory?"
                      })}
                      onClick={this.addMoreInventory}
                    />
                  ) : (
                    <Button
                      text={intl.formatMessage({
                        id: "add_more",
                        defaultMessage: "Add more to inventory?"
                      })}
                      onClick={this.addMoreInventory}
                    />
                  )}
                </div>
              </div>
              <div className={css(styles.tableContainer)}>
                <ReactTable
                  className={"addProductTable"}
                  data={this.state.data}
                  filterable={false}
                  pages={this.state.numPages}
                  columns={columns}
                  footerClassName={css(styles.tableFooter)}
                  defaultPageSize={1000000}
                  minRows={0}
                />
              </div>
            </div>
          )}
        </div>
        {this.state.data.length > 0 && !this.state.inventoryPushed && (
          <div className={css(styles.addInventory)}>
            <Button
              text={
                !this.state.pushingInventory ? (
                  modals.addProductModal.move ? (
                    intl.formatMessage(
                      {
                        id: "move_products",
                        defaultMessage: `Move Products from ${
                          this.state.currentLocationText
                        } to ${this.state.locationText}`
                      },
                      {
                        currentLocation: this.state.currentLocationText,
                        location: this.state.locationText
                      }
                    )
                  ) : modals.openReturnModal ? (
                    intl.formatMessage({
                      id: "return_all",
                      defaultMessage: "Return All to Inventory"
                    })
                  ) : (
                    intl.formatMessage({
                      id: "push_inventory",
                      defaultMessage: "Push All to Inventory"
                    })
                  )
                ) : (
                  <FontAwesomeIcon
                    icon={["far", "spinner-third"]}
                    className={css(styles.spinner)}
                    spin
                  />
                )
              }
              onClick={this.pushToInventory}
            />
          </div>
        )}
        {barcodes}
        <ShineAlreadySoldModal
          soldItems={this.state.soldItems}
          updateProduct={this.updateProduct}
        />
        <ManualInputModal
          inventoryCreatedCallback={this.inventoryCreatedCallback}
          locationId={this.state.locationId}
          styleToPrice={this.state.styleToPrice}
          updatePriceByStyle={this.updatePriceByStyle}
          updateCostByStyle={this.updateCostByStyle}
          styleToCost={this.state.styleToCost}
          quantity={this.state.quantity}
          addInventoryOrder={this.addInventoryOrder}
          consignerId={this.state.consignerId}
        />
      </BaseModal>
    );
  }

  /***
   * Updates the price by style dict
   * @params string -- price of the shoe
   * @params style -- the style of the shoe
   */
  updatePriceByStyle = (price, style) => {
    let styleToPrice = { ...this.state.styleToPrice };
    styleToPrice[style] = price;
    this.setState({
      styleToPrice
    });
  };

  /***
   * Updates the cost by style dict
   * @params string -- price of the shoe
   * @params style -- the style of the shoe
   */
  updateCostByStyle = (cost, style) => {
    let styleToCost = { ...this.state.styleToCost };
    styleToCost[style] = cost;
    this.setState({
      styleToCost
    });
  };
}

var styles = StyleSheet.create({
  productModal: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    marginBottom: 30
  },
  bodyContainer: {
    maxHeight: "unset",
    height: "100%"
  },
  modal: {
    width: "100%",
    height: "100%",
    maxWidth: "unset"
    // transition: '.5s ease-in-out',
  },
  chooseStoreBody: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  allLocations: {
    width: "50%",
    maxWidth: 700,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  location: {
    width: "45%",
    border: "1px solid",
    borderRadius: 4,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 18,
    marginBottom: 32,
    cursor: "pointer",

    ":hover": {
      background: colors.RED(1),
      borderColor: colors.RED(1),
      color: "#fff"
    }
  },
  separate: {
    marginLeft: "auto"
  },
  reprint: {
    background: colors.RED(1),
    height: 80,
    // width: 80,
    cursor: "pointer",
    fontSize: 18,
    borderRadius: 4,
    border: "none",
    color: "#fff"
  },
  img: {
    width: 80,
    height: 80,
    objectFit: "contain"
  },
  moveColor: {
    background: colors.LIGHT_BLUE(1),
    borderColor: colors.LIGHT_BLUE(1),

    ":hover": {
      background: colors.LIGHT_BLUE(0.8)
    }
  },
  shineSoldColor: {
    background: "#fff",
    borderColor: colors.LIGHT_BLUE(1),
    color: colors.LIGHT_BLUE(1),
    marginLeft: 10,

    ":hover": {
      background: colors.LIGHT_BLUE(0.8),
      borderColor: colors.LIGHT_BLUE(0.8),
      color: "#fff"
    }
  },
  times: {
    position: "absolute",
    right: 32,
    top: 32,
    fontSize: "2.5em",
    cursor: "pointer"
  },
  check: {
    color: "green"
  },
  timesRow: {
    fontSize: "1.5em",
    cursor: "pointer",
    boxSizing: "content-box",
    padding: 16,
    color: "#000"
  },
  moveTitle: {
    textAlign: "center",
    letterSpacing: 0.7
  },
  longArrow: {
    marginLeft: 16
  },
  cell: {
    padding: 0,
    borderColor: "#eee",
    borderBottom: "solid 1px #eee",
    maxHeight: 100
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 8
  },
  buttonContainer: {
    // boxShadow: 'rgba(129, 148, 167, 0.39) 0px 3px 10px 0px',
  },
  printAllBarcodes: {
    width: 200,
    margin: "0 auto",
    marginTop: 32,
    display: "flex"
  },
  buttonGroup: {
    display: "flex",
    width: "70%",
    margin: "0 auto"
  },
  doneButton: {
    marginRight: 16,
    background: "#fff",
    color: colors.RED(1),

    ":hover": {
      background: "rgba(245, 245, 245, .7)"
    }
  },
  cellValue: {
    textAlign: "center",
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  tableContainer: {
    marginTop: 32
  },
  title: {
    letterSpacing: 0.7,
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  spinner: {
    color: "#fff"
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  tableFooter: {
    display: "none"
  },
  marginLeft: {
    marginLeft: 16
  },
  quantityInput: {
    width: 100
  },
  addInventory: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0
  },
  doubleClickInput: {
    width: "100%",
    height: "100%",
    border: "none",
    textAlign: "center",
    fontSize: 16,
    padding: "auto"
  },
  input: {
    width: 350,
    // boxShadow: 'rgba(129, 148, 167, 0.39) 0px 3px 10px 0px',
    borderRadius: 4,
    height: 52,
    outline: "none",
    padding: 8,
    fontSize: 16,
    boxSizing: "border-box",
    border: "1px solid #ddd",
    background: "rgb(245, 245, 245)",

    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.LIGHT_BLUE(1)}`
    }
  },
  inventoryPushed: {
    textAlign: "center",
    letterSpacing: 0.7
  },
  inventoryMoved: {
    fontSize: 24
  },
  inventoryBody: {
    marginTop: 16
  },
  locationSelector: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // margin: '16px 0px',
    width: 200,
    marginLeft: 16
  },
  manual: {
    color: colors.RED(1),
    background: "#fff",

    ":hover": {
      background: colors.RED(0.8),
      color: "#fff"
    }
  },
  printButtonContainer: {
    width: 400
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  modals: state.modals,
  inventory: state.inventory
});

const mapDispatchToProps = dispatch => ({
  // authActions: bindActionCreators(AuthActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddProductModal)
);
