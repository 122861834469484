import locales from "../config/locales";
// import API from '../config/api';
// import Helpers from './helpers';

/***
 * Action / Reducer for localization
 */

/**********************************
 *        CONSTANTS SECTION       *
 **********************************/

const LocalizationConstants = {
  SET_LOCALE: "@@localization/SET_LOCALE",
  PERSIST_LANGUAGE: "@@localization/PERSIST_LANGUAGE"
};

/**********************************
 *        ACTIONS SECTION         *
 **********************************/

export const localeToLanguage = {
  "en-US": "en",
  "zh-CN": "zh"
};

export const languageToReadable = {
  en: "English",
  zh: "中文"
};

export const languageToLocale = {
  en: "en-US",
  zh: "zh-CN"
};

function saveToLocalStorage(key, value) {
  let storage = window.localStorage;
  storage.setItem(key, value);
  return;
}

const setLocale = (locale, dispatch) => {
  const language = localeToLanguage[locale];
  // Just return english if we cant find the locale somehow
  if (!language) {
    return dispatch({
      type: LocalizationConstants.SET_LOCALE,
      locale: "en-US",
      messages: locales["en-US"],
      language: "en"
    });
  }
  return dispatch({
    type: LocalizationConstants.SET_LOCALE,
    locale,
    messages: locales[locale],
    language: localeToLanguage[locale]
  });
};

export const LocalizationActions = {
  setLocaleByLanguage: (language, selectedOption) => {
    return dispatch => {
      saveToLocalStorage("solestage.language", language);
      dispatch({
        type: LocalizationConstants.SET_LOCALE,
        selectedOption: selectedOption
      });
      return setLocale(languageToLocale[language], dispatch);
    };
  },
  initializeLocale: () => {
    return dispatch => {
      let language = window.localStorage["solestage.language"];
      if (language === "zh") {
        dispatch({
          type: LocalizationConstants.SET_LOCALE,
          selectedOption: { value: "zh", label: "中文" }
        });
      }
      return setLocale(languageToLocale[language], dispatch);
    };
  },
  setLocale
};

/*********************************
 *        REDUCER SECTION         *
 **********************************/

const initialState = {
  locale: "en-US",
  messages: locales["en-US"],
  language: "en",
  selectedOption: { value: "en", label: "English" },
  locales: [
    {
      display: "简体中文 (zh)",
      small: "ZH - 简体中文",
      value: "zh-CN"
    },
    {
      display: "English (en)",
      small: "EN - English",
      value: "en-US"
    }
  ]
};

const localizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LocalizationConstants.SET_LOCALE: {
      return {
        ...state,
        ...action
      };
    }
    default:
      return state;
  }
};

export default localizationReducer;
