/***
 * Orders screen for Solestage
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { FormattedMessage, injectIntl } from "react-intl";
import TimeAgo from "javascript-time-ago";
import numeral from "numeral";
import { Link } from "react-router-dom";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

// Components
import SpecificOrder from "./SpecificOrder";
import Dropdown from "../../components/inventory/Dropdown";
import Searchbar from "../../components/inventory/Searchbar";
import Checkbox from "./Checkbox";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Redux
import { MessageActions } from "../../redux/message";
import { ModalActions } from "../../redux/modals";
import { InventoryActions } from "../../redux/inventory";

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// Create relative date/time formatter.
// const timeAgo = new TimeAgo("en-US");

class Orders extends React.Component {
  constructor(props) {
    super(props);

    let { locationId } = this.props;
    this.state = {
      data: [],
      selectedFulfillmentId: null,
      numPages: 1,
      page: 1,
      pageSize: 20,
      search: "",
      hoveredRow: null,
      location: locationId,
      orderCounts: {},
      orderChecked: {}
    };
  }

  componentDidMount() {
    this.getOrderCounts();
    this.getOrders({ location: this.state.location });
  }

  getOrderCounts = () => {
    return fetch(
      API.ORDER_PAGE_COUNTS({ location: this.state.location }),
      API.GET_CONFIG(false)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          orderCounts: res
        });
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.match.params.orderStatus !== this.props.match.params.orderStatus
    ) {
      this.getOrders({ location: this.state.location });
      this.getOrderCounts();
    }
  }

  getOrders = ({ location, page, pageSize, search }) => {
    if (!page) {
      page = 1;
    }

    if (!pageSize) {
      pageSize = 20;
    }

    let { messageActions } = this.props;
    location = Number(location);
    messageActions.showMessage({ show: true, load: true });
    let SOLESTAGE_WAREHOUSE_LOCATION_ID = 4;
    let SOLESTAGE_ONLINE_LOCATION_ID = 7;
    if (location === SOLESTAGE_WAREHOUSE_LOCATION_ID) {
      location += `,${SOLESTAGE_ONLINE_LOCATION_ID}`;
    }

    let fulfillmentStatus = this.props.match.params.orderStatus;

    return fetch(
      API.ORDERS({
        inventory__location: location !== 0 ? location : null,
        order__store_transaction: false,
        page,
        search,
        fulfillment__status: search ? "" : fulfillmentStatus,
        size: pageSize
      }),
      API.GET_CONFIG(false)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = res.results.map((order, index) => {
          let orderId = order.order.id;
          if (order.order.shopify_order_number) {
            orderId = order.order.shopify_order_number;
          } else if (order.order.clover_order_id) {
            orderId = order.order.clover_order_id;
          }
          // TODO: update the date here
          let fulfillmentStatus =
            order.fulfillment.status &&
            order.fulfillment.status
              .split("_")
              .map(string => string.charAt(0).toUpperCase() + string.slice(1))
              .join(" ");
          return {
            Order: `#${order.order.id}`,
            order_id: order.id,
            true_order_id: order.order.id,
            fulfillment_id: order.fulfillment.id,
            "Order ID": `#${orderId}`,
            Channel: order.order.sales_channel || order.order.platform,
            Date: new Date(order.created_at).toLocaleDateString("en-US"),
            Customer: order.order
              ? order.order.shipping_address
                ? [
                    order.order.shipping_address.state,
                    order.order.shipping_address.country
                  ]
                    .filter(el => el !== "")
                    .join(", ")
                : "Online"
              : "-",
            "Payment Status": order.order
              ? order.order.payment
                ? order.order.payment.status
                : "Not Paid"
              : "-",
            "Fulfillment Status": fulfillmentStatus,
            Total: numeral(order.total).format(
              order.total >= 1000 ? "($0.0 a)" : "($0.00)"
            )
          };
        });
        messageActions.showMessage({ show: false });
        this.setState({
          data,
          totalItems: res.count,
          numPages: Math.ceil(res.count / this.state.pageSize)
        });
      });
  };

  renderCell = (header, props, index) => {
    let { intl } = this.props;
    switch (header) {
      case "Fulfillment Status":
        let fulfilled = props.value === "Fulfilled";
        if (fulfilled) {
          return (
            <div className={css(styles.cellValue, styles.fulfilled)}>
              {intl.formatMessage({
                id: "fulfilled",
                defaultMessage: "Fulfilled"
              })}
            </div>
          );
        } else {
          return (
            <div className={css(styles.cellValue, styles.unfulfilled)}>
              {intl.formatMessage({
                id: "unfulfilled",
                defaultMessage: props.value
              })}
            </div>
          );
        }
      default:
        return (
          <div
            className={css(
              styles.cellValue,
              header === "Order" && styles.orderNumber
            )}
          >
            {props.row[index] ? props.row[index].value : props.value}
          </div>
        );
    }
  };

  /**
   * mark order as fulfilled
   */
  markAsFulfilled = () => {
    let data = [...this.state.data];
    data[this.state.selectedIndex]["Fulfillment Status"] = "Fulfilled";
    this.setState({
      data
    });
  };

  /**
   * update current table data
   */
  updateTableData = () => {
    let data = [...this.state.data];
    data.splice(this.state.selectedIndex, 1);
    this.setState({
      data
    });
  };

  /**
   * Search changes
   */
  searchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  /**
   * Searches the backend for order by Shopify Order Number
   */
  search = async e => {
    if (e.key === "Enter") {
      this.setState({
        page: 1
      });
      this.getOrders({
        location: this.state.location,
        search: this.state.search
      });
    }
  };

  /**
   * open specifc order modal
   */
  openSpecificOrderModal = (orderId, index, fulfillmentId) => {
    let { modalActions } = this.props;

    modalActions.openSpecificOrderModal(true);
    this.setState({
      selectedOrder: orderId,
      selectedIndex: index,
      selectedFulfillmentId: fulfillmentId
    });
  };

  /**
   * on change of location selector
   * @param  {Event} e -- on change event for selector
   */
  onLocationChange = e => {
    let value = parseInt(e.target.value);
    this.setState(
      {
        location: value,
        page: 1
      },
      () => {
        this.props.inventoryActions.setLocation(parseInt(value));
        this.getOrders({ location: value });
        this.getOrderCounts();
      }
    );
  };

  /**
   * Exports the selected orders
   */
  exportOrders = () => {
    let location = this.state.location;
    let inventoryOrderIds = [];
    for (let i = 0; i < this.state.data.length; i++) {
      if (this.state.orderChecked[this.state.data[i].order_id]) {
        inventoryOrderIds.push(this.state.data[i].true_order_id);
      }
    }
    let params = {
      ids: inventoryOrderIds,
      location: location !== 0 ? location : null
    };

    let rest = {
      route: "download_shopify_orders"
    };
    this.setState({
      exportingInventory: true
    });
    return fetch(API.EXPORT_ORDERS({ rest }), API.POST_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        this.setState({
          exportingInventory: false,
          orderChecked: {}
        });
        let link = document.createElementNS(
          "http://www.w3.org/1999/xhtml",
          "a"
        );
        link.href = URL.createObjectURL(res);
        link.download = `orders-${moment().format()}.csv`;
        link.click();
      });
  };

  /**
   * Checks an order so it can be exported
   * @param { Event } e -- on change event for checkbox
   * @param { Integer } id -- the order id being checked
   */
  handleCheckboxChange = (e, id) => {
    let orderChecked = { ...this.state.orderChecked };
    orderChecked[id] = e.target.checked;
    this.setState({
      orderChecked
    });
  };

  toggleSelectAll = () => {
    if (this.allSelected) {
      this.selectNone();
    } else {
      this.selectAll();
    }
  };

  selectAll = () => {
    const orderChecked = {};
    _.each(this.state.data, item => (orderChecked[item.order_id] = true));
    this.setState({ orderChecked });
  };

  selectNone = () => {
    this.setState({ orderChecked: {} });
  };

  get allSelected() {
    return _.every(
      this.state.data,
      item => this.state.orderChecked[item.order_id]
    );
  }

  render() {
    let { intl } = this.props;
    let columns = [
      "Select",
      "Order",
      "Order ID",
      "Channel",
      "Date",
      "Customer",
      "Payment Status",
      "Fulfillment Status",
      "Total"
    ].map((column, index) => ({
      Header:
        column === "Select" ? (
          <div className={css(styles.checkbox)}>
            <Checkbox checked={this.allSelected} value="" />
          </div>
        ) : (
          column
        ),
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      filterable: false,
      minWidth: column === "table-id" ? 50 : 165,
      getHeaderProps: () => {
        const onClick = this.toggleSelectAll;
        return column === "Select" ? { onClick } : {};
      },
      Cell: props => (
        <div
          className={css(
            styles.valueContainer,
            props.row._index === this.state.hoveredRow && styles.hoveredRow
          )}
          onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
          onClick={() =>
            column !== "Select" &&
            this.openSpecificOrderModal(
              props.original.order_id,
              props.row._index,
              props.original.fulfillment_id
            )
          }
        >
          {column === "Select" ? (
            <label className={css(styles.checkbox)}>
              <Checkbox
                checked={this.state.orderChecked[props.original.order_id]}
                onChange={e =>
                  this.handleCheckboxChange(e, props.original.order_id)
                }
                value=""
              />
            </label>
          ) : (
            this.renderCell(props.column.Header, props, index)
          )}
        </div>
      )
    }));

    return (
      <div className={css(styles.orders)}>
        <h2 className={css(styles.dashboardTitle)}>
          <FormattedMessage id="order_title" defaultMessage="Orders" />
        </h2>
        <div className={css(styles.dropdown)}>
          <span className={css(styles.location)}>
            {intl.formatMessage({
              id: "inventory_location",
              defaultMessage: "Location"
            })}
            :
          </span>
          <Dropdown
            onChange={this.onLocationChange}
            default={this.state.location}
            removeAll={true}
          />
        </div>
        <div className={css(styles.tabs)}>
          <Link
            to={"/orders/unfulfilled"}
            className={css(
              styles.tab,
              this.props.match.params.orderStatus === "unfulfilled" &&
                styles.active
            )}
          >
            Unfulfilled orders{" "}
            {this.state.orderCounts.unfulfilled !== undefined &&
              `(${this.state.orderCounts.unfulfilled})`}
          </Link>
          <Link
            to={"/orders/awaiting_shipment"}
            className={css(
              styles.tab,
              this.props.match.params.orderStatus === "awaiting_shipment" &&
                styles.active
            )}
          >
            Awaiting Shipment{" "}
            {this.state.orderCounts.awaiting_shipment !== undefined &&
              `(${this.state.orderCounts.awaiting_shipment})`}
          </Link>
          <Link
            to={"/orders/fulfilled"}
            className={css(
              styles.tab,
              this.props.match.params.orderStatus === "fulfilled" &&
                styles.active
            )}
          >
            Fulfilled{" "}
            {this.state.orderCounts.fulfilled !== undefined &&
              `(${this.state.orderCounts.fulfilled})`}
          </Link>
          <Link
            to={"/orders/refund"}
            className={css(
              styles.tab,
              this.props.match.params.orderStatus === "refund" && styles.active
            )}
          >
            Refund / Cancelled{" "}
            {this.state.orderCounts.refund !== undefined &&
              `(${this.state.orderCounts.refund})`}
          </Link>
        </div>
        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <div className={css(styles.topBar)}>
            <div className={css(styles.search)}>
              <Searchbar
                search={this.search}
                searchChange={this.searchChange}
              />
            </div>
            <div className={css(styles.utilities)}>
              <button
                id="export"
                className={css(styles.export)}
                onClick={this.exportOrders}
              >
                <FontAwesomeIcon
                  icon={["fas", "file-export"]}
                  className={css(styles.fileIcon)}
                />
                {this.state.exportingInventory ? (
                  <FontAwesomeIcon
                    icon={["far", "spinner-third"]}
                    className={css(styles.fileIcon)}
                    spin
                  />
                ) : (
                  <FormattedMessage
                    id="export_orders"
                    defaultMessage="Export Orders"
                  />
                )}
              </button>
            </div>
          </div>
          <ReactTable
            className={css(styles.reactTable) + " order-table"}
            data={this.state.data}
            filterable={true}
            pages={this.state.numPages}
            columns={columns}
            footerClassName={css(styles.tableFooter)}
            defaultPageSize={this.state.pageSize}
            page={this.state.page - 1}
            onPageSizeChange={pageSize => {
              this.setState({
                pageSize,
                numPages: Math.ceil(this.state.totalItems / pageSize)
              });
            }}
            onPageChange={pageIndex => {
              this.setState({
                page: pageIndex + 1
              });
            }}
            minRows={0}
            onFetchData={(state, instance) => {
              this.getOrders({
                location: this.state.location,
                page: state.page + 1,
                pageSize: state.pageSize,
                filters: state.filtered
              });
            }}
            nextText={intl.formatMessage({
              id: "next",
              defaultMessage: "Next"
            })}
            previousText={intl.formatMessage({
              id: "previous",
              defaultMessage: "Previous"
            })}
            noDataText={intl.formatMessage({
              id: "no_items_found",
              defaultMessage: "No items found"
            })}
            manual
          />
        </div>
        <SpecificOrder
          orderId={this.state.selectedOrder}
          selectedFulfillmentId={this.state.selectedFulfillmentId}
          markAsFulfilled={this.markAsFulfilled}
          location={this.state.location}
          updateTableData={this.updateTableData}
        />
      </div>
    );
  }
}

var styles = StyleSheet.create({
  orders: {
    textAlign: "center",
    padding: 20,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  topBar: {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    justifyContent: "flex-start"
  },
  search: {
    width: "60%"
  },
  table: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
    color: "#212b36"
  },
  orderNumber: {
    color: colors.LIGHT_BLUE(1)
  },
  hoveredRow: {
    background: "#eee"
  },
  link: {
    textDecoration: "none"
  },
  unfulfilled: {
    background: "#ffea8a",
    padding: "8px 8px",
    borderRadius: 24
  },
  fulfilled: {
    padding: "8px 8px",
    borderRadius: 24,
    background: "#dfe3e8"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  dropdown: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
    alignItems: "center"
  },
  location: {
    fontSize: 20,
    fontWeight: "bold",
    marginRight: 5
  },
  tab: {
    color: "#111",
    fontSize: 16,
    padding: 16,
    textDecoration: "none",
    borderRight: "1px solid #ddd"
  },
  active: {
    background: colors.RED(1),
    color: "#fff"
  },
  tabs: {
    marginBottom: 16,
    background: "#fff",
    display: "inline-flex",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  checkbox: {
    padding: 16
  },
  utilities: {
    display: "flex",
    alignItems: "center",
    width: "60%",
    justifyContent: "flex-end",

    "@media only screen and (max-width: 1440px)": {
      flexWrap: "wrap"
    }
  },
  export: {
    display: "flex",
    alignItems: "center",
    height: 36,
    borderRadius: 4,
    padding: 8,
    border: "1px solid #ddd",
    background: "rgb(245, 245, 245)",
    cursor: "pointer",
    outline: "none",
    fontSize: 14,
    letterSpacing: 0.7,

    ":hover": {
      transform: "scale(1.05)",
      "-webkit-transition": ".5s",
      transition: ".5s"
    },

    "@media only screen and (max-width: 1440px)": {
      width: 170,
      marginBottom: 12
    }
  },
  fileIcon: {
    marginRight: 8
  }
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  messageActions: bindActionCreators(MessageActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch),
  inventoryActions: bindActionCreators(InventoryActions, dispatch)
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Orders));
