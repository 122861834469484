/***
 * The login form. This is only the form element for login.
 * @patr + @craiglu
 */

import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { injectIntl, FormattedMessage } from "react-intl";

// Components
import Input from "../Input";
import Button from "../Button";
import ErrorBanner from "../ErrorBanner";

// Configs
import colors from "../../config/colors";

const LOGIN_DELAY = 10;

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    let { intl } = props;
    this.INPUTS = [
      {
        id: "username",
        placeholder: intl.formatMessage({
          id: "email_address",
          defaultMessage: "Email Address"
        }),
        ref: this.email
      },
      {
        id: "password",
        placeholder: intl.formatMessage({
          id: "password",
          defaultMessage: "Password"
        }),
        ref: this.password
      }
    ];

    this.state = {
      loggingIn: false,
      errorMessage: null
    };
  }

  /***
   * Submits the login form to the backend
   */
  submit = e => {
    e.preventDefault();
    this.setState({
      loggingIn: true,
      errorMessage: null
    });
    var params = {};
    for (var i = 0; i < this.INPUTS.length; i++) {
      params[this.INPUTS[i].id] = this.INPUTS[i].ref.inputRef.value;
    }

    this.props.login(params).then(user => {
      if (user.error) {
        this.setState({
          loggingIn: false,
          errorMessage: user.error.non_field_errors[0]
        });
        return null;
      }
      if (user) {
        this.props.getProfile(true).then(profile => {
          if (!profile.isLoggedIn) {
            this.setState({
              loggingIn: false,
              errorMessage: "Permission Denied"
            });
            return null;
          }
          if (profile.userProfile && profile.userProfile.first_login) {
            this.props.history.push("/login/first");
          } else {
            this.props.history.push("/dashboard");
          }
        });
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    let { auth } = this.props;
    clearTimeout(this.loginTimeout);
    clearTimeout(this.errorTimeout);

    // TODO check this error
    if (!auth) {
      return;
    }

    // Make sure the login takes at least LOGIN_DELAY ms
    if (
      auth.isFetchingLogin !== nextProps.auth.isFetchingLogin &&
      !nextProps.auth.isFetchingLogin
    ) {
      this.loginTimeout = setTimeout(() => {
        this.setState({
          loggingIn: nextProps.auth.isFetchingRegister
        });
      }, LOGIN_DELAY);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.loginTimeout);
    clearTimeout(this.errorTimeout);
  }

  /***
   * Set the error messsage for signup
   * @param error dict -- the error message from sending signup form
   */
  setError(error) {
    if (error) {
      var errorMessage = error[0];
      this.setState({
        errorMessage: errorMessage
      });
    }
  }

  render() {
    var inputs = this.INPUTS.map((input, index) => {
      return (
        <div
          className={css(styles.inputContainer)}
          key={`${input.id}_${index}`}
        >
          <Input
            name={input.id}
            ref={ref => (input.ref = ref)}
            type={input.id}
            autoComplete={input.id}
            placeholder={input.placeholder}
            autoFocus={index === 0}
            className={styles.input}
            required
          />
        </div>
      );
    });

    return (
      <form className={css(styles.login)} onSubmit={this.submit}>
        {this.state.errorMessage ? (
          <div className={css(styles.errorContainer)}>
            <ErrorBanner error={this.state.errorMessage} />
          </div>
        ) : null}
        {inputs}
        <Button
          button_color={styles.buttonColor}
          text={
            !this.state.loggingIn ? (
              <FormattedMessage id="login" defaultMessage={"Login"} />
            ) : (
              <FontAwesomeIcon
                spin
                icon={["far", "spinner-third"]}
                className={css(styles.icon)}
              />
            )
          }
        />
      </form>
    );
  }
}

var styles = StyleSheet.create({
  login: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: "100%",

    margin: "0 auto",

    "@media only screen and (min-width: 320px)": {
      width: "100%"
    },

    "@media only screen and (min-width: 768px)": {
      width: "440px"
    }
  },
  input: {
    padding: 15
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "15px"
  },
  inputLabel: {
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "16px",
    color: "#fff",
    marginBottom: "5px"
  },
  errorContainer: {
    width: "100%",
    marginBottom: "10px",
    fontFamily: "'Open Sans', sans-serif"
  },
  buttonColor: {
    background: `${colors.RED(1)}`
  }
});

export default injectIntl(LoginForm);
