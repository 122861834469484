import React, { Component } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
// import { bindActionCreators } from 'redux';
import numeral from "numeral";
import { FormattedMessage, injectIntl } from "react-intl";

class ProductCard extends Component {
  render() {
    let priceInCents = 0;
    if (this.props.item.price) {
      priceInCents = this.props.item.price.default_price;
    } else if (
      this.props.item.consignment &&
      this.props.item.consignment.price
    ) {
      priceInCents = this.props.item.consignment.price.default_price;
    }
    // let priceInCents = (this.props.item.price && this.props.item.price.default_price) || this.props.item.consignment.price.default_price;
    return (
      <div className={css(styles.productCard)}>
        <div className={css(styles.productName)}>
          {this.props.item.sku.product_name_us}
        </div>
        {this.props.item.pictures[0] && (
          <img
            className={css(styles.image)}
            src={this.props.item.pictures[0] && this.props.item.pictures[0].url}
            alt={this.props.item.sku.product_name_us}
          />
        )}
        <div className={css(styles.statContainer)}>
          <div className={css(styles.revenue)}>
            {numeral(priceInCents / 100).format("$0,0.00")}
          </div>
          <div className={css(styles.sold)}>
            <FormattedMessage
              id="sold_units"
              defaultMessage={`sold {count} units {count, number}}`}
              values={{ count: this.props.count }}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  productCard: {
    width: 170,
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  statContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  image: {
    width: "100%",
    marginBottom: 15
  },
  sold: {
    opacity: 0.5
  },
  revenue: {
    color: "#afa100"
  },
  productName: {
    textAlign: "center"
  }
});

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ProductCard));
