/***
 * Link to all accepted consignment orders for this location
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";
import * as Sentry from "@sentry/browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import isAfterDay from "react-dates/lib/utils/isAfterDay";
import ReactTable from "react-table";
import queryString from "query-string";

// Components
import Input from "../../components/Input";
import Dropdown from "../../components/inventory/Dropdown";
import UsedDropdown from "../../components/consignment/UsedDropdown";
import SizeDropdown from "../../components/consignment/SizeDropdown";
import CurrencyDisplay from "../../components/CurrencyDisplay";

// Redux
import { ModalActions } from "../../redux/modals";
import { JoyrideActions } from "../../redux/joyride";
import { MessageActions } from "../../redux/message";
import Actions from "../../redux/inventory/actions";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import colors from "../../config/colors";
import IMAGECOMING from "../../assets/imagecoming.jpg";
import * as InventoryHelper from "./InventoryHelper";

import "react-placeholder/lib/reactPlaceholder.css";

const SEARCH_TIMEOUT = 700;

class Inventory extends React.Component {
  constructor(props) {
    super(props);

    let { auth, intl } = this.props;

    this.tabs = [
      {
        id: "all",
        label: intl.formatMessage({
          id: "all_consignment_inventory",
          defaultMessage: "All"
        })
      },
      {
        id: "listed",
        label: intl.formatMessage({
          id: "listed_consignment_inventory",
          defaultMessage: "Listed"
        })
      },
      {
        id: "ordered",
        label: intl.formatMessage({
          id: "consignment_ordered",
          defaultMessage: "Ordered"
        })
      },
      {
        id: "sold",
        label: intl.formatMessage({
          id: "consignment_sold",
          defaultMessage: "Sold"
        })
      }
    ];

    if (
      auth.userProfile.role &&
      ["admin", "accountant", "warehouse manager"].includes(
        auth.userProfile.role.toLowerCase()
      )
    ) {
      this.tabs.splice(1, 0, {
        id: "pending",
        label: intl.formatMessage({
          id: "consignment_pending",
          defaultMessage: "Pending"
        })
      });
    }

    if (
      auth.userProfile.role &&
      ["admin", "accountant", "warehouse manager"].includes(
        auth.userProfile.role.toLowerCase()
      )
    ) {
      this.tabs.push({
        id: "missing",
        label: intl.formatMessage({
          id: "missing",
          defaultMessage: "Missing"
        })
      });
      this.tabs.push({
        id: "deleted",
        label: intl.formatMessage({
          id: "deleted",
          defaultMessage: "Deleted"
        })
      });
    }

    this.state = {
      items: [],
      data: [],
      type: this.props.match.params.type,
      activeTab: "listed",
      loading: false,
      page: 1,
      firstLoad: true,
      error: null,
      search: "",
      selectedIndex: 0,
      selectedConditions: [],
      location: auth.userProfile.location.id,
      exportingConsignments: false,
      startDate: null,
      endDate: null,
      focusedInput: null,
      sizes: [],
      selectedSize: null,
      selectedConsigner: null,
      isUsed: null,
      hidePrice: true,
      users: [],
      selectedUser: {},
      emailSearch: null,
      openDropdown: false,
      itemChecked: {},
      pageSize: 20,
      hoveredRow: null,
      expanded: {},
      skus: {}
    };
  }

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    this.setState(
      {
        activeTab: tab,
        page: 1,
        firstLoad: true,
        items: []
      },
      () => {
        this.loadData({ search: this.state.search });
      }
    );
  };

  onTypeChange = e => {
    let value = e.target.value;
    this.setState(
      {
        type: value,
        firstLoad: true
      },
      () => {
        this.props.history.push(
          `/inventory/${value}?consigner=${
            this.state.selectedConsigner ? this.state.selectedConsigner : ""
          }&location=${this.state.location}&page=1&size&tab=${
            this.state.activeTab
          }&search=${this.state.search ? this.state.search : ""}`
        );
      }
    );
  };

  /**
   * on change of location selector
   * @param  {Event} e -- on change event for selector
   */
  onLocationChange = e => {
    let value = e.target.value;
    if (e.target.value === "0") {
      value = null;
    }

    this.setState(
      {
        location: value,
        page: 1,
        firstLoad: true
      },
      () => {
        this.loadData({ search: this.state.search });
      }
    );
  };

  onUsedChange = e => {
    let value = e.target.value;
    if (e.target.value === "All") {
      value = null;
    }

    this.setState(
      {
        isUsed: value,
        page: 1,
        firstLoad: true
      },
      () => {
        this.loadData({ search: this.state.search });
      }
    );
  };

  onSizeFilterChange = e => {
    let value = e.target.value;
    if (e.target.value === "All") {
      value = null;
    }

    this.setState(
      {
        selectedSize: value,
        page: 1,
        firstLoad: true
      },
      () => {
        this.loadData({ search: this.state.search });
      }
    );
  };

  /***
   * Loads sizes
   */
  loadSizes = async () => {
    let config = API.GET_CONFIG(false);

    let url = API.SIZES;
    return fetch(url, config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({ sizes: res });
      })
      .catch(error => {
        console.error(error);
      });
  };

  /***
   * Loads products
   */

  loadData = ({ search, id, page, pageSize, sorted }) => {
    let { showMessage } = this.props;
    if (search && search.substring(0, 3) === "ss-") {
      search = search.toLocaleLowerCase().replace("ss-", "");
    }

    showMessage({ show: true, load: true });

    if (this.state.type === "inventory") {
      this.loadInventory({ search, id, page, pageSize, sorted });
    } else if (this.state.type === "products") {
      this.loadProducts({ search, id, page, pageSize });
    }
  };

  loadInventory = async ({ search, id, page, pageSize }) => {
    if (!page) {
      page = this.state.page;
    }

    if (!pageSize) {
      pageSize = this.state.pageSize;
    }

    let { auth, intl, queryInventory, showMessage } = this.props;

    let status = this.state.activeTab;
    if (this.state.activeTab === "all") {
      if (auth.userProfile.role) {
        //warehouse manager can see pending, approved, listed, rejected, sold
        if (
          ["warehouse manager"].includes(auth.userProfile.role.toLowerCase())
        ) {
          status = "pending,approved,listed,sold";
        }
        //accountant and admin can see all
        else if (
          ["admin", "accountant"].includes(auth.userProfile.role.toLowerCase())
        ) {
          status = "pending,approved,listed,sold";
        }
        //other user can only see sold and listed
        else {
          status = "sold,listed";
        }
      }
    }

    let params = {
      location: this.state.location,
      sku__us_size: this.state.selectedSize,
      pageSize,
      page,
      id,
      consignment__isnull: "False",
      consignment__list_status: status,
      search,
      consigner: this.state.selectedConsigner
    };

    InventoryHelper.setOrderingAttribute(this.state, params);
    InventoryHelper.setIsUsedAttribute(this.state, params);
    InventoryHelper.setDeletedAttribute(this.state, params);

    const onStart = () => {
      this.setState({ loading: true });
    };

    const onSuccess = res => {
      showMessage({ show: false });
      if (this.state.firstLoad) {
        this.setState({
          firstLoad: false
        });
      }

      //only return 1 result
      if (!res.results) {
        res = { count: 1, results: [res] };
      }

      let data = res.results.map((item, index) => {
        let picture_url = null;
        if (item.pictures && item.pictures.length > 0) {
          picture_url = item.pictures[0].url;
        }

        let updated_date = null;
        if (this.state.activeTab === "deleted") {
          if (item.consignment.deleted_at) {
            updated_date = item.consignment.deleted_at;
          } else {
            updated_date = item.updated_at;
          }
        } else {
          updated_date =
            this.state.activeTab === "all"
              ? item.updated_at
              : this.state.activeTab === "sold"
              ? new Date(item.sold_at)
              : this.state.activeTab === "ordered"
              ? item.ordered_at
              : item.consignment[`${this.state.activeTab}_at`];
        }

        updated_date = new Date(updated_date).toLocaleString("en-US");

        return {
          "SS ID": `#${item.id}`,
          item_id: item.id,
          sold: item.consignment.status === "sold",
          active: item.active,
          Image: picture_url,
          "Product Name": item.sku && item.sku.product_name_us,
          Size: item.sku && item.sku.us_size,
          Style: item.sku && item.sku.style,
          Used: item.consignment.used,
          "Consignment Status": item.consignment.status,
          "Listed Date": new Date(item.consignment.listed_at).toLocaleString(
            "en-US"
          ),
          "Updated Date": updated_date,
          Consigner: `${item.consigner &&
            item.consigner.first_name} ${item.consigner &&
            item.consigner.last_name}`,
          "Consignment Order": item.consignment.order,
          "Deleted Reason": item.delete_reason,
          Price: item.consignment.price
            ? item.consignment.price.default_price / 100
            : 0
        };
      });

      this.setState(
        {
          items: id
            ? [...this.state.items, res]
            : this.state.page === 1
            ? res.results
            : [...this.state.items, ...res.results],
          data,
          itemCount: res.count,
          loading: false,
          numPages: Math.ceil(res.count / this.state.pageSize)
        },
        () => {
          let params = {
            location: this.state.location,
            page: this.state.page,
            pageSize: this.state.pageSize,
            search: this.state.search,
            tab: this.state.activeTab,
            size: this.state.selectedSize,
            isUsed: this.state.isUsed,
            consigner: this.state.selectedConsigner
          };

          this.props.history.replace({
            search: queryString.stringify(params),
            ...this.props.history
          });
        }
      );
    };

    const onFailure = error => {
      showMessage({ show: false });
      if (this.state.firstLoad) {
        this.setState({
          firstLoad: false
        });
      }

      this.setState({
        loading: false
      });

      if (
        error.message ===
        intl.formatMessage({
          id: "not_found",
          defaultMessage: "Not Found"
        })
      ) {
        this.setState({
          items: [],
          error: intl.formatMessage({
            id: "consignment_not_found",
            defaultMessage: `Consignment not found`
          })
        });
      } else if (error.response && error.response.status === 404) {
        this.setState({
          items: [],
          error: intl.formatMessage({
            id: "consignment_not_found",
            defaultMessage: "Consignment not found."
          })
        });
      } else {
        Sentry.captureException(error);
        this.setState({
          items: [],
          data: [],
          error: intl.formatMessage({
            id: "error_try_again",
            defaultMessage: "An error has occured. Please try again later."
          })
        });
      }
    };

    queryInventory({
      params,
      type: "item",
      onStart,
      onSuccess,
      onFailure
    });
  };

  loadProducts = ({ search, id, page, pageSize }) => {
    if (!page) {
      page = this.state.page;
    }

    if (!pageSize) {
      pageSize = this.state.pageSize;
    }

    let { auth, intl, queryInventory, showMessage } = this.props;

    let status = this.state.activeTab;
    if (this.state.activeTab === "deleted") {
      status = null;
    } else if (this.state.activeTab === "all") {
      if (auth.userProfile.role) {
        //warehouse manager can see pending, approved, listed, sold
        if (
          ["warehouse manager"].includes(auth.userProfile.role.toLowerCase())
        ) {
          status = "pending,approved,listed,sold";
        }
        //accountant and admin can see all
        else if (
          ["admin", "accountant"].includes(auth.userProfile.role.toLowerCase())
        ) {
          status = "pending,approved,listed,sold";
        }
        //other user can only see sold and listed
        else {
          status = "sold,listed";
        }
      }
    }

    let params = {
      page,
      search,
      size: pageSize,
      location: this.state.location,
      consigner: this.state.selectedConsigner,
      us_size: this.state.selectedSize,
      consignment_status: status,
      deleted: this.state.activeTab === "deleted"
    };

    const onStart = () => {
      this.setState({ loading: true });
    };

    const onSuccess = res => {
      showMessage({ show: false });
      if (this.state.firstLoad) {
        this.setState({
          firstLoad: false
        });
      }
      let data = res.results.map((item, index) => {
        let picture_url = null;
        if (item.pictures && item.pictures.length > 0) {
          picture_url = item.pictures[0].url;
        }

        return {
          Image: picture_url,
          "Product Name": item.product_name_us,
          Style: item.style,
          Type: item.item_type,
          Quantity: item.stock_quantity
        };
      });
      let sku_info = res.results.map((item, index) => {
        return item.sku_info_by_us_size;
      });
      this.setState(
        {
          items: id
            ? page === 1
              ? [res]
              : [...this.state.items, res]
            : page === 1
            ? res.results
            : [...this.state.items, ...res.results],
          data,
          itemCount: res.count,
          loading: false,
          numPages: Math.ceil(res.count / this.state.pageSize),
          expanded: data.map(x => false),
          sku_info
        },
        () => {
          let params = {
            location: this.state.location,
            page: this.state.page,
            pageSize: this.state.pageSize,
            search: this.state.search,
            tab: this.state.activeTab,
            size: this.state.selectedSize,
            consigner: this.state.selectedConsigner
          };
          this.props.history.replace({
            search: queryString.stringify(params),
            ...this.props.history
          });
        }
      );
    };

    const onFailure = error => {
      showMessage({ show: false });
      if (this.state.firstLoad) {
        this.setState({
          firstLoad: false
        });
      }

      this.setState({
        loading: false
      });

      if (
        error.message ===
        intl.formatMessage({
          id: "not_found",
          defaultMessage: "Not Found"
        })
      ) {
        this.setState({
          items: [],
          error: intl.formatMessage({
            id: "consignment_not_found",
            defaultMessage: `Consignment not found`
          })
        });
      } else if (error.response && error.response.status === 404) {
        this.setState({
          items: [],
          error: intl.formatMessage({
            id: "consignment_not_found",
            defaultMessage: "Consignment not found."
          })
        });
      } else {
        Sentry.captureException(error);
        this.setState({
          items: [],
          data: [],
          error: intl.formatMessage({
            id: "error_try_again",
            defaultMessage: "An error has occured. Please try again later."
          })
        });
      }
    };

    queryInventory({
      params,
      type: "style",
      onStart,
      onSuccess,
      onFailure
    });
  };

  editByStyle = (e, style) => {
    this.props.history.push(
      `products/${style}?consigner=${
        this.state.selectedConsigner ? this.state.selectedConsigner : ""
      }&location=${this.state.location}&page=1&size&tab=${this.state.activeTab}`
    );
  };

  renderSubComponent = row => {
    let { auth } = this.props;

    let style = row.original.Style;
    let columns = ["Size", "Price", "Quantity"].map((column, index) => ({
      Header: column,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(styles.cellHeader),
      filterable: false,
      minWidth: 30,
      Cell: props =>
        props.column.Header === "Price" ? (
          <div className={css(styles.subValueContainer)}>
            <CurrencyDisplay price={props.value} />
          </div>
        ) : (
          <div className={css(styles.subValueContainer)}>
            <div className={css(styles.subCellValue)}>{props.value}</div>
          </div>
        )
    }));
    let data = [];
    if (this.state.sku_info[row.viewIndex]) {
      Object.keys(this.state.sku_info[row.viewIndex]).forEach(size => {
        let size_data = [];
        if (
          Object.keys(this.state.sku_info[row.viewIndex][size]).length === 0
        ) {
          size_data = [
            {
              Size: size,
              Price: null,
              Quantity: 0
            }
          ];
        } else {
          size_data = Object.keys(this.state.sku_info[row.viewIndex][size]).map(
            (key, index) => {
              return {
                Size: size,
                Price: parseInt(key) / 100,
                Quantity: this.state.sku_info[row.viewIndex][size][key]
              };
            }
          );
        }
        data = [...data, ...size_data];
      });
    }

    let sizeOrder = ["O/S", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "N/A"];

    let sortedData =
      row.original.Type === "shoe"
        ? data.sort((a, b) => parseFloat(a.Size) - parseFloat(b.Size))
        : data.sort(
            (a, b) => sizeOrder.indexOf(a.Size) - sizeOrder.indexOf(b.Size)
          );

    return (
      <div className={css(styles.subComponent)}>
        {sortedData.length > 0 ? (
          <div className={css(styles.subTable)}>
            <div className={css(styles.subTableTitle)}>
              <h3>All Skus</h3>
              {auth.userProfile.role &&
                ["admin", "accountant", "warehouse manager"].includes(
                  auth.userProfile.role.toLowerCase()
                ) && (
                  <button
                    className={css(styles.editButton)}
                    onClick={e => this.editByStyle(e, style)}
                  >
                    {" "}
                    Edit{" "}
                  </button>
                )}
            </div>

            <ReactTable
              className={css(styles.reactTable) + "inventory-table"}
              data={sortedData}
              filterable={false}
              columns={columns}
              showPagination={false}
              defaultPageSize={this.state.pageSize}
              minRows={0}
              // onFetchData={(state, instance) => {
              //   console.log("table fetch");
              //   this.loadSkus({});
              // }}
              noDataText={this.props.intl.formatMessage({
                id: "no_items_found",
                defaultMessage: "No items found"
              })}
              manual
            />
          </div>
        ) : (
          <div>No Skus Found</div>
        )}
      </div>
    );
  };

  /***
   * Search for a consigner
   */
  searchConsigner = e => {
    let searchValue = e.target.value;

    this.setState(
      {
        firstLoad: true,
        search: searchValue,
        page: 1
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.loadData({ search: searchValue });
        }, SEARCH_TIMEOUT);
      }
    );
  };

  /***
   * Removes consignment item from list after printing
   */
  removeConsignment = inventory_id => {
    if (this.state.activeTab === "listed") {
      return null;
    }
    let items = [...this.state.items];
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === inventory_id) {
        items.splice(i, 1);
        break;
      }
    }

    this.setState({
      items
    });
  };

  componentDidMount = () => {
    if (this.props.location.search && this.props.location.search !== "") {
      let params = queryString.parse(this.props.location.search);
      if (params.consigner) {
        fetch(
          API.USER_ACTIONS + `?id=${params.consigner}&page=1&size=5`,
          API.GET_CONFIG()
        )
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON)
          .then(res => {
            this.setState({ selectedUser: res.results[0] });
          });
      }

      this.setState(
        {
          search: params.search,
          type: this.props.match.params.type,
          page: params.page,
          location: parseInt(params.location),
          activeTab: params.tab,
          pageSize: params.pageSize,
          selectedSize: params.size,
          selectedConsigner: params.consigner
        },
        () => {
          this.loadData({
            search: this.state.search,
            page: this.state.page,
            sorted: this.state.sorted,
            pageSize: this.state.pageSize
          });
        }
      );
    } else {
      this.setState(
        {
          type: this.props.match.params.type,
          search: null
        },
        () => {
          this.loadData({});
        }
      );
    }

    this.loadSizes();

    if (!this.joyride && this.props.joyride.forceJoy) {
      let { joyrideActions } = this.props;
      this.joyride = true;
      joyrideActions.setRun({ run: true, inProgress: true });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.type !== this.props.match.params.type) {
      let params = queryString.parse(this.props.location.search);
      if (params.consigner) {
        fetch(
          API.USER_ACTIONS + `?id=${params.consigner}&page=1&size=5`,
          API.GET_CONFIG()
        )
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON)
          .then(res => {
            this.setState({ selectedUser: res.results[0] });
          });
      }

      this.setState(
        {
          search: params.search,
          type: this.props.match.params.type,
          page: 1,
          pageSize: params.pageSize,
          location: parseInt(params.location),
          activeTab: params.tab,
          selectedSize: params.size,
          selectedConsigner: params.consigner
        },
        () => {
          this.loadData({
            search: this.state.search,
            page: this.state.page,
            pageSize: this.state.pageSize
          });
        }
      );
    }
  }

  renderCell = (header, props, index) => {
    switch (header) {
      case "Image":
        return (
          <img
            alt="product"
            src={props.value ? props.value : IMAGECOMING}
            className={css(styles.productImg)}
          />
        );
      case "Price":
        return <CurrencyDisplay price={props.value} />;
      case "Used":
        return <div className={css(styles.cellValue)}>{`${props.value}`}</div>;
      default:
        return <div className={css(styles.cellValue)}>{props.value}</div>;
    }
  };

  onSearchChange = e => {
    let search = e.target.value;
    this.setState(
      {
        page: 1,
        search: search,
        firstLoad: true
      },
      () => {
        this.search(search);
      }
    );
  };

  search = searchValue => {
    clearTimeout(this.searchTimeout);
    this.setState(
      {
        firstLoad: true,
        search: searchValue,
        page: 1
      },
      () => {
        this.searchTimeout = setTimeout(() => {
          this.loadData({ search: searchValue });
        }, SEARCH_TIMEOUT);
      }
    );
  };

  deleteConsignment = () => {
    let items = [...this.state.items];

    items.splice(this.state.selectedIndex, 1);

    this.setState(
      {
        items: items
      },
      () => this.loadData({ search: this.state.search })
    );
  };

  updateConsignment = (updated_item, index) => {
    let items = [...this.state.items];
    if (updated_item.consignment.status !== "pending") {
      updated_item.consignment.price.default_price =
        items[index].consignment.price.default_price;
    }
    items[index] = { ...this.state.items[index], ...updated_item };
    this.setState({
      items: items
    });
  };

  /***
   * Marks item for this.state.items at index as sold
   * @params int index -- the index we want to mark as sold
   */
  markAsSold = index => {
    let items = [...this.state.items];
    items[index].sold = true;
    this.setState({
      items
    });
  };

  exportConsignments = type => {
    this.setState({
      exportingConsignments: true
    });

    let location = this.state.location ? this.state.location : "";
    let size = this.state.selectedSize ? this.state.selectedSize : "";
    let consigner = this.state.selectedConsigner
      ? this.state.selectedConsigner
      : "";

    let startDate = this.state.startDate
      ? this.state.startDate.format("YYYY-MM-DD")
      : "";
    let endDate = this.state.endDate
      ? this.state.endDate.format("YYYY-MM-DD")
      : "";
    if (startDate && endDate && startDate === endDate) {
      endDate = this.state.endDate.add(1, "days").format("YYYY-MM-DD");
    }

    let baseUrl = API.CONSIGNMENT_REPORT;
    // if (type === "deleted") {
    //   baseUrl = API.DELETED_CONSIGNMENT_REPORT;
    // }

    let status = type || "all";

    fetch(
      baseUrl +
        `?location=${location}&size=${size}&consigner=${consigner}&consignment__list_status=${status}`,
      API.GET_CONFIG()
    )
      .then(Helpers.checkStatus)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        this.setState({
          message: null,
          exportingConsignments: false
        });
        alert(
          `Consignment report (location ${location}) will be sent to ${
            this.props.auth.userProfile.email
          }`
        );
      });
  };

  consignmentReportDatesChange = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate });
  };

  isOutsideRange = day => {
    let dayIsBlocked = false;

    if (isAfterDay(day, moment())) {
      dayIsBlocked = true;
    }

    return dayIsBlocked;
  };

  setConsigner = selectedConsigner => {
    this.setState({ selectedConsigner }, () =>
      this.loadData({ search: this.state.search })
    );
  };

  onSelectEmail = user => {
    this.setState(
      {
        selectedUser: user,
        openDropdown: false,
        selectedConsigner: user.user,
        page: 1,
        firstLoad: true
      },
      () => this.loadData({ search: this.state.search })
    );
  };

  searchForEmails = email => {
    if (email === null || email === "") {
      this.setState(
        {
          users: [],
          selectedUser: { email: email },
          selectedConsigner: null
        },
        () => {
          this.loadData({ search: this.state.search });
        }
      );
    } else {
      let config = API.GET_CONFIG();
      fetch(API.USER_ACTIONS + `?search=${email}&page=1&size=5`, config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          let users = [...res.results];
          this.setState({
            users: users,
            openDropdown: true
          });
        });
    }
    this.setState({
      selectedUser: { email: email }
    });
  };

  /***
   * Get Date text property based on activeTab state
   */
  getDatePropertyText = () => {
    if (this.state.activeTab === "all") {
      return "Updated Date";
    }
    return `${this.state.activeTab} Date`;
  };

  /***
   * Set Columns based on Consignment inventory view type
   */
  setColumnsBasedOnConsignmentType = () => {
    let columns = [];
    let date_text = this.getDatePropertyText();

    if (this.state.type === "inventory") {
      let headers = InventoryHelper.defaultInventoryHeaders(date_text).map(
        item => item.header
      );

      if (date_text === "listed Date") {
        headers.splice(headers.findIndex(item => item === "Listed Date"), 1);
      }

      if (this.state.activeTab === "deleted") {
        headers.push("Deleted Reason");
      }

      columns = headers.map((column, index) => ({
        Header: column,
        accessor:
          column.includes("Date") && column !== "Listed Date"
            ? "Updated Date"
            : column,
        className: css(styles.cell),
        headerClassName: css(styles.cellHeader),
        filterable: false,
        minWidth:
          column === "Image"
            ? 100
            : column === "Product Name"
            ? 400
            : column === "Size"
            ? 70
            : column === "Deleted Reason"
            ? 300
            : column.includes("Date")
            ? 200
            : 150,
        Cell: props => (
          <div
            className={css(
              styles.valueContainer,
              props.row._index === this.state.hoveredRow && styles.hoveredRow
            )}
            onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
            onClick={() => {
              column !== "SS ID" &&
                this.props.history.push(`inventory/${props.original.item_id}`);
            }}
          >
            {this.renderCell(props.column.Header, props, index)}
          </div>
        )
      }));
    } else if (this.state.type === "products") {
      columns = ["Image", "Product Name", "Style", "Quantity"].map(
        (column, index) => ({
          Header: column,
          accessor: column,
          className: css(styles.cell),
          headerClassName: css(styles.cellHeader),
          filterable: false,
          minWidth:
            column === "Image" ? 80 : column === "Product Name" ? 400 : 150,
          Cell: props => (
            <div
              className={css(
                styles.valueContainer,
                props.row._index === this.state.hoveredRow && styles.hoveredRow
              )}
              onMouseEnter={() =>
                this.setState({ hoveredRow: props.row._index })
              }
              onClick={() => {
                let expanded = this.state.expanded;
                let rowExpanded = expanded[props.viewIndex];
                if (rowExpanded === false) {
                  expanded[props.viewIndex] = {};
                } else {
                  expanded[props.viewIndex] = false;
                }
                this.setState({
                  expanded
                });
              }}
            >
              {this.renderCell(props.column.Header, props, index)}
            </div>
          )
        })
      );
      columns.push({
        expander: true,
        Header: "",
        width: 100,
        Expander: ({ isExpanded, ...rest }) => {
          return (
            <div>
              {isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}
            </div>
          );
        },
        style: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 25,
          padding: "0",
          userSelect: "none"
        }
      });
    }
    return columns;
  };

  /***
   * Changes the state when fetching date from react table
   */
  onTableFetchData = state => {
    this.setState({ sorted: state.sorted }, () => {
      this.loadData({
        search: this.state.search,
        pageSize: this.state.pageSize,
        sorted: this.state.sorted
      });
    });
  };

  render() {
    let { intl, auth } = this.props;
    const columns = this.setColumnsBasedOnConsignmentType();

    let tabs = this.tabs.map((tab, index) => {
      return (
        <div
          className={css(
            styles.tab,
            this.state.activeTab === tab.id && styles.activeTab
          )}
          onClick={() => this.setActiveTab(tab.id)}
          key={`tab_${tab.id}_${index}`}
          id={tab.id === "sold" && "consignmentsSold"}
        >
          {tab.label}
        </div>
      );
    });

    let users = this.state.users.map(user => {
      return (
        <div
          className={css(styles.dropdownItem)}
          onClick={() => this.onSelectEmail(user)}
        >
          {user.email}
        </div>
      );
    });

    return (
      <div className={css(styles.allBarcodes)}>
        <h2 className={css(styles.inventoryTitle)}>
          <FormattedMessage
            id="inventory_title"
            defaultMessage="Consignment Inventory"
          />
        </h2>
        <div className={css(styles.filterWrapper)}>
          <div className={css(styles.search)}>
            <div>
              <select
                className={css(styles.input)}
                onChange={this.onTypeChange}
              >
                <option
                  value="inventory"
                  selected={this.state.type === "inventory"}
                >
                  Inventory
                </option>
                <option
                  value="products"
                  selected={this.state.type === "products"}
                >
                  Products
                </option>
              </select>
            </div>
            <Input
              value={this.state.search}
              placeholder={intl.formatMessage({
                id: "search_consigner",
                defaultMessage:
                  "Search a consigner or scan a consignment barcode"
              })}
              onChange={this.onSearchChange}
              id={"searchConsignment"}
            />
          </div>
          <div className={css(styles.filterRow)}>
            <div className={css(styles.dropdown)}>
              <span className={css(styles.location)}>{`${intl.formatMessage({
                id: "inventory_location",
                defaultMessage: "Location"
              })}:`}</span>
              <Dropdown
                onChange={this.onLocationChange}
                default={this.state.location}
                removeAll
              />
            </div>
            <div className={css(styles.dropdown)}>
              <span className={css(styles.location)}>{`${intl.formatMessage({
                id: "size",
                defaultMessage: "Size"
              })}:`}</span>
              <SizeDropdown
                sizes={this.state.sizes}
                onChange={this.onSizeFilterChange}
                default={this.state.selectedSize}
              />
            </div>
            {this.state.type === "inventory" && (
              <div className={css(styles.dropdown)}>
                <span className={css(styles.location)}>{`${intl.formatMessage({
                  id: "used",
                  defaultMessage: "New/Used"
                })}:`}</span>
                <UsedDropdown
                  onChange={this.onUsedChange}
                  default={this.state.isUsed}
                  removeAll
                />
              </div>
            )}
            <div className={css(styles.dropdown)}>
              <span className={css(styles.location)}>{`${intl.formatMessage({
                id: "consigner",
                defaultMessage: "Consigner"
              })}:`}</span>
              <div className={css(styles.emailInputContainer)}>
                <Input
                  className={[styles.userInputs, styles.emailInput]}
                  value={this.state.selectedUser.email}
                  onChange={e => this.searchForEmails(e.target.value)}
                  placeholder={intl.formatMessage({
                    id: "profile_email",
                    defaultMessage: "Email"
                  })}
                  required
                  autoComplete="no"
                />
                {this.state.users &&
                this.state.selectedUser.email &&
                this.state.openDropdown ? (
                  <div className={css(styles.userDropdown)}>{users}</div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={css(styles.tabContainer)}>{tabs}</div>
          {this.state.type === "inventory" && (
            <div className={css(styles.filterRow)}>
              {["admin", "accountant", "warehouse manager"].includes(
                auth.userProfile.role.toLowerCase()
              ) && (
                <button
                  id="export"
                  className={css(styles.export)}
                  onClick={() => this.exportConsignments()}
                >
                  <FontAwesomeIcon
                    icon={["fas", "file-export"]}
                    className={css(styles.fileIcon)}
                  />
                  {this.state.exportingConsignments ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.fileIcon)}
                      spin
                    />
                  ) : (
                    <FormattedMessage
                      id="export_consignments"
                      defaultMessage="Export Consignments"
                    />
                  )}
                </button>
              )}
              {["admin", "accountant"].includes(
                auth.userProfile.role.toLowerCase()
              ) && (
                <button
                  id="export"
                  className={css(styles.export)}
                  onClick={() => this.exportConsignments("listed")}
                >
                  <FontAwesomeIcon
                    icon={["fas", "file-export"]}
                    className={css(styles.fileIcon)}
                  />
                  {this.state.exportingConsignments ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.fileIcon)}
                      spin
                    />
                  ) : (
                    <FormattedMessage
                      id="export_consignments"
                      defaultMessage="Export Listed Consignments"
                    />
                  )}
                </button>
              )}
              {["admin", "accountant"].includes(
                auth.userProfile.role.toLowerCase()
              ) && (
                <button
                  id="export"
                  className={css(styles.export)}
                  onClick={() => this.exportConsignments("deleted")}
                >
                  <FontAwesomeIcon
                    icon={["fas", "file-export"]}
                    className={css(styles.fileIcon)}
                  />
                  {this.state.exportingConsignments ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.fileIcon)}
                      spin
                    />
                  ) : (
                    <FormattedMessage
                      id="export_deleted_consignments"
                      defaultMessage="Export Deleted Consignments"
                    />
                  )}
                </button>
              )}
              {["admin", "accountant"].includes(
                auth.userProfile.role.toLowerCase()
              ) && (
                <button
                  id="export"
                  className={css(styles.export)}
                  onClick={() => this.exportConsignments("missing")}
                >
                  <FontAwesomeIcon
                    icon={["fas", "file-export"]}
                    className={css(styles.fileIcon)}
                  />
                  {this.state.exportingConsignments ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.fileIcon)}
                      spin
                    />
                  ) : (
                    <FormattedMessage
                      id="export_deleted_consignments"
                      defaultMessage="Export Missing Consignments"
                    />
                  )}
                </button>
              )}
            </div>
          )}
        </div>

        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <ReactTable
            className={css(styles.reactTable) + "inventory-table"}
            data={this.state.data}
            expanded={this.state.type === "products" && this.state.expanded}
            filterable={false}
            pages={this.state.numPages}
            columns={columns}
            footerClassName={css(styles.tableFooter)}
            defaultPageSize={this.state.pageSize}
            page={this.state.page - 1}
            pageSize={this.state.pageSize}
            onPageSizeChange={pageSize => {
              this.setState(
                {
                  pageSize,
                  numPages: Math.ceil(this.state.itemCount / pageSize)
                },
                () => {
                  this.loadData({
                    search: this.state.search,
                    page: this.state.page,
                    pageSize: this.state.pageSize
                  });
                }
              );
            }}
            onPageChange={pageIndex => {
              this.setState({ page: pageIndex + 1 }, () => {
                this.loadData({
                  search: this.state.search,
                  page: this.state.page,
                  pageSize: this.state.pageSize
                });
              });
            }}
            minRows={0}
            nextText={intl.formatMessage({
              id: "next",
              defaultMessage: "Next"
            })}
            previousText={intl.formatMessage({
              id: "previous",
              defaultMessage: "Previous"
            })}
            noDataText={intl.formatMessage({
              id: "no_items_found",
              defaultMessage: "No items found"
            })}
            onExpandedChange={
              this.state.type === "products" &&
              (expanded => {
                // don't for get to save the 'expanded'
                // so it can be fed back in as a prop
                this.setState({ expanded });
              })
            }
            onFetchData={state => this.onTableFetchData(state)}
            SubComponent={
              this.state.type === "products" && this.renderSubComponent
            }
            manual
          />
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  inventoryTitle: {
    fontSize: 40
  },
  allBarcodes: {
    padding: 30,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  filterWrapper: {
    background: "#fff",
    padding: 20,
    marginTop: 20,
    marginBottom: 40,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  filterRow: {
    width: "100%",
    maxWidth: 900,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap"
  },
  barcodeGroup: {
    padding: 16,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center"
  },
  tab: {
    color: "#111",
    fontSize: 16,
    padding: 14,
    textDecoration: "none",
    cursor: "pointer",
    borderRight: "1px solid #ddd"
  },
  info: {
    textAlign: "center",
    fontSize: 36,
    marginTop: 36
  },
  dropdown: {
    width: "max-content",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "13px 10px"
  },
  input: {
    width: "max-content",
    borderRadius: 4,
    padding: 16,
    fontSize: 16,
    marginRight: 10,
    boxSizing: "border-box",
    border: "2px solid #ddd",
    background: "#fff",
    cursor: "pointer"
  },
  location: {
    marginRight: 8
  },
  activeTab: {
    background: colors.RED(1),
    color: "#fff"
  },
  tabContainer: {
    marginBottom: 16,
    background: "#fff",
    display: "inline-flex",
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  search: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%"
  },
  consignmentOrder: {
    width: 500,
    margin: 16,
    padding: 16,
    borderRadius: 4,
    cursor: "pointer",
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    position: "relative"
    // margin: '0 auto',
  },
  soldBanner: {
    opacity: 0.8,
    zIndex: 4
  },
  soldOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "#ddd",
    opacity: 0,
    zIndex: 3,
    top: 0,
    left: 0
  },
  export: {
    display: "flex",
    alignItems: "center",
    margin: 8,
    height: 36,
    borderRadius: 4,
    padding: 8,
    border: "1px solid #ddd",
    background: "rgb(245, 245, 245)",
    cursor: "pointer",
    outline: "none",
    fontSize: 14,
    letterSpacing: 0.7,

    ":hover": {
      transform: "scale(1.05)",
      "-webkit-transition": ".5s",
      transition: ".5s"
    }
  },
  emailInputContainer: {
    width: 320
  },
  emailInput: {
    marginBottom: 0
  },
  dropdownItem: {
    padding: 10,
    paddingLeft: 20,
    cursor: "pointer",

    ":hover": {
      background: "rgb(204, 204, 204)"
    }
  },
  userInputs: {
    marginBottom: 20,
    height: 38,
    fontSize: 16,
    background: "#fff",
    border: "1px solid rgb(204, 204, 204)"
  },
  userDropdown: {
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: 4,
    zIndex: 2,
    position: "absolute",
    background: "#fff",
    width: "max-content"
  },
  productImg: {
    width: 80
  },
  table: {
    background: "#fff",
    padding: 20,
    marginTop: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: 60,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    cursor: "pointer",
    padding: "8 16",
    color: "#212b36"
  },
  subComponent: {
    padding: 30,
    textAlign: "left",
    background: "rgba(252,252,252)"
  },
  subTable: {
    width: "90%",
    maxWidth: 500,
    padding: "20px 30px",
    background: "white",
    boxShadow: "rgba(129, 148, 167, 0.3) 0px 3px 10px 0px"
  },
  subTableTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  editButton: {},
  // subValueContainer: {
  //   height: 40,
  //   width: "100%",
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  //   padding: "8 16",
  //   color: "#212b36"
  // },
  hoveredRow: {
    background: "#eee"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "6px 6px",
    outline: "none",
    textAlign: "left",
    fontWeight: "bold",
    overflow: "visible"
  },
  cell: {
    padding: 0
  },
  cellValue: {
    width: "100%",
    whiteSpace: "normal",
    paddingLeft: 10
  },

  subCellValue: {
    width: "100%",
    whiteSpace: "normal",
    paddingLeft: 10
  },
  subValueContainer: {
    height: 60,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    cursor: "pointer",
    padding: "8 16",
    color: "#212b36"
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  joyride: state.joyride
});

const mapDispatchToProps = {
  openConsignmentManagementModal: ModalActions.openConsignmentManagementModal,
  joyrideActions: JoyrideActions,
  setMessage: MessageActions.setMessage,
  showMessage: MessageActions.showMessage,
  queryInventory: payload => dispatch =>
    dispatch(Actions.inventory.queryInventory(payload))
};

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Inventory)
);
