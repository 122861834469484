export const checkPermissions = (permission, userProfile, location = null) => {
  const { permissions, role } = userProfile;
  if (role.toLowerCase() === "admin") {
    return true;
  }
  if (location) {
    if (permissions[location] && permissions[location].includes(permission)) {
      return true;
    }
  }
  if (permissions.global && permissions.global.includes(permission)) {
    return true;
  }
  return false;
};
