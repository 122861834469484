import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import ToolTip from "rc-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Component
import BaseModal from "../BaseModal";
import SizeBlock from "../../SizeBlock";

// Actions
import { ModalActions } from "../../../redux/modals";

// Config
import API from "../../../config/api";
import Helpers from "../../../redux/helpers";

import "rc-tooltip/assets/bootstrap_white.css";

class SizeModal extends React.Component {
  constructor(props) {
    super(props);

    this.youthSizes = [];
    for (let i = 1; i < 13; i += 0.5) {
      this.youthSizes.push(i + "Y");
    }

    this.childSizes = [];
    for (let i = 1; i < 14; i += 0.5) {
      this.childSizes.push(i + "C");
    }

    this.adidasChildSizes = [];
    for (let i = 1; i < 14; i += 0.5) {
      this.adidasChildSizes.push(i + "K");
    }

    this.shoeSizes = [];
    for (let i = 3.5; i < 16; i += 0.5) {
      this.shoeSizes.push(i);
    }

    this.apparelSizes = [
      "N/A",
      "XXS",
      "XS",
      "S",
      "M",
      "L",
      "XL",
      "XXL",
      "XXXL"
    ];

    this.defaultState = {
      loading: false,
      quantity_by_size: {}
    };

    this.state = {
      ...this.defaultState
    };
  }
  /***
   * closes the modal
   */
  closeModal = () => {
    let { modalActions } = this.props;
    modalActions.openSizeModal(false, []);

    this.setState({
      ...this.defaultState
    });
  };

  /**
   * Gets the quantity per size
   */
  getQuantityBySize = () => {
    let { modals } = this.props;
    this.setState({
      loading: true
    });
    return fetch(
      API.TRUE_STYLE({
        style: modals.sizeModal.style,
        location: this.props.locationId
      }),
      API.GET_CONFIG(true)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          quantity_by_size: res,
          loading: false
        });
      });
  };

  afterOpen = () => {
    this.getQuantityBySize();
  };

  renderSize = (inStock, size) => {
    let { intl } = this.props;
    return (
      <div key={size}>
        {inStock ? (
          <ToolTip
            placement="top"
            trigger={["click"]}
            overlay={
              <div>
                <div>
                  {`${intl.formatMessage({
                    id: "inventory_tab",
                    defaultMessage: "Inventory"
                  })}:`}{" "}
                  {this.state.quantity_by_size[size] &&
                    this.state.quantity_by_size[size]["inventory"]}
                </div>
                <div>
                  {`${intl.formatMessage({
                    id: "consignment_tab",
                    defaultMessage: "Consignment"
                  })}:`}{" "}
                  {this.state.quantity_by_size[size] &&
                    this.state.quantity_by_size[size]["consignment"]}
                </div>
              </div>
            }
          >
            <div className={css(styles.sizeBlock)}>
              <SizeBlock size={size} key={size} inStock={inStock} />
            </div>
          </ToolTip>
        ) : (
          <div className={css(styles.sizeBlock)}>
            <SizeBlock size={size} key={size} inStock={inStock} />
          </div>
        )}
      </div>
    );
  };

  render() {
    let { modals } = this.props;
    let sizeCarousel = this.apparelSizes;
    let youthSizes = [];
    let childSizes = [];
    let adidasChildSizes = [];
    if (modals.sizeModal.item_type === "shoe") {
      sizeCarousel = this.shoeSizes;

      youthSizes = this.youthSizes.map((size, index) => {
        if (!size) {
          return null;
        }

        let inStock =
          this.state.quantity_by_size[size] &&
          (this.state.quantity_by_size[size].consignment ||
            this.state.quantity_by_size[size].inventory);

        return <Fragment key={size}>{this.renderSize(inStock, size)}</Fragment>;
      });

      childSizes = this.childSizes.map((size, index) => {
        if (!size) {
          return null;
        }

        let inStock =
          this.state.quantity_by_size[size] &&
          (this.state.quantity_by_size[size].consignment ||
            this.state.quantity_by_size[size].inventory);

        return <Fragment key={size}>{this.renderSize(inStock, size)}</Fragment>;
      });

      adidasChildSizes = this.adidasChildSizes.map((size, index) => {
        if (!size) {
          return null;
        }

        let inStock =
          this.state.quantity_by_size[size] &&
          (this.state.quantity_by_size[size].consignment ||
            this.state.quantity_by_size[size].inventory);

        return <Fragment key={size}>{this.renderSize(inStock, size)}</Fragment>;
      });
    }

    let sizes = sizeCarousel.map((size, index) => {
      if (!size) {
        return null;
      }

      let inStock =
        this.state.quantity_by_size[size] &&
        (this.state.quantity_by_size[size].consignment ||
          this.state.quantity_by_size[size].inventory);

      return <Fragment key={size}>{this.renderSize(inStock, size)}</Fragment>;
    });
    return (
      <BaseModal
        isOpen={modals.openSizeModal}
        closeModal={this.closeModal}
        afterOpen={this.afterOpen}
        modalClassName={styles.modal}
        contentLabel="SizeModal"
      >
        <div className={css(styles.container)}>
          {this.state.loading && (
            <FontAwesomeIcon
              icon={["far", "spinner-third"]}
              className={css(styles.loader)}
              size="4x"
              spin
            />
          )}
          <h2 className={css(styles.header)}>Available Sizes</h2>
          {modals.sizeModal.item_type === "shoe" && (
            <Fragment>
              <div className={css(styles.sizes)}>{youthSizes}</div>
              <div className={css(styles.sizes)}>{childSizes}</div>
              <div className={css(styles.sizes)}>{adidasChildSizes}</div>
            </Fragment>
          )}
          <div className={css(styles.sizes)}>{sizes}</div>
        </div>
      </BaseModal>
    );
  }
}

var styles = StyleSheet.create({
  container: {
    width: "100%",
    position: "relative"
  },
  header: {
    textAlign: "center"
  },
  modal: {
    maxWidth: 1024
  },
  loader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
  },
  sizeBlock: {
    marginRight: 16,
    marginTop: 16
  },
  sizes: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 16
  }
});

const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SizeModal)
);
