import React from "react";
import _ from "lodash";

// NPM Modules
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

import API from "../../config/api";
import Helpers from "../../redux/helpers";
import { MessageActions } from "../../redux/message";

class Permissions extends React.Component {
  state = {
    profile: {},
    global_permissions: [],
    all_permissions: [],
    location_permissions: [],
    role_permissions_map: {},
    userPermissions: {}
  };

  componentDidMount() {
    const { userId } = this.props;

    this.getPermissionInfo();
    this.getProfile(userId);
  }

  getProfile() {
    const userId = this.props.match.params.userId;
    const url = API.USER_PERMISSIONS({ userId });
    fetch(url, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(profile => {
        this.setState({
          profile,
          userPermissions: _.cloneDeep(profile.permissions)
        });
      });
  }

  getPermissionInfo() {
    fetch(API.PERMISSION_INFO, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(data => {
        const {
          global_permissions,
          role_permissions_map,
          all_permissions
        } = data;

        // For location-specific permissions, exclude permissions that only
        // apply globally, like managing products
        const location_permissions = _.filter(
          all_permissions,
          permission => !global_permissions.includes(permission)
        );

        this.setState({
          global_permissions,
          location_permissions,
          all_permissions,
          role_permissions_map
        });
      });
  }

  savePermissions() {
    const { messageActions, intl } = this.props;
    const userId = this.profile.id;
    const permissions = this.state.userPermissions;
    let putConfig = API.PUT_CONFIG({ permissions });
    const url = API.USER_PERMISSIONS({ userId });
    this.setState({ saving: true });
    fetch(url, putConfig);
    return fetch(url, putConfig)
      .then(() => {
        this.setState({ saving: false });
        messageActions.setMessage(
          intl.formatMessage({
            id: "permissions_saved",
            defaultMessage: "Permissions saved"
          })
        );
        messageActions.showMessage({ show: true });
      })
      .catch(() => {
        this.setState({ saving: false });
        messageActions.setMessage(
          intl.formatMessage({
            id: "permissions_save_error",
            defaultMessage: "Error saving permissions"
          })
        );
        messageActions.showMessage({ show: true, error: true });
      });
  }

  togglePermission(location_id, permission) {
    let locationPermissions = _.get(
      this.state,
      `userPermissions.${location_id}`,
      []
    );
    if (locationPermissions.includes(permission)) {
      locationPermissions = _.without(locationPermissions, permission);
    } else {
      locationPermissions = [...locationPermissions, permission];
    }

    let userPermissions = _.cloneDeep(this.state.userPermissions);
    _.assign(userPermissions, {
      [location_id.toString()]: locationPermissions
    });

    this.setState({
      userPermissions
    });
  }

  get profile() {
    return this.state.profile;
  }

  get role() {
    const role = _.get(this.state, "profile.role");
    return (role || "").toLowerCase();
  }

  get roleGlobalPermissions() {
    return _.get(this.state.role_permissions_map, `${this.role}.global`, []);
  }

  get roleLocationPermissions() {
    const location = _.get(this.profile, "location.id", "").toString();
    const permissions = _.get(
      this.state.role_permissions_map,
      `${this.role}.local`,
      []
    );
    return { [location]: permissions };
  }

  roleHasGlobalPermission(permission) {
    if (this.role === "admin") {
      return true;
    }
    return this.roleGlobalPermissions.includes(permission);
  }

  roleHasLocationPermission(permission, location_id) {
    const location = location_id.toString();

    if (this.role === "admin") {
      return true;
    }

    return _.get(this.roleLocationPermissions, location, []).includes(
      permission
    );
  }

  userHasGlobalPermission(permission) {
    if (this.roleHasGlobalPermission(permission)) {
      return true;
    }
    return _.get(this.state.userPermissions, "global", []).includes(permission);
  }

  userHasLocationPermission(permission, location_id) {
    if (this.roleHasLocationPermission(permission, location_id)) {
      return true;
    }

    const locationPermissions =
      this.state.userPermissions[location_id.toString()] || [];
    return locationPermissions.includes(permission);
  }

  renderPermissionCheckbox(location, permission) {
    let disabled;
    let checked;

    if (location === "global") {
      disabled = this.roleHasGlobalPermission(permission);
      checked = this.userHasGlobalPermission(permission);
    } else {
      disabled = this.roleHasLocationPermission(permission, location);
      checked = this.userHasLocationPermission(permission, location);
    }

    return (
      <>
        <input
          id={`${location}_${permission}`}
          type="checkbox"
          onChange={() => this.togglePermission(location, permission)}
          disabled={disabled}
          checked={checked}
        />
        <label for={`${location}_${permission}`}>{permission}</label>
      </>
    );
  }

  renderGlobalPermissions() {
    return (
      <table>
        {_.map(_.chunk(this.state.global_permissions, 5), row => (
          <tr>
            {_.map(row, permission => (
              <td>{this.renderPermissionCheckbox("global", permission)}</td>
            ))}
          </tr>
        ))}
        {_.map(_.chunk(this.state.location_permissions, 5), row => (
          <tr>
            {_.map(row, permission => (
              <td>{this.renderPermissionCheckbox("global", permission)}</td>
            ))}
          </tr>
        ))}
      </table>
    );
  }

  renderLocationPermissions(location_id) {
    return (
      <table>
        {_.map(_.chunk(this.state.location_permissions, 5), row => (
          <tr>
            {_.map(row, permission => (
              <td>
                {this.renderPermissionCheckbox(
                  location_id.toString(),
                  permission
                )}
              </td>
            ))}
          </tr>
        ))}
      </table>
    );
  }

  render() {
    const { locations } = this.props;

    return (
      <>
        <h4>
          {this.profile.first_name} {this.profile.last_name}
        </h4>
        <h5>{this.profile.email}</h5>

        <p>
          <h6>Global permissions</h6>
          {this.renderGlobalPermissions()}
        </p>
        {_.map(locations, location => (
          <p>
            <h6>{location.name}</h6>
            {this.renderLocationPermissions(location.id)}
          </p>
        ))}
        <button onClick={() => this.savePermissions()}>Save</button>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    locations: _.get(state, "inventory.locations")
  };
};

const mapDispatchToProps = dispatch => ({
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Permissions)
);
