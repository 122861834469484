/***
 * Manual Input modal for adding new products
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faArrowLeft
} from "@fortawesome/pro-regular-svg-icons";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import InputMask from "react-input-mask";

// Components
import BaseModal from "../BaseModal";
import Input from "../../Input";
import Button from "../../Button";
import SearchPlaceholder from "./AddConsignmentModalSearchPlaceholder";
import BarcodeModal from "../AddProduct/BarcodeModal";
import ConsignmentFields from "../../consignment/ConsignmentFields";

// Actions
import { ModalActions } from "../../../redux/modals";
import { AuthActions } from "../../../redux/auth";
import { JoyrideActions } from "../../../redux/joyride";

// Config
import colors from "../../../config/colors";
import API from "../../../config/api";
import Helpers from "../../../redux/helpers";
import { MessageActions } from "../../../redux/message";

const SEARCH_TIMEOUT = 700;

class AddConsignmentModal extends React.Component {
  constructor(props) {
    super(props);

    let { auth } = this.props;

    this.initialState = {
      skus: [],
      selectedStyle: {},
      page: 1,
      form: {
        sku: {},
        consignment: {}
        // pictures: []
      },
      upcCodes: {},
      searching: false,
      search: "",
      searchingForBarcode: false,
      users: [],
      selectedUser: {},
      selectedOption: {},
      emailSearch: null,
      openDropdown: false,
      consignmentOrder: [],
      consignments: [],
      barcodes: [],
      newAccount: false,
      location: auth.userProfile.location && auth.userProfile.location.id,
      listOnShopify: true,
      listOnEbay: false,
      created: false,
      createLoading: false,
      editIndex: null,
      isEbay: false
    };

    this.state = {
      ...this.initialState
    };
  }

  closeModal = () => {
    if (!this.state.created) {
      let answer = window.confirm(
        "Are you sure you want to exit without creating the consignment order?"
      );
      if (!answer) {
        return;
      }
    }

    let { modalActions } = this.props;
    modalActions.openAddConsignmentModal(false);

    this.setState({
      ...this.initialState,
      form: {
        sku: {},
        consignment: {}
      }
    });
  };

  /***
   * When search changes, set a small timeout to wait for to search
   */
  onSearchChange = e => {
    clearTimeout(this.searchTimeout);
    let searchValue = e.target.value;
    this.setState({
      searching: true,
      search: searchValue
    });

    this.searchTimeout = setTimeout(async () => {
      await this.search(searchValue);
      this.setState({
        searching: false,
        searchForBarcode: searchValue.match(/[0-9]{8}/)
      });
    }, SEARCH_TIMEOUT);
  };

  /**
   * style search
   * @param  {String} searchValue -- search value
   */
  search = searchValue => {
    return fetch(API.PRODUCT({ search: searchValue }), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          skus: res.results
        });
      });
  };

  setModalRef = ref => {
    this.modalRef = ref;
  };

  /**
   * selecting a styl
   * @param  {Object} style -- style selected
   */
  onStyleClick = style => {
    let color = style.color;
    let barcode = {};
    let size = "";
    if (this.state.searchForBarcode) {
      color = style.color;
      size = style.us_size;
    }
    let available_sizes = [];
    if (style.skus) {
      style.skus.forEach(sku => {
        barcode[sku.us_size] = sku.barcode;
      });

      available_sizes = style.skus
        .filter(sku => sku.active)
        .map(sku => sku.us_size);
    }
    console.log(available_sizes);

    console.log("style", style);

    this.setState({
      selectedStyle: style,
      page: this.state.page + 1,
      upcCodes: barcode,
      form: {
        sku: {
          product_name_us: style.product_name_us,
          product: {
            brand: style.brand,
            available_sizes
          },
          style: style.style,
          item_type: style.item_type,
          barcode: "",
          color: color
        },
        consignment: {
          size: size
        },
        showPrice: style.item_type !== "shoe"
        // pictures: []
      }
    });

    setTimeout(() => {
      this.modalRef.node.children[0].children[0].scrollTo(0, 0);
    }, 50);
  };

  /**
   * input change for product information
   * @param  {EventObject} e  -- event object
   * @param  {String} id -- id string of input
   */
  onFormInputChange = (e, id) => {
    let form = { ...this.state.form };
    if (id === "price") {
      form.price = e.target.value;
    } else if (id === "cost") {
      form.cost = e.target.value !== "" ? e.target.value : null;
    } else if (id === "showPrice") {
      form["showPrice"] = e.target.checked;
    } else if (id === "listOnShopify") {
      this.setState({ listOnShopify: e.target.checked });
    } else if (id === "listOnEbay") {
      this.setState({ listOnEbay: e.target.checked });
    } else if (id === "quantity") {
      if (parseInt(e.target.value, 10) > 20) {
        form.quantity = "20";
      } else if (parseInt(e.target.value, 10) <= 0) {
        form.quantity = "1";
      } else {
        form.quantity = e.target.value;
      }
    } else {
      form.sku[id] = e.target.value;
    }

    this.setState({
      form: form
    });
  };

  /**
   * On size change
   * @param  {Float} size -- size
   */
  onSizeChange = size => {
    let form = { ...this.state.form };
    form.consignment.size = size;
    form.consignment.waist = null;
    form.consignment.length = null;

    this.setState({
      form: form
    });
  };

  /**
   * On type change
   * @param  {String} typeId -- id of type
   */
  onTypeChange = typeId => {
    let form = { ...this.state.form };
    form.sku.item_type = typeId;
    this.setState({
      form: form
    });
  };

  /**
   * Go to next page without selecting an existing style
   * @return {[type]} [description]
   */
  addNewStyle = () => {
    this.setState({
      page: this.state.page + 1
    });
  };

  printBarcode = (barcode, item) => {
    let { modals } = this.props;

    if (!modals.addProductModal.move) {
      let key = Math.random()
        .toString(36)
        .substring(7);

      let barcodes = [];
      if (this.state.barcodes) {
        barcodes = [...this.state.barcodes, { barcode, item, key }];
      } else {
        barcodes = [{ barcode, item, key }];
      }
      this.setState({
        barcodes
      });
    }
  };

  /**
   * create consignment item
   * @param  {EventObject} e -- event object
   */
  createConsignment = e => {
    e.preventDefault();

    let form = { ...this.state.form };

    if (form.consignment.size === "") {
      window.alert("please choose a size");
      return;
    }

    if (form.sku.item_type === "") {
      window.alert("please choose a product type");
      return;
    }

    if (
      this.state.form.consignment.waist &&
      this.state.form.consignment.length
    ) {
      form.consignment.size = `${this.state.form.consignment.waist}x${
        this.state.form.consignment.length
      }`;
    }

    let sku = {
      product: {
        brand: form.sku.product.brand,
        available_sizes: form.sku.product.available_sizes
      },
      product_name: form.sku.product_name_us,
      product_name_us: form.sku.product_name_us,
      us_size: form.consignment.size,
      style: form.sku.style,
      barcode: form.sku.barcode,
      location_id: this.state.location,
      color: form.sku.color,
      item_type: form.sku.item_type
    };

    let params = {
      selectedUserId: this.state.selectedUser.user,
      product: {
        item: sku
      },
      style: form.sku.style,
      item_type: form.sku.item_type,
      list_on_shopify: this.state.listOnShopify,
      list_on_ebay: this.state.listOnEbay,
      consignment: {
        quantity: parseInt(form.quantity, 10) || 1,
        price: form.price * 100,
        cost: form.cost ? form.cost * 100 : form.cost,
        defects: form.consignment.defects,
        missing_insoles: form.consignment.missing_insoles,
        scuff_marks: form.consignment.scuff_marks,
        yellowing: form.consignment.yellowing,
        apparel_package_condition: form.consignment.apparel_package_condition,
        box_condition: form.consignment.box_condition,
        discolored: form.consignment.discolored,
        missing_tags: form.consignment.missing_tags,
        tears: form.consignment.tears,
        size: form.consignment.size,
        status: "pending",
        used: form.consignment.used
      }
      // pictures: form.pictures
    };

    if (!this.state.selectedStyle.id) {
      params["itemName"] = form.sku.product_name_us;
    }

    //set hidePrice
    params["hidePrice"] = !form.showPrice;

    this.setState({
      consignments: [...this.state.consignments, params],
      page: this.state.page + 1,
      skus: [],
      selectedStyle: {},
      form: {
        sku: {},
        consignment: {}
      },
      searching: false,
      search: "",
      selectedOption: {}
    });
  };

  removeConsignment = index => {
    let newConsignments = [...this.state.consignments];
    newConsignments.splice(index, 1);
    this.setState({ consignments: newConsignments });
  };

  editConsignment = index => {
    let consignment = this.state.consignments[index];
    this.setState({
      form: {
        ...this.state.form,
        consignment: consignment.consignment,
        sku: consignment.product.item,
        price: consignment.consignment.price / 100,
        cost:
          consignment.consignment.cost !== null
            ? consignment.consignment.cost / 100
            : null,
        showPrice: !consignment.hidePrice,
        quantity: consignment.consignment.quantity,
        pictures: consignment.pictures
      },
      page: 5,
      editIndex: index
    });
  };

  updateConsignment = e => {
    e.preventDefault();

    let form = { ...this.state.form };
    let sku = {
      product: {
        brand: form.sku.product.brand,
        available_sizes: form.sku.product.available_sizes
      },
      product_name: form.sku.product_name_us,
      product_name_us: form.sku.product_name_us,
      us_size: form.consignment.size,
      style: form.sku.style,
      barcode: form.sku.barcode,
      location_id: this.state.location,
      color: form.sku.color,
      item_type: form.sku.item_type
    };

    let Newconsignment = {
      selectedUserId: this.state.consignments[this.state.editIndex]
        .selectedUserId,
      product: {
        item: sku
      },
      style: form.sku.style,
      item_type: form.sku.item_type,
      consignment: {
        quantity: parseInt(form.quantity, 10) || 1,
        price: form.price * 100,
        cost: form.cost ? form.cost * 100 : form.cost,
        defects: form.consignment.defects,
        missing_insoles: form.consignment.missing_insoles,
        scuff_marks: form.consignment.scuff_marks,
        yellowing: form.consignment.yellowing,
        apparel_package_condition: form.consignment.apparel_package_condition,
        box_condition: form.consignment.box_condition,
        discolored: form.consignment.discolored,
        missing_tags: form.consignment.missing_tags,
        tears: form.consignment.tears,
        size: form.consignment.size,
        status: "pending",
        used: form.consignment.used
      },
      hidePrice: !form.showPrice
      // pictures: form.pictures
    };

    let consignments = [...this.state.consignments];
    consignments[this.state.editIndex] = Newconsignment;

    this.setState({
      consignments,
      page: 4,
      form: {
        sku: {},
        consignment: {}
        // pictures: []
      }
    });
  };

  back = () => {
    this.setState({
      page: 4,
      form: {
        sku: {},
        consignment: {}
      }
    });
  };

  onAfterOpen = () => {
    let { modals, auth } = this.props;
    this.setState({
      ...modals.manualInputModal
    });

    // IF WE ARE A WAREHOUSE USER OR ADMIN, THEN SET PAGE TO 0;
    if (
      auth.userProfile.role === "Admin" ||
      auth.userProfile.role === "Warehouse Manager" ||
      auth.userProfile.role === "Store Manager" ||
      auth.userProfile.location.id === 4
    ) {
      this.setState({
        page: 0
      });
    }
  };

  /**
   * On defect selected
   * @param  {String} defect --
   * @return {[type]}        [description]
   */
  onDefectClick = defect => {
    let form = { ...this.state.form };
    form.consignment[defect] = form.consignment[defect] ? false : true;
    this.setState({
      form: form
    });
  };

  onPackageClick = (packaging, packageCondition) => {
    let form = { ...this.state.form };
    form.consignment[packaging] = packageCondition;
    this.setState({
      form: form
    });
  };

  onPantsChange = (type, size) => {
    let form = { ...this.state.form };
    form.consignment.size = null;
    if (type === "waist") {
      form.consignment.waist = size;
    } else {
      form.consignment.length = size;
    }
    this.setState({
      form: form
    });
  };

  onPicturesChange = pictures => {
    let form = { ...this.state.form };
    form.pictures = pictures;
    this.setState({
      form: form
    });
  };

  searchForEmails = email => {
    if (this.state.newAccount) {
      this.setState({
        selectedUser: { email: email }
      });
      return;
    }
    if (email === null || email === "") {
      this.setState({
        users: [],
        selectedUser: { email: email }
      });
    } else {
      let config = API.GET_CONFIG();
      fetch(API.USER_ACTIONS + `?search=${email}&page=1&size=5`, config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          let users = [...res.results];
          this.setState({
            users: users,
            openDropdown: true
          });
        });
    }

    this.setState({
      selectedUser: { email: email }
    });
  };

  noFilter = options => {
    return options;
  };

  onSelectEmail = user => {
    this.setState({
      selectedUser: user,
      openDropdown: false
    });
  };

  onUserInfoChange = (e, key) => {
    let user = { ...this.state.selectedUser };
    user[key] = e.target.value;
    this.setState({
      selectedUser: user
    });
  };

  consignmentRegister = () => {
    // let { authActions } = this.props;
    let password = [...Array(16)]
      .map(i => {
        let char = (~~(Math.random() * 36)).toString(36);
        let rand = Math.random();
        if (rand > 0.5) {
          return char.toUpperCase();
        } else {
          return char;
        }
      })
      .join("");
    let params = {
      email: this.state.selectedUser.email,
      first_name: this.state.selectedUser.first_name,
      last_name: this.state.selectedUser.last_name,
      phone_number:
        this.state.selectedUser.phone_number &&
        this.state.selectedUser.phone_number
          .replace(new RegExp("\\(", "g"), "")
          .replace(new RegExp("\\)", "g"), "")
          .replace(new RegExp(" ", "g"), "")
          .replace(new RegExp("-", "g"), ""),
      password: password
    };
    let config = API.POST_CONFIG(params);
    fetch(API.USER_ACTIONS, config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          selectedUser: res
        });
      });
  };

  handleUserButton = e => {
    e.preventDefault();
    if (this.state.newAccount) {
      this.consignmentRegister();
    }
    this.setState({
      page: this.state.page + 1
    });
  };

  unmount = () => {
    this.setState({
      barcodes: []
    });
  };

  onRegisterClick = e => {
    this.setState({
      newAccount: true,
      openDropdown: false
    });
  };

  createConsignmentOrder = async () => {
    var fetches = [];

    let consignments = this.state.consignments;

    if (!consignments || consignments.length === 0) {
      window.alert("No consignments created");
      return;
    }

    this.setState({ createLoading: true });
    consignments.forEach(item => {
      let params = JSON.parse(JSON.stringify(item));
      delete params["hidePrice"];
      fetches.push(
        fetch(API.CONSIGNMENT({}), API.POST_CONFIG(params))
          .then(Helpers.checkStatus)
          .then(Helpers.parseJSON)
          .then(res => {
            // for (let i = 0; i < res.items.length; i++) {
            //   this.printBarcode(`ss-${res.items[i].id}`, res.items[i]);
            // }

            // messageActions.showMessage({ show: false });
            let hidePrice = item["hidePrice"];
            item["itemIds"] = res.items.map(item => item.id);
            item["items"] = res.items;
            res.items = res.items.forEach(item => {
              item["hidePrice"] = hidePrice;
            });

            this.setState({
              consignmentOrder: [
                ...this.state.consignmentOrder,
                ...res.consignment
              ]
            });
          })
      );
    });

    Promise.all(fetches).then(() => {
      let consignment_ids = [];
      for (let i = 0; i < this.state.consignmentOrder.length; i++) {
        consignment_ids.push(this.state.consignmentOrder[i].id);
      }
      let params = {
        selectedUserId: this.state.selectedUser.user,
        consignment_ids: consignment_ids,
        dropoff_location: this.state.location,
        status: "pending"
      };
      let config = API.POST_CONFIG(params);
      return fetch(API.CONSIGNMENT_ORDERS({}), config)
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          // this.closeModal();
          console.log("consignments", this.state.consignments);
          let barcodes = [];
          this.state.consignments.forEach(consignment => {
            consignment.itemIds.forEach((itemId, index) => {
              let key = Math.random()
                .toString(36)
                .substring(7);
              barcodes.push({
                barcode: `ss-${itemId}`,
                item: consignment.items[index],
                key
              });
            });
          });
          console.log("barcodes", barcodes);

          this.setState({
            created: true,
            createLoading: false,
            barcodes
          });
        });
    });
  };

  componentWillReceiveProps = (nextProps, nextState) => {
    if (
      nextProps.joyride.inProgress &&
      nextProps.joyride.stepIndex === 2 &&
      this.props.joyride.stepIndex !== 2
    ) {
      this.setState(
        {
          page: this.state.page + 1
        },
        () => {
          let { joyrideActions } = this.props;
          joyrideActions.setRun({ run: true, inProgress: true });
        }
      );
    } else if (
      nextProps.joyride.inProgress &&
      nextProps.joyride.stepIndex === 4 &&
      this.props.joyride.stepIndex !== 4
    ) {
      this.setState(
        {
          page: this.state.page + 1
        },
        () => {
          let { joyrideActions } = this.props;
          joyrideActions.setRun({ run: true, inProgress: true });
        }
      );
    }
  };

  checkInInventory = location => {
    const inventoryLocation = this.props.inventory.locations.find(
      ({ id }) => id === location
    );
    const isEbay =
      inventoryLocation && inventoryLocation.name.toLowerCase() === "ebay";
    if (isEbay) {
      this.setState({ listOnEbay: true, listOnShopify: false, isEbay });
    }
    this.setState({
      page: 1,
      location
    });
  };

  printLabel = consignment => {
    consignment.itemIds.forEach((itemId, index) => {
      this.printBarcode(`ss-${itemId}`, consignment.items[index]);
    });
  };

  render() {
    let { modals, inventory, intl } = this.props;

    let productStyles = this.state.skus.map((sku, index) => {
      return (
        <div
          className={css(styles.productStyle)}
          onClick={() => this.onStyleClick(sku)}
          key={`style_${index}`}
        >
          <div className={css(styles.column)}>
            {sku.pictures && sku.pictures.length > 0 ? (
              <img
                className={css(styles.picture)}
                src={sku.pictures[0].url}
                alt={sku.product_name_us}
              />
            ) : (
              <div>
                {intl.formatMessage({
                  id: "no_image_found",
                  defaultMessage: "No image found"
                })}
              </div>
            )}
          </div>
          <div className={css(styles.column)}>{sku.product_name_us}</div>
          <div className={css(styles.column)}>{sku.style}</div>
        </div>
      );
    });

    let users = this.state.users.map((user, index) => {
      return (
        <div
          className={css(styles.dropdownItem)}
          onClick={() => this.onSelectEmail(user)}
        >
          {user.email}
        </div>
      );
    });

    let consignments = this.state.consignments.map((consignment, index) => {
      let defects = [];
      if (consignment.consignment.defects) {
        defects.push("Manufacturer Defects");
      }
      if (consignment.consignment.missing_insoles) {
        defects.push("Missing Insoles");
      }
      if (consignment.consignment.scuff_marks) {
        defects.push("Scuff Marks");
      }
      if (consignment.consignment.yellowing) {
        defects.push("Yellowing");
      }

      if (consignment.consignment.discolored) {
        defects.push("Discolored/Stained");
      }
      if (consignment.consignment.missing_tags) {
        defects.push("Missing Tags");
      }
      if (consignment.consignment.tears) {
        defects.push("Tears");
      }
      if (consignment.consignment.used) {
        defects.push("Used");
      }
      return (
        <div className={css(styles.consignment)}>
          <div className={css(styles.index, styles.consignmentSection)}>
            {index + 1}.
          </div>
          <div className={css(styles.productName, styles.consignmentSection)}>
            {consignment.product.item.product_name_us}
          </div>
          <div
            className={css(styles.consignmentSize, styles.consignmentSection)}
          >
            {`${intl.formatMessage({
              id: "size",
              defaultMessage: "Size"
            })} ${consignment.product.item.us_size}`}
          </div>
          <div className={css(styles.consignmentInfo)}>
            {consignment.consignment.box_condition && (
              <div className={css(styles.packaging, styles.consignmentSection)}>
                {`${intl.formatMessage({
                  id: "packaging",
                  defaultMessage: "Packaging"
                })} ${
                  consignment.consignment.box_condition
                    ? consignment.consignment.box_condition
                    : consignment.consignment.apparel_package_condition
                }`}
              </div>
            )}
            {defects.length > 0 && (
              <div className={css(styles.defects, styles.consignmentSection)}>
                {defects.join(", ")}
              </div>
            )}
          </div>
          <div
            className={css(styles.consignmentPrice, styles.consignmentSection)}
          >
            ${consignment.consignment.price / 100}
          </div>
          <div
            className={css(styles.consignmentPrice, styles.consignmentSection)}
          >
            Quantity: {consignment.consignment.quantity}
          </div>
          {!this.state.created && (
            <div>
              <span
                onClick={() => this.editConsignment(index)}
                className={css(styles.icon)}
              >
                <FontAwesomeIcon icon={faEdit} />
              </span>
              <span
                onClick={() => this.removeConsignment(index)}
                className={css(styles.icon)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </div>
          )}
          {this.state.created && (
            <Button
              text={intl.formatMessage({
                id: "print_label",
                defaultMessage: "Print Label"
              })}
              className={styles.reprintButton}
              onClick={() => {
                let barcodes = consignment.itemIds.map((itemId, index) => {
                  let key = Math.random()
                    .toString(36)
                    .substring(7);
                  return {
                    barcode: `ss-${itemId}`,
                    item: consignment.items[index],
                    key
                  };
                });
                this.setState({ barcodes });
                // this.printLabel(consignment)
              }}
            />
          )}
        </div>
      );
    });

    // let barcodes = this.state.barcodes.map((dict, index) => {
    //   if (index > 0) {
    //     return null;
    //   }
    //   return (
    //     <BarcodeModal
    //       barcode={dict.barcode}
    //       hidePrice={!this.state.showPrice}
    //       key={`${dict.key}_${index}`}
    //       unmount={this.unmount}
    //       barcodeKey={dict.key}
    //       name={dict.item.sku.product_name_us}
    //       size={dict.item.sku.us_size}
    //       color={dict.item.sku.color}
    //       style={dict.item.sku.style}
    //       price={dict.item.consignment.price.default_price / 100}
    //       hidePrice={dict.item.hidePrice}
    //     />
    //   );
    // });

    let barcodes = this.state.barcodes.length > 0 && (
      <BarcodeModal
        barcodes={this.state.barcodes}
        // barcode={dict.barcode}
        // key={`${dict.key}_${index}`}
        unmount={this.unmount}
        // barcodeKey={dict.key}
        // name={dict.item.sku.product_name_us}
        // size={dict.item.sku.us_size}
        // color={dict.item.sku.color}
        // style={dict.item.sku.style}
        // price={dict.item.consignment.price.default_price / 100}
        // hidePrice={dict.item.hidePrice}
      />
    );

    let locations = inventory.locations.map((location, index) => {
      return (
        <div
          className={css(styles.location, index % 2 === 1 && styles.separate)}
          key={`location_${location.id}_addproductmodal`}
          onClick={() => this.checkInInventory(location.id)}
        >
          {location.name}
        </div>
      );
    });

    const { isEbay } = this.state;

    return (
      <BaseModal
        isOpen={modals.openAddConsignmentModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        setModalRef={this.setModalRef}
        id={"addConsignment"}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddConsignmentModal"
      >
        <div className={css(styles.consignmentModalContainer)}>
          {this.state.page === 0 ? (
            <div className={css(styles.chooseStoreBody)}>
              <h2>
                {" "}
                {intl.formatMessage({
                  id: "which_location",
                  defaultMessage: "Which Location are these products going to?"
                })}{" "}
              </h2>
              <div className={css(styles.allLocations)}>{locations}</div>
            </div>
          ) : this.state.page === 1 ? (
            <form
              className={css(styles.container)}
              onSubmit={this.handleUserButton}
              autoComplete="off"
              id="consignerInformation"
            >
              <div className={css(styles.instructions)}>
                <span className={css(styles.title)}>
                  {intl.formatMessage({
                    id: "consigner_info",
                    defaultMessage: "Consigner information"
                  })}
                </span>
                <div className={css(styles.description)}>
                  {intl.formatMessage({
                    id: "search_by_consigner_email",
                    defaultMessage:
                      "Search by consigner email to find the associated consigner account. If the account does not exist, enter in the requested information to begin account creation."
                  })}
                </div>
              </div>
              <div className={css(styles.emailInputContainer)}>
                <Input
                  className={[styles.userInputs, styles.emailInput]}
                  value={this.state.selectedUser.email}
                  onChange={e => this.searchForEmails(e.target.value)}
                  placeholder={intl.formatMessage({
                    id: "profile_email",
                    defaultMessage: "Email"
                  })}
                  required
                  autoComplete="no"
                />
                {this.state.users &&
                this.state.selectedUser.email &&
                this.state.openDropdown ? (
                  <div className={css(styles.dropdown)}>
                    {users}
                    <div
                      className={css(styles.dropdownItem)}
                      onClick={this.onRegisterClick}
                    >
                      {intl.formatMessage({
                        id: "register_new_account",
                        defaultMessage: "Register new account"
                      })}
                    </div>
                  </div>
                ) : null}
              </div>
              <Input
                className={styles.userInputs}
                value={this.state.selectedUser.first_name}
                onChange={e => this.onUserInfoChange(e, "first_name")}
                placeholder={intl.formatMessage({
                  id: "profile_first_name",
                  defaultMessage: "First Name"
                })}
                required
              />
              <Input
                className={styles.userInputs}
                value={this.state.selectedUser.last_name}
                onChange={e => this.onUserInfoChange(e, "last_name")}
                placeholder={intl.formatMessage({
                  id: "profile_last_name",
                  defaultMessage: "Last Name"
                })}
                required
              />
              <InputMask
                className={css(styles.userInputs, styles.inputMask)}
                value={this.state.selectedUser.phone_number}
                mask={
                  this.state.selectedUser.phone_number &&
                  this.state.selectedUser.phone_number.length === 11
                    ? "+? (999) 999-9999"
                    : this.state.selectedUser.phone_number &&
                      this.state.selectedUser.phone_number.length === 10
                    ? "(999) 999-9999"
                    : this.state.location === 6
                    ? "+99 99 9999 99999"
                    : "(999) 999-9999"
                }
                formatChars={{
                  "9": "[0-9]",
                  "?": "[0-9]"
                }}
                onChange={e => this.onUserInfoChange(e, "phone_number")}
                placeholder={intl.formatMessage({
                  id: "profile_phone_number",
                  defaultMessage: "Phone Number"
                })}
              />
              <Button
                text={
                  this.state.newAccount
                    ? intl.formatMessage({
                        id: "register_account",
                        defaultMessage: "Register Account"
                      })
                    : intl.formatMessage({
                        id: "continue",
                        defaultMessage: "Continue"
                      })
                }
              />
            </form>
          ) : this.state.page === 2 ? (
            <div className={css(styles.container)}>
              <div className={css(styles.search)}>
                <div className={css(styles.instructions)}>
                  <span className={css(styles.title)}>
                    {intl.formatMessage({
                      id: "existing_products_search",
                      defaultMessage: "Search Existing Products"
                    })}
                  </span>
                  <div className={css(styles.description)}>
                    {intl.formatMessage({
                      id: "existing_products_message",
                      defaultMessage:
                        "Search for existing products in inventory and associate the product you're adding with the correct style or if it can't be found create a new one."
                    })}
                  </div>
                </div>
                <div className={css(styles.inputContainer)} id="searchExisting">
                  <Input
                    className={styles.input}
                    containerClassName={styles.inputWrapper}
                    placeholder={intl.formatMessage({
                      id: "existing_products_input",
                      defaultMessage: "Search by product name or style code"
                    })}
                    onChange={this.onSearchChange}
                    autoFocus
                  />
                </div>
                <div className={css(styles.labels)}>
                  <span className={css(styles.column)}>
                    {intl.formatMessage({
                      id: "picture",
                      defaultMessage: "Picture"
                    })}
                  </span>
                  <span className={css(styles.column)}>
                    {intl.formatMessage({
                      id: "product_name",
                      defaultMessage: "Product Name"
                    })}
                  </span>
                  <span className={css(styles.column)}>
                    {intl.formatMessage({
                      id: "style_code",
                      defaultMessage: "Style Code"
                    })}
                  </span>
                </div>
                <div className={css(styles.productStyles)}>
                  <ReactPlaceholder
                    ready={!this.state.searching}
                    customPlaceholder={<SearchPlaceholder />}
                  >
                    {productStyles.length > 0
                      ? productStyles
                      : this.state.search &&
                        !this.state.searching && (
                          <div className={css(styles.noProductsFound)}>
                            {intl.formatMessage({
                              id: "no_product_found",
                              defaultMessage: "No Products Found"
                            })}
                          </div>
                        )}
                  </ReactPlaceholder>
                </div>
              </div>
              {/* {["Admin", "Warehouse Manager", "Store Supermanager"].includes(
                auth.userProfile.role
              ) && (
                <Button
                  className={styles.button}
                  text={intl.formatMessage({
                    id: "add_new_style",
                    defaultMessage: "Add new style"
                  })}
                  id="addNewStyle"
                  onClick={this.addNewStyle}
                />
              )} */}
            </div>
          ) : this.state.page === 3 ? (
            <div>
              <div onClick={this.back} className={css(styles.icon)}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>

              <ConsignmentFields
                form={this.state.form}
                upcCodes={this.state.upcCodes}
                instructions={intl.formatMessage({
                  id: "consignment_instructions",
                  defaultMessage:
                    "Enter in as much product information as possible"
                })}
                buttonText={
                  this.state.updatingConsignment ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.iconSpinner)}
                      spin
                    />
                  ) : (
                    intl.formatMessage({
                      id: "add_consignment",
                      defaultMessage: "Add Consignment"
                    })
                  )
                }
                onFormInputChange={this.onFormInputChange}
                onSizeChange={this.onSizeChange}
                onTypeChange={this.onTypeChange}
                onDefectClick={this.onDefectClick}
                onPackageClick={this.onPackageClick}
                onPantsChange={this.onPantsChange}
                id="consignmentInformation"
                onButtonClick={this.createConsignment}
                listOnShopify={this.state.listOnShopify}
                // onPicturesChange={this.onPicturesChange}
                listOnEbay={this.state.listOnEbay || isEbay}
                isEbay={isEbay}
                addItem
                addConsignment
                changeable
              />
            </div>
          ) : this.state.page === 4 ? (
            <div className={css(styles.container)}>
              <div className={css(styles.instructions)}>
                <span className={css(styles.title)}>
                  {intl.formatMessage({
                    id: "consignment_order",
                    defaultMessage: "Consignment Order"
                  })}
                </span>
                <div className={css(styles.description)}>
                  {intl.formatMessage({
                    id: "consignment_order_description",
                    defaultMessage:
                      "These are the items that are currently added to the consignment order, add more items or continue if complete."
                  })}
                </div>
              </div>
              <div className={css(styles.consignmentsWrapper)}>
                {consignments}
              </div>
              <div className={css(styles.consignmentOrderButtons)}>
                <Button
                  text={intl.formatMessage({
                    id: "add_more_items",
                    defaultMessage: "Add more items"
                  })}
                  className={styles.consignmentOrderButton}
                  onClick={() => this.setState({ page: 2 })}
                  disabled={this.state.created}
                />
                <Button
                  text={
                    this.state.createLoading ? (
                      <FontAwesomeIcon
                        icon={["far", "spinner-third"]}
                        className={css(styles.iconSpinner)}
                        spin
                      />
                    ) : (
                      intl.formatMessage({
                        id: "create_consignment_order",
                        defaultMessage: this.state.created
                          ? "Created!"
                          : "Create consignment order"
                      })
                    )
                  }
                  className={styles.consignmentOrderButton}
                  onClick={
                    this.state.createLoading
                      ? () => {
                          return;
                        }
                      : this.createConsignmentOrder
                  }
                  disabled={this.state.created}
                />
              </div>
            </div>
          ) : (
            <div>
              <div onClick={this.back} className={css(styles.icon)}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              <ConsignmentFields
                form={this.state.form}
                instructions={intl.formatMessage({
                  id: "consignment_instructions",
                  defaultMessage:
                    "Enter in as much product information as possible"
                })}
                buttonText={
                  this.state.updatingConsignment ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      className={css(styles.iconSpinner)}
                      spin
                    />
                  ) : (
                    intl.formatMessage({
                      id: "update_consignment",
                      defaultMessage: "Update Consignment"
                    })
                  )
                }
                onFormInputChange={this.onFormInputChange}
                onSizeChange={this.onSizeChange}
                onTypeChange={this.onTypeChange}
                onDefectClick={this.onDefectClick}
                onPackageClick={this.onPackageClick}
                onPantsChange={this.onPantsChange}
                id="consignmentInformation"
                onButtonClick={this.updateConsignment}
                listOnShopify={this.state.listOnShopify}
                // onPicturesChange={this.onPicturesChange}
                listOnEbay={this.state.listOnEbay || isEbay}
                isEbay={isEbay}
                addConsignment
                addItem
                changeable
              />
            </div>
          )}
        </div>
        {barcodes}
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  modal: {
    height: "90%",
    maxWidth: 900,
    position: "absolute"
  },
  consignmentModalContainer: {
    height: "100%",
    width: "100%"
  },
  bodyContainer: {
    height: "100%",
    maxHeight: "unset",
    alignItems: "unset",
    justifyContent: "unset"
  },
  formContainer: {
    width: "100%",
    overflow: "scroll"
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #aaa"
  },
  inputWrapper: {
    margin: 16
  },
  input: {
    height: 50,

    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.RED(1)}`
    }
  },
  search: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  reprintButton: {
    width: 110
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  chooseStoreBody: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  allLocations: {
    width: "80%",
    maxWidth: 700,
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  location: {
    width: "45%",
    border: "1px solid",
    borderRadius: 4,
    height: 80,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 18,
    marginBottom: 32,
    cursor: "pointer",

    ":hover": {
      background: colors.RED(1),
      borderColor: colors.RED(1),
      color: "#fff"
    }
  },
  separate: {
    marginLeft: "auto"
  },

  instructions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    textAlign: "center",
    paddingTop: 0,
    borderBottom: "1px solid #aaa"
    // marginBottom: 20,
  },
  title: {
    fontSize: 22
  },
  description: {
    marginTop: 8,
    fontSize: 14,
    width: "80%",
    opacity: 0.7
  },
  picture: {
    width: 100,
    height: 100
  },
  productStyle: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
    height: 100,
    ":hover": {
      background: "#eee"
    }
  },
  error: {
    textAlign: "center"
  },
  errorBlock: {
    marginBottom: 12
  },
  noProductsFound: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  productStyles: {
    width: "100%",
    overflow: "scroll",
    border: "1px solid #ddd",
    borderRadius: 4,
    flex: 1,
    marginBottom: 40
  },
  button: {
    width: "100%"
  },
  labels: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 20
  },
  column: {
    width: "32%",
    display: "flex",
    justifyContent: "center"
  },
  label: {
    fontWeight: "bold",
    letterSpacing: 0.7,
    marginBottom: 8
  },
  formBody: {
    marginTop: 16
  },
  formInputContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 16
  },
  dropdownMenu: {
    border: "1px solid #ddd",
    borderTop: "none"
  },
  menuItem: {
    padding: 10
  },
  size: {
    border: "1px solid #000",
    width: 50,
    height: 50,
    marginRight: 10,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#000",

    ":hover": {
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`,
      background: colors.RED(1)
    }
  },
  sizes: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: 20,
    boxSizing: "border-box"
  },
  selected: {
    color: "#fff",
    border: `1px solid ${colors.RED(1)}`,
    background: colors.RED(1)
  },
  type: {
    width: 100,
    height: 40,
    border: "1px solid #000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    marginBottom: 10,
    cursor: "pointer",

    ":hover": {
      opacity: 0.6
    }
  },
  productTypes: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  message: {
    opacity: 0.7,
    textAlign: "center",
    fontSize: 20,
    letterSpacing: 1,
    padding: 20,
    width: "100%"
  },
  priceInput: {
    width: "100%",
    marginRight: 16,
    paddingLeft: 38,
    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.RED(1)}`
    }
  },
  currencySelector: {
    width: "100%"
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    padding: "20px 28px",
    position: "relative"
  },
  dollarSign: {
    position: "absolute",
    left: 48
  },
  placeholderContainer: {
    width: 500,
    height: 500
  },
  rowPlaceholder: {
    width: 100,
    height: 100,
    display: "flex",
    justifyContent: "space-between"
  },
  imagePlaceholder: {
    width: 100,
    height: 100
  },
  infoPlaceholder: {
    width: 100
  },
  consignmentConditions: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  consignmentCondition: {
    padding: 10,
    border: "1px solid #ddd",
    margin: 8,
    width: 220,
    height: 85,
    cursor: "pointer",

    ":hover": {
      background: colors.RED(1),
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`
    }
  },
  conditionTitle: {
    fontSize: 20,
    fontWeight: "bold"
  },
  conditionText: {
    fontSize: 14
  },
  packageConditions: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  packageCondition: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ddd",
    margin: 8,
    width: 220,
    height: 50,
    cursor: "pointer",

    ":hover": {
      background: colors.RED(1),
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`
    }
  },
  userInputs: {
    marginBottom: 20,
    height: 38,
    fontSize: 16,
    background: "#fff",
    border: "1px solid rgb(204, 204, 204)"
  },
  inputMask: {
    width: "100%",
    borderRadius: 4,
    outline: "none",
    padding: 16,
    fontSize: 16,
    boxSizing: "border-box",
    border: "1px solid rgb(204, 204, 204)",

    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.LIGHT_BLUE(1)}`
    },

    "::placeholder": {
      opacity: 0.5
    }
  },
  emailInputContainer: {
    marginTop: 20,
    marginBottom: 20,
    position: "relative"
  },
  emailInput: {
    marginBottom: 0
  },
  dropdownItem: {
    padding: 10,
    paddingLeft: 20,
    cursor: "pointer",

    ":hover": {
      background: "rgb(204, 204, 204)"
    }
  },
  dropdown: {
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: 4,
    zIndex: 2,
    position: "fixed",
    background: "#fff",
    width: "calc(100% - 51px)"
  },
  consignment: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: "1px solid #ddd"
  },
  consignmentInfo: {
    fontSize: 14,
    opacity: 0.7
  },
  consignmentSection: {
    margin: "0px 30px"
  },
  consignmentOrderButtons: {
    width: "calc(100% - 50px)",
    display: "flex"
  },
  consignmentOrderButton: {
    margin: 10
  },
  consignmentsWrapper: {
    height: "70%",
    overflow: "auto"
  },
  icon: {
    cursor: "pointer",
    marginRight: 10
  }
});
const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth,
  joyride: state.joyride,
  inventory: state.inventory
});
const mapDispatchToProps = dispatch => ({
  authActions: bindActionCreators(AuthActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch),
  joyrideActions: bindActionCreators(JoyrideActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddConsignmentModal)
);
