/***
 * Specific order modal
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { List } from "react-content-loader";
import { FormattedMessage, injectIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

// Components
import Button from "../../components/Button";
import ShippingLabelModal from "../../components/modals/ShippingLabelModal";
import PackingSlipModal from "../../components/modals/PackingSlipModal";
import BaseModal from "../../components/modals/BaseModal";
import CheckItemModal from "../../components/modals/CheckItemModal";
import OrderDetails from "../../components/orders/OrderDetails";

// Redux
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import ChooseShippingLabel from "./ChooseShippingLabel";
import Input from "../../components/Input";

class SpecificOrder extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      receivedOrder: false,
      shippingAddress: null,
      order: [],
      editAddress: false,
      fulfillmentStatus: "Unfulfilled",
      shippingLabelUrl: "",
      printShippingLabel: false,
      check: {
        count: 0
      },
      scanItem: {},
      items: [],
      risks: [],
      openPackingSlip: false,
      updateTracking: false,
      trackingCompany: "",
      trackingNumber: "",
      trackingUrl: ""
    };

    this.state = {
      ...this.initialState
    };
  }

  /**
   * Syncs with shipstation to get the address verification.
   */
  syncWithShipstation = () => {
    let { messageActions } = this.props;
    messageActions.showMessage({ show: true, load: true });
    return fetch(
      API.SYNC_WITH_SHIPSTATION({
        fulfillment_id: this.props.selectedFulfillmentId
      }),
      API.POST_CONFIG({})
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        messageActions.showMessage({ show: false });
        let shippingAddress = res.shipping_address;
        console.log("sync with shipstation", res);
        this.setState({
          shippingAddress
        });
      });
  };

  getOrder = () => {
    return fetch(
      API.ORDERS({
        orderId: this.props.orderId,
        location: this.props.location
      }),
      API.GET_CONFIG(false)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let fulfillments = [];
        for (let i = 0; i < res.order.length; i++) {
          if (
            parseInt(this.props.location, 10) ===
            res.order[i][0].inventory.location.id
          ) {
            fulfillments = fulfillments.concat(res.order[i]);
          }
        }
        let checks = { count: 0 };
        for (let i = 0; i < fulfillments.length; i++) {
          if (fulfillments[i].fulfillment.status === "scanned") {
            checks[i] = true;
          } else {
            checks[i] = false;
          }
          checks.count++;
        }
        this.setState({ check: checks });
        // let otherFulfillments = [];

        // for (let i = 0; i < fulfillments.length; i++) {
        //   if (
        //     parseInt(this.props.location, 10) !==
        //     fulfillments[i].inventory.location.id
        //   ) {
        //     otherFulfillments = otherFulfillments.concat(
        //       fulfillments.splice(i, 1)
        //     );
        //   }
        // }
        console.log("get order", res);

        this.setState({
          receivedOrder: true,
          order: res.order[0][0].order,
          shippingAddress: res.order[0][0].order.shipping_address,
          items: fulfillments,
          // otherItems: otherFulfillments,
          total: res.order_total,
          ordered_at: res.ordered_at,
          fulfillmentStatus: res.fulfillmentStatus
        });
      });
  };

  removeItemFromOrder = (item, index) => {
    const config = API.PATCH_CONFIG({});
    return fetch(API.ORDER_ITEM_UNAVAILABLE({ orderId: item.id }), config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(() => {
        let items = [...this.state.items];
        items[index] = { ...item, inventory_status: "unavailable" };
        this.setState({ items });
        let availableItems = items.filter(
          item => item.inventory_status !== "unavailable"
        );
        if (availableItems.length === 0) {
          this.props.updateTableData();
        }
      });
  };

  /**
   * mark fulfillment as fulfilled on backend
   * @param  {Object} fulfillment -- fulfilment object to mark as fulfilled
   * @param  {Integer} orderListIndex -- index of fulfillment
   */
  markAsFulfilled = (item, orderListIndex) => {
    const { fulfillment, inventory_status } = item;
    if (inventory_status === "unavailable" || fulfillment.status === "refund") {
      // Don't mark as fulfilled if the item has been marked as out of stock or refund
      console.log(`Skipping ${item.id}`);
      return;
    }

    let params = {
      ...fulfillment,
      from_location: fulfillment.from_location
        ? fulfillment.from_location.id
        : null,
      status: "fulfilled"
    };
    let config = API.PUT_CONFIG(params);
    return fetch(API.FULFILLMENT({ fulfillment_id: fulfillment.id }), config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(resp => {
        this.props.markAsFulfilled();
      });
  };

  /**
   * create shipping label for fulfillment
   * @param  {Integer} fulfillmentId -- id of fulfillment
   */
  createShippingLabel = fulfillmentId => {
    let { messageActions } = this.props;
    let config = API.POST_CONFIG();
    messageActions.showMessage({ show: true, load: true });
    return fetch(
      API.CREATE_SHIPPING_LABEL({ fulfillment_id: fulfillmentId }),
      config
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(resp => {
        const items = this.state.items;
        for (let i = 0; i < this.state.items.length; i++) {
          items[i].fulfillment = resp;
        }

        this.setState({
          items,
          fulfillmentStatus: true
        });
        this.props.markAsFulfilled();
        this.printShippingLabel(resp);
        messageActions.showMessage({ show: false, load: false });
      });
  };

  /**
   * print shipping label
   * @param  {Object} fulfillment -- fulfillment object
   */
  printShippingLabel = fulfillment => {
    this.setState({
      printShippingLabel: true,
      shippingLabelUrl: fulfillment.label_link
    });
  };

  /**
   * close shipping modal after new window closes
   */
  closeShippingLabelModal = () => {
    this.setState({
      printShippingLabel: false
    });
  };

  closePackingSlipModal = () => {
    this.setState({
      openPackingSlip: false
    });
  };

  /**
   * Gets the risks
   */
  getRisks = () => {
    let rest = {
      id: this.state.order.id,
      route: "risks"
    };
    return fetch(API.TRUE_ORDERS({ rest }), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          risks: res.risks
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  onAfterOpen = async () => {
    this.setState({
      receivedOrder: false
    });
    await this.getOrder();
    this.getRisks();
    if (this.state.shippingAddress) {
      this.syncWithShipstation();
    }
  };

  scanItem = (item, index) => {
    let { modalActions } = this.props;
    this.setState({
      scanItem: item,
      scanItemIndex: index
    });

    modalActions.openCheckItemModal(true);
  };

  closeModal = () => {
    let { modalActions, messageActions } = this.props;
    messageActions.showMessage({ show: false });
    modalActions.openSpecificOrderModal(false);
    this.setState({
      ...this.initialState
    });
  };

  setFulfillments = fulfillment => {
    let items = [...this.state.items];
    for (let i = 0; i < this.state.items.length; i++) {
      items[i].fulfillment = fulfillment;
    }

    this.setState({
      items
    });
  };

  resetShippingLabel = async () => {
    let { messageActions } = this.props;
    let params = {
      status: "unfulfilled",
      label_link: "",
      shipstation_order_id: "",
      shipping_cost: 0,
      shipstation_shipment_id: "",
      tracking_service: "",
      tracking_company: "",
      tracking_number: ""
    };
    let config = API.PATCH_CONFIG(params);
    messageActions.showMessage({ show: true, load: true });
    let promises = [];
    for (let i = 0; i < this.state.items.length; i++) {
      let fulfillment = this.state.items[i].fulfillment;

      if (
        fulfillment.from_location.id ===
        this.state.items[i].inventory.location.id
      ) {
        promises.push(
          fetch(API.FULFILLMENT({ fulfillment_id: fulfillment.id }), config)
            .then(Helpers.checkStatus)
            .then(Helpers.parseJSON)
        );
      }
    }

    await Promise.all(promises);
    messageActions.showMessage({ show: false, load: false });
    let items = [...this.state.items];
    items[0].fulfillment.label_link = "";
    this.setState({
      items
    });
  };

  toggleUpdateTracking = async () => {
    this.setState({ updateTracking: !this.state.updateTracking });
  };

  updateTracking = async e => {
    e.stopPropagation();
    const { trackingCompany, trackingNumber, trackingUrl } = this.state;
    let params = { trackingNumber };
    if (trackingCompany) params.trackingCompany = trackingCompany.toLowerCase();
    if (trackingUrl) params.trackingUrl = trackingUrl;

    let promises = [];
    let fulfillmentId;
    for (let i = 0; i < this.state.items.length; i++) {
      let fulfillment = this.state.items[i].fulfillment;

      if (
        fulfillment.from_location.id ===
        this.state.items[i].inventory.location.id
      ) {
        promises.push(
          fetch(
            API.UPDATE_TRACKING({ fulfillment_id: fulfillment.id }),
            API.PUT_CONFIG(params)
          )
            .then(Helpers.checkStatus)
            .then(Helpers.parseJSON)
        );
        fulfillmentId = fulfillment.id;
      }
    }
    promises.push(
      fetch(
        API.POST_TRACKING({ fulfillment_id: fulfillmentId }),
        API.POST_CONFIG(params)
      )
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
    );

    await Promise.all(promises);
    this.setState({ updateTracking: false });
  };

  scanItemMatches = () => {
    let { messageActions } = this.props;

    let item = this.state.scanItem;
    const { fulfillment, inventory_status } = item;
    if (inventory_status === "unavailable") {
      return;
    }

    let params = {
      ...fulfillment,
      from_location: fulfillment.from_location
        ? fulfillment.from_location.id
        : null,
      status: "scanned"
    };
    let config = API.PUT_CONFIG(params);
    fetch(API.FULFILLMENT({ fulfillment_id: fulfillment.id }), config)
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(resp => {
        let check = { ...this.state.check };
        messageActions.showMessage({ show: true });

        check[this.state.scanItemIndex] = true;

        this.setState({
          check
        });
      });

    setTimeout(() => {
      messageActions.showMessage({ show: false });
      // modalActions.openCheckItemModal(false);
    }, 1000);
  };

  removeFromOrderButton = (item, index) => {
    const { intl } = this.props;

    if (item.inventory_status === "unavailable") {
      return (
        <Button
          className={[styles.button, styles.markOutOfStock, styles.outOfStock]}
          text={intl.formatMessage({
            id: "removed",
            defaultMessage: "Removed"
          })}
          onClick={() => {}}
        />
      );
    }

    if (
      item.fulfillment.status.toLocaleLowerCase() !== "unfulfilled" &&
      item.fulfillment.status.toLocaleLowerCase() !== "scanned"
    ) {
      return;
    }

    const label_created = _.some(this.state.items, item =>
      _.get(item, "fulfillment.tracking_number")
    );
    if (label_created) {
      return;
    }

    return (
      <Button
        className={[styles.button, styles.markOutOfStock]}
        text={intl.formatMessage({
          id: "remove_from_order",
          defaultMessage: "Remove from Order"
        })}
        onClick={() => {
          this.removeItemFromOrder(item, index);
        }}
        disabled={this.state.check[index]}
      />
    );
  };

  renderItems = (item, index) => {
    let { intl } = this.props;

    return (
      <div className={css(styles.orderBox)} key={`item_${index}`}>
        <div className={css(styles.itemsSection)}>
          {this.state.check[index] && (
            <FontAwesomeIcon
              className={css(styles.check)}
              icon={["far", "check-circle"]}
            />
          )}
          <span style={{ marginLeft: 8, fontWeight: "bold" }}>
            {item.fulfillment.from_location ? (
              item.fulfillment.from_location.name
            ) : (
              <FormattedMessage
                id="item_not_received_consigner"
                defaultMessage="Item not received from consigner yet"
              />
            )}
          </span>
          <span
            style={{
              marginLeft: 8,
              fontWeight: "bold",
              float: "right"
            }}
          >
            {index + 1}
          </span>

          <OrderDetails orderIndex={0} orderList={[]} order={item} />
        </div>
        <div className={css(styles.actionBar)}>
          {item.fulfillment.status.toLocaleLowerCase() === "refund" ? (
            <div>Refund</div>
          ) : (
            (item.fulfillment.status.toLocaleLowerCase() === "unfulfilled" ||
              item.fulfillment.status.toLocaleLowerCase() === "scanned") &&
            item.inventory_status !== "unavailable" && (
              <Button
                className={styles.button}
                text={intl.formatMessage({
                  id: "scan_item",
                  defaultMessage: "Scan Item"
                })}
                onClick={() => {
                  this.scanItem(item, index);
                }}
                disabled={
                  item.order.payment.status === "pending" ||
                  this.state.check[index]
                }
              />
            )
          )}
        </div>
      </div>
    );
  };

  renderShippingButtons = () => {
    let { intl } = this.props;
    if (this.state.items.length > 0) {
      let checkCount = 0;
      for (let i = 0; i < this.state.items.length; i++) {
        if (
          this.state.items[i].fulfillment.status.toLocaleLowerCase() ===
            "unfulfilled" &&
          this.state.items[i].inventory_status !== "unavailable"
        ) {
          checkCount += 1;
        }
      }
      if (!this.state.items[0].order.shipping_address) {
        return (
          <div style={{ textAlign: "center" }}>
            No Shipping Address Provided
          </div>
        );
      }
      if (
        this.state.items[0].fulfillment.label_link ||
        (this.state.check.count === checkCount &&
          this.state.items[0].fulfillment.label_link)
      ) {
        return (
          <div style={{ textAlign: "center" }}>
            <Button
              className={styles.shippingButton}
              text={intl.formatMessage({
                id: "print_shipping_label",
                defaultMessage: "Print Shipping Label"
              })}
              onClick={() => {
                this.printShippingLabel(this.state.items[0].fulfillment);
              }}
            />
            <Button
              className={[styles.shippingButton, styles.markAsFulfilled]}
              text={intl.formatMessage({
                id: "mark_as_fulfilled",
                defaultMessage: "Mark as Fulfilled"
              })}
              onClick={() => {
                for (let i = 0; i < this.state.items.length; i++) {
                  this.markAsFulfilled(this.state.items[i]);
                }
                this.closeModal();
              }}
            />
            <Button
              className={[styles.shippingButton, styles.resetShipping]}
              text={intl.formatMessage({
                id: "reset_shipping",
                defaultMessage: "Reset Shipping Label"
              })}
              onClick={() => {
                this.resetShippingLabel(this.state.items[0].fulfillment);
              }}
            />
            <Button
              className={styles.shippingButton}
              text={intl.formatMessage({
                id: "update_tracking",
                defaultMessage: "Update Tracking"
              })}
              onClick={this.toggleUpdateTracking}
            />
            <Button
              className={styles.shippingButton}
              text={intl.formatMessage({
                id: "packing_slip",
                defaultMessage: "Packing Slip"
              })}
              onClick={() => {
                // this.createShippingLabel(this.state.items[0].fulfillment.id);
                this.setState({ openPackingSlip: true });
              }}
              disabled={!this.state.shippingAddress}
            />
          </div>
        );
      } else if (
        this.state.check.count === this.state.items.length &&
        this.state.items[0].order.shipping_address.country
          .toLocaleLowerCase()
          .includes("china", "cn")
      ) {
        return (
          <div className={css(styles.bringToChina)}>
            <div className={css(styles.shipToChinaMessage)}>
              THIS ITEM IS SHIPPING TO CHINA, PLEASE BRING IT TO THE WAREHOUSE.
            </div>

            <Button
              className={styles.shippingButton}
              text={intl.formatMessage({
                id: "brought-to-warehouse",
                defaultMessage: "Brought To Warehouse"
              })}
              onClick={() => {
                for (let i = 0; i < this.state.items.length; i++) {
                  this.markAsFulfilled(this.state.items[i]);
                }
                this.closeModal();
              }}
            />
          </div>
        );
      } else if (
        (checkCount && this.state.check.count === checkCount) ||
        (this.state.items[0].fulfillment.status.toLocaleLowerCase() !== // TODO why are selecting the first item?
          "unfulfilled" &&
          this.state.items[0].inventory_status !== "unavailable") // do we want to show this button on fulfilled/refunded items?
      ) {
        return (
          <div style={{ textAlign: "center" }}>
            <Button
              className={[styles.shippingButton, styles.markAsFulfilled]}
              text={intl.formatMessage({
                id: "mark_as_fulfilled",
                defaultMessage: "Mark as Fulfilled"
              })}
              onClick={() => {
                for (let i = 0; i < this.state.items.length; i++) {
                  this.markAsFulfilled(this.state.items[i]);
                }
                this.closeModal();
              }}
            />
            <Button
              className={styles.shippingButton}
              text={intl.formatMessage({
                id: "choose_shipping_label",
                defaultMessage: "Choose Shipping Label"
              })}
              onClick={() => {
                // this.createShippingLabel(this.state.items[0].fulfillment.id);
                let { modalActions } = this.props;
                modalActions.chooseShippingModal(true);
              }}
              disabled={!this.state.shippingAddress}
            />
            <Button
              className={styles.shippingButton}
              text={intl.formatMessage({
                id: "update_tracking",
                defaultMessage: "Update Tracking"
              })}
              onClick={this.toggleUpdateTracking}
            />
            <Button
              className={styles.shippingButton}
              text={intl.formatMessage({
                id: "packing_slip",
                defaultMessage: "Packing Slip"
              })}
              onClick={() => {
                // this.createShippingLabel(this.state.items[0].fulfillment.id);
                this.setState({ openPackingSlip: true });
              }}
              disabled={!this.state.shippingAddress}
            />
          </div>
        );
      }
    }
    return null;
  };

  /**
   * Sets the state after change of address
   */
  addressChange = (e, type) => {
    let shippingAddress = JSON.parse(
      JSON.stringify({ ...this.state.shippingAddress })
    );
    shippingAddress[type] = e.target.value;
    this.setState({
      shippingAddress
    });
  };

  verificationStatus = verifiedStatus => {
    switch (verifiedStatus) {
      case "Address not yet validated":
        return (
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            color="orange"
            className={css(styles.verificationIcon)}
          />
        );
      case "Address validated successfully":
        return (
          <FontAwesomeIcon
            icon={["fas", "check-circle"]}
            color={"green"}
            className={css(styles.verificationIcon)}
          />
        );
      case "Address validation warning":
        return (
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            color={"orange"}
            className={css(styles.verificationIcon)}
          />
        );
      case "Address validation failed":
        return (
          <FontAwesomeIcon
            icon={["fas", "times-circle"]}
            color="red"
            className={css(styles.verificationIcon)}
          />
        );
      default:
        return null;
    }
  };

  renderRisks = () => {
    let msg = "";
    // let score = 0.0;
    let risks = this.state.risks;
    let cancel = false;
    let investigate = false;
    for (let i = 0; i < risks.length; i++) {
      msg =
        risks[i].message + ", Recommendation " + risks[i].recommendation + "\n";
      // score += parseFloat(risks[i].score);
      if (risks[i].recommendation === "cancel") {
        cancel = true;
      } else if (risks[i].recommendation === "investigate") {
        investigate = true;
      }
    }

    if (!cancel && !investigate) {
      return null;
    }
    return (
      <div className={css(styles.riskFactor, styles.trackingInfo)}>
        {msg.trim()}
        <span>
          <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            color={investigate ? "orange" : "red"}
            className={css(styles.verificationIcon)}
          />
        </span>
      </div>
    );
  };

  /**
   * Edits the customer address and hits shipstation to edit it too.
   */
  editCustomerAddress = e => {
    e.preventDefault();
    let { shippingAddress } = this.state;
    let rest = {
      id: shippingAddress.id,
      route: "edit_address"
    };

    let config = {
      ...this.state.shippingAddress,
      fulfillment_id: this.state.items[0].fulfillment.id
    };
    let { messageActions } = this.props;
    messageActions.showMessage({ show: true, load: true });
    return fetch(API.ADDRESS({ rest }), API.PATCH_CONFIG(config))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        messageActions.showMessage({ show: false });
        messageActions.setMessage("Address Updated!");
        messageActions.showMessage({ show: true });
        debugger;
        this.setState({
          editAddress: false,
          shippingAddress: res.shipping_address
        });
      });
  };

  render() {
    let { modals } = this.props;
    let items = this.state.items.map((item, index) => {
      return this.renderItems(item, index);
    });
    let shippingButtons = this.renderShippingButtons();
    let shippingAddress = this.state.shippingAddress;
    let fulfillment = this.state.items[0] && this.state.items[0].fulfillment;

    return (
      <BaseModal
        isOpen={modals.openSpecificOrderModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <div className={css(styles.specificOrder)}>
          <div className={css(styles.orderInfoWrapper)}>
            <div className={css(styles.orderTitle)}>
              <h3 className={css(styles.orderId)}>#{this.state.order.id}</h3>
              <span className={css(styles.timeTitle)}>
                {moment(this.state.ordered_at).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </span>
            </div>
            {this.state.order && (
              <div className={css(styles.orderTitle)}>
                <span className={css(styles.timeTitle)}>
                  Shopify Order: #
                  {this.state.order.shopify_order_number
                    ? this.state.order.shopify_order_number
                    : _.get(
                        this.state,
                        "order.fulfillment.shipstation_order_id"
                      )}
                </span>
                {this.state.order.payment && (
                  <>
                    <span className={css(styles.timeTitle)}>
                      Shipping Amount: $
                      {this.state.order.payment.shipping_amount / 100}
                    </span>
                    <span className={css(styles.timeTitle)}>
                      Tax Amount: ${this.state.order.payment.tax_amount / 100}
                    </span>
                    <span className={css(styles.timeTitle)}>
                      Selected Shipping Method:{" "}
                      {this.state.order.selected_shipping_method
                        ? this.state.order.selected_shipping_method
                        : "N/A"}
                    </span>
                  </>
                )}
              </div>
            )}
            {this.state.order && this.state.order.shopify_order_email && (
              <div className={css(styles.orderTitle)}>
                <span className={css(styles.timeTitle)}>
                  Customer email:&nbsp;
                  {this.state.order.shopify_order_email}
                </span>
              </div>
            )}
            {shippingAddress &&
              (!this.state.editAddress ? (
                <div className={css(styles.customer)}>
                  <div className={css(styles.pencil)}>
                    <FontAwesomeIcon
                      icon={["fas", "pencil"]}
                      onClick={() =>
                        this.setState({
                          editAddress: true
                        })
                      }
                    />
                  </div>
                  <div className={css(styles.name)}>
                    {shippingAddress.first_name +
                      " " +
                      shippingAddress.last_name}
                  </div>
                  <div className={css(styles.name)}>
                    {shippingAddress.address_one} {shippingAddress.address_two}
                  </div>
                  <div className={css(styles.name)}>
                    {[
                      shippingAddress.city +
                        " " +
                        (shippingAddress.state ? shippingAddress.state : ""),
                      shippingAddress.country,
                      shippingAddress.zip_code
                    ]
                      .filter(el => el && el.trim() !== "")
                      .join(", ")}
                  </div>
                  <div className={css(styles.verificationStatus)}>
                    {shippingAddress.verified_status}
                    <span className={css(styles.verificationIcon)}>
                      {this.verificationStatus(shippingAddress.verified_status)}
                    </span>
                  </div>
                </div>
              ) : (
                <form
                  className={css(styles.customer)}
                  onSubmit={this.editCustomerAddress}
                >
                  <div className={css(styles.label)}>Name</div>
                  <div className={css(styles.inputSection, styles.inputRow)}>
                    <Input
                      value={this.state.shippingAddress.first_name}
                      onChange={e => this.addressChange(e, "first_name")}
                      placeholder="First Name"
                      containerClassName={styles.inputLeft}
                      required={true}
                    />
                    <Input
                      value={this.state.shippingAddress.last_name}
                      onChange={e => this.addressChange(e, "last_name")}
                      placeholder="Last Name"
                      required={true}
                    />
                  </div>
                  <div className={css(styles.inputSection)}>
                    <div className={css(styles.label)}>Address One</div>
                    <Input
                      value={this.state.shippingAddress.address_one}
                      onChange={e => this.addressChange(e, "address_one")}
                      placeholder="Address One"
                      required={true}
                      className={styles.input}
                    />
                    <div className={css(styles.label)}>Address Two</div>
                    <Input
                      value={this.state.shippingAddress.address_two}
                      onChange={e => this.addressChange(e, "address_two")}
                      placeholder="Address Two"
                      className={styles.input}
                    />
                  </div>
                  <div className={css(styles.inputSection)}>
                    <div className={css(styles.label)}>City</div>
                    <Input
                      value={this.state.shippingAddress.city}
                      onChange={e => this.addressChange(e, "city")}
                      placeholder="City"
                      required={true}
                      className={styles.input}
                    />
                    <div className={css(styles.label)}>State</div>
                    <Input
                      value={this.state.shippingAddress.state}
                      onChange={e => this.addressChange(e, "state")}
                      placeholder="State"
                      required={true}
                      className={styles.input}
                    />
                    <div className={css(styles.label)}>Country</div>
                    <Input
                      value={this.state.shippingAddress.country}
                      onChange={e => this.addressChange(e, "country")}
                      placeholder="Country"
                      required={true}
                      className={styles.input}
                    />
                    <div className={css(styles.label)}>Zip Code</div>
                    <Input
                      value={this.state.shippingAddress.zip_code}
                      onChange={e => this.addressChange(e, "zip_code")}
                      placeholder="Zip Code"
                      required={true}
                      className={styles.input}
                    />
                  </div>
                  <div className={css(styles.actions)}>
                    <button
                      className={css(styles.cancel)}
                      type={"button"}
                      onClick={() =>
                        this.setState({
                          editAddress: false
                        })
                      }
                    >
                      Cancel
                    </button>
                    <button className={css(styles.update)}>Update</button>
                  </div>
                </form>
              ))}
          </div>
          {fulfillment && fulfillment.tracking_number && (
            <div className={css(styles.trackingInfo)}>
              <div> Tracking Company: {fulfillment.tracking_company} </div>
              <div> Tracking Number: {fulfillment.tracking_number} </div>
              {fulfillment.confirmation && (
                <div> Confirmation: {fulfillment.confirmation} </div>
              )}
            </div>
          )}
          {this.state.risks &&
            this.state.risks.length > 0 &&
            this.renderRisks()}
          <div className={css(styles.orderInformation)}>
            {this.state.receivedOrder ? (
              items
            ) : (
              <div className={css(styles.orderBox, styles.placeholderBox)}>
                <List className={css(styles.listPlaceholder)} />
              </div>
            )}
            {shippingButtons}
            {this.state.updateTracking && (
              <form
                className={css(styles.customer)}
                onSubmit={this.updateTracking}
              >
                <div className={css(styles.inputSection)}>
                  <div className={css(styles.label)}>Tracking Company</div>
                  <Input
                    value={this.state.trackingCompany}
                    onChange={e =>
                      this.setState({ trackingCompany: e.target.value })
                    }
                    placeholder="Tracking Company"
                    containerClassName={styles.inputLeft}
                  />
                </div>
                <div className={css(styles.inputSection)}>
                  <div className={css(styles.label)}>Tracking Number</div>
                  <Input
                    value={this.state.trackingNumber}
                    onChange={e =>
                      this.setState({ trackingNumber: e.target.value })
                    }
                    placeholder="Tracking Number"
                    containerClassName={styles.inputLeft}
                  />
                </div>
                <div className={css(styles.inputSection)}>
                  <div className={css(styles.label)}>Tracking URL</div>
                  <Input
                    value={this.state.trackingUrl}
                    onChange={e =>
                      this.setState({ trackingUrl: e.target.value })
                    }
                    placeholder="Tracking URL"
                    containerClassName={styles.inputLeft}
                  />
                </div>
                <div className={css(styles.actions)}>
                  <button
                    className={css(styles.cancel)}
                    type="button"
                    onClick={() => this.setState({ updateTracking: false })}
                  >
                    Cancel
                  </button>
                  <button className={css(styles.update)}>Update</button>
                </div>
              </form>
            )}
            <div className={css(styles.customerInfo)}>
              <div className={css(styles.customer)} />
              <div className={css(styles.contactInfo)} />
              <div className={css(styles.shippingAddr)} />
              <div className={css(styles.billingAddr)} />
            </div>
          </div>
          {this.state.printShippingLabel && (
            <ShippingLabelModal
              url={this.state.shippingLabelUrl}
              closeShippingLabelModal={this.closeShippingLabelModal}
            />
          )}
          {(this.state.openPackingSlip || this.state.printShippingLabel) && (
            <PackingSlipModal
              shopify_order_id={this.state.order.shopify_order_number}
              address={this.state.shippingAddress}
              // created_at={}
              order_id={this.state.order.id}
              items={this.state.items}
              closePackingSlipModal={this.closePackingSlipModal}
            />
          )}
          <FontAwesomeIcon
            className={css(styles.close)}
            icon={["far", "times"]}
            size="2x"
            onClick={this.closeModal}
          />
          <CheckItemModal
            scanItem={this.state.scanItem}
            callback={this.scanItemMatches}
          />
          <ChooseShippingLabel
            markAsFulfilled={this.props.markAsFulfilled}
            location={this.props.location}
            setFulfillments={this.setFulfillments}
            fulfillment={this.state.items[0] && this.state.items[0].fulfillment}
            printShippingLabel={this.printShippingLabel}
            items={this.state.items}
            shopify_orderId={
              this.state.order.shopify_order_number
                ? this.state.order.shopify_order_number
                : _.get(this.state, "order.fulfillment.shipstation_order_id")
            }
            amount={
              this.state.order.payment &&
              this.state.order.payment.shipping_amount / 100
            }
            method={
              this.state.order.payment &&
              this.state.order.selected_shipping_method
                ? this.state.order.selected_shipping_method
                : "N/A"
            }
          />
        </div>
      </BaseModal>
    );
  }
}

var styles = StyleSheet.create({
  orderInfoWrapper: {
    margin: "0 auto",
    width: "100%",
    position: "sticky",
    top: 0,
    backgroundColor: "rgb(249, 249, 249)",
    paddingBottom: 20,
    zIndex: 999
  },
  orderTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 16
  },
  orderId: {
    fontSize: 30,
    margin: 0
  },
  inputRow: {
    display: "flex",
    marginBottom: 8
  },
  input: {
    marginBottom: 8
  },
  customer: {
    textAlign: "center",
    background: "#fff",
    padding: 16,
    maxWidth: 600,
    margin: "0 auto",
    marginBottom: 16,
    position: "relative"
  },
  pencil: {
    position: "absolute",
    right: 8,
    top: 8,
    padding: 8,
    fontSize: 20,
    cursor: "pointer"
  },
  riskFactor: {
    whiteSpace: "pre-wrap"
  },
  resetShipping: {
    marginLeft: 16,
    background: colors.LIGHT_BLUE(1),
    borderColor: colors.LIGHT_BLUE(1),

    ":hover": {
      background: colors.LIGHT_BLUE(0.8)
    }
  },
  timeTitle: {
    marginLeft: 8,
    marginRight: 8
  },
  bringToChina: {
    textAlign: "center"
  },
  shipToChinaMessage: {
    marginBottom: 14
  },
  label: {
    textAlign: "left",
    marginBottom: 8
  },
  trackingInfo: {
    padding: 16,
    textAlign: "center",
    background: "#fff",
    maxWidth: 600,
    margin: "0 auto",
    marginBottom: 16
  },
  specificOrder: {
    padding: 50,
    background: "rgb(249, 249, 249)",
    height: "max-content",
    width: "100%",
    color: "#212b36",
    boxSizing: "border-box"
  },
  listPlaceholder: {
    height: 200
  },
  orderBox: {
    borderRadius: 4,
    background: "#fff",
    maxWidth: 600,
    marginBottom: 24,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  inputLeft: {
    marginRight: 16
  },
  placeholderBox: {
    padding: 24
  },
  itemsSection: {
    padding: 24
  },
  price: {
    fontSize: 18
  },
  itemLine: {
    display: "flex",
    alignItems: "center",
    padding: 16,
    borderBottom: "1px solid #eee"
  },
  photo: {
    width: 75,
    height: 75,
    objectFit: "cover"
  },
  nameLine: {
    marginTop: 8,
    opacity: 0.7,
    fontSize: 14
  },
  orderLine: {
    marginRight: 80
  },
  noBorderBottom: {
    borderBottom: 0
  },
  actionBar: {
    display: "flex",
    justifyContent: "flex-end",
    padding: 16
  },
  button: {
    width: 200,
    ":disabled": {
      background: colors.RED(0.8)
    }
  },
  // markAsFulfilled: {
  //   marginRight: 8,
  //   background: "#fff",
  //   color: colors.RED(1),
  //   ":hover": {
  //     background: "#fff"
  //   }
  // },
  markFulfilled: {
    marginBottom: 0
  },
  verificationIcon: {
    marginLeft: 4
  },
  orderInformation: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  goBackLink: {
    color: "#000",
    textDecoration: "none"
  },
  modal: {
    maxWidth: "unset",
    maxHeight: "unset",
    width: "70%",
    zIndex: 999999,
    background: "rgb(249, 249, 249)"
  },
  close: {
    position: "absolute",
    top: 10,
    right: 10,
    cursor: "pointer"
  },
  bodyContainer: {
    padding: 0,
    display: "block",
    background: "rgb(249, 249, 249)",
    overflow: "auto"
  },
  check: {
    fontSize: 25,
    color: "green"
  },
  shippingButton: {
    marginBottom: 10,
    width: 200,
    marginRight: 10
  },
  actions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 16
  },
  cancel: {
    padding: "16px 32px",
    marginRight: 16,
    border: 0,
    background: "#fff",
    fontSize: 18
  },
  update: {
    background: colors.RED(1),
    color: "#fff",
    border: 0,
    padding: "16px 32px",
    fontSize: 18
  },
  markAsFulfilled: {
    marginLeft: 16,
    backgroundColor: colors.DARK_BLUE(1),
    borderColor: colors.DARK_BLUE(1),

    ":hover": {
      backgroundColor: colors.DARK_BLUE(0.8)
    },
    ":disabled": {
      background: colors.DARK_BLUE(0.8)
    }
  },
  markOutOfStock: {
    marginRight: 16
  },
  outOfStock: {
    backgroundColor: colors.GREY(1),
    borderColor: colors.GREY(1),
    ":hover": {
      backgroundColor: colors.GREY(0.8)
    }
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  modals: state.modals
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SpecificOrder));
