/***
 * Manual Input modal for adding new products
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
// eslint-disable-next-line
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";

// Components
import Input from "../Input";
import ErrorBlock from "../ErrorBlock";

// Config
import colors from "../../config/colors";

class ProductInfo extends React.Component {
  constructor(props) {
    super(props);

    let { intl } = this.props;

    this.fields = [
      {
        id: "item_type",
        label: intl.formatMessage({
          id: "product_type",
          defaultMessage: "Product Type"
        })
      },
      {
        id: "product_name_us",
        label: intl.formatMessage({
          id: "sku_product_name",
          defaultMessage: "Product Name"
        })
      },
      {
        id: "brand",
        label: intl.formatMessage({ id: "sku_brand", defaultMessage: "Brand" })
      },
      {
        id: "color",
        label: intl.formatMessage({ id: "sku_color", defaultMessage: "Color" })
      },
      {
        id: "style",
        label: intl.formatMessage({
          id: "sku_style",
          defaultMessage: "Style Number"
        })
      }
    ];

    this.initialState = {
      form: {}
    };
  }

  /**
   * input change for product information
   * @param  {EventObject} e  -- event object
   * @param  {String} id -- id string of input
   */
  onFormInputChange = (e, id) => {
    this.props.onFormInputChange(e, id);
  };

  render = () => {
    let { modals } = this.props;

    let fields = this.fields.map((field, index) => {
      // let required = true;
      // if (field.id === "style" || field.id === "barcode") {
      //   required = false;
      // }
      return (
        <div className={css(styles.formInputContainer)} key={`field_${index}`}>
          <label className={css(styles.label)}> {field.label} </label>
          <Input
            value={
              this.props.form && field.id === "brand"
                ? this.props.form.sku.product["brand"].name
                : this.props.form.sku[field.id]
            }
            onChange={e => this.onFormInputChange(e, field.id)}
            placeholder={field.label}
            // required={required}
            disabled={true}
          />
        </div>
      );
    });

    return (
      <div className={css(styles.formContainer)} id={this.props.id}>
        {modals.manualInputModal.error && (
          <ErrorBlock
            error={modals.manualInputModal.error}
            errorStyles={styles.errorBlock}
          />
        )}
        <form
          className={css(styles.formBody)}
          onSubmit={this.props.onButtonClick}
        >
          <div className={css(styles.form)}>{fields}</div>
        </form>
      </div>
    );
  };
}
var styles = StyleSheet.create({
  formContainer: {
    width: "100%",
    overflow: "scroll"
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    margin: "20px 0"
  },
  inputContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1px solid #aaa"
  },
  input: {
    margin: 16,
    height: 50,

    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.RED(1)}`
    }
  },
  search: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  instructions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
    textAlign: "center",
    paddingTop: 0,
    borderBottom: "1px solid #aaa"
    // marginBottom: 20,
  },
  title: {
    fontSize: 20
  },
  description: {
    marginTop: 8,
    fontSize: 14,
    width: "80%",
    opacity: 0.7
  },
  picture: {
    width: 100,
    height: 100
  },
  productStyle: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "space-around",
    paddingTop: 10,
    paddingBottom: 10,
    cursor: "pointer",
    height: 100,
    ":hover": {
      background: "#eee"
    }
  },
  error: {
    textAlign: "center"
  },
  errorBlock: {
    marginBottom: 12
  },
  noProductsFound: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  productStyles: {
    width: "100%",
    overflow: "scroll",
    border: "1px solid #ddd",
    borderRadius: 4,
    flex: 1,
    marginBottom: 20
  },
  button: {
    width: "100%",
    marginBottom: 20
  },
  labels: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    paddingTop: 20,
    paddingBottom: 20
  },
  column: {
    width: "32%",
    display: "flex",
    justifyContent: "center"
  },
  label: {
    fontWeight: "bold",
    letterSpacing: 0.7,
    marginBottom: 8
  },
  formBody: {
    marginTop: 16
  },
  formInputContainer: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 16
  },
  size: {
    border: "1px solid #000",
    width: 50,
    height: 50,
    marginRight: 10,
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "#000",

    ":hover": {
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`,
      background: colors.RED(1)
    }
  },
  sizes: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    padding: 20,
    boxSizing: "border-box"
  },
  selected: {
    color: "#fff",
    border: `1px solid ${colors.RED(1)}`,
    background: colors.RED(1)
  },
  type: {
    // width: 100,
    height: 40,
    border: "1px solid #000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    marginBottom: 10,
    cursor: "pointer",
    padding: "0px 16px",

    ":hover": {
      opacity: 0.6
    }
  },
  productTypes: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  message: {
    opacity: 0.7,
    textAlign: "center",
    fontSize: 20,
    letterSpacing: 1,
    padding: 20,
    width: "100%"
  },
  priceInput: {
    width: "100%",
    marginRight: 16,
    paddingLeft: 38,
    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.RED(1)}`
    }
  },
  priceContainer: {
    display: "flex",
    alignItems: "center",
    padding: "20px 28px",
    position: "relative"
  },
  showPriceContainer: {
    display: "flex",
    alignItems: "center",
    padding: "20px 28px",
    position: "relative"
  },
  dollarSign: {
    position: "absolute",
    left: 48
  },
  consignmentConditions: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  consignmentCondition: {
    padding: 10,
    border: "1px solid #ddd",
    margin: 8,
    width: 220,
    height: 85,
    cursor: "pointer",

    ":hover": {
      background: colors.RED(1),
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`
    }
  },
  conditionTitle: {
    fontSize: 20,
    fontWeight: "bold"
  },
  conditionText: {
    fontSize: 14
  },
  packageConditions: {
    display: "flex",
    flexWrap: "wrap",
    padding: 20
  },
  packageCondition: {
    padding: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #ddd",
    margin: 8,
    width: 220,
    height: 50,
    cursor: "pointer",

    ":hover": {
      background: colors.RED(1),
      color: "#fff",
      border: `1px solid ${colors.RED(1)}`
    }
  },
  pantsTitle: {
    fontWeight: "bold",
    fontSize: 18,
    marginLeft: 20
  },
  separator: {
    width: "100%",
    fontWeight: "bold",
    padding: 20,
    paddingBottom: 40
  }
});
const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth,
  localization: state.localization
});
const mapDispatchToProps = dispatch => ({});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductInfo)
);
