/***
 * Shipping Label Component
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import colors from "../../config/colors";

class ShippingLabelComponent extends React.Component {
  render() {
    return (
      <div
        className={css(
          styles.container,
          this.props.selected && styles.selected
        )}
      >
        <div className={css(styles.logo)}>{this.props.logo}</div>
        <div className={css(styles.information)}>
          <div className={css(styles.name)}>{this.props.name}</div>
        </div>
        <div className={css(styles.price)}>${this.props.price}</div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ddd",
    padding: 16,
    cursor: "pointer"
  },
  logo: {
    marginRight: 16
  },
  selected: {
    borderColor: colors.DARK_BLUE(1),
    background: colors.DARK_BLUE(1),
    color: "#fff"
  },
  price: {
    marginLeft: "auto",
    fontWeight: "bold"
  }
});

export default ShippingLabelComponent;
