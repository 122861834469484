import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import ImageUpload from "react-image-upload-grid";

// eslint-disable-next-line
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import

// Config
import colors from "../../../../config/colors";
import API from "../../../../config/api";
import Helpers from "../../../../redux/helpers";

// Actions
import { ModalActions } from "../../../../redux/modals";
import { MessageActions } from "../../../../redux/message";

// Component
import SKUTypeList from "./SKUTypeList";
import SKUFormInput from "./SKUFormInput";
import SKUFormDropdown from "./SKUFormDropdown";
import SKUProductDescription from "./SKUProductDescription";
import SKUVariant from "./SKUVariant";
import BaseModal from "../../BaseModal";

class SKUModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      product: {
        product_name_us: "",
        item_type: "",
        style: "",
        brand: "",
        color: "",
        weight: "",
        description: "",
        gender: ""
      },
      uploadedPhotos: {},
      pictures: [],
      skus: {},
      tags: [],
      brands: [],
      incompleteField: false
    };
  }

  componentDidUpdate() {
    let { modals, messageActions } = this.props;
    let { edit, id } = modals.skuModal;

    if (this.state.brands.length === 0) {
      this.loadBrands();
    }

    if (edit && !this.state.edit) {
      messageActions.showMessage({ show: true, load: true });
      return fetch(API.SKU({ product_id: id }), API.GET_CONFIG())
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          let skus = {};
          if (res.results.length) {
            res.results.forEach(sku => {
              skus[sku.us_size] = sku;
              //[sku.us_size] = sku.barcode;
            });
          }
          let tags = this.mapSKUsToTags(skus);

          let release_date =
            modals.skuModal.release_date &&
            moment(modals.skuModal.release_date).format("MM-DD-YYYY");

          this.setState(
            {
              edit,
              pictures: res.results[0] ? res.results[0].pictures : [],
              skus: skus ? skus : {},
              tags: tags ? tags : [],
              product: {
                ...modals.skuModal,
                release_date
              }
            },
            () => {
              document.getElementById("title").scrollIntoView(false);
              messageActions.showMessage({ show: false });
            }
          );
        });
    }
  }

  /***
   * Loads brands
   */
  loadBrands = async () => {
    return fetch(API.BRANDS, API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({ brands: res });
      })
      .catch(error => {
        console.error(error);
      });
  };

  addBrand = async name => {
    let { intl, messageActions } = this.props;
    let params = {
      name
    };
    return fetch(API.BRANDS, API.POST_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        console.log("add brand", res);
        let product = { ...this.state.product };
        product.brand = res;
        this.loadBrands();
        this.setState({ product });
      })
      .catch(error => {
        let message = intl.formatMessage({
          id: "brand duplicated",
          defaultMessage: "Brand Already Exists"
        });
        messageActions.showMessage({ show: false });
        messageActions.setMessage(message);
        messageActions.showMessage({
          show: true,
          load: false,
          error: true
        });
        console.error(error);
      });
  };

  /**
   * Confirms deleting the product
   * @param { String } productName -- the name of the product we want to delete
   * @param { Integer } id -- the id of the product we want to delete
   */
  confirmDeleteProduct = () => {
    let { modals } = this.props;
    let { id, product_name_us } = modals.skuModal;
    confirmAlert({
      title: `Delete ${product_name_us}`,
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.deleteProduct({ id });
          }
        },
        {
          label: "No",
          onClick: () => {
            return null;
          }
        }
      ]
    });
  };

  /**
   * Deletes the product by setting active to false on the product
   */
  deleteProduct = ({ id }) => {
    let params = {
      active: false
    };
    let { messageActions } = this.props;
    messageActions.showMessage({ show: true, load: true });
    return fetch(API.PRODUCT({ id }), API.PATCH_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(_ => {
        messageActions.showMessage({ show: false });
        this.props.refresh();
        this.closeModal();
      });
  };

  /**
   * closes the modal & resets state to default
   */
  closeModal = (sku = null) => {
    let { modalActions } = this.props;
    modalActions.openAddSKUModal(false, null, sku);

    this.setState({
      edit: false,
      product: {},
      uploadPhotos: {},
      pictures: [],
      skus: {},
      tags: [],
      incompleteField: false
    });
  };

  /**
   * create tag objects from state's sku object; enforces consistent information
   * @param { Object } skus - skus object changed by handlers
   */
  mapSKUsToTags = skus => {
    let order = ["O/S", "XS", "S", "M", "L", "XL", "XXL", "XXXL", "N/A"];
    if (Object.keys(skus).length > 0) {
      let type = skus[Object.keys(skus)[0]].item_type;
      let tags = [];
      let entries = Object.entries(skus);
      entries.forEach(tag => {
        tags.push({
          id: tag[1].us_size,
          name: tag[1].us_size,
          barcode: tag[1].barcode
        });
      });
      let sorted_tags =
        type === "shoe"
          ? tags.sort((a, b) => parseFloat(a.name) - parseFloat(b.name))
          : tags.sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));
      return sorted_tags;
    }
  };

  /**
   * adds a size and it's barcode to state's skus object, passed to child component
   * @param { String } size - size input by user
   * @param { String } barcode - barcode input by user
   */
  handleBarcode = (size, barcode) => {
    let skus = { ...this.state.skus };
    skus[size].barcode = barcode;
    let tags = this.mapSKUsToTags(skus);
    this.setState({ skus, tags });
  };

  /***
   * removes size variant from state's tags array and removes size and barcode from state's sku object
   * @param { Number } i - index of size variant
   */
  handleDelete = i => {
    let { messageActions, intl } = this.props;
    let skus = { ...this.state.skus },
      removedTag = this.state.tags[i];

    if (this.state.tags.length > 0) {
      let removed_id = skus[removedTag.id].id;
      messageActions.showMessage({ show: true, load: true });
      if (!removed_id) {
        delete skus[removedTag.id];
        let tags = this.mapSKUsToTags(skus);
        this.setState({ skus, tags }, () => {
          let message = intl.formatMessage({
            id: "sku_delete_success",
            defaultMessage: "Variant Removed"
          });
          messageActions.showMessage({ show: false });
          messageActions.setMessage(message);
          messageActions.showMessage({
            show: true,
            load: false
          });
        });
        return;
      }
      return fetch(API.SKU({ id: removed_id }), API.DELETE_CONFIG())
        .then(Helpers.checkStatus)
        .then(res => {
          delete skus[removedTag.id];
          let tags = this.mapSKUsToTags(skus);
          this.setState({ skus, tags }, () => {
            let message = intl.formatMessage({
              id: "sku_delete_success",
              defaultMessage: "Variant Removed"
            });
            messageActions.showMessage({ show: false });
            messageActions.setMessage(message);
            messageActions.showMessage({ show: true, load: false });
          });
        })
        .catch(error => {
          messageActions.showMessage({ show: false });
        });
    }
  };

  /***
   * adds tag to state's skus object and maps it into tags array
   * @param { Object } tag - variant size object
   */
  handleAddition = tag => {
    console.log("add");
    const skus = { ...this.state.skus };
    if (!skus[tag.id]) {
      skus[tag.id] = { us_size: tag.id, barcode: "" };
      let tags = this.mapSKUsToTags(skus);
      this.setState({ tags, skus });
    }
  };

  /**
   * handles 'item_type' & 'gender' inputs from SKUTypeList Component, passed to child component
   * @param { String } type_id - a value (eg. 'male', 'female', 'shoe', etc.)
   * @param { String } listType - category of type_id (eg. 'gender', 'product')
   */
  handleListType = (type_id, listType) => {
    let product = { ...this.state.product };
    if (listType === "product") {
      product.item_type = type_id;
      this.setState({ product, sku: {} });
    } else {
      product.gender = type_id;
      this.setState({ product });
    }
  };

  handleDropdown = value => {
    let product = { ...this.state.product };
    let new_brand = this.state.brands.find(brand => brand.id === value);
    product.brand = new_brand;
    this.setState({ product });
  };

  /**
   * handles the values from user input, passed to child component
   * @param { Object } e - onChange event object
   * @param { Boolean } uppercase -- whether the input needs to be forced uppercase or not
   */
  handleInputChange = (e, uppercase) => {
    let product = { ...this.state.product };
    let value = e.target.value;
    if (uppercase) {
      value = value.toUpperCase();
    }

    product[e.target.id] = value;

    if (e.target.id === "style") {
      product[e.target.id] = value.replace(/\s/g, "");
      this.setState({ product, incompleteField: false });
    }
    this.setState({ product });
  };

  /**
   * Gets the pictures in the right form for a nested create for the product
   * @param { Object } product -- the product which we're creating
   */
  setupProductPictures = product => {
    let allPictures = [...this.state.pictures];
    let pictures = [];
    for (let i = 0; i < allPictures.length; i++) {
      let file = allPictures[i];
      if (file.url && file.id) {
        file.index = i;
        pictures.push(file);
      } else {
        let uid = file.name + file.lastModified;
        let params = { url: this.state.uploadedPhotos[uid], index: i };
        pictures.push(params);
      }
    }

    product.pictures = pictures;
    return product;
  };

  /**
   * checks to make sure 'style number' isn't empty, submits information
   * @param { Object } e - onClick event object
   */
  submitForm = e => {
    e.preventDefault();
    let { messageActions, intl } = this.props;
    let product = { ...this.state.product };
    product.color = product.color ? product.color.toUpperCase() : "";
    product.product_name = product.product_name_us;
    product.release_date = product.release_date
      ? moment(product.release_date).format("YYYY-MM-DD")
      : null;

    product = this.setupProductPictures(product);
    product = this.formatDataBeforeSubmit(product);
    product.brand_id = product.brand ? product.brand.id : null;
    delete product.brand;
    if (!product.style) {
      let msg = intl.formatMessage({
        id: "style_not_inputted",
        defaultMessage: "Style is required"
      });
      messageActions.setMessage(msg);
      messageActions.showMessage({ show: true, error: true });
      this.setState({ incompleteField: true }, () => {
        document.getElementById("style").scrollIntoView(false);
        document.getElementById("style") &&
          document.getElementById("style").focus();
      });
    } else if (!product.brand_id) {
      let msg = intl.formatMessage({
        id: "brand_not_selected",
        defaultMessage: "Brand is required"
      });
      messageActions.setMessage(msg);
      messageActions.showMessage({ show: true, error: true });
    } else if (!product.item_type) {
      let msg = intl.formatMessage({
        id: "type_not_selected",
        defaultMessage: "Product type is required"
      });
      messageActions.setMessage(msg);
      messageActions.showMessage({ show: true, error: true });
    } else {
      let { messageActions } = this.props;
      messageActions.showMessage({ show: true, load: true });
      debugger;
      return fetch(API.PRODUCT({}), API.POST_CONFIG(product))
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          messageActions.showMessage({ show: false });
          messageActions.setMessage(
            intl.formatMessage({
              id: "success",
              defaultMessage: "Success"
            })
          );
          messageActions.showMessage({ show: true });
          this.closeModal(res);
          this.props.refresh && this.props.refresh();
        })
        .catch(err => {
          messageActions.showMessage({ show: false });
          let errMsg = intl.formatMessage({
            id: "something_wrong",
            defaultMessage: "Something went wrong!"
          });
          if (err.message.style) {
            errMsg = err.message.style.join(", ");
          } else if (err.message.detail) {
            errMsg = err.message.detail;
          }
          messageActions.setMessage(errMsg);
          messageActions.showMessage({ show: true, error: true });
        });
    }
  };

  /**
   * checks to make sure 'style number' isn't empty, submits information PUT REQUEST
   * @param { Object } e - onClick event object
   */
  updateForm = e => {
    e.preventDefault();
    let product = { ...this.state.product };
    let { messageActions, intl } = this.props;
    product.color = product.color ? product.color.toUpperCase() : "";
    product.product_name = product.product_name_us;
    product.release_date = product.release_date
      ? moment(product.release_date).format("YYYY-MM-DD")
      : null;

    product = this.setupProductPictures(product);
    product = this.formatDataBeforeSubmit(product);

    product.brand_id = product.brand ? product.brand.id : null;
    delete product.brand;

    if (!product.style) {
      let msg = intl.formatMessage({
        id: "style_not_inputted",
        defaultMessage: "Style is required"
      });
      messageActions.setMessage(msg);
      messageActions.showMessage({ show: true, error: true });
      this.setState({ incompleteField: true }, () => {
        document.getElementById("style").scrollIntoView(false);
        document.getElementById("style") &&
          document.getElementById("style").focus();
      });
    } else if (!product.brand_id) {
      let msg = intl.formatMessage({
        id: "brand_not_selected",
        defaultMessage: "Brand is required"
      });
      messageActions.setMessage(msg);
      messageActions.showMessage({ show: true, error: true });
    } else if (!product.item_type) {
      let msg = intl.formatMessage({
        id: "type_not_selected",
        defaultMessage: "Product type is required"
      });
      messageActions.setMessage(msg);
      messageActions.showMessage({ show: true, error: true });
    } else {
      let { messageActions, modals } = this.props;
      let { id } = modals.skuModal;
      product.id = id;
      messageActions.showMessage({ show: true, load: true });
      return fetch(API.PRODUCT({ id }), API.PUT_CONFIG(product))
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          messageActions.showMessage({ show: false });
          messageActions.setMessage(
            intl.formatMessage({
              id: "success",
              defaultMessage: "Success"
            })
          );
          messageActions.showMessage({ show: true });
          this.closeModal(res);
          this.props.refresh && this.props.refresh();
        })
        .catch(err => {
          messageActions.showMessage({ show: false });
          let errMsg = intl.formatMessage({
            id: "something_wrong",
            defaultMessage: "Something went wrong!"
          });
          if (err.message.style) {
            errMsg = err.message.style.join(", ");
          }
          messageActions.setMessage(errMsg);
          messageActions.showMessage({ show: true, error: true });
        });
    }
  };

  /**
   * takes the skus object in state, manipulates it to how the backend expects it
   * @param { Object } product - product object, copy of state's product
   */
  formatDataBeforeSubmit = product => {
    let sizeBarCode = Object.entries(this.state.skus);
    let skus = [];

    sizeBarCode.forEach(el => {
      var sku = {
        id: el[1].id,
        us_size: el[0],
        barcodes: [{ code: el[1].barcode }]
      };
      if ("barcodes" in el[1] && el[1].barcodes.length > 0) {
        sku.barcodes[0]["id"] = el[1].barcodes.sort((a, b) => {
          return a["code"] > b["code"] ? 1 : -1;
        })[0].id;
      }
      skus.push(sku);
    });

    product.skus = skus;
    return product;
  };

  /**
   * Set the images after sort
   */
  onSortEnd = pictures => {
    this.setState({
      pictures
    });
  };

  /**
   * Uploads photos to the backend, returns a list of URLS from s3
   */
  uploadPhotos = files => {
    let formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("pictures[]", files[i]);
    }
    formData.append("product_name_us", this.state.product.product_name_us);
    let { messageActions } = this.props;
    messageActions.showMessage({ show: true, load: true });
    return fetch(
      API.PICTURE({ upload_photos: true }),
      API.POST_FORM_DATA_CONFIG(formData)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let pictures = [...this.state.pictures, ...files];
        let uploadedPhotos = { ...this.state.uploadedPhotos };
        for (let i = 0; i < files.length; i++) {
          let file = files[i];
          let uid = file.name + file.lastModified;
          uploadedPhotos[uid] = res[i];
        }
        this.setState({
          uploadedPhotos,
          pictures
        });

        let { intl } = this.props;
        let message = intl.formatMessage({
          id: "photo_upload_success",
          defaultMessage: "Photos Uploaded!"
        });
        messageActions.showMessage({ show: false });
        messageActions.setMessage(message);
        messageActions.showMessage({ show: true });
      })
      .catch(error => {
        messageActions.showMessage({ show: false });
      });
  };

  /**
   * Removes photo from the backend, on success, removes the image from the state's pictures array
   * @param { Object } product - product object, copy of state's product
   */
  removePhotos = index => {
    let { intl, messageActions } = this.props;
    let pictures = [...this.state.pictures];
    let removed_id = pictures[index].id;

    messageActions.showMessage({ show: true, load: true });
    return fetch(API.PICTURE({ id: removed_id }), API.DELETE_CONFIG())
      .then(Helpers.checkStatus)
      .then(res => {
        pictures.splice(index, 1);
        this.setState({ pictures }, () => {
          let message = intl.formatMessage({
            id: "photo_delete_success",
            defaultMessage: "Photos Removed"
          });
          messageActions.showMessage({ show: false });
          messageActions.setMessage(message);
          messageActions.showMessage({ show: true, load: false });
        });
      })
      .catch(error => {
        messageActions.showMessage({ show: false });
      });
  };

  render() {
    let { edit, product, skus, tags, incompleteField, pictures } = this.state;
    let { modals, intl } = this.props;

    let {
      item_type,
      description,
      product_name_us,
      style,
      color,
      weight,
      gender,
      release_date,
      brand
    } = product;

    return (
      <BaseModal
        isOpen={modals.openAddSKUModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <div className={css(styles.body)}>
          <div className={css(styles.modalContainer)}>
            <span className={css(styles.closeBtn)} onClick={this.closeModal}>
              <p className={css(styles.btnText)}>X</p>
            </span>
            <div className={css(styles.modalContent)}>
              <div className={css(styles.titleWrapper)}>
                <button
                  className={css(styles.deleteProduct)}
                  onClick={this.confirmDeleteProduct}
                >
                  {intl.formatMessage({
                    id: "delete-product",
                    defaultMessage: "Delete Product"
                  })}
                </button>
                <h1 className={css(styles.title)} id="title">
                  {edit
                    ? intl.formatMessage({
                        id: "update_product",
                        defaultMessage: "Update Product"
                      })
                    : intl.formatMessage({
                        id: "add_product",
                        defaultMessage: "Add Product"
                      })}
                </h1>
                <h3 className={css(styles.description)}>
                  {intl.formatMessage({
                    id: "consignment_instructions",
                    defaultMessage:
                      "Enter in as much product information as possible"
                  })}
                </h3>
              </div>
              <div className={css(styles.page)}>
                <form onSubmit={edit ? this.updateForm : this.submitForm}>
                  <h2 className={css(styles.pageTitle)}>
                    {intl.formatMessage({
                      id: "product_info",
                      defaultMessage: "Product Info"
                    })}
                  </h2>
                  <div className={css(styles.row)}>
                    <SKUFormInput
                      id="product_name_us"
                      label={intl.formatMessage({
                        id: "sku_product_name",
                        defaultMessage: "Product Name"
                      })}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "sku_product_name",
                        defaultMessage: "Product Name"
                      })}
                      size="808px"
                      required={true}
                      value={product_name_us}
                      handleInputChange={this.handleInputChange}
                    />
                  </div>
                  <div className={css(styles.row)}>
                    <SKUFormInput
                      id="style"
                      label={intl.formatMessage({
                        id: "sku_style",
                        defaultMessage: "Style Number"
                      })}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "sku_style",
                        defaultMessage: "Style Number"
                      })}
                      size="393px"
                      value={style}
                      required={true}
                      incompleteField={incompleteField}
                      uppercase={true}
                      handleInputChange={this.handleInputChange}
                    />
                    {/* <SKUFormInput
                      id="brand"
                      label={intl.formatMessage({
                        id: "sku_brand",
                        defaultMessage: "Brand"
                      })}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "sku_brand",
                        defaultMessage: "Brand"
                      })}
                      size="393px"
                      value={brand}
                      required={true}
                      uppercase={true}
                      handleInputChange={this.handleInputChange}
                    /> */}
                    <SKUFormDropdown
                      id="brand"
                      label={intl.formatMessage({
                        id: "sku_brand",
                        defaultMessage: "Brand"
                      })}
                      placeholder={intl.formatMessage({
                        id: "sku_brand",
                        defaultMessage: "Brand"
                      })}
                      size="393px"
                      options={this.state.brands.map(brand => ({
                        label: brand.name,
                        value: brand.id
                      }))}
                      value={
                        brand && {
                          label: brand.name,
                          value: brand.id
                        }
                      }
                      handleDropdown={this.handleDropdown}
                      onCreateOption={this.addBrand}
                    />
                  </div>
                  <div className={css(styles.row)}>
                    <SKUFormInput
                      id="color"
                      label={intl.formatMessage({
                        id: "sku_color",
                        defaultMessage: "Color"
                      })}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "sku_color",
                        defaultMessage: "Color"
                      })}
                      size="393px"
                      value={color}
                      required={true}
                      uppercase={true}
                      handleInputChange={this.handleInputChange}
                    />
                    <SKUFormInput
                      id="weight"
                      label={intl.formatMessage({
                        id: "sku_weight",
                        defaultMessage: "Weight (lbs)"
                      })}
                      type="number"
                      placeholder={intl.formatMessage({
                        id: "weight",
                        defaultMessage: "Weight"
                      })}
                      size="393px"
                      value={weight}
                      required={true}
                      handleInputChange={this.handleInputChange}
                    />
                  </div>
                  <div className={css(styles.row)}>
                    <SKUFormInput
                      id="release_date"
                      label={intl.formatMessage({
                        id: "release-date",
                        defaultMessage: "Release Date (MM-DD-YYYY)"
                      })}
                      mask={"99-99-9999"}
                      type="text"
                      placeholder={intl.formatMessage({
                        id: "release-date",
                        defaultMessage: "Release Date"
                      })}
                      size="393px"
                      value={release_date}
                      required={false}
                      handleInputChange={this.handleInputChange}
                    />
                  </div>
                  <SKUTypeList
                    label={intl.formatMessage({
                      id: "sku_gender",
                      defaultMessage: "Gender"
                    })}
                    listType="gender"
                    gender={gender}
                    handleListType={this.handleListType}
                  />
                  <SKUTypeList
                    label={intl.formatMessage({
                      id: "product_type",
                      defaultMessage: "Product Type"
                    })}
                    listType="product"
                    item_type={item_type}
                    handleListType={this.handleListType}
                  />
                  <SKUProductDescription
                    description={description}
                    handleInputChange={this.handleInputChange}
                  />
                  <button className={css(styles.addSKUBtn)}>
                    <p className={css(styles.addSKUBtnTxt)}>
                      {edit
                        ? intl.formatMessage({
                            id: "update_product",
                            defaultMessage: `U P D A T E  P R O D U C T`
                          })
                        : intl.formatMessage({
                            id: "add_product",
                            defaultMessage: `A D D  P R O D U C T`
                          })}
                    </p>
                  </button>
                </form>
              </div>
              <div className={css(styles.imagesPage)}>
                <h2 className={css(styles.pageTitle)}>
                  {intl.formatMessage({
                    id: "images",
                    defaultMessage: "Images"
                  })}
                </h2>
                <ImageUpload
                  imageContainerClassName={css(styles.imageContainerClass)}
                  imageAddedCallback={this.uploadPhotos}
                  removeImageCallback={this.removePhotos}
                  images={pictures}
                  onSortEnd={this.onSortEnd}
                />
              </div>
              {edit && (
                <SKUVariant
                  type={item_type}
                  tags={tags}
                  skus={skus}
                  edit={edit}
                  handleAddition={this.handleAddition}
                  handleDelete={this.handleDelete}
                  handleBarcode={this.handleBarcode}
                />
              )}
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }
}

let styles = StyleSheet.create({
  titleWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 870,
    position: "relative",
    borderBottom: "1px solid black"
  },
  deleteProduct: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: 16,
    background: `${colors.RED(1)}`,
    color: "#fff",
    border: 0,
    padding: 12,
    fontSize: 15,
    cursor: "pointer",
    outline: "none"
  },
  title: {
    margin: 0
  },
  modal: {
    width: "100%",
    height: "100%",
    maxWidth: "unset"
  },
  bodyContainer: {
    width: "100%",
    height: "100%",
    padding: 0,
    maxHeight: "unset"
  },
  description: {
    marginTop: 10,
    fontSize: 15,
    fontWeight: 100
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    width: 840,
    margin: "0 0 20px 0"
  },
  body: {
    overflowY: "scroll",
    height: "100%"
  },
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100vw",
    padding: "40px 0px 100px 0px",
    overflowY: "scroll"
  },
  closeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: " #ffffff",
    height: 35,
    width: 35,
    borderRadius: "50%",
    boxShadow: "rgba(129,148,167,0.39) 0px 0px 5px 0px",
    position: "absolute",
    top: 20,
    right: 30,
    zIndex: 100,
    cursor: "pointer",
    transition: "all 0.2s ease-in",
    ":hover": {
      color: "black",
      transform: "scale(1.05)"
    }
  },
  imageContainerClass: {
    zIndex: 11
  },
  btnText: {
    margin: 0,
    padding: 0,
    fontWeight: 100
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: "#ffffff",
    height: 70,
    width: "100%"
  },
  modalContent: {
    width: 870,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative"
  },
  page: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderRadius: 3,
    padding: "0px 15px 25px 15px",
    marginBottom: 30,
    minHeight: 485,
    width: 840
  },
  pageTitle: {
    paddingLeft: 10,
    fontWeight: "bold"
  },
  imagesPage: {
    width: 840,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderRadius: 3,
    padding: "0px 15px 25px 15px",
    marginBottom: 30
  },
  addSKUBtn: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    left: 0,
    bottom: 0,
    width: "100vw",
    height: 60,
    borderRadius: 3,
    background: `${colors.RED(1)}`,
    border: `1px solid ${colors.RED(1)}`,
    boxShadow: `0px 0px 5px  ${colors.RED(1)}`,
    cursor: "pointer",
    zIndex: 100,
    ":hover": {}
  },
  addSKUBtnTxt: {
    margin: "auto",
    color: "white",
    whiteSpace: "pre-wrap",
    textShadow: `0px 0px 3px ${colors.PINK(1)}`
  }
});

const mapStateToProps = state => ({
  modals: state.modals
  // auth: state.auth,
});

const mapDispatchToProps = dispatch => ({
  // authActions: bindActionCreators(AuthActions, dispatch),
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SKUModal)
);
