/***
 * Pick location
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { RadioGroup, Radio } from "react-radio-group";

// Config
import colors from "../../config/colors";

export default class LocationPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: null
    };
  }

  /***
   * Change the selected location
   */
  handleChange = value => {
    this.setState({
      selectedValue: value
    });

    this.props.handleChange && this.props.handleChange(value);
  };

  render() {
    let locations = this.props.locations.map((location, index) => {
      // let sizes = this.props.styleByLocation[location.id];
      // if (sizes) {
      //   let sizeKeys = Object.keys(sizes)
      //   sizes = sizeKeys.map((key, index) => {
      //     return (
      //       <div className={css(styles.sizeContainer)}>
      //         <div className={css(styles.size)}>
      //           { key }
      //         </div>
      //         <span>
      //           x{sizes[key]}
      //         </span>
      //       </div>
      //     );
      //   });
      // }

      return (
        <div className={css(styles.locationContainer)}>
          <div
            className={css(
              styles.location,
              this.state.selectedValue === location.id && styles.activeLocation
            )}
            onClick={() => this.handleChange(location.id)}
          >
            <Radio value={location.id} />
            <div className={css(styles.locationInformation)}>
              <div className={css(styles.name)}>{location.name}</div>
              <div className={css(styles.address)}>
                {location.address.address_one}, {location.address.city}{" "}
                {location.address.state}, {location.address.zip_code}
              </div>
            </div>
          </div>
        </div>
      );
    });
    return (
      <div className={css(styles.locationPicker)}>
        <RadioGroup
          name="fruit"
          selectedValue={this.state.selectedValue}
          onChange={this.handleChange}
          className={css(styles.radioGroup)}
        >
          {locations}
        </RadioGroup>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  locationPicker: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  },
  location: {
    padding: 16,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginBottom: 16,
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    cursor: "pointer",

    ":hover": {
      borderColor: colors.LIGHT_BLUE(1)
    }
  },
  locationContainer: {
    width: "50%",
    boxSizing: "border-box",
    paddingRight: 16
  },
  radioGroup: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap"
  },
  activeLocation: {
    borderColor: colors.LIGHT_BLUE(1)
  },
  locationInformation: {
    marginLeft: 32
  },
  sizeContainer: {
    display: "inline-flex",
    alignItems: "center",
    marginRight: 16,
    marginBottom: 8
  },
  allSizes: {
    marginTop: 16
  },
  size: {
    // padding: 8,
    border: "1px solid #ddd",
    borderRadius: 4,
    marginRight: 8,
    width: 25,
    height: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  name: {
    // fontWeight: 'bold',
    letterSpacing: 0.7,
    // marginLeft: 32,
    fontSize: 24
  },
  address: {
    marginTop: 4,
    letterSpacing: 0.7,
    opacity: 0.7
  }
});
