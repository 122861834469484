/***
 * Language picker component
 * @patr
 */
import React from "react";

// NPM Modules
// import { StyleSheet, css } from 'aphrodite';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";

// Redux
import { LocalizationActions } from "../../redux/localization";

const options = [
  { value: "en", label: "English" },
  { value: "zh", label: "中文" }
];

class LanguageChooser extends React.Component {
  handleChange = selectedOption => {
    let { localizationActions } = this.props;
    localizationActions.setLocaleByLanguage(
      selectedOption.value,
      selectedOption
    );
  };

  render() {
    let { localization } = this.props;
    return (
      <Select
        value={localization.selectedOption}
        onChange={this.handleChange}
        options={options}
      />
    );
  }
}
// var styles = StyleSheet.create({

// })

const mapStateToProps = state => ({
  localization: state.localization
});

const mapDispatchToProps = dispatch => ({
  localizationActions: bindActionCreators(LocalizationActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageChooser);
