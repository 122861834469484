/***
 * Stockx oauth redirect URL
 */
 import React from "react";

 // NPM Modules
 import qs from "qs";
 
 // Config
 import API from "../../config/api";
 import Helpers from "../../redux/helpers";
 
 class Stockx extends React.Component {
  getToken = code => {
    return fetch(
      API.STOCKX_TOKEN,
      API.POST_CONFIG({
        grant_type: 'authorization_code',
        client_id: process.env.REACT_APP_STOCKX_CLIENT_ID,
        client_secret: process.env.REACT_APP_STOCKX_CLIENT_SECRET,
        code,
        redirect_uri: process.env.REACT_APP_STOCKX_REDIRECT_URI,
      })
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        console.log(res);
        // this.sendTokenToBackend(res.access_token, res.refresh_token);
      });
  };
  
  sendTokenToBackend = (access_token, refresh_token) => {
    return fetch(
      API.STOCKX_CODE,
      API.POST_CONFIG({ access_token, refresh_token })
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        // this.props.history.push("/dashboard");
      });
  };

  componentDidMount = () => {
    let query = qs.parse(this.props.history.location.search);
    let code = query['?code'];

    // this.getToken(code);
    console.log({ code });
  };

  render() {
    return <div />;
  }
 }
 
 export default Stockx;
 