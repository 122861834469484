export const INTERNAL_BARCODE_PREFIX = "ss-";
export const INTERNAL_INVENTORY_ITEM_PREFIX = "sk-";
export const INTERNAL_CONSIGNMENT_ORDER_PREFIX = "co-";

export const EU_TO_USA_SHOE_SIZE = {
  35: 5,
  35.5: 5.5,
  36: 6,
  37: 6.5,
  37.5: 7,
  38: 7.5,
  38.5: 8,
  39: 8.5,
  40: 9,
  41: 9.5,
  42: 10,
  43: 10.5,
  44: 12,
  44.5: 11,
  45: 13,
  46.5: 14,
  48.5: 15.5
};
