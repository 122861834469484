import React, { Component } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import CurrencyDisplay from "../CurrencyDisplay";

class SalesDataCard extends Component {
  render() {
    let { intl } = this.props;
    return (
      <div className={css(styles.salesDataCard)}>
        <div className={css(styles.column)}>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "total_revenue",
                defaultMessage: "Total Revenue"
              })}
            </div>
            <div className={css(styles.value)}>
              <CurrencyDisplay
                price={this.props.salesData.total_revenue / 100}
              />
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "in_store_revenue",
                defaultMessage: "In Store Revenue"
              })}
            </div>
            <div className={css(styles.value)}>
              <CurrencyDisplay
                price={this.props.salesData.in_store_revenue / 100}
              />
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "web_revenue",
                defaultMessage: "Web Revenue"
              })}
            </div>
            <div className={css(styles.value)}>
              <CurrencyDisplay price={this.props.salesData.web_revenue / 100} />
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "mobile_app_revenue",
                defaultMessage: "Mobile Revenue"
              })}
            </div>
            <div className={css(styles.value)}>
              <CurrencyDisplay
                price={this.props.salesData.mobile_app_revenue / 100}
              />
            </div>
          </div>
        </div>
        <div className={css(styles.column)}>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "total_items_sold",
                defaultMessage: "Total Items Sold"
              })}
            </div>
            <div className={css(styles.value)}>
              {this.props.salesData.total_sold}
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "in_store_items_sold",
                defaultMessage: "In Store Items Sold"
              })}
            </div>
            <div className={css(styles.value)}>
              {this.props.salesData.in_store_sold}
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "web_sold",
                defaultMessage: "Web Items Sold"
              })}
            </div>
            <div className={css(styles.value)}>
              {this.props.salesData.web_sold}
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "mobile_app_sold",
                defaultMessage: "Mobile Items Sold"
              })}
            </div>
            <div className={css(styles.value)}>
              {this.props.salesData.mobile_app_sold}
            </div>
          </div>
        </div>
        <div className={css(styles.column)}>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "total_returns",
                defaultMessage: "Total Returns"
              })}
            </div>
            <div className={css(styles.value)}>
              <CurrencyDisplay
                price={this.props.returnsData.total_returns / 100}
              />
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "in_store_returns",
                defaultMessage: "In Store Returns"
              })}
            </div>
            <div className={css(styles.value)}>
              <CurrencyDisplay
                price={this.props.returnsData.in_store_returns / 100}
              />
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "web_returns",
                defaultMessage: "Web Returns"
              })}
            </div>
            <div className={css(styles.value)}>
              <CurrencyDisplay
                price={this.props.returnsData.web_returns / 100}
              />
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "mobile_app_returns",
                defaultMessage: "Mobile Returns"
              })}
            </div>
            <div className={css(styles.value)}>
              <CurrencyDisplay
                price={this.props.returnsData.mobile_app_returns / 100}
              />
            </div>
          </div>
        </div>
        <div className={css(styles.column)}>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "total_items_returned",
                defaultMessage: "Total Items Returned"
              })}
            </div>
            <div className={css(styles.value)}>
              {this.props.returnsData.total_returned_items}
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "in_store_items_returned",
                defaultMessage: "In Store Items Returned"
              })}
            </div>
            <div className={css(styles.value)}>
              {this.props.returnsData.in_store_returned_items}
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "web_returned",
                defaultMessage: "Web Items Returned"
              })}
            </div>
            <div className={css(styles.value)}>
              {this.props.returnsData.web_returned_items}
            </div>
          </div>
          <div className={css(styles.section)}>
            <div className={css(styles.title)}>
              {intl.formatMessage({
                id: "mobile_app_returned",
                defaultMessage: "Mobile Items Returned"
              })}
            </div>
            <div className={css(styles.value)}>
              {this.props.returnsData.mobile_app_returned_items}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  salesDataCard: {
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px",
    padding: 20,
    marginBottom: 20,
    width: "100%",
    display: "flex",
    boxSizing: "border-box"
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 5
  },
  value: {
    fontSize: 18
  },
  column: {
    margin: 10,
    marginRight: 30,
    wordBreak: "break-word"
  },
  section: {
    marginBottom: 10
  }
});

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SalesDataCard));
