/***
 * Manual Input modal Search Placeholder
 * @patr
 */
import React from "react";
// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextRow, RectShape } from "react-placeholder/lib/placeholders";
import "react-placeholder/lib/reactPlaceholder.css";

class ManualInputModalSearchPlaceholder extends React.Component {
  render() {
    return (
      <div className={css(styles.placeholderContainer) + " loading-animation"}>
        <div className={css(styles.rowPlaceholder)}>
          <RectShape className={css(styles.imagePlaceholder)} />
          <TextRow className={css(styles.infoPlaceholder)} />
          <TextRow className={css(styles.infoPlaceholder)} />
        </div>
        <div className={css(styles.rowPlaceholder)}>
          <RectShape className={css(styles.imagePlaceholder)} />
          <TextRow className={css(styles.infoPlaceholder)} />
          <TextRow className={css(styles.infoPlaceholder)} />
        </div>
        <div className={css(styles.rowPlaceholder)}>
          <RectShape className={css(styles.imagePlaceholder)} />
          <TextRow className={css(styles.infoPlaceholder)} />
          <TextRow className={css(styles.infoPlaceholder)} />
        </div>
        <div className={css(styles.rowPlaceholder)}>
          <RectShape className={css(styles.imagePlaceholder)} />
          <TextRow className={css(styles.infoPlaceholder)} />
          <TextRow className={css(styles.infoPlaceholder)} />
        </div>
      </div>
    );
  }
}
var styles = StyleSheet.create({
  placeholderContainer: {
    flex: 1
  },
  rowPlaceholder: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: 10,
    paddingBottom: 10
  },
  imagePlaceholder: {
    width: 100,
    height: 100,
    background: "#eee"
  },
  infoPlaceholder: {
    width: 100,
    height: 20,
    background: "#eee"
  }
});
const mapStateToProps = state => ({
  modals: state.modals
  // auth: state.auth,
});
const mapDispatchToProps = dispatch => ({});
export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ManualInputModalSearchPlaceholder)
);
