/***
 * Consignment modal to approve / reject
 * @patr
 */
import React, { Fragment } from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import { Carousel } from "react-responsive-carousel";
import Checkbox from "rc-checkbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

// Components
import Input from "../Input";
import BaseModal from "./BaseModal";
import Button from "../Button";
import LocationPicker from "../locations/LocationPicker";
import ConsignmentItem from "../ConsignmentItem";
import Barcode from "../inventory/Barcode";
import BarcodeModal from "../modals/AddProduct/BarcodeModal";
import ConsignmentPrint from "../consignment/ConsignmentPrint";
import ManifestModal from "./ConsignmentManagement/ManifestModal";
import {
  Modal,
  Form,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Button as BootstrapButton
} from "react-bootstrap";

// Actions
import { ModalActions } from "../../redux/modals";
import { MessageActions } from "../../redux/message";

// Config
import API from "../../config/api";
import Helpers from "../../redux/helpers";
import colors from "../../config/colors";

// Stylesheets
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./stylesheets/ImageCarousel.css";
import "rc-steps/assets/index.css";
import "rc-steps/assets/iconfont.css";
import "./stylesheets/Scrollbar.css";
import CurrencyDisplay from "../CurrencyDisplay";
import IMAGECOMING from "../../assets/imagecoming.jpg";

class ApproveConsignmentModal extends React.Component {
  constructor(props) {
    super(props);

    this.defaultState = {
      rejectLoading: false,
      showRejectModal: false,
      rejectionReasons: [
        "Too many stock",
        "Cannot currently accept",
        "Pass on item"
      ],
      selectedRejectionReason: null,
      rejectionReasonText: null,
      orderStatus: null,
      initStatusList: [],
      statusList: [],
      messages: [],
      items: [],
      approveLoading: false,
      approve: false,
      page: 0,
      shipToLocation: null,
      noLoading: false,
      submitLoading: false,
      currentItem: null,
      consignmentMessages: {},
      selectedConsignmentMessages: [],
      initNote: "",
      note: "",
      isEditing: false,
      printBarcode: false,
      printAllBarcode: false,
      scannedItemId: "",
      printManifest: false
    };

    this.state = {
      ...this.defaultState,
      locations: []
    };
  }

  /***
   * closes the modal
   */
  closeModal = () => {
    let { modalActions } = this.props;
    modalActions.openApproveConsignmentModal(false, []);
    this.setState({
      ...this.defaultState,
      locations: this.state.locations
    });
  };

  componentDidMount = () => {
    this.getLocations();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.modals.openApproveConsignmentModal) {
      let items = nextProps.modals.approveConsignmentModal.items;
      let orderStatus = nextProps.modals.approveConsignmentModal.status;
      let note = nextProps.modals.approveConsignmentModal.note;
      if (items) {
        //sort items
        items = items.sort((a, b) => {
          if (a.sku.product_name_us > b.sku.product_name_us) {
            return 1;
          }
          if (a.sku.product_name_us < b.sku.product_name_us) {
            return -1;
          }
          if (a.sku.us_size > b.sku.us_size) {
            return 1;
          }
          if (a.sku.us_size < b.sku.us_size) {
            return -1;
          }
          return 0;
        });
        let consignments = null;
        if (items[0]) {
          consignments = items[0].consignment.id;
        }

        //get initial status of each consignment
        let initStatusList = [];
        let messages = [];
        items.forEach(item => {
          initStatusList.push(item.consignment.status);
          messages.push("");
        });
        this.setState({
          initStatusList,
          statusList: initStatusList,
          messages
        });

        if (consignments) {
          fetch(
            API.CONSIGNMENT_MESSAGES + `?consignment=${consignments}`,
            API.GET_CONFIG()
          )
            .then(Helpers.checkStatus)
            .then(Helpers.parseJSON)
            .then(res => {
              let consignmentMessages = {};
              consignmentMessages[items[0].consignment.id] = res.results;
              this.setState({
                consignmentMessages: consignmentMessages,
                selectedConsignmentMessages: res.results
              });
            });
        }
        this.setState({ items });
        this.setState({ orderStatus });
        if (note) {
          this.setState({ initNote: note, note: note });
        }
      }
    }

    if (nextProps.modals.skuModal !== this.props.modals.skuModal) {
      this.setState({
        barcode: nextProps.modals.skuModal.barcode
      });
    }
  }

  /**
   * Update order note
   *
   */
  saveNote = async () => {
    let id = this.state.items[0].consignment_order;
    let params = { note: this.state.note };

    let url = API.CONSIGNMENT_ORDERS_NOTE({ id });

    return fetch(url, API.PUT_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(() => {
        this.props.onNoteChange();
        this.setState(prev => {
          return { isEditing: !prev.isEditing, initNote: prev.note };
        });
      });
  };

  /**
   * Rejects a specific consignment
   */
  reject = async () => {
    this.setState({
      showRejectModal: true
    });
  };

  doubleCheck = () => {
    let allRejected = true;
    //check if every item is checked & check if all are rejected
    for (let i = 0; i < this.state.items.length; i++) {
      if (this.state.statusList[i] !== "rejected") {
        allRejected = false;
      }
    }

    //go to the double check page
    if (this.state.orderStatus === "approved" && !allRejected) {
      this.setState({
        page: this.state.items.length + 1,
        currentItem: "doubleCheck"
      });
    } else {
      this.submit();
    }
  };

  returnToSubmit = () => {
    this.setState({
      page: this.state.items.length,
      currentItem: "submit"
    });
  };

  approve = () => {
    let newStatusList = [...this.state.statusList];
    newStatusList[this.state.page] =
      this.state.initStatusList[this.state.page] === "pending"
        ? "approved"
        : "listed";
    this.setState({ statusList: newStatusList });
    let newMessage = [...this.state.messages];
    newMessage[this.state.page] = "";
    this.setState({ messages: newMessage });
  };

  approveAll = e => {
    e.preventDefault();
    e.stopPropagation();
    let newStatusList = [...this.state.statusList];
    let newMessage = [...this.state.messages];

    newStatusList = newStatusList.map((status, index) => {
      return this.state.initStatusList[index] === "rejected"
        ? "rejected"
        : this.state.initStatusList[index] === "pending"
        ? "approved"
        : "listed";
    });
    this.setState({ statusList: newStatusList });

    newMessage = newMessage.map(() => {
      return "";
    });
    this.setState({ messages: newMessage });
  };

  _handleKeyDown = e => {
    if (e.key === "Enter") {
      if (this.state.scannedItemId.slice(0, 3) !== "ss-") {
        window.alert("Barcode Error: " + this.state.scannedItemId);
        this.setState({
          scannedItemId: ""
        });
        return;
      }
      let itemId = this.state.scannedItemId.slice(3);
      let i = this.state.items.findIndex(item => item.id.toString() === itemId);

      if (
        this.state.statusList[i] ===
        (this.state.initStatusList[i] === "pending" ? "approved" : "listed")
      ) {
        window.alert("repeating item " + this.state.scannedItemId);
        this.setState({
          scannedItemId: ""
        });
        return;
      }
      if (i === -1) {
        window.alert("item not found " + this.state.scannedItemId);
        this.setState({
          scannedItemId: ""
        });
        return;
      }

      let newStatusList = [...this.state.statusList];
      newStatusList[i] =
        this.state.initStatusList[i] === "pending" ? "approved" : "listed";
      this.setState({ statusList: newStatusList });
      let newMessage = [...this.state.messages];
      newMessage[i] = "";
      this.setState({ messages: newMessage });

      this.setState({
        scannedItemId: ""
      });
    }
  };

  scanItem = e => {
    const scannedId = e.target.value;
    this.setState({
      scannedItemId: scannedId
    });
  };

  /***
   * Sets the ship to location for consignment
   * @params int locationId -- the id of the location we're shipping to
   */
  shipToLocation = locationId => {
    this.setState({
      shipToLocation: locationId
    });
  };

  rejectConsignmentItem = async index => {
    let { auth } = this.props;
    let id = this.state.items[index].consignment.id;
    let params = {
      status: "rejected"
    };

    await fetch(API.CONSIGNMENT({ id, simple: true }), API.PATCH_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON);

    let messageParams = {
      message: this.state.messages[index],
      from_user: auth.userProfile.id,
      consignment: id,
      to_user: this.state.items[index].consigner.user
    };

    await fetch(API.CONSIGNMENT_MESSAGE, API.POST_CONFIG(messageParams));
  };

  approveConsignmentItem = async index => {
    let id = this.state.items[index].consignment.id;
    let params = { consignment: { status: "approved" } };
    return fetch(
      API.CONSIGNMENT({ id, simple: true }),
      API.PATCH_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON);
  };

  listConsignmentItem = async index => {
    let id = this.state.items[index].consignment.id;
    let params = { consignment: { status: "listed" } };
    return fetch(
      API.CONSIGNMENT({ id, simple: true }),
      API.PATCH_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON);
  };

  /***
   * Approve consignment by changing the status on the backend. Also callback to print barcode if need be
   * @params boolean list -- whether we've listed the item or not (if not, don't print barcode)
   */
  approveConsignmentOrder = (e, list) => {
    let { intl } = this.props;
    let id = this.state.items[0].consignment_order;
    let params = { status: "approved" };

    if (list) {
      params.status = "listed";
      this.setState({
        approveLoading: true
      });
    } else {
      this.setState({
        noLoading: true
      });
    }

    if (this.state.shipToLocation) {
      params["ship_to_id"] = this.state.shipToLocation;
    }

    if (!this.state.approve) {
      params["status"] = "rejected";
    }
    let url = API.CONSIGNMENT_ORDERS({ id });
    return fetch(url, API.PUT_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          approveLoading: false
        });
        let { messageActions } = this.props;
        if (this.state.approve) {
          messageActions.setMessage(
            intl.formatMessage({
              id: "consignment_order_approved",
              defaultMessage: "Consignment Order Approved"
            })
          );
          messageActions.showMessage({ show: true });
        } else {
          messageActions.setMessage(
            intl.formatMessage({
              id: "consignment_order_rejected",
              defaultMessage: "Consignment Order Rejected"
            })
          );
          messageActions.showMessage({ show: true, error: true });
        }
        this.props.onApproveOrReject(res.id, list);
        this.closeModal();
      });
  };

  print = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      printBarcode: true
    });
  };

  printAll = e => {
    e.preventDefault();
    e.stopPropagation();

    console.log("items", this.state.items);

    this.setState({
      printAllBarcode: true
    });
  };

  exportConsignmentOrder = () => {
    let { messageActions } = this.props;
    let consignment_order = this.state.items[0].consignment_order;
    messageActions.showMessage({ show: true, load: true });
    return fetch(
      API.CONSIGNMENT_ORDER_CSV({ id: consignment_order }),
      API.GET_CONFIG()
    )
      .then(Helpers.checkStatus)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        let link = document.createElementNS(
          "http://www.w3.org/1999/xhtml",
          "a"
        );
        link.href = URL.createObjectURL(res);
        link.download = `consignment_order_${consignment_order}.csv`;
        link.click();
        messageActions.showMessage({ show: false });
      });
  };

  printManifest = e => {
    e.preventDefault();
    e.stopPropagation();

    for (let i = 0; i < this.state.items.length; i++) {
      if (this.state.statusList[i] === "pending") {
        alert(
          "You need to approve or reject all the item before printing manifest"
        );
        return;
      }
    }

    this.setState({
      printManifest: true
    });
  };

  closeManifestModal = () => {
    this.setState({
      printManifest: false
    });
  };

  submit = async () => {
    let { intl } = this.props;
    let allRejected = true;
    //check if every item is checked & check if all are rejected
    for (let i = 0; i < this.state.items.length; i++) {
      if (
        this.state.initStatusList[i] === this.state.statusList[i] &&
        this.state.initStatusList[i] !== "rejected"
      ) {
        alert("You need to approve or reject all the item before submitting");
        return;
      }

      if (this.state.statusList[i] !== "rejected") {
        allRejected = false;
      }
    }
    this.setState({
      submitLoading: true
    });

    for (let i = 0; i < this.state.items.length; i++) {
      //Anystate -> Reject
      if (
        this.state.statusList[i] === "rejected" &&
        this.state.initStatusList[i] !== "rejected"
      ) {
        await this.rejectConsignmentItem(i);
      }

      // //Pending -> Approve
      // if (this.state.statusList[i] === "approved") {
      //   await this.approveConsignmentItem(i);
      // }

      // //Approve -> List
      // if (this.state.statusList[i] === "listed") {
      //   await this.listConsignmentItem(i);
      // }
    }

    let id = this.state.items[0].consignment_order;
    let params = { status: "approved" };
    if (allRejected) {
      params.status = "rejected";
    } else if (this.state.orderStatus === "approved") {
      params.status = "listed";
    } else if (this.state.orderStatus === "pending") {
      params.status = "approved";
    }

    let url = API.CONSIGNMENT_ORDERS({ id });

    return fetch(url, API.PUT_CONFIG(params))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(() => {
        this.setState({
          submitLoading: false
        });
        let { messageActions } = this.props;
        if (allRejected) {
          messageActions.setMessage(
            intl.formatMessage({
              id: "consignment_order_rejected",
              defaultMessage: "Consignment Order Rejected"
            })
          );
          messageActions.showMessage({ show: true, error: true });
        } else if (this.state.orderStatus === "pending") {
          messageActions.setMessage(
            intl.formatMessage({
              id: "consignment_order_approved",
              defaultMessage: "Consignment Order Approved"
            })
          );
          messageActions.showMessage({ show: true });
        } else if (this.state.orderStatus === "approved") {
          messageActions.setMessage(
            intl.formatMessage({
              id: "consignment_order_listed",
              defaultMessage: "Consignment Order Listed"
            })
          );
          messageActions.showMessage({ show: true });
        }
        this.closeModal();
      });
  };

  renderItem = item => {
    let { intl } = this.props;
    let consignment = item ? item.consignment : { price: { default_price: 0 } };
    let messages = this.state.selectedConsignmentMessages.map(
      (message, index) => {
        return (
          <div className={css(styles.messageContainer)}>
            <div className={css(styles.numbering)}>{`${index + 1}.`}</div>
            <div className={css(styles.message)}>{message.message}</div>
            <div className={css(styles.timeCreated)}>
              {moment(message.created_at).format("L")}
            </div>
          </div>
        );
      }
    );
    return (
      <div className={css(styles.information)}>
        {this.state.page === this.state.items.length + 1 ? (
          <div id="haveConsignerItem">
            <div className={css(styles.question)}>
              {intl.formatMessage({
                id: "have_consignment_order",
                defaultMessage: "Do you already have the consigner's items?"
              })}
            </div>
            <div className={css(styles.actionGroup)}>
              <Button
                text={
                  this.state.noLoading ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      spin
                      color={"#fff"}
                    />
                  ) : (
                    <FormattedMessage id="no" defaultMessage="No" />
                  )
                }
                onClick={this.returnToSubmit}
              />
              <Button
                text={
                  this.state.submitLoading ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      spin
                      color={"#fff"}
                    />
                  ) : (
                    <FormattedMessage id="yes" defaultMessage="Yes" />
                  )
                }
                id="haveConsignerItemApprove"
                className={styles.approveButton}
                onClick={this.submit}
              />
            </div>
          </div>
        ) : (
          <Fragment>
            <div className={css(styles.sectionContainer)}>
              <div id={"consignmentInfo"}>
                <div className={css(styles.inventoryID)}>
                  {item && "#" + item.id}
                </div>
                <div id={"statusInfo"} className={css(styles.statusInfo)}>
                  <div
                    className={css(
                      styles.tag,
                      this.state.initStatusList[this.state.page] === "rejected"
                        ? styles.rejectedTag
                        : this.state.initStatusList[this.state.page] ===
                          "listed"
                        ? styles.listedTag
                        : this.state.initStatusList[this.state.page] ===
                          "approved"
                        ? styles.approvedTag
                        : this.state.initStatusList[this.state.page] === "sold"
                        ? styles.soldTag
                        : styles.pendingTag
                    )}
                  >
                    <span>
                      {this.state.initStatusList[this.state.page] &&
                        this.state.initStatusList[
                          this.state.page
                        ].toUpperCase()}
                    </span>
                  </div>
                  {this.state.initStatusList[this.state.page] !==
                    this.state.statusList[this.state.page] && (
                    <div>
                      <FontAwesomeIcon
                        icon={["far", "long-arrow-right"]}
                        size={"2x"}
                        style={{
                          marginRight: 10,
                          marginLeft: 10,
                          width: 15
                        }}
                      />
                    </div>
                  )}
                  {this.state.initStatusList[this.state.page] !==
                    this.state.statusList[this.state.page] && (
                    <div
                      className={css(
                        styles.tag,
                        this.state.statusList[this.state.page] === "rejected"
                          ? styles.rejectedTag
                          : this.state.statusList[this.state.page] === "listed"
                          ? styles.listedTag
                          : this.state.statusList[this.state.page] ===
                            "approved"
                          ? styles.approvedTag
                          : this.state.statusList[this.state.page] === "sold"
                          ? styles.soldTag
                          : styles.pendingTag
                      )}
                    >
                      {this.state.statusList[this.state.page] &&
                        this.state.statusList[this.state.page].toUpperCase()}
                    </div>
                  )}
                </div>

                <div>
                  {this.state.messages[this.state.page] !== "" && (
                    <div>
                      <span>
                        {
                          <FormattedMessage
                            id="Rejection Reason"
                            defaultMessage="Rejection Reason: "
                          />
                        }
                      </span>
                      {this.state.messages[this.state.page]}
                    </div>
                  )}
                </div>
                <div className={css(styles.section, styles.center)}>
                  <img
                    className={css(styles.productImg)}
                    src={
                      item && item.pictures[0] && item.pictures[0].url
                        ? item.pictures[0].url
                        : IMAGECOMING
                    }
                    alt={item && item.sku.product_name_us}
                  />
                  <div className={css(styles.info)}>
                    <div className={css(styles.price)}>
                      <CurrencyDisplay
                        price={consignment.price.default_price / 100}
                      />
                    </div>
                    <div className={css(styles.size)}>
                      {`${intl.formatMessage({
                        id: "size",
                        defaultMessage: "Size"
                      })} ${consignment.size}`}
                    </div>
                  </div>
                </div>
                <div className={css(styles.title)}>
                  {item && item.sku.product_name_us}
                </div>
                <div className={css(styles.subInfo)}>
                  <div className={css(styles.subTitle)}>
                    Style: {item && item.sku.style}
                  </div>
                  <div className={css(styles.subTitle)}>
                    Barcode: {item && item.sku.barcode}
                  </div>
                </div>
              </div>
              <div
                className={css(styles.sectionContainer, styles.middleContainer)}
              >
                <div className={css(styles.section)}>
                  <div className={css(styles.checkContainer)}>
                    <Checkbox
                      checked={consignment.yellowing}
                      className={css(styles.checkbox)}
                      disabled={true}
                    />
                    {intl.formatMessage({
                      id: "yellowing",
                      defaultMessage: "Yellowing"
                    })}
                  </div>
                  <div className={css(styles.checkContainer)}>
                    <Checkbox
                      checked={consignment.scuff_marks}
                      className={css(styles.checkbox)}
                      disabled={true}
                    />
                    {intl.formatMessage({
                      id: "scuff_marks",
                      defaultMessage: "Scuff Marks"
                    })}
                  </div>
                  <div className={css(styles.checkContainer)}>
                    <Checkbox
                      checked={consignment.missing_insoles}
                      className={css(styles.checkbox)}
                      disabled={true}
                    />
                    {intl.formatMessage({
                      id: "missing_insoles",
                      defaultMessage: "Missing Insoles"
                    })}
                  </div>
                  <div className={css(styles.checkContainer)}>
                    <Checkbox
                      checked={consignment.defects}
                      className={css(styles.checkbox)}
                      disabled={true}
                    />
                    {intl.formatMessage({
                      id: "defects",
                      defaultMessage: "Defects"
                    })}
                  </div>
                </div>
                <div className={css(styles.section, styles.center)}>
                  {`${intl.formatMessage({
                    id: "box_condition",
                    defaultMessage: "Box Condition"
                  })}: ${consignment.box_condition}`}
                </div>
              </div>
              {this.state.selectedConsignmentMessages.length > 0 ? (
                <div className={css(styles.rejectionTitle)}>
                  {intl.formatMessage({
                    id: "past_rejection_reasons",
                    defaultMessage: "Past Rejection Reasons"
                  })}
                </div>
              ) : null}
              <div className={css(styles.rejectionContainer)}>{messages}</div>
            </div>

            <div className={css(styles.actionGroup)}>
              <Button
                text={
                  this.state.rejectLoading ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      spin
                      color={"#fff"}
                    />
                  ) : (
                    <FormattedMessage id="reject" defaultMessage="Reject" />
                  )
                }
                id="reject"
                onClick={this.reject}
                disabled={
                  this.state.initStatusList[this.state.page] === "rejected" ||
                  this.state.initStatusList[this.state.page] === "listed" ||
                  this.state.initStatusList[this.state.page] === "sold"
                }
              />
              <Button
                text={
                  this.state.approveLoading ? (
                    <FontAwesomeIcon
                      icon={["far", "spinner-third"]}
                      spin
                      color={"#fff"}
                    />
                  ) : this.state.orderStatus === "approved" ? (
                    <FormattedMessage id="list" defaultMessage="List" />
                  ) : (
                    <FormattedMessage id="approve" defaultMessage="Approve" />
                  )
                }
                id="approve"
                className={styles.approveButton}
                onClick={this.approve}
                disabled={
                  this.state.initStatusList[this.state.page] === "rejected" ||
                  this.state.initStatusList[this.state.page] === "listed" ||
                  this.state.initStatusList[this.state.page] === "sold"
                }
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  };

  renderPageTwo = () => {
    return (
      <div className={css(styles.locations)}>
        <div className={css(styles.picker)}>
          <LocationPicker
            locations={this.state.locations}
            styleByLocation={this.state.styleByLocation}
            handleChange={this.shipToLocation}
          />
        </div>
        <div className={css(styles.actionGroup)}>
          <Button
            text={
              this.state.approveLoading ? (
                <FontAwesomeIcon
                  icon={["far", "spinner-third"]}
                  spin
                  color={"#fff"}
                />
              ) : (
                <FormattedMessage
                  id="choose_shipping_location"
                  defaultMessage="Choose Shipping Location"
                />
              )
            }
            onClick={this.approveConsignmentOrder}
          />
        </div>
      </div>
    );
  };

  renderPrintPage = () => {
    return (
      <div>
        <ul style={{ textAlign: "left", width: "100%" }}>
          <div style={{ textAlign: "center", width: "100%" }}>
            <button className={css(styles.printButton)} onClick={this.printAll}>
              <FormattedMessage id="print_label" defaultMessage={"Print All"} />
            </button>
          </div>
          {this.state.statusList.map((item, index) => {
            return (
              <ConsignmentPrint
                item={this.state.items[index]}
                index={index}
                clickConsignment={() => this.selectConsignment(index)}
                printFinish={this.setPrinted}
              />
            );
          })}
        </ul>
      </div>
    );
  };

  renderSubmitPage = () => {
    let { auth, intl } = this.props;
    if (
      this.state.orderStatus === "rejected" ||
      this.state.orderStatus === "listed"
    ) {
      return (
        <div className={css(styles.submitButtonWrapper)}>
          <h2>This order has already been {this.state.orderStatus}</h2>
        </div>
      );
    } else {
      return (
        <div>
          {["admin", "warehouse manager"].includes(
            auth.userProfile.role.toLowerCase()
          ) &&
            (this.state.orderStatus === "pending" ||
              this.state.orderStatus === "approved") && (
              <>
                <button
                  onClick={this.approveAll}
                  className={css(styles.approveAllButton)}
                >
                  <FormattedMessage
                    id="approve_all"
                    defaultMessage={
                      this.state.orderStatus === "pending"
                        ? "Approve All"
                        : "List All"
                    }
                  />
                </button>
              </>
            )}
          {["admin", "warehouse manager", "warehouse employee"].includes(
            auth.userProfile.role.toLowerCase()
          ) &&
            this.state.orderStatus === "pending" && (
              <>
                <div className={css(styles.scanItemWrapper)}>
                  <Input
                    value={this.state.scannedItemId}
                    placeholder={intl.formatMessage({
                      id: "scan_item",
                      defaultMessage: "Scan Item"
                    })}
                    onChange={this.scanItem}
                    id={"scanItem"}
                    disabled={this.state.submitted}
                    onKeyDown={this._handleKeyDown}
                  />
                </div>
              </>
            )}

          {/* Todo */}
          {[
            "admin",
            "warehouse manager",
            "store manager",
            "store employee"
          ].includes(auth.userProfile.role.toLowerCase()) &&
            this.state.orderStatus === "approved" && (
              <div className={css(styles.scanItemWrapper)}>
                <Input
                  value={this.state.scannedItemId}
                  placeholder={intl.formatMessage({
                    id: "scan_item",
                    defaultMessage: "Scan Item"
                  })}
                  onChange={this.scanItem}
                  id={"scanItem"}
                  onKeyDown={this._handleKeyDown}
                />
              </div>
            )}
          <ul style={{ textAlign: "left" }}>
            {this.state.statusList.map((item, index) => {
              return (
                <div className={css(styles.summaryInfo)} key={index}>
                  <div
                    className={css(styles.productSummay)}
                    onClick={() => this.selectConsignment(index)}
                  >
                    <img
                      className={css(styles.productImgSteps)}
                      alt={this.state.items[index].sku.product_name_us}
                      src={
                        this.state.items[index] &&
                        this.state.items[index].pictures[0] &&
                        this.state.items[index].pictures[0].url
                          ? this.state.items[index].pictures[0].url
                          : IMAGECOMING
                      }
                    />
                    <p className={css(styles.productName)}>
                      {this.state.items[index].sku.product_name_us}
                    </p>
                    <p className={css(styles.productSize)}>
                      {this.state.items[index].consignment.size}
                    </p>
                  </div>

                  <div
                    id={"statusInfo"}
                    className={css(styles.statusInfo, styles.statusInfoSummary)}
                  >
                    <div
                      className={css(
                        styles.tag,
                        this.state.initStatusList[index] === "rejected"
                          ? styles.rejectedTag
                          : this.state.initStatusList[index] === "listed"
                          ? styles.listedTag
                          : this.state.initStatusList[index] === "approved"
                          ? styles.approvedTag
                          : this.state.initStatusList[index] === "pending"
                          ? styles.pendingTag
                          : styles.soldTag
                      )}
                    >
                      <span>
                        {this.state.initStatusList[index] &&
                          this.state.initStatusList[index].toUpperCase()}
                      </span>
                    </div>
                    {this.state.initStatusList[index] !==
                      this.state.statusList[index] && (
                      <div>
                        <FontAwesomeIcon
                          icon={["far", "long-arrow-right"]}
                          size={"2x"}
                          style={{
                            marginRight: 10,
                            marginLeft: 10,
                            width: 15
                          }}
                        />
                      </div>
                    )}
                    {this.state.initStatusList[index] !==
                    this.state.statusList[index] ? (
                      <div
                        className={css(
                          styles.tag,
                          this.state.statusList[index] === "rejected"
                            ? styles.rejectedTag
                            : this.state.statusList[index] === "listed"
                            ? styles.listedTag
                            : this.state.statusList[index] === "approved"
                            ? styles.approvedTag
                            : this.state.statusList[index] === "sold"
                            ? styles.soldTag
                            : styles.pendingTag
                        )}
                      >
                        {this.state.statusList[index] &&
                          this.state.statusList[index].toUpperCase()}
                      </div>
                    ) : this.state.initStatusList[index] !== "rejected" &&
                      this.state.initStatusList[index] !== "listed" ? (
                      <div className={css(styles.warningMessage)}>
                        <FontAwesomeIcon
                          icon={["fas", "exclamation-circle"]}
                          color={"red"}
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              );
            })}
          </ul>
          <div className={css(styles.submitButtonWrapper)}>
            <Button
              text={
                this.state.submitLoading ? (
                  <FontAwesomeIcon
                    icon={["far", "spinner-third"]}
                    spin
                    color={"#fff"}
                  />
                ) : (
                  <FormattedMessage id="submit" defaultMessage="Submit" />
                )
              }
              onClick={this.doubleCheck}
            />
          </div>
          {["admin", "warehouse manager"].includes(
            auth.userProfile.role.toLowerCase()
          ) &&
            this.state.orderStatus === "pending" && (
              <div className={css(styles.submitButtonWrapper)}>
                <Button
                  text={
                    <FormattedMessage
                      id="print_manifest"
                      defaultMessage="Print Manifest"
                    />
                  }
                  onClick={this.printManifest}
                />
              </div>
            )}
        </div>
      );
    }
  };

  setPrinted = index => {
    let newItems = this.state.items;
    newItems[index].consignment.barcode_printed = true;
    this.setState({ items: newItems });
  };

  getLocations = async () => {
    return fetch(API.LOAD_LOCATIONS(), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let locations = [...res.results];
        this.setState({
          locations: locations
        });
      });
  };

  getLocationStock = () => {
    let { modals } = this.props;
    let style = modals.approveConsignmentModal.item.sku.style;
    return fetch(API.STYLE_QUANTITY_BY_LOCATION({ style }), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState({
          styleByLocation: res.results
        });
      });
  };

  onAfterOpen = () => {
    this.setState({
      currentItem: this.state.items[0]
    });
  };

  selectConsignment = async index => {
    let item = this.state.items[index];
    let consignmentMessages = { ...this.state.consignmentMessages };
    if (!consignmentMessages[item.consignment.id]) {
      consignmentMessages[item.consignment.id] = await fetch(
        API.CONSIGNMENT_MESSAGES + `?consignment=${item.consignment.id}`,
        API.GET_CONFIG()
      )
        .then(Helpers.checkStatus)
        .then(Helpers.parseJSON)
        .then(res => {
          return res.results;
        });
    }
    let selectedConsignmentMessages = [];
    if (consignmentMessages[item.consignment.id]) {
      selectedConsignmentMessages = consignmentMessages[item.consignment.id];
    }
    this.setState({
      page: index,
      currentItem: item,
      consignmentMessages: consignmentMessages,
      selectedConsignmentMessages: selectedConsignmentMessages
    });
  };

  selectSubmit = () => {
    let length = this.state.items.length;
    this.setState({
      page: length,
      currentItem: "submit"
    });
  };

  selectPrint = () => {
    let length = this.state.items.length;
    this.setState({
      page: length + 2,
      currentItem: "print"
    });
  };

  selectRejectionReason = event => {
    const selectedRejectionReason = this.state.rejectionReasons[event];
    this.setState({
      selectedRejectionReason
    });
  };

  handleCloseModal = () => {
    this.setState({
      showRejectModal: false
    });
  };

  handleItemRejection = () => {
    let rejectionReason = "";

    if (this.state.selectedRejectionReason) {
      rejectionReason = this.state.selectedRejectionReason;
    }

    if (this.state.rejectionReasonText) {
      rejectionReason = rejectionReason + " " + this.state.rejectionReasonText;
    }

    let messages = [...this.state.messages];
    messages[this.state.page] = rejectionReason;

    let newStatusList = [...this.state.statusList];
    newStatusList[this.state.page] = "rejected";

    this.setState({
      showRejectModal: false,
      statusList: newStatusList,
      messages
    });
  };

  handleInputReasonTextChange = e => {
    this.setState({
      rejectionReasonText: e.target.value
    });
  };

  render() {
    let { modals, auth, intl } = this.props;

    let consigner =
      this.state.items[0] &&
      this.state.items[0].consigner.first_name +
        " " +
        this.state.items[0].consigner.last_name;

    let images = [];
    if (
      this.state.currentItem &&
      this.state.currentItem !== "submit" &&
      this.state.currentItem !== "doubleCheck" &&
      this.state.currentItem !== "print"
    ) {
      images = this.state.currentItem.consignment.pictures.map(picture => {
        return picture.url;
      });
    }

    if (images.length === 0) {
      images = ["https://via.placeholder.com/800?text=No+Pictures"];
    }

    let renderShippingLocation = false;
    let steps = this.state.items.map((item, index) => {
      return (
        <ConsignmentItem
          onActiveTab={this.selectConsignment.bind(this, index)}
          active={this.state.page === index}
          name={item.sku.product_name_us}
          picture={item && item.pictures[0] && item.pictures[0].url}
          status={this.state.statusList[index]}
          key={index}
        />
      );
    });
    if (
      this.state.orderStatus !== "rejected" &&
      this.state.orderStatus !== "listed"
    ) {
      steps.push(
        <ConsignmentItem
          onActiveTab={this.selectSubmit.bind(this)}
          active={this.state.page === this.state.items.length}
          name={this.state.orderStatus === "listed" ? "Print" : "Submit"}
          status={"submit"}
          key={this.state.items.length}
        />
      );
    }

    if (this.state.orderStatus === "listed") {
      steps.push(
        <ConsignmentItem
          onActiveTab={this.selectPrint.bind(this)}
          active={this.state.page === this.state.items.length + 2}
          name={"Print"}
          status={"print"}
          key={this.state.items.length}
        />
      );
    }

    return (
      <BaseModal
        isOpen={modals.openApproveConsignmentModal}
        closeModal={this.closeModal}
        modalClassName={styles.modal}
        afterOpen={this.onAfterOpen}
        bodyContainer={styles.bodyContainer}
        contentLabel="AddProductModal"
      >
        <div className={css(styles.orderInfo)}>
          <div className={css(styles.consignerInfo)}>
            <div
              style={{
                fontSize: ".8em",
                marginBottom: 10,
                color: "rgb(180,180,180)"
              }}
            >
              <div
                style={{
                  fontSize: "1.5em",
                  marginBottom: 10,
                  color: "black"
                }}
              >
                #{this.state.items[0] && this.state.items[0].consignment_order}
              </div>
              <FormattedMessage id="consigner" defaultMessage="Consigner" />
            </div>
            {consigner}
          </div>
          <div className={css(styles.consignmentNoteWrapper)}>
            <textarea
              name={"note"}
              placeholder={"Add note for this consignment order"}
              value={this.state.note}
              className={css(
                styles.consignmentNote,
                this.state.isEditing ? styles.noteEdit : styles.noteDisplay
              )}
              autoFocus
              disabled={!this.state.isEditing}
              onChange={e => this.setState({ note: e.target.value })}
            />
            <div className={css(styles.editButtonWrapper)}>
              <button
                onClick={
                  !this.state.isEditing
                    ? () => {
                        this.setState(prev => {
                          return {
                            isEditing: !prev.isEditing
                          };
                        });
                      }
                    : this.saveNote
                }
                className={css(styles.editButton)}
              >
                {this.state.isEditing ? (
                  <FormattedMessage id="save" defaultMessage="Save" />
                ) : (
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                )}
              </button>
              <button
                onClick={() => {
                  this.setState(prev => {
                    return {
                      isEditing: !prev.isEditing,
                      note: prev.initNote
                    };
                  });
                }}
                className={css(
                  !this.state.isEditing ? styles.hideButton : styles.editButton
                )}
              >
                <FormattedMessage id="discard" defaultMessage="Discard" />
              </button>
            </div>
          </div>
        </div>
        {["admin", "warehouse manager", "accountant"].includes(
          auth.userProfile.role.toLowerCase()
        ) && (
          <div style={{ textAlign: "left", width: "100%" }}>
            <div style={{ marginLeft: 50, width: 100 }}>
              <Button
                text={intl.formatMessage({
                  id: "export_consignment_order",
                  defaultMessage: "Export"
                })}
                onClick={this.exportConsignmentOrder}
              />
            </div>
          </div>
        )}
        <FontAwesomeIcon
          icon={["far", "times"]}
          className={css(styles.times)}
          onClick={this.closeModal}
        />
        <div className={css(styles.grouping)}>
          <h2 style={{ paddingLeft: 50, fontWeight: "normal" }}>
            <FormattedMessage id="consignments" defaultMessage="Consignments" />
          </h2>
          <div className={css(styles.steps)}>{steps}</div>
          <div className={css(styles.approveConsignment)}>
            {this.state.page < this.state.items.length && (
              <div className={css(styles.photos)}>
                {images && (
                  <Carousel
                    autoPlay={true}
                    width={500}
                    infiniteLoop={true}
                    showThumbs={false}
                  >
                    {images.map(item => (
                      <div>
                        <img alt="" src={item} />
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            )}
            <div className={css(styles.right)}>
              {!renderShippingLocation
                ? this.state.currentItem === "submit"
                  ? this.renderSubmitPage()
                  : this.state.currentItem === "print"
                  ? this.renderPrintPage()
                  : this.renderItem(this.state.currentItem)
                : this.renderPageTwo()}
            </div>
          </div>
        </div>
        {this.state.currentItem && this.state.printManifest && (
          <ManifestModal
            closeManifestModal={this.closeManifestModal}
            consignment_order_id={this.state.items[0].consignment_order}
            consigner={consigner}
            location={this.state.items[0].location.name}
            created_at={this.state.items[0].created_at}
            items={this.state.items}
            status={this.state.statusList}
          />
        )}
        {this.state.currentItem &&
          this.state.currentItem.consignment &&
          this.state.currentItem.consignment.status === "listed" &&
          this.state.printBarcode && (
            <BarcodeModal
              unmount={() => {
                this.setState({
                  printBarcode: false
                });
              }}
              component={
                <Barcode
                  hidePrice={false}
                  name={
                    this.state.currentItem.sku &&
                    this.state.currentItem.sku.product_name_us
                  }
                  conditions={
                    this.state.currentItem.consignment.used && ["used"]
                  }
                  price={
                    (this.state.currentItem.consignment.price
                      ? this.state.currentItem.consignment.price.default_price
                      : 0) / 100
                  }
                  barcode={`ss-${this.state.currentItem.id}`}
                  size={
                    this.state.currentItem.sku &&
                    this.state.currentItem.sku.us_size
                  }
                  color={
                    this.state.currentItem.sku &&
                    this.state.currentItem.sku.color
                  }
                  style={
                    this.state.currentItem.sku &&
                    this.state.currentItem.sku.style
                  }
                  consigner={
                    this.state.currentItem.consigner.first_name +
                    " " +
                    this.state.currentItem.consigner.last_name
                  }
                />
              }
            />
          )}
        {this.state.printAllBarcode && (
          <BarcodeModal
            unmount={() => {
              this.setState({
                printAllBarcode: false
              });
            }}
            barcodes={this.state.items.map(item => {
              let key = Math.random()
                .toString(36)
                .substring(7);
              return {
                barcode: `ss-${item.id}`,
                key,
                item
              };
            })}
          />
        )}
        <Modal
          show={this.state.showRejectModal}
          onHide={this.handleCloseModal}
          centered={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>Rejection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="rejections">
                <Row>
                  <Col>
                    <DropdownButton
                      variant="danger"
                      id="dropdown-item-button"
                      title="Why you are rejecting?"
                    >
                      {this.state.rejectionReasons.map((reason, index) => {
                        return (
                          <Dropdown.Item
                            onSelect={this.selectRejectionReason}
                            eventKey={index}
                          >
                            {reason}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </Col>
                  {this.state.selectedRejectionReason && (
                    <Col className={css(styles.rejectionReasonContainer)}>
                      <span className={css(styles.rejectionReason)}>
                        {this.state.selectedRejectionReason}
                      </span>
                    </Col>
                  )}
                </Row>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <Form.Label>Please give a reason for rejection</Form.Label>
                <Form.Control
                  value={this.state.rejectionReasonText}
                  onChange={e => this.handleInputReasonTextChange(e)}
                  size="small"
                  type="text"
                  placeholder="Enter a reason"
                />
              </Form.Group>
              <Modal.Footer>
                <BootstrapButton
                  variant="secondary"
                  onClick={this.handleCloseModal}
                >
                  Cancel
                </BootstrapButton>

                <BootstrapButton
                  variant="danger"
                  disabled={
                    !this.state.selectedRejectionReason &&
                    !this.state.rejectionReasonText
                  }
                  onClick={this.handleItemRejection}
                >
                  OK
                </BootstrapButton>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </BaseModal>
    );
  }
}
var styles = StyleSheet.create({
  modal: {
    height: "100%",
    maxWidth: "unset",
    width: "100%"
  },
  subInfo: {
    display: "flex",
    justifyContent: "center"
  },
  title: {
    textAlign: "center",
    letterSpacing: 0.7,
    fontSize: 24
  },
  subTitle: {
    textAlign: "center",
    letterSpacing: 0.7,
    fontSize: 18,
    margin: "10px"
  },
  grouping: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: 30
  },
  approveConsignment: {
    boxSizing: "border-box",
    height: "max-content",
    display: "flex",
    width: "100%",
    padding: 50,
    paddingTop: 20,
    justifyContent: "center"
  },
  bodyContainer: {
    height: "100%",
    maxHeight: "unset",
    justifyContent: "flex-start"
  },
  section: {
    display: "flex",
    justifyContent: "space-between",
    padding: 8,
    fontSize: 18,
    overflow: "auto",
    flexWrap: "wrap"
  },
  steps: {
    width: "100%",
    flexWrap: "wrap",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 50px",
    margin: "0 -50px",
    backgroundColor: "rgb(250,250,250)"
  },
  checkContainer: {
    width: "50%",
    marginTop: 4
  },
  orderInfo: {
    marginTop: 10,
    paddingBottom: 10,
    width: "100%",
    height: "max-content",
    display: "flex"
  },
  consignerInfo: {
    fontSize: "1.5em",
    marginLeft: 50,
    width: 300
  },
  consignmentNoteWrapper: {
    width: "100%",
    backgroundColor: "rgb(250,250,250)",
    marginRight: 100,
    padding: 10
  },
  consignmentNote: {
    boxSizing: "border-box",
    resize: "none",
    width: "100%",
    fontSize: "1.3em",
    height: 60,
    padding: 10,
    overflowY: "scroll",
    backgroundColor: "rgba(0,0,0,0)"
  },
  noteEdit: {
    backgroundColor: "rgb(255,255,255)",
    border: "none"
  },
  noteDisplay: {
    border: "none"
  },

  editButtonWrapper: {
    marginTop: 10,
    display: "flex",
    width: "100%"
  },
  editButton: {
    padding: "1em 2em",
    border: "none",
    cursor: "pointer",
    letterSpacing: 0.7,
    outline: "none",
    marginRight: 10
  },
  hideButton: {
    display: "none"
  },

  times: {
    position: "absolute",
    right: 32,
    top: 32,
    fontSize: "2.5em",
    cursor: "pointer"
  },
  productImg: {
    width: 100,
    height: 100
  },
  photos: {
    width: 500
  },
  photo: {
    height: 300,
    width: "auto"
  },
  sectionContainer: {
    padding: 20,
    background: "rgb(249, 249, 249)",
    textAlign: "left",
    marginBottom: 10
  },
  middleContainer: {
    background: "rgb(240, 240, 240)",
    padding: 10,
    marginTop: 20
  },
  picker: {
    overflow: "scroll",
    height: "calc(100%)",
    paddingTop: 32,
    paddingBottom: 32
  },
  inline: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: 0
  },
  information: {
    flex: 1,
    marginLeft: 32,
    display: "flex",
    height: "100%",
    flexDirection: "column"
  },
  column: {
    flexDirection: "column",
    alignItems: "center"
  },
  info: {
    // display: 'flex',
    marginLeft: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontSize: 18
  },
  actionGroup: {
    display: "flex",
    marginTop: "auto"
  },
  price: {
    fontSize: 24,
    letterSpacing: 0.7
    // marginLeft: 16,
  },
  question: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    margin: "16px 0px"
  },
  productSteps: {
    width: 100,
    height: "max-content",
    textAlign: "center",
    cursor: "pointer",
    margin: "0 auto"
  },
  productImgSteps: {
    width: 50,
    height: 50
  },
  approveButton: {
    marginLeft: 16
  },
  locations: {
    flex: 1,
    marginLeft: 16
  },
  right: {
    width: "100%",
    flex: 1,
    minHeight: 500
  },
  center: {
    justifyContent: "center"
  },
  rejectionTitle: {
    marginTop: 20,
    marginBottom: 10,
    fontSize: 20,
    fontWeight: "bold"
  },
  messageContainer: {
    paddingLeft: 10,
    display: "flex",
    alignItems: "center"
  },
  numbering: {
    width: 30,
    textAlign: "center",
    marginRight: 10
  },
  timeCreated: {
    opacity: ".5",
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: 10
  },
  rejectionContainer: {
    width: "100%",
    flex: 1,
    marginBottom: 20,
    overflow: "scroll"
  },
  inventoryID: {
    marginBottom: 5
  },
  statusInfo: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20
  },
  tag: {
    textAlign: "center",
    alignSelf: "right",
    padding: 20,
    paddingTop: 10,
    paddingBottom: 10,
    fontWeight: "bold",
    color: "white"
  },
  rejectedTag: {
    backgroundColor: "rgb(199,49,49)"
  },
  listedTag: {
    backgroundColor: "rgb(86,136,228)"
  },
  approvedTag: {
    backgroundColor: "rgb(29,178,145)"
  },
  soldTag: {
    backgroundColor: "rgb(164,90,206)"
  },
  pendingTag: {
    color: "black",
    backgroundColor: "rgb(254,234,138)"
  },

  selectSubmit: {
    fontSize: 18,
    cursor: "pointer",
    textAlign: "center"
  },
  submitButtonWrapper: {
    width: 300,
    margin: "0 auto",
    marginTop: 30
  },
  approveAllButton: {
    width: "max-content",
    display: "block",
    margin: "0 auto",
    marginBottom: 10,
    padding: "10px 20px",
    border: "none",
    outline: "none",
    cursor: "pointer",
    color: "black",
    fontSize: 18,
    ":hover": {
      backgroundColor: "rgba(230,230,230)"
    }
  },
  summaryInfo: {
    width: "70%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    height: "max-content",
    marginBottom: 10,
    backgroundColor: "rgb(250,250,250)"
  },
  productSummay: {
    padding: 20,
    backgroundColor: "rgb(244,244,244)",
    height: 100,
    width: "60%",
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  },
  productName: {
    width: "60%",
    marginLeft: 15
  },
  productSize: {
    marginLeft: 15
  },
  statusInfoSummary: {
    padding: 20,
    width: 300,
    height: 100,
    marginBottom: 0
  },
  warningMessage: {
    marginLeft: 30,
    width: 30,
    fontWeight: "bolder",
    color: "red",
    fontSize: 30
  },
  scanItemWrapper: {
    width: "30%",
    margin: "0 auto"
  },
  rejectionReasonContainer: {
    display: "flex"
  },
  rejectionReason: {
    paddingTop: "0.5em"
  },
  printButton: {
    backgroundColor: `${colors.RED(1)}`,
    borderRadius: 4,
    border: 0,
    padding: 16,
    color: "#fff",
    fontSize: 14,
    minWidth: 120,
    width: "30%",
    letterSpacing: 0.7,
    cursor: "pointer",
    outline: "none",
    marginLeft: 10,
    marginBottom: 20
  }
});
const mapStateToProps = state => ({
  modals: state.modals,
  auth: state.auth
});
const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  messageActions: bindActionCreators(MessageActions, dispatch)
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApproveConsignmentModal)
);
