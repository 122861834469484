/***
 * Consignment screen for Solestage
 * @patr -- patrick@quantfive.org
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactTable from "react-table";
import { FormattedMessage, injectIntl } from "react-intl";
import TimeAgo from "javascript-time-ago";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import Button from "../../components/Button";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Redux
import { ModalActions } from "../../redux/modals";
import { JoyrideActions } from "../../redux/joyride";

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// Create relative date/time formatter.
// const timeAgo = new TimeAgo("en-US");

class MissingItems extends React.Component {
  constructor(props) {
    super(props);

    let { joyride, intl } = this.props;

    this.tabs = [
      {
        id: "missing",
        label: intl.formatMessage({
          id: "missing",
          defaultMessage: "Missing"
        })
      },
      {
        id: "extra",
        label: intl.formatMessage({
          id: "extra",
          defaultMessage: "Extra"
        })
      }
    ];

    this.guideData = [
      {
        Consigner: "Johnny Champ",
        Consignment: "#76",
        Date: "a month ago",
        Price: "$900",
        Quantity: 1,
        Status:
          joyride.feature === "list_consignment" ? "completed" : "pending",
        items: [
          {
            active: true,
            clover_id: null,
            condition: "New",
            consignment_order: 76,
            cost: null,
            created_at: "2018-11-03T12:53:38.336007-07:00",
            id: 148660,
            image_link:
              "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg",
            in_transit: false,
            labelled: false,
            listable: false,
            listed: false,
            location: null,
            price: null,
            shelf: "",
            sold: false,
            sold_at: null,
            consigner: {
              billing_address: null,
              consignment_approved: true,
              consignment_rate: 15,
              created_at: "2018-09-24T17:55:32.379915-07:00",
              email: "cu@quantfive.org",
              first_name: "Johnny",
              id: 15,
              last_name: "Champ",
              phone_number: "8502944844",
              return_address: null,
              shipping_address: {
                id: 14,
                state: "CA",
                country: "USA",
                city: "Los Angeles",
                zip_code: "94709"
              },
              updated_at: "2018-11-07T00:00:04.717238-08:00",
              user: 21,
              verified_consigner: true
            },
            consignment: {
              apparel_package_condition: "",
              barcode_printed: false,
              box_condition: "Good Condition",
              clover_item_id: "",
              created_at: "2018-11-03T12:58:53.342228-07:00",
              defects: false,
              discolored: false,
              id: 206,
              missing_insoles: false,
              missing_tags: false,
              order: 77,
              payout: null,
              pending_price: null,
              pictures: [],
              price: {
                id: 201,
                default_price: 90000,
                default_currency: "usd",
                solestage_price: 90000,
                instore_price: 90000
              },
              scuff_marks: false,
              size: "10",
              status: "approved",
              tears: false,
              updated_at: "2018-11-03T13:11:19.096066-07:00",
              yellowing: false
            },
            pictures: [
              {
                id: 20504,
                sku: 20549,
                url:
                  "https://img.alicdn.com/bao/uploaded/i4/78538291/TB232KfdtfJ8KJjy0FeXXXKEXXa_!!78538291.jpg"
              }
            ],
            updated_at: "2018-11-03T12:55:28.734470-07:00",
            sku: {
              active: true,
              barcode: "12345678999",
              brand: "Adidas",
              color: "Beluga",
              created_at: "2018-11-09T08:44:04.436342-08:00",
              default_location: null,
              eu_size: null,
              gender: "M",
              id: 20549,
              item_type: "shoe",
              product_name: "Adidas Yeezy Boost",
              product_name_us: "Adidas Yeezy Boost",
              sku: null,
              style: "508214-660",
              updated_at: "2018-11-09T08:44:04.781280-08:00",
              us_size: "10"
            }
          }
        ],
        request_id: 76
      }
    ];

    let { reconciliationId } = this.props.match.params;
    this.state = {
      id: reconciliationId,
      data: [],
      numPages: 1,
      page: 1,
      pageSize: 20,
      hoveredRow: null,
      activeTab: "missing",
      count: null,
      search: "",
      initNotes: "",
      notes: "",
      isEditing: false,
      submitted: false,
      total_scanned: 0,
      start_date: "",
      submit_date: "",
      location: 0,
      exporting: false
    };
  }

  // search = async e => {
  //   if (e.key === "Enter") {
  //     this.setState({
  //       page: 1,
  //       searching: true
  //     });
  //     if (this.state.activeTab === "pending" && !this.state.search) {
  //       await this.getSessions(1, this.state.pageSize, "pending");
  //     } else if (this.state.activeTab === "completed" && !this.state.search) {
  //       await this.getSessions(1, this.state.pageSize, "completed");
  //     } else {
  //       await this.getSessions();
  //     }
  //     this.setState({
  //       searching: false
  //     });
  //   }
  // };

  /***
   * Gets missing items for the inventory reconciliation
   */
  getItems = (page, pageSize) => {
    const { id } = this.state;
    const params = {
      reconciliationId: id,
      type: "missing_report",
      page,
      pageSize
    };

    return fetch(API.INVENTORY_RECONCILIATION(params), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = [];

        if (res.data) {
          data = res.data.map((item, index) => {
            let picture_url = null;
            if (item.pictures && item.pictures.length > 0) {
              picture_url = item.pictures[0].url;
            }

            return {
              ID: `#${item.id}`,
              Image: picture_url,
              Brand: item.brand,
              "Product Name": item.product_name_us,
              Size: item.us_size,
              Consigner: item.consigner_name,
              "Date Listed": item.listed_at
            };
          });
        }
        this.setState({
          // submitted: !!res.submit_date,
          numPages: Math.ceil(res.count / this.state.pageSize),
          count: res.count,
          data
          // notes: res.notes,
          // initNotes: res.notes,
          // total_scanned: res.total_scanned,
          // start_date: res.start_date,
          // submit_date: res.submit_date,
          // location: res.location,
          // name: res.name
        });
      });
  };

  /***
   * Sets the search value
   */
  searchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    this.setState(
      {
        activeTab: tab,
        page: 1
      },
      () => {
        this.getItems(1, this.state.pageSize);
      }
    );
  };

  /**
   * Update order note
   *
   */
  saveNote = () => {
    let { id, notes } = this.state;
    let params = {
      type: "update_reconciliation_notes",
      reconciliationId: id,
      notes
    };

    return fetch(API.INVENTORY_RECONCILIATION(params), API.PUT_CONFIG())
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        this.setState(prev => {
          return { isEditing: !prev.isEditing, initNotes: prev.notes };
        });
      });
  };

  renderCell = (header, props, index) => {
    switch (header) {
      case "Image":
        return (
          <img
            alt="product"
            src={props.value}
            className={css(styles.productImg)}
          />
        );
      default:
        return <div className={css(styles.cellValue)}>{props.value}</div>;
    }
  };

  componentDidMount = () => {
    if (this.props.joyride.forceJoy) {
      let { joyrideActions } = this.props;
      joyrideActions.setRun({ run: true, inProgress: true });
    }
  };

  onClickBack = () => {
    let { history } = this.props;
    const { id } = this.state;

    history.push(`/reconciliation/${id}`);
  };

  onClickExport = () => {
    this.setState({
      exporting: true
    });
    const { id } = this.state;
    const params = {
      type: "download_missing_report",
      reconciliationId: id
    };

    return fetch(API.INVENTORY_RECONCILIATION(params), API.GET_CONFIG())
      .then(Helpers.checkStatus)
      .then(res => {
        return res.blob();
      })
      .then(res => {
        let link = document.createElementNS(
          "http://www.w3.org/1999/xhtml",
          "a"
        );
        link.href = URL.createObjectURL(res);
        link.download = `missing_inventory_reconciliation_${id}.csv`;
        link.click();
        this.setState({
          exporting: false
        });
      });
  };

  render() {
    let { intl } = this.props;
    let columns = [
      intl.formatMessage({
        id: "id",
        defaultMessage: "ID"
      }),
      intl.formatMessage({ id: "image", defaultMessage: "Image" }),
      intl.formatMessage({ id: "brand", defaultMessage: "Brand" }),
      intl.formatMessage({
        id: "product_name",
        defaultMessage: "Product Name"
      }),
      intl.formatMessage({
        id: "size",
        defaultMessage: "Size"
      }),
      intl.formatMessage({ id: "consigner", defaultMessage: "Consigner" }),
      intl.formatMessage({ id: "date_listed", defaultMessage: "Date Listed" })
    ].map((column, index) => ({
      Header: column
        ? column !== "table-id"
          ? column !== "manage"
            ? column
            : null
          : null
        : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      filterable: false,
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => (
        <div
          className={css(
            styles.valueContainer,
            props.row._index === this.state.hoveredRow && styles.hoveredRow
          )}
          id={
            window.location.search.includes("guide=true") &&
            props.row._index === 0 &&
            "clickRow"
          }
          // onClick={() =>
          //   this.sessionClicked(props.original["ID"].replace("#", ""))
          // }
          onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
        >
          {this.renderCell(props.column.Header, props, index)}
        </div>
      )
    }));

    // let tabs = this.tabs.map((tab, index) => {
    //   return (
    //     <div
    //       className={css(
    //         styles.tab,
    //         this.state.activeTab === tab.id && styles.activeTab
    //       )}
    //       onClick={() => this.setActiveTab(tab.id)}
    //       key={`tab_${tab.id}_${index}`}
    //       // id={tab.id === "completed" && "completedTab"}
    //     >
    //       {tab.label}
    //     </div>
    //   );
    // });

    // let location = this.props.inventory.locations.find(
    //   o => o.id === this.state.location
    // );

    return (
      <div className={css(styles.orders)}>
        <h2 className={css(styles.dashboardTitle)}>
          <FormattedMessage
            id="reconciliation_sessions"
            defaultMessage={`Missing Items (Reconciliation ${this.state.id})`}
          />
        </h2>
        {/* <div className={css(styles.tabs)}>{tabs}</div> */}
        {/* <div className={css(styles.infoWrapper)}>
          <div className={css(styles.infoDetailWrapper)}>
            <div>
              {intl.formatMessage({
                id: "name",
                defaultMessage: "Name: "
              })}
              {this.state.name}
            </div>
            <div>
              {intl.formatMessage({
                id: "location",
                defaultMessage: "Location: "
              })}
              {location && location.name}
            </div>
            <div>
              {intl.formatMessage({
                id: "startDate",
                defaultMessage: "Start Date: "
              })}{" "}
              {this.state.start_date}
            </div>
            {this.state.submit_date != "" && (
              <div>
                {intl.formatMessage({
                  id: "submitDate",
                  defaultMessage: "Submit Date: "
                })}{" "}
                {this.state.submit_date}
              </div>
            )}

            <div>
              {intl.formatMessage({
                id: "total_scanned",
                defaultMessage: "Total Scanned: "
              })}{" "}
              {this.state.total_scanned}
            </div>
          </div>
          <div className={css(styles.reconciliationNoteWrapper)}>
            <textarea
              name={"note"}
              placeholder={"Add notes for this reconciliation"}
              value={this.state.notes}
              className={css(
                styles.reconciliationNote,
                this.state.isEditing ? styles.noteEdit : styles.noteDisplay
              )}
              autoFocus
              disabled={!this.state.isEditing}
              onChange={e => this.setState({ notes: e.target.value })}
            />
            <div className={css(styles.editButtonWrapper)}>
              <button
                onClick={
                  !this.state.isEditing
                    ? () => {
                        this.setState(prev => {
                          return { isEditing: !prev.isEditing };
                        });
                      }
                    : this.saveNote
                }
                className={css(styles.editButton)}
              >
                {this.state.isEditing ? (
                  <FormattedMessage id="save" defaultMessage="Save" />
                ) : (
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                )}
              </button>
              <button
                onClick={() => {
                  this.setState(prev => {
                    return {
                      isEditing: !prev.isEditing,
                      notes: prev.initNotes ? prev.initNotes : ""
                    };
                  });
                }}
                className={css(
                  !this.state.isEditing ? styles.hideButton : styles.editButton
                )}
              >
                <FormattedMessage id="discard" defaultMessage="Discard" />
              </button>
            </div>
          </div>
        </div> */}

        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <div className={css(styles.header)}>
            {/* <Searchbar
              search={this.search}
              searchChange={this.searchChange}
              searching={this.state.searching}
            /> */}
            <Button
              className={styles.button}
              text={intl.formatMessage({
                id: "back",
                defaultMessage: "<< Back"
              })}
              onClick={this.onClickBack}
            />
            <button
              id="export"
              className={css(styles.export)}
              onClick={this.onClickExport}
            >
              <FontAwesomeIcon
                icon={["fas", "file-export"]}
                className={css(styles.fileIcon)}
              />
              {this.state.exporting ? (
                <FontAwesomeIcon
                  icon={["far", "spinner-third"]}
                  className={css(styles.fileIcon)}
                  spin
                />
              ) : (
                <FormattedMessage
                  id="export_consignments"
                  defaultMessage="Export"
                />
              )}
            </button>
          </div>
          <div id="approveConsignment">
            <ReactTable
              className={css(styles.reactTable) + " order-table"}
              id="approveConsignment"
              data={
                window.location.search.includes("guide=true")
                  ? this.guideData
                  : this.state.data
              }
              filterable={true}
              pages={this.state.numPages}
              columns={columns}
              footerClassName={css(styles.tableFooter)}
              defaultPageSize={this.state.pageSize}
              page={this.state.page - 1}
              onPageSizeChange={pageSize => {
                this.setState({
                  pageSize,
                  numPages: Math.ceil(this.state.count / pageSize)
                });
              }}
              onPageChange={pageIndex => {
                this.setState({
                  page: pageIndex + 1
                });
              }}
              minRows={0}
              onFetchData={(state, instance) => {
                if (window.location.search.includes("guide=true")) {
                  return null;
                }
                this.getItems(state.page + 1, state.pageSize);
              }}
              nextText={intl.formatMessage({
                id: "next",
                defaultMessage: "Next"
              })}
              previousText={intl.formatMessage({
                id: "previous",
                defaultMessage: "Previous"
              })}
              noDataText={intl.formatMessage({
                id: "no_items_found",
                defaultMessage: "No items found"
              })}
              manual
            />
          </div>
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  header: {
    display: "flex",
    justifyContent: "space-between"
  },
  orders: {
    textAlign: "center",
    padding: 20,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  status: {
    textTransform: "capitalize"
  },
  table: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
    color: "#212b36",
    borderBottom: "1px solid #ddd"
  },
  productColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    whiteSpace: "pre-wrap"
  },
  itemPhoto: {
    width: 75,
    height: 75,
    paddingBottom: 8
  },
  orderNumber: {
    color: colors.LIGHT_BLUE(1)
  },
  hoveredRow: {
    background: "#eee"
  },
  link: {
    textDecoration: "none"
  },
  unfulfilled: {
    background: "#ffea8a",
    padding: "8px 8px",
    borderRadius: 24
  },
  rejected: {
    padding: "8px 8px",
    borderRadius: 24,
    color: "red"
  },
  fulfilled: {
    padding: "8px 8px",
    borderRadius: 24,
    background: "#dfe3e8"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 16,
    position: "relative"
  },
  tab: {
    marginLeft: 7,
    marginRight: 7,
    opacity: ".3",
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  },
  button: {
    width: 100,
    height: 38,
    fontSize: 16,
    padding: 10
  },
  startSessionButton: {
    width: 180,
    height: 38,
    marginLeft: 20,
    fontSize: 16,
    padding: 10
  },

  infoWrapper: {
    display: "flex",
    marginBottom: 20,
    width: "100%",
    justifyContent: "center",
    height: 150
  },
  infoDetailWrapper: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
    width: "20%",
    boxShadow: " 0px 0px 10px rgba(0,0,0,0.1)",
    marginRight: "2%"
  },

  reconciliationNoteWrapper: {
    marginRight: 100,
    width: "40%",
    backgroundColor: "rgb(250,250,250)",
    boxShadow: " 0px 0px 10px rgba(0,0,0,0.1)",
    padding: 10
  },
  reconciliationNote: {
    boxSizing: "border-box",
    resize: "none",
    width: "100%",
    fontSize: "1.3em",
    height: "60%",
    padding: 10,
    overflowY: "scroll",
    backgroundColor: "rgba(0,0,0,0)"
  },
  noteEdit: {
    backgroundColor: "rgb(255,255,255)",
    border: "none"
  },
  noteDisplay: {
    border: "none"
  },

  editButtonWrapper: {
    marginTop: 10,
    display: "flex",
    width: "100%"
  },
  editButton: {
    padding: "1em 2em",
    border: "none",
    cursor: "pointer",
    letterSpacing: 0.7,
    outline: "none",
    marginRight: 10
  },
  hideButton: {
    display: "none"
  },
  productImg: {
    height: 100,
    marginRight: 32
  },
  export: {
    display: "flex",
    alignItems: "center",
    marginLeft: 8,
    height: 36,
    borderRadius: 4,
    padding: 8,
    border: "1px solid #ddd",
    background: "rgb(245, 245, 245)",
    cursor: "pointer",
    outline: "none",
    fontSize: 14,
    letterSpacing: 0.7,

    ":hover": {
      transform: "scale(1.05)",
      "-webkit-transition": ".5s",
      transition: ".5s"
    }
  }
});

const mapStateToProps = state => ({
  auth: state.auth,
  joyride: state.joyride,
  inventory: state.inventory
});

const mapDispatchToProps = dispatch => ({
  modalActions: bindActionCreators(ModalActions, dispatch),
  joyrideActions: bindActionCreators(JoyrideActions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MissingItems));
