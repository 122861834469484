/***
 * Generic banner that will display error messages
 * @craiglu
 */

import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Configs
import colors from "../config/colors";

export default class ErrorBanner extends React.Component {
  render() {
    return (
      <div className={css(styles.error, this.props.className)}>
        <FontAwesomeIcon
          className={css(styles.exclamation)}
          icon={["fal", "exclamation-triangle"]}
          size="2x"
        />
        {this.props.error}
      </div>
    );
  }
}

var styles = StyleSheet.create({
  error: {
    background: `${colors.RED(1)}`,
    padding: "10px",
    "overflow-wrap": "break-word",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    letterSpacing: ".5px"
  },
  exclamation: {
    color: "#fff",
    // background: '#E7665C',
    padding: "5px 15px",
    borderRadius: "4px",
    marginRight: "10px"
  }
});
