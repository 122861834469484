/***
 * Consignment screen for Solestage
 * @patr -- patrick@quantfive.org
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";

import ReactTable from "react-table";
import { FormattedMessage, injectIntl } from "react-intl";
import TimeAgo from "javascript-time-ago";
import Searchbar from "../../components/inventory/Searchbar";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Redux

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

// Create relative date/time formatter.
// const timeAgo = new TimeAgo("en-US");

class AuditTrail extends React.Component {
  constructor(props) {
    super(props);

    let { intl } = this.props;

    this.tabs = [
      {
        id: "consignment_order",
        label: intl.formatMessage({
          id: "consignment_order",
          defaultMessage: "Consignment Order"
        })
      },
      {
        id: "consignment",
        label: intl.formatMessage({
          id: "consignment",
          defaultMessage: "Consignment"
        })
      },
      {
        id: "inventory",
        label: intl.formatMessage({
          id: "inventory",
          defaultMessage: "Inventory"
        })
      },
      {
        id: "price",
        label: intl.formatMessage({
          id: "price",
          defaultMessage: "Price"
        })
      },
      {
        id: "product",
        label: intl.formatMessage({
          id: "product",
          defaultMessage: "Product"
        })
      },
      {
        id: "payout",
        label: intl.formatMessage({
          id: "payout",
          defaultMessage: "Payout"
        })
      },
      {
        id: "requested_payouts",
        label: intl.formatMessage({
          id: "requested_payouts",
          defaultMessage: "Requested Payouts"
        })
      },
      {
        id: "sku",
        label: intl.formatMessage({
          id: "sku",
          defaultMessage: "Sku"
        })
      }
    ];

    this.state = {
      activeTab: "consignment_order",
      numPages: 1,
      page: 1,
      pageSize: 20,
      hoveredRow: null,
      count: null,
      data: [],
      search: "",
      model: "ConsignmentOrder",
      appLabel: null
    };
  }

  /**
   * sets active tab for dashboard
   * @param  {String} tab -- tab id
   */
  setActiveTab = tab => {
    let model, appLabel;
    switch (tab) {
      case "consignment_order":
        model = "ConsignmentOrder";
        break;
      case "consignment":
        model = "Consignment";
        break;
      case "inventory":
        model = "Inventory";
        break;
      case "price":
        model = "Price";
        break;
      case "product":
        model = "Product";
        break;
      case "payout":
        model = "Payout";
        appLabel = "payment";
        break;
      case "requested_payouts":
        model = "RequestedPayouts";
        appLabel = "payment";
        break;
      case "sku":
        model = "Sku";
        break;
      default:
        model = this.state.model;
        break;
    }

    this.setState(
      {
        activeTab: tab,
        page: 1,
        firstLoad: true,
        items: [],
        search: "",
        model,
        appLabel
      },
      () => {
        this.getLog(1, this.state.pageSize, model);
      }
    );
  };

  /***
   * Searches the backend for SKU, Barcode, Product Name
   */
  search = async e => {
    if (e.key === "Enter") {
      this.setState({
        page: 1,
        searching: true
      });

      await this.getLog(1, this.state.pageSize);
      this.setState({
        searching: false
      });
    }
  };

  /***
   * Gets all audit entries
   */
  getLog = (page, pageSize, newModel = null) => {
    let { model } = this.state;
    if (newModel) {
      model = newModel;
    }
    let params = {
      page,
      pageSize,
      model
    };

    if (this.state.appLabel) {
      params["appLabel"] = this.state.appLabel;
    }

    if (this.state.search) {
      params["search"] = this.state.search;
    }

    this.setState({
      searching: true
    });

    return fetch(API.AUDIT_TRAIL(params), API.GET_CONFIG(false))
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(res => {
        let data = [];
        this.setState({
          searching: false
        });
        if (res.results) {
          data = res.results.map(fields => {
            return {
              ID: `#${fields.object_id}`,
              Object: fields.model,
              Date: new Date(fields.timestamp).toLocaleDateString("en-US"),
              Changes: JSON.parse(fields.changes),
              "User ID": fields.actor,
              User: fields.user
            };
          });
        }
        this.setState({
          numPages: Math.ceil(res.count / this.state.pageSize),
          count: res.count,
          data
        });
      });
  };

  /***
   * Sets the search value
   */
  searchChange = e => {
    this.setState({
      search: e.target.value
    });
  };

  renderCell = (header, props, index) => {
    if (header === "Changes") {
      return (
        <div className={css(styles.cellValue)}>
          {Object.keys(props.value).map(key => (
            <div>{`${key}: From: ${props.value[key][0]} To: ${
              props.value[key][1]
            }`}</div>
          ))}
        </div>
      );
    } else {
      return (
        <div className={css(styles.cellValue)}>
          {props.row[index] ? props.row[index].value : props.value}
        </div>
      );
    }
  };

  render() {
    let { intl } = this.props;
    let columns = [
      intl.formatMessage({
        id: "id",
        defaultMessage: "ID"
      }),
      intl.formatMessage({
        id: "object",
        defaultMessage: "Object"
      }),
      intl.formatMessage({
        id: "date",
        defaultMessage: "Date"
      }),
      intl.formatMessage({
        id: "changes",
        defaultMessage: "Changes"
      }),
      intl.formatMessage({ id: "user", defaultMessage: "User" }),
      intl.formatMessage({ id: "user_id", defaultMessage: "User ID" })
    ].map((column, index) => ({
      Header: column
        ? column !== "table-id"
          ? column !== "manage"
            ? column
            : null
          : null
        : null,
      accessor: column,
      className: css(styles.cell),
      headerClassName: css(
        styles.cellHeader,
        column === "table-id" && styles.headerRowNumber
      ),
      filterable: false,
      minWidth: column === "table-id" ? 50 : 165,
      Cell: props => (
        <div
          className={css(
            styles.valueContainer,
            props.row._index === this.state.hoveredRow && styles.hoveredRow
          )}
          id={
            window.location.search.includes("guide=true") &&
            props.row._index === 0
              ? "clickRow"
              : undefined
          }
          onClick={() => {}}
          onMouseEnter={() => this.setState({ hoveredRow: props.row._index })}
        >
          {this.renderCell(props.column.Header, props, index)}
        </div>
      )
    }));

    let tabs = this.tabs.map((tab, index) => {
      return (
        <div
          className={css(
            styles.tab,
            this.state.activeTab === tab.id && styles.activeTab
          )}
          onClick={() => this.setActiveTab(tab.id)}
          key={`tab_${tab.id}_${index}`}
        >
          {tab.label}
        </div>
      );
    });

    return (
      <div className={css(styles.orders)}>
        <h2 className={css(styles.dashboardTitle)}>
          <FormattedMessage id="audit_trail" defaultMessage="Audit Trail" />
        </h2>
        <div className={css(styles.tabContainer)}>{tabs}</div>
        <div
          className={css(styles.table)}
          onMouseLeave={() => this.setState({ hoveredRow: null })}
        >
          <div className={css(styles.search)} id="search">
            <Searchbar
              search={this.search}
              searchChange={this.searchChange}
              searching={this.state.searching}
            />
          </div>
          <div id="auditTrail">
            <ReactTable
              className={css(styles.reactTable) + " order-table"}
              id="auditTrail"
              data={this.state.data}
              filterable={true}
              pages={this.state.numPages}
              columns={columns}
              footerClassName={css(styles.tableFooter)}
              defaultPageSize={this.state.pageSize}
              page={this.state.page - 1}
              onPageSizeChange={pageSize => {
                this.setState({
                  pageSize,
                  numPages: Math.ceil(this.state.count / pageSize)
                });
              }}
              onPageChange={pageIndex => {
                this.setState({
                  page: pageIndex + 1
                });
              }}
              minRows={0}
              onFetchData={(state, instance) => {
                this.getLog(state.page + 1, state.pageSize);
              }}
              nextText={intl.formatMessage({
                id: "next",
                defaultMessage: "Next"
              })}
              previousText={intl.formatMessage({
                id: "previous",
                defaultMessage: "Previous"
              })}
              noDataText={intl.formatMessage({
                id: "no_items_found",
                defaultMessage: "No items found"
              })}
              manual
            />
          </div>
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  orders: {
    textAlign: "center",
    padding: 20,
    background: "rgb(249, 249, 249)",
    height: "100%"
  },
  status: {
    textTransform: "capitalize"
  },
  table: {
    background: "#fff",
    padding: 20,
    borderRadius: 4,
    boxShadow: "rgba(129, 148, 167, 0.39) 0px 3px 10px 0px"
  },
  valueContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: 16,
    color: "#212b36",
    borderBottom: "1px solid #ddd"
  },
  productColumn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    whiteSpace: "pre-wrap"
  },
  itemPhoto: {
    width: 75,
    height: 75,
    paddingBottom: 8
  },
  orderNumber: {
    color: colors.LIGHT_BLUE(1)
  },
  hoveredRow: {
    background: "#eee"
  },
  link: {
    textDecoration: "none"
  },
  unfulfilled: {
    background: "#ffea8a",
    padding: "8px 8px",
    borderRadius: 24
  },
  rejected: {
    padding: "8px 8px",
    borderRadius: 24,
    color: "red"
  },
  fulfilled: {
    padding: "8px 8px",
    borderRadius: 24,
    background: "#dfe3e8"
  },
  cellHeader: {
    borderRight: 0,
    borderBottom: "1px solid #eee",
    padding: "12px 5px",
    outline: "none",
    fontVariant: "small-caps",
    fontWeight: "bold",
    overflow: "visible"
  },
  tabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 16,
    position: "relative"
  },
  tab: {
    marginLeft: 7,
    marginRight: 7,
    opacity: ".3",
    cursor: "pointer",

    ":hover": {
      opacity: 1,
      color: colors.RED(1)
    }
  },
  activeTab: {
    opacity: 1,
    borderBottom: "2px solid #000",
    paddingBottom: 5
  },
  button: {
    width: 200,
    position: "absolute",
    right: 0,
    bottom: 5
  },
  cellValue: {
    textAlign: "center",
    width: "100%",
    boxSizing: "border-box",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
});

export default injectIntl(AuditTrail);
