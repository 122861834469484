import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";

// Config
import colors from "../config/colors";

export default class Input extends React.Component {
  render() {
    return (
      <div
        className={css(styles.inputContainer, this.props.containerClassName)}
      >
        {this.props.icon && this.props.icon}
        <input
          {...this.props}
          ref={ref => (this.inputRef = ref)}
          className={css(
            styles.input,
            this.props.className,
            this.props.icon && styles.iconPadding
          )}
        />
      </div>
    );
  }
}

var styles = StyleSheet.create({
  inputContainer: {
    position: "relative",
    width: "100%"
  },
  iconPadding: {
    paddingLeft: 35
  },
  input: {
    width: "100%",
    borderRadius: 4,
    outline: "none",
    padding: 16,
    fontSize: 16,
    boxSizing: "border-box",
    border: "2px solid #ddd",
    background: "rgb(245, 245, 245)",

    ":focus": {
      transition: ".3s ease-in-out",
      background: "rgb(251, 251, 251)",
      border: `2px solid ${colors.LIGHT_BLUE(1)}`
    },

    "::placeholder": {
      opacity: 0.5
    }
  }
});
