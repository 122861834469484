import React from "react";

import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PayoutStatus = props => {
  let payoutStatuses = {
    closed: {
      icon: "times-circle",
      color: "red"
    },
    reversed: {
      icon: "sync",
      color: "darkgreen"
    },
    paid: {
      icon: "check-circle",
      color: "green"
    },
    partially_paid: {
      icon: "check-circle",
      color: "#fea301"
    },
    open: {
      icon: "sync",
      color: "#fea301"
    },
    initiated: {
      icon: "sync",
      color: "#fea301"
    }
  };

  let status = payoutStatuses[props.payout.state];

  return (
    <>
      {status && (
        <div className={css(styles.statusColumn)}>
          <FontAwesomeIcon icon={status.icon} color={status.color} />
          <span className={css(styles.statusText)}>{props.payout.state}</span>
        </div>
      )}
      {!status && (
        <div className={css(styles.statusColumn)}>
          <span className={css(styles.statusText)}>{props.payout.state}</span>
        </div>
      )}
    </>
  );
};

var styles = StyleSheet.create({
  statusText: {
    margin: "0em 0.5em",
    textTransform: "capitalize"
  }
});

export default PayoutStatus;
