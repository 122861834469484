/***
 *
 * @patr
 */
import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage } from "react-intl";

// import { bindActionCreators } from 'redux';
import TimeAgo from "javascript-time-ago";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import Barcode from "../inventory/Barcode";
import BarcodeModal from "../modals/AddProduct/BarcodeModal";
import CurrencyDisplay from "../CurrencyDisplay";

// Redux
import { MessageActions } from "../../redux/message";

// Config
import colors from "../../config/colors";
import API from "../../config/api";
import Helpers from "../../redux/helpers";

// Load locale-specific relative date/time formatting rules.
import en from "javascript-time-ago/locale/en";
import { bindActionCreators } from "redux";

// Add locale-specific relative date/time formatting rules.
TimeAgo.locale(en);

class Consignment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      printBarcode: false,
      markingAsSold: false,
      syncingClover: false
    };
  }

  syncClover = e => {
    let { messageActions, intl } = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      syncingClover: true
    });

    return fetch(API.SYNC_WITH_CLOVER({ id: this.props.id }), API.POST_CONFIG())
      .then(Helpers.checkStatus)
      .then(_ => {
        messageActions.setMessage(
          intl.formatMessage({
            id: "clover_synced",
            defaultMessage: "Synced to Clover"
          })
        );
        messageActions.showMessage({ show: true });
        this.setState({
          syncingClover: false
        });
      });
  };

  renderListedText = () => {
    let { intl } = this.props;
    if (this.props.sold) {
      return `${intl.formatMessage({
        id: "sold",
        defaultMessage: "Sold"
      })} ${moment(this.props.sold_at || this.props.updated_at).format("L")}`;
    }
    return `${intl.formatMessage({
      id: this.props.status,
      defaultMessage: this.props.deleted ? "deleted" : this.props.status
    })} ${new Date(
      this.props.deleted
        ? this.props.updated_at
        : this.props[`${this.props.status}_at`]
    ).toLocaleDateString("en-US")}`;
  };

  /***
   * On unmount of barcode modal
   */
  printFinished = () => {
    this.setState({
      printBarcode: false
    });

    let params = {
      barcode_printed: true
    };

    return fetch(
      API.CONSIGNMENT({ id: this.props.consignment_id, simple: true }),
      API.PUT_CONFIG(params)
    )
      .then(Helpers.checkStatus)
      .then(Helpers.parseJSON)
      .then(() => {
        // this.props.removeConsignment(this.props.id);
      });
  };

  print = e => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({
      printBarcode: true
    });
  };

  render() {
    return (
      <div
        className={css(styles.productContainer)}
        onClick={
          this.props.openManagementModal &&
          !this.props.deleted &&
          !this.props.sold
            ? this.props.openManagementModal
            : null
        }
      >
        <div className={css(styles.touchable)}>
          <div
            className={css(styles.consignment)}
            contentContainerStyle={styles.product}
          >
            <div className={css(styles.listing)}>
              <div className={css(styles.title)}>
                <div className={css(styles.info, styles.consignmentText)}>
                  ID #{this.props.id}
                </div>
                <div
                  className={
                    this.props.sold
                      ? css(styles.info, styles.soldText)
                      : css(styles.info, styles.listingText)
                  }
                >
                  {this.renderListedText()}
                </div>
              </div>
            </div>
            <div className={css(styles.photoGroup)}>
              <div
                className={css(styles.item)}
                key={`${this.props.id}_order_item`}
              >
                {this.props.pictures && this.props.pictures[0] && (
                  <img
                    className={css(styles.img)}
                    src={this.props.pictures[0].url}
                    alt={this.props.product_name}
                  />
                )}
                <div className={css(styles.text)}>
                  <div className={css(styles.productName)}>
                    {this.props.product_name}
                  </div>
                  {this.props.size && (
                    <div className={css(styles.size)}>sz {this.props.size}</div>
                  )}
                  {this.props.conditions && (
                    <div className={css(styles.condition)}>
                      {this.props.conditions.join(", ")}
                    </div>
                  )}
                </div>
                {this.props.conditions.includes("used") && (
                  <button disabled>USED</button>
                )}
              </div>
            </div>
            {this.props.print && !this.props.deleted && !this.props.display && (
              <>
                <div className={css(styles.showPriceContainer)}>
                  <input
                    type="checkbox"
                    onChange={this.props.hidePriceChange}
                  />
                  <span>Show Price</span>
                </div>
                <div className={css(styles.print)}>
                  <button
                    className={css(styles.printButton, styles.manageButton)}
                  >
                    <FormattedMessage
                      id="manage_consignment"
                      defaultMessage={`Manage Consignment`}
                    />
                  </button>
                  <button
                    className={css(styles.printButton)}
                    onClick={this.props.print && this.print}
                  >
                    <FormattedMessage
                      id="print_label"
                      defaultMessage={`Print Label`}
                    />
                  </button>

                  <button
                    className={css(styles.printButton, styles.cloverSync)}
                    onClick={this.syncClover}
                  >
                    {this.state.syncingClover ? (
                      <FontAwesomeIcon
                        spin
                        icon={["far", "spinner-third"]}
                        className={css(styles.icon)}
                      />
                    ) : (
                      <FormattedMessage
                        id="sync_clover"
                        defaultMessage={`Sync with Clover`}
                      />
                    )}
                  </button>
                </div>
              </>
            )}
            <div className={css(styles.bottom)}>
              <div className={css(styles.consigner)}>
                {this.props.consigner
                  ? this.props.consigner.first_name +
                    " " +
                    this.props.consigner.last_name
                  : null}
              </div>
              {this.props.consignment_order_id && (
                <div>Consignment Order #{this.props.consignment_order_id}</div>
              )}
              <div className={css(styles.price)}>
                Price:
                <CurrencyDisplay price={this.props.price / 100} />
              </div>
              {/* <div className={css(styles.price)}>
                Cost:
                {this.props.cost && (
                  <CurrencyDisplay price={this.props.cost / 100} />
                )}
              </div> */}
            </div>
            {this.props.deleted && this.props.listed_at && (
              <div>
                <span>listed </span>
                {new Date(this.props.listed_at).toLocaleDateString("en-US")}
              </div>
            )}
            {this.props.deleted && (
              <div>
                <span>Deleted Reason: </span>
                {this.props.deletedReason}
              </div>
            )}
          </div>
        </div>
        {this.props.print && this.state.printBarcode && (
          <BarcodeModal
            unmount={this.printFinished}
            component={
              <Barcode
                hidePrice={this.props.hidePrice}
                name={this.props.product_name}
                conditions={this.props.conditions}
                price={this.props.price / 100}
                barcode={this.props.barcode}
                size={this.props.size}
                color={this.props.color}
                style={this.props.style}
                consigner={
                  this.props.consigner.first_name +
                  " " +
                  this.props.consigner.last_name
                }
              />
            }
          />
        )}
      </div>
    );
  }
}
var styles = StyleSheet.create({
  showPriceContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "5px"
  },
  productContainer: {
    width: "100%",
    backgroundColor: "#fff",
    // paddingBottom: 24,
    paddingTop: 8,
    position: "relative"
  },
  quantity: {
    position: "absolute",
    right: 0,
    opacity: 0.7
  },
  touchable: {
    height: "100%"
  },
  cloverSync: {
    background: "#65c174",
    marginLeft: 16
  },
  productName: {
    letterSpacing: 0.7
  },
  size: {
    letterSpacing: 0.7,
    opacity: 0.7
  },
  text: {
    paddingLeft: 16,
    paddingRight: 8,
    flex: 1
  },
  print: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap"
  },
  printButton: {
    backgroundColor: colors.DARK_BLUE(1),
    borderRadius: 4,
    border: 0,
    padding: 16,
    color: "#fff",
    fontSize: 14,
    minWidth: 120,
    letterSpacing: 0.7,
    cursor: "pointer",
    outline: "none",
    ":hover": {
      transform: "scale(1.08)"
    }
  },
  manageButton: {
    backgroundColor: colors.GREY(1),
    marginRight: 16
  },
  product: {
    paddingTop: 8,
    paddingBottom: 8,
    alignItems: "center",
    height: "100%"
  },
  photoGroup: {
    width: "100%",
    overflow: "hidden",
    paddingLeft: 16,
    paddingRight: 16,
    boxSizing: "border-box"
  },
  item: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingBottom: 16,
    paddingTop: 16
  },
  bottom: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    marginTop: 8
  },
  listing: {
    marginBottom: 8,
    alignItems: "center",
    width: "100%"
  },
  listingText: {
    letterSpacing: 0.7
  },
  soldText: {
    letterSpacing: 0.7,
    color: "red"
  },
  consignmentText: {
    letterSpacing: 0.7
  },
  title: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 8,
    opacity: 0.5
  },
  img: {
    height: 70,
    width: 70,
    borderRadius: 4,
    objectFit: "contain"
  },
  sku: {
    fontSize: 14,
    opacity: 0.7,
    marginTop: 4,
    textAlign: "center"
  },
  priceBanner: {
    borderRadius: 50
  },
  info: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center"
  },
  firstInfo: {
    marginTop: 16
  },
  price: {
    opacity: 1,
    fontSize: 15,
    letterSpacing: 0.7,
    textAlign: "right"
  },
  status: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 5
  },
  pendingPriceBanner: {
    backgroundColor: "rgba(255,165,0, .8)"
  },
  consignmentInfo: {
    paddingLeft: 12,
    paddingRight: 12
  }
});

const mapStateToProps = state => ({
  // auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  messageActions: bindActionCreators(MessageActions, dispatch)
  // authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Consignment));
