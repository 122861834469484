import React from "react";

//NPM
import ReactTags from "react-tag-autocomplete";
import { injectIntl } from "react-intl";

//Style
import "../../stylesheets/ReactTags.css";

class VariantTags extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      suggestions: []
    };
    this.shoes = [
      { id: "1", name: "1" },
      { id: "1Y", name: "1Y" },
      { id: "1.5Y", name: "1.5Y" },
      { id: "2Y", name: "2Y" },
      { id: "2.5Y", name: "2.5Y" },
      { id: "3Y", name: "3Y" },
      { id: "3.5Y", name: "3.5Y" },
      { id: "4Y", name: "4Y" },
      { id: "4.5Y", name: "4.5Y" },
      { id: "5Y", name: "5Y" },
      { id: "5.5Y", name: "5.5Y" },
      { id: "6Y", name: "6Y" },
      { id: "6.5Y", name: "6.5Y" },
      { id: "7Y", name: "7Y" },
      { id: "1C", name: "1C" },
      { id: "1.5C", name: "1.5C" },
      { id: "2C", name: "2C" },
      { id: "2.5C", name: "2.5C" },
      { id: "3C", name: "3C" },
      { id: "3.5C", name: "3.5C" },
      { id: "4C", name: "4C" },
      { id: "4.5C", name: "4.5C" },
      { id: "5C", name: "5C" },
      { id: "5.5C", name: "5.5C" },
      { id: "6C", name: "6C" },
      { id: "6.5C", name: "6.5C" },
      { id: "7C", name: "7C" },
      { id: "7.5C", name: "7.5C" },
      { id: "8C", name: "8C" },
      { id: "8.5C", name: "8.5C" },
      { id: "9C", name: "9C" },
      { id: "9.5C", name: "9.5C" },
      { id: "10C", name: "10C" },
      { id: "10.5C", name: "10.5C" },
      { id: "11C", name: "11C" },
      { id: "11.5C", name: "11.5C" },
      { id: "12C", name: "12C" },
      { id: "12.5C", name: "12.5C" },
      { id: "13C", name: "13C" },
      { id: "13.5C", name: "13.5C" },
      { id: "1K", name: "1K" },
      { id: "1.5K", name: "1.5K" },
      { id: "2K", name: "2K" },
      { id: "2.5K", name: "2.5K" },
      { id: "3K", name: "3K" },
      { id: "3.5K", name: "3.5K" },
      { id: "4K", name: "4K" },
      { id: "4.5K", name: "4.5K" },
      { id: "5K", name: "5K" },
      { id: "5.5K", name: "5.5K" },
      { id: "6K", name: "6K" },
      { id: "6.5K", name: "6.5K" },
      { id: "7K", name: "7K" },
      { id: "7.5K", name: "7.5K" },
      { id: "8K", name: "8K" },
      { id: "8.5K", name: "8.5K" },
      { id: "9K", name: "9K" },
      { id: "9.5K", name: "9.5K" },
      { id: "10K", name: "10K" },
      { id: "10.5K", name: "10.5K" },
      { id: "11K", name: "11K" },
      { id: "11.5K", name: "11.5K" },
      { id: "12K", name: "12K" },
      { id: "12.5K", name: "12.5K" },
      { id: "13K", name: "13K" },
      { id: "13.5K", name: "13.5K" },
      { id: "1.5", name: "1.5" },
      { id: "2", name: "2" },
      { id: "2.5", name: "2.5" },
      { id: "3", name: "3" },
      { id: "3.5", name: "3.5" },
      { id: "4", name: "4" },
      { id: "4.5", name: "4.5" },
      { id: "5", name: "5" },
      { id: "5.5", name: "5.5" },
      { id: "6", name: "6" },
      { id: "6.5", name: "6.5" },
      { id: "7", name: "7" },
      { id: "7.5", name: "7.5" },
      { id: "8", name: "8" },
      { id: "8.5", name: "8.5" },
      { id: "9", name: "9" },
      { id: "9.5", name: "9.5" },
      { id: "10", name: "10" },
      { id: "10.5", name: "10.5" },
      { id: "11", name: "11" },
      { id: "11.5", name: "11.5" },
      { id: "12", name: "12" },
      { id: "12.5", name: "12.5" },
      { id: "13", name: "13" },
      { id: "13.5", name: "13.5" },
      { id: "14", name: "14" },
      { id: "14.5", name: "14.5" },
      { id: "15", name: "15" },
      { id: "15.5", name: "15.5" },
      { id: "16", name: "16" },
      { id: "16.5", name: "16.5" },
      { id: "17", name: "17" },
      { id: "17.5", name: "17.5" }
    ];
    this.accessorySizes = [
      { id: "N/A", name: "N/A" },
      { id: "O/S", name: "O/S" },
      { id: "XXS", name: "XXS" },
      { id: "XS", name: "XS" },
      { id: "S", name: "S" },
      { id: "M", name: "M" },
      { id: "L", name: "L" },
      { id: "XL", name: "XL" },
      { id: "XXL", name: "XXL" },
      { id: "XXXL", name: "XXXL" }
    ];
    this.general = [
      { id: "N/A", name: "N/A" },
      { id: "O/S", name: "O/S" },
      { id: "XXS", name: "XXS" },
      { id: "XS", name: "XS" },
      { id: "S", name: "S" },
      { id: "M", name: "M" },
      { id: "L", name: "L" },
      { id: "XL", name: "XL" },
      { id: "XXL", name: "XXL" },
      { id: "XXXL", name: "XXXL" }
    ];

    this.pants = [
      { id: "XXS", name: "XXS" },
      { id: "XS", name: "XS" },
      { id: "S", name: "S" },
      { id: "M", name: "M" },
      { id: "L", name: "L" },
      { id: "XL", name: "XL" },
      { id: "XXL", name: "XXL" },
      { id: "XXXL", name: "XXXL" },
      { id: "28W", name: "28W" },
      { id: "29W", name: "29W" },
      { id: "30W", name: "30W" },
      { id: "31W", name: "31W" },
      { id: "32W", name: "32W" },
      { id: "33W", name: "33W" },
      { id: "34W", name: "34W" },
      { id: "35W", name: "35W" },
      { id: "36W", name: "36W" },
      { id: "28L", name: "28L" },
      { id: "29L", name: "29L" },
      { id: "30L", name: "30L" },
      { id: "31L", name: "31L" },
      { id: "32L", name: "32L" },
      { id: "33L", name: "33L" },
      { id: "34L", name: "34L" },
      { id: "35L", name: "35L" },
      { id: "36L", name: "36L" }
    ];
  }

  componentDidMount() {
    if (this.props.type) {
      switch (this.props.type) {
        case "shoe":
          if (this.state.type !== "shoe") {
            this.setState({
              suggestions: this.shoes,
              type: "shoe",
              typeID: this.props.type
            });
          }
          break;
        case "pants":
          if (this.state.type !== "pants") {
            this.setState({
              suggestions: this.pants,
              type: "pants",
              typeID: this.props.type
            });
          }
          break;
        case "accessory":
          if (this.state.type !== "accessory") {
            this.setState({
              suggestions: this.accessorySizes,
              type: "accessory",
              typeID: this.props.type
            });
          }
          break;
        default:
          if (this.state.type !== "general") {
            this.setState({
              suggestions: this.general,
              type: "general",
              typeID: this.props.type
            });
          }
      }
    }
  }

  componentDidUpdate() {
    switch (this.props.type) {
      case "shoe":
        if (this.state.type !== "shoe") {
          this.setState({
            suggestions: this.shoes,
            type: "shoe",
            typeID: this.props.type
          });
        }
        break;
      case "pants":
        if (this.state.type !== "pants") {
          this.setState({
            suggestions: this.pants,
            type: "pants",
            typeID: this.props.type
          });
        }
        break;
      case "accessory":
        if (this.state.type !== "accessory") {
          this.setState({
            suggestions: this.accessorySizes,
            type: "accessory",
            typeID: this.props.type
          });
        }
        break;
      default:
        if (this.state.type !== "general") {
          this.setState({
            suggestions: this.general,
            type: "general",
            typeID: this.props.type
          });
        }
    }
  }

  render() {
    // let styles = StyleSheet.create({
    //   reactTags: {
    //     display: "flex",
    //     flexDirection: "row",
    //     alignItems: "center",
    //     flexWrap: "wrap",
    //     minHeight: 38,
    //     width: 400,
    //     borderRadius: 3,
    //     outline: "none",
    //     background: "white",
    //     fontSize: 15,
    //     fontWeight: 200,
    //     cursor: "text",
    //     border: "1px solid #ddd",
    //     transition: "all 0.30s ease-in-out",
    //     paddingTop: 5,
    //     paddingLeft: 10,
    //     ":focus": {
    //       border: `1px solid ${colors.LIGHT_BLUE(1)}`,
    //       boxShadow: `0px 0px 5px  ${colors.LIGHT_BLUE(1)}`
    //     }
    //   },
    //   searchInput: {
    //     maxWidth: "100%",
    //     margin: 0,
    //     padding: 0,
    //     border: 0,
    //     outline: "none",
    //     background: "white",
    //     fontSize: "inherit",
    //     lineHeight: "inherit"
    //   }
    // });
    let { intl } = this.props;
    return (
      <ReactTags
        tags={this.props.tags}
        suggestions={this.state.suggestions}
        minQueryLength={1}
        maxSuggestionsLength={8}
        placeholderText={intl.formatMessage({
          id: "add_new_size",
          defaultMessage: "Add new size"
        })}
        onDelete={this.props.handleDelete}
        onAddition={this.props.handleAddition}
      />
    );
  }
}

export default injectIntl(VariantTags);
