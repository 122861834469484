/***
 * The sign up form. This is only the form element for signup.
 * @patr + @craiglu
 */

import React from "react";

// NPM Modules
import { StyleSheet, css } from "aphrodite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { injectIntl } from "react-intl";

// Components
import Input from "../Input";
import Button from "../Button";
import ErrorBanner from "../ErrorBanner";

// Configs
import colors from "../../config/colors";

const SIGNUP_DELAY = 500;

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    let { intl } = this.props;

    this.inputsOne = [
      {
        id: "first_name",
        placeholder: intl.formatMessage({
          id: "profile_first_name",
          defaultMessage: "First Name"
        }),
        minlength: "0",
        ref: this.firstName
      },
      {
        id: "last_name",
        placeholder: intl.formatMessage({
          id: "profile_last_name",
          defaultMessage: "Last Name"
        }),
        minlength: "0",
        ref: this.lastName
      },
      {
        id: "email",
        placeholder: intl.formatMessage({
          id: "profile_email",
          defaultMessage: "Email"
        }),
        minlength: "0",
        ref: this.email
      },
      {
        id: "password",
        placeholder: intl.formatMessage({
          id: "create_password",
          defaultMessage: "Create your password"
        }),
        minlength: "8",
        ref: this.password
      }
    ];

    this.state = {
      errorMessage: null,
      signingUp: false,
      page: 0,
      params: []
    };
  }

  /***
   * Submit the signup form to the backend
   */
  submit = e => {
    e.preventDefault();

    this.setState({
      signingUp: true,
      errorMessage: null
    });

    // let tempParams = [...this.state.params, ...params]

    this.props.register(this.props.auth.signupInfo).then(user => {
      if (user && user.isLoggedIn) {
        this.props.history.push("/dashboard");
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    clearTimeout(this.registerTimeout);
    clearTimeout(this.errorTimeout);

    // Delay signup by ~700ms
    if (this.props.auth.error !== nextProps.auth.error) {
      this.errorTimeout = setTimeout(() => {
        this.setError(nextProps.auth.error);
      }, SIGNUP_DELAY);
    }

    // Delay signup by ~700ms
    if (
      this.props.auth.isFetchingRegister !==
        nextProps.auth.isFetchingRegister &&
      !nextProps.auth.isFetchingRegister
    ) {
      this.registerTimeout = setTimeout(() => {
        this.setState({
          signingUp: nextProps.auth.isFetchingRegister
        });
      }, SIGNUP_DELAY);
    }
  }

  /***
   * Set the error messsage for signup
   * @param error dict -- the error message from sending signup form
   */
  setError(error) {
    if (error.email) {
      let errorMessage = error.email[0];
      this.setState({
        errorMessage: errorMessage
      });
    } else {
      let errorMessage = error;
      this.setState({
        errorMessage: errorMessage
      });
    }
  }

  render() {
    let { intl } = this.props;
    let inputsOne = this.inputsOne.map((input, index) => {
      return (
        <div
          className={css(styles.addressInput)}
          key={`inputs_one${input.id}_${index}`}
        >
          <Input
            ref={ref => (input.ref = ref)}
            name={input.id}
            type={input.id}
            autoComplete={input.id}
            pattern={`.{${input.minlength},}`}
            placeholder={input.placeholder}
            autoFocus={index === 0}
            is_phone={input.id === "phone"}
            value={
              this.props.auth.signupInfo && this.props.auth.signupInfo[input.id]
            }
            onChange={this.props.inputChange}
            maxLength={input.maxlength ? input.maxlength : null}
            required
          />
        </div>
      );
    });

    return (
      <form className={css(styles.signup)} onSubmit={this.submit}>
        {this.state.errorMessage ? (
          <div className={css(styles.errorContainer)}>
            <ErrorBanner error={this.state.errorMessage} />
          </div>
        ) : null}
        <div className={css(styles.execSignup)}>
          <div className={css(styles.addressInputContainer)}>{inputsOne}</div>
        </div>
        <Button
          text={
            !this.state.signingUp ? (
              intl.formatMessage({ id: "sign_up", defaultMessage: "Sign up" })
            ) : (
              <FontAwesomeIcon
                spin
                icon={["far", "spinner-third"]}
                className={css(styles.icon)}
              />
            )
          }
        />
      </form>
    );
  }
}

let styles = StyleSheet.create({
  signupContainer: {},
  signup: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    maxWidth: "100%",
    flexDirection: "column",

    "@media only screen and (min-width: 320px)": {
      width: "100%"
    },

    "@media only screen and (min-width: 768px)": {
      width: "440px"
    }
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "15px"
  },
  addressInputContainer: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  addressInput: {
    display: "flex",
    width: "100%",
    marginBottom: "15px"
  },
  inputLabel: {
    fontSize: "16px",
    color: "#fff",
    marginBottom: "5px",
    fontFamily: "'Open Sans', sans-serif"
  },
  errorMessage: {
    fontSize: "14px",
    fontFamily: "'Open Sans', sans-serif",
    color: "#ef3434",
    textAlign: "center",
    marginBottom: "20px"
  },
  errorContainer: {
    width: "100%",
    marginBottom: "10px",
    fontFamily: "'Open Sans', sans-serif"
  },
  buttonColor: {
    background: `${colors.RED(1)}`
  },
  managerSignup: {
    width: "100%"
  },

  execSignup: {
    width: "100%"
  }
});

export default injectIntl(SignupForm);
