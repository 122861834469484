import React, { Component } from "react";
import { StyleSheet, css } from "aphrodite";
import IMAGECOMING from "../assets/imagecoming.jpg";

export default class ConsignmentItem extends Component {
  render() {
    return (
      <div
        onClick={this.props.onActiveTab}
        className={css(
          styles.tab,
          this.props.active ? styles.activeTab : styles.inActiveTab,
          this.props.status === "rejected"
            ? styles.rejectedTab
            : this.props.status === "listed"
            ? styles.listedTab
            : this.props.status === "approved"
            ? styles.approvedTab
            : this.props.status === "sold"
            ? styles.soldTab
            : this.props.status === "pending"
            ? styles.pendingTab
            : styles.submitTab
        )}
      >
        <div className={css(styles.infoWrapper)}>
          {this.props.status !== "submit" && this.props.status !== "print" && (
            <img
              className={css(styles.productImg)}
              alt={this.props.name}
              src={this.props.picture ? this.props.picture : IMAGECOMING}
            />
          )}

          <p>{this.props.name}</p>
        </div>
      </div>
    );
  }
}

var styles = StyleSheet.create({
  tab: {
    width: 130,
    height: "max-content",
    textAlign: "center",
    marginRight: 30,
    cursor: "pointer",
    padding: 6,
    transition: ".5s",
    backgroundColor: "white",
    fontSize: ".8em",
    marginBottom: 10
  },
  activeTab: {
    boxShadow: "0px 0px 20px rgba(0,0,0,0.3)"
  },
  inActiveTab: {
    boxShadow: "none"
  },
  rejectedTab: {
    border: "5px solid rgb(199,49,49)"
  },
  listedTab: {
    border: "5px solid rgb(86,136,228)"
  },
  approvedTab: {
    border: "5px solid rgb(29,178,145)"
  },
  soldTab: {
    border: "5px solid rgb(164,90,206)"
  },
  pendingTab: {
    border: "5px solid rgb(254,234,138)"
  },
  submitTab: {
    border: `5px solid rgb(0,0,0)`,
    width: 100,
    fontSize: "em"
  },
  infoWrapper: {},
  productImg: {
    width: 70,
    height: 70
  }
});
