/**
 * Module define all API paths
 * author: @patr -- patrick@quantfive.org
 */

const PRODUCTION_SITE = "backend.solestage.com";
const LOCALHOST = "localhost:8000";
const STAGING_SITE = "staging.solestage.com";

export function getShopifyUrl() {
  if (process.env.REACT_APP_ENV === "staging") {
    return process.env.REACT_APP_SHOPIFY_URL_STAGING;
  } else if (process.env.NODE_ENV === "production") {
    return process.env.REACT_APP_SHOPIFY_URL;
  } else {
    return process.env.REACT_APP_SHOPIFY_URL_STAGING;
  }
}

export function getConsignerIds() {
  if (process.env.REACT_APP_ENV === "staging") {
    return {
      warehouseId: process.env.REACT_APP_CONSIGNER_ID_WAREHOUSE_STAGING,
      warehouseEmail: process.env.REACT_APP_CONSIGNER_EMAIL_WAREHOUSE,
      fairfaxId: process.env.REACT_APP_CONSIGNER_ID_FAIRFAX,
      fairfaxEmail: process.env.REACT_APP_CONSIGNER_EMAIL_FAIRFAX
    };
  } else if (process.env.NODE_ENV === "production") {
    return {
      warehouseId: process.env.REACT_APP_CONSIGNER_ID_WAREHOUSE,
      warehouseEmail: process.env.REACT_APP_CONSIGNER_EMAIL_WAREHOUSE,
      fairfaxId: process.env.REACT_APP_CONSIGNER_ID_FAIRFAX,
      fairfaxEmail: process.env.REACT_APP_CONSIGNER_EMAIL_FAIRFAX
    };
  } else {
    return {
      warehouseId: process.env.REACT_APP_CONSIGNER_ID_WAREHOUSE_STAGING,
      warehouseEmail: process.env.REACT_APP_CONSIGNER_EMAIL_WAREHOUSE,
      fairfaxId: process.env.REACT_APP_CONSIGNER_ID_FAIRFAX,
      fairfaxEmail: process.env.REACT_APP_CONSIGNER_EMAIL_FAIRFAX
    };
  }
}

/**
 * getApiRoot() Returns the base URL for api to connect to.  If  API_ROOT
 * is provided as an env var, it will take precedence.
 * @return {string} The hostname of the backend server
 */
function getApiRoot() {
  if (process.env.REACT_APP_API_ROOT) {
    return process.env.REACT_APP_API_ROOT;
  } else if (process.env.REACT_APP_ENV === "staging") {
    return "https://" + STAGING_SITE + "/api/";
  } else if (process.env.NODE_ENV === "production") {
    return "https://" + PRODUCTION_SITE + "/api/";
  } else {
    return "http://" + LOCALHOST + "/api/";
  }
}

const hasWhiteSpace = s => {
  return s.indexOf(" ") >= 0;
};

const BASE_URL = getApiRoot();

/**
 * Function to prep the URL for querystring / url
 * @param { String } url -- the URL we want to manipulate
 * @param { Object } params -- params for querystring
 */
const prepURL = (url, params) => {
  let { querystring, rest, filtered } = params;
  let qs = "";

  if (rest.id !== null && rest.id !== undefined) {
    url += `${rest.id}/`;
  }

  if (rest.route) {
    url += `${rest.route}/`;
  }

  if (querystring) {
    let querystringKeys = Object.keys(querystring);
    for (let i = 0; i < querystringKeys.length; i++) {
      if (i === 0) {
        qs += `?`;
      }

      let currentKey = querystringKeys[i];
      let value = querystring[currentKey];
      if (value !== null && value !== undefined) {
        qs += `${currentKey}=${value}&`;
      }
    }

    url += qs;
  }

  if (filtered && filtered.length > 0) {
    for (let i = 0; i < filtered.length; i++) {
      if (filtered[i].value.value !== "") {
        url += `${filtered[i].id}__${filtered[i].value.type}=${
          filtered[i].value.value
        }&`;
      }
    }
  }

  return url;
};

function setupRequestHeaders(noContentType) {
  const storage = window.localStorage;
  const token = storage["solestage.management.token"];

  var headers = {
    "Content-Type": "application/json"
  };

  if (noContentType) {
    headers = {};
  }

  if (token) {
    headers["Authorization"] = `Token ${token}`;
  }

  return headers;
}

const API = {
  // Auth
  LOGIN: BASE_URL + "auth/login/",
  REGISTER: BASE_URL + "auth/register/",
  PASSWORD_RESET: BASE_URL + "auth/password/reset/",
  PASSWORD_RESET_CONFIRM: BASE_URL + "auth/password/reset/confirm/",
  STRIPE_CODE: BASE_URL + "user/stripe_connect/",
  STRIPE_ONBOARDING: BASE_URL + "user/stripe_onboarding/",
  USER: BASE_URL + "self/",
  USER_PROFILE: BASE_URL + "user/",
  USER_PERMISSIONS: ({ userId }) => `${BASE_URL}permissions/${userId}/`,
  PERMISSION_INFO: BASE_URL + "permissions/permission_info/",
  SIGNOUT: BASE_URL + "auth/logout/",
  FACEBOOK_LOGIN: BASE_URL + "auth/facebook/",
  EMPLOYEE: BASE_URL + "employee/",
  EMPLOYEE_INVITES: BASE_URL + "invites/employee/",
  USER_ACTIONS: BASE_URL + "user/",
  CONFIRM_EMAIL: BASE_URL + "auth/confirm-email/",
  SALES_REPORT: BASE_URL + "inventory_csv/sales_report/",
  UPDATE_SKUS: BASE_URL + "sku/update_style/",
  UPDATE_PICTURES: BASE_URL + "sku/update_picture/",
  DEACTIVATE_PICTURES: BASE_URL + "sku/deactivate_picture/",
  CONSIGNMENT_MESSAGES: BASE_URL + "message/consignment/",
  DAILY_SALES_REPORT: BASE_URL + "inventory/daily_sales_summary_v2/",
  CHECK_PAYOUT: BASE_URL + "consignment/payout_value/",
  MARK_PAYOUT: BASE_URL + "consignment/payout/",
  PAYOUT: BASE_URL + "payout/",
  PAYOUT_AMOUNT: ({ user_id }) => {
    return BASE_URL + `payout/payout_amount/?user_id=${user_id}`;
  },
  CURRENT_BALANCE: ({ user_id }) => {
    return (
      BASE_URL + `consignment/current_balance_inventory/?user_id=${user_id}`
    );
  },
  REQUESTED_PAYOUT: BASE_URL + "request_payout/",
  REQUEST_PAYOUT: BASE_URL + "ssm/request_payout/",
  REQUEST_PAYOUT_2: ({ rest, querystring }) => {
    let url = BASE_URL + "ssm/request_payout/";

    let params = {
      querystring: querystring,
      rest: rest
    };

    url = prepURL(url, params);
    return url;
  },
  VERIFY_BARCODES: BASE_URL + "inventory/verify_inventory/",

  AUTHENTICATE_TOKEN: (token, email) => {
    return BASE_URL + `invites/employee/?token=${token}&email=${email}`;
  },

  // Clover oauth
  CLOVER_CODE: BASE_URL + "code/clover/",

  // Paypal oauth
  PAYPAL_CODE: BASE_URL + "user/paypal_account_connect/",

  // Stockx
  STOCKX_CODE: BASE_URL + "token/stockx/",
  STOCKX_TOKEN: 'https://accounts.stockx.com/oauth/token',

  // Importing Files
  IMPORT_EXCEL: BASE_URL + "excel/",
  IMPORT_SKU_EXCEL: BASE_URL + "excel/sku/",
  ADD_INVENTORY_ORDER: BASE_URL + "addinventoryorder/",
  CONSIGNMENT_REPORT: BASE_URL + "inventory_csv/download_consignment_report/",
  DELETED_CONSIGNMENT_REPORT:
    BASE_URL + "inventory_csv/download_deleted_consignment_report/",
  PRODUCT_REPORT: BASE_URL + "inventory_csv/download_product_report/",
  SKU_REPORT: BASE_URL + "inventory_csv/download_sku_report/",
  INVENTORY_CSV: ({ route, location, consigner }) => {
    let url = BASE_URL + "inventory_csv/";

    let params = {
      querystring: {
        location,
        consigner
      },
      rest: {
        route
      }
    };

    url = prepURL(url, params);
    return url;
  },

  // Get all sizes
  SIZES: BASE_URL + "sku/all_sizes/",

  //Get all brands
  BRANDS: BASE_URL + "brand/",

  // Find or create SKU
  SKU_FIND_OR_CREATE: () => {
    let url = BASE_URL + `sku/find_or_create/`;
    return url;
  },

  // Inventory Data
  INVENTORY: ({
    path,
    page,
    sku__us_size,
    pageSize,
    sku,
    location,
    search,
    id,
    filtered,
    isUsed,
    ordering,
    consignment,
    price__isnull,
    consignment__barcode_printed,
    consignment__isnull,
    consignment__status,
    consignment__list_status,
    consigner__profile__first_name,
    consigner__profile__last_name,
    deleted,
    in_transit,
    consigner
  }) => {
    let url = BASE_URL + `inventory`;
    if (id) {
      url += `/${id}/?`;
    } else {
      if (path) {
        url += `/${path}/?`;
      } else {
        url += "/?";
      }
    }

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (pageSize) {
      url += `size=${pageSize}&`;
    }

    if (isUsed != null) {
      url += `consignment__used=${isUsed}&`;
    }

    if (ordering) {
      url += `ordering=${ordering}&`;
    }

    if (sku) {
      url += `sku=${sku}&`;
    }

    if (sku__us_size) {
      url += `sku__us_size=${sku__us_size}&`;
    }

    if (location) {
      url += `location=${location}&`;
    }

    if (consigner) {
      url += `consigner=${consigner}&`;
    }

    if (
      consignment__barcode_printed !== null &&
      consignment__barcode_printed !== "" &&
      consignment__barcode_printed !== undefined
    ) {
      url += `consignment__barcode_printed=${consignment__barcode_printed}&`;
    }

    if (consignment__isnull !== undefined && consignment__isnull !== null) {
      url += `consignment__isnull=${consignment__isnull}&`;
    }

    if (in_transit !== undefined && in_transit !== null) {
      url += `in_transit=${in_transit}&`;
    }

    if (price__isnull !== undefined && price__isnull !== null) {
      url += `price__isnull=${price__isnull}&`;
    }

    if (consigner__profile__first_name) {
      url += `consigner__profile__first_name=${consigner__profile__first_name}&`;
    }

    if (consigner__profile__last_name) {
      url += `consigner__profile__last_name=${consigner__profile__last_name}&`;
    }

    if (consignment__status) {
      url += `consignment__status=${consignment__status}&`;
    }

    if (consignment__list_status) {
      url += `consignment__list_status=${consignment__list_status}&`;
    }

    if (consignment === false) {
      url += `consignment__isnull=${true}&`;
    } else if (consignment === true) {
      url += `consignment__isnull=${false}&`;
    } else if (consignment) {
      url += `consignment=${consignment}&`;
    }

    if (search || search === 0) {
      url += `search=${search}&`;
    }

    if (deleted) {
      url += `deleted&`;
    }

    if (filtered && filtered.length > 0) {
      for (let i = 0; i < filtered.length; i++) {
        if (filtered[i].value.value !== "") {
          url += `${filtered[i].id}__${filtered[i].value.type}=${
            filtered[i].value.value
          }&`;
        }
      }
    }
    return url;
  },

  MARK_AS_MISSING: ({ id }) => {
    let url = BASE_URL + `inventory`;
    if (id) {
      url += `/${id}/mark_as_missing/`;
    }
    return url;
  },

  REMARK_AS_LISTED: ({ id }) => {
    let url = BASE_URL + `inventory`;
    if (id) {
      url += `/${id}/remark_as_listed/`;
    }
    return url;
  },

  STYLE_QUANTITY_BY_LOCATION: ({ style }) => {
    return BASE_URL + `inventory/location/${style}/`;
  },

  SYNC_WITH_SHOPIFY_BY_STYLE: () => {
    return BASE_URL + `truestyle/sync_with_shopify/`;
  },

  UPDATE_PRICE_BY_STYLE: ({ style, location }) => {
    let url = BASE_URL + `product/${style}/update_price/`;

    if (location) {
      url += `?location=${location}`;
    }

    return url;
  },

  UPDATE_PRICE_BY_SKU: ({ location }) => {
    let url = BASE_URL + `sku/update_price/`;

    if (location) {
      url += `?location=${location}`;
    }
    return url;
  },

  GET_TAGS: BASE_URL + `shopify/get_tags/`,
  INVENTORY_BY_SKU: ({ id }) => {
    let url = BASE_URL + "inventory/alteration/";
    let params = {
      rest: {
        id,
        route: "inventory_by_sku"
      }
    };
    return prepURL(url, params);
  },

  TRUE_STYLE: ({
    page,
    size,
    location,
    consigner,
    consignment_status,
    deleted,
    us_size,
    search,
    filtered,
    inventoryId,
    barcode,
    sold,
    id,
    style,
    sku,
    add_inventory_order
  }) => {
    let url = BASE_URL + `truestyle`;
    if (style) {
      url += `/quantity_by_size`;
    }

    if (id) {
      url += `/${id}/?`;
    } else {
      url += "/?";
    }

    if (style) {
      url += `style=${encodeURIComponent(style)}&`;
    }

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (add_inventory_order) {
      url += `skus__inventory__add_inventory_order=${add_inventory_order}&`;
    }

    if (size) {
      url += `size=${size}&`;
    }

    if (location) {
      url += `skus__inventory__location=${location}&`;
    }

    if (us_size) {
      url += `skus__inventory__sku__us_size=${us_size}&`;
    }

    if (consigner) {
      url += `skus__inventory__consigner_id=${consigner}&`;
    }

    if (consignment_status) {
      url += `skus__inventory__consignment__status__in=${consignment_status}&`;
    }

    if (deleted) {
      url += `deleted&`;
    }

    if (sku) {
      url += `skus=${sku}&`;
    }

    if (inventoryId) {
      url += `skus__inventory__id=${inventoryId}&`;
    }

    if (search || search === 0) {
      url += `search=${search}&`;
    }

    if (barcode) {
      url += `barcode=${barcode}&`;
    }

    if (sold !== null && sold !== undefined) {
      url += `skus__inventory__sold=${sold}&`;
    }

    if (filtered && filtered.length > 0) {
      for (let i = 0; i < filtered.length; i++) {
        if (filtered[i].value.value !== "") {
          let key = filtered[i].id;
          let filterType = filtered[i].value.type;
          if (key === "sizes") {
            key = "skus__us_size";
            url += `${key}=${filtered[i].value.value}&`;
          } else {
            url += `${key}__${filterType}=${filtered[i].value.value}&`;
          }
        }
      }
    }
    return url;
  },

  UPDATE_ITEMS_BY_SKU: ({
    type,
    style,
    size,
    location,
    consigner,
    consignment_status,
    price,
    quantity,
    reason,
    currentPrice
  }) => {
    let url = BASE_URL + `truestyle`;
    if (style) {
      url += `/${style}/${type}/?`;
    }

    if (size) {
      url += `size=${size}&`;
    }

    if (location) {
      url += `location=${location}&`;
    }

    if (consigner) {
      url += `consigner=${consigner}&`;
    }

    if (consignment_status) {
      url += `status=${consignment_status}&`;
    }

    if (price) {
      url += `price=${price}&`;
    }
    if (quantity) {
      url += `quantity=${quantity}&`;
    }
    if (reason) {
      url += `reason=${reason}&`;
    }

    if (currentPrice) {
      url += `currentPrice=${currentPrice}`;
    }

    return url;
  },

  STYLE: ({ page, size, location, search, filtered }) => {
    let url = BASE_URL + `style/?`;
    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (size) {
      url += `size=${size}&`;
    }

    if (location) {
      url += `inventory__location=${location}&`;
    }

    if (search || search === 0) {
      let searchTerm = `${search}`;
      if (hasWhiteSpace(search)) {
        searchTerm = `"${search}"`;
      }
      url += `search=${searchTerm}&`;
    }

    if (filtered && filtered.length > 0) {
      for (let i = 0; i < filtered.length; i++) {
        if (filtered[i].value.value !== "") {
          url += `${filtered[i].id}__${filtered[i].value.type}=${
            filtered[i].value.value
          }&`;
        }
      }
    }
    return url;
  },

  ADDRESS: ({ querystring, rest }) => {
    let url = BASE_URL + "address/";

    let params = {
      querystring: querystring,
      rest: rest
    };

    url = prepURL(url, params);
    return url;
  },

  TRUE_ORDERS: ({ querystring, rest }) => {
    let url = BASE_URL + "ssm/trueorders/";

    let params = {
      querystring: querystring,
      rest: rest
    };

    url = prepURL(url, params);
    return url;
  },

  PRODUCT: ({
    page,
    style,
    search,
    size,
    id,
    product_name_us,
    merge,
    ordering,
    filtered,
    route
  }) => {
    // Endpoint to get all Product (STYLE) information
    let url = BASE_URL + `product/`;
    let params = {
      filtered,
      querystring: {
        page,
        style,
        search,
        product_name_us,
        ordering,
        size
      },
      rest: {
        id,
        route
      }
    };

    if (merge) {
      url += `${id}/merge/`;
    } else {
      url = prepURL(url, params);
    }
    return url;
  },

  SYNC_WITH_CLOVER: ({ id }) => {
    return BASE_URL + `inventory/${id}/sync_with_clover/`;
  },

  CONSIGNMENT_MESSAGE: BASE_URL + `message/consignment/`,

  CONSIGNMENT: ({
    page,
    size,
    id,
    status,
    barcode_printed,
    location,
    simple
  }) => {
    let url = BASE_URL + `consignment/`;

    if (simple) {
      url = BASE_URL + `ssm/simple/consignment/`;
    }

    if (id) {
      url += `${id}/?`;
    } else {
      url += `?`;
    }

    if (barcode_printed) {
      url += `barcode_printed=${barcode_printed}&`;
    }

    if (location) {
      url += `item__location=${location}&`;
    }

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (size) {
      url += `size=${size}&`;
    }

    if (status) {
      url += `status=${status}`;
    }

    return url;
  },

  CONSIGNMENT_ORDERS_BARCODE: ({ id }) => {
    let url = BASE_URL + `order/consignment/`;

    if (id) {
      url += `${id}/`;
    }

    url += "print_barcodes/";
    return url;
  },

  CONSIGNMENT_ORDERS_NOTE: ({ id, note }) => {
    let url = BASE_URL + `order/consignment/`;

    if (id) {
      url += `${id}/`;
    }

    url += "update_note/?";

    if (note) {
      url += `note=${note}`;
    }
    return url;
  },

  CONSIGNMENT_ORDER_CSV: ({ id }) => {
    let url = BASE_URL + "inventory_csv/download_consignment_order_report/?";
    if (id) {
      url += `consignment_order=${id}&`;
    }
    return url;
  },

  CONSIGNMENT_ORDERS: ({
    page,
    size,
    id,
    status,
    location,
    barcode_printed,
    dropoff_location,
    consignment__status__in,
    search
  }) => {
    let url = BASE_URL + `order/consignment/`;

    if (id) {
      url += `${id}/?`;
    } else {
      url += `?`;
    }

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (size) {
      url += `size=${size}&`;
    }

    if (status) {
      url += `status=${status}&`;
    }

    if (search || search === 0) {
      let searchTerm = `${search}`;
      if (hasWhiteSpace(search)) {
        searchTerm = `"${search}"`;
      }
      url += `search=${searchTerm}&`;
    }

    if (consignment__status__in) {
      url += `consignment__status__in=${consignment__status__in}&`;
    }

    if (dropoff_location) {
      url += `dropoff_location=${dropoff_location}&`;
    }

    if (location) {
      url += `items__location=${location}&`;
    }

    if (barcode_printed) {
      url += `barcode_printed=${barcode_printed}`;
    }

    return url;
  },

  CONSIGNMENT_PRICE: ({
    page,
    pageSize,
    id,
    status,
    search,
    type,
    location
  }) => {
    let url = BASE_URL + `request/`;

    if (id) {
      url += `${id}/`;
    }

    if (type) {
      url += `${type}/?`;
    } else {
      url += `?`;
    }

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (pageSize) {
      url += `size=${pageSize}&`;
    }

    if (status) {
      url += `status=${status}&`;
    }

    if (search || search === 0) {
      let searchTerm = `${search}`;
      if (hasWhiteSpace(search)) {
        searchTerm = `"${search}"`;
      }
      url += `search=${searchTerm}&`;
    }

    if (location) {
      url += `location=${location}&`;
    }

    return url;
  },

  INVENTORY_RECONCILIATION: ({
    page,
    pageSize,
    id,
    status,
    search,
    location,
    notes,
    type,
    reconciliationId,
    sessionId,
    itemId,
    preview
  }) => {
    let url = BASE_URL + `reconciliation/`;

    if (id) {
      url += `${id}/`;
    }

    if (type) {
      url += `${type}/?`;
    } else {
      url += `?`;
    }

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (pageSize) {
      url += `size=${pageSize}&`;
    }

    if (status) {
      url += `status=${status}&`;
    }

    if (search || search === 0) {
      let searchTerm = `${search}`;
      if (hasWhiteSpace(search)) {
        searchTerm = `"${search}"`;
      }
      url += `search=${searchTerm}&`;
    }

    if (location) {
      url += `location=${location}&`;
    }

    if (notes) {
      url += `notes=${encodeURIComponent(notes)}&`;
    }

    if (reconciliationId) {
      url += `reconciliation_id=${reconciliationId}&`;
    }

    if (sessionId) {
      url += `session_id=${sessionId}&`;
    }

    if (itemId) {
      url += `item_id=${itemId}&`;
    }

    if (preview) {
      url += `preview=${preview}&`;
    }

    return url;
  },

  ORDER_PAGE_COUNTS: ({ location }) => {
    let url = BASE_URL + `orders/order_counts/?`;
    if (location) {
      url += `location=${location}&`;
    }
    return url;
  },
  ORDER_ITEM_UNAVAILABLE: ({ orderId }) => {
    const url = BASE_URL + `orders/${orderId}/mark_out_of_stock/`;
    return url;
  },
  ORDERS: ({
    page,
    size,
    location,
    orderId,
    search,
    inventory__location,
    status,
    fulfillment__status,
    order__store_transaction
  }) => {
    let url = BASE_URL + `orders/`;

    if (orderId) {
      url += `${orderId}/`;
    }

    url += "?";

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (size) {
      url += `size=${size}&`;
    }

    if (status) {
      url += `status=${status}&`;
    }

    if (search) {
      url += `search=${search}&`;
    }

    if (fulfillment__status) {
      url += `fulfillment__status=${fulfillment__status}&`;
    }

    if (location && parseInt(location, 10) !== 0) {
      url += `location=${location}&`;
    }

    if (inventory__location) {
      url += `inventory__location=${inventory__location}&`;
    }

    if (order__store_transaction) {
      url += `order__store_transaction=${order__store_transaction}&`;
    } else if (order__store_transaction === false) {
      url += `order__store_transaction=False&`;
    }

    return url;
  },

  SKU: ({
    page,
    path,
    size,
    location,
    search,
    filtered,
    style,
    id,
    barcode,
    product_id
  }) => {
    let url = BASE_URL + `sku/`;

    if (id) {
      url += `${id}/?`;
    } else if (path) {
      url += `${path}/?`;
    } else {
      url += `?`;
    }

    if (product_id) {
      url += `product=${product_id}&`;
    }

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (barcode) {
      url += `barcode=${barcode}&`;
    }

    if (style) {
      url += `style=${style}&`;
    }

    if (size) {
      url += `size=${size}&`;
    }

    if (location) {
      url += `inventory__location=${location}&`;
    }

    if (search || search === 0) {
      let searchTerm = `${search}`;
      if (hasWhiteSpace(search)) {
        searchTerm = `"${search}"`;
      }
      url += `search=${searchTerm}&`;
    }

    if (filtered && filtered.length > 0) {
      for (let i = 0; i < filtered.length; i++) {
        if (filtered[i].value.value !== "") {
          url += `${filtered[i].id}__${filtered[i].value.type}=${
            filtered[i].value.value
          }&`;
        }
      }
    }
    return url;
  },

  SAVE_SKU: id => {
    return BASE_URL + `sku/${id}/`;
  },

  SHELF: params => {
    let url = BASE_URL + `shelf/?`;
    if (params.name) {
      url += `name=${params.name}`;
    }

    return url;
  },

  SHIPMENT: id => {
    let url = BASE_URL + `shipment/`;
    if (id) {
      url += `${id}/`;
    }
    return url;
  },

  LOAD_LOCATIONS: () => {
    return BASE_URL + `location/?size=`;
  },

  DASHBOARD: (option, location) => {
    return BASE_URL + `dashboard/?timeframe=${option}&location=${location}`;
  },

  PICTURE: ({ upload_photos, id }) => {
    let url = BASE_URL + "picture";

    if (id) {
      url += `/${id}/`;
    }

    if (upload_photos) {
      url += "/upload_photos/";
    }

    return url;
  },

  CONSIGNMENT_PICTURE: ({ id, upload_photos }) => {
    let url = BASE_URL + `photos/consignment`;
    if (id) {
      url += `/${id}/`;
    }

    if (upload_photos) {
      url += "upload_photos/";
    }

    return url;
  },

  GET_BULK_STOCK_QUANTITY: ({ location, style }) => {
    let url = BASE_URL + `inventory/get_bulk_stock_quantity`;
    if (style) {
      url += `/?sku__product__style=${encodeURIComponent(style.trim())}&`;
    } else {
      url += "/?";
    }

    if (location) {
      url += `location=${location}&`;
    }

    return url;
  },

  EXPORT_ORDERS: ({ rest }) => {
    let url = BASE_URL + `orders_csv/`;

    let params = {
      rest
    };

    url = prepURL(url, params);
    return url;
  },

  DECREASE_STOCK_QUANTITY: ({ location, style, size }) => {
    let url = BASE_URL + `inventory/decrease_stock_quantity`;
    if (style) {
      url += `/?sku__product__style=${encodeURIComponent(style.trim())}&`;
    } else {
      url += "/?";
    }

    if (size) {
      url += `sku__us_size=${size}&`;
    }

    if (location) {
      url += `location=${location}&`;
    } else {
      url += "location=&";
    }

    url += `consignment__isnull=${true}`;
    return url;
  },

  WDT_WAREHOUSES: ({ id, page, route }) => {
    let url = BASE_URL + "wdt/warehouse/";
    let params = {
      rest: {
        id,
        route
      },
      querystring: {
        page
      }
    };
    url = prepURL(url, params);
    return url;
  },

  FULFILLMENT: ({
    fulfillment_id,
    page,
    pageSize,
    shipped,
    status,
    from_location,
    trackingNumber
  }) => {
    let url = BASE_URL + `fulfillment/`;
    if (fulfillment_id) {
      url += `${fulfillment_id}/`;
    }

    url += "?";

    if (page) {
      url += `page=${page}&`;
    }

    if (pageSize) {
      url += `limit=${pageSize}&`;
    }

    if (shipped !== null || shipped !== undefined) {
      url += `shipped=${shipped}&`;
    }

    if (status) {
      url += `status=${status}&`;
    }

    if (from_location) {
      url += `from_location=${from_location}&`;
    }

    if (trackingNumber) {
      url += `tracking_number=${trackingNumber}`;
    }

    return url;
  },

  MARK_AS_SHIPPED: BASE_URL + `fulfillment/mark_as_shipped/`,
  SYNC_WITH_SHIPSTATION: ({ fulfillment_id }) => {
    return BASE_URL + `fulfillment/${fulfillment_id}/sync_with_shipstation/`;
  },

  CREATE_SHIPPING_LABEL: ({ fulfillment_id }) => {
    return BASE_URL + `fulfillment/${fulfillment_id}/generate_shipping_label/`;
  },
  CHOOSE_SHIPPING_LABEL: ({ fulfillment_id }) => {
    return BASE_URL + `fulfillment/${fulfillment_id}/choose_shipping_label/`;
  },
  UPDATE_TRACKING: ({ fulfillment_id }) => {
    return BASE_URL + `fulfillment/${fulfillment_id}/update_tracking/`;
  },
  POST_TRACKING: ({ fulfillment_id }) => {
    return BASE_URL + `fulfillment/${fulfillment_id}/post_tracking/`;
  },

  GET_RATES: ({ weight, confirmation, fulfillmentId, dimensions }) => {
    let url = BASE_URL + `fulfillment/${fulfillmentId}/get_shipping_options/?`;

    if (weight) {
      url += `weight=${weight}`;
    }

    if (confirmation) {
      url += `&confirmation=${confirmation}`;
    }

    if (dimensions) {
      let { length, width, height } = dimensions;
      url += `&length=${length}&width=${width}&height=${height}`;
    }

    return url;
  },

  AUDIT_TRAIL: ({ page, pageSize, search, model, appLabel }) => {
    let url = BASE_URL + `audit_trail/get_log/?`;

    if (page || page === 0) {
      url += `page=${page}&`;
    }

    if (pageSize) {
      url += `limit=${pageSize}&`;
    }

    if (search) {
      url += `search=${search}&`;
    }

    if (model) {
      url += `model=${model}&`;
    }

    if (appLabel) {
      url += `appLabel=${appLabel}&`;
    }

    return url;
  },

  // HTTP Configurations
  GET_CONFIG: (token = null) => {
    let headers;
    headers = setupRequestHeaders(false);
    return {
      method: "GET",
      headers: headers
    };
  },
  GET_CONFIG_WITH_BODY: data => {
    let headers;
    headers = setupRequestHeaders(false);
    return {
      method: "GET",
      body: JSON.stringify(data),
      headers: headers
    };
  },
  POST_FORM_DATA_CONFIG: (formData, overrideHeaders = {}) => {
    // authorization token
    let headers = setupRequestHeaders(true);
    headers = { ...headers, ...overrideHeaders };
    return {
      method: "post",
      body: formData,
      headers: headers
    };
  },
  POST_FILE_CONFIG: (key, data, key2, data2) => {
    // authorization token
    var headers = setupRequestHeaders(true);
    var formData = new FormData();
    formData.append(key, data);
    formData.append(key2, data2);
    return {
      method: "post",
      body: formData,
      headers: headers
    };
  },
  POST_CONFIG: function POST_CONFIG(data, contentType = '') {
    // authorization token
    let headers = setupRequestHeaders();
    // if (contentType) {
    //   headers = {
    //     "Content-Type": contentType,
    //   };
    // }
    return {
      method: "post",
      body: JSON.stringify(data),
      headers: headers
    };
  },
  PUT_CONFIG: function PUT_CONFIG(data) {
    // authorization token
    var headers = setupRequestHeaders();
    return {
      method: "put",
      body: JSON.stringify(data),
      headers: headers
    };
  },
  PATCH_CONFIG: function PATCH_CONFIG(data) {
    // authorization token
    var headers = setupRequestHeaders();
    return {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: headers
    };
  },
  DELETE_CONFIG: function DELETE_CONFIG(data) {
    // authorization token
    var headers = setupRequestHeaders();
    return {
      method: "delete",
      body: JSON.stringify(data),
      headers: headers
    };
  }
};

export default API;
