import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./screens/app/App";
import { unregister } from "./registerServiceWorker";

import { ConnectedRouter } from "react-router-redux";
import { Provider } from "react-redux";
import { configure, history } from "./config/configure-store";

const store = configure();
const initialize = () =>
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App store={store} />
      </ConnectedRouter>
    </Provider>,
    document.getElementById("root")
  );

initialize();

if (module.hot && process.env.NODE_ENV !== "production") {
  module.hot.accept("./screens/app/App", initialize);
  window.store = store;
  window.redux = store;
}
unregister();
// registerServiceWorker();
